import type { SVGProps } from "react";
const SvgDocumentTextSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.001 3.996h6.708l.146.147 5 5 .146.146v10.707h-12v-16Zm1 1v14h10v-9h-5v-5h-5Zm6 .707 3.293 3.293h-3.293V5.703ZM9 13h6v-1H9v1Zm6 2H8.998v-1H15v1Zm-6 2h6v-1H9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentTextSmall;
