import type { SVGProps } from "react";
const SvgGearChangingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5-4v9h1v-4h4v4h1v-4h5V8h-1v4h-4V8h-1v4H8V8H7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGearChangingSmall;
