import type { SVGProps } from "react";
const SvgStarFilledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 14.25H6.75l6.75 5.25-3 8.699L18 23.25l7.5 4.949-3-8.699 6.75-5.25H21l-3-9z"
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
    />
  </svg>
);
export default SvgStarFilledMedium;
