import type { SVGProps } from "react";
const SvgArmchairProfileUserMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.5 4.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM7.75 6a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm6.816 9.998-3-5.25.868-.496 2.856 4.998h7.21v1h-7.79l-.144-.252Zm-9.967-1.156c-.344-1.034-.284-1.84-.033-2.42a2.112 2.112 0 0 1 1.269-1.168c1.105-.381 2.634.053 3.467 1.72l.447-.224-.447.224.001.002.004.006.013.026.05.1.19.381.68 1.36 2.063 4.125.138.276H23.25c.555 0 1.361.172 1.91.633.263.222.468.51.566.89.099.383.1.895-.102 1.574L24.51 25.57l-1.616 4.679H10.099l-.06-.164a1679.667 1679.667 0 0 1-2.069-5.693c-1.219-3.376-2.623-7.308-3.37-9.551Zm4.681 16.08.47-.172-.47.172.12.328h14.206l.116-.337 1.49-4.309 5.957 5.27.662-.748-6.267-5.544 1.009-2.919.003-.01.003-.01c.245-.815.27-1.52.115-2.12a2.694 2.694 0 0 0-.891-1.406c-.79-.664-1.858-.867-2.553-.867H13.059l-1.925-3.849-.68-1.359-.19-.38-.05-.101-.013-.026-.003-.006v-.002h-.001c-1.041-2.084-3.075-2.775-4.688-2.218a3.112 3.112 0 0 0-1.86 1.714c-.37.85-.404 1.919.002 3.135.752 2.257 2.16 6.2 3.379 9.574A1530.567 1530.567 0 0 0 9.1 30.43l.134.364.035.096.008.024.003.006v.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArmchairProfileUserMedium;
