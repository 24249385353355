import type { SVGProps } from "react";
const SvgArrowsLeftRightDottedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 7.5h4.5m0 0-3 3m3-3-3-3M9 16.5H4.5m0 0 3-3m-3 3 3 3m3.512-12H8.988m5.024 0h-2.024m-3.976 0H5.988m-.976 0H3.988m8.024 9H9.988m5.024 0h-2.024m5.024 0h-2.024m4.024 0h-1.024"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgArrowsLeftRightDottedSmall;
