import type { SVGProps } from "react";
const SvgSunshadeSunSandLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M37 2v3h-1V2h1Zm6.571 3.136-2.12 2.121-.708-.707 2.121-2.12.707.706ZM30.136 4.43l2.12 2.121-.706.707-2.121-2.12.707-.708ZM36.5 8a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM32 11.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm-5-.5h3v1h-3v-1Zm19 1h-3v-1h3v1ZM9.19 14.569c6.387-3.385 14.36-1.13 18.004 5.183l.25.433-.433.25-10.815 6.244L26.78 44H42v1H5v-1h20.61L15.33 27.179 4.495 33.435l-.433.25-.25-.433C.166 26.94 2.198 18.909 8.323 15.069l-.504-.874.866-.5.504.874Zm-3.185 3.8C2.756 22.162 2 27.683 4.435 32.314l3.461-1.998c-1.666-3.045-2.465-6.224-2.388-8.96.03-1.065.194-2.076.497-2.987Zm2.758 11.447 12.98-7.494c-1.724-2.829-3.95-4.967-6.175-6.17-2.345-1.267-4.609-1.46-6.315-.475-1.707.985-2.67 3.043-2.745 5.707-.071 2.527.667 5.524 2.255 8.432Zm13.846-7.994 3.46-1.998c-2.791-4.424-7.95-6.529-12.858-5.614.94.194 1.896.556 2.832 1.063 2.41 1.302 4.763 3.583 6.566 6.549Zm6.82-3.958 2.12-2.121.708.707-2.121 2.12-.707-.706Zm13.435.707-2.12-2.121.706-.707 2.121 2.12-.707.708ZM36 21v-3h1v3h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunshadeSunSandLarge;
