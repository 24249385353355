import type { SVGProps } from "react";
const SvgBicycleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.5 6H13v1h2.566l-1.334 2H8.65l-.12.329-.644 1.77A4.002 4.002 0 0 0 3 15c0 2.206 1.794 4 4 4s4-1.794 4-4a4.003 4.003 0 0 0-2.174-3.559L9.35 10h4.828l.725 1.595A4 4 0 0 0 17.002 19c2.206 0 4-1.794 4-4a4.004 4.004 0 0 0-5.188-3.82l-.743-1.634 1.846-2.77.518-.776H16.5Zm-2.499 9a3 3 0 0 1 1.32-2.485l1.225 2.691.91-.413-1.224-2.693a3.004 3.004 0 0 1 3.77 2.9A3.007 3.007 0 0 1 17 18c-1.655 0-3-1.346-3-3Zm-6.46-2.951-1.01 2.78.939.341 1.01-2.778A3.002 3.002 0 0 1 10 15c0 1.654-1.346 3-3 3s-3-1.346-3-3a3.004 3.004 0 0 1 3.54-2.951ZM7.208 8h2.793V7H7.209v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBicycleSmall;
