import type { SVGProps } from "react";
const SvgQrcodeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 21h6v-6h-6v6Zm-1.5 1.5h9v-9h-9v9Zm3-3h3v-3h-3v3ZM10.498 5.994H15V4.492h-4.502v1.502Zm0 3.005V7.5h-3V9l1.5.005v3h1.5v-1.5h3V9.001h3v-1.5h-3v.003h-1.5v1.5h-1.5v-.005Zm0 4.5H7.5v-3H6V9H4.5v3H6v1.5h1.499V15h3v-1.5ZM18 7.505h1.5v-3H18v1.497h-1.5v1.5H18v.003Zm12.001 3h1.5V9.002H30v1.501Zm-3 7.5h1.5v-1.5H27v1.497h-3v1.5h3v-1.497ZM6 6.005l3-.012v-1.5H4.5v3.012H6v-1.5Zm21 13.5v3h1.5v-1.5H30v1.5h1.5v-3H27Zm-7.5-12v3H21v-1.5l4.5-.003v-1.5H27v-1.5h-1.5V4.505H24v2.997h-1.5V6.005H21v1.5h-1.5Zm4.5 3v3h1.5v-.003H27v-1.5h-1.5v-1.497H24Zm1.5 21.002H27v-3h-1.5v3Zm4.5-1.505h-1.5v1.5H30v.005h1.5v-3H30v1.495Zm0-22.497h1.5v-3H30v-.003h-3v1.5h3v1.503Zm0 15h-1.5v4.497H27v1.5h3v-1.5h1.5v-1.5H30v-2.997Zm0-4.5h1.5v-3H30v3Zm-4.5-7.504h3v-1.5h-3v1.5Zm3 1.5v3.004H30v-1.5l1.5-.003v-1.5h-3Zm-12 19.502h3V30h-3v1.502ZM15 12.002h1.5v.003H18v-3h-1.5v1.497H15v1.5Zm0 16.5h-1.5v1.5H15v.005h1.5v-3H15v1.495Zm7.5.005H21v3h1.5v-.005H24v-1.5h-1.5v-1.495Zm-10.502-6.005-3 .004v1.5h1.5v1.497h3v-1.5h-1.5v-1.5Zm3.002 3h3v-1.5h-3v1.5Zm9-9h3v-1.5h-3v1.5Zm-19.501 10.5h1.5v-1.5h-1.5v1.5Zm3-7.5H9v-1.5H7.499v1.5Zm3-6h1.5v-1.5h-1.5v1.5ZM21 12.001h1.5v-1.5H21v1.5Zm-15.001 6h1.5v-1.5h-1.5v1.5Zm-1.5-1.5h1.5v-3h-1.5v3Zm0 4.5h1.5v-3h-1.5v3Zm3 6.002H10.5v-1.5H7.499v1.5Zm0-6.002h-1.5v1.5l-1.5.004v1.5h3v-3.003Zm3 7.5v.005h-1.5v1.5h1.5v1.495h1.5v-2.996h1.5v-1.5h-3v1.497Zm-4.5 0h-1.5v3h1.5v.005h3v-1.5h-3v-1.505Zm3-7.496h3v-1.5h-3v1.5Zm0-3h3v-1.5h-3v1.5ZM25.5 25.503H27v-1.5h-1.5v1.5Zm-4.5 0h1.5v3h3v-1.5H24v-2.997h1.5v-3H24v2.997l-4.5.003v1.5H18v3h1.5v-1.5H21v-1.503Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeMedium;
