import type { SVGProps } from "react";
const SvgIc6Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-6-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M42.244 7.122h2.191a3.889 3.889 0 0 0-.749-1.874c-.678-.86-1.655-1.29-2.932-1.29a3.751 3.751 0 0 0-3.209 1.622c-.81 1.118-1.214 2.663-1.214 4.635 0 1.846.378 3.284 1.135 4.315.757 1.031 1.816 1.547 3.177 1.547a3.84 3.84 0 0 0 2.728-1.068c.84-.791 1.261-1.838 1.261-3.14a4.051 4.051 0 0 0-.938-2.737 3.26 3.26 0 0 0-2.61-1.155 3.008 3.008 0 0 0-1.414.308c-.44.25-.82.596-1.108 1.013.05-.657.169-1.307.355-1.938.357-1.05.962-1.574 1.813-1.574.804 0 1.309.445 1.514 1.336Zm-3.028 3.347a1.53 1.53 0 0 1 1.317-.697 1.64 1.64 0 0 1 1.411.736c.291.425.44.932.426 1.448a2.427 2.427 0 0 1-.473 1.495 1.586 1.586 0 0 1-1.333.672 1.546 1.546 0 0 1-1.34-.704 2.489 2.489 0 0 1-.449-1.48 2.46 2.46 0 0 1 .441-1.47Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIc6Negative;
