import type { SVGProps } from "react";
const SvgSpeechBubbleGroupEmptySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 4h9v6H6.957l-2.103 2.104-.854.853V10H3V4Zm1 5h1v1.543l1.396-1.397L6.543 9H11V5H4v4Zm3 3h10v7h-2v2.957l-.854-.853L12.043 19H7v-7Zm1 1v5h4.457l.147.146L14 19.543V18h2v-5H8Zm6.5-8H14v5h3.043l2.103 2.104.854.853V10h1V5h-6.5ZM20 6v3h-1v1.543l-1.396-1.397L17.457 9H15V6h5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSpeechBubbleGroupEmptySmall;
