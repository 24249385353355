import type { SVGProps } from "react";
const SvgCloudSnowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.044 16.064a2.86 2.86 0 0 0-1.864-1.08c.246-1.253.065-2.373-.417-3.293a4.664 4.664 0 0 0-2.555-2.204c-1.997-.725-4.471-.138-5.8 2.23-2.192-1.226-5.127.33-5.134 3.133-.744.144-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.841 1.073 2.548.708.713 1.75 1.156 3.125 1.156h10.5c1.33 0 2.366-.446 3.087-1.148a3.943 3.943 0 0 0 1.162-2.52c.07-.901-.16-1.822-.705-2.52Zm-9.986-3.412c1.01-2.29 3.123-2.837 4.81-2.225.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.2.67.7-.028c.777-.03 1.329.273 1.685.73.368.47.55 1.133.496 1.825a2.943 2.943 0 0 1-.863 1.882c-.517.503-1.294.864-2.389.864H12c-1.15 0-1.922-.364-2.415-.86-.498-.503-.753-1.184-.784-1.887-.03-.707.167-1.4.528-1.905.355-.495.855-.798 1.471-.798h.563l-.066-.56c-.296-2.473 2.358-3.737 3.986-2.403l.51.416.265-.601ZM20 27.75a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm3-2a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm4 3a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowMedium;
