import type { SVGProps } from "react";
const SvgHandWithServiceBellSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 4.5a.5.5 0 1 1 .998-.002A.5.5 0 0 1 11 4.5Zm1.888.57A1.499 1.499 0 1 0 10 4.5c0 .202.04.394.112.57-1.648.17-3.207.648-4.449 1.476C4.18 7.535 3.164 9.018 3.018 11H2v1h19v-1h-1.018c-.146-1.982-1.162-3.465-2.645-4.454-1.241-.828-2.801-1.307-4.449-1.476ZM11.5 6c-2.013 0-3.907.46-5.282 1.378-1.23.82-2.056 2.01-2.197 3.622H18.98c-.141-1.612-.966-2.801-2.197-3.622C15.407 6.46 13.512 6 11.5 6Zm-3.498 9.82-3.577-2.5a1.5 1.5 0 0 0-1.873 2.343l5.917 5.213.141.125h3.926l.862.855.146.145h8.857l-.846-.852-5.028-5.064-.008-.008c-1.188-1.144-2.75-2.045-4.4-2.076H10c-.633 0-1.145.244-1.495.635-.301.336-.465.76-.503 1.185ZM3.84 14.13l4.697 3.285-5.428-3.228a.502.502 0 0 1 .705-.077l.013.01.013.01Zm4.697 3.285L3.11 14.187a.5.5 0 0 0 .078.703l.01.007.008.008L8.988 20h3.96l.146.145.862.855H20l-4.177-4.208c-1.084-1.041-2.413-1.766-3.718-1.792H10c-.36 0-.597.131-.75.302-.162.18-.254.434-.256.702-.002.268.086.518.243.695.15.168.389.301.763.301h2v1h-2c-.613 0-1.113-.22-1.463-.585Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandWithServiceBellSmall;
