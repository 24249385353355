import type { SVGProps } from "react";
const SvgIr96Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-96-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-96-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm10.446.53h2.183c.18.896.699 1.344 1.558 1.344.486 0 .878-.163 1.179-.49.306-.332.54-.847.704-1.543.105-.443.182-.946.23-1.51-.354.495-.718.849-1.092 1.06-.37.205-.82.308-1.353.308-.849 0-1.587-.248-2.215-.744a3.543 3.543 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.765-.696 1.682-1.044 2.753-1.044.659 0 1.268.148 1.827.443a3.961 3.961 0 0 1 1.408 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.296 2.99-.886 4.129-.77 1.482-1.936 2.222-3.497 2.222-1.039 0-1.898-.287-2.578-.862-.675-.575-1.066-1.347-1.171-2.317Zm3.844-6.985c-.495 0-.915.203-1.258.609-.342.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.338.544.812.815 1.424.815.554 0 .997-.221 1.329-.664.311-.417.467-.912.467-1.487 0-.607-.161-1.12-.483-1.543a1.606 1.606 0 0 0-1.337-.656Zm13.108 1.21h-2.2c-.205-.891-.712-1.337-1.518-1.337-.855 0-1.461.525-1.82 1.574-.147.428-.266 1.074-.356 1.938.348-.469.718-.806 1.108-1.012.395-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.627.728.941 1.64.941 2.737 0 1.303-.422 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.429-.514-3.188-1.542-.76-1.034-1.14-2.473-1.14-4.319 0-1.972.407-3.517 1.219-4.635.786-1.081 1.859-1.622 3.22-1.622 1.28 0 2.262.43 2.942 1.29.39.495.64 1.12.751 1.874Zm-3.916 2.65c-.554 0-.994.232-1.321.696-.295.411-.443.902-.443 1.471 0 .565.15 1.058.45 1.48.328.469.776.704 1.346.704.553 0 .999-.224 1.336-.673.317-.422.475-.92.475-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-96-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr96Negative;
