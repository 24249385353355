import type { SVGProps } from "react";
const SvgSuitcaseDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 3.01h6v2.999h6V20h-1V7.009H9.498L6.998 7l.004-1L9 6.007V3.01Zm5 1v1.999h-4v-2h4ZM3 6.017 1.99 5.01l.707-.707 17.005 16.994-.707.707L16.99 20H3V6.018Zm1 1V19h11.99L4 7.018Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseDisabledSmall;
