import type { SVGProps } from "react";
const SvgListSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 6h2V5H4v1Zm5.002 0h10V5h-10v1Zm10 4h-10V9h10v1ZM4 10h2V9H4v1Zm15.002 8h-10v-1h10v1ZM4 18h2v-1H4v1Zm15.002-4h-10v-1h10v1ZM4 14h2v-1H4v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgListSmall;
