import type { SVGProps } from "react";
const SvgEnvelopeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.5 10H5v28h38V10H5.5Zm.5 1.707v24.586L18.293 24 6 11.707ZM6.707 37h34.586L29 24.707l-4.646 4.647-.354.353-.354-.353L19 24.707 6.707 37ZM42 36.293V11.707L29.707 24 42 36.293ZM41.293 11H6.707L24 28.293 41.293 11Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeLarge;
