import type { SVGProps } from "react";
const SvgFiveCirclesInterconnectedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 0 0-1.354 2.147l-5 5a1.5 1.5 0 1 0 0 2.708l5 4.998a1.5 1.5 0 1 0 2.708 0l5-5a1.5 1.5 0 1 0 0-2.708l-5-4.998A1.5 1.5 0 0 0 12.5 4Zm5.585 9H14.95A2.503 2.503 0 0 1 13 14.95v3.135c.05.018.099.038.147.061l5-5a1.486 1.486 0 0 1-.062-.146Zm0-1H14.95A2.503 2.503 0 0 0 13 10.05V6.915c.05-.018.1-.038.147-.061l5 5a1.474 1.474 0 0 0-.062.146Zm-8.035 1c.199.98.97 1.75 1.95 1.95v3.136c-.05.017-.1.038-.147.06l-5-5a1.2 1.2 0 0 0 .062-.147h3.135Zm-3.196-1.147a1.5 1.5 0 0 1 .06.146h3.136c.2-.979.97-1.75 1.95-1.949V6.915c-.05-.018-.1-.038-.147-.061l-5 5ZM12.5 11a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm7 1a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM12 19.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM5.5 12a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFiveCirclesInterconnectedSmall;
