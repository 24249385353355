import type { SVGProps } from "react";
const SvgTrainLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M32 5H16v1h3.732l3.334 5h-7.374C12.567 11 10 13.444 10 16.5V36h5.139l-1.143 2H12v1h1.424l-1.714 3H10v1h28v-1h-1.71l-1.714-3H36v-1h-1.996l-1.142-2H38V16.5c0-3.055-2.566-5.5-5.692-5.5h-7.374l3.334-5H32V5Zm-4.934 1L24 10.598 20.934 6h6.132ZM20 14v1h8v-1h-8Zm-4 14a2 2 0 1 0 .001 4A2 2 0 0 0 16 28Zm-1 2a1 1 0 1 1 2.001 0A1 1 0 0 1 15 30Zm-.424 9-1.715 3h22.277l-1.714-3H14.576Zm17.134-3 1.142 2H15.148l1.142-2h15.42ZM30 30a2 2 0 1 1 4.001 0A2 2 0 0 1 30 30Zm2-1a1 1 0 1 0 .001 2A1 1 0 0 0 32 29Zm-21 6V25h26v10H11Zm26-11H11v-7.5c0-2.468 2.083-4.5 4.692-4.5h16.616C34.918 12 37 14.031 37 16.5V24Zm-10.834 5.5L24.722 28h1.083l1.806 2-1.806 2h-1.083l1.444-1.5H24.45V32h-.902v-1.5h-1.715l1.445 1.5h-1.083l-1.806-2 1.806-2h1.083l-1.445 1.5h1.715V28h.902v1.5h1.715Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainLarge;
