import type { SVGProps } from "react";
const SvgCableCarProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.301 11.72 14.2 8.997l-4.598 8.49H5.43a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h25.5a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2h-4.18L21.89 7.075 33.302 4.22l-.242-.97-30 7.5.242.97Zm17.6-4.4-5.386 1.347-4.776 8.819h14.906L20.901 7.32ZM5.431 18.487a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h25.5a1 1 0 0 0 1-1v-12a1 1 0 0 0-1-1H5.43Zm1 2h5.5v7h-5.5v-7Zm1 1v5h3.5v-5h-3.5Zm8.5-1h-.5v7h5.5v-7h-5Zm.5 6v-5h3.5v5h-3.5Zm8-6h5.5v7h-5.5v-7Zm1 1v5h3.5v-5h-3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCableCarProfileMedium;
