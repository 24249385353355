import type { SVGProps } from "react";
const SvgDownloadLargeDataMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.75 13.043 2.897-2.897.707.707-3.75 3.75-.354.354-.354-.354-3.754-3.762.708-.707 2.9 2.907V4.5h1v8.543ZM5.25 9.25h-.5v17.5h25V9.25H22.5v1h6.25v15.5h-23v-15.5H12v-1H5.25Zm2.25 8.5H27v-1H7.5v1ZM27 20.741H7.5v-1H27v1Zm-19.5 3.01H27v-1H7.5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDownloadLargeDataMedium;
