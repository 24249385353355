import type { SVGProps } from "react";
const SvgKeySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 4C9.77 4 8 5.77 8 8s1.77 4 4 4 4-1.77 4-4-1.77-4-4-4ZM7 8c0-2.783 2.217-5 5-5s5 2.217 5 5a4.977 4.977 0 0 1-2 4.014v6.993l-.146.146-2.5 2.5-.354.354-.354-.354-2.5-2.5L9 19.008v-6.994A4.977 4.977 0 0 1 7 8Zm3 4.591v6.002l2 2 2-2V17h-2.007v-1H14v-1h-2v-1h2v-1.409A5.04 5.04 0 0 1 12 13a5.04 5.04 0 0 1-2-.409Zm2.005-5.59C11.416 7 11 7.42 11 7.976 11 8.553 11.436 9 12.005 9 12.57 9 13 8.558 13 7.977c0-.56-.41-.977-.995-.977ZM10 7.976C10 6.851 10.882 6 12.005 6 13.132 6 14 6.855 14 7.977 14 9.08 13.152 10 12.005 10 10.862 10 10 9.083 10 7.977Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgKeySmall;
