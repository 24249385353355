import type { SVGProps } from "react";
const SvgSpeechBubbleExclamationPointSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 6h17v12H9.957l-2.103 2.104-.854.853V18H4V6Zm1 1v10h3v1.543l1.396-1.397.147-.146H20V7H5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path d="M12.5 8v5m0 1.5V16" stroke="currentColor" />
  </svg>
);
export default SvgSpeechBubbleExclamationPointSmall;
