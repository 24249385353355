import type { SVGProps } from "react";
const SvgEyeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 6C9.708 6 7.685 7.154 6.157 8.41a17.663 17.663 0 0 0-3.066 3.303l-.198.282.193.285c.392.58 1.486 1.994 3.031 3.268C7.656 16.818 9.707 18 12 18c2.292 0 4.315-1.153 5.843-2.41a17.667 17.667 0 0 0 3.066-3.302l.198-.282-.193-.286c-.392-.58-1.486-1.994-3.031-3.268C16.344 7.183 14.294 6 12 6Zm-5.247 8.777a16.426 16.426 0 0 1-2.637-2.774 16.72 16.72 0 0 1 2.677-2.82C8.24 7.992 10.042 7 12 7c1.956 0 3.784 1.017 5.247 2.223a16.425 16.425 0 0 1 2.638 2.774c-.499.667-1.437 1.8-2.678 2.82C15.76 16.01 13.958 17 12 17c-1.956 0-3.784-1.017-5.247-2.223ZM12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-3 4a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEyeSmall;
