import type { SVGProps } from "react";
const SvgWheelchairUncertainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.019 5.378c1.153-.566 2.573-.5 3.725.061l.008.004.008.005c.747.398 1.41 1.126 1.622 2.065.218.961-.055 2.054-1.01 3.12l-.009.01-.01.01-1.488 1.49c-.433.492-.632.85-.737 1.23-.111.404-.128.87-.128 1.627h-1c0-.74.012-1.339.164-1.893.16-.576.458-1.067.96-1.638l.011-.012.011-.01 1.49-1.49c.774-.87.903-1.638.77-2.223-.137-.607-.577-1.113-1.108-1.4-.91-.44-1.998-.471-2.839-.059C15.65 6.673 15 7.517 15 9h-1c0-1.825.832-3.04 2.019-3.622ZM6 13.5V8h1v5h5v6h-1v-5H6v-.5Zm-4 1c0-1.748 1.01-3.27 2.471-4.013l.454.892A3.513 3.513 0 0 0 3 14.5C3 16.423 4.577 18 6.5 18c1.39 0 2.604-.827 3.167-2.018l.904.427A4.513 4.513 0 0 1 6.5 19 4.514 4.514 0 0 1 2 14.5Zm15.045 2.498v2h1v-2h-1ZM6.5 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairUncertainSmall;
