import type { SVGProps } from "react";
const SvgSaSb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.302 3.133c1.019 0 2.08.291 2.9.698l.66-2.23C10.722 1.155 9.462 1 8.241 1 5.56 1 3.04 2.24 3.04 5.092c0 1.978 1.433 2.755 2.726 3.456 1.065.577 2.036 1.103 2.036 2.206 0 1.454-1.321 2.114-2.662 2.114-1.2 0-2.34-.447-3.42-.932L1 14.244c1.26.446 2.64.756 3.98.756 3.061 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.776-1.009-.577-1.896-1.084-1.896-2.022 0-1.163 1.34-1.551 2.341-1.551Zm9.56 3.568H16.76l.74-3.335h.98c1.06 0 2.342.058 2.342 1.357 0 1.59-1.541 1.978-2.961 1.978Zm-1.421 5.934h-.96l.84-3.917h.9c1.5 0 2.86.213 2.86 1.63 0 2.093-1.92 2.287-3.64 2.287ZM19.4 1.233h-4.14l-2.92 13.534h3.982c2.08 0 6.56-.31 6.56-4.188 0-1.454-.68-2.617-2.24-2.85V7.69c1.62-.252 2.98-1.571 2.98-3.219 0-2.463-2.04-3.238-4.22-3.238Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSb;
