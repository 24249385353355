import type { SVGProps } from "react";
const SvgLocomotiveSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 3.999h10v-1H2v1Zm0 1h5v12.5h2V5h2.498c1.685-.016 2.964.26 3.975.835 1.017.577 1.71 1.425 2.277 2.454l.005.01.005.01 3.563 7.186.01.019.007.019c.404 1.018.58 1.897.495 2.646a2.51 2.51 0 0 1-1.049 1.807c-.573.419-1.336.666-2.21.814-.881.148-1.919.202-3.076.201H9V18.5H7V21H2v-1h4V6H2V5Zm9 4h4.309l.138.277 3 6 .362.724H11V9Zm1 1v5h5.191l-2.5-5H12ZM11.505 6c1.578-.015 2.666.245 3.475.704.801.456 1.38 1.135 1.889 2.058l3.549 7.159c.37.935.482 1.63.423 2.143a1.512 1.512 0 0 1-.645 1.115c-.393.287-.98.499-1.787.635-.8.135-1.775.188-2.909.187H10V6h1.505Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveSmall;
