import type { SVGProps } from "react";
const SvgPetrolStationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 4.5A1.5 1.5 0 0 1 7.5 3h7A1.5 1.5 0 0 1 16 4.5V21H6V4.5ZM5 21V4.5A2.5 2.5 0 0 1 7.5 2h7A2.5 2.5 0 0 1 17 4.5V14c.576 0 1.096.143 1.47.52.372.375.517.896.53 1.469V17c0 .278.007.553.09.758.036.09.078.142.122.173.041.03.123.069.288.069.17 0 .252-.04.294-.068a.353.353 0 0 0 .12-.167c.082-.2.088-.473.086-.762V8.18l-2.384-2.86.768-.641 2.5 3 .116.139v9.2c.002.258.005.72-.16 1.123-.089.22-.235.444-.474.61-.24.167-.533.248-.866.248a1.47 1.47 0 0 1-.868-.254 1.365 1.365 0 0 1-.47-.615C18 17.731 18 17.276 18 17.03v-1.023c-.01-.431-.117-.658-.24-.781-.12-.121-.338-.225-.76-.225v6h1v1H4v-1h1ZM7 5h8v5H7V5Zm1 1v3h6V6H8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPetrolStationSmall;
