import type { SVGProps } from "react";
const SvgCarProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.928 10.591c.19-.217.466-.342.753-.342l-.753.342Zm0 0L4.852 15.25h8.898v-5.001H9.681m5.069 0v5h11.043l-4.707-4.705a1.001 1.001 0 0 0-.708-.295H14.75Zm12.25 6H4.252a.993.993 0 0 0-.002.064v6.436h2.035a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.464 3h8.071a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.465 3h3.535l-.003-5.497c0-.406-.165-.63-.358-.771a1.288 1.288 0 0 0-.656-.229L27 16.249Zm1.215 7.5a3.502 3.502 0 0 1-3.465 3 3.502 3.502 0 0 1-3.465-3h-8.07a3.502 3.502 0 0 1-3.465 3 3.502 3.502 0 0 1-3.465-3H3.25v-7.436c0-.484.176-.952.496-1.318l4.43-5.062a2 2 0 0 1 1.505-.684h10.697a2 2 0 0 1 1.415.587l5.414 5.413 3.537.003h.02c.326.013.804.121 1.214.42.433.317.769.833.769 1.58l.003 5.997v.5h-4.535Zm-18.465-3a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Zm15 0a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileMedium;
