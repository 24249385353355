import type { SVGProps } from "react";
const SvgTabletLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.5 5.5h23a2 2 0 0 1 2 2V10h-27V7.5a2 2 0 0 1 2-2Zm-3 5.5V7.5a3 3 0 0 1 3-3h23a3 3 0 0 1 3 3v34a3 3 0 0 1-3 3h-23a3 3 0 0 1-3-3V11Zm28 0v27h-27V11h27Zm-27 30.5V39h27v2.5a2 2 0 0 1-2 2h-23a2 2 0 0 1-2-2ZM22 42h4v-1h-4v1Zm0-34h6V7h-6v1Zm-1 0h-1V7h1v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTabletLarge;
