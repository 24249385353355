import type { SVGProps } from "react";
const SvgSaRr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1 1h13.905v14H1V1Zm1.264 12.727H13.64V2.273H2.264v11.454ZM5.425 3.545h2.761c.845 0 1.494.2 1.953.599.458.4.685.968.685 1.705 0 1.188-.575 1.899-1.724 2.136.264.062.463.168.594.322.133.154.26.404.38.753l1.175 3.394H9.536L8.648 9.55c-.191-.623-.596-.935-1.217-.935h-.389v3.84H5.425v-8.91Zm1.723 3.78h.565c.458 0 .81-.12 1.06-.362.249-.24.374-.577.374-1.006 0-.462-.109-.785-.325-.976-.215-.19-.578-.285-1.09-.285h-.69v2.627h.106v.001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRr;
