import type { SVGProps } from "react";
const SvgShareLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M30 9.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM34.5 4a5.5 5.5 0 0 0-4.773 8.235l-15.031 8.709a5.5 5.5 0 1 0 0 7.113l15.031 8.708a5.5 5.5 0 1 0 .577-.821l-15.031-8.709c.463-.806.727-1.74.727-2.735 0-.996-.265-1.93-.727-2.735l15.031-8.709A5.5 5.5 0 1 0 34.5 4Zm-24 16a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm24 15a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShareLarge;
