import type { SVGProps } from "react";
const SvgTwoUsersMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.139 6.793c-.61.58-1.025 1.412-1.119 2.487 2.573.3 4.48 2.812 4.48 5.72 0 .071-.001.143-.003.214C23.463 14.926 25 12.954 25 10.5c0-2.658-1.803-4.75-4-4.75-1.167 0-2.162.376-2.861 1.043Zm-.69-.724c-.822.783-1.334 1.877-1.432 3.207-2.59.28-4.517 2.802-4.517 5.724 0 1.451.475 2.803 1.271 3.832-4.198 1.992-6.52 7.091-6.52 11.917v.5h20.5v-.5c0-1.338-.181-2.695-.532-4h5.031v-.5c0-4.832-2.406-9.829-6.596-11.813C25.502 13.387 26 11.983 26 10.5c0-3.043-2.097-5.75-5-5.75-1.382 0-2.638.448-3.551 1.319Zm6.454 9.127a4.543 4.543 0 0 1-2.52 1.038 6.122 6.122 0 0 1-1.18 2.631c2.731 1.295 4.682 3.895 5.71 6.885h4.328c-.164-4.534-2.544-8.952-6.338-10.554ZM16.5 10.25c-2.14 0-4 2.051-4 4.75 0 2.698 1.86 4.75 4 4.75 2.14 0 4-2.052 4-4.75 0-2.699-1.86-4.75-4-4.75Zm-3.003 9.352c-3.777 1.607-6.08 6.102-6.238 10.647h18.483c-.16-4.54-2.492-9.012-6.274-10.617-.822.699-1.842 1.118-2.968 1.118-1.141 0-2.175-.431-3.003-1.148Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTwoUsersMedium;
