import type { SVGProps } from "react";
const SvgGpsDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.256 7.761A10.96 10.96 0 0 0 12.7 9.558l.55.835a9.967 9.967 0 0 1 5.5-1.643 9.98 9.98 0 0 1 10 10 9.968 9.968 0 0 1-1.643 5.5l.835.549a10.96 10.96 0 0 0 1.797-5.555h3.26v-1h-3.26A10.977 10.977 0 0 0 19.255 7.762V4.5h-1v3.261ZM4.5 18.251h3.262a10.958 10.958 0 0 1 2.858-6.923L7.26 7.967l.706-.707 22.275 22.273-.707.707-3.36-3.36a10.964 10.964 0 0 1-6.918 2.859v3.26h-1v-3.26A10.976 10.976 0 0 1 7.762 19.25H4.5v-1Zm20.966 7.921-2.486-2.486a6.5 6.5 0 0 1-9.166-9.165l-2.486-2.485a9.965 9.965 0 0 0-2.577 6.714c0 5.54 4.46 10 10 10a9.97 9.97 0 0 0 6.715-2.578ZM14.523 15.23a5.5 5.5 0 0 0 7.747 7.746l-7.747-7.746Zm4.228-1.98c-.803 0-1.563.181-2.256.495l-.412-.911a6.45 6.45 0 0 1 2.668-.583 6.5 6.5 0 0 1 6.5 6.5 6.45 6.45 0 0 1-.585 2.666l-.911-.412a5.445 5.445 0 0 0 .496-2.254 5.5 5.5 0 0 0-5.5-5.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGpsDisabledMedium;
