import type { SVGProps } from "react";
const SvgBluetoothMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m16.75 3.378.834.75 7.5 6.75.413.372-.413.372L17.997 18l7.087 6.379.413.371-.413.372-7.5 6.75-.834.751v-13.5l-6.665 5.999-.67-.743L16.504 18l-7.087-6.378.668-.743 6.666 5.999v-13.5Zm1 15.745 6.253 5.627-6.253 5.628V19.123Zm0-2.245V5.623l6.253 5.627-6.253 5.628Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothMedium;
