import type { SVGProps } from "react";
const SvgSaTc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.021 3.133c1.5 0 2.56.37 3.201.795l.7-2.268c-1.22-.486-2.7-.66-4.1-.66-4.942 0-8.003 3.219-8.003 7.582 0 3.664 2.02 6.418 6.242 6.418 1.06 0 2.14-.098 3.16-.368l.44-2.599c-.8.465-1.76.835-2.94.835-2.46 0-3.98-2.017-3.98-4.286 0-3.18 2.18-5.449 5.28-5.449Zm-7.76-1.9H1.479L1 3.366h3.94L2.4 14.767h2.92L7.861 3.366h3.94l.46-2.133Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaTc;
