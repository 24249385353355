import type { SVGProps } from "react";
const SvgSaFw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={34}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.642 1H3.94L1 15h2.8l1.281-6.137h4.68l.441-2.207H5.541l.74-3.45h4.881L11.642 1Zm3.68 0H12.52l.88 14h3.461l4.921-11.192h.04L21.962 15h3.5l6.662-14h-2.9l-4.94 10.73h-.04L24.181 1h-3.76l-4.64 10.73h-.04L15.321 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaFw;
