import type { SVGProps } from "react";
const SvgHandCursorMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#hand-cursor-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M13.75 8.25c0-1.101.899-2 2-2s2 .899 2 2v5.77c.295-.172.636-.27 1-.27 1.026 0 1.876.78 1.988 1.777a1.985 1.985 0 0 1 1.012-.277c1.026 0 1.876.78 1.988 1.776a1.985 1.985 0 0 1 1.012-.276c1.101 0 2 .899 2 2V24c0 2.257-.345 3.992-.696 5.171a11.55 11.55 0 0 1-.485 1.346 6.96 6.96 0 0 1-.214.444l-.015.026-.004.008-.002.003v.001l-.434-.249.433.25-.144.25H13.965l-.145-.245.43-.255-.43.254-.001-.001-.003-.005-.011-.018-.043-.073-.162-.275-.585-.988-1.804-3.053a1241.697 1241.697 0 0 1-2.96-5.027c-.25-.437-.44-.905-.46-1.377-.02-.498.149-.964.54-1.356.78-.78 2.057-.78 2.838 0l2.581 2.582V8.25Zm9 10.5v.75h1v-.76c.006-.545.455-.99 1-.99.549 0 1 .451 1 1V24c0 2.153-.33 3.793-.654 4.886a10.552 10.552 0 0 1-.504 1.364H14.535l-.075-.127-.584-.988-1.803-3.053c-1.335-2.26-2.724-4.614-2.954-5.016-.214-.373-.318-.678-.328-.921a.76.76 0 0 1 .248-.606 1.01 1.01 0 0 1 1.422 0l3.435 3.435.854.853V8.25c0-.549.451-1 1-1 .549 0 1 .451 1 1V19.5h1v-3.75c0-.549.451-1 1-1 .549 0 1 .451 1 1v3.75h1v-2.25c0-.549.451-1 1-1 .549 0 1 .451 1 1v1.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="hand-cursor-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandCursorMedium;
