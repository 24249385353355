import type { SVGProps } from "react";
const SvgChartPieLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.434 3.027c.188-.01.377-.017.566-.021v20.783l.25.144L41 34.181C37.4 40.071 30.909 44 23.5 44 12.178 44 3 34.822 3 23.5 3 12.536 11.608 3.581 22.434 3.027ZM44 23.5c0 3.556-.906 6.9-2.498 9.816L24.5 23.5l17.002-9.816A20.41 20.41 0 0 1 44 23.5Zm-3-10.681c-3.52-5.756-9.8-9.64-17-9.813v19.628l17-9.815ZM22.384 2.029c.37-.02.742-.029 1.117-.029C35.374 2 45 11.626 45 23.5S35.374 45 23.5 45 2 35.374 2 23.5C2 12 11.028 2.61 22.383 2.029Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartPieLarge;
