import type { SVGProps } from "react";
const SvgChevronSmallUpMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.994 13.876.356.36 5.856 5.924-.711.703-5.5-5.565-5.501 5.564-.711-.703 5.856-5.924.355-.36Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallUpMedium;
