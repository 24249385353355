import type { SVGProps } from "react";
const SvgBarrierConstructionSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 3.5a.5.5 0 1 1 1 0V5H6V3.5ZM5 5V3.5a1.5 1.5 0 1 1 3 0V5h8V3.5a1.5 1.5 0 0 1 3 0V5h3v5h-3v3.001h3v5h-3V22h-3v-3.999H8V22H5v-3.999H2v-5h3V10H2V5h3Zm13-1.5V5h-1V3.5a.5.5 0 1 1 1 0Zm0 9.501V10h-1v3.001h1ZM8 10h8v3.001H8V10Zm-2 3.001V10h1v3.001H6ZM3 6v3h2.293l3-3H3Zm6.707 0-3 3h7.586l3-3H9.707ZM21 9h-5.293l3-3H21v3Zm-3 9.001V21h-1v-2.999h1Zm-12 0V21h1v-2.999H6Zm-3-4v2.292L5.292 14H3Zm6.292 3H3.706l3-3h5.586l-3 3Zm4.414-3-3 3h5.586l3-3h-5.586Zm7 0-3 3H21v-3h-.294Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBarrierConstructionSmall;
