import type { SVGProps } from "react";
const SvgWagonCouplingDraggingEquipmentSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14 4H2V3h13v9h3v-2h1v5h-1v-2h-1v2.605l2.328 2.017-.655.756-2.5-2.167-.173-.15V13h-1v3h-4v.75A4.367 4.367 0 0 1 9.579 20h1.736l3.36-2.88.325-.279.325.28L18.685 20H22v1H2v-1h2.421A4.367 4.367 0 0 1 3 16.75V16H2v-1h12V4Zm1 14.159L17.148 20h-4.296L15 18.159ZM7 20c1.625 0 3-1.422 3-3.25V16H4v.75C4 18.578 5.375 20 7 20Zm13-5v-5h1v2h1v1h-1v2h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWagonCouplingDraggingEquipmentSmall;
