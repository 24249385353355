import type { SVGProps } from "react";
const SvgChevronSmallRightCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.75 18c0-5.937 4.813-10.75 10.75-10.75 5.938 0 10.75 4.813 10.75 10.75 0 5.938-4.812 10.75-10.75 10.75-5.937 0-10.75-4.812-10.75-10.75ZM19.5 6.25C13.01 6.25 7.75 11.51 7.75 18s5.26 11.75 11.75 11.75S31.25 24.49 31.25 18 25.99 6.25 19.5 6.25Zm3.764 11.394-5.923-5.856-.703.712 5.564 5.5-5.566 5.5.703.712 5.925-5.856.36-.356-.36-.356Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallRightCircleMedium;
