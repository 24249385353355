import type { SVGProps } from "react";
const SvgDocumentXlsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.263 6.25h9.694l.146.147 7.5 7.5.147.146v15.708H9.25v-.5l.013-22.5v-.5Zm1 1-.013 21.5h15.5v-14h-7.5v-7.5h-7.987Zm8.987.708 5.793 5.793H19.25V7.958Zm-4.536 16.691 1.446-2.145h-1.09l-.903 1.443-.893-1.443h-1.15l1.464 2.145L12 27.001h1.123l1.002-1.563.984 1.563h1.182l-1.578-2.352Zm9.292-.812h-.936c-.018-.219-.1-.387-.245-.504-.144-.117-.348-.172-.607-.172-.233 0-.413.043-.54.135a.444.444 0 0 0-.192.384c0 .148.061.253.184.318.119.067.422.159.905.279.511.126.864.255 1.062.384.361.237.543.568.543.993 0 .465-.182.832-.548 1.1-.32.24-.757.356-1.306.356-.61 0-1.086-.14-1.424-.42-.339-.277-.514-.676-.526-1.19h.976c.015.25.08.434.195.557.18.194.452.288.815.288.246 0 .44-.046.585-.144a.52.52 0 0 0 .243-.451c0-.19-.114-.33-.34-.419-.125-.05-.422-.138-.887-.267a5.493 5.493 0 0 1-.683-.228 1.523 1.523 0 0 1-.394-.24c-.23-.205-.345-.489-.345-.85 0-.43.18-.773.54-1.025.304-.217.702-.325 1.19-.325.428 0 .787.085 1.076.255.416.246.635.639.659 1.186Zm-4.081 3.165v-.831h-2.2v-3.666h-.982v4.497h3.182Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentXlsMedium;
