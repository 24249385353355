import type { SVGProps } from "react";
const SvgUserChangeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 3C5.738 3 5 3.815 5 5c0 .406.09.785.248 1.1v.001c.285.576.753.9 1.252.9s.966-.324 1.252-.902M4 5c0-1.49.968-3 2.5-3S9 3.51 9 5c0 .463-.088.912-.25 1.315 1.751 1.07 2.253 3.157 2.253 5.189v.5h-9v-.5c0-2.022.492-4.111 2.249-5.182A3.531 3.531 0 0 1 4 5Zm.772 2.177c-1.183.728-1.68 2.124-1.758 3.827h6.978c-.079-1.708-.581-3.105-1.76-3.832C7.802 7.667 7.207 8 6.5 8c-.704 0-1.299-.33-1.728-.823ZM7.752 6.1A2.49 2.49 0 0 0 8 5c0-1.185-.738-2-1.5-2m10.06 1.272c.902 1.206 1.43 2.839 1.471 4.802L19.65 7.48l.702.712-2.506 2.471-.351.347-.352-.347-2.493-2.465.702-.711 1.681 1.661c-.029-1.821-.512-3.26-1.273-4.276-.904-1.208-2.226-1.861-3.765-1.856l-.004-1c1.85-.006 3.472.79 4.57 2.257ZM17.5 13c-.762 0-1.5.815-1.5 2 0 .476.128.917.338 1.262.291.476.712.738 1.162.738.45 0 .871-.262 1.162-.738.21-.345.338-.786.338-1.262 0-1.185-.738-2-1.5-2Zm1.703 4.204c-.427.477-1.014.796-1.703.796-.69 0-1.276-.32-1.703-.795-1.051.825-1.667 2.264-1.777 3.798h6.966c-.11-1.54-.73-2.976-1.783-3.799Zm-3.937-.852A3.528 3.528 0 0 1 15 15c0-1.49.968-3 2.5-3s2.5 1.51 2.5 3c0 .477-.093.939-.265 1.352 1.542 1.145 2.269 3.201 2.269 5.151v.5h-9.002v-.5c0-1.94.721-4.002 2.264-5.15ZM6.5 12.966l.35.346 2.5 2.464-.701.713-1.687-1.664c.029 1.826.513 3.275 1.274 4.302.905 1.22 2.228 1.88 3.764 1.875l.004 1c-1.852.006-3.475-.802-4.57-2.28-.902-1.214-1.43-2.854-1.471-4.82L4.35 16.494l-.702-.711 2.5-2.47.35-.347Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserChangeSmall;
