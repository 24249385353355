import type { SVGProps } from "react";
const SvgControlsXSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.5 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM2 7h4.05a2.5 2.5 0 0 0 4.9 0H22V6H10.95a2.5 2.5 0 0 0-4.9 0H2v1Zm11.048 5c.224-1.157 1.218-2 2.452-2s2.228.843 2.452 2H22v1h-5v-.5c0-.854-.646-1.5-1.5-1.5s-1.5.646-1.5 1.5v.5H2v-1h11.048ZM7 18.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8.5 16a2.5 2.5 0 0 0-2.45 2H2v1h4.05a2.5 2.5 0 0 0 4.9 0h2.07v-1h-2.07a2.5 2.5 0 0 0-2.45-2Zm10 3.207-3.146 3.147-.708-.708 3.147-3.146-3.147-3.146.708-.708 3.146 3.147 3.146-3.147.708.708-3.147 3.146 3.147 3.146-.708.708-3.146-3.147Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgControlsXSmall;
