import type { SVGProps } from "react";
const SvgWalletLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M35.228 5.03 13.763 15h26.003l-3.803-9.664-.008-.018-.005-.019c-.055-.178-.325-.38-.722-.269ZM20 11l-8.612 4H4v-4h16.001Zm2.153-1H3v32h42V10h-6.127L36.9 4.986c-.27-.835-1.213-1.15-2-.902l-.032.01-.03.014L22.155 10Zm17.112 1 1.575 4H44v-4h-4.734ZM44 16H4v25h40v-6H26.5a6.5 6.5 0 1 1 0-13H44v-6ZM26.5 34H44V23H26.5a5.5 5.5 0 1 0 0 11Zm0-7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM24 28.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalletLarge;
