import type { SVGProps } from "react";
const SvgAirplaneTrainLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.5 5h-.579l.084.573L15.362 8H14c-1.106 0-2 .894-2 2 0 1.106.894 2 2 2h1.95l.295 2H15c-1.106 0-2 .894-2 2 0 1.106.894 2 2 2h1.833l.588 4h-7.144l-2.353-3.765L7.777 18H2.215l.332.711L5.948 26l-3.401 7.289-.332.711h5.562l.147-.235L10.277 30h7.14l-.911 5.924.988.152 1-6.5.089-.576h-8.86l-.147.235L7.223 33H3.785l3.168-6.789.099-.211-.099-.211L3.785 19h3.438l2.353 3.765.147.235h8.856l-.084-.573L16.079 6h4.555l2.89 9.15.953-.3-3-9.5-.11-.35H15.5Zm1.186 12-.294-2H15c-.554 0-1 .446-1 1s.446 1 1 1h1.686ZM14 9h1.51l.293 2H14c-.554 0-1-.446-1-1s.446-1 1-1Zm14.473 1.162-11 32-.946-.325 11-32 .946.325Zm-.207 4.88c6.203 3.288 11.58 7.98 15.734 13.622v1.503l-5.395-1.156-.512-.11-.086.517L37.41 33h-7.081a4.481 4.481 0 0 0-2.829-1 4.481 4.481 0 0 0-2.829 1H22v1h1.758A4.478 4.478 0 0 0 23 36.5c0 .526.09 1.03.256 1.5H20v1h3.758a4.496 4.496 0 0 0 3.742 2c1.56 0 2.935-.794 3.742-2h11.323l-.68-.82-1.81-2.18H42.5c1.622 0 2.536-.548 3.025-1.2a2.428 2.428 0 0 0 .466-1.16 1.553 1.553 0 0 0 .009-.122V33.5l-.5-.001h.5v-2.904l-.395-.085-.605-.13v-2.044l-.096-.132c-4.254-5.82-9.781-10.66-16.17-14.047l-.468.884ZM38.776 36H36.91l.097-.582.236-1.418h-6c.478.715.757 1.575.757 2.5 0 .526-.09 1.03-.256 1.5h8.691l-1.66-2ZM45 31.404v2.094a1.18 1.18 0 0 1-.032.194 1.43 1.43 0 0 1-.243.508c-.261.348-.847.8-2.225.8h-4.41l.817-4.901L45 31.404ZM28.738 17.061l-.524-.285-.188.566-2 6-.22.658H37.7l-.845-.852a34.767 34.767 0 0 0-8.117-6.087ZM27.194 23l1.59-4.771A33.746 33.746 0 0 1 35.27 23h-8.075Zm.306 10a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAirplaneTrainLarge;
