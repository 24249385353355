import type { SVGProps } from "react";
const SvgGiftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.001 4.718a3.073 3.073 0 0 0-1.3-1.2c-.805-.383-1.707-.355-2.475-.045s-1.435.915-1.757 1.73c-.327.827-.278 1.817.295 2.841l.015.028.019.025 1.96 2.653H4.75v5.5h1.5v16.5h23.5v-16.5h1.5v-5.5H21.241l1.961-2.652.017-.023.014-.024c.584-1.009.639-1.992.31-2.817-.322-.81-.991-1.413-1.76-1.725-.768-.312-1.672-.348-2.48.027a3.032 3.032 0 0 0-1.302 1.182Zm-.5 1.35c-.258-.91-.733-1.41-1.23-1.647-.52-.247-1.127-.24-1.67-.02-.545.22-.992.638-1.202 1.169-.202.512-.205 1.179.223 1.96l2.38 3.22h1.493l.005-4.682Zm1 0-.006 4.682h1.503l2.383-3.224c.434-.762.434-1.417.233-1.922-.208-.525-.657-.945-1.207-1.168-.549-.223-1.161-.235-1.682.007-.498.232-.972.723-1.225 1.625ZM22.25 16.25v15.5h6.5v-15.5h-6.5Zm-1 15.5h-6.5v-15.5h6.5v15.5Zm1-16.5v-3.5h8v3.5h-8Zm-1 0v-3.5h-6.5v3.5h6.5Zm-7.5 0v-3.5h-8v3.5h8Zm0 16.5v-15.5h-6.5v15.5h6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGiftMedium;
