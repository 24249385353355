import type { SVGProps } from "react";
const SvgSaGz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.462 3.133c1.46 0 2.801.291 3.881.97l.7-2.347C12.582 1.213 10.943 1 9.382 1 4.341 1 1 4.044 1 8.99 1 13.176 3.74 15 7.842 15c1.359 0 2.92-.271 3.74-.601l1.601-7.388H7.522l-.46 2.133h2.96l-.781 3.587c-.319.136-.74.136-1.379.136-2.381 0-3.941-1.765-3.941-3.955 0-3.2 2.14-5.78 5.541-5.78Zm17.04-1.9H16.92l-.48 2.132h6.101l-8.402 9.27-.44 2.133h9.802l.46-2.133h-6.36l8.421-9.27.48-2.132Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaGz;
