import type { SVGProps } from "react";
const SvgSaVo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.75 4.5c-.79 0-1.53.19-2.2.5l-.8-1.22V2.5H21V1h-3.75v2.25h-5.76l-.39-.75h.9V1H8v1.5h1.41l.71 1.38-1.16 1.4a5.17 5.17 0 0 0-2.72-.78C3.35 4.5.99 6.85.99 9.75S3.35 15 6.24 15c2.64 0 4.81-1.96 5.17-4.5h2.97l3.59-5.16.32.49c-1.09.96-1.8 2.36-1.8 3.92 0 2.9 2.36 5.25 5.25 5.25s5.25-2.35 5.25-5.25-2.36-5.25-5.25-5.25h.01ZM12.77 9h-1.35a5.115 5.115 0 0 0-1.3-2.76l.75-.91L12.76 9h.01ZM9.16 7.41c.37.46.65.99.77 1.59H7.85l1.31-1.59ZM6.25 13.5c-2.07 0-3.75-1.68-3.75-3.75C2.5 7.68 4.18 6 6.25 6c.64 0 1.22.17 1.75.45L4.66 10.5h5.27c-.35 1.71-1.86 3-3.67 3h-.01Zm7.85-5.2-1.83-3.55h4.3L14.1 8.3Zm7.65 5.2c-2.07 0-3.75-1.68-3.75-3.75 0-1.04.43-1.98 1.12-2.66l2.01 3.07 1.26-.82-2-3.07c.43-.17.89-.27 1.37-.27 2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75h-.01Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSaVo;
