import type { SVGProps } from "react";
const SvgEv68 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-68_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.104 2.896h-2.206c-.206-.894-.714-1.341-1.524-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.47.72-.81 1.111-1.016.397-.206.873-.31 1.428-.31 1.09 0 1.966.387 2.627 1.16.63.73.944 1.644.944 2.745 0 1.306-.423 2.356-1.27 3.15-.761.714-1.676 1.07-2.745 1.07-1.37 0-2.436-.515-3.197-1.546-.762-1.037-1.143-2.481-1.143-4.333 0-1.978.407-3.528 1.222-4.65.788-1.084 1.864-1.626 3.23-1.626 1.285 0 2.268.431 2.95 1.293.392.498.644 1.124.755 1.88Zm-3.928 2.658c-.555 0-.997.233-1.325.698-.296.413-.444.905-.444 1.476 0 .566.15 1.06.452 1.484.328.47.777.706 1.349.706.555 0 1.002-.225 1.34-.674.318-.424.477-.923.477-1.5 0-.566-.143-1.05-.429-1.452-.349-.492-.822-.738-1.42-.738Zm6.588-.183c-.54-.185-.955-.489-1.246-.912-.285-.423-.428-.936-.428-1.54 0-1.004.412-1.806 1.238-2.403.73-.53 1.597-.794 2.602-.794 1.143 0 2.076.315 2.801.944.688.593 1.032 1.325 1.032 2.198 0 .609-.146 1.13-.437 1.563-.29.434-.703.749-1.238.944.418.122.76.278 1.024.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.453 2.119-1.357 2.817-.762.587-1.74.88-2.936.88-1.296 0-2.327-.322-3.094-.967-.8-.672-1.198-1.553-1.198-2.642 0-1.021.354-1.812 1.063-2.373.323-.248.68-.423 1.071-.524Zm2.134-3.848c-.508 0-.917.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.318.286.741.428 1.27.428s.947-.142 1.254-.428c.306-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.735-.405-1.286-.405Zm-.016 4.721c-.57 0-1.039.178-1.404.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.849.532 1.436.532.667 0 1.167-.196 1.5-.587.323-.376.484-.81.484-1.301 0-.561-.185-1.021-.556-1.381-.354-.35-.843-.524-1.468-.524Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv68;
