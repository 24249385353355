import type { SVGProps } from "react";
const SvgSaTa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.262 1H1.48L1 3.206h3.94L2.4 15h2.921l2.54-11.794h3.941L12.262 1Zm6.68 8.584h-4.32l3.36-6.097h.08l.88 6.097ZM20.062 1h-3.1L8.5 15h3.1l1.781-3.209h5.941l.48 3.209h2.8l-2.54-14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaTa;
