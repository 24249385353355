import type { SVGProps } from "react";
const SvgCigaretteDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m28.25 27.956 17.396 17.398.707-.708-43-43-.707.708 19.69 19.69H4.045v5.912H28.25Zm-.913-.912-4.088-4.088h-6.293v4.088h10.38Zm7.68-22.687a2.486 2.486 0 0 1 4.562-1.219A3.486 3.486 0 0 1 43.986 6.5c0 1.09-.502 2.057-1.282 2.694.177.558.282 1.163.282 1.806 0 1.197-.339 2.302-.91 3.17.56.613.91 1.426.91 2.33a3.486 3.486 0 1 1-6.972 0c0-.608.16-1.176.433-1.67-.888-.926-1.433-2.31-1.433-3.83 0-.122.006-.24.016-.354A3.47 3.47 0 0 1 33.013 7.5a3.47 3.47 0 0 1 2.005-3.142ZM37.5 2.985c-.836 0-1.514.678-1.514 1.514l.003.028.002.019.003.024.008.064.034.378-.358.125a2.505 2.505 0 0 0-1.692 2.362c0 1.105.72 2.037 1.72 2.372l.375.125-.046.392-.002.015c-.025.22-.047.406-.047.597 0 1.446.578 2.68 1.4 3.381l.342.291-.262.364c-.301.416-.48.919-.48 1.464a2.514 2.514 0 1 0 5.028 0c0-.762-.348-1.44-.895-1.905l-.36-.306.296-.368c.581-.725.959-1.756.959-2.921 0-.651-.13-1.26-.341-1.81l-.142-.368.331-.215A2.506 2.506 0 0 0 43.014 6.5a2.514 2.514 0 0 0-3.481-2.32l-.449.187-.187-.449a1.514 1.514 0 0 0-1.397-.933ZM13.956 22.956h2.088v4.088h-2.088v-4.088Zm-.912 4.088v-4.088H4.956v4.088h8.087Zm25-4.088H26v-.912h16.5c.804 0 1.456.652 1.456 1.456v3c0 .804-.652 1.456-1.456 1.456H31v-.912h7.044v-4.088Zm.912 0v4.088H42.5c.3 0 .544-.244.544-.544v-3c0-.3-.244-.544-.544-.544h-3.544Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCigaretteDisabledLarge;
