import type { SVGProps } from "react";
const SvgTrainProfileLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.003 10c.105 0 .232.063.368.203L16 9h-4c-.476.001-.848.298-1.051.495a2.857 2.857 0 0 0-.358.424l-.007.01-.002.003v.001h-.001l.419.274.42.272.01-.015a1.854 1.854 0 0 1 .215-.251c.16-.156.288-.212.356-.212h.883l4.34 2.17L15.76 14H2v1h22c1.68 0 3.804.677 6.08 1.83 1.67.847 3.394 1.938 5.048 3.17H33v7h9.308l.006.007c1.391 1.9 2.178 3.64 2.186 4.989-.011.562-.268 1.046-.807 1.404-.558.37-1.44.618-2.686.6H2v1h8.345a3.431 3.431 0 0 0-.31 2H2v1h8.337c.178.374.42.712.714 1H2v1h44v-1H27.95a3.51 3.51 0 0 0 .713-1h9.531l-.22-.659-.78-2.342h3.802c1.365.02 2.469-.246 3.25-.766.8-.532 1.238-1.315 1.254-2.225v-.01c-.007-1.678-.954-3.636-2.38-5.582-1.438-1.964-3.42-3.991-5.618-5.82-2.199-1.828-4.628-3.47-6.97-4.657C28.202 14.757 25.91 14 24 13.999h-6.96l1.35-1.687.387-.483-.553-.277-3.104-1.551h.883ZM36.14 35h-7.485a3.427 3.427 0 0 1 .31 2h7.842l-.667-2ZM11 36.5c0-.563.192-1.08.513-1.5h3.974c.321.42.513.938.513 1.5a2.5 2.5 0 1 1-5 0Zm6 0c0-.54-.125-1.048-.345-1.5h5.69a3.431 3.431 0 0 0-.31 2h-5.07c.023-.164.035-.33.035-.5Zm-.337 1.5h5.674c.178.374.42.712.713 1h-7.1a3.51 3.51 0 0 0 .713-1Zm10.824-3h-3.974A2.462 2.462 0 0 0 23 36.5a2.5 2.5 0 1 0 5 0 2.46 2.46 0 0 0-.513-1.5Zm8.929-14H34v5h7.531c-1.285-1.568-2.898-3.162-4.669-4.634a41.54 41.54 0 0 0-.446-.366ZM20 20h9v7h-9v-7Zm1 1v5h7v-5h-7ZM9.5 20H9v7h9v-7H9.5Zm.5 6v-5h7v5h-7Zm-4-5H2v-1h5v7H2v-1h4v-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainProfileLarge;
