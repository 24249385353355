import type { SVGProps } from "react";
const SvgCustomerAssistanceSbbMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.752 4.25c-1.284 0-2.5 1.344-2.5 3.25 0 1.905 1.216 3.25 2.5 3.25 1.281 0 2.5-1.345 2.5-3.25 0-1.906-1.219-3.25-2.5-3.25Zm-3.5 3.25c0-2.238 1.467-4.25 3.5-4.25 2.03 0 3.5 2.012 3.5 4.25a4.89 4.89 0 0 1-.756 2.638c2.268 1.533 3.756 4.862 3.756 8.61v.5H18.25v-.5c0-3.748 1.49-7.077 3.756-8.61a4.89 4.89 0 0 1-.755-2.638Zm3.5 4.25c.806 0 1.524-.318 2.097-.84 1.853 1.196 3.265 3.958 3.393 7.339H19.26c.128-3.38 1.542-6.142 3.395-7.34.573.523 1.29.84 2.098.84ZM5.248 3.25h-.5v10h13V9.005l1.324-1.124.413-.35-.383-.384-1.354-1.354V3.25H5.25Zm.5 9v-8h11v1.957l.147.147 1.116 1.116-1.086.923-.177.15v3.707h-11ZM15 6.22H7.468v1h7.531v-1Zm-7.531 3H12v1H7.468v-1Zm10.282 21.53c0-3.749-1.488-7.078-3.755-8.61.479-.74.755-1.669.755-2.639 0-2.238-1.47-4.25-3.5-4.25-2.03 0-3.5 2.012-3.5 4.25 0 .97.276 1.898.755 2.638C6.238 23.672 4.75 27 4.75 30.748v.5h13v-.5Zm-1.01-.5c-.127-3.38-1.54-6.143-3.393-7.34-.572.524-1.29.841-2.097.841-.806 0-1.525-.317-2.097-.84-1.853 1.198-3.266 3.959-3.394 7.339h10.982Zm-5.491-14c-1.282 0-2.5 1.345-2.5 3.25 0 1.904 1.218 3.25 2.5 3.25 1.281 0 2.5-1.346 2.5-3.25 0-1.905-1.218-3.25-2.5-3.25ZM27 14.53l-1.5-1.405h1.125L28.5 15l-1.875 1.875H25.5l1.5-1.407h-1.78v1.407h-.938v-1.407H22.5l1.5 1.407h-1.125L21 15l1.875-1.875H24l-1.5 1.406h1.782v-1.406h.938v1.406H27Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCustomerAssistanceSbbMedium;
