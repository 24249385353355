import type { SVGProps } from "react";
const SvgFireplaceLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 4h40v3.175l-.11.137L42 9.675v34.323l-.498.002-35 .152-.502.002V9.675L4.11 7.312 4 7.175V3.999Zm1 1v1.824l1.89 2.363.11.137V43.15l3.984-.017.041-23.634V19H37v.5l-.016 23.52L41 43.002V9.324l.11-.137L43 6.824V5H5Zm30.984 38.024L36 20H12.024l-.04 23.128 4.668-.02a4.417 4.417 0 0 1-.327-.792c-.27-.901-.272-2.114.828-3.175.765-.738.897-1.796.797-2.76a6.735 6.735 0 0 0-.4-1.66l-.009-.022-.001-.004-.429-1.013 1.045.343-.156.476a54.9 54.9 0 0 1 .157-.475h.002l.003.002.012.004.018.006.022.008.14.053a6.98 6.98 0 0 1 1.877 1.128c1.01.857 1.999 2.258 1.766 4.33l-.994-.112c.185-1.643-.577-2.742-1.42-3.456a5.776 5.776 0 0 0-.777-.552c.056.253.106.537.138.84.114 1.087-.004 2.53-1.097 3.583-.757.731-.759 1.518-.564 2.17a3.573 3.573 0 0 0 .542 1.073l12.396-.054c1.04-1.371.667-3.287-.144-5.06a15.694 15.694 0 0 0-1.398-2.413 9.04 9.04 0 0 1-.554 1.23c-.272.488-.644 1.01-1.134 1.292a1.468 1.468 0 0 1-.885.202c-.327-.032-.636-.178-.923-.412-1.044-.855-1.558-2.094-1.83-3.452-.2-1.005-.275-2.114-.317-3.242-.338.439-.57.889-.729 1.281a5.483 5.483 0 0 0-.3 1.02 2.241 2.241 0 0 0-.009.054l-.002.012-.496-.06a58.78 58.78 0 0 1-.496-.06v-.005l.001-.007.004-.024.012-.081a6.484 6.484 0 0 1 .359-1.223 6.208 6.208 0 0 1 1.803-2.487l.79-.646.027 1.019c.042 1.544.084 2.999.334 4.253.25 1.25.693 2.228 1.483 2.873.171.14.3.183.387.192a.473.473 0 0 0 .29-.074c.244-.14.507-.462.756-.91a8.5 8.5 0 0 0 .567-1.304 10.719 10.719 0 0 0 .188-.6l.009-.033.002-.008v-.001l.246-.948.623.755-.385.319.386-.319.001.002.003.004.012.014.017.022.025.03.153.197c.129.17.309.414.515.717.413.605.937 1.45 1.374 2.406.737 1.611 1.308 3.718.416 5.47l4.582-.02ZM37 11H11v-1h26v1Zm-24 2.97h22v-.94H13v.94Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFireplaceLarge;
