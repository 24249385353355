import type { SVGProps } from "react";
const SvgAirplaneTrainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.434 2h3.448l.1.368 1.5 5.5-.964.264L11.118 3H9.566l.93 7.438.07.562H5.69l-.137-.276L4.69 9h-.933l1.202 2.803.084.197-.084.197L3.758 15h.933l.862-1.724L5.69 13H8v1H6.309l-.862 1.724L5.31 16H2.242l.298-.697L3.956 12 2.54 8.697 2.242 8h3.067l.138.276L6.31 10h3.125l-.93-7.438L8.434 2ZM3.646 19.646l15-15 .707.708-15 15-.707-.708ZM16.5 12H16v5h4.637l.252.708.01.028.014.027a.826.826 0 0 1 .087.377c0 .48-.383.86-.84.86H10v1h3.05c.076.369.233.709.453 1H10v1h12v-1h-4.503a2.49 2.49 0 0 0 .453-1h2.21A1.85 1.85 0 0 0 22 18.14c0-.287-.063-.553-.178-.794l-1.87-5.24a4.592 4.592 0 0 0-2.557-2.746l-.39.92A3.598 3.598 0 0 1 18.828 12H16.5Zm-1 9a1.51 1.51 0 0 0 1.414-1h-2.828c.208.58.765 1 1.414 1Zm3.71-8 1.07 3H17v-3h2.21ZM15 16.5V12h-1v4h-4v1h5v-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAirplaneTrainSmall;
