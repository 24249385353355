import type { SVGProps } from "react";
const SvgWarningLightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.5 11.499v-6h-1v6h1Zm-12.876-1.082 4.243 4.243-.707.707-4.243-4.243.707-.707ZM5.998 22.999h6v1h-6v-1Zm4.078 14h3.923V24c0-5.521 4.477-10 10-10 5.524 0 10 4.479 10 10v13h3.924l.07.418 1 6 .097.583H8.91l.096-.583 1-6 .07-.417Zm22.923 0V24a9 9 0 0 0-18 0v13H21v-7a3 3 0 0 1 6 .002v.004L26.998 37h6Zm-11-7L22 37h4v-6.998A2 2 0 1 0 22 30Zm-11.076 8-.833 5h27.82l-.834-5H10.923ZM24 17a7 7 0 0 0-7 7h1a6 6 0 0 1 6-6v-1Zm18 7.002h-6v-1h6v1Zm-9.16-8.633 4.242-4.242-.707-.707-4.243 4.242.707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWarningLightLarge;
