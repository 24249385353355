import type { SVGProps } from "react";
const SvgStarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12 1.92.475 1.422L14.36 9h6.596l-1.15.895-4.217 3.28 1.883 5.461.48 1.39-1.228-.81L12 16.1l-4.724 3.117-1.228.81.48-1.39 1.883-5.46-4.218-3.281L3.043 9H9.64l1.886-5.658L12 1.92Zm0 3.161-1.525 4.577-.114.342H5.958l3.35 2.605.282.22-.117.338-1.52 4.409 3.772-2.49.275-.18.276.18 3.772 2.49-1.52-4.409-.117-.338.282-.22L18.043 10H13.64l-.114-.342L12 5.081Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStarSmall;
