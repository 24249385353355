import type { SVGProps } from "react";
const SvgPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.75 18.25V6h-1v12.25H4.5v1h12.25V31.5h1V19.25H30v-1H17.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPlusMedium;
