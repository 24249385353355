import type { SVGProps } from "react";
const SvgIr35Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-35-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M36.608 7.898a4.344 4.344 0 0 1 .702-2.39c.715-1.033 1.79-1.55 3.224-1.55 1.104 0 1.995.283 2.673.847a2.953 2.953 0 0 1 1.096 2.373 2.593 2.593 0 0 1-.43 1.499 2.107 2.107 0 0 1-1.163.85c.59.123 1.114.461 1.47.95.37.518.559 1.143.54 1.78a3.545 3.545 0 0 1-1.261 2.807c-.778.675-1.76 1.013-2.948 1.013-1.288 0-2.309-.372-3.063-1.116-.754-.743-1.131-1.748-1.131-3.013v-.15h2.215c0 .711.168 1.261.504 1.649.364.4.889.613 1.427.581.615 0 1.088-.182 1.42-.546.301-.335.465-.774.457-1.226a1.686 1.686 0 0 0-.584-1.352c-.32-.275-.814-.412-1.482-.412-.187 0-.374.01-.56.032v-1.7c.137.01.268.015.395.015 1.324 0 1.986-.493 1.986-1.48a1.359 1.359 0 0 0-.433-1.047 1.62 1.62 0 0 0-1.143-.4 1.574 1.574 0 0 0-1.254.506c-.294.338-.452.831-.473 1.48h-2.184Zm16.461-3.663v2.057h-4.643l-.497 2.603a2.807 2.807 0 0 1 2.136-.886 3.678 3.678 0 0 1 2.224.688c.935.69 1.403 1.74 1.403 3.148 0 1.382-.5 2.476-1.498 3.283a4.283 4.283 0 0 1-2.79.949c-1.22 0-2.2-.328-2.941-.985-.741-.656-1.133-1.544-1.175-2.662h2.263a1.9 1.9 0 0 0 .394 1.037 1.917 1.917 0 0 0 2.9.015c.348-.443.53-.994.513-1.558.03-.58-.162-1.15-.536-1.594a1.824 1.824 0 0 0-1.427-.597 1.895 1.895 0 0 0-1.695.98l-2.057-.03 1.096-6.448h6.33ZM9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr35Negative;
