import type { SVGProps } from "react";
const SvgSpannerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 6.5A4.5 4.5 0 0 1 17.5 2c.662 0 1.285.151 1.846.403l.66.298-.512.512L17 5.708V7h1.292l2.495-2.494.511-.511.298.658A4.47 4.47 0 0 1 22 6.5a4.5 4.5 0 0 1-4.5 4.5c-.538 0-1.049-.1-1.521-.272l-5.25 5.25c.172.474.27.985.27 1.522A4.5 4.5 0 0 1 6.5 22c-.662 0-1.284-.15-1.845-.404l-.66-.298.512-.511L7 18.293V17H5.707l-2.494 2.494-.511.511-.298-.659A4.464 4.464 0 0 1 2 17.5 4.5 4.5 0 0 1 6.5 13a4.45 4.45 0 0 1 1.522.271l5.25-5.25a4.421 4.421 0 0 1-.273-1.52ZM17.5 3A3.5 3.5 0 0 0 14 6.5c0 .51.115.991.315 1.434l.143.315-.245.245-5.719 5.719-.244.245-.316-.143A3.468 3.468 0 0 0 6.5 14 3.5 3.5 0 0 0 3 17.5c0 .246.026.485.076.716l2.07-2.07.146-.146H8v2.707l-.147.147-2.07 2.07A3.5 3.5 0 0 0 10 17.5c0-.51-.114-.993-.314-1.436l-.143-.315.245-.244 5.719-5.72.244-.243.315.141c.444.2.926.316 1.434.316a3.5 3.5 0 0 0 3.424-4.216l-2.07 2.07-.146.146H16V5.293l.146-.146 2.07-2.07A3.386 3.386 0 0 0 17.5 3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSpannerSmall;
