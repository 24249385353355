import type { SVGProps } from "react";
const SvgCloudSnowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.494 10.606a2.065 2.065 0 0 0-1.182-.75c.125-.804-.004-1.532-.322-2.14a3.276 3.276 0 0 0-1.794-1.548c-1.363-.495-3.042-.116-3.988 1.422-1.516-.719-3.43.326-3.522 2.176-.481.129-.87.432-1.144.815-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.926 0 1.66-.312 2.174-.813A2.795 2.795 0 0 0 18 12.402c.05-.636-.112-1.293-.505-1.796Zm-6.636-2.104c.635-1.44 1.948-1.774 2.997-1.394.53.192.983.566 1.249 1.073.262.5.358 1.165.117 1.977l-.2.67.7-.028c.464-.019.78.16.985.422.216.276.33.675.296 1.102-.033.423-.209.84-.525 1.148-.31.302-.786.528-1.477.528H8c-.73 0-1.2-.23-1.492-.524-.298-.3-.455-.713-.474-1.148-.019-.437.104-.862.321-1.165.21-.294.497-.463.845-.463h.563l-.066-.56c-.18-1.5 1.419-2.245 2.386-1.453l.51.417.265-.602ZM13.5 17.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm1-1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowSmall;
