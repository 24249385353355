import type { SVGProps } from "react";
const SvgLayersMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.282 4.837 18 4.644l-.282.193-14.25 9.75-.603.413.603.413 3.83 2.62-3.825 2.551-.616.41.61.419 14.25 9.75.283.193.282-.193 14.25-9.75.611-.418-.616-.411-3.825-2.55 3.83-2.621.604-.413-.604-.413-14.25-9.75Zm9.53 13.805-9.53 6.52-.282.194-.282-.193-9.53-6.52-3.545 2.362L18 30.145l13.357-9.14-3.545-2.363ZM18 24.144 4.635 15 18 5.856 31.365 15 18 24.144Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLayersMedium;
