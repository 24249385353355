import type { SVGProps } from "react";
const SvgBatteryLightningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 7V3.5h-2v-1h-4v1h-2v18h8V18m2-9.5-2 3h3L13 18l1.5-4.5h-3l2-5h4Z"
      stroke="currentColor"
    />
  </svg>
);
export default SvgBatteryLightningSmall;
