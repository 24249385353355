import type { SVGProps } from "react";
const SvgPinMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.25 7.75h-.677l.2.647 2.972 9.66-.383 5.156-.04.537H17.5v4.75h1v-4.75h4.162l-.038-.536-.368-5.156 2.972-9.661.2-.647H11.25Zm3.478 10.103-2.8-9.103h12.145l-2.8 9.103-.028.09.006.093.337 4.714h-7.19l.35-4.713.008-.094-.028-.09Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPinMedium;
