import type { SVGProps } from "react";
const SvgCameraMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.89 6.25h8.221l.114.342 1.38 4.158h9.145v19H3.25V9.25h4v1.5h5.14l1.386-4.158.114-.342Zm-7.64 4.5v-.5h-2v.5h2Zm15.14-3.5 1.161 3.5h-9.107l1.166-3.5h6.78ZM4.25 28.75v-17h27.5v17H4.25Zm9-8.5a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0ZM18 14.5A5.75 5.75 0 1 0 18 26a5.75 5.75 0 0 0 0-11.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCameraMedium;
