import type { SVGProps } from "react";
const SvgDocumentSbbSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.01 4h6.697l.146.147 5 5 .147.146V20H6v-.5l.009-15V4Zm.999 1L7 19h10v-9h-5V5H7.01ZM13 5.708 16.293 9H13V5.708Zm1.4 9.792L12.8 14H14l2 2-2 2h-1.2l1.6-1.5h-1.9V18h-1v-1.5H9.6l1.6 1.5H10l-2-2 2-2h1.2l-1.6 1.5h1.9V14h1v1.5h1.9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSbbSmall;
