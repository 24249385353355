import type { SVGProps } from "react";
const SvgUtilizationLowHighContrast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="utilization-low-high-contrast_svg__color-immutable"
    fill="none"
    height={16}
    width={19}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 3C3.327 3 4 2.327 4 1.5S3.327 0 2.5 0 1 .673 1 1.5 1.673 3 2.5 3M0 4v6h1v6h3v-6h1V4z"
      fill="#fff"
      fillRule="evenodd"
    />
    <mask fill="#fff" id="utilization-low-high-contrast_svg__a">
      <path
        clipRule="evenodd"
        d="M9.504 3c.827 0 1.5-.673 1.5-1.5S10.33 0 9.504 0s-1.5.673-1.5 1.5.673 1.5 1.5 1.5"
        fillRule="evenodd"
      />
    </mask>
    <path
      d="M9.504 4a2.5 2.5 0 0 0 2.5-2.5h-2c0 .275-.225.5-.5.5zm2.5-2.5a2.5 2.5 0 0 0-2.5-2.5v2c.275 0 .5.225.5.5zM9.504-1a2.5 2.5 0 0 0-2.5 2.5h2c0-.275.225-.5.5-.5zm-2.5 2.5a2.5 2.5 0 0 0 2.5 2.5V2a.502.502 0 0 1-.5-.5z"
      fill="#fff"
      mask="url(#utilization-low-high-contrast_svg__a)"
    />
    <mask fill="#fff" id="utilization-low-high-contrast_svg__b">
      <path
        clipRule="evenodd"
        d="M16.508 3c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5-1.5.673-1.5 1.5.673 1.5 1.5 1.5"
        fillRule="evenodd"
      />
    </mask>
    <path
      d="M16.508 4c1.379 0 2.5-1.12 2.5-2.5h-2c0 .275-.226.5-.5.5zm2.5-2.5c0-1.38-1.121-2.5-2.5-2.5v2c.274 0 .5.225.5.5zm-2.5-2.5a2.5 2.5 0 0 0-2.5 2.5h2c0-.275.225-.5.5-.5zm-2.5 2.5a2.5 2.5 0 0 0 2.5 2.5V2a.502.502 0 0 1-.5-.5z"
      fill="#fff"
      mask="url(#utilization-low-high-contrast_svg__b)"
    />
    <path
      d="M8.504 10v-.5h-1v-5h4v5h-1v6h-2zm6.996 0v-.5h-1v-5h4v5h-1v6h-2z"
      stroke="#fff"
    />
  </svg>
);
export default SvgUtilizationLowHighContrast;
