import type { SVGProps } from "react";
const SvgSwisspassMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.75 12.75a2.5 2.5 0 0 1 2.5-2.5h19.5a2.5 2.5 0 0 1 2.5 2.5v10.5a2.5 2.5 0 0 1-2.5 2.5H8.25a2.5 2.5 0 0 1-2.5-2.5v-10.5Zm2.5-3.5a3.501 3.501 0 0 0-3.5 3.5v10.5c0 1.932 1.568 3.5 3.5 3.5h19.5c1.932 0 3.5-1.568 3.5-3.5v-10.5a3.5 3.5 0 0 0-3.5-3.5H8.25Zm17 4.251v1.75H27v1h-1.75v1.75h-1v-1.75H22.5v-1h1.75v-1.75h1Zm-12.5 4.75a2.501 2.501 0 0 1 0-5 2.5 2.5 0 0 1 0 5Zm2.171.244a3.5 3.5 0 1 0-4.341.001c-.857.411-1.532.965-2.01 1.681-.563.847-.82 1.877-.82 3.073v.5h10v-.5c0-1.196-.257-2.228-.82-3.075-.477-.717-1.152-1.27-2.009-1.68Zm-2.171.755h-1.404c-.899.366-1.53.86-1.943 1.482-.358.537-.574 1.2-.635 2.018h7.964c-.061-.819-.277-1.484-.635-2.021-.414-.622-1.044-1.117-1.942-1.479H12.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSwisspassMedium;
