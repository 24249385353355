import type { SVGProps } from "react";
const SvgBackpackSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.004 2a2 2 0 0 0-2 1.998v.101A5.002 5.002 0 0 0 5 9v13h14V9a5.002 5.002 0 0 0-3.999-4.9L15 3.997A2 2 0 0 0 13 2h-1.996Zm-1 2H14a1 1 0 0 0-1-1h-1.996a1 1 0 0 0-1 1ZM6 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v12H6V9Zm3.004 5a1 1 0 0 0-1 1H8v4h8v-4a1 1 0 0 0-1-1H9.004ZM17 15v5H7v-5h.004a2 2 0 0 1 2-2H15a2 2 0 0 1 2 2Zm-4 1H9v-1h6v1h-1v1h-1v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBackpackSmall;
