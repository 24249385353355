import type { SVGProps } from "react";
const SvgGearsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 3v2.016a7.5 7.5 0 0 1 2.799.738L16.86 3.96l.86.51-1.055 1.781c.64.426 1.21.947 1.689 1.544l1.724-.977.493.87-1.643.93A7.48 7.48 0 0 1 19.984 12H22v1h-2.016a7.494 7.494 0 0 1-.673 2.66l1.614 1.03-.538.843-1.556-.993a7.478 7.478 0 0 1-2.272 2.279l.881 1.644-.881.473-.876-1.635a7.492 7.492 0 0 1-2.683.683V22h-1v-2.016a7.49 7.49 0 0 1-2.758-.719l-.98 1.73-.87-.493.98-1.728a7.477 7.477 0 0 1-2.405-2.57l-1.69.957-.494-.87 1.751-.99a7.508 7.508 0 0 1-.518-2.3H3v-1h2.016a7.494 7.494 0 0 1 .694-2.707L3.911 8.23l.51-.86 1.774 1.05A7.477 7.477 0 0 1 8.27 6.294l-.95-1.68.87-.492.943 1.666A7.49 7.49 0 0 1 12 5.016V3h1ZM9.737 18.393c.837.39 1.773.607 2.763.607a6.472 6.472 0 0 0 6.481-6h-3.015a3.462 3.462 0 0 1-3.466 3c-.452 0-.883-.082-1.277-.232l-1.486 2.625Zm.614-3.114-1.485 2.622A6.475 6.475 0 0 1 6 12.501a6.475 6.475 0 0 1 2.765-5.333l1.49 2.63A3.48 3.48 0 0 0 9 12.5c0 1.143.525 2.144 1.35 2.78Zm.758-6-1.482-2.62A6.52 6.52 0 0 1 12.5 6a6.47 6.47 0 0 1 6.481 6h-3.015A3.462 3.462 0 0 0 12.5 9c-.496 0-.966.1-1.39.279ZM10 12.5c0-1.407 1.093-2.5 2.5-2.5s2.5 1.093 2.5 2.5-1.093 2.5-2.5 2.5a2.467 2.467 0 0 1-2.5-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGearsSmall;
