import type { SVGProps } from "react";
const SvgEv25 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-25_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.302 9.537v2.063h-8.355c0-.63.1-1.23.301-1.801a4.438 4.438 0 0 1 .865-1.476 8.124 8.124 0 0 1 1-.928c.386-.302 1.15-.855 2.293-1.659.556-.386.944-.745 1.167-1.079a2.04 2.04 0 0 0 .333-1.158c0-.508-.151-.918-.452-1.23-.297-.317-.688-.476-1.175-.476-1.195 0-1.811.86-1.849 2.579h-2.23v-.254c0-1.407.395-2.497 1.183-3.27.767-.75 1.759-1.126 2.976-1.126 1.079 0 1.98.307 2.705.92.788.667 1.182 1.563 1.182 2.69 0 .984-.314 1.83-.944 2.54-.206.232-.4.42-.579.563-.18.137-.653.473-1.42 1.007-.73.508-1.254.897-1.571 1.167a6.37 6.37 0 0 0-.873.928h5.443Zm8.223-9.537v2.063h-4.674l-.5 2.61c.56-.592 1.278-.889 2.15-.889.857 0 1.603.23 2.238.69.942.694 1.412 1.746 1.412 3.159 0 1.386-.502 2.483-1.507 3.293-.783.634-1.72.952-2.81.952-1.226 0-2.213-.328-2.959-.984-.746-.661-1.14-1.553-1.182-2.674h2.277c.037.407.17.754.397 1.04.354.449.846.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.299.328-1.706.984l-2.07-.032 1.102-6.466h6.372Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv25;
