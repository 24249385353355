import type { SVGProps } from "react";
const SvgChartColumnSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.5 4H18v15h4V4h-3.5Zm.5 14V5h2v13h-2ZM8.5 7H8v12h4V7H8.5ZM9 18V8h2v10H9Zm-6-3h4v4H3v-4Zm1 1v2h2v-2H4Zm18 5H3v-1h19v1ZM13 9h4v10h-4V9Zm1 1v8h2v-8h-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnSmall;
