import type { SVGProps } from "react";
const SvgBookSectionMarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 4A1.5 1.5 0 0 0 5 5.5v11a2.49 2.49 0 0 1 1.5-.499H19V4H6.5ZM4 18.5v-13A2.5 2.5 0 0 1 6.5 3H20v14.001H6.5a1.499 1.499 0 1 0 0 3L20 20v1H6.5A2.501 2.501 0 0 1 4 18.5Zm16 .5H7v-1h13v1Zm-9-9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm2.323 1.5A2 2 0 0 0 12 8a1 1 0 1 1 1-1h1a2 2 0 1 0-3.323 1.5A2 2 0 0 0 12 12a1 1 0 1 1-1 1h-1a2 2 0 1 0 3.323-1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBookSectionMarkSmall;
