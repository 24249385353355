import type { SVGProps } from "react";
const SvgCloudSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.924 6.536c.437.293.81.69 1.066 1.18.318.609.447 1.336.322 2.14a2.048 2.048 0 0 1 1.285.904C18.435 10.366 19 9.52 19 8.5 19 7.085 17.915 6 16.5 6c-.609 0-1.153.2-1.576.536Zm3.03 5.16c.04.233.05.472.03.708a2.82 2.82 0 0 1-.826 1.784c-.512.5-1.24.812-2.158.812H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.028-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.815c.092-1.851 2.006-2.896 3.522-2.177.888-1.443 2.421-1.865 3.732-1.504A3.484 3.484 0 0 1 16.5 5C18.467 5 20 6.533 20 8.5c0 1.44-.823 2.654-2.046 3.196Zm-7.096-3.194c.635-1.44 1.948-1.774 2.997-1.394.53.192.983.566 1.249 1.073.262.5.358 1.165.117 1.977l-.2.67.7-.028c.464-.019.777.16.978.42.213.275.324.674.288 1.101a1.82 1.82 0 0 1-.528 1.152c-.31.302-.78.527-1.459.527H8c-.73 0-1.2-.23-1.492-.524-.298-.3-.455-.713-.474-1.147-.019-.438.104-.863.321-1.166.21-.294.497-.463.845-.463h.563l-.066-.559c-.18-1.502 1.419-2.246 2.386-1.454l.51.417.265-.602Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSunSmall;
