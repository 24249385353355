import type { SVGProps } from "react";
const SvgWashingMachineLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.5 4H8v40h33V4H8.5Zm.5 6.304V5h31v8H9v-2.696ZM9 14h31v29H9V14Zm8.005 14.231c.123.126.25.305.41.546l.034.051c.303.459.776 1.172 1.713 1.172.938 0 1.41-.713 1.715-1.172l.034-.05c.363-.546.557-.778.916-.778.36 0 .553.232.917.777l.034.051c.304.46.777 1.172 1.715 1.172.938 0 1.41-.713 1.715-1.172l.034-.05c.363-.546.557-.778.917-.778.361 0 .556.233.92.778l.033.05c.305.46.779 1.172 1.718 1.172.938 0 1.412-.712 1.717-1.171l.034-.051a3.86 3.86 0 0 1 .414-.55 7.5 7.5 0 1 1-14.99.003Zm.122-1.11c.538.223.86.708 1.086 1.05l.034.052c.363.546.557.777.915.777.36 0 .553-.232.917-.777l.034-.051c.304-.459.777-1.172 1.714-1.172.938 0 1.41.713 1.715 1.172l.034.05c.364.546.558.778.917.778.36 0 .553-.232.917-.777l.034-.051c.304-.46.777-1.172 1.715-1.172.939 0 1.412.712 1.717 1.171l.034.051c.364.545.558.778.92.778.36 0 .555-.233.919-.778l.034-.05c.228-.344.55-.829 1.09-1.051a7.502 7.502 0 0 0-14.747 0ZM12 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm19 1h-4V7h4v1Zm2 0h4V7h-4v1Zm-2 3h-4v-1h4v1Zm2 0h4v-1h-4v1Zm-9.5 7.063c-5.799 0-10.5 4.7-10.5 10.5 0 5.799 4.701 10.5 10.5 10.5S35 34.36 35 28.563c0-5.8-4.701-10.5-10.5-10.5ZM13 28.563c0-6.352 5.149-11.5 11.5-11.5S36 22.21 36 28.563c0 6.35-5.149 11.5-11.5 11.5S13 34.913 13 28.563ZM24.5 20a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWashingMachineLarge;
