import type { SVGProps } from "react";
const SvgTelephoneReceiverSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.78 2.697c.813-.471 1.806-.054 2.255.722l1.757 3.042c.45.779.311 1.847-.503 2.316L9.337 9.905c0 .006.001.016.006.028.358 1 .833 2.032 1.425 3.058M9.314 9.918ZM7.779 2.697Zm0 0-1.708.982h-.002a1.183 1.183 0 0 0-.293.248C4.678 4.916 4.35 6.681 4.56 8.631c.214 1.997 1.001 4.33 2.316 6.609 1.315 2.276 2.942 4.125 4.565 5.31 1.584 1.156 3.277 1.755 4.683 1.297a1.21 1.21 0 0 0 .365-.137l1.7-.981.003-.002c.796-.468.952-1.53.5-2.314l-1.757-3.042c-.45-.78-1.443-1.193-2.256-.723l-1.953 1.126a.085.085 0 0 1-.021-.019 18.944 18.944 0 0 1-1.937-2.764M9.17 3.92c-.24-.417-.664-.488-.89-.358v.001l-1.708.982a.325.325 0 0 0-.06.06l-.022.027-.027.023c-.745.655-1.108 2.004-.908 3.87.197 1.833.93 4.034 2.188 6.215 1.259 2.179 2.798 3.914 4.288 5.001 1.516 1.106 2.866 1.466 3.805 1.148l.033-.011.034-.006a.264.264 0 0 0 .08-.025l.005-.003 1.699-.98h.001c.225-.134.375-.54.138-.951l-1.756-3.042c-.24-.417-.663-.488-.89-.357l-1.973 1.138c-.442.256-.966.102-1.264-.25a19.946 19.946 0 0 1-2.04-2.91 19.937 19.937 0 0 1-1.5-3.221M9.17 3.92Zm0 0 1.757 3.042c.24.415.09.817-.136.948h-.001L8.816 9.05v.001c-.441.254-.57.786-.415 1.22"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTelephoneReceiverSmall;
