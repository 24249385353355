import type { SVGProps } from "react";
const SvgTabletSmartphoneLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 6.5A3.5 3.5 0 0 1 5.5 3h22.834a3.62 3.62 0 0 1 1.938.58l-.543.84A2.62 2.62 0 0 0 28.328 4H5.5A2.5 2.5 0 0 0 3 6.5V9h20v1H3v27h20v1H3v2.5A2.5 2.5 0 0 0 5.5 43H23v1H5.5A3.5 3.5 0 0 1 2 40.5v-34ZM15 41h4v-1h-4v1Zm0-34h6V6h-6v1Zm-1 0h-1V6h1v1Zm24 36h-4v-1h4v1Zm7-4V8.5A2.5 2.5 0 0 0 42.5 6h-15A2.5 2.5 0 0 0 25 8.5V39h20Zm0 1H25v2.5a2.5 2.5 0 0 0 2.5 2.5h15a2.5 2.5 0 0 0 2.5-2.5V40ZM39 9h-6V8h6v1Zm-8 0h1V8h-1v1Zm-3.5-4A3.5 3.5 0 0 0 24 8.5v34a3.5 3.5 0 0 0 3.5 3.5h15a3.5 3.5 0 0 0 3.5-3.5v-34A3.5 3.5 0 0 0 42.5 5h-15Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTabletSmartphoneLarge;
