import type { SVGProps } from "react";
const SvgRailwaySwitchMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.75 10.25v3.5H6v1h1.75v3.5H6v1h1.75v3.5H6v1h1.75v3.5H6v1h1.75v3.25h1v-2.912L22.918 7.025l-.836-.55L20.26 9.25h-.76v1h.103l-2.3 3.5H16.25v-3.5H18v-1h-1.75V6h-1v3.25H10.5v1h4.75v3.5H10.5v1h4.75v2.124l-.899 1.368H10.5v1h3.194l-2.3 3.5H10.5v1h.238L8.75 26.767V6h-1v3.25H6v1h1.75Zm8.5 5.102v-.602h.395l-.395.602Zm0 13.972v2.175h-1v-3.25H12v-1h4.485l2.46-3.5H15v-1h4.647l2.46-3.499H18v-1h4.809l2.459-3.5H21v-1h4.97l2.46-3.5H24v-1h5.132l1.959-2.787.818.574-1.554 2.213H31.5v1h-1.848l-2.46 3.5H28.5v1h-2.01l-2.46 3.5h1.47v1h-2.172l-2.459 3.5H22.5v1h-2.333l-2.46 3.5H19.5v1h-2.495l-.755 1.074Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRailwaySwitchMedium;
