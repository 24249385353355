import type { SVGProps } from "react";
const SvgSplitSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.5 3H16v1h4.31l-.271.28-.002.002v.002c-1.522 1.577-2.95 3.059-5.115 4.244C12.261 9.985 8.455 11 2 11v1c6.455 0 10.26 1.015 12.922 2.472 2.165 1.185 3.593 2.667 5.114 4.244l.274.284H16v1h6v-6h-1v4.274l-.207-.215c-1.529-1.588-3.08-3.199-5.39-4.464-1.669-.913-3.722-1.645-6.446-2.095 2.724-.45 4.777-1.182 6.446-2.095 2.31-1.265 3.861-2.876 5.39-4.464L21 4.726V9h1V3h-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSplitSmall;
