import type { SVGProps } from "react";
const SvgSoccerBallMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#soccer-ball-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M4.25 18c0-2.717.788-5.25 2.148-7.383l1.5 5.11L4.26 18.52c-.007-.173-.01-.347-.01-.521Zm4.63-2.479L7.135 9.574a13.804 13.804 0 0 1 4.983-4.005L17.5 9.263v3.982l-4.837 3.51-3.782-1.234Zm3.473 2.185L8.57 16.472l-4.215 3.236a13.684 13.684 0 0 0 2.842 6.799l4.663.135 2.346-3.24-1.853-5.696Zm2.662 6.284-2.34 3.23 1.406 3.963c1.242.37 2.558.567 3.919.567 1.361 0 2.677-.198 3.919-.567l1.405-3.962-2.34-3.231h-5.969Zm6.78-.587 2.345 3.24 4.663-.136a13.684 13.684 0 0 0 2.84-6.785l-4.224-3.247-3.772 1.231-1.853 5.697Zm1.542-6.648 3.766-1.229 1.76-5.957a13.805 13.805 0 0 0-4.98-4L18.5 9.263v3.982l4.837 3.51ZM8.091 27.533a13.772 13.772 0 0 0 4.78 3.228l-1.108-3.121-3.672-.107Zm16.146.107 3.672-.107a13.77 13.77 0 0 1-4.78 3.228l1.108-3.121Zm7.503-9.105a13.686 13.686 0 0 0-2.142-7.924l-1.511 5.117 3.653 2.807ZM18 8.394l-4.789-3.287A13.721 13.721 0 0 1 18 4.25c1.684 0 3.297.303 4.789.857L18 8.394Zm0-5.144C9.854 3.25 3.25 9.854 3.25 18S9.854 32.75 18 32.75 32.75 26.146 32.75 18 26.146 3.25 18 3.25Zm-2.877 19.74-1.785-5.489L18 14.118l4.662 3.383-1.785 5.489h-5.754Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="soccer-ball-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSoccerBallMedium;
