import type { SVGProps } from "react";
const SvgIc24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-24_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-24_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.722 9.542v2.056h-8.33c0-.627.1-1.226.3-1.795.206-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.94-.744 1.162-1.076.222-.332.333-.717.333-1.155 0-.506-.15-.915-.451-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.697.918.786.664 1.18 1.558 1.18 2.681 0 .981-.315 1.825-.942 2.531a4.65 4.65 0 0 1-.578.562c-.179.137-.65.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.353 6.353 0 0 0-.87.926h5.426Zm9.12-2.539v1.954h-1.456v2.642H50.08v-2.643h-4.825V10.99l4.944-6.755h2.135v6.969h1.456Zm-3.663.024V6.922l-2.974 4.303h2.974Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-24_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc24;
