import type { SVGProps } from "react";
const SvgGunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 5.085c.427.15.765.489.916.915H22v2h-1v.618l-.053.106-1 2-.138.276h-5.923c-.175 1.072-.52 1.886-.969 2.495a3.752 3.752 0 0 1-1.746 1.317 4.338 4.338 0 0 1-1.737.247L9 18.535a1.5 1.5 0 0 1-1.5 1.466h-2a1.5 1.5 0 0 1-1.5-1.5v-.031l.004-.031.93-7.438H4.5a1.5 1.5 0 0 1-1.5-1.5V7H2V6h1.085c.206-.582.762-1 1.415-1H19V4h1v1.085ZM12.871 11h-2.87a1 1 0 0 0 1 1v1c-.475 0-.911-.165-1.254-.442l-.189 1.506.05.002c.328.008.768-.028 1.218-.193a2.752 2.752 0 0 0 1.287-.973c.319-.432.598-1.04.758-1.9ZM4.5 6a.5.5 0 0 0-.5.5V8h16l.001-1.5a.5.5 0 0 0-.5-.5H4.5ZM4 9.501V9h15.691l-.5 1H9.059l-.055.438-1 8.001-.004.03v.032a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.474l.996-7.965.07-.563L5.5 10l-1 .001a.5.5 0 0 1-.5-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGunSmall;
