import type { SVGProps } from "react";
const SvgWeightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.5 5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM14 7a2.5 2.5 0 1 0-4 0H5.071l-.065.424-2 13-.089.576h18.166l-.089-.576-2-13L18.93 7H14Zm-2 1H5.929L4.083 20h15.834L18.071 8H12Zm-.166 4.01-2.238 2.26 2.449 3.453h-1.574L8.737 15.16l-.719.719v1.844H6.772V12.01h1.246v2.363l2.238-2.363h1.578Zm5.46 5.711v-3.078h-2.397v.914h1.269c-.013.386-.145.696-.396.934-.251.236-.574.355-.967.355-.544 0-.956-.204-1.235-.613-.226-.334-.34-.784-.34-1.351 0-.582.122-1.043.364-1.383.276-.39.667-.585 1.172-.585.333 0 .608.085.826.257.218.171.357.41.42.719h1.207a2.13 2.13 0 0 0-.606-1.29c-.471-.483-1.082-.726-1.832-.726-.812 0-1.475.27-1.988.813-.547.572-.82 1.309-.82 2.207 0 .79.21 1.457.629 1.996.505.646 1.194.968 2.066.968.716 0 1.285-.264 1.707-.788l.125.651h.797Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWeightSmall;
