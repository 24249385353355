import type { SVGProps } from "react";
const SvgIc13 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-13_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-13_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm26.341.034v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.98-.49.428-.328.716-.879.863-1.654h1.812Zm3.314 3.662c.026-.949.26-1.745.704-2.389.717-1.033 1.795-1.55 3.235-1.55 1.108 0 2.001.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.163.847c.623.142 1.113.459 1.472.949.363.49.545 1.084.545 1.78 0 1.144-.421 2.08-1.265 2.808-.78.675-1.767 1.012-2.959 1.012-1.292 0-2.317-.371-3.077-1.115-.754-.744-1.13-1.748-1.13-3.014v-.15h2.222c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.196-1.02-.586-1.353-.321-.274-.817-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.138.01.27.016.396.016 1.329 0 1.993-.493 1.993-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.672-.403-1.147-.403-.543 0-.962.169-1.257.506-.296.338-.454.83-.475 1.48h-2.191Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-13_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc13;
