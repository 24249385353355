import type { SVGProps } from "react";
const SvgWifiLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24 10c-7.563 0-14.37 3.184-19.2 8.268l-.725-.688C9.086 12.307 16.15 9 24 9c7.85 0 14.914 3.307 19.923 8.58l-.725.688C38.368 13.184 31.562 10 24 10ZM9.148 22.396C12.885 18.463 18.15 16 24 16s11.115 2.463 14.851 6.396l.725-.688C35.66 17.586 30.138 15 24 15s-11.66 2.585-15.577 6.708l.725.689Zm4.357 4.138c2.643-2.784 6.368-4.527 10.506-4.527 4.134 0 7.854 1.738 10.496 4.515l.725-.69c-2.822-2.965-6.8-4.825-11.22-4.825-4.427 0-8.41 1.865-11.232 4.838l.725.689Zm4.345 4.126C19.401 29.027 21.576 28 24 28c2.424 0 4.598 1.027 6.15 2.66l.724-.689C29.146 28.151 26.714 27 24 27c-2.715 0-5.146 1.152-6.875 2.971l.725.689Zm3.65 5.84a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM24 33a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWifiLarge;
