import type { SVGProps } from "react";
const SvgScreenInsideTrainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 4h1.293L20 7.707V21h1V7.293l-.146-.146-4-4L16.707 3H3v1h3v3H4v9h13V7h-2V4Zm-1 0H7v3h7V4ZM5 15V8h11v7H5Zm10-5H6V9h9v1Zm-9 2h9v-1H6v1Zm9 2H6v-1h9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgScreenInsideTrainSmall;
