import type { SVGProps } from "react";
const SvgIc67 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-67_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-67_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.524 2.921h-2.2c-.205-.891-.711-1.337-1.518-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.266 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.628.728.941 1.64.941 2.737 0 1.303-.421 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.76-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.942 1.29.39.495.641 1.12.752 1.874Zm-3.916 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.775.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Zm12.846-5.537v1.962c-.063.068-.25.282-.561.64-.433.496-.863 1.12-1.29 1.875a12.65 12.65 0 0 0-.996 2.16c-.501 1.44-.805 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-67_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc67;
