import type { SVGProps } from "react";
const SvgLocomotiveHighSpeedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.91 7.736H6v1h.91c1.742 0 3.45.39 4.976 1.199l2.395 1.269c-1.235 1.795-.726 4.445 1.343 5.382l.044.02 3.776 1.657c1.495.656 3.11.995 4.743.995h5.29l.627.332a3.097 3.097 0 0 1 1.646 2.737c0 .138-.008.275-.024.41H10.5v1h20.946a3.405 3.405 0 0 1-3.1 2H3v1h1.785a3.49 3.49 0 0 0 1.016 2H3v1h30v-1H19.72a3.49 3.49 0 0 0 1.015-2h7.61a4.407 4.407 0 0 0 4.405-4.41c0-1.518-.838-2.91-2.178-3.62L12.354 9.05C10.671 8.16 8.8 7.736 6.91 7.736Zm8.215 4.006.046-.066 12.42 6.582h-3.404c-1.495 0-2.973-.31-4.341-.91L16.07 15.69l-.034-.015c-1.432-.649-1.87-2.577-.911-3.933Zm-.305 14.994a2.5 2.5 0 0 0 4.9 0h-4.9Zm-1.014 0h-2.091a3.49 3.49 0 0 1-1.015 2h4.12a3.49 3.49 0 0 1-1.014-2Zm-8.006 0a2.5 2.5 0 0 0 4.9 0H5.8Zm-1.3-18H3v-1h1.5v1Zm0 14H3v1h1.5v-1Zm1.5 0h3v1H6v-1Zm1.5-4.498h-3v1h3v-1Zm1.75-1.751a1.75 1.75 0 0 0-1.75-1.75v-1a2.75 2.75 0 0 1 0 5.5v-1a1.75 1.75 0 0 0 1.75-1.75ZM3 14.737h3v-1H3v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveHighSpeedMedium;
