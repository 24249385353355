import type { SVGProps } from "react";
const SvgMovingBusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.25 10.499a3.25 3.25 0 0 1 3.25-3.25h9a3.25 3.25 0 0 1 3.25 3.25v6.251h-15.5v-6.251Zm0 7.25v6.5h15.5v-6.498l-15.5-.002Zm3.25-11.5a4.25 4.25 0 0 0-4.25 4.25v3.25h-1.5v2.5h1.5v12h5.5v-3h6.502v3h5.498v-12h1.5v-2.5h-1.5v-3.25a4.25 4.25 0 0 0-4.25-4.25h-9Zm8.752 21v-2h3.498v2h-3.498Zm4.498-12.5v.5h.5v-.5h-.5Zm-13 10.5h-3.5v2h3.5v-2Zm-4.5-10v-.5h-.5v.5h.5Zm4.25 4.5a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25c0-.137.113-.25.25-.25s.25.113.25.25a.25.25 0 0 1-.5 0Zm8 0a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm6.25 1.75h1v-3h-1v3Zm4 1.5h-1v-6h1v6Zm-26.5-4.5v3h1v-3h-1Zm-3 4.5v-6h1v6h-1ZM22.5 9.251l-9-.002v1l9 .002v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMovingBusMedium;
