import type { SVGProps } from "react";
const SvgSaXp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={29}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.041 7.478h-1l.9-4.272h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.58 2.547-3.12 2.547ZM15.641 15h2.8l1.12-5.315h1.62c3.121 0 5.901-1.666 5.901-5.054 0-3.49-3.54-3.631-6.12-3.631h-2.36L15.64 15Zm-6.2-9.186L7.421 1H4.4l3.061 6.398L1 15h3.481l4.361-5.617h.04L11.022 15h3.06l-3.24-7.321L16.623 1h-3.301l-3.84 4.814h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaXp;
