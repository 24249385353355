import type { SVGProps } from "react";
const SvgSaGx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.461 3.133c1.461 0 2.801.291 3.881.97l.701-2.346C12.582 1.214 10.942 1 9.382 1 4.34 1 1 4.045 1 8.99 1 13.177 3.74 15 7.841 15c1.36 0 2.92-.271 3.741-.601l1.6-7.388H7.521l-.46 2.133h2.961l-.781 3.587c-.32.136-.74.136-1.38.136-2.38 0-3.941-1.764-3.941-3.955 0-3.2 2.141-5.779 5.541-5.779Zm12.06 2.754-2.02-4.654H16.48l3.06 6.185-6.461 7.349h3.482l4.36-5.428h.04l2.14 5.428h3.06l-3.24-7.077 5.782-6.457h-3.301l-3.84 4.654h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaGx;
