import type { SVGProps } from "react";
const SvgGlassCocktailSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12.647 6.76.6-1H6.864l.667 1h5.116Zm1.166 0h2.516l.667-1h-2.583l-.6 1Zm1.85 1H8.196l3.733 5.599 3.732-5.599Zm-9.733-3h7.917L15.5 2.003l.858.514-1.346 2.243h3.851l-.518.777-5.916 8.874v6.349h4v1h-9v-1h4v-6.349L5.514 5.537l-.518-.777h.934Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGlassCocktailSmall;
