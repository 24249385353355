import type { SVGProps } from "react";
const SvgIr40Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-40-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M41.218 11.228V6.925l-2.974 4.303h2.974Zm3.663-.024v1.954h-1.456V15.8h-2.254v-2.642h-4.825v-2.167l4.944-6.756h2.135v6.97h1.456Zm4.706-5.292c-.76 0-1.276.438-1.55 1.313-.217.697-.325 1.672-.325 2.927 0 1.107.111 1.996.333 2.666.284.87.812 1.305 1.582 1.305.638 0 1.112-.337 1.423-1.012.312-.676.467-1.706.467-3.094 0-1.18-.11-2.111-.332-2.792-.285-.875-.818-1.313-1.598-1.313Zm.087-1.954c.88 0 1.656.27 2.326.807.664.538 1.147 1.366 1.447 2.484.227.855.34 1.783.34 2.785 0 1.66-.295 3.026-.886 4.097-.348.622-.802 1.102-1.364 1.44a3.578 3.578 0 0 1-1.879.506c-.944 0-1.75-.285-2.42-.854-1.192-1.013-1.788-2.743-1.788-5.19 0-1.74.311-3.137.933-4.192a3.76 3.76 0 0 1 1.389-1.392 3.767 3.767 0 0 1 1.902-.49Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIr40Negative;
