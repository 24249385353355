import type { SVGProps } from "react";
const SvgTrainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 2h8v1h-1.732l-1.333 2H16a3 3 0 0 1 3 3v10h-2.191l.5 1H18v1h-.191l.5 1H19v1H5v-1h.691l.5-1H6v-1h.691l.5-1H5V8a3 3 0 0 1 3-3h3.065L9.732 3H8V2Zm4 2.599L13.066 3h-2.132L12 4.599ZM10 8V7h4v1h-4ZM6.809 21l.5-1h9.382l.5 1H6.809Zm9.382-2-.5-1H8.309l-.5 1h8.382ZM6 13v4h12v-4H6Zm12-1V8a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v4h12Zm-4 3a1 1 0 1 1 2.002.001A1 1 0 0 1 14 15Zm-5-1a1 1 0 1 0 .002 2A1 1 0 0 0 9 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainSmall;
