import type { SVGProps } from "react";
const SvgStationSurroundingAreaMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M27.939 7.487c-4.467-1.823-7.17-2.763-9.908-2.755-2.736.008-5.46.963-9.966 2.754l-.315.125v20.64h7v-4.487h6.5v4.486h7V7.614l-.311-.127ZM8.75 27.25V8.29c4.341-1.718 6.828-2.55 9.284-2.558 2.452-.007 4.919.81 9.216 2.555V27.25h-5v-4.486h-8.5v4.486h-5ZM18 8.75a4.712 4.712 0 0 0-4.75 4.75A4.712 4.712 0 0 0 18 18.25a4.712 4.712 0 0 0 4.75-4.75A4.712 4.712 0 0 0 18 8.75Zm-5.75 4.75A5.712 5.712 0 0 1 18 7.75a5.712 5.712 0 0 1 5.75 5.75A5.712 5.712 0 0 1 18 19.25a5.712 5.712 0 0 1-5.75-5.75Zm6.104.354 2.25-2.25-.708-.707L18 12.793l-1.146-1.146-.708.707 1.5 1.5.354.354.354-.354ZM5.778 24.883c-.74.366-1.359.78-1.798 1.244-.442.466-.73 1.012-.73 1.622 0 .848.544 1.568 1.316 2.145.78.584 1.88 1.09 3.195 1.507 2.635.836 6.251 1.348 10.239 1.348 3.989 0 7.604-.512 10.24-1.348 1.315-.417 2.414-.923 3.194-1.507.772-.577 1.316-1.297 1.316-2.145 0-.61-.288-1.156-.73-1.622-.44-.464-1.058-.877-1.799-1.243l-.442.897c.68.336 1.186.687 1.516 1.034.328.346.455.658.455.934 0 .386-.25.846-.915 1.344-.656.491-1.638.956-2.897 1.355-2.514.797-6.023 1.301-9.938 1.301-3.914 0-7.423-.504-9.937-1.301-1.26-.4-2.242-.864-2.898-1.355-.666-.498-.915-.958-.915-1.344 0-.276.127-.588.455-.934.33-.348.836-.7 1.517-1.036l-.444-.896Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStationSurroundingAreaMedium;
