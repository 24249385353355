import type { SVGProps } from "react";
const SvgUserKeyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.25 9c0-2.68 1.762-4.75 4-4.75s4 2.07 4 4.75-1.762 4.75-4 4.75-4-2.07-4-4.75Zm4-5.75c-2.946 0-5 2.688-5 5.75 0 1.525.51 2.957 1.384 4.01-3.888 3.083-5.741 7.403-5.883 13.228l-.012.512H15v-1H5.768c.23-5.375 2.021-9.252 5.582-12.03a4.55 4.55 0 0 0 2.9 1.03 4.54 4.54 0 0 0 2.86-1c1.63 1.194 2.775 2.57 3.563 4.085l.887-.461c-.838-1.612-2.044-3.07-3.723-4.33.893-1.056 1.413-2.503 1.413-4.044 0-3.062-2.054-5.75-5-5.75Zm6.5 20c0-2.232 1.768-4 4-4s4 1.768 4 4-1.768 4-4 4-4-1.768-4-4Zm4-5c-2.784 0-5 2.216-5 5 0 .266.02.527.06.781l-4.417 4.493-.143.146v4.079h4.08l.146-.144 4.479-4.416c.258.04.524.061.795.061 2.784 0 5-2.216 5-5s-2.216-5-5-5Zm-1.907 9.63a4.957 4.957 0 0 1-2.728-2.734L16.25 29.08v2.67h2.67l3.923-3.869Zm1.91-5.63a.981.981 0 0 0-1.003.998.984.984 0 0 0 1.003 1.002.979.979 0 0 0 .997-1.002.976.976 0 0 0-.997-.998Zm-2.003.998a1.98 1.98 0 0 1 2.003-1.998c1.124 0 1.997.88 1.997 1.998a1.979 1.979 0 0 1-1.997 2.002 1.984 1.984 0 0 1-2.003-2.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserKeyMedium;
