import type { SVGProps } from "react";
const SvgContactLineArrowTrainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 3h20V2H2v1Zm11.277 3.084-3-2-.554.832L12.099 6.5 9.849 8H9v1h5a3.97 3.97 0 0 1 3.448 2.007h-4.441v4.986h6.313l.55 1.516.01.027.012.025c.07.14.108.287.108.439a1 1 0 0 1-1 1H2v1h1.05c.075.37.231.71.45 1H2v1h20v-1h-5.5c.218-.29.375-.63.45-1H19a2 2 0 0 0 2-2c0-.314-.078-.603-.199-.856l-2.094-5.774C18.031 9.418 16.192 8 14 8h-2.349l1.626-1.084.624-.416-.624-.416Zm.716 5.909h3.876l1.094 3.014h-4.97v-3.014ZM7.5 21h5a2.49 2.49 0 0 1-.45-1h-4.1c-.075.37-.231.71-.45 1Zm-3.415-1a1.5 1.5 0 0 0 2.83 0h-2.83ZM14.5 21a1.5 1.5 0 0 1-1.415-1h2.83a1.5 1.5 0 0 1-1.415 1ZM6 5.707l1.646 1.647.708-.708-2.5-2.5-.354-.353-.354.353-2.5 2.5.708.708L5 5.707V11h1V5.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgContactLineArrowTrainSmall;
