import type { SVGProps } from "react";
const SvgMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m14.588 8.084-.586 1.613c-1.123 3.087-.08 6.91 2.357 9.62 2.42 2.689 6.156 4.21 10.362 2.785l1.997-.677-1.48 1.502c-2.746 2.784-5.69 3.886-8.41 3.784-2.71-.102-5.137-1.397-6.866-3.292-1.727-1.894-2.778-4.412-2.708-6.995.07-2.597 1.272-5.22 3.973-7.295l1.36-1.045Zm10.468 15.45c-3.828.57-7.181-1.039-9.44-3.549-2.231-2.479-3.438-5.887-2.965-9.012-1.63 1.68-2.347 3.6-2.398 5.478-.062 2.297.875 4.57 2.448 6.294 1.572 1.724 3.756 2.876 6.165 2.966 1.946.073 4.077-.545 6.19-2.177Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMoonMedium;
