import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 16.003C12 9.373 17.372 4 24 4s12 5.374 12 12.003v.004c-.018 1.992-.645 3.766-1.601 5.442l-.006.01-.005.008-9.932 15.8-.423.672-.424-.673-9.932-15.799-.005-.009c-.975-1.627-1.59-3.366-1.672-5.436v-.02Zm1-.01c.075 1.87.627 3.444 1.527 4.947l9.506 15.12 9.502-15.116C34.43 19.374 34.984 17.772 35 16c-.001-6.075-4.926-11-11-11-6.072 0-10.995 4.92-11 10.993ZM24 9a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm.957 14.095c-3.699.45-6.882 1.245-9.156 2.274-1.136.513-2.07 1.097-2.728 1.745C4.414 34.763 4 35.513 4 36.342c0 1.075.69 2.011 1.727 2.786 1.045.782 2.527 1.467 4.317 2.035C13.628 42.3 18.556 43 24 43c5.444 0 10.372-.7 13.956-1.837 1.79-.568 3.272-1.253 4.317-2.035C43.31 38.353 44 37.417 44 36.342c0-.828-.413-1.577-1.07-2.225-.655-.648-1.587-1.23-2.72-1.744-2.268-1.027-5.443-1.823-9.132-2.273l-.122.992c3.631.444 6.698 1.221 8.841 2.192 1.073.486 1.89 1.01 2.43 1.544.54.533.773 1.041.773 1.514 0 .613-.395 1.29-1.326 1.985-.92.69-2.286 1.333-4.02 1.883C34.192 41.308 29.37 42 24 42c-5.37 0-10.192-.692-13.654-1.79-1.734-.55-3.1-1.194-4.02-1.883C5.396 37.632 5 36.955 5 36.342c0-.473.233-.982.775-1.516.543-.535 1.362-1.06 2.438-1.546 2.15-.972 5.224-1.75 8.864-2.192l-.12-.993Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaLarge;
