import type { SVGProps } from "react";
const SvgMountainPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M27.25 10.253V13.5h1v-3.247h3.257v-1H28.25V6h-1v3.253h-3.34v1h3.34Zm-9.564-2.998-.438-.779-.435.78-9.315 16.721-3.805 6.521-.439.752h27.991l-.438-.752-1.269-2.175L17.686 7.255Zm.075 10.386L9.293 22.81l7.96-14.285 9.79 17.404-8.677-8.223-.278-.263-.327.2Zm.195 1.053 10.75 10.188.799 1.368H4.994L8.3 24.586l9.656-5.892Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainPlusMedium;
