import type { SVGProps } from "react";
const SvgChevronSmallLeftRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m30.83 17.644-5.925-5.856-.703.711 5.565 5.5-5.562 5.501.703.711 5.922-5.856.36-.355-.36-.356Zm-25.6 0 5.925-5.856.703.711L6.293 18l5.562 5.501-.703.711-5.922-5.856L4.87 18l.36-.356Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallLeftRightMedium;
