import type { SVGProps } from "react";
const SvgRssMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M28.75 30C28.75 17.435 18.565 7.25 6 7.25v-1c13.118 0 23.75 10.633 23.75 23.75h-1Zm-22.5-3a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM9 25.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm-3-9c7.594 0 13.75 6.156 13.75 13.75h1c0-8.146-6.603-14.75-14.75-14.75v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRssMedium;
