import type { SVGProps } from "react";
const SvgGpsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25 8.007V3h-1v5.007A16.466 16.466 0 0 0 8.007 24H3v1h5.007A16.466 16.466 0 0 0 24 40.993V46h1v-5.007A16.466 16.466 0 0 0 40.993 25h5.006v-1h-5.006A16.466 16.466 0 0 0 25 8.007ZM9 24.5C9 15.915 15.915 9 24.5 9S40 15.915 40 24.5 33.085 40 24.5 40 9 33.085 9 24.5Zm7 0c0-4.708 3.792-8.5 8.5-8.5s8.5 3.792 8.5 8.5-3.792 8.5-8.5 8.5a8.483 8.483 0 0 1-8.5-8.5Zm8.5-9.5c-5.26 0-9.5 4.24-9.5 9.5s4.24 9.5 9.5 9.5 9.5-4.24 9.5-9.5-4.24-9.5-9.5-9.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGpsLarge;
