import type { SVGProps } from "react";
const SvgCoffeeMachineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 3.5a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5v-3ZM3.5 2A1.5 1.5 0 0 0 2 3.5v3a1.5 1.5 0 0 0 1 1.415v10.17A1.5 1.5 0 0 0 2 19.5v1A1.5 1.5 0 0 0 3.5 22h17a1.5 1.5 0 0 0 1.5-1.5v-1a1.5 1.5 0 0 0-1-1.415V7.915A1.5 1.5 0 0 0 22 6.5v-3A1.5 1.5 0 0 0 20.5 2h-17ZM20 8h-5v3h-1v1h-1v-1h-2v1h-1v-1H9V8H4v10h4.677A2.84 2.84 0 0 1 8 16.16V13h8v1h.69A1.31 1.31 0 0 1 18 15.31v.37A1.32 1.32 0 0 1 16.68 17h-.807c-.113.365-.3.706-.55 1H20V8Zm-4 8h.68a.32.32 0 0 0 .32-.32v-.37a.31.31 0 0 0-.31-.31H16v1Zm-6.461 1.461A1.84 1.84 0 0 0 10.84 18h2.32A1.84 1.84 0 0 0 15 16.16V14H9v2.16c0 .488.194.956.539 1.301ZM10.84 19h9.66a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h7.34ZM10 8v2h4V8h-4Zm6-4h3v1h-3V4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCoffeeMachineSmall;
