import type { SVGProps } from "react";
const SvgSaGk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.461 3.133c1.461 0 2.801.291 3.881.97l.701-2.346C12.582 1.214 10.942 1 9.382 1 4.34 1 1 4.045 1 8.99 1 13.177 3.74 15 7.841 15c1.36 0 2.92-.271 3.741-.601l1.6-7.388H7.521l-.46 2.133h2.961l-.781 3.587c-.32.136-.74.136-1.38.136-2.38 0-3.941-1.764-3.941-3.955 0-3.2 2.141-5.779 5.541-5.779Zm9.74 3.413 1.18-5.313h-2.8l-3.002 13.534h2.802l1.52-6.883 3.88 6.883h3.341l-4.5-7.368 7.061-6.166h-3.901L19.24 6.546h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaGk;
