import type { SVGProps } from "react";
const SvgFaceWorkerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.1 8a5.001 5.001 0 0 1 9.8 0H13v1h4c0 .364.097.706.267 1H6.733c.17-.294.267-.636.267-1h4V8H7.1ZM19 10a1 1 0 0 1-1-1A6 6 0 0 0 6 9a1 1 0 0 1-1 1v1h1.803a6 6 0 1 0 10.394 0H19v-1Zm-3 1H8a5 5 0 1 0 8 0Zm-7 2a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-4.276 3.287A3.993 3.993 0 0 0 12 18a3.99 3.99 0 0 0 3.276-1.714l-.82-.572A2.991 2.991 0 0 1 12 17a2.993 2.993 0 0 1-2.457-1.287l-.82.574Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceWorkerSmall;
