import type { SVGProps } from "react";
const SvgWalletMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.75 7.75h26.5v20.5H4.75V7.75Zm1 1v18.5h24.5v-5H19.5c-1.384 0-2.46-.524-3.183-1.34-.715-.805-1.062-1.866-1.062-2.91s.347-2.104 1.062-2.91c.724-.815 1.799-1.34 3.183-1.34h10.75v-5H5.75Zm13.75 6h10.75v6.5H19.5c-1.113 0-1.91-.412-2.435-1.003-.533-.6-.81-1.416-.81-2.247 0-.83.277-1.645.81-2.246.525-.591 1.322-1.004 2.435-1.004ZM19.25 18a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalletMedium;
