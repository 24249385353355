import type { SVGProps } from "react";
const SvgFolderLockMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.25 9.25h-.309l-.138.276-1.362 2.724H6.25v14.5H19.5v-1H7.25v-12.5h14.809l.138-.276 1.362-2.724h5.184L28.75 18h1l-.008-8.25v-.5H23.25Zm3.5 12.5a1 1 0 1 1 2 0v2.5h-2v-2.5Zm-1 2.5v-2.5c0-1.106.894-2 2-2 1.106 0 2 .894 2 2v2.5h3v8.5h-10v-8.5h3Zm2.5 2.703V30h-1v-3.047h1Zm-4.5 4.797v-6.5h8v6.5h-8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderLockMedium;
