import type { SVGProps } from "react";
const SvgIc35 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-35_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-35_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm20.637 3.696c.027-.949.261-1.745.704-2.389.718-1.033 1.796-1.55 3.236-1.55 1.107 0 2 .282 2.681.846.733.612 1.1 1.403 1.1 2.373 0 .58-.145 1.082-.435 1.503a2.1 2.1 0 0 1-1.163.847c.622.142 1.113.459 1.471.949.364.49.546 1.084.546 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.766 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.754-.744-1.131-1.748-1.131-3.014v-.15h2.223c0 .712.168 1.263.506 1.653.337.385.815.578 1.431.578.617 0 1.092-.182 1.424-.546a1.78 1.78 0 0 0 .46-1.226c0-.57-.196-1.02-.586-1.353-.322-.274-.818-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.673-.403-1.147-.403-.543 0-.963.169-1.258.506-.295.338-.453.83-.474 1.48h-2.192ZM53.11 4.234V6.29h-4.66l-.498 2.602c.56-.59 1.274-.886 2.144-.886.854 0 1.598.23 2.23.688.94.691 1.409 1.74 1.409 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.951-.982-.744-.659-1.136-1.547-1.179-2.665h2.27c.037.406.17.751.396 1.036.353.448.844.672 1.471.672.607 0 1.087-.218 1.44-.656.343-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.358-.401-.835-.602-1.431-.602-.728 0-1.295.327-1.701.981l-2.065-.031 1.1-6.447h6.352Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-35_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc35;
