import type { SVGProps } from "react";
const SvgCrossMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18.707 18 8.647-8.647-.707-.707L18 17.293 9.354 8.646l-.708.707L17.293 18l-8.647 8.646.708.707L18 18.707l8.646 8.646.708-.707L18.707 18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCrossMedium;
