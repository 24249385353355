import type { SVGProps } from "react";
const SvgWineCheeseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 2h3v5a2 2 0 0 1 2 2v3H4v4h4v1H4v4h4v1H3V9a2 2 0 0 1 2-2V2Zm4 7v2H4V9a1 1 0 0 1 1-1h1V3h1v5h1a1 1 0 0 1 1 1Zm3 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm4.5 3a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM9.5 13H9v9h13v-3.064l-.248-.145A1.494 1.494 0 0 1 21 17.5c0-.549.3-1.028.752-1.291l.248-.145V13h-4v1a1 1 0 1 1-2 0v-1H9.5Zm.5 8v-7h5a2 2 0 1 0 4 0h2v1.51c-.602.453-1 1.17-1 1.99 0 .82.398 1.537 1 1.99V21H10Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWineCheeseSmall;
