import type { SVGProps } from "react";
const SvgCloudSnowSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.99 7.717a3.215 3.215 0 0 0-1.066-1.18A2.51 2.51 0 0 1 16.5 6C17.915 6 19 7.085 19 8.5c0 1.011-.553 1.849-1.378 2.248a2.135 2.135 0 0 0-1.31-.892c.125-.804-.004-1.532-.322-2.14Zm2.07 4.676a2.787 2.787 0 0 0-.051-.722C19.201 11.117 20 9.92 20 8.5 20 6.533 18.467 5 16.5 5c-1.016 0-1.923.41-2.56 1.086-1.311-.361-2.844.06-3.732 1.504-1.516-.719-3.43.326-3.522 2.177-.481.128-.87.43-1.144.814-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.96 0 1.713-.307 2.239-.81.52-.497.783-1.155.82-1.799Zm-4.205-5.285c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.166.018.434.175.846.473 1.147.293.295.763.524 1.492.524h7c.738 0 1.233-.23 1.548-.531.32-.306.489-.717.514-1.136.025-.422-.099-.823-.327-1.103-.218-.268-.549-.449-1.015-.43l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073ZM15.5 15.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowSunSmall;
