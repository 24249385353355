import type { SVGProps } from "react";
const SvgEuropeFlagSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm8.6-5.552.62.207-.422.634.832.555.37-.555.37.555.832-.555-.423-.634.62-.207-.316-.949-.583.195v-.571h-1v.57l-.583-.194-.316.949Zm4.62 1.85-.62-.206.317-.949.583.195v-.571h1v.57l.583-.194.316.949-.62.207.423.634-.832.555-.37-.555-.37.555-.832-.555.423-.634Zm.88 3.763.62.206-.422.635.832.554.37-.555.37.555.832-.554-.423-.635.62-.206-.316-.949-.583.194v-.57h-1v.57l-.583-.194-.316.949Zm-.88 4.175-.62-.207.317-.948.583.194v-.57h1v.57l.583-.194.316.948-.62.207.423.634-.832.555-.37-.555-.37.555-.832-.555.423-.634Zm-4.62 1.437.62.207-.422.634.832.555.37-.555.37.555.832-.555-.423-.634.62-.207-.316-.948-.583.194v-.571h-1v.571l-.583-.194-.316.948Zm-3.38-1.437-.62-.207.317-.948.583.194v-.57h1v.57l.583-.194.316.948-.62.207.423.634-.832.555L8 16.87l-.37.555-.832-.555.423-.634ZM5.1 12.061l.62.206-.422.635.832.554.37-.555.37.555.832-.554-.423-.635.62-.206-.316-.949-.583.194v-.57H6v.57l-.583-.194-.316.949Zm2.12-3.762-.62-.207.317-.949.583.195v-.571h1v.57l.583-.194.316.949-.62.207.423.634-.832.555L8 8.933l-.37.555-.832-.555.423-.634Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEuropeFlagSmall;
