import type { SVGProps } from "react";
const SvgSaReisegruppe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.9 1H1v14h22.9V1Zm-1.26 12.73H2.26V2.27h20.38v11.46ZM6.75 3.55H4v8.8h1.62V8.57H6a1.16 1.16 0 0 1 1.21.92l.87 2.86h1.74L8.67 9c-.3-.79-.67-.94-1-1v-.06a2 2 0 0 0 1.71-2.07c0-1.62-1.06-2.32-2.63-2.32Zm.086 3.78a1.27 1.27 0 0 1-.566.05l-.65-.04v-2.6h.65c.91 0 1.44.26 1.44 1.26a1.27 1.27 0 0 1-.874 1.33ZM15.64 12a4.868 4.868 0 0 1-2 .41c-2.09 0-3-1.5-3-3.25 0-1.94 1-3.45 2.68-3.45 1.4 0 2.59.82 2.59 3.47v.31H12.2c0 1.11.45 1.78 1.66 1.78a2.53 2.53 0 0 0 1.7-.51l.08 1.24Zm-1.19-3.5c0-1.15-.38-1.73-1.08-1.73-.7 0-1.17.87-1.17 1.73h2.25Zm4.18 2.77a4.2 4.2 0 0 1-1.63-.43l-.07 1.28c.578.206 1.187.31 1.8.31 1.42 0 2.42-.72 2.42-1.91 0-.76-.4-1.13-1.21-1.64l-.77-.5-.091-.059c-.396-.253-.639-.408-.639-.771 0-.39.27-.63.9-.63a3.61 3.61 0 0 1 1.37.29L20.8 6a5.13 5.13 0 0 0-1.64-.25 2.07 2.07 0 0 0-2.24 1.85c0 1 .683 1.414 1.332 1.807l.038.023.64.37c.57.33.7.57.7.82 0 .51-.43.65-1 .65Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaReisegruppe;
