import type { SVGProps } from "react";
const SvgSaLe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.762 1H3.961L1 15h8.262l.48-2.206H4.261L6.762 1Zm15.56 0h-8.221l-2.94 14h8.322l.479-2.206H14.44l.82-3.931h4.902l.44-2.207H15.72l.72-3.45h5.462L22.323 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaLe;
