import type { SVGProps } from "react";
const SvgGlobeArrowsLeftRightUpDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 12.5a4 4 0 0 1-4 4m4-4a4 4 0 0 0-4-4m4 4h-8m4 4a4 4 0 0 1-4-4m4 4c.884 0 1.6-1.79 1.6-4m-1.6 4c-.884 0-1.6-1.79-1.6-4s.716-4 1.6-4m-4 4a4 4 0 0 1 4-4m0 0c.884 0 1.6 1.79 1.6 4m0 0H8.5M9 4l2.5 2.5L14 4m6 6-2.5 2.5L20 15M3 15l2.5-2.5L3 10m11 11-2.5-2.5L9 21"
      stroke="currentColor"
    />
  </svg>
);
export default SvgGlobeArrowsLeftRightUpDownSmall;
