import type { SVGProps } from "react";
const SvgWheelThermometerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#wheel-thermometer-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M18 4.25C10.406 4.25 4.25 10.406 4.25 18S10.406 31.75 18 31.75c.497 0 .97-.022 1.447-.072l.106.994A14.61 14.61 0 0 1 18 32.75C9.854 32.75 3.25 26.146 3.25 18S9.854 3.25 18 3.25c1.625 0 3.204.295 4.657.775l-.314.95A13.879 13.879 0 0 0 18 4.25Zm4.75 4a3.5 3.5 0 1 1 7 0v14.191A5.73 5.73 0 0 1 32 27a5.75 5.75 0 0 1-11.5 0 5.73 5.73 0 0 1 2.25-4.559V8.25Zm3.5-2.5a2.5 2.5 0 0 0-2.5 2.5v14.706l-.214.15A4.733 4.733 0 0 0 21.5 27a4.75 4.75 0 0 0 9.5 0 4.733 4.733 0 0 0-2.036-3.895l-.214-.149V8.25a2.5 2.5 0 0 0-2.5-2.5ZM16.25 18a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0ZM18 15.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM24.5 27a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.25-2.705a2.75 2.75 0 1 0 1 0V10.5h-1v13.795ZM7.25 18c0-5.935 4.815-10.75 10.75-10.75 1.003 0 1.964.108 2.867.357l.266-.964c-1.007-.278-2.06-.393-3.133-.393-6.487 0-11.75 5.263-11.75 11.75S11.513 29.75 18 29.75c.087 0 .167.002.247.004.16.004.32.007.532-.005l-.058-.998c-.175.01-.27.007-.4.004-.082-.002-.18-.005-.321-.005-5.935 0-10.75-4.815-10.75-10.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="wheel-thermometer-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWheelThermometerMedium;
