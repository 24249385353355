import type { SVGProps } from "react";
const SvgUserKeySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 6c0-1.723 1.126-3 2.5-3S12 4.277 12 6s-1.126 3-2.5 3S7 7.723 7 6Zm2.5-4C7.418 2 6 3.895 6 6c0 .986.311 1.926.856 2.647-2.55 2.085-3.762 4.984-3.855 8.84L2.988 18h7.013v-1H4.02c.18-3.402 1.324-5.86 3.546-7.641A3.187 3.187 0 0 0 9.5 10c.729 0 1.376-.232 1.91-.624 1.012.764 1.727 1.636 2.224 2.59l.887-.46c-.547-1.053-1.325-2.009-2.393-2.838C12.683 7.944 13 6.996 13 6c0-2.105-1.418-4-3.5-4ZM14 15.5a2.48 2.48 0 0 1 2.5-2.5 2.48 2.48 0 0 1 2.5 2.5 2.48 2.48 0 0 1-2.5 2.5 2.48 2.48 0 0 1-2.5-2.5Zm2.5-3.5a3.48 3.48 0 0 0-3.5 3.5c0 .157.01.311.03.463l-2.887 2.936-.143.146V22h2.955l.146-.144 2.927-2.887c.154.02.312.031.472.031a3.48 3.48 0 0 0 3.5-3.5 3.48 3.48 0 0 0-3.5-3.5Zm-1.562 6.64a3.472 3.472 0 0 1-1.58-1.585L11 19.455V21h1.545l2.393-2.36ZM16.502 15a.488.488 0 0 0-.502.499.49.49 0 0 0 .502.501.486.486 0 0 0 .498-.501.484.484 0 0 0-.498-.499ZM15 15.499c0-.84.663-1.499 1.502-1.499.842 0 1.498.662 1.498 1.499 0 .835-.655 1.501-1.498 1.501A1.49 1.49 0 0 1 15 15.499Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserKeySmall;
