import type { SVGProps } from "react";
const SvgPercentTagSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.5 5H3v14h14.299l.141-.263 3.5-6.5.128-.237-.128-.237-3.5-6.5L17.3 5H3.5ZM4 18V6h12.701l3.231 6-3.23 6H4Zm4-9c0-.564.436-1 1-1 .564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1Zm1-2c-1.116 0-2 .884-2 2s.884 2 2 2 2-.884 2-2-.884-2-2-2Zm3 8c0-.564.436-1 1-1 .564 0 1 .436 1 1 0 .565-.436 1-1 1-.564 0-1-.435-1-1Zm1-2c-1.116 0-2 .883-2 2s.884 2 2 2 2-.883 2-2-.884-2-2-2Zm-6.354 2.647 8-8 .708.707-8 8-.708-.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPercentTagSmall;
