import type { SVGProps } from "react";
const SvgArrowLongLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m7.297 8.998-3.14 3.149-.354.353.354.353 3.14 3.141.708-.707L5.718 13H20v-1H5.715l2.29-2.296-.708-.706Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongLeftSmall;
