import type { SVGProps } from "react";
const SvgDocumentSignatureMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.35 6.349h9.566l.118.117 7.5 7.5.117.118V29.65H9.35V6.35Zm.801.802v21.698H25.85V14.645h-7.5V7.151h-8.2Zm9 .567 6.126 6.126H19.15V7.718Zm3.35 9.933h-9v-.802h9v.802Zm0 3H13.5v-.802h9.003v.802Zm-8.087 3.099c.284-.16.646-.236 1.018-.055l.003.001c.2.1.306.272.362.428.055.152.074.32.08.474.01.291-.027.64-.06.95a4.877 4.877 0 0 0-.005.05c-.037.344-.065.638-.05.857a.904.904 0 0 0 .015.127.985.985 0 0 0 .21-.148c.199-.171.42-.43.66-.726l.122-.152c.194-.24.397-.492.587-.69a2.39 2.39 0 0 1 .37-.327.858.858 0 0 1 .505-.168h.006c.189.01.365.054.518.147a.886.886 0 0 1 .336.366c.133.265.137.575.127.806a9.36 9.36 0 0 1-.025.33l-.002.021a4.26 4.26 0 0 0-.02.316.404.404 0 0 0 .053-.022c.114-.055.249-.164.44-.334l.074-.066c.16-.144.35-.313.554-.457.24-.168.527-.323.865-.375h.001c.524-.079.959.197 1.22.42.268.23.44.477.465.517l-.683.42-.004-.005a2.009 2.009 0 0 0-.3-.322c-.203-.174-.404-.263-.579-.237a1.258 1.258 0 0 0-.523.238c-.162.114-.314.25-.477.395l-.08.072c-.18.16-.394.345-.623.456-.252.122-.563.171-.879-.006a.621.621 0 0 1-.291-.391 1.273 1.273 0 0 1-.035-.358c.003-.11.013-.227.023-.334l.002-.018c.01-.104.018-.202.023-.298.01-.216-.01-.342-.043-.408a.087.087 0 0 0-.034-.04.274.274 0 0 0-.115-.03.378.378 0 0 0-.048.029 1.626 1.626 0 0 0-.241.216c-.164.172-.34.39-.534.63l-.133.164c-.235.29-.497.604-.757.828-.13.113-.278.22-.44.287a.83.83 0 0 1-.578.04.701.701 0 0 1-.426-.387 1.341 1.341 0 0 1-.107-.472c-.02-.303.019-.671.053-.996l.002-.02c.036-.337.068-.634.06-.867a.85.85 0 0 0-.031-.225c-.062-.018-.134-.01-.24.048-.139.078-.292.225-.443.416a4.333 4.333 0 0 0-.49.788l-.005.013-.002.003-.363-.17-.364-.169.001-.002.003-.005.009-.02.032-.064a5.634 5.634 0 0 1 .55-.872c.178-.224.408-.465.68-.617Zm1.306 2.85h.002-.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSignatureMedium;
