import type { SVGProps } from "react";
const SvgFaceDisagreeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 23.5C7 13.835 14.835 6 24.5 6S42 13.835 42 23.5 34.165 41 24.5 41 7 33.165 7 23.5ZM24.5 5C14.283 5 6 13.283 6 23.5S14.283 42 24.5 42 43 33.717 43 23.5 34.717 5 24.5 5Zm0 22c-3.929 0-7.385 1.825-9.568 4.698l.796.605C17.725 29.675 20.89 28 24.5 28c3.611 0 6.775 1.675 8.772 4.303l.796-.605C31.885 28.825 28.43 27 24.5 27Zm-6-7.793-2.646 2.647-.708-.708 2.647-2.646-2.647-2.646.708-.708 2.646 2.647 2.646-2.647.708.708-2.647 2.646 2.647 2.646-.708.708-2.646-2.647Zm12 0-2.646 2.647-.708-.708 2.647-2.646-2.647-2.646.708-.708 2.646 2.647 2.646-2.647.708.708-2.647 2.646 2.647 2.646-.708.708-2.646-2.647Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceDisagreeLarge;
