import type { SVGProps } from "react";
const SvgSmartphoneDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17 17.707 3.646 3.647.708-.707-18-18-.708.707L7 7.707V19.5A1.5 1.5 0 0 0 8.5 21h7a1.5 1.5 0 0 0 1.5-1.5v-1.793Zm-1-1V19.5a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5V8.707l8 8ZM8.5 4h7A1.5 1.5 0 0 1 17 5.5V14h-1V5.5a.5.5 0 0 0-.5-.5H14v1h-4V5H8.5c-.104 0-.247.075-.386.28l-.828-.56C7.506 4.393 7.914 4 8.5 4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneDisabledSmall;
