import type { SVGProps } from "react";
const SvgTrainProfileSignalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m10 2.899.277.185 3 2 .624.416-.624.416L11.651 7H14a4.976 4.976 0 0 1 4.562 3h.01l.06.165c.026.068.051.136.075.205l2.094 5.774c.121.253.199.542.199.856a2 2 0 0 1-2 2h-2.05a2.49 2.49 0 0 1-.45 1H22v1H2v-1h1.5a2.49 2.49 0 0 1-.45-1H2v-1h17a1 1 0 0 0 1-1 .985.985 0 0 0-.108-.44l-.012-.025-.01-.026-.547-1.51H13v-5h4.444A3.97 3.97 0 0 0 14 8h-3.849L10 8.102 9.849 8H2V7h6.349L6.723 5.916 6.099 5.5l.624-.416 3-2L10 2.899Zm7.872 8.1H14v3h4.96l-1.088-3Zm-5.822 8c.075.37.231.71.45 1h-5c.219-.29.375-.63.45-1h4.1Zm-7.965 0h2.83a1.5 1.5 0 0 1-2.83 0Zm9 0a1.5 1.5 0 0 0 2.83 0h-2.83ZM7.901 5.5 10 6.9l2.099-1.4L10 4.102 7.901 5.5ZM4.86 11.848c-1.147 1.187-1.147 3.119 0 4.306l-.72.694c-1.52-1.574-1.52-4.12 0-5.695l.72.695Zm1.5 3.056a1.313 1.313 0 0 1 0-1.806l-.72-.694a2.313 2.313 0 0 0 0 3.194l.72-.694Zm3.5-3.75c1.52 1.574 1.52 4.12 0 5.694l-.72-.695c1.147-1.186 1.147-3.118 0-4.305l.72-.694Zm-1.5 4.444a2.313 2.313 0 0 0 0-3.194l-.72.694c.48.497.48 1.309 0 1.806l.72.694Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainProfileSignalSmall;
