import type { SVGProps } from "react";
const SvgUserHeadsetLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.987 6a8.76 8.76 0 0 0-8.958 8h1.17c.804-3.972 3.95-7 7.801-7 3.85 0 6.997 3.028 7.8 7h1.17a8.76 8.76 0 0 0-8.957-8h-.026Zm6.665 15H34.5a1.5 1.5 0 0 0 1.5-1.5v-4a1.5 1.5 0 0 0-1.5-1.5h-.526A9.76 9.76 0 0 0 24 5a9.76 9.76 0 0 0-9.974 9H13.5a1.5 1.5 0 0 0-1.5 1.5v4a1.5 1.5 0 0 0 1.5 1.5H17v-.642a9.048 9.048 0 0 0 1.32 1.98C12.264 25.293 8 32.794 8 41.5v.5h32v-.5c0-8.705-4.246-16.21-10.319-19.162.36-.41.686-.858.97-1.338ZM9.005 41c.162-8.357 4.387-15.325 10.064-17.91C20.421 24.284 22.127 25 24 25c1.873 0 3.58-.716 4.931-1.91 5.695 2.584 9.902 9.552 10.064 17.91H9.005Zm21.06-21H25v1h4.465c-1.293 1.838-3.277 3-5.465 3-3.809 0-7-3.521-7-8 0-.687.075-1.351.216-1.984a13.16 13.16 0 0 0 4.391-2.38l.007-.005.006-.005a.439.439 0 0 1 .5-.057l.013.007.012.006a4.32 4.32 0 0 0 3.71 0l.012-.006.012-.007a.44.44 0 0 1 .5.056l.007.006.006.005a13.16 13.16 0 0 0 4.392 2.38c.141.633.216 1.297.216 1.984 0 1.466-.342 2.83-.935 4Zm.368-7.164a12.16 12.16 0 0 1-3.4-1.968 1.44 1.44 0 0 0-1.618-.184 3.32 3.32 0 0 1-2.83 0 1.44 1.44 0 0 0-1.618.184 12.163 12.163 0 0 1-3.4 1.968C18.648 9.97 21.151 8 24 8c2.849 0 5.352 1.97 6.433 4.836ZM16 15h-2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5H16v-5Zm10 .5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM19 31h3v1h-5v-1h1v-1h1v1Zm2.5-16a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm12-.5h2.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H32v-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserHeadsetLarge;
