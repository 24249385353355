import type { SVGProps } from "react";
const SvgCashRegisterMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 3.25h14.809l-.362.724-2.947 5.894v2.39c7.335.258 13.234 6.157 13.491 13.492h4.509v7H3.25V3.25Zm1 7h9.5v3h.5c7.013 0 12.728 5.551 12.99 12.5H24v1h7.75v5H4.25v-5h9.25v-1H4.25v-15.5Zm9.691-1H4.25v-5h12.191l-2.5 5Zm4.809 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-1.016-.723a1.999 1.999 0 0 1 3.016 1.723 2 2 0 1 1-3.723-1.016l-3.681-3.681a3.501 3.501 0 1 1-2.096-6.303 3.501 3.501 0 0 1 2.803 5.596l3.68 3.68ZM8.75 18.75a2.501 2.501 0 0 1 5 0 2.501 2.501 0 0 1-5 0Zm20.792 2.733-.513-1.41.94-.342.513 1.41-.94.342Zm-1.81-3.883.75 1.3.866-.5-.75-1.3-.866.5Zm-.742-1.06-.965-1.15.766-.642.965 1.149-.766.643Zm-3.03-3.028 1.15.964.643-.766-1.15-.965-.643.767Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCashRegisterMedium;
