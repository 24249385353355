import type { SVGProps } from "react";
const SvgDocumentCheckMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.743 6.25h-.5v.5l.006 22.5v.5h17.5v-.5l-.003-15.01v-.206l-.146-.147-7.497-7.49-.147-.147H9.743Zm.506 22.5-.006-21.5h8.007v7.5h.5l6.996-.008.003 14.008h-15.5ZM25.04 13.742l-5.79-5.785v5.792l5.79-.007ZM12 20.75h4.5v-1H12v1Zm4.5 4.5H12v-1h4.5v1Zm3.353-3.896 4.5-4.5-.707-.708-4.146 4.147-1.147-1.147-.707.708 1.5 1.5.354.353.353-.353Zm4.5 0-4.5 4.5-.353.353-.354-.353-1.5-1.5.707-.708 1.147 1.147 4.146-4.147.707.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentCheckMedium;
