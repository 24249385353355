import type { SVGProps } from "react";
const SvgThermometerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.25 24.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm0 0V12m5.25 3.75h3m-3-3h3m-3-3h3m-3 9h3m-5.25 3.95V5.25h-6V22.7A5.238 5.238 0 0 0 12 27a5.25 5.25 0 1 0 10.5 0c0-1.782-.892-3.35-2.25-4.3Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgThermometerMedium;
