import type { SVGProps } from "react";
const SvgBellDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.75 4.75h-.5v1.853a7.781 7.781 0 0 0-2.813 1.422l.626.78C14.205 7.89 15.796 7.25 18 7.25c3.137 0 5.05 1.296 6.194 2.886C25.357 11.75 25.75 13.705 25.75 15v7.5h1V15c0-1.455-.432-3.626-1.744-5.448-.938-1.302-2.313-2.409-4.256-2.949V4.75h-5Zm4 1.635V5.75h-3.5v.635A10.959 10.959 0 0 1 18 6.25c.623 0 1.206.047 1.75.135Zm-9.183 6.39A8.59 8.59 0 0 0 10.25 15v9c0 1.538-.602 2.583-1.412 3.25h16.205L10.567 12.775Zm-.8-.801A9.752 9.752 0 0 0 9.25 15v9c0 1.337-.546 2.124-1.198 2.594-.678.488-1.507.656-2.052.656v1h7.78c.12.946.58 1.679 1.275 2.181.792.573 1.844.819 2.945.819 1.1 0 2.154-.246 2.945-.819.695-.502 1.155-1.235 1.274-2.181h3.824l2.104 2.104.707-.707-22.5-22.5-.708.707 4.12 4.12ZM21.163 28.25h.046a2.07 2.07 0 0 1-.85 1.371c-.566.41-1.388.629-2.359.629-.97 0-1.793-.22-2.359-.629a2.07 2.07 0 0 1-.85-1.371h6.372Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBellDisabledMedium;
