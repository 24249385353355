import type { SVGProps } from "react";
const SvgCertificateRibbonTickMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.25 12a7.75 7.75 0 1 1 15.5 0 7.75 7.75 0 0 1-15.5 0ZM18 3.25a8.75 8.75 0 0 0-5.384 15.648L6.326 28.96l-.737 1.179 1.32-.44 4.088-1.363 1.306 2.613.447.894.447-.895 4.797-9.593 4.809 9.618.447.894.447-.894 1.306-2.612 4.089 1.363 1.319.44-.737-1.18-6.3-10.08A8.75 8.75 0 0 0 18 3.25Zm-.803 17.464a8.696 8.696 0 0 1-3.759-1.246l-5.527 8.844 3.18-1.06.412-.138.194.388 1.053 2.106 4.447-8.894Zm5.354-1.239a8.696 8.696 0 0 1-3.76 1.24l4.459 8.917 1.053-2.105.194-.388.411.137 3.181 1.06-5.538-8.861Zm.303-10.121-6 6-.354.353-.354-.353-3-3 .707-.708 2.647 2.647 5.646-5.647.707.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCertificateRibbonTickMedium;
