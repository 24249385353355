import type { SVGProps } from "react";
const SvgSeatWindowLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.51 4h-.5v15h22.98V4H22.51Zm.5 11.26V5h20.98v10.492L36.8 10.1l-.347-.26-.307.306-.375.376a2.5 2.5 0 1 0-3.279 3.279L30.5 15.793l-3.646-3.647-.353-.352-.353.351-3.138 3.114Zm11.52-4.82a1.5 1.5 0 0 1 .43.892l-1.658 1.659a1.5 1.5 0 1 1 1.229-2.552Zm-11.52 6.228V18h20.98v-1.258l-7.443-5.582-5.693 5.694-.354.353-.354-.353-3.647-3.648-3.489 3.462ZM34 8V6h-1v2h1Zm-4.036-.743 1.42 1.42-.707.707-1.42-1.42.707-.707ZM30 11h-2v1h2v-1Zm7.743-3.036-1.42 1.42-.707-.707 1.42-1.42.707.707Zm-27.582 2.802a3.5 3.5 0 1 1 2.678 6.467 3.5 3.5 0 0 1-2.678-6.467Zm2.217-1.18A4.5 4.5 0 0 0 7 14v.038a2.499 2.499 0 0 0-3.539-.783 2.34 2.34 0 0 0-.865 2.822v.001L10 34.566V45.97h23v-9.582l4.426 7.77v.002a1.932 1.932 0 0 0 3.347-1.93l-5.336-9.214a4.007 4.007 0 0 0 .483-2.879l-.002-.008a4.06 4.06 0 0 0-4.011-3.159H28V26a2 2 0 0 0-2-2h-8.217l-2.854-4.757-.858.514 3 5 .146.243H26a1 1 0 0 1 1 1v.97h-9.774a4.5 4.5 0 0 1-3.801-2.034L9.288 17.92a4.5 4.5 0 1 0 3.09-8.333Zm-8.36 4.5-.278-.416.28.415a1.5 1.5 0 0 1 2.13.48l6.42 10.889.005.01.006.009a5.5 5.5 0 0 0 4.651 2.497h14.685a3.06 3.06 0 0 1 3.025 2.377 3.01 3.01 0 0 1-.444 2.285l-2.04 1.302a3.004 3.004 0 0 1-.459.036H10.84L3.523 15.704l-.464.186.463-.188a1.34 1.34 0 0 1 .496-1.617ZM11 34.97h21v10H11v-10Zm27.293 8.69-5.061-8.886a4.003 4.003 0 0 0 1.536-.918l5.139 8.874a.931.931 0 0 1-1.613.931Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSeatWindowLarge;
