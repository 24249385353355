import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaPowerPlugLarge = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 15.5C12 9.149 17.149 4 23.5 4S35 9.149 35 15.5v.004c-.017 1.91-.618 3.61-1.535 5.216l-.004.007-.004.006-9.53 15.528-.428.697-.426-.698-9.469-15.528-.002-.003c-.934-1.559-1.524-3.226-1.602-5.21v-.02Zm1-.01c.072 1.786.6 3.289 1.459 4.723v.002l9.042 14.827 9.1-14.825c.855-1.5 1.384-3.03 1.399-4.72C33.999 9.7 29.298 5 23.5 5 17.704 5 13.005 9.696 13 15.49ZM7.833 32.278c2.293-1.044 5.5-1.842 9.213-2.275l.115.993c-3.652.426-6.747 1.206-8.914 2.192-1.085.493-1.912 1.027-2.461 1.574C5.238 35.307 5 35.83 5 36.32c0 .622.389 1.302 1.292 1.998.896.69 2.224 1.335 3.912 1.887C13.574 41.305 18.27 42 23.5 42c5.23 0 9.925-.694 13.296-1.795 1.688-.552 3.016-1.197 3.911-1.887C41.61 37.622 42 36.942 42 36.32c0-.486-.235-1.005-.773-1.546-.54-.542-1.355-1.072-2.424-1.563-2.136-.981-5.19-1.76-8.798-2.194l.12-.993c3.668.44 6.832 1.239 9.095 2.278 1.13.52 2.06 1.11 2.715 1.766.656.659 1.066 1.416 1.066 2.252 0 1.07-.67 2.01-1.682 2.79-1.02.787-2.467 1.475-4.212 2.045C33.611 42.297 28.806 43 23.5 43c-5.306 0-10.11-.703-13.606-1.845-1.745-.57-3.191-1.258-4.212-2.045C4.668 38.33 4 37.39 4 36.32c0-.842.416-1.605 1.08-2.267.664-.66 1.607-1.254 2.753-1.775ZM22 15c0-1.772 1.494-3 3-3h3v7h-3c-1.734 0-3-1.417-3-3v-1Zm3-4c-1.367 0-2.737.757-3.465 2H19v2h-4v1h4v2h2.538A3.96 3.96 0 0 0 25 20h4v-2h3v-1h-3v-3h3v-1h-3v-2h-4Zm-5 3h1.124c-.08.315-.124.65-.124 1v1c0 .341.044.677.128 1H20v-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaPowerPlugLarge;
