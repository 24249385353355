import type { SVGProps } from "react";
const SvgSaR = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={12}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.266 9.663c-.189-.546-.388-.94-.595-1.181-.204-.241-.515-.41-.928-.506 1.795-.37 2.693-1.488 2.693-3.354 0-1.16-.358-2.051-1.072-2.681C7.647 1.312 6.632 1 5.314 1H1v14h2.526V8.965h.607c.97 0 1.602.491 1.901 1.468L7.422 15H10.1L8.266 9.663ZM4.574 6.937H3.526V2.81h1.08c.8 0 1.367.15 1.703.448.337.298.507.808.507 1.533 0 .673-.196 1.201-.584 1.58-.39.378-.945.567-1.658.567Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaR;
