import type { SVGProps } from "react";
const SvgChevronSmallLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m9.373 11.644 3.95-3.904.703.711L10.436 12l3.588 3.548-.703.711-3.948-3.904-.36-.355.36-.356Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallLeftSmall;
