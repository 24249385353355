import type { SVGProps } from "react";
const SvgStationSurroundingAreaSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.689 4.837c-2.974-1.214-4.806-1.855-6.67-1.849-1.86.006-3.705.656-6.704 1.847L5 4.961V19h5v-2.992h4V19h5V4.964l-.311-.127ZM6 18V5.64c2.832-1.118 4.441-1.647 6.023-1.652 1.579-.005 3.174.513 5.977 1.649V18h-3v-2.99H9V18H6Zm6-12c-1.677 0-3 1.323-3 3s1.323 3 3 3 3-1.323 3-3-1.323-3-3-3ZM8 9c0-2.229 1.77-4 4-4s4 1.771 4 4c0 2.23-1.77 4-4 4s-4-1.77-4-4Zm4.354.354 1.5-1.5-.708-.707L12 8.293l-.646-.646-.708.707 1 1 .354.353.354-.353Zm-8.576 7.085c-.503.249-.934.536-1.246.864-.314.331-.532.734-.532 1.197 0 .642.412 1.165.944 1.563.541.405 1.294.749 2.18 1.03C6.9 21.657 9.328 22 12 22c2.671 0 5.1-.343 6.877-.907.886-.281 1.638-.625 2.18-1.03.531-.398.943-.921.943-1.563 0-.463-.218-.866-.532-1.197-.312-.328-.743-.615-1.247-.863l-.442.897c.443.218.762.442.963.655.2.21.258.379.258.508 0 .18-.117.443-.543.762-.417.312-1.052.614-1.882.878-1.656.525-3.977.86-6.575.86-2.597 0-4.919-.335-6.574-.86-.831-.264-1.466-.566-1.883-.878C3.117 18.943 3 18.68 3 18.5c0-.13.058-.298.258-.509.201-.212.52-.436.964-.655l-.444-.897Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStationSurroundingAreaSmall;
