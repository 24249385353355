import type { SVGProps } from "react";
const SvgTrainTracksMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.25 4.5v3.25H6v1h3.25v5H6v1h3.25v5H6v1h3.25v5H6v1h3.25V30h1v-3.25h15.5V30h1v-3.25H30v-1h-3.25v-5H30v-1h-3.25v-5H30v-1h-3.25v-5H30v-1h-3.25V4.5h-1v3.25h-15.5V4.5h-1Zm16.5 21.25v-5h-15.5v5h15.5Zm0-6h-15.5v-5h15.5v5Zm0-6v-5h-15.5v5h15.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainTracksMedium;
