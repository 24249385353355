import type { SVGProps } from "react";
const SvgHandHeartMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.493 4.751c-2.352 0-4.252 1.874-4.252 4.223 0 .735.186 1.425.515 2.026.182.336.403.649.675.927l.005.006 5.705 5.673.352.35.353-.35 5.697-5.666.017-.016.015-.018c.134-.161.48-.602.609-.828.354-.62.556-1.337.556-2.104 0-.51-.088-.998-.255-1.454a4.23 4.23 0 0 0-3.993-2.769c-1.18 0-2.234.476-2.997 1.24a4.221 4.221 0 0 0-3.002-1.24ZM7.241 8.974c0-1.788 1.444-3.223 3.252-3.223 1.075 0 2.01.512 2.602 1.303l.4.535.4-.536a3.22 3.22 0 0 1 2.597-1.302 3.23 3.23 0 0 1 3.054 2.112c.127.346.195.718.195 1.111 0 .589-.155 1.135-.425 1.607a7.762 7.762 0 0 1-.495.667l-5.328 5.298-5.349-5.32a3.159 3.159 0 0 1-.51-.704v-.001a3.21 3.21 0 0 1-.393-1.547Zm5.005 15.228-5.758-4.023a2 2 0 0 0-2.499 3.123l8.88 7.823.141.125h5.898l1.365 1.355.147.145H33l-.846-.852-7.542-7.596-.008-.008c-1.756-1.69-4.041-2.999-6.431-3.044H15c-.881 0-1.582.338-2.058.87-.468.522-.697 1.206-.702 1.876 0 .069.001.137.005.206ZM5.903 20.99l7.056 4.929c.475.512 1.169.831 2.042.831h3v-1h-3c-.63 0-1.057-.227-1.332-.536-.282-.317-.432-.755-.428-1.21.004-.456.16-.896.447-1.217.28-.312.705-.537 1.313-.537h3.158c2.044.04 4.097 1.172 5.749 2.76l6.691 6.74h-9.768l-1.365-1.355-.146-.145h-5.932l-8.746-7.705-.009-.008-.008-.007a1 1 0 0 1-.155-1.405v-.001a1 1 0 0 1 1.407-.154l.013.01.013.01Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandHeartMedium;
