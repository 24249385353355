import type { SVGProps } from "react";
const SvgStrollerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.004 4h.5a7.497 7.497 0 0 1 7.48 7h.008v.142c.005.118.008.236.008.355v.5h-.008V14.5c0 1.326-.875 2.552-2.025 3.137A1.999 1.999 0 0 1 16 20c-1.106 0-2-.894-2-2h-3a2 2 0 1 1-3.967-.363c-1.152-.583-2.035-1.809-2.035-3.137V9H2V8h3.998v3h5.998l.007-6.5V4Zm6.977 6.997a6.498 6.498 0 0 0-5.978-5.978l-.007 5.978h5.985Zm.01 1.003H5.999v2.5c0 .894.617 1.793 1.457 2.23a1.998 1.998 0 0 1 3.278.27h3.534a1.998 1.998 0 0 1 3.278-.27c.834-.437 1.447-1.334 1.447-2.23V12ZM9 17a1 1 0 1 0 .001 2.001A1 1 0 0 0 9 17Zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStrollerSmall;
