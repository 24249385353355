import type { SVGProps } from "react";
const SvgDocumentTextLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 5h18.723l.149.166 9 10 .128.142V43H11V5Zm1 1v36h26V16h-9V6H12Zm18 .803V15h7.377L30 6.803ZM34 29H15v-1h19v1Zm-19-4h20v-1H15v1Zm7-15h-7V9h7v1Zm-7 3h7v-1h-7v1Zm7 3h-7v-1h7v1Zm-7 17h21v-1H15v1Zm19 4H15v-1h19v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentTextLarge;
