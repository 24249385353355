import type { SVGProps } from "react";
const SvgFactoryMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.5 5.5h7V17l5.2-3.9.8-.6V17l5.2-3.9.8-.6V17l5.2-3.9.8-.6v18h-25v-25Zm1 1v23h23v-15l-5.2 3.9-.8.6v-4.5l-5.2 3.9-.8.6v-4.5l-5.2 3.9-.8.6V6.5h-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFactoryMedium;
