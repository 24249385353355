import type { SVGProps } from "react";
const SvgIr43Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-43-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-43-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.68-1.163v1.954h-1.455v2.642h-2.255v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.662.024V6.922l-2.974 4.303h2.974Zm4.493-3.331c.026-.949.261-1.745.704-2.389.717-1.033 1.795-1.55 3.235-1.55 1.108 0 2.002.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.163.847c.623.142 1.113.459 1.472.949.363.49.545 1.084.545 1.78 0 1.144-.421 2.08-1.265 2.808-.78.675-1.767 1.012-2.958 1.012-1.292 0-2.318-.371-3.078-1.115-.754-.744-1.13-1.748-1.13-3.014v-.15h2.222c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.196-1.02-.586-1.353-.321-.274-.817-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.138.01.27.016.396.016 1.329 0 1.993-.493 1.993-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.672-.403-1.147-.403-.543 0-.962.169-1.257.506-.296.338-.454.83-.475 1.48h-2.191Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-43-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr43Negative;
