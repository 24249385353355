import type { SVGProps } from "react";
const SvgHandFingersSnapLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26 2v10h-1V2h1Zm8.916 2.277-4 6-.832-.554 4-6 .832.554Zm-14 5.446-4-6-.832.554 4 6 .832-.554Zm14.04 2.1a1.5 1.5 0 0 0-1.062.44l-8.478 8.478-.002.003-3.558 3.607-.856.868v-7.706a1.55 1.55 0 0 0-1.595-1.463h-.015c-.415 0-.704.144-.931.392-.244.266-.44.676-.585 1.233v.001l-3.577 13.877 9.64 9.64a9.59 9.59 0 0 0 .234-.089c.37-.145.912-.383 1.586-.748 1.346-.732 3.217-1.977 5.281-4.021a2782.853 2782.853 0 0 1 4.811-4.755l.149-.146.033-.032.007-.007a1.498 1.498 0 0 0 0-2.13 1.5 1.5 0 0 0-2.115 0l-1.41 1.409-.706-.708 2.12-2.12a1.5 1.5 0 0 0-.487-2.448 1.5 1.5 0 0 0-1.636.326l-2.12 2.12-.707-.708 2.83-2.83a1.5 1.5 0 0 0-1.062-2.562 1.502 1.502 0 0 0-.868.277l-2.312 2.331-.025-.025-.686.686-.707-.707 2.83-2.83a2.47 2.47 0 0 1 .243-.213l6.795-6.855.004-.004a1.5 1.5 0 0 0 0-2.138l-.002-.003a1.5 1.5 0 0 0-1.062-.44ZM33.017 24.29l.038-.087a2.5 2.5 0 0 0-1.941-3.43l5.608-5.658a2.5 2.5 0 0 0 0-3.559 2.5 2.5 0 0 0-3.536.001l-8.48 8.48-.003.003L22 22.78v-5.304a2.55 2.55 0 0 0-2.617-2.426c-.691.002-1.245.263-1.661.716-.4.436-.65 1.025-.816 1.658v.001l-3.46 13.42a1.1 1.1 0 0 0-1.157.255l-1.98 1.98a1.1 1.1 0 0 0 0 1.556l10.465 10.465a1.1 1.1 0 0 0 1.556 0l1.98-1.98a1.1 1.1 0 0 0 .272-1.104c.404-.16.967-.41 1.652-.783 1.424-.773 3.372-2.073 5.508-4.189a2776.065 2776.065 0 0 1 4.808-4.751l.148-.145.032-.032.007-.007.002-.002.003-.003a2.5 2.5 0 0 0-1.606-4.272l.04-.09a2.5 2.5 0 0 0-2.158-3.454Zm-19.832 7.567-.049-.049a.1.1 0 0 0-.141 0l-1.98 1.98a.1.1 0 0 0 0 .142L21.48 44.394a.1.1 0 0 0 .141 0l1.98-1.98a.1.1 0 0 0 .029-.084l-.03.009-.205-.206-10.01-10.07-.2-.202v-.005Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandFingersSnapLarge;
