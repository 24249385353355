import type { SVGProps } from "react";
const SvgMugHotSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 2c0 .445.211.933.536 1.304C7.87 3.685 8.373 4 9 4c.557 0 .935.458.963 1.025a.974.974 0 0 1-.205.682C9.625 5.864 9.397 6 9 6c-1.054 0-2 .802-2 2h1c0-.602.454-1 1-1 .653 0 1.175-.239 1.52-.645.336-.395.466-.902.442-1.38C10.915 4.042 10.242 3 9 3c-.273 0-.52-.135-.711-.354C8.089 2.417 8 2.155 8 2H7Zm11.5 10H16V9H5v12h11v-2h3v-7h-.5ZM16 18h2v-5h-2v5ZM11.536 3.304C11.211 2.933 11 2.445 11 2h1c0 .155.089.417.289.646.192.219.438.354.711.354 1.243 0 1.915 1.042 1.962 1.975.024.478-.106.985-.442 1.38-.345.406-.867.645-1.52.645-.546 0-1 .398-1 1h-1c0-1.198.946-2 2-2 .396 0 .625-.136.758-.293a.974.974 0 0 0 .205-.682C13.935 4.458 13.557 4 13 4c-.627 0-1.13-.315-1.464-.696ZM6 20V10h9v10H6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMugHotSmall;
