import type { SVGProps } from "react";
const SvgFolderOpenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 5h7.01v2h8v2h2.992l-.218.658-2.65 8-.114.342H4.01v-.5L4 5.5V5Zm14.01 4V8h-8V6H5l.007 8.614L6.89 9.332 7.007 9H18.01ZM5.219 17h13.079l2.32-7H7.711L5.22 17Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderOpenSmall;
