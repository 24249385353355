import type { SVGProps } from "react";
const SvgLocomotiveViaductLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.5 5c-.747 0-1.397.409-1.74 1.014a2 2 0 0 0-.118 3.954c.356.33.834.532 1.358.532.473 0 .908-.165 1.25-.439a1.994 1.994 0 0 0 2.25.171 1.994 1.994 0 0 0 2.437-.342 1.496 1.496 0 0 0 1.563-.272 1.5 1.5 0 1 0 0-2.236 1.494 1.494 0 0 0-1.563-.273 2.002 2.002 0 0 0-.614-.432 2 2 0 0 0-3.11-.709A1.999 1.999 0 0 0 21.5 5ZM28 8.5a.5.5 0 0 0-.844-.363l-.383.363.383.363A.5.5 0 0 0 28 8.5Zm1 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm-8.444-1.832a1 1 0 0 1 1.942.39l-.042.751.709-.252a.98.98 0 0 1 .044-.014l.256-.078.078-.256a1 1 0 0 1 1.914 0l.078.256.256.078c.25.075.46.247.584.473l.269.484-.269.484a1 1 0 0 1-1.541.261l-.334-.298-.334.298a.999.999 0 0 1-1.5-.193l-.416-.626-.416.627a.999.999 0 0 1-1.582.11l-.134-.15-.2-.016a1 1 0 0 1 .139-1.995l.375.02.124-.354Zm7.945 4.326h-.5v3h-8v-2.993h-.5l-7-.006h-.5v10.999h2.267a1.999 1.999 0 0 0 3.465 0h2.535a1.999 1.999 0 0 0 3.465 0h2.536a2 2 0 0 0 3.465 0h4.282l-.307-.7-1.707-3.896L32 14.494v-.5h-1v-3h-2.5Zm1.5 10h2.485l-1.312-2.993H13.001v2.993h1a2 2 0 1 1 4 0h2a2 2 0 1 1 4 0h2a2 2 0 0 1 4 0Zm1-3.993h-18v-5.006l6 .006v2.993h12v2.007Zm-1-3.007v-2h-1v2h1ZM43.999 24H6v-1h38v1Zm-30.998 5.001a3 3 0 0 1 6 0v12.5h1v-12.5a3 3 0 1 1 6 0v6.018a22.4 22.4 0 0 1 1.001-1.107v-4.911a3 3 0 1 1 6 0v.513c3.245-1.626 6.97-2.513 10.998-2.513v1c-9.861 0-17.774 5.515-21.032 14.176l-.122.324H6v-13.5a3 3 0 0 1 6 0v12.5h1v-12.5Zm5 12.5v-12.5a2 2 0 1 0-4 0v12.5h4Zm14.001-11.454v-1.046a2 2 0 0 0-4 0v3.94a22.094 22.094 0 0 1 4-2.894Zm-7.001 6.246v-7.292a2 2 0 1 0-4 0v12.5h1.155a23.291 23.291 0 0 1 2.845-5.208ZM7 29.001a2 2 0 0 1 4 0v12.5H7v-12.5Zm14.001-8.006A1 1 0 1 1 23 20.993a1 1 0 0 1-1.999.002Zm-5-1a1 1 0 1 0-.002 1.998A1 1 0 0 0 16 19.995Zm11 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveViaductLarge;
