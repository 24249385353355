import type { SVGProps } from "react";
const SvgPaperAeroplaneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.086 3.635-.088.913-1.5 15.5-.084.862-.705-.503-4.575-3.268-1.696 3.09-.938 1.709V15.39l-7.621-1.905-1.288-.322 1.18-.608 16.5-8.5.815-.42ZM11.5 15.971l.815.582-.815 1.485v-2.067Zm.223-1.07 7.12-8.794-1.257 12.982-5.863-4.187Zm-.911-.464-6.403-1.6 13.207-6.804-6.804 8.404Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPaperAeroplaneSmall;
