import type { SVGProps } from "react";
const SvgSunshadeSunSandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.5 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM15 6.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM5.251 7.872a6.5 6.5 0 0 1 8.88 2.379l.25.433-.434.25-4.788 2.764L12.809 21H22v1H2v-1h9.691l-3.4-6.8-4.737 2.734-.433.25-.25-.433a6.5 6.5 0 0 1 2.377-8.877m.503.864c-.465.268-.778.967-.71 2.12.06 1 .41 2.22 1.054 3.454l4.312-2.489c-.748-1.175-1.629-2.087-2.466-2.64-.963-.636-1.725-.714-2.19-.445Zm2.194-.71c.185.095.369.202.547.32.984.65 1.968 1.686 2.782 2.975l1.72-.994a5.504 5.504 0 0 0-5.05-2.301Zm-2.716 6.785c-.71-1.35-1.115-2.719-1.185-3.896a5.748 5.748 0 0 1-.004-.634 5.504 5.504 0 0 0-.533 5.524l1.722-.994Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunshadeSunSandSmall;
