import type { SVGProps } from "react";
const SvgCirclePlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.5 4a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM4 11.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Zm4 .5v-1h4V7h1v4h4v1h-4v4h-1v-4H8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCirclePlusSmall;
