import type { SVGProps } from "react";
const SvgBookmarkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#bookmark-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M9.25 3.25h17.5v20.207l-.146.147-8.25 8.25-.354.353-.354-.353-8.25-8.25-.146-.147V3.25Zm1 1v18.793l7.75 7.75 7.75-7.75V4.25h-15.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="bookmark-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBookmarkMedium;
