import type { SVGProps } from "react";
const SvgChristmasTreeShoppingBagLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m16.5 3.919.474 1.423L17.86 8h4.064l-1.113.89-2.227 1.783.331.994 4.982 6.53.613.803h-2.99l6.175 7.94.305-5.476.026-.472H33v-3.5a2.5 2.5 0 1 1 5 0l-.003 3.5h4.976l.026.473 1 18 .03.527H26.972l.029-.527.137-2.465H16.999v3h-1v-3H2.477l.628-.807L9.477 28h-5l.628-.807L11.477 19H8.49l.613-.803 4.98-6.528.332-.996-2.227-1.782L11.074 8h4.065l.886-2.659.474-1.423Zm20.496 18.073-.002 3h1l.002-3h4.031l.945 17H28.029l.258-4.664.686-12.336H33v3h1v-3h2.996Zm.001-1 .003-3.5a1.5 1.5 0 0 0-3 0v3.5h2.997ZM27.5 28h.139l-.275 4.939-3.841-4.94H27.5Zm-5.394-.193 5.174 6.652L27.193 36H4.522l6.372-8.193.628-.807h-5l6.372-8.193.628-.807h-3.013l2.598-3.403-.118.351 1.322-1.058 2.188-1.75 2.188 1.75 1.322 1.058-.118-.353L22.49 18h-3.012l.628.807L26.477 27h-5l.628.807ZM16.498 7.08l-.525 1.577L15.86 9h-1.935l1.386 1.11.273.217-.11.331-.465 1.394 1.178-.942.312-.25.313.25 1.177.942-.464-1.394-.11-.33.272-.218L19.074 9h-1.935l-.114-.342-.526-1.577Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChristmasTreeShoppingBagLarge;
