import type { SVGProps } from "react";
const SvgCarPowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.92 3.994A1.68 1.68 0 0 0 5.994 5.92L6 5.96V8h6.05a2.5 2.5 0 0 1 4.9 0H18V5.96l.006-.04a1.68 1.68 0 0 0-1.926-1.926L16.04 4H7.96l-.04-.006ZM15.914 8a1.5 1.5 0 0 0-2.828 0h2.828ZM18 9H6v4h12V9Zm1 4.5V8h1V7h-1v-.962A2.679 2.679 0 0 0 15.962 3H8.038A2.68 2.68 0 0 0 5 6.038V7H4v1h1v8h4v-2h6v2h4v-2.5ZM6 15v-1h2v1H6Zm10 0v-1h2v1h-2Zm-9-4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm8 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-1 7v-1h-1.5a2.5 2.5 0 0 0-2.45 2H7v1h3.05a2.5 2.5 0 0 0 2.45 2H14v-1h2v-1h-2v-1h2v-1h-2Zm-2.56.44A1.5 1.5 0 0 1 12.5 18h.5v3h-.5a1.5 1.5 0 0 1-1.06-2.56Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarPowerPlugSmall;
