import type { SVGProps } from "react";
const SvgCloudRainSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 2v2h1V2h-1Zm-.01 5.717a3.215 3.215 0 0 0-1.066-1.18A2.51 2.51 0 0 1 16.5 6C17.915 6 19 7.085 19 8.5c0 1.013-.556 1.852-1.383 2.25a2.116 2.116 0 0 0-1.304-.895c.124-.803-.005-1.53-.323-2.138Zm2.053 4.678c.015-.24 0-.482-.046-.718C19.196 11.125 20 9.924 20 8.5 20 6.533 18.467 5 16.5 5c-1.016 0-1.923.41-2.56 1.086-1.311-.361-2.844.06-3.732 1.504-1.516-.719-3.43.326-3.522 2.177-.481.128-.87.43-1.144.814-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.95 0 1.698-.308 2.22-.81a2.727 2.727 0 0 0 .823-1.796Zm-4.188-5.287c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.166.018.434.175.846.473 1.147.293.295.763.524 1.492.524h7c.725 0 1.214-.23 1.528-.53.319-.307.49-.72.517-1.14.027-.423-.093-.823-.318-1.102-.215-.267-.541-.447-1.007-.428l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073Zm-.931-1.484-1.441-1.386.693-.72 1.44 1.386-.692.72Zm6.515 6.593 1.414 1.414.707-.707-1.413-1.414-.708.707ZM21 8h2v1h-2V8Zm-.258-4.465L19.328 4.95l.707.707 1.414-1.414-.707-.707ZM8.052 17.776l1-2 .895.448-1 2-.894-.448Zm7-2-1 2 .895.448 1-2-.894-.448Zm-6 4 2-4 .895.448-2 4-.894-.448Zm4.05-4-2 4 .895.448 2-4-.894-.448Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSunSmall;
