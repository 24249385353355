import type { SVGProps } from "react";
const SvgMugHotMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.75 3c0 .595.286 1.27.742 1.792.465.53 1.156.958 2.008.958 1.007 0 1.647.833 1.694 1.775.023.46-.105.89-.366 1.198-.253.297-.669.527-1.328.527-1.454 0-2.75 1.102-2.75 2.75h1c0-1.052.804-1.75 1.75-1.75.916 0 1.625-.332 2.09-.88.455-.535.636-1.23.603-1.895-.065-1.308-1-2.725-2.693-2.725-.498 0-.932-.248-1.255-.617-.331-.378-.495-.828-.495-1.133h-1Zm17 15.25h-4v-4.5h-16v17.5h16v-3h4.5v-10h-.5Zm-4 9h3.5v-8h-3.5v8ZM17.492 4.792C17.037 4.27 16.75 3.595 16.75 3h1c0 .305.164.755.495 1.133.323.37.757.617 1.255.617 1.693 0 2.628 1.417 2.693 2.725.033.665-.148 1.36-.603 1.896-.465.546-1.174.879-2.09.879-.946 0-1.75.698-1.75 1.75h-1c0-1.648 1.296-2.75 2.75-2.75.659 0 1.075-.23 1.328-.527.261-.308.39-.738.366-1.198-.047-.942-.687-1.775-1.694-1.775-.852 0-1.543-.427-2.008-.958ZM8.75 30.25v-15.5h14v15.5h-14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMugHotMedium;
