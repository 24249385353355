import type { SVGProps } from "react";
const SvgTabletMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.75 6.75a1 1 0 0 1 1-1h16.5a1 1 0 0 1 1 1v24a1 1 0 0 1-1 1H9.75a1 1 0 0 1-1-1v-24Zm1-2a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h16.5a2 2 0 0 0 2-2v-24a2 2 0 0 0-2-2H9.75ZM21 7.25h-6v-1h6v1Zm0 22.5h-6v-1h6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTabletMedium;
