import type { SVGProps } from "react";
const SvgHeartFilledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.2 7.776A4.977 4.977 0 0 0 15.5 4.5a4.952 4.952 0 0 0-3.997 2.018A4.969 4.969 0 0 0 7.5 4.5a4.983 4.983 0 0 0-5.006 4.997 5.09 5.09 0 0 0 1.397 3.493l7.61 7.61 7.598-7.6c.18-.218.606-.768.746-1.015.417-.73.655-1.58.655-2.488 0-.606-.106-1.185-.3-1.72Z"
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
    />
  </svg>
);
export default SvgHeartFilledSmall;
