import type { SVGProps } from "react";
const SvgHandSafetyShieldMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#hand-safety-shield-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="m18 3.238.109.024 6.75 1.5.391.087v.401c0 5-1.733 8.482-3.48 10.714a14.734 14.734 0 0 1-2.404 2.439 11.088 11.088 0 0 1-1.031.73l-.065.039-.018.01-.006.003-.002.001L18 18.75l-.243.437-.003-.002-.005-.003-.018-.01a7.079 7.079 0 0 1-.295-.184 11.1 11.1 0 0 1-.802-.585 14.744 14.744 0 0 1-2.402-2.437c-1.748-2.23-3.482-5.708-3.482-10.701v-.4l.39-.088 6.75-1.515.11-.024Zm0 15.512.243.437-.243.135-.242-.135.242-.437Zm0-.588.015-.01c.174-.114.425-.29.729-.531a13.743 13.743 0 0 0 2.238-2.273c1.581-2.02 3.175-5.164 3.264-9.698l-6.245-1.388-6.247 1.402c.09 4.527 1.683 7.667 3.265 9.686a13.742 13.742 0 0 0 2.238 2.27c.304.242.555.418.728.532l.015.01ZM6.48 20.18l5.755 4.018a2.973 2.973 0 0 1-.005-.202c.005-.67.234-1.353.702-1.876.475-.532 1.174-.87 2.053-.87h3.358v.006c2.326.11 4.529 1.404 6.243 3.043l.01.008 7.544 7.59.848.853H20.405l-.146-.145-1.37-1.355h-5.891l-.142-.125-8.888-7.824a2.003 2.003 0 0 1-.29-2.812 2.003 2.003 0 0 1 2.804-.31Zm11.665 2.07h-3.16c-.605 0-1.03.224-1.308.536a1.855 1.855 0 0 0-.447 1.218c-.004.456.145.893.427 1.21.274.309.7.536 1.329.536h3v1h-3c-.88 0-1.577-.325-2.053-.845L5.894 20.99l-.015-.01-.014-.012a1.003 1.003 0 0 0-1.412.153 1.003 1.003 0 0 0 .153 1.411l.008.006.007.007 8.753 7.705h5.927l.146.145 1.37 1.355h9.767l-6.694-6.733c-1.659-1.585-3.7-2.724-5.745-2.767Zm-.466-9.896 3.75-3.75-.707-.708-3.397 3.397-1.521-1.522-.707.707 1.874 1.876.354.353.354-.354Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="hand-safety-shield-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandSafetyShieldMedium;
