import type { SVGProps } from "react";
const SvgTaxiMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.75 7h-.5v2.25H12c-1.348 0-2.44.313-3.188 1.062-.749.749-1.062 1.84-1.062 3.188v1.75H6v1h1.75v12h5.5v-3h9.5l.002 2.5v.5h5.498v-12H30v-1h-1.75V13.5c0-1.348-.313-2.44-1.062-3.188C26.44 9.563 25.348 9.25 24 9.25h-3.25V7h-5Zm4 2.25V8h-3.5v1.25h3.5ZM9.519 11.019c-.486.486-.769 1.27-.769 2.481v3.25h9.535a3.501 3.501 0 0 1 6.93 0h2.035V13.5c0-1.211-.282-1.995-.769-2.481-.486-.486-1.27-.769-2.481-.769H12c-1.211 0-1.995.283-2.481.769ZM8.75 24.25v-6.5h18.5v6.5H8.75Zm15 1h3.5v2h-3.499v-2Zm-15 0h3.5v2h-3.5v-2Zm15.45-8.5a2.501 2.501 0 0 0-4.9 0h4.9ZM10.75 21a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm12-1a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM23.75 21c0-.138.113-.25.25-.25s.25.112.25.25a.25.25 0 0 1-.5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTaxiMedium;
