import type { SVGProps } from "react";
const SvgLotusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.342 4.135 18 3.815l-.342.32C14.603 7 13.04 9.755 12.45 12.297c-.59 2.545-.194 4.831.587 6.737.78 1.9 1.944 3.425 2.907 4.473.483.524.917.932 1.233 1.21a11.85 11.85 0 0 0 .482.402l.029.022.008.006.002.002h.001l.3-.399-.3.4.3.225.3-.225-.3-.4.301.4.001-.001.003-.002.008-.006.029-.022.105-.084c.09-.073.218-.18.376-.318.316-.278.75-.686 1.233-1.21.963-1.048 2.128-2.573 2.907-4.473.782-1.906 1.178-4.192.587-6.737-.59-2.543-2.152-5.298-5.207-8.162Zm-.18 19.831-.162.14a15.515 15.515 0 0 1-1.32-1.276c-.912-.993-1.997-2.42-2.718-4.176-.718-1.75-1.072-3.824-.538-6.13.514-2.218 1.86-4.694 4.576-7.333 2.715 2.639 4.061 5.115 4.575 7.332.535 2.307.18 4.38-.538 6.131-.72 1.756-1.805 3.183-2.718 4.176-.455.494-.864.878-1.157 1.136Zm-7.486-13.623a13.173 13.173 0 0 0-5.163-.888l-.391.012-.082.384c-1.5 7.067 2.966 14.028 10.017 15.608l.219-.976c-6.387-1.43-10.478-7.639-9.334-14.035 1.5.003 2.979.29 4.374.828l.36-.933Zm21.22 8.682a13.412 13.412 0 0 1 2.455 1.739l.294.261-.185.347a13.21 13.21 0 0 1-13.531 6.863l.142-.99a12.21 12.21 0 0 0 12.313-5.995 12.407 12.407 0 0 0-1.97-1.35l.482-.875Zm-30.247 1.74a13.466 13.466 0 0 1 2.455-1.74l.483.876c-.699.386-1.36.838-1.971 1.35a12.212 12.212 0 0 0 12.313 5.994l.142.99a13.212 13.212 0 0 1-13.53-6.862l-.186-.347.294-.262Zm28.409-10.317c-1.5.003-2.979.29-4.374.828l-.36-.933a13.173 13.173 0 0 1 5.163-.888l.392.012.08.384c1.5 7.067-2.964 14.028-10.016 15.608l-.219-.976c6.387-1.43 10.479-7.639 9.334-14.035Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLotusMedium;
