import type { SVGProps } from "react";
const SvgWalkLargeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.5 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.389 1.421a2.5 2.5 0 1 1 2.778 4.157 2.5 2.5 0 0 1-2.778-4.157ZM20.53 14.03h-.175l-.136.109-5.53 4.4-.116.092-.047.14-2 6-.001.003a1.69 1.69 0 0 0 1.548 2.215h.01a1.69 1.69 0 0 0 1.633-1.177v-.001l1.713-5.293 2.531-1.997v7.949l-.634 7.866-5.87 5.87-.354.355.356.353 1.6 1.59.353.351.352-.351 6.59-6.57.122-.122.021-.17.946-7.642h.308L28 33.666V43h3V32.348l-.084-.126L27 26.348v-7.707l3.661 3.376.092.085.122.032 6.18 1.6a1.57 1.57 0 0 0 1.82-.866l-.455-.208.46.194a1.56 1.56 0 0 0-.956-2.09l-.01-.003-.01-.003-5.76-1.607-5.27-4.985-.145-.136H20.53Zm17.473 8.21a.557.557 0 0 1-.04.218.57.57 0 0 1-.658.308l-6.058-1.569-4.408-4.065-.839-.774v10.293l.084.126L30 32.651V42h-1v-8.667l-.1-.133-4.5-6-.15-.2h-1.692l-.054.438-.979 7.91-6.116 6.096-.89-.885 5.645-5.646.13-.13.014-.183.65-8.07.002-.02V16.458l-.81.64-3.46 2.73-.119.093-.047.145-1.76 5.44v.003a.69.69 0 0 1-.662.48.69.69 0 0 1-.627-.902v-.002l1.952-5.856 5.278-4.2h5.626l5.215 4.934.09.085.12.033 5.868 1.637a.561.561 0 0 1 .38.522Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkLargeLarge;
