import type { SVGProps } from "react";
const SvgCroissantSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.9 2.772c-.256-.57-.905-.92-1.552-.712a12.899 12.899 0 0 0-8.288 8.316c-.161.502.011 1.01.366 1.332a1.167 1.167 0 0 0-.16.437 10.507 10.507 0 0 0 .272 4.434c.125.448.486.749.9.84.335 1.45 1.062 2.654 1.872 3.46.44.438.921.774 1.397.959.47.183.992.24 1.452.012.456-.225.748-.65.93-1.11.183-.463.276-1.015.303-1.585a9.007 9.007 0 0 0-.39-2.99c.3-.155.514-.43.61-.742.116-.381.266-.744.447-1.085a1.23 1.23 0 0 0 .14-.48l.338.086a1.286 1.286 0 0 0 1.316-.442 12.01 12.01 0 0 1 1.659-1.677c.346-.285.534-.732.468-1.187.164-.014.33-.061.486-.148.337-.185.696-.34 1.073-.46.29-.093.546-.29.7-.562a8.738 8.738 0 0 0 2.975.35c.563-.035 1.106-.138 1.562-.33.453-.19.868-.49 1.084-.948.217-.46.16-.98-.023-1.448-.185-.475-.518-.954-.954-1.392-.795-.801-1.98-1.516-3.409-1.843a1.194 1.194 0 0 0-.855-.95 10.321 10.321 0 0 0-4.413-.23 1.17 1.17 0 0 0-.307.095Zm5.449 2.083-.837 3.65a7.74 7.74 0 0 0 2.638.316c.492-.032.917-.12 1.238-.255.324-.136.495-.3.568-.454.058-.121.075-.335-.05-.657-.124-.316-.367-.683-.732-1.05-.647-.652-1.628-1.256-2.825-1.55Zm-13.833 7.29 5.54 1.42c.122.039.185.18.12.304a7.142 7.142 0 0 0-.521 1.262c-.03.095-.092.145-.15.159l-4.75 1.152c-.123.03-.23-.046-.254-.133a9.507 9.507 0 0 1-.246-4.012c.014-.095.126-.187.26-.153Zm5.776.448.029.007 1.464.376c.1.025.213-.008.293-.106a13.002 13.002 0 0 1 1.798-1.816.297.297 0 0 0 .107-.305l-.265-1.007v-.004l-1.715-6.512c-.047-.178-.22-.255-.347-.214a11.899 11.899 0 0 0-7.644 7.67c-.044.135.041.311.214.356l6.066 1.555Zm-3.277 7.576c-.659-.655-1.274-1.65-1.577-2.864l3.6-.873c.265.836.396 1.817.355 2.676-.023.5-.104.935-.235 1.264-.131.334-.292.508-.442.582-.116.057-.324.078-.646-.048-.316-.123-.684-.368-1.055-.737Zm7.67-10.686v.002a.205.205 0 0 0 .3.13 7.043 7.043 0 0 1 1.249-.537c.091-.03.141-.092.156-.155l1.099-4.79c.03-.131-.052-.238-.134-.26a9.32 9.32 0 0 0-3.985-.21.173.173 0 0 0-.12.087.239.239 0 0 0-.027.183l1.461 5.55Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCroissantSmall;
