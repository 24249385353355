import type { SVGProps } from "react";
const SvgCheckpointsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.494 11a5.506 5.506 0 1 1 11.012 0 5.506 5.506 0 0 1-11.012 0ZM11 4.506a6.494 6.494 0 1 0 6.476 6.988H39A3.506 3.506 0 0 1 42.506 15v4A3.506 3.506 0 0 1 39 22.506l-9.524-.001a6.495 6.495 0 0 0-12.952 0H9A4.494 4.494 0 0 0 4.506 27v6A4.494 4.494 0 0 0 9 37.494h21.524a6.495 6.495 0 1 0 0-.988H9A3.506 3.506 0 0 1 5.494 33v-6A3.506 3.506 0 0 1 9 23.494h7.524a6.495 6.495 0 0 0 12.952 0H39A4.494 4.494 0 0 0 43.494 19v-4A4.494 4.494 0 0 0 39 10.506H17.476a6.495 6.495 0 0 0-6.476-6Zm-.65 8.843 4-4-.7-.698L10 12.3l-1.65-1.65-.7.699 2 2 .35.349.35-.35ZM37 31.494a5.506 5.506 0 1 0 0 11.012 5.506 5.506 0 0 0 0-11.012ZM36 39.7l.35-.35 4-4-.7-.698L36 38.3l-1.65-1.65-.7.698 2 2 .35.35ZM23 17.493a5.506 5.506 0 1 0 0 11.012 5.506 5.506 0 0 0 0-11.012Zm-.65 7.856 4-4-.7-.7L22 24.3l-1.65-1.65-.7.699 2 2 .35.35.35-.35Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCheckpointsLarge;
