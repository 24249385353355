import type { SVGProps } from "react";
const SvgClipboardListSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.5 2H9v1H3v19h18V3h-6V2H9.5Zm.5 1.5V3h4v1h-4v-.5ZM9 4H4v17h16V4h-5v1H9V4Zm9 6H6V9h12v1ZM6 13h12v-1H6v1Zm12 3H6v-1h12v1ZM6 19h12v-1H6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardListSmall;
