import type { SVGProps } from "react";
const SvgCoinDollarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.25C10.406 4.25 4.25 10.406 4.25 18S10.406 31.75 18 31.75 31.75 25.594 31.75 18 25.594 4.25 18 4.25ZM3.25 18C3.25 9.853 9.853 3.25 18 3.25S32.75 9.853 32.75 18 26.147 32.75 18 32.75 3.25 26.147 3.25 18Zm15.402 6.127c.822-.006 1.494-.264 2.02-.77.523-.507.784-1.155.784-1.948 0-.813-.225-1.457-.68-1.928-.453-.47-1.16-.795-2.124-.972v5.618Zm-1.167-12.7c-.687.02-1.225.166-1.614.434-.694.482-1.042 1.175-1.042 2.082 0 .708.217 1.268.65 1.677.431.413 1.099.695 2.006.85v-5.044Zm-4.726 9.3h1.804c0 2.155.975 3.287 2.922 3.4v-5.873c-1.083-.183-1.996-.513-2.74-.987-1.133-.715-1.7-1.774-1.7-3.175 0-1.197.402-2.172 1.205-2.927.805-.754 1.882-1.162 3.235-1.227v-1.9h1.167v1.9c1.346.107 2.366.504 3.059 1.19.751.736 1.119 1.707 1.104 2.91H21.03c-.112-1.677-.906-2.546-2.379-2.601v5.32c.894.227 1.51.4 1.854.52.344.12.665.277.962.467 1.182.765 1.773 1.93 1.773 3.494 0 1.614-.659 2.844-1.976 3.685-.63.405-1.501.638-2.613.702v2.337h-1.167v-2.337c-1.593-.099-2.776-.574-3.547-1.423-.794-.878-1.187-2.036-1.18-3.474ZM13.3 7.235a11.768 11.768 0 0 0-6.607 7.633l.964.267c.87-3.135 3.107-5.7 6.044-6.983l-.4-.917Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCoinDollarMedium;
