import type { SVGProps } from "react";
const SvgArmchairSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.44 3.44A1.5 1.5 0 0 1 7.5 3h9A1.5 1.5 0 0 1 18 4.5v4.555a2.501 2.501 0 0 0-1.97 2.445H16V13H8v-1.5a2.5 2.5 0 0 0-2-2.451V4.5c0-.398.158-.78.44-1.06Zm.966 8.06H7a1.5 1.5 0 1 0-2.25 1.297l.25.144v3.59a1.5 1.5 0 0 0 1.5 1.5h11.03a1.5 1.5 0 0 0 1.5-1.5v-3.59l.25-.144a1.5 1.5 0 1 0-2.25-1.297h-.406H17v3a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 7 14.5v-3h.406ZM8 14v.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V14H8Zm11-9.5v4.543a2.5 2.5 0 0 1 1.03 4.455v3.032A2.5 2.5 0 0 1 19 18.552v2.016A1.421 1.421 0 0 1 17.568 22h-.136A1.417 1.417 0 0 1 16 20.568V19.03H8v1.538A1.42 1.42 0 0 1 6.568 22h-.135A1.418 1.418 0 0 1 5 20.568V18.53a2.5 2.5 0 0 1-1-2v-3.032a2.5 2.5 0 0 1 1-4.45V4.5A2.5 2.5 0 0 1 7.5 2h9A2.5 2.5 0 0 1 19 4.5Zm-1.47 14.53c.159 0 .316-.015.47-.045v1.591a.42.42 0 0 1-.424.424h-.151a.42.42 0 0 1-.425-.424V19.03h.53Zm-11.03 0H7v1.546a.42.42 0 0 1-.424.424h-.151A.42.42 0 0 1 6 20.576V18.98c.163.033.33.05.5.05Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArmchairSmall;
