import type { SVGProps } from "react";
const SvgArrowDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12 16.293 3.146-3.147.708.707-4 4-.354.354-.354-.354-4-4 .708-.707L11 16.293V5h1v11.293Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowDownSmall;
