import type { SVGProps } from "react";
const SvgWheelchairUncertainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.138 8.29c1.653-.81 3.7-.72 5.369.094l.008.004.008.004c1.067.57 2.006 1.606 2.306 2.932.306 1.35-.068 2.907-1.456 4.459l-.01.01-.01.01-2.238 2.239c-.67.761-.993 1.331-1.163 1.95-.178.644-.202 1.376-.202 2.508h-1c0-1.114.019-1.98.238-2.773.224-.816.648-1.52 1.387-2.358l.01-.012.011-.01 2.24-2.241c1.208-1.354 1.438-2.588 1.218-3.56-.226-.995-.942-1.81-1.794-2.268-1.424-.691-3.142-.747-4.481-.09-1.31.643-2.329 2.002-2.329 4.312h-1c0-2.653 1.203-4.382 2.888-5.21ZM9.25 20.25V12h1v7.75h7.5v8.75h-1v-7.75h-7.5v-.5Zm-6 1.501c0-2.525 1.459-4.723 3.57-5.797l.453.89c-1.791.912-3.023 2.776-3.023 4.907 0 3.021 2.477 5.5 5.5 5.5 2.187 0 4.093-1.3 4.976-3.17l.904.428c-1.04 2.204-3.289 3.742-5.88 3.742-3.575 0-6.5-2.927-6.5-6.5Zm22.567 3.746v3h1v-3h-1ZM9.5 9a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairUncertainMedium;
