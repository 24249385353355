import type { SVGProps } from "react";
const SvgWalkFastSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.991 5.5c0-.257.243-.5.5-.5s.5.243.5.5-.243.5-.5.5a.527.527 0 0 1-.5-.5Zm.5-1.5c-.809 0-1.5.69-1.5 1.5s.691 1.5 1.5 1.5c.81 0 1.5-.69 1.5-1.5s-.69-1.5-1.5-1.5ZM7 6h5v1H7V6Zm7.5 2h-.15l-.127.084-3 2-.153.102-.05.177-1 3.5.961.274.95-3.323 1.843-1.229-.763 3.817-.01.049-.11.549h.11v2.293l-2.854 2.853.707.707 3-3 .147-.146V14h1.292l1.66 1.66-.941 4.231.976.218 1.001-4.5.06-.268-.194-.195-1.812-1.81.765-3.822 1.339 1.34.073.072.095.038 2.5 1 .371-.928-2.404-.962-1.928-1.928L16.708 8H14.5Zm-.39 5 .8-4h.981l-.8 4h-.98ZM5 9v1h4V9H5Zm-2 4h5v1H3v-1Zm7 3H5v1h5v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkFastSmall;
