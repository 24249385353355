import type { SVGProps } from "react";
const SvgTranslateSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 3H2v10h3v3.028l.854-.854L8 13.028V20h8.002l2.144 2.145.854.853V20h3V10h-6V3H2.5ZM15 10V4H3v8h3v1.614l2-2V10h7Zm6 9h-3v1.584l-1.437-1.438-.147-.146H9v-8h12v8ZM4 6h10v1H4V6Zm8 2H4v1h8V8Zm8 5v1H10v-1h10Zm0 3v-1H10v1h10ZM2 19.5V15h1v4h2.085l-.914-.914.707-.707 1.768 1.768L7 19.5l-.354.353-1.768 1.767-.707-.707.915-.914H2v-.5ZM18.914 5H22v4h-1V6h-2.086l.914.914-.707.707-1.768-1.768-.354-.354.354-.353 1.768-1.767.707.707-.914.914Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTranslateSmall;
