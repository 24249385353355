import type { SVGProps } from "react";
const SvgServiceBellMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.47 7.25h-3v-1h3v1Zm-9.123 5.995C12.073 10.872 14.653 9.25 18 9.25c3.348 0 5.923 1.622 7.648 3.995 1.715 2.361 2.594 5.468 2.602 8.503l.001.502H7.75v-.5c0-3.037.88-6.144 2.597-8.505ZM8.758 21.25H27.24c-.097-2.695-.92-5.379-2.401-7.417-1.565-2.153-3.857-3.584-6.839-3.584-2.983 0-5.278 1.431-6.844 3.584-1.483 2.039-2.307 4.723-2.398 7.417Zm-.718 3h-.362l-.113.343-.99 3-.217.657h23.287l-.221-.66-1.005-3-.114-.34H8.04Zm-.299 3 .66-2h19.184l.67 2H7.741Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgServiceBellMedium;
