import type { SVGProps } from "react";
const SvgHandSafetyShieldSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12 1.988.109.024 4.5 1 .391.087V3.5c0 3.375-1.171 5.732-2.356 7.246a9.992 9.992 0 0 1-1.63 1.653 7.54 7.54 0 0 1-.705.5c-.02.01-.034.02-.045.026l-.014.008-.004.003h-.002s-.001.001-.244-.436l-.243.437-.002-.001-.005-.003-.013-.008-.046-.027a7.622 7.622 0 0 1-.706-.5 10 10 0 0 1-1.629-1.65C8.172 9.233 7 6.88 7 3.51v-.4l.39-.088 4.5-1.01.11-.024ZM12 12.5l.243.437-.243.135-.243-.135L12 12.5Zm0-.593c.104-.071.238-.168.392-.29.396-.316.93-.805 1.464-1.488 1.02-1.302 2.05-3.32 2.138-6.23l-3.993-.887-3.995.897c.087 2.904 1.119 4.92 2.138 6.221a8.999 8.999 0 0 0 1.464 1.487c.155.122.288.219.392.29ZM4.42 13.32l3.575 2.496c.039-.423.202-.846.502-1.18.35-.392.861-.636 1.493-.636h2.238v.004c1.61.073 3.117.965 4.278 2.075l.009.008 5.03 5.06.847.853h-8.857l-.147-.145-.863-.855H8.602l-.142-.125-5.924-5.214A1.502 1.502 0 0 1 4.42 13.32ZM12.095 15H9.99c-.358 0-.595.13-.748.302a1.07 1.07 0 0 0-.256.702c-.002.268.086.518.243.695.149.168.387.301.761.301h2v1h-2c-.622 0-1.128-.228-1.478-.603L3.834 14.13l-.015-.01-.014-.012a.502.502 0 1 0-.63.784l.008.006.008.007L8.979 20h3.957l.146.145.864.855h6.042l-4.178-4.203c-1.089-1.04-2.411-1.769-3.715-1.797Zm-.192-6.646 2.5-2.5-.707-.708-2.146 2.147-.897-.897-.707.708 1.25 1.25.354.353.353-.353Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandSafetyShieldSmall;
