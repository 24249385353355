import type { SVGProps } from "react";
const SvgMicrophoneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.25 8.249c0-2.197 1.801-4 4-4s4 1.803 4 4v12c0 2.199-1.801 4-4 4s-4-1.801-4-4v-12Zm4-5c-2.751 0-5 2.25-5 5v12c0 2.751 2.249 5 5 5s5-2.249 5-5v-12c0-2.75-2.249-5-5-5Zm-.5 24.988v3.512H13.5v1H21v-1h-3.25v-3.512c4.123-.204 7.5-2.925 7.5-7.238h-1c0 3.8-3.06 6.25-7 6.25-3.938 0-7-2.45-7-6.25h-1c0 4.313 3.378 7.034 7.5 7.238Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMicrophoneMedium;
