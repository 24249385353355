import type { SVGProps } from "react";
const SvgCarProfileLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.816 13.65A1.5 1.5 0 0 1 9.052 13H16v7H3.45l4.366-6.35ZM34.905 20H17v-7h7.686a.5.5 0 0 1 .286.09L34.905 20ZM3 21h33.456l4.827.804.753 1.881.002.004c.236.574.647 1.268 1.436 1.31H45V31h-6.027a4.5 4.5 0 0 0-8.945 0H14.973a4.5 4.5 0 0 0-8.946 0H3v-5h.5A1.5 1.5 0 0 0 5 24.5v-1A1.5 1.5 0 0 0 3.5 22H3v-1Zm42 3v-.306a1.5 1.5 0 0 0-1.253-1.48l-1.31-.218.526 1.315c.107.259.214.44.319.553.091.098.165.13.234.135H45ZM3 23v2h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H3Zm3.028 9H2V20.81c0-.303.092-.6.264-.85l4.728-6.876A2.5 2.5 0 0 1 9.052 12h15.634a1.5 1.5 0 0 1 .857.269l11.15 7.756 7.218 1.203A2.5 2.5 0 0 1 46 23.694V32h-7.028a4.5 4.5 0 0 1-8.945 0H14.973a4.5 4.5 0 0 1-8.944 0ZM7 31.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm2 0a1.5 1.5 0 1 1 3.002.001A1.5 1.5 0 0 1 9 31.499Zm1.5-2.5a2.5 2.5 0 1 0 .002 5 2.5 2.5 0 0 0-.002-5Zm24-1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM33 31.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 33 31.499Zm1.5-2.5a2.5 2.5 0 1 0 .002 5 2.5 2.5 0 0 0-.002-5ZM22 23h-3v-1h3v1Zm-5.994-1.001-.006 6L17 28l.006-6-1-.001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileLarge;
