import type { SVGProps } from "react";
const SvgSaAa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={31}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1 15 9.463 1h3.101l2.539 14h-2.8l-.48-3.21H5.882L4.102 15H1Zm9.563-11.513h-.08L7.122 9.584h4.32l-.88-6.097ZM15.44 15l8.463-14h3.1l2.54 14h-2.8l-.479-3.21h-5.942L18.542 15H15.44Zm9.563-11.513h-.08l-3.36 6.097h4.321l-.88-6.097Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaAa;
