import type { SVGProps } from "react";
const SvgIc30 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-30_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-30_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm20.637 3.696c.027-.949.261-1.745.704-2.389.718-1.033 1.796-1.55 3.236-1.55 1.107 0 2 .282 2.681.846.733.612 1.1 1.403 1.1 2.373 0 .58-.145 1.082-.435 1.503a2.1 2.1 0 0 1-1.163.847c.622.142 1.113.459 1.471.949.364.49.546 1.084.546 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.766 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.754-.744-1.131-1.748-1.131-3.014v-.15h2.223c0 .712.168 1.263.506 1.653.337.385.815.578 1.431.578.617 0 1.092-.182 1.424-.546a1.78 1.78 0 0 0 .46-1.226c0-.57-.196-1.02-.586-1.353-.322-.274-.818-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.673-.403-1.147-.403-.543 0-.963.169-1.258.506-.295.338-.453.83-.474 1.48h-2.192Zm12.989-3.939c.88 0 1.656.269 2.326.807.664.538 1.146 1.366 1.447 2.484.227.854.34 1.782.34 2.784 0 1.661-.295 3.027-.886 4.098-.348.622-.804 1.102-1.368 1.44a3.556 3.556 0 0 1-1.875.505c-.944 0-1.75-.284-2.42-.854-1.192-1.012-1.788-2.742-1.788-5.189 0-1.74.311-3.138.933-4.192a3.77 3.77 0 0 1 1.384-1.393 3.788 3.788 0 0 1 1.907-.49Zm-.087 1.954c-.76 0-1.276.438-1.55 1.313-.217.696-.325 1.672-.325 2.927 0 1.107.11 1.996.332 2.665.285.87.813 1.306 1.582 1.306.638 0 1.113-.338 1.424-1.013.311-.675.467-1.706.467-3.093 0-1.181-.11-2.112-.332-2.792-.285-.875-.818-1.313-1.598-1.313Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-30_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc30;
