import type { SVGProps } from "react";
const SvgFaceDisagreeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm-4.146 8.354L9 10.207l1.146 1.147.708-.708L9.707 9.5l1.147-1.146-.708-.708L9 8.793 7.854 7.646l-.708.708L8.293 9.5l-1.147 1.146.708.708Zm6 0L15 10.207l1.146 1.147.708-.708L15.707 9.5l1.147-1.146-.708-.708L15 8.793l-1.146-1.147-.708.708L14.293 9.5l-1.147 1.146.708.708ZM12.06 12c-2.279 0-4.174 1.357-5.01 3.303l.92.394A4.414 4.414 0 0 1 12.06 13a4.414 4.414 0 0 1 4.09 2.697l.92-.394C16.233 13.357 14.337 12 12.06 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceDisagreeSmall;
