import type { SVGProps } from "react";
const SvgSaS = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={13}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.202 3.831c-.82-.407-1.881-.698-2.9-.698-1.001 0-2.341.388-2.341 1.551 0 2.172 4.761 2.036 4.761 5.798C10.722 13.798 8.041 15 4.98 15c-1.34 0-2.72-.31-3.98-.756l.72-2.308c1.08.485 2.22.931 3.42.931 1.341 0 2.662-.659 2.662-2.113 0-2.443-4.762-2.056-4.762-5.662C3.04 2.24 5.56 1 8.241 1c1.221 0 2.481.155 3.621.601l-.66 2.23Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaS;
