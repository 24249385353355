import type { SVGProps } from "react";
const SvgWaitingRoomSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 7.5C13 5.544 14.545 4 16.5 4 18.456 4 20 5.544 20 7.5S18.456 11 16.5 11A3.472 3.472 0 0 1 13 7.5ZM16.5 3A4.472 4.472 0 0 0 12 7.5c0 2.508 1.993 4.5 4.5 4.5 2.508 0 4.5-1.992 4.5-4.5S19.008 3 16.5 3Zm.392 5.31 1.9-2.4-.783-.62-1.572 1.983-1.503-1.346-.667.745 1.9 1.7.395.354.33-.416ZM12 15.01h9V21h-9v-5.99Zm1 1V20h7v-3.99h-7Zm2.001-1h3v-1h-3v1ZM5.5 6a.5.5 0 1 0-.001 1 .5.5 0 0 0 0-1ZM4 6.5a1.5 1.5 0 1 1 2.999 0 1.5 1.5 0 0 1-3 0ZM3.5 9H3v6h1v6h3v-6h1V9H3.5Zm1 5H4v-4h3v4H6v6H5v-6h-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWaitingRoomSmall;
