import type { SVGProps } from "react";
const SvgNineSquaresSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 4h4v4H4V4Zm1 1v2h2V5H5Zm5-1h4v4h-4V4Zm1 1v2h2V5h-2Zm5.5-1H16v4h4V4h-3.5Zm.5 3V5h2v2h-2ZM4 10h4v4H4v-4Zm1 1v2h2v-2H5Zm5.5-1H10v4h4v-4h-3.5Zm.5 3v-2h2v2h-2Zm5-3h4v4h-4v-4Zm1 1v2h2v-2h-2ZM4.5 16H4v4h4v-4H4.5Zm.5 3v-2h2v2H5Zm5-3h4v4h-4v-4Zm1 1v2h2v-2h-2Zm5.5-1H16v4h4v-4h-3.5Zm.5 3v-2h2v2h-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgNineSquaresSmall;
