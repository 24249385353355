import type { SVGProps } from "react";
const SvgNewspaperLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.08 8.12h31v6h3v20.5a4.5 4.5 0 0 1-4.5 4.5h-25a4.5 4.5 0 0 1-4.5-4.5V8.12Zm31 7v20.5h-1V9.12h-29v25.5a3.5 3.5 0 0 0 3.5 3.5h25a3.5 3.5 0 0 0 3.5-3.5v-19.5h-2Zm-28 1h10v7h-10v-7Zm1 1v5h8v-5h-8Zm11.5 8h-.5v7h12v-7h-11.5Zm.5 6v-5h10v5h-10Zm11-10h-12v-1h12v1Zm-12-4h12v-1h-12v1Zm12-4h-24v-1h24v1Zm-24 13h10v-1h-10v1Zm10 4h-10v-1h10v1Zm-10 4v1h24v-1h-24Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgNewspaperLarge;
