import type { SVGProps } from "react";
const SvgCurriculumVitaeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 2h16v20H4V2Zm1 1v18h14V3H5Zm13 2h-5v1h5V5Zm0 2h-5v1h5V7Zm-5 2h5v1h-5V9Zm4 4H6v1h11v-1ZM6 15h11v1H6v-1Zm2.02 3.01h-2v1h2v-1Zm2 0h2v1h-2v-1Zm6 0h-2v1h2v-1ZM8.25 6c0-.642.417-1 .75-1s.75.358.75 1S9.333 7 9 7s-.75-.358-.75-1ZM9 4c-1.048 0-1.75.985-1.75 2 0 .486.161.966.444 1.333a4.38 4.38 0 0 0-1.693 3.693l.998-.052A3.38 3.38 0 0 1 8.567 7.94a1.588 1.588 0 0 0 .846.005A3.4 3.4 0 0 1 11 10.978l.999.044a4.4 4.4 0 0 0-1.704-3.677c.29-.369.454-.853.454-1.345 0-1.015-.702-2-1.75-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCurriculumVitaeSmall;
