import type { SVGProps } from "react";
const SvgTicketFvMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.75 3.25h20.5v29.5H7.75V3.25Zm1 1v27.5h18.5V4.25H8.75ZM24 23.75H12v-1h12v1Zm-12 3h12v-1H12v1Zm0-8.732h1.384v-3.269h3.268v-1.204h-3.268v-1.782h3.712V10.5H12v7.518Zm8.651-1.887 1.887-5.63 1.474-.001-2.69 7.518h-1.38l-.054-.15-2.63-7.368h1.515l1.878 5.63Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketFvMedium;
