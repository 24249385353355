import type { SVGProps } from "react";
const SvgLiftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m28.5 5.11.354.354 3.181 3.183-.707.707-2.828-2.83-2.828 2.83-.707-.707 3.181-3.183.354-.354Zm-24.875.015h18.25v25.75H3.625V5.125Zm1 1v23.75h16.25V6.125H4.625Zm21.047 20.523 2.828 2.828 2.828-2.828.707.707-3.181 3.181-.354.354-.353-.354-3.182-3.181.707-.707ZM12.75 8.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-1 4h-.5v8.5h1.5v6h4v-6h1.5v-8.5h-6.5Zm.5 7.5v-6.5h5v6.5h-1.5v6h-2v-6h-1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLiftMedium;
