import type { SVGProps } from "react";
const SvgExclamationPointMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.357 14.388V8.154h-2.686v6.234l.669 9.046h1.321l.696-9.046Zm.14 14.112h-2.993v-2.866h2.993V28.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgExclamationPointMedium;
