import type { SVGProps } from "react";
const SvgMeetingPointSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.293 8 2.896 4.604l.708-.707L7 7.293V4h1v5H3V8h3.293Zm5.207 2A2.478 2.478 0 0 0 9 12.5c0 1.398 1.102 2.5 2.5 2.5s2.5-1.102 2.5-2.5-1.102-2.5-2.5-2.5ZM8 12.5C8 10.55 9.55 9 11.5 9s3.5 1.55 3.5 3.5-1.55 3.5-3.5 3.5S8 14.45 8 12.5Zm7-4V4h1v3.293l3.396-3.396.708.707L16.707 8H20v1h-5v-.5Zm0 7.5h5v1h-3.293l3.397 3.396-.707.707L16 17.707V21h-1v-5ZM3 16h5v5H7v-3.293l-3.396 3.396-.708-.707L6.293 17H3v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMeetingPointSmall;
