import type { SVGProps } from "react";
const SvgEarthMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#earth-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M15.696 4.442c.75-.126 1.519-.192 2.304-.192 2.479 0 4.804.656 6.812 1.803l-1.205.788c-.4.26-.923.769-1.196 1.145l-.84 1.155-.007.01a.24.24 0 0 1-.204.099h-3.81c-.314 0-.646.119-.887.38l-2.69 2.69-.03.024-.03.022-.003.002-.01.006-2.85 1.605c-.984.555-.785 1.977.265 2.294l.004.002 2.61.765.01.002.01.003c.15.037.358.072.59.018l5.629-1.135a.7.7 0 0 1 .159 0l.584.142.01.003c.17.037.344.249.344.442v3.435c0 .351.149.67.371.893l.008.007 6.59 6.334a13.801 13.801 0 0 1-3.922 3.035l-.522-3.783-.001-.01-.002-.01a1.206 1.206 0 0 0-.358-.695l-2.81-2.81-1.125-1.125a1.248 1.248 0 0 0-1.448-.229l-.001.001-1.425.72-.004.002c-.145.075-.351-.03-.351-.23v-1.035c0-.697-.554-1.25-1.25-1.25H7.2c-.454 0-.874.25-1.096.645l-.004.007-1.108 2.051A13.732 13.732 0 0 1 4.25 18c0-4.658 2.316-8.774 5.859-11.261l1.659 1.022a.5.5 0 0 0 .262.074h2.4a.5.5 0 0 0 .484-.375l.782-3.018Zm13.18 21.972A13.69 13.69 0 0 0 31.75 18c0-4.72-2.38-8.886-6.004-11.362l-1.593 1.04a4.48 4.48 0 0 0-.934.896l-.837 1.15c-.23.33-.609.526-1.022.526h-3.81a.205.205 0 0 0-.153.06l-.01.009-.009.01-2.714 2.714-.014.014-.015.012-.325-.38.325.38v.001l-.002.002-.005.004-.017.014a1.272 1.272 0 0 1-.228.16l-2.842 1.6a.253.253 0 0 0 .062.466l2.597.76c.08.02.105.015.109.014l.01-.003.012-.003 5.655-1.14.014-.002.014-.002a1.75 1.75 0 0 1 .463-.002l.029.004.027.007.611.149c.638.145 1.121.771 1.121 1.417v3.435c0 .067.03.136.076.184l6.535 6.28Zm-6.075.17.564 4.08A13.706 13.706 0 0 1 18 31.75c-5.568 0-10.364-3.31-12.526-8.07l1.503-2.783a.263.263 0 0 1 .223-.132h7.815c.144 0 .25.106.25.25v1.035c0 .909.963 1.553 1.807 1.12l1.422-.718a.248.248 0 0 1 .293.041l1.125 1.125 2.82 2.82.011.012.013.01c0 .002.007.007.016.026.01.02.02.052.03.099Zm-8.198-21.91c-1.267.322-2.463.82-3.562 1.465l1.13.697h1.872l.56-2.162ZM18 3.25C9.854 3.25 3.25 9.854 3.25 18S9.854 32.75 18 32.75 32.75 26.146 32.75 18 26.146 3.25 18 3.25Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="earth-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEarthMedium;
