import type { SVGProps } from "react";
const SvgSwitzerlandRouteMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.649 8.217-.4-.353-.326.421-1.417 1.829-5.292 1.442-.934-.505L12 10.9l-.256.19-3.66 2.72-.761.565.897.309.939.322-3.848 4.267-.08.088-.03.115-1.62 5.931-.227.828.832-.212 3.21-.816.067-.017.06-.035 1.093-.636 3.084 2.952.169.161.232-.026 4.736-.53.185-.022.126-.136 2.314-2.505 2.872 3.972.496.686.361-.766 2.184-4.632 5.095 1.331.707.185-.084-.726-.282-2.433 1.739.044.538.014-.026-.538-.237-4.903-.011-.232-.185-.142-2.014-1.538-.47-.36-.276.524-.462.876-1.776-.536.83-2.476.152-.452-.445-.173-5.492-2.132-2.027-1.79Zm-1.455 2.642 1.193-1.54 1.694 1.496.067.059.083.032 5.13 1.992-.842 2.511-.164.489.494.149 2.663.804.395.119.192-.364.378-.718 1.36 1.039.2 4.134-1.776-.045-.576-.015.066.572.264 2.279-4.789-1.251-.401-.105-.177.376-2 4.24-2.733-3.78-.358-.495-.415.448-2.602 2.817-4.318.484-3.186-3.05-.272-.26-.326.19-1.357.79-2.31.587 1.362-4.989 4.275-4.74.507-.562-.716-.246-.757-.26 2.643-1.963.83.448.176.095.193-.052 5.646-1.54.161-.043.103-.132Zm-5.379 9.766c.232 0 .451.053.647.146l5.504-5.504a1.5 1.5 0 1 1 .712.702l-5.51 5.51a1.5 1.5 0 1 1-1.354-.854Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSwitzerlandRouteMedium;
