import type { SVGProps } from "react";
const SvgHandLocomotiveProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 3H2V2h14v1Zm3.108 2.285c-.419-.664-.784-1.074-1.418-1.225-.28-.067-.59-.078-.926-.077-.147.001-.308.004-.481.007l-.089.002A45.07 45.07 0 0 1 15.5 4H13v6h-1V4H2v1h9v7H6v1h6v-1h1v1h6c1.315 0 2.294-.422 2.737-1.255.436-.818.236-1.82-.31-2.72l-.002-.003-2.315-3.734-.002-.003ZM15.501 5c.265 0 .501-.004.711-.008l.09-.002c.174-.003.327-.006.465-.007.33 0 .536.013.69.05.237.056.42.174.804.784l2.313 3.73c.453.747.48 1.352.28 1.729-.193.363-.713.724-1.854.724h-5V5h1.5ZM15 6h2.23l.14.268 1.572 3 .383.732H15V6Zm1 1v2h1.673l-1.048-2H16ZM4.424 13.319l3.582 2.503a2.03 2.03 0 0 1 .494-1.185c.35-.394.863-.637 1.5-.637h2.125c1.643.056 3.2.937 4.391 2.074l5.328 5.064.907.862h-9.208l-.146-.145-.862-.855H8.611l-.141-.125-5.919-5.213a1.5 1.5 0 0 1 1.874-2.343Zm4.138 4.11L3.84 14.13l-.014-.01-.013-.01a.5.5 0 1 0-.626.78l.009.007.009.008L8.989 20h3.959l.146.145.862.855h6.292l-4.42-4.201-.002-.001c-1.08-1.033-2.413-1.75-3.725-1.798H10c-.364 0-.6.132-.751.301A1.05 1.05 0 0 0 9 16c0 .268.09.52.249.7.15.168.387.3.751.3h2v1h-2c-.6 0-1.09-.216-1.437-.57Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandLocomotiveProfileSmall;
