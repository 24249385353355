import type { SVGProps } from "react";
const SvgPaperClipLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M31 4v33.707l-.146.147-7 7-.354.353-.354-.353-7-7-.146-.147V5h11v24.768l-.223.148-3 2-.277.185-.277-.185-3-2-.223-.148V16h1v13.232l2.5 1.667 2.5-1.667V6h-9v31.293l6.5 6.5 6.5-6.5V4h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPaperClipLarge;
