import type { SVGProps } from "react";
const SvgSignExclamationPointMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.25 3.376.447.901 13.05 26.25.36.723H3.392l.359-.723 13.05-26.25.448-.9ZM29.492 30.25 17.25 5.623 5.006 30.25h24.487ZM16.75 27v-3h1v3h-1Zm1-6v-9h-1v9h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSignExclamationPointMedium;
