import type { SVGProps } from "react";
const SvgCloudIceMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.04 16.065a2.841 2.841 0 0 0-1.86-1.08c.246-1.254.065-2.374-.416-3.294a4.664 4.664 0 0 0-2.556-2.204c-1.997-.725-4.471-.138-5.8 2.23-2.192-1.226-5.127.33-5.134 3.133-.744.144-1.342.586-1.758 1.167-.504.705-.753 1.624-.713 2.53.04.91.371 1.841 1.073 2.548.708.713 1.75 1.156 3.124 1.156h10.5c1.32 0 2.35-.447 3.068-1.148a3.971 3.971 0 0 0 1.164-2.518c.073-.9-.152-1.822-.693-2.52Zm-9.982-3.413c1.01-2.29 3.124-2.837 4.81-2.225.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.199.67.698-.028c.778-.03 1.326.273 1.679.728.364.47.543 1.133.486 1.825a2.971 2.971 0 0 1-.866 1.884c-.515.504-1.287.864-2.369.864H12c-1.15 0-1.922-.364-2.415-.86-.498-.503-.753-1.184-.783-1.887-.031-.707.167-1.4.527-1.905.355-.495.856-.798 1.471-.798h.564l-.067-.56c-.296-2.473 2.359-3.737 3.987-2.403l.508.416.266-.601ZM10.5 25.5H9V24h1.5v1.5Zm1.5 0h-1.5V27H9v1.5h1.5V27H12v1.5h1.5V27H12v-1.5Zm0 0V24h1.5v1.5H12Zm3 6h1.5V33H15v1.5h1.5V33H18v1.5h1.5V33H18v-1.5h1.5V30H18v1.5h-1.5V30H15v1.5Zm7.5-6H21V24h1.5v1.5Zm1.5 0h-1.5V27H21v1.5h1.5V27H24v1.5h1.5V27H24v-1.5Zm0 0V24h1.5v1.5H24Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudIceMedium;
