import type { SVGProps } from "react";
const SvgLeafLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m23.781 8.084-.277.416-.277-.416.277-.185.277.185Zm12.887 17.952a19.667 19.667 0 0 1-1.98 2.204 12.903 12.903 0 0 1 4.181 2.914l.305.32-.278.341c-1.875 2.308-7.29 5.05-14.873 1.497l.012 6.687-1 .002-.012-6.706c-7.603 3.579-13.032.831-14.91-1.48l-.28-.342.306-.319a12.904 12.904 0 0 1 4.181-2.914 19.667 19.667 0 0 1-1.98-2.204c-1.19-1.562-2.145-3.411-2.334-5.49l-.032-.352.32-.148c1.946-.901 4.321-.905 6.157-.707a21.192 21.192 0 0 1 2.662.464c.376-3.732 1.673-6.496 2.979-8.4a15.016 15.016 0 0 1 2.134-2.496 10.832 10.832 0 0 1 .919-.766l.057-.04.017-.012.005-.003.002-.001.278.415.278-.416.002.002.005.003.016.012.058.04a10.832 10.832 0 0 1 .919.765c.585.545 1.36 1.369 2.134 2.497 1.306 1.904 2.603 4.668 2.98 8.4a21.194 21.194 0 0 1 2.662-.464c1.835-.198 4.21-.194 6.156.707l.32.148-.032.351c-.19 2.08-1.145 3.93-2.334 5.49Zm-3.848 3.852c.339-.276.695-.576 1.06-.9a11.889 11.889 0 0 1 3.941 2.53c-1.867 1.913-6.783 4.162-13.8.683l-.003-1.443 5.763-3.842-.554-.832-5.211 3.474-.012-6.559-1 .002.012 6.573-5.235-3.49-.554.832 5.791 3.861.003 1.407c-7.035 3.503-11.963 1.25-13.834-.665a11.898 11.898 0 0 1 3.941-2.53c.365.323.721.623 1.06.9l.632-.776c-1.186-.967-2.563-2.211-3.684-3.683-1.048-1.376-1.856-2.928-2.093-4.618 1.64-.647 3.633-.659 5.3-.479a20.226 20.226 0 0 1 2.692.48c-.02.387-.031.782-.031 1.187h1c0-4.64 1.462-7.918 2.912-10.031a14.013 14.013 0 0 1 1.99-2.33c.243-.225.448-.396.598-.515.15.119.355.29.597.515.54.502 1.264 1.27 1.99 2.33 1.45 2.113 2.913 5.391 2.913 10.031h1c0-.405-.01-.8-.031-1.186a20.233 20.233 0 0 1 2.691-.48c1.668-.181 3.662-.169 5.3.478-.236 1.69-1.044 3.242-2.092 4.618-1.121 1.472-2.498 2.716-3.684 3.683l.632.775Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLeafLarge;
