import type { SVGProps } from "react";
const SvgSaHl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.722 1H3.921L1 15h2.801l1.28-6.137h5.641L9.442 15h2.8l2.921-14h-2.801l-1.179 5.656H5.541zm14.693.023h-2.898l-3.063 14h8.547l.497-2.206h-5.67z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaHl;
