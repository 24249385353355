import type { SVGProps } from "react";
const SvgArrowStartLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m10.146 8.146-3.99 3.992-.352.353.352.354 3.99 4.007.708-.705-3.141-3.156H17v-1H7.717l3.137-3.138zM20 9v7h-1V9z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowStartLeftSmall;
