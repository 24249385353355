import type { SVGProps } from "react";
const SvgCarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.519 11.019c-.486.486-.769 1.27-.769 2.481v3.25h9.535a3.501 3.501 0 0 1 6.93 0h2.035V13.5c0-1.211-.282-1.995-.769-2.481-.486-.486-1.27-.769-2.481-.769H12c-1.211 0-1.995.283-2.481.769ZM24.2 16.75a2.501 2.501 0 0 0-4.9 0h4.9Zm-15.45 7.5v-6.5h18.5v6.5H8.75Zm.062-13.938C9.56 9.563 10.652 9.25 12 9.25h12c1.348 0 2.44.313 3.188 1.062.749.749 1.062 1.84 1.062 3.188v1.75H30v1h-1.75v12h-5.498v-3H13.25v3h-5.5v-12H6v-1h1.75V13.5c0-1.348.313-2.44 1.062-3.188Zm14.94 14.938h3.498v2h-3.498v-2Zm-15.002 0h3.5v2h-3.5v-2Zm2-4.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm10.75.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarMedium;
