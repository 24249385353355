import type { SVGProps } from "react";
const SvgTrainTracksPenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 22V2m7 10V2M2 7.5h12m-12-3h12m-12 6h12m-12 3h8m-8 3h6m-6 3h4m1.5-.29v2.29h2.29l7.5-7.501L15 11.71l-7.5 7.5ZM16.71 10 19 12.29l2.29-2.3L19 7.71 16.71 10Z"
      stroke="currentColor"
    />
  </svg>
);
export default SvgTrainTracksPenSmall;
