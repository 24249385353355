import type { SVGProps } from "react";
const SvgRailwaySwitchSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 7v2H4v1h1v2H4v1h1v2H4v1h1v2H4v1h1v2h1v-1.891l9.418-14.334-.836-.55L13.416 6H13v.634L11.445 9H11V7h1V6h-1V4h-1v2H7v1h3v2H7v1h3v1.2l-.522.795H7v1h1.82l-1.314 2H7v.77l-1 1.523V4H5v2H4v1h1Zm6 12.602V21h-1v-2H8v-1h2.904l1.405-2H10v-1h3.012l1.405-2H12v-1h3.12l1.405-2H14V9h3.227l1.405-2H16V6h3.335l1.256-1.787.818.574L20.557 6H21v1h-1.145l-1.406 2H19v1h-1.253l-1.406 2H17v1h-1.361l-1.405 2H15v1h-1.469l-1.405 2H13v1h-1.577l-.423.602Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRailwaySwitchSmall;
