import type { SVGProps } from "react";
const SvgEv34 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-34_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.674c.026-.953.262-1.751.706-2.397.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.145 1.084-.436 1.507-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.548 1.087.548 1.786 0 1.147-.424 2.086-1.27 2.816-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.57-.195-1.023-.587-1.356-.322-.275-.82-.413-1.491-.413-.191 0-.379.01-.564.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.047-.291-.27-.674-.405-1.15-.405-.545 0-.966.17-1.262.508-.296.338-.455.833-.476 1.484h-2.198Zm17.014 3.316v1.96h-1.46v2.65h-2.261v-2.65h-5v-2.174l5.119-6.776h2.142v6.99h1.46Zm-3.674.024V6.868l-3.142 4.316h3.142Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv34;
