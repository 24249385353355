import type { SVGProps } from "react";
const SvgEv30Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-30-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.673c.026-.952.262-1.75.706-2.396.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.849.735.614 1.103 1.407 1.103 2.38 0 .582-.145 1.085-.436 1.508-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.785 0 1.148-.423 2.087-1.269 2.817-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.571-.195-1.023-.587-1.357-.323-.274-.82-.412-1.492-.412-.19 0-.378.01-.563.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.048-.291-.27-.674-.404-1.15-.404-.546 0-.966.17-1.262.508-.296.338-.455.833-.476 1.483h-2.198Zm12.785-3.951c.883 0 1.66.27 2.332.81.667.539 1.15 1.37 1.452 2.49.228.858.342 1.789.342 2.794 0 1.666-.297 3.036-.889 4.11-.35.624-.807 1.105-1.373 1.444a3.567 3.567 0 0 1-1.88.508c-.947 0-1.756-.286-2.428-.857-1.196-1.016-1.793-2.75-1.793-5.205 0-1.746.312-3.148.936-4.206a3.782 3.782 0 0 1 1.388-1.396 3.8 3.8 0 0 1 1.913-.492Zm-.088 1.96c-.761 0-1.28.439-1.555 1.317-.217.698-.325 1.677-.325 2.936 0 1.11.111 2.002.333 2.674.286.873.815 1.309 1.587 1.309.64 0 1.116-.339 1.428-1.016.312-.677.468-1.711.468-3.102 0-1.185-.11-2.119-.333-2.801-.285-.878-.82-1.317-1.603-1.317Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv30Negative;
