import type { SVGProps } from "react";
const SvgIr46Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-46-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M44.768 13.158v-1.954h-1.45V4.235h-2.13l-4.927 6.756v2.167h4.81V15.8h2.246v-2.642h1.45Zm-3.65-6.233v4.303h-2.965l2.965-4.303Zm10.138.198h2.191a3.888 3.888 0 0 0-.749-1.875c-.678-.86-1.655-1.29-2.932-1.29a3.752 3.752 0 0 0-3.209 1.622c-.81 1.118-1.214 2.663-1.214 4.635 0 1.846.379 3.284 1.135 4.315.757 1.031 1.816 1.547 3.177 1.547a3.84 3.84 0 0 0 2.728-1.068c.841-.791 1.261-1.838 1.261-3.14a4.05 4.05 0 0 0-.938-2.737 3.26 3.26 0 0 0-2.61-1.155 3.008 3.008 0 0 0-1.414.308c-.44.25-.82.596-1.108 1.013a9.32 9.32 0 0 1 .355-1.938c.357-1.05.962-1.574 1.813-1.574.804 0 1.309.445 1.514 1.337Zm-3.028 3.345a1.53 1.53 0 0 1 1.317-.696 1.64 1.64 0 0 1 1.411.736c.291.425.44.932.426 1.448a2.428 2.428 0 0 1-.473 1.495 1.586 1.586 0 0 1-1.332.672 1.546 1.546 0 0 1-1.34-.704 2.49 2.49 0 0 1-.45-1.48 2.46 2.46 0 0 1 .441-1.47Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr46Negative;
