import type { SVGProps } from "react";
const SvgBatteryLevelEmptyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.25 6.25h5.5v1.5h3v22h-11.5v-22h3v-1.5Zm1 1v1.5h-3v20h9.5v-20h-3v-1.5h-3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBatteryLevelEmptyMedium;
