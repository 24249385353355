import type { SVGProps } from "react";
const SvgCarProfileSignParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.75 5.25a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10.5a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V5.25Zm1-2c-1.106 0-2 .894-2 2v10.5a2 2 0 0 0 2 2h4V33h1V17.75h4a2 2 0 0 0 2-2V5.25c0-1.106-.894-2-2-2h-9Zm2.5 3H27c.817 0 1.527.207 2.031.706.505.5.719 1.211.719 2.044 0 .828-.208 1.539-.71 2.04-.502.501-1.214.708-2.039.71H25.25V15h-1V6.25Zm1 4.5H27c.671-.001 1.084-.17 1.333-.418.249-.248.417-.66.417-1.332s-.172-1.085-.423-1.334c-.252-.25-.667-.416-1.327-.416h-1.75v3.5Zm-12.5 16a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Zm-3.465 2a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.465 3h3.535v-6a.5.5 0 0 0-.5-.5H3v-1h10.792L8.94 16.395a.5.5 0 0 0-.353-.146H3v-1h5.586a1.5 1.5 0 0 1 1.06.44l5.561 5.56h4.043a1.5 1.5 0 0 1 1.5 1.5v7h-4.536a3.502 3.502 0 0 1-3.464 3 3.502 3.502 0 0 1-3.465-3H3v-1h6.285Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileSignParkingMedium;
