import type { SVGProps } from "react";
const SvgArrowCircleTimeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 9H5.255C6.413 6.677 9.093 5 12 5c4.074 0 7 2.926 7 7s-2.926 7-7 7c-3.428 0-6.022-2.071-6.775-5.12l-.97.24C5.122 17.63 8.128 20 12 20c4.626 0 8-3.374 8-8 0-4.626-3.374-8-8-8-2.81 0-5.466 1.392-7 3.501V5.01H4V10h5V9Zm2-1v5h5v-1h-4V8h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTimeSmall;
