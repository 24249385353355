import type { SVGProps } from "react";
const SvgRackRailawayProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.125 2.004.233.089 4.18 1.59.971.369-.895.528-1.854 1.096 4.728-.494.094.996-6.89.719-.088.052-.095-.033-9.407.98-.104-.994 7.307-.762-2.071-.728-.976-.344.876-.551 3.78-2.38.21-.133Zm7.457 4.174.946 9.993a.42.42 0 0 1-.01.001h-.002l-17.52 1.89.108.995 3.047-.329c.093.355.265.686.502.97L2 20.061l.1.995 5.035-.503a2.495 2.495 0 0 0 1.349-.135l4.768-.477a2.5 2.5 0 0 0 1.101-.11l7.747-.775-.1-.995-6.261.627c.195-.347.306-.737.32-1.138l3.564-.384-.095-.996h.001l.095.996a1.5 1.5 0 0 0 1.334-1.634v-.001l-.82-8.998v-.002a1.502 1.502 0 0 0-1.65-1.35l.095.996Zm0 0 .01-.001a.5.5 0 0 1 .55.449l.82 8.999v.002a.5.5 0 0 1-.433.544l-.947-9.993ZM15.049 17.66l-2.899.313a1.502 1.502 0 0 0 1.13.962l.849-.085a1.5 1.5 0 0 0 .92-1.19Zm-3.91.422-1.068.115c-.017.383-.12.755-.302 1.089l1.9-.19a2.5 2.5 0 0 1-.53-1.014Zm-2.082.225-2.892.312a1.502 1.502 0 0 0 1.027.924l1.038-.104a1.501 1.501 0 0 0 .827-1.132Zm2.18-15.191L8.541 4.812l2.954 1.039 2.715-1.603-2.976-1.132Zm4.472 4.518-.498.05.05.497.492 4.976.05.497.497-.049 1.99-.197.497-.05-.049-.497-.492-4.975-.05-.498-.497.05-1.99.196Zm.99 4.927-.394-3.981.995-.098.394 3.98-.995.099ZM7.74 8.426l-.498.049.05.497.492 4.976.049.498.497-.05 4.976-.492.498-.049-.05-.498-.492-4.975-.049-.498-.498.05-4.975.492Zm.99 4.926-.394-3.98 3.98-.394.394 3.98-3.98.394ZM6.249 8.576l.049.495.49 4.97.049.5-.5.047-4.24.4-.094-.996 3.74-.353-.392-3.975-3.255.304-.092-.996 3.75-.35.495-.046Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRackRailawayProfileSmall;
