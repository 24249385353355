import type { SVGProps } from "react";
const SvgSaWb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.8 1H1l.88 14h3.461l4.921-11.192h.04L10.442 15h3.501l6.661-14h-2.9l-4.942 10.731h-.04L12.662 1H8.901l-4.64 10.731h-.04L3.8 1Zm21.001 5.656h-1.1l.74-3.45h.98c1.06 0 2.341.06 2.341 1.404 0 1.645-1.54 2.046-2.96 2.046Zm-1.42 6.138h-.96l.84-4.05h.9c1.5 0 2.86.219 2.86 1.684 0 2.165-1.92 2.366-3.64 2.366ZM26.34 1H22.2l-2.922 14h3.982c2.08 0 6.56-.32 6.56-4.332 0-1.505-.68-2.708-2.24-2.949v-.04c1.62-.26 2.98-1.624 2.98-3.329 0-2.548-2.04-3.35-4.22-3.35Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaWb;
