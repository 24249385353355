import type { SVGProps } from "react";
const SvgCircleExclamationPointSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm9-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm.5 4v7h-1V6h1Zm-1 11v-2h1v2h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleExclamationPointSmall;
