import type { SVGProps } from "react";
const SvgBucketFoamBroomSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 12.035a3.5 3.5 0 0 1 3 3.465V20h-7v-4.5a3.5 3.5 0 0 1 3-3.465V3h1v9.035ZM2.86 11h1.19a2.502 2.502 0 0 1 4.9 0h5.19l-.155.621-2 8-.095.379H5.11l-.095-.379-2-8L2.86 11Zm3.64-1c.653 0 1.208.418 1.415 1h-2.83c.207-.582.762-1 1.415-1Zm-2.36 2 1.75 7h5.22l1-4H9v-1h3.36l.5-2H4.14ZM16 19v-2h1v2h1v-3h1v3h1v-3.5a2.5 2.5 0 1 0-5 0V19h1ZM10 8.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBucketFoamBroomSmall;
