import type { SVGProps } from "react";
const SvgHammerWrenchMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.692 5.8c-.515.103-1.091.381-1.692.982l-4.42 4.42.708.707.572.572-.768.255-2.414.805 3.098 3.099.805-2.415.256-.767.572.572.707.707 3.536-3.536-1.238-1.237-.354-.353.354-.354 2.792-2.792-.003-.002a4.109 4.109 0 0 0-1.069-.554 2.792 2.792 0 0 0-1.442-.109Zm3.258.629.353-.354.354.354-.354.353-2.828 2.829 1.237 1.237.354.353-.354.354-.707.707 4.202 4.202 3.601-3.601c-.958-2.137-.487-4.895 1.15-6.453 1.698-1.616 4.58-2.148 6.774-.966l.591.318-.475.475-3.716 3.717 1.414 1.414 3.717-3.716.475-.476.319.592c1.181 2.193.648 5.076-.967 6.773-1.558 1.638-4.316 2.11-6.453 1.15l-3.601 3.602 7.722 7.722.354.354-.354.353-2.121 2.121-.354.354-.353-.354-7.723-7.722-7.713 7.714-.354.353-.353-.353-2.122-2.122-.353-.353.353-.354 7.714-7.713-4.202-4.202-.707.707-.354.353-.354-.353-.488-.489-.805 2.415-.256.767-.572-.572-4.243-4.243-.572-.572.768-.255 2.414-.805-.488-.489-.354-.354.354-.353 4.773-4.773c.725-.725 1.474-1.11 2.203-1.255a3.79 3.79 0 0 1 1.954.14 5.107 5.107 0 0 1 1.335.689 5.092 5.092 0 0 1 .505.413l.009.008.002.003.001.001c.001 0 .001.001-.352.355Zm.136 12.157 1.414-1.414-4.202-4.203-1.414 1.414 4.202 4.203Zm2.828 2.828 7.37 7.369 1.413-1.414-7.368-7.37-1.415 1.415Zm4.734-14.28c1.248-1.187 3.287-1.66 4.975-1.086L25.071 9.6l-.354.354.354.354 2.121 2.12.354.354.354-.353 3.552-3.552c.574 1.688.1 3.727-1.086 4.975-1.315 1.382-3.792 1.762-5.592.793l-.328-.177-.263.263L10.14 28.774 8.726 27.36l14.043-14.043.263-.263-.177-.328c-.97-1.8-.589-4.277.793-5.592Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHammerWrenchMedium;
