import type { SVGProps } from "react";
const SvgSaX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={18}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m7.421 1 2.021 4.814h.04L13.322 1h3.301l-5.781 6.679L14.082 15h-3.06l-2.14-5.617h-.04L4.481 15H1l6.461-7.602L4.4 1h3.021Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaX;
