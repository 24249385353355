import type { SVGProps } from "react";
const SvgWheelchairPartiallySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.9 12c0-3.412 1.037-5.913 2.395-7.793l.81.586C3.863 6.513 2.9 8.81 2.9 12c0 3.182.96 5.387 2.213 7.217l-.825.565C2.94 17.815 1.9 15.417 1.9 12Zm4.102 2.497a4.51 4.51 0 0 1 2.471-4.012l.454.891a3.512 3.512 0 0 0-1.925 3.121c0 1.924 1.578 3.5 3.5 3.5a3.515 3.515 0 0 0 3.168-2.016l.904.427a4.515 4.515 0 0 1-4.072 2.59 4.513 4.513 0 0 1-4.5-4.5ZM21.901 12c0-3.418-1.041-5.814-2.389-7.783l-.825.565c1.254 1.832 2.214 4.035 2.214 7.218 0 3.188-.963 5.487-2.207 7.207l.81.586c1.36-1.88 2.397-4.382 2.397-7.793ZM10 13.5V8h1v5h5v6h-1v-5h-5v-.5Zm.5-8.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairPartiallySmall;
