import type { SVGProps } from "react";
const SvgCircleTriangleSquareSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 3a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM13 5h9v9h-9V5Zm1 1v7h7V6h-7Zm-4.071 7.743-.429-.715-.429.715-4.5 7.5-.454.757h10.766l-.454-.757-4.5-7.5ZM9.5 14.972 13.117 21H5.883L9.5 14.972Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleTriangleSquareSmall;
