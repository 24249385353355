import type { SVGProps } from "react";
const SvgChartLineLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 5v41h44v-1H3.707l8.566-8.566 8.393-2.962.209-.074.086-.204L24.832 24H30.9l.088-.39L33.399 13h5.325l.15-.168 4-4.5-.748-.664L38.276 12H32.6l-.088.39L30.101 23h-5.933l-.129.306-3.914 9.296-8.291 2.927-.107.037-.08.08L3 44.293V5H2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartLineLarge;
