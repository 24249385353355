import type { SVGProps } from "react";
const SvgLocationPinMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.977 4.771a8.75 8.75 0 0 0-8.75 8.75v.02c.06 1.51.508 2.781 1.22 3.967l.006.01 7.125 11.247.423.667.422-.667 7.125-11.247.006-.01.006-.01c.694-1.216 1.154-2.512 1.167-3.972v-.005a8.75 8.75 0 0 0-8.75-8.75ZM11.3 16.988c-.635-1.06-1.021-2.165-1.074-3.476a7.75 7.75 0 0 1 15.5.008c-.012 1.238-.399 2.362-1.03 3.473L18 27.563l-6.7-10.575Zm1.937-3.478a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Zm4.75-5.75a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinMedium;
