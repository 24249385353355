import type { SVGProps } from "react";
const SvgDoubleChevronSmallRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18.764 17.645-5.922-5.856-.704.71 5.563 5.5-5.565 5.501.703.712 5.925-5.856.36-.356-.36-.355Zm6 0-5.922-5.856-.703.71 5.563 5.5-5.566 5.501.703.712 5.925-5.856.36-.356-.36-.355Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDoubleChevronSmallRightMedium;
