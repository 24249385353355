import type { SVGProps } from "react";
const SvgArrowsLeftRightDottedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arrows-left-right-dotted-medium_svg__a)">
      <path
        d="M22.5 11.25h6.75m0 0-4.5 4.5m4.5-4.5-4.5-4.5m-11.25 18H6.75m0 0 4.5-4.5m-4.5 4.5 4.5 4.5m5.268-18h-3.036m7.536 0h-3.036m-5.964 0H8.982m-1.464 0H5.982m12.036 13.5h-3.036m7.536 0h-3.036m7.536 0h-3.036m6.036 0h-1.536"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="arrows-left-right-dotted-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowsLeftRightDottedMedium;
