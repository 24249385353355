import type { SVGProps } from "react";
const SvgAirplaneTrainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#airplane-train-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M12.934 3.25h4.698l.1.368 2.25 8.25-.964.264-2.15-7.882h-2.802l1.43 11.438.07.562H8.691l-.138-.276L7.19 13.25H5.258l1.952 4.553.084.197-.084.197-1.952 4.553h1.933l1.362-2.724.138-.276H12v1H9.309l-1.362 2.724-.138.276H3.742l.298-.697L6.206 18 4.04 12.947l-.298-.697h4.067l.138.276L9.31 15.25h5.125l-1.43-11.438-.07-.562ZM5.646 29.646l22.5-22.5.707.708-22.5 22.5-.707-.708ZM24.75 18.25h-.5v7h6.881l.438 1.228.01.028.014.027c.1.202.157.425.157.677 0 .856-.683 1.54-1.51 1.54H15v1h4.786a3.499 3.499 0 0 0 1.02 2H15v1h18v-1h-7.306a3.498 3.498 0 0 0 1.02-2h3.526c1.393 0 2.51-1.146 2.51-2.54a2.48 2.48 0 0 0-.249-1.095l-2.809-7.875a6.637 6.637 0 0 0-3.697-3.97l-.39.92a5.641 5.641 0 0 1 3.024 3.06H24.75Zm-1.5 13.5a2.512 2.512 0 0 0 2.45-2h-4.9a2.512 2.512 0 0 0 2.45 2Zm5.74-12.5 1.784 5H25.25v-5h3.74Zm-6.74 5.5V18h-1v6.25H15v1h7.25v-.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="airplane-train-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAirplaneTrainMedium;
