import type { SVGProps } from "react";
const SvgSn41 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="sn-41_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.792 0h55.416C58.197 0 59 .806 59 1.8v16.4c0 .994-.801 1.8-1.792 1.8H1.792A1.797 1.797 0 0 1 0 18.2V1.8C0 .806.803 0 1.792 0Z"
      fill="currentColor"
    />
    <path
      d="M12.794 7.702h-2.405c-.047-.564-.258-.994-.632-1.29-.37-.3-.89-.45-1.559-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.672.625-1.163 0-.49-.293-.849-.878-1.076-.322-.126-1.081-.356-2.278-.688-.75-.206-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642C6.057 4.277 7.078 4 8.333 4c1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.878h-2.428V4.277h2.57l4.81 7.863-.031-7.863h2.428Zm9.971 6.943v1.945h-1.473V15.8h-2.282v-2.635H25.84v-2.162l5.005-6.726h2.162v6.942h1.474Zm-3.707.023V6.951l-3.01 4.292h3.01Zm10.322-6.966V15.8h-2.338V8.257h-2.755V6.59c.577-.021 1.044-.08 1.401-.176.358-.102.687-.264.986-.489.432-.325.723-.875.872-1.65h1.834Z"
      fill="#FFDE15"
    />
  </svg>
);
export default SvgSn41;
