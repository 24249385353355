import type { SVGProps } from "react";
const SvgMountainMinusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.498 3.977.438.778 7.9 14.044.846 1.45.439.751H1.88l.438-.752 2.536-4.346 6.21-11.145.434-.78ZM6.649 14.734l5.105-3.115.327-.2.277.264 4.985 4.723-5.84-10.382-4.854 8.71Zm12.357 4.625-7.058-6.687-6.292 3.84L3.621 20H19.38l-.374-.64Zm1.998-12.357H15.94v-1h5.066v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainMinusSmall;
