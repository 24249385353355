import type { SVGProps } from "react";
const SvgSkisSkiPolesMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 3.25C4.48 3.25 3.25 4.48 3.25 6v26.75h5.5V6c0-1.52-1.23-2.75-2.75-2.75Zm-1.75 19.5V16.5a1.749 1.749 0 1 1 3.5 0v6.25h-3.5Zm1.75-9a2.74 2.74 0 0 1 1.75.628V6a1.749 1.749 0 1 0-3.5 0v8.378A2.74 2.74 0 0 1 6 13.75Zm-1.75 10v8h3.5v-8h-3.5Zm9.25-20.5c-1.52 0-2.75 1.23-2.75 2.75v26.75h5.5V6c0-1.52-1.23-2.75-2.75-2.75Zm1.75 11.128V6a1.749 1.749 0 1 0-3.5 0v8.378a2.74 2.74 0 0 1 1.75-.628 2.74 2.74 0 0 1 1.75.628Zm-3.5 2.122a1.749 1.749 0 1 1 3.5 0v6.25h-3.5V16.5Zm0 15.25v-8h3.5v8h-3.5Zm9.72-27.423 4.78 12.977 4.78-12.977.94.346-.807 2.188.495.165.474.158-.158.474-1.5 4.5-.158.475-.474-.158-.665-.222-2.394 6.497 4.198 11.396 1.86-.62.317.948-1.83.61.641 1.743-.938.346-.653-1.772-1.72.573-.316-.948 1.69-.564-3.782-10.266-3.782 10.266 1.69.564-.316.948-1.72-.573-.653 1.772-.938-.346.642-1.742-1.831-.61.316-.95 1.86.62 4.2-11.395-2.395-6.497-.665.222-.474.158-.158-.474-1.5-4.5-.158-.475.474-.158.495-.165-.806-2.188.938-.346Zm8.052 6.987.161.054 1.184-3.552-.05-.016-1.295 3.514Zm-6.705.054.16-.054L21.684 7.8l-.05.016 1.184 3.552Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSkisSkiPolesMedium;
