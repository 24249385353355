import type { SVGProps } from "react";
const SvgTrainStationLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.191 5H9V4h17v1h-3.25L20.5 8h4a5.5 5.5 0 0 1 5.5 5.5V38h-3v1.5c0 .172-.03.34-.086.5H35v-6h4v-7h-1v-7.44h6V27h-1v7h3v1H36v6H2v-1h5.086A1.5 1.5 0 0 1 7 39.5V38H4V13.5A5.5 5.5 0 0 1 9.5 8h4.191l-1.5-3ZM42 26.5V34h-2v-8h-1v-5.44h4V26h-1v.5ZM24.086 40a1.498 1.498 0 0 1-.086-.5V38H10v1.5c0 .172-.03.34-.086.5h14.172ZM8.5 40a.5.5 0 0 0 .5-.5V38H8v1.5a.5.5 0 0 0 .5.5Zm17 0a.5.5 0 0 0 .5-.5V38h-1v1.5a.5.5 0 0 0 .5.5ZM13.309 5H21.5l-2.25 3h-4.441l-1.5-3Zm-6.991 5.318A4.5 4.5 0 0 1 9.5 9h15a4.5 4.5 0 0 1 4.5 4.5v10.635l-12 3.84-12-3.84V13.5a4.5 4.5 0 0 1 1.318-3.182ZM5 25.185V37h24V25.185l-11.848 3.791-.152.049-.152-.049L5 25.185ZM8.46 31a.46.46 0 0 0-.46.46v.08c0 .254.206.46.46.46h3.08a.46.46 0 0 0 .46-.46v-.08a.46.46 0 0 0-.46-.46H8.46ZM7 31.46c0-.806.654-1.46 1.46-1.46h3.08c.806 0 1.46.654 1.46 1.46v.08A1.46 1.46 0 0 1 11.54 33H8.46A1.46 1.46 0 0 1 7 31.54v-.08Zm15 0a.46.46 0 0 1 .46-.46h3.08a.46.46 0 0 1 .46.46v.08a.46.46 0 0 1-.46.46h-3.08a.46.46 0 0 1-.46-.46v-.08Zm.46-1.46A1.46 1.46 0 0 0 21 31.46v.08c0 .806.654 1.46 1.46 1.46h3.08A1.46 1.46 0 0 0 27 31.54v-.08A1.46 1.46 0 0 0 25.54 30h-3.08ZM8 14.5a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5v-8Zm.5-1.5A1.5 1.5 0 0 0 7 14.5v8A1.5 1.5 0 0 0 8.5 24h17a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5h-17ZM7 36v-1h2v1H7Zm20 0v-1h-2v1h2Zm-6-24h-7v-1h7v1Zm19 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainStationLarge;
