import type { SVGProps } from "react";
const SvgLocationPinMapMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.196 6.303.292.157 7.5 4.05.262.142V13.5h-1v-2.252l-6.5-3.51V19.91l1.074 1.208-.748.664-.884-.995-7.13 5.704-.298.239-.309-.226-7.5-5.475-.205-.15V6.524l.792.57 7.192 5.179 7.204-5.763.258-.207ZM19.75 19.86V7.94l-6.5 5.2v11.92l6.5-5.2Zm-14-11.384 6.5 4.68v11.96l-6.5-4.745V8.476Zm17.006 11.307c0-2.468 1.867-4.532 4.244-4.532 2.379 0 4.246 2.063 4.246 4.532v.004c-.007.761-.231 1.432-.56 2.052l-.005.01-.005.01-3.233 5.497-.43.733-.432-.732-3.235-5.498-.006-.01a4.414 4.414 0 0 1-.584-2.049v-.017Zm1-.009c.022.596.185 1.097.455 1.583l2.801 4.76 2.796-4.755c.271-.516.432-1.026.438-1.581-.001-1.986-1.488-3.53-3.246-3.53-1.754 0-3.24 1.54-3.244 3.523Zm2.994-.274c0-.133.117-.25.25-.25s.25.117.25.25a.25.25 0 0 1-.242.25.263.263 0 0 1-.258-.25Zm.25-1.25c-.686 0-1.25.564-1.25 1.25 0 .692.578 1.25 1.258 1.25a1.25 1.25 0 0 0-.008-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinMapMedium;
