import type { SVGProps } from "react";
const SvgFaceKingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m9.25 2.867.757.454 3.415 2.05 1.974-1.975.354-.353.354.353L18 5.293l1.896-1.897.354-.353.354.353 1.974 1.974 3.415-2.049.757-.454v9.683a11.226 11.226 0 0 1 3 8.468 11.75 11.75 0 0 1-23.5 0 11.225 11.225 0 0 1 3-8.468V2.867Zm.715 10.383a10.224 10.224 0 0 0-2.716 7.714l.001.018V21a10.75 10.75 0 1 0 21.5 0v-.018l.001-.018a10.224 10.224 0 0 0-2.716-7.714H9.965Zm.285-8.617v7.617h15.5V4.633L22.757 6.43l-.335.2-.276-.275-1.896-1.897-1.896 1.897-.354.353-.354-.353-1.896-1.897-1.896 1.897-.276.276-.335-.201-2.993-1.796Zm11.845 14.212a.52.52 0 1 0 0 1.04.52.52 0 0 0 0-1.04Zm-1.52.52a1.52 1.52 0 1 1 3.04 0 1.52 1.52 0 0 1-3.04 0Zm-7.19 0a.52.52 0 1 1 1.04 0 .52.52 0 0 1-1.04 0Zm.52-1.52a1.52 1.52 0 1 0 0 3.04 1.52 1.52 0 0 0 0-3.04Zm.68 9.546a7.25 7.25 0 0 1-2.61-2.363l.83-.556a6.25 6.25 0 0 0 10.39 0l.83.556a7.25 7.25 0 0 1-9.44 2.363Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceKingMedium;
