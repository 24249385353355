import type { SVGProps } from "react";
const SvgEv90Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-90-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.145 8.688h2.19c.18.9.701 1.349 1.563 1.349.487 0 .881-.164 1.183-.492.306-.333.542-.849.706-1.547.105-.444.182-.95.23-1.516-.355.498-.72.852-1.095 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.031-1.357 4.457 4.457 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.768-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.296 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.586-.865-.678-.577-1.069-1.351-1.175-2.325Zm3.856-7.006c-.497 0-.917.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.815.817 1.428.817.556 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.611 1.611 0 0 0-1.34-.66Zm8.977-1.96c.883 0 1.66.27 2.332.81.667.539 1.15 1.37 1.452 2.49.228.858.342 1.789.342 2.794 0 1.666-.297 3.036-.889 4.11-.35.624-.807 1.105-1.373 1.444a3.567 3.567 0 0 1-1.88.508c-.947 0-1.756-.286-2.428-.857-1.196-1.016-1.793-2.75-1.793-5.205 0-1.746.312-3.148.936-4.205a3.782 3.782 0 0 1 1.388-1.397 3.8 3.8 0 0 1 1.913-.492Zm-.088 1.96c-.761 0-1.28.439-1.555 1.317-.217.698-.325 1.677-.325 2.936 0 1.11.111 2.002.333 2.674.286.873.815 1.309 1.587 1.309.64 0 1.116-.338 1.428-1.016.312-.677.468-1.71.468-3.102 0-1.185-.11-2.119-.333-2.8-.285-.879-.82-1.318-1.603-1.318Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv90Negative;
