import type { SVGProps } from "react";
const SvgEv77 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-77_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.016 0v1.967c-.063.07-.25.283-.563.643-.434.497-.865 1.124-1.293 1.88a12.781 12.781 0 0 0-1 2.167c-.503 1.444-.807 3.092-.913 4.943h-2.396c.016-.873.167-1.84.453-2.904a19.09 19.09 0 0 1 1.198-3.19c.724-1.502 1.46-2.62 2.205-3.356h-5.554V4.17h7.863Zm8.802 0v1.967c-.063.07-.251.283-.563.643-.434.497-.865 1.124-1.294 1.88a12.773 12.773 0 0 0-1 2.167c-.502 1.444-.806 3.092-.912 4.943h-2.396c.016-.873.166-1.84.452-2.904.291-1.063.69-2.127 1.198-3.19.725-1.502 1.46-2.62 2.206-3.356h-5.554V4.17h7.863Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv77;
