import type { SVGProps } from "react";
const SvgDoorMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 29.25h-27M24 18.75h-1.5m3.75 10.5h-10.5V6.75h10.5v22.5Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgDoorMedium;
