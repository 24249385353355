import type { SVGProps } from "react";
const SvgHalfFareCardMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 11.25c0-1.932 1.568-3.5 3.5-3.5h22.5c1.932 0 3.5 1.568 3.5 3.5v15c0 1.932-1.568 3.5-3.5 3.5H6.75a3.501 3.501 0 0 1-3.5-3.5v-15Zm3.5-2.5a2.5 2.5 0 0 0-2.5 2.5v15a2.5 2.5 0 0 0 2.5 2.5h22.5a2.5 2.5 0 0 0 2.5-2.5v-15a2.5 2.5 0 0 0-2.5-2.5H6.75ZM12 18v-7.5h-1.35c-.11.501-.322.86-.64 1.072a2.36 2.36 0 0 1-.728.319A4.853 4.853 0 0 1 8.25 12v1.09h2.031V18H12Zm9.292 2.082c-.486-.387-1.094-.582-1.821-.582-.82 0-1.488.237-2.003.714-.53.485-.795 1.173-.795 2.063v.159h1.499c.027-1.087.44-1.628 1.245-1.628.328 0 .59.099.792.297.202.2.303.459.303.78 0 .276-.074.52-.225.73-.149.212-.41.437-.785.683-.768.507-1.282.857-1.539 1.047a5.12 5.12 0 0 0-.676.585 2.754 2.754 0 0 0-.58.931c-.139.36-.206.74-.206 1.139h5.625v-1.303H18.46c.183-.223.378-.416.59-.587.213-.17.564-.415 1.056-.736.516-.336.834-.551.955-.64.122-.087.252-.204.389-.352.426-.449.636-.983.636-1.6 0-.715-.266-1.28-.795-1.7Zm-11.918 5.75 12-13.5-.748-.664-12 13.5.748.664Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHalfFareCardMedium;
