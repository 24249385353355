import type { SVGProps } from "react";
const SvgEv36Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-36-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.674c.026-.953.262-1.751.706-2.397.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.145 1.084-.436 1.507-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.269 2.816-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.57-.195-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.047-.291-.27-.674-.405-1.15-.405-.546 0-.966.17-1.262.508-.296.338-.455.833-.476 1.484h-2.198Zm16.712-.778H40.01c-.206-.894-.714-1.341-1.523-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.47.72-.81 1.11-1.016.398-.206.874-.31 1.43-.31 1.089 0 1.964.387 2.625 1.16.63.73.945 1.644.945 2.745 0 1.306-.424 2.356-1.27 3.15-.762.714-1.677 1.07-2.745 1.07-1.37 0-2.436-.515-3.198-1.546-.762-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.221-4.65.789-1.084 1.865-1.626 3.23-1.626 1.285 0 2.27.431 2.951 1.293.392.498.643 1.124.754 1.88ZM38.29 9.724c-.556 0-.998.233-1.325.698-.297.413-.445.905-.445 1.476 0 .566.151 1.06.453 1.484.328.47.777.706 1.348.706.556 0 1.003-.225 1.341-.674.318-.424.476-.923.476-1.5 0-.566-.142-1.05-.428-1.452-.35-.492-.823-.738-1.42-.738Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv36Negative;
