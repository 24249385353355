import type { SVGProps } from "react";
const SvgSeatMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 7.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM9.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm3.03 18.92-6-16.5.94-.34 5.88 16.17H24v1H12.4l-.12-.33Zm-.715-14.17h-.71l.239.668 3.75 10.5.119.332h9.796l3.73 7.288.89-.456-3.87-7.56-.138-.272h-9.704l-3.393-9.5h1.944l2.129 5.69.121.325h6.002v-1h-5.308l-2.129-5.69-.121-.325h-3.347Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSeatMedium;
