import type { SVGProps } from "react";
const SvgLiftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m19 3.17.354.355 2.12 2.122-.707.707L19 4.585l-1.767 1.769-.708-.707 2.121-2.122L19 3.17Zm-16.75.08h12.5v17.5H2.25V3.25Zm1 1v15.5h10.5V4.25H3.25Zm13.983 13.397L19 19.415l1.768-1.768.707.707-2.121 2.121-.354.354-.353-.354-2.121-2.12.707-.708ZM8.5 6a.5.5 0 1 0-.001 1 .5.5 0 0 0 0-1ZM7 6.5a1.5 1.5 0 1 1 2.999 0 1.5 1.5 0 0 1-3 0ZM6.5 9H6v6h1v4h3v-4h1V9H6.5Zm1 5H7v-4h3v4H9v4H8v-4h-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLiftSmall;
