import type { SVGProps } from "react";
const SvgBikeProfilePowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.5 3H13v1h2.566l-1.334 2H8.65l-.12.33-.643 1.769A4 4 0 1 0 7 16c2.206 0 4-1.794 4-4a4.004 4.004 0 0 0-2.174-3.559L9.35 7h4.828l.725 1.595A4 4 0 0 0 17.001 16c2.206 0 4-1.794 4-4a4.004 4.004 0 0 0-5.188-3.82l-.743-1.634 1.846-2.769.518-.777H16.5Zm-2.499 9a3 3 0 0 1 1.32-2.485l1.225 2.692.91-.414L16.232 9.1a3.004 3.004 0 0 1 3.77 2.9A3.007 3.007 0 0 1 17 15c-1.655 0-3-1.346-3-3Zm-6.46-2.951-1.01 2.78.939.342 1.01-2.78A3.002 3.002 0 0 1 10 12c0 1.654-1.346 3-3 3s-3-1.346-3-3a3.004 3.004 0 0 1 3.541-2.951ZM7.208 5h2.793V4H7.209v1ZM14 17.999v-1h-.5l-1.002.002a2.5 2.5 0 0 0-2.448 1.998H7v1h3.05a2.502 2.502 0 0 0 2.45 2H14v-1h2v-1h-2v-1l2.001-.004-.002-1-1.999.004ZM12.498 18H13v3h-.5a1.5 1.5 0 0 1-.002-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBikeProfilePowerPlugSmall;
