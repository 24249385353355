import type { SVGProps } from "react";
const SvgChevronDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18 23.293 11.646-11.646.708.707-12 12-.354.353-.354-.353-12-12 .708-.707L18 23.293Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronDownMedium;
