import type { SVGProps } from "react";
const SvgAlarmClockMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m5.604 10.103 4.5-4.5-.708-.707-4.5 4.5.708.707ZM7.25 18c0-5.937 4.813-10.75 10.75-10.75 5.938 0 10.75 4.813 10.75 10.75 0 5.938-4.812 10.75-10.75 10.75-5.937 0-10.75-4.812-10.75-10.75ZM18 6.25C11.51 6.25 6.25 11.51 6.25 18S11.51 29.75 18 29.75 29.75 24.49 29.75 18 24.49 6.25 18 6.25Zm12.396 3.853-4.5-4.5.708-.707 4.5 4.5-.707.707ZM16.75 10.5v8.75h8.75v-1h-7.75V10.5h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAlarmClockMedium;
