import type { SVGProps } from "react";
const SvgWheelchairSectorLocationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25.486 4.72a5.762 5.762 0 0 0-5.765 5.765v.017c.033.999.335 1.846.807 2.627l.005.009 4.545 7.17.423.666.422-.666 4.545-7.17.007-.012.007-.012c.447-.799.752-1.645.769-2.62v-.009a5.762 5.762 0 0 0-5.765-5.765Zm-4.105 7.887c-.395-.656-.632-1.335-.66-2.13a4.762 4.762 0 0 1 4.765-4.757 4.762 4.762 0 0 1 4.765 4.76c-.014.759-.247 1.438-.635 2.134l-4.115 6.492-4.12-6.5Zm2.355-2.137c0-.969.78-1.75 1.75-1.75.969 0 1.75.781 1.75 1.75s-.781 1.75-1.75 1.75c-.97 0-1.75-.781-1.75-1.75Zm1.75-2.75a2.747 2.747 0 0 0-2.75 2.75 2.747 2.747 0 0 0 2.75 2.75 2.747 2.747 0 0 0 2.75-2.75 2.747 2.747 0 0 0-2.75-2.75ZM11.029 9.03c0-.144.106-.25.25-.25.144 0 .25.106.25.25 0 .144-.106.25-.25.25a.243.243 0 0 1-.25-.25Zm.25-1.25c-.696 0-1.25.554-1.25 1.25s.554 1.25 1.25 1.25 1.25-.554 1.25-1.25-.554-1.25-1.25-1.25Zm-.5 4.25v8.75h7.5v7.75h1v-8.75h-7.5v-7.75h-1Zm-2.426 3.95c-2.11 1.07-3.574 3.278-3.574 5.8 0 3.576 2.924 6.5 6.5 6.5 2.587 0 4.843-1.544 5.882-3.736l-.904-.428c-.88 1.858-2.795 3.164-4.978 3.164-3.024 0-5.5-2.476-5.5-5.5 0-2.128 1.237-4 3.027-4.91l-.453-.89Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairSectorLocationMedium;
