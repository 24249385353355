import type { SVGProps } from "react";
const SvgFaceThinkingLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 23.5C7 13.726 14.726 6 24.5 6 34.274 6 42 13.726 42 23.5c0 1.53-.185 2.997-.544 4.396l.968.248c.381-1.481.576-3.033.576-4.644C43 13.174 34.826 5 24.5 5S6 13.174 6 23.5c0 7.96 4.861 14.645 11.823 17.288l.354-.935C11.6 37.355 7 31.038 7 23.5Zm10-5c0-.826.674-1.5 1.5-1.5s1.5.674 1.5 1.5-.674 1.5-1.5 1.5-1.5-.674-1.5-1.5Zm1.5-.5c-.274 0-.5.226-.5.5s.226.5.5.5.5-.226.5-.5-.226-.5-.5-.5Zm10.5.5c0-.826.674-1.5 1.5-1.5s1.5.674 1.5 1.5-.674 1.5-1.5 1.5-1.5-.674-1.5-1.5Zm1.5-.5c-.274 0-.5.226-.5.5s.226.5.5.5.5-.226.5-.5-.226-.5-.5-.5Zm3.598 8.49-19 3.8-.196-.98 19-3.8.196.98Zm6.826 5.16h.001c.881-.37 1.791-.124 2.25.614.614.985.22 2.362-.787 2.809h-.002l-5.53 2.47-.296.133v1.784A4.538 4.538 0 0 1 32.02 44h-7.097c-.194-.904-.646-1.833-1.201-2.714-.651-1.032-1.47-2.038-2.267-2.916a35.871 35.871 0 0 0-3.062-2.983l-.022-.018a1.76 1.76 0 0 1 1.02-2.704h.003c.362-.093.75-.063 1.102.086l.194-.461-.194.46 8.81 3.71.196.083.195-.083 11.227-4.81Zm-23.29 4.405.046.039.003.003.012.01.049.041.188.163a34.92 34.92 0 0 1 2.782 2.731c.774.853 1.553 1.812 2.162 2.778.614.973 1.03 1.914 1.127 2.739l.052.441h7.965a5.538 5.538 0 0 0 5.54-5.54v-1.136l5.232-2.337h.001c1.611-.714 2.138-2.796 1.231-4.251-.76-1.222-2.23-1.536-3.488-1.007l-.003.001-11.035 4.727-8.614-3.628a2.735 2.735 0 0 0-1.736-.134c-1.906.477-2.71 2.736-1.55 4.312l.035.048Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceThinkingLarge;
