import type { SVGProps } from "react";
const SvgToiletSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 5.5a.5.5 0 1 1 .999 0 .5.5 0 0 1-1 0ZM5.5 4a1.5 1.5 0 1 0-.001 3 1.5 1.5 0 0 0 0-3Zm-2 4H3v6h1v6h3v-6h1V8H3.5Zm1 5H4V9h3v4H6v6H5v-6h-.5ZM17 19v-3h1v3h-1Zm-1 .5V16h-2.163l.182-.638 2-7L16.123 8h2.754l.104.362 2 7 .182.638H19v4h-3v-.5Zm1-14a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.623 5-1.714 6h4.674l-1.714-6h-1.246ZM11 4v16h1V4h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgToiletSmall;
