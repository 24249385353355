import type { SVGProps } from "react";
const SvgCutleryLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 6v10.517C8 17.336 8.664 18 9.483 18h2.034c.819 0 1.483-.664 1.483-1.483V6h1v10.517c0 1.206-.86 2.211-2 2.436V41.5a1.5 1.5 0 0 1-3 0V18.953c-1.14-.225-2-1.23-2-2.436V6h1Zm3 13h-1v22.5a.5.5 0 1 0 1 0V19ZM10 6v9H9V6h1Zm25.088 2.535C34.426 9.528 34 10.928 34 12.5s.426 2.972 1.088 3.965C35.752 17.462 36.615 18 37.5 18s1.748-.538 2.412-1.535C40.574 15.472 41 14.072 41 12.5s-.426-2.972-1.088-3.965C39.248 7.538 38.385 7 37.5 7s-1.748.538-2.412 1.535Zm-.833-.555C35.04 6.805 36.176 6 37.5 6s2.461.805 3.245 1.98C41.53 9.159 42 10.758 42 12.5c0 1.742-.47 3.341-1.255 4.52-.468.701-1.061 1.27-1.745 1.616V41.5a1.5 1.5 0 0 1-3 0V18.636c-.684-.346-1.277-.915-1.745-1.616C33.47 15.841 33 14.242 33 12.5c0-1.742.47-3.341 1.255-4.52ZM37.5 19c.17 0 .337-.013.5-.039V41.5a.5.5 0 0 1-1 0V18.961c.163.026.33.039.5.039Zm-15-13H22v35.5a1.5 1.5 0 0 0 3 0v-18h3V13c0-2.258-.581-4.008-1.581-5.203C25.413 6.595 24.019 6 22.5 6Zm.5 35.5V7.027a3.938 3.938 0 0 1 2.652 1.412C26.462 9.407 27 10.906 27 13v9.5h-3v19a.5.5 0 0 1-1 0ZM12 15V6h-1v9h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCutleryLarge;
