import type { SVGProps } from "react";
const SvgCircleInformationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 12c0-5.03 3.972-9 9-9 5.028 0 9 3.97 9 9 0 5.028-3.972 9-9 9-5.028 0-9-3.972-9-9Zm9-10C6.42 2 2 6.418 2 12c0 5.58 4.42 10 10 10s10-4.42 10-10c0-5.582-4.42-10-10-10Zm.98 6V6h-1v2h1Zm0 7.99h2v1h-5v-1h2v-5H10v-1h2.98v6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleInformationSmall;
