import type { SVGProps } from "react";
const SvgSuitcaseDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M45.647 45.354 40.293 40H5.5A2.5 2.5 0 0 1 3 37.5v-22A2.5 2.5 0 0 1 5.5 13h7.793L2.646 2.354l.708-.708 43 43-.707.708ZM14.293 14 35 34.707V39H13V14h1.293ZM36 39v-3.293l2 2V39h-2Zm3 0v-.293l.293.293H39ZM12 14v25h-2V14h2ZM5.5 39H9V14H5.5A1.5 1.5 0 0 0 4 15.5v22A1.5 1.5 0 0 0 5.5 39ZM39 14h3.5a1.5 1.5 0 0 1 1.5 1.5v22.013a1.5 1.5 0 0 1-1.162 1.5l.224.974A2.5 2.5 0 0 0 45 37.494V15.5a2.5 2.5 0 0 0-2.5-2.5H31V9.5A2.5 2.5 0 0 0 28.5 7h-9A2.5 2.5 0 0 0 17 9.5V14h18v18h1V14h2v21h1V14Zm-21-1h1v-2.5A1.5 1.5 0 0 1 20.5 9h7a1.5 1.5 0 0 1 1.5 1.5V13h1V9.5A1.5 1.5 0 0 0 28.5 8h-9A1.5 1.5 0 0 0 18 9.5V13Zm10-2.5V13h-8v-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseDisabledLarge;
