import type { SVGProps } from "react";
const SvgTrainSignalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.325 3.25h-.207l-.146.146-1.575 1.575-.147.147v22.764l.147.147 1.575 1.575.146.146h2.632V33h1v-3.25h.5V33h1v-3.25h2.632l.147-.146 1.575-1.575.146-.147V5.118l-.146-.147-1.575-1.575-.146-.146h-7.558ZM13.25 5.532l1.283-1.282h6.935l1.282 1.282v21.936l-1.282 1.282h-6.935l-1.283-1.282V5.532ZM18 7.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM18 13.75a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm0 8.5a1.75 1.75 0 1 0 .002 3.502A1.75 1.75 0 0 0 18 22.25ZM15.25 24a2.75 2.75 0 1 1 5.502.002A2.75 2.75 0 0 1 15.25 24Zm10.25-6.25h3v-1h-3v1Zm-15 0h-3v-1h3v1Zm-6 0H6v-1H4.5v1Zm4.854 3.604-3 3-.708-.708 3-3 .708.708Zm21 2.292-3-3-.708.708 3 3 .708-.708Zm-24-13.5 3 3-.708.708-3-3 .708-.708ZM31.5 17.75H30v-1h1.5v1Zm-4.146-3.896 3-3-.708-.708-3 3 .708.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainSignalMedium;
