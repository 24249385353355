import type { SVGProps } from "react";
const SvgCloudLittleSnowSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.25 6V3h1v3h-1Zm-2.282 3.839a4.6 4.6 0 0 1 1.796 1.852c.481.92.662 2.04.416 3.293.79.118 1.424.516 1.865 1.08.096.123.183.253.26.389 1.451-.589 2.445-1.994 2.445-3.703 0-2.26-1.74-4-4-4-1.101 0-2.074.411-2.782 1.089Zm4.695 7.547c.09.389.119.795.087 1.197a3.94 3.94 0 0 1-1.162 2.52c-.72.702-1.757 1.147-3.088 1.147H12c-1.374 0-2.416-.442-3.124-1.155-.702-.707-1.034-1.638-1.073-2.548-.04-.906.209-1.825.713-2.53.416-.581 1.014-1.024 1.758-1.167.007-2.802 2.942-4.36 5.134-3.134 1.281-2.282 3.626-2.91 5.582-2.303.91-1.031 2.25-1.663 3.76-1.663 2.813 0 5 2.187 5 5 0 2.126-1.251 3.9-3.087 4.636Zm-10.605-4.734c1.01-2.29 3.124-2.837 4.81-2.225.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.199.67.698-.028c.778-.031 1.33.273 1.687.73.368.47.55 1.133.496 1.825a2.942 2.942 0 0 1-.862 1.882c-.517.503-1.295.863-2.39.863H12c-1.151 0-1.923-.363-2.415-.86-.5-.502-.754-1.183-.784-1.886-.031-.707.167-1.4.527-1.905.355-.496.856-.799 1.471-.799h.564l-.067-.559c-.296-2.473 2.359-3.737 3.987-2.404l.508.417.266-.602Zm1.34-6.475 2.16 2.08.694-.721-2.162-2.08-.693.721ZM31.456 20.27l-2.121-2.12.707-.708 2.121 2.121-.707.707Zm3.043-8.02h-3v1h3v-1ZM29.17 7.6l2.121-2.12.707.707-2.12 2.12-.708-.707ZM14.75 25.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2 4.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudLittleSnowSunMedium;
