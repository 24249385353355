import type { SVGProps } from "react";
const SvgPictureSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 2H2v20h20V2H2.5ZM3 17V3h18v14h-.7l-3.859-7.235-.334-.626-.477.525-4.67 5.137-3.63-3.177-.47-.411-.299.548L3.703 17H3Zm0 1v3h18v-3H3ZM7 6a1 1 0 1 0-.001 1.999A1 1 0 0 0 7 6ZM5 7a2 2 0 1 1 3.999-.001A2 2 0 0 1 5 7Zm14.167 10-3.274-6.139-4.523 4.975-.33.363-.37-.323-3.53-3.089L4.842 17h14.325Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPictureSmall;
