import type { SVGProps } from "react";
const SvgFastForwardMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m6.25 7.272.793.573 6.75 4.875 1.457 1.052v-6.5l.793.573 6.75 4.875 6.75 4.874.561.406-.561.405-6.75 4.875-6.75 4.875-.793.573v-6.501l-1.457 1.053-6.75 4.875-.793.573V7.272Zm1 1.956v17.544l5.957-4.302 2.25-1.626.793-.573v6.501l5.957-4.302L28.396 18l-6.189-4.47-5.957-4.302v6.499l-.793-.572-2.25-1.625L7.25 9.228Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFastForwardMedium;
