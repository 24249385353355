import type { SVGProps } from "react";
const SvgFireplaceMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 3.25h29.5v2.957l-.146.147-1.354 1.353V32.75H4.75V7.707L3.396 6.354l-.146-.147V3.25Zm1 1v1.543l1.354 1.353.146.147V31.75h3.5v-18h17.498l.002.498.067 17.502h3.433V7.293l.147-.147 1.353-1.353V4.25H4.25Zm21.567 27.5-.065-17H10.25v17h3.3a21.268 21.268 0 0 1-.275-.94c-.263-.981-.553-2.328-.65-3.777-.19-2.863.367-6.338 3.651-7.98l.702-.351.022.784.006.228c.03 1.13.068 2.586.247 3.91.096.707.229 1.357.413 1.889.189.542.412.905.65 1.1.12.097.282.134.61-.045.335-.185.714-.54 1.085-.972a12.98 12.98 0 0 0 1.207-1.682l.075-.123.018-.031.005-.008v-.001l.542-.941.363 1.023-.471.167.471-.167v.002l.002.004.005.014.018.053a18.68 18.68 0 0 1 .276.907c.165.596.363 1.42.496 2.325.132.903.202 1.904.099 2.849-.067.61-.207 1.217-.463 1.763h3.163Zm-4.313 0h-6.901l-.028-.083a19.802 19.802 0 0 1-.334-1.117 18.974 18.974 0 0 1-.617-3.583c-.167-2.506.285-5.126 2.4-6.604.03 1.025.084 2.253.238 3.395.1.74.244 1.463.46 2.083.212.61.512 1.179.961 1.546.569.465 1.219.334 1.724.057.495-.272.968-.738 1.363-1.196.309-.36.589-.738.816-1.067.151.56.325 1.3.443 2.102.125.856.184 1.765.094 2.595-.08.742-.277 1.384-.619 1.872Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFireplaceMedium;
