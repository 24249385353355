import type { SVGProps } from "react";
const SvgTwoFoldersMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.25 4.75h-.309l-.138.277L24.44 7.75H9.25v9h-4.5v14.514h.5l22.5-.014h.5v-9h4.5V4.75h-6.5Zm2 16.5h3.5V5.75h-5.191l-1.362 2.724-.138.276H10.25v8h9.691l1.362-2.723.138-.276h6.809v7.5Zm-7.553-3.776 1.362-2.723h5.191v15.5l-21.5.013V17.75h14.809l.138-.276Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTwoFoldersMedium;
