import type { SVGProps } from "react";
const SvgTicketDaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 4H2v15h20v-5h-.5c-1.424 0-2.5-1.076-2.5-2.5S20.076 9 21.5 9h.5V4H2.5ZM3 18V5h13v.464h1V5h4v3.033c-1.727.233-3 1.66-3 3.467 0 1.806 1.273 3.234 3 3.466V18h-4v-.465h-1V18H3ZM4.5 7H4v9.975h.499l10 .025H15V7h-2V6h-1v1H7V6H6v1H4.5Zm.5 8.977V10h9v5.999l-9-.022ZM14 9H5V8h9v1Zm-5 4H8v-1h1v-1h1v4H9v-2Zm7-6.607v.928h1v-.928h-1Zm0 1.857v.928h1V8.25h-1Zm0 1.857v.929h1v-.93h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketDaySmall;
