import type { SVGProps } from "react";
const SvgIr83 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-83_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-83_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.195-1.547-1.195-2.634 0-1.018.354-1.806 1.06-2.365a2.924 2.924 0 0 1 1.068-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.467-1.123-.305-.269-.733-.403-1.28-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522Zm5.142-2.563c.026-.949.26-1.745.704-2.389.717-1.033 1.795-1.55 3.235-1.55 1.108 0 2.001.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.163.847c.623.142 1.113.459 1.472.949.363.49.545 1.084.545 1.78 0 1.144-.421 2.08-1.265 2.808-.78.675-1.767 1.012-2.959 1.012-1.292 0-2.317-.371-3.077-1.115-.754-.744-1.13-1.748-1.13-3.014v-.15h2.222c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.196-1.02-.586-1.353-.322-.274-.817-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.138.01.27.016.396.016 1.329 0 1.993-.493 1.993-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.672-.403-1.147-.403-.543 0-.962.169-1.257.506-.296.338-.454.83-.475 1.48h-2.191Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-83_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr83;
