import type { SVGProps } from "react";
const SvgChargingStationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.25 4.25a2.5 2.5 0 0 0-2.5 2.5v25h15.5v-25a2.5 2.5 0 0 0-2.5-2.5h-10.5Zm-3.5 2.5v25H6v1h21v-1h-1.75v-9.5h.25c.672 0 1.075.165 1.319.41.245.248.415.662.431 1.346v1.528c0 .377.001 1.017.224 1.57.12.297.312.593.62.812.309.22.696.334 1.156.334.458 0 .846-.11 1.156-.327.31-.215.503-.508.624-.805.226-.557.222-1.207.22-1.598v-4.805a3.502 3.502 0 0 0 3-3.465v-2h-1.5V12h-1v3.25h-2V12h-1v3.25h-1.5v2a3.502 3.502 0 0 0 3 3.465v4.788c.002.425-.003.885-.147 1.239a.776.776 0 0 1-.269.36c-.112.079-.29.148-.584.148-.29 0-.465-.07-.577-.15a.803.803 0 0 1-.271-.37c-.145-.36-.151-.821-.152-1.23v-1.511c-.019-.825-.226-1.534-.722-2.033-.498-.502-1.203-.706-2.028-.706h-.25V6.75c0-1.932-1.568-3.5-3.5-3.5h-10.5a3.501 3.501 0 0 0-3.5 3.5Zm3.5 1h-.5v7h11.5v-7h-11Zm.5 6v-5h9.5v5h-9.5Zm16.5 3.5v-1h5v1a2.501 2.501 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChargingStationMedium;
