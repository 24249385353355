import type { SVGProps } from "react";
const SvgCloudMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.131 15.013a2.92 2.92 0 0 1 1.879 1.07c.555.692.801 1.609.74 2.51a3.843 3.843 0 0 1-1.16 2.517c-.729.7-1.78 1.14-3.139 1.14h-10.5c-1.373 0-2.415-.44-3.123-1.15-.702-.704-1.034-1.632-1.074-2.538-.04-.903.21-1.819.714-2.52.416-.58 1.014-1.02 1.757-1.163.008-2.795 2.946-4.336 5.135-3.118 1.329-2.358 3.803-2.94 5.799-2.22 1.063.384 2 1.14 2.555 2.195.482.915.663 2.03.418 3.277Zm-3.312-4.531c-1.688-.61-3.801-.064-4.81 2.214l-.266.599-.508-.414c-1.632-1.331-4.282-.066-3.987 2.39l.067.559h-.564c-.617 0-1.117.302-1.471.795-.36.501-.558 1.19-.527 1.893.03.699.285 1.376.783 1.876.492.493 1.265.856 2.415.856h10.5c1.133 0 1.926-.362 2.447-.862a2.844 2.844 0 0 0 .854-1.864c.047-.685-.145-1.345-.522-1.815-.367-.457-.93-.76-1.709-.73l-.7.028.201-.67c.379-1.268.233-2.326-.193-3.136a3.659 3.659 0 0 0-2.01-1.72Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudMedium;
