import type { SVGProps } from "react";
const SvgClipboardListMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.25 3.25h-.5v1.5h-9v28h26.5v-28h-9v-1.5h-8Zm.5 2.5v.5h6.5v-2h-6.5v1.5Zm7.5 0v1.5h-8.5v-1.5h-8v26h24.5v-26h-8ZM27 15H9v-1h18v1ZM9 19.5h18v-1H9v1ZM27 24H9v-1h18v1ZM9 28.5h18v-1H9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardListMedium;
