import type { SVGProps } from "react";
const SvgAlternative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="alternative_svg__color-immutable"
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M0 1.994C0 .894.895 0 1.994 0h12.012C15.106 0 16 .894 16 1.994v12.012A1.996 1.996 0 0 1 14.006 16H1.994A1.996 1.996 0 0 1 0 14.006V1.994Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.39 2h-1.5v8.317H5.782l1.724-1.725-1.061-1.06-3.536 3.537 3.536 3.537 1.06-1.061-1.727-1.728h4.612V2Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAlternative;
