import type { SVGProps } from "react";
const SvgChartColumnLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M38.5 7H38v34h8V7h-7.5Zm.5 33V8h6v32h-6ZM14.5 15H14v26h8V15h-7.5Zm.5 25V16h6v24h-6ZM2 32h8v9H2v-9Zm1 1v7h6v-7H3Zm43 13H2v-1h44v1ZM26 20h8v21h-8V20Zm1 1v19h6V21h-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnLarge;
