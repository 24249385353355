import type { SVGProps } from "react";
const SvgEiffelTowerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 2h-.449l-.048.446a72.612 72.612 0 0 1-2.9 13.903 32.062 32.062 0 0 1-2.047 4.921l-.378.73H10.5v-.5a1.5 1.5 0 1 1 3 0v.5h4.83l-.388-.734c-1.865-3.524-3.104-8.216-3.877-12.057a84.452 84.452 0 0 1-1.01-6.246l-.044-.383-.01-.098-.003-.025V2.45l-.498.05.497-.05-.045-.45H11.5Zm.447 1h.106l.01.081A69.36 69.36 0 0 0 12.318 5h-.628c.095-.665.18-1.332.256-2Zm-.405 3a73.62 73.62 0 0 1-.959 5h2.84a85.174 85.174 0 0 1-.95-5h-.931Zm2.111 6h-3.305a73.551 73.551 0 0 1-1.09 4h5.488a59.615 59.615 0 0 1-1.093-4Zm1.419 5h-6.13a33.064 33.064 0 0 1-1.632 4h2.24a2.5 2.5 0 0 1 4.9 0h2.236a34.363 34.363 0 0 1-1.614-4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEiffelTowerSmall;
