import type { SVGProps } from "react";
const SvgWalkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 5c-.257 0-.5.243-.5.5s.243.5.5.5.5-.243.5-.5-.243-.5-.5-.5Zm-1.5.5c0-.81.69-1.5 1.5-1.5s1.5.69 1.5 1.5S12.309 7 11.5 7 10 6.31 10 5.5ZM11 9v4h1V9h-1Zm-1 .434v6.914l-1.916 2.874.832.555 2-3 .084-.126V14h1.293L14 15.707V20h1v-4.707l-.146-.147L13 13.293V9.707l1.146 1.146.147.147H17v-1h-2.293l-1.853-1.854L12.707 8H10.35l-.126.084-3 2-.223.148V14h1v-3.233l2-1.333Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkSmall;
