import type { SVGProps } from "react";
const SvgWheelchairLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-4 3a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1.5 16C14.149 22 9 27.149 9 33.5S14.149 45 20.5 45 32 39.851 32 33.5 26.851 22 20.5 22Zm-4.763-.06c.396-.164.802-.307 1.217-.43l-.907-1.959a1.22 1.22 0 0 1 .235-1.37l1.819-1.879.86-.887v5.679A12.623 12.623 0 0 1 20.5 21c2.38 0 4.606.666 6.5 1.82v-8.16A1.66 1.66 0 0 0 25.34 13h-6.452a.41.41 0 0 0-.296.125l.002-.003-.364-.342.36.346.002-.001-4.578 4.877a.75.75 0 0 0-.096.9v.001l1.819 3.037Zm2.223-.682-.019.004-.987-2.132v-.001a.221.221 0 0 1 .042-.248l.003-.003.961-.993v3.373Zm-3.137 1.102A12.5 12.5 0 0 0 8 33.5C8 40.404 13.596 46 20.5 46S33 40.404 33 33.5c0-1.215-.173-2.39-.497-3.5h1.587L36 39.55V43h7v-1.5l.001-.239c.002-.188.003-.38-.003-.554a2.945 2.945 0 0 0-.108-.768 1.279 1.279 0 0 0-.483-.691C42.15 39.069 41.84 39 41.5 39h-1.468L37.39 26.953l-.001-.003A2.5 2.5 0 0 0 34.894 25h-5.229A12.57 12.57 0 0 0 28 23.499V14.66A2.66 2.66 0 0 0 25.34 12h-6.45v.5l.002-.5h-.001a1.408 1.408 0 0 0-1.022.434l-.004.004-4.58 4.88.365.342-.365-.342a1.75 1.75 0 0 0-.224 2.1v-.001l.429-.257-.429.258 1.762 2.942ZM42 42v-.5l.001-.27v-.001a12.4 12.4 0 0 0-.002-.49 2.001 2.001 0 0 0-.064-.503c-.033-.108-.07-.147-.101-.168-.035-.025-.124-.068-.334-.068h-2.272l-.086-.393-2.73-12.437v-.001a1.5 1.5 0 0 0-1.5-1.17L34.9 26h-4.4c.686.911 1.248 1.92 1.666 3h2.744l.08.402 2 10 .01.048V42h5ZM13.392 27.198A9.477 9.477 0 0 1 20 24.013v8.45l-6.608-5.265Zm-.624.781A9.457 9.457 0 0 0 11 33.5c0 .555.048 1.1.139 1.628l8.238-1.884-6.61-5.265Zm3.164 13.853a9.523 9.523 0 0 1-4.57-5.729l8.237-1.884-3.667 7.613Zm.9.434A9.47 9.47 0 0 0 20.5 43c1.3 0 2.54-.261 3.668-.734L20.5 34.652l-3.667 7.614Zm12.807-6.163a9.523 9.523 0 0 1-4.57 5.729L21.4 34.219l8.238 1.884Zm.222-.975A9.557 9.557 0 0 0 30 33.5a9.457 9.457 0 0 0-1.768-5.52l-6.608 5.264 8.237 1.884ZM21 24.013a9.477 9.477 0 0 1 6.608 3.184L21 32.462v-8.45ZM20.5 23C14.701 23 10 27.701 10 33.5S14.701 44 20.5 44 31 39.299 31 33.5 26.299 23 20.5 23Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairLarge;
