import type { SVGProps } from "react";
const SvgMountainPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.936 4.755-.438-.778-.435.78-6.21 11.145-2.535 4.346L1.88 21h19.24l-.438-.752-.845-1.449-7.901-14.044Zm-.182 6.864L6.65 14.734l4.853-8.71 5.84 10.382-4.983-4.723-.278-.264-.327.2Zm.194 1.053 7.058 6.687.373.641H3.622l2.035-3.489 6.292-3.84ZM19 7.002h2.005v-1H19V4h-1v2.002h-2.06v1H18V9h1V7.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainPlusSmall;
