import type { SVGProps } from "react";
const SvgBicycleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.75 9.249H19.5v1h4.316l-2.334 3.5H13.15l-.12.33-1.042 2.866A5.756 5.756 0 0 0 4.75 22.5a5.756 5.756 0 0 0 5.75 5.75 5.756 5.756 0 0 0 5.75-5.75 5.757 5.757 0 0 0-3.323-5.213l.923-2.538h7.578l1.248 2.744a5.753 5.753 0 0 0-2.924 5.007 5.755 5.755 0 0 0 5.75 5.75 5.756 5.756 0 0 0 5.75-5.75 5.756 5.756 0 0 0-7.666-5.422l-1.266-2.783 2.846-4.269.518-.777h-.934Zm-3.998 13.25c0-1.74.94-3.264 2.34-4.092l1.954 4.299.91-.414-1.955-4.3a4.756 4.756 0 0 1 6.25 4.508 4.756 4.756 0 0 1-4.75 4.75 4.755 4.755 0 0 1-4.75-4.75Zm-9.108-4.61-1.614 4.44.94.34 1.614-4.437A4.756 4.756 0 0 1 15.25 22.5a4.756 4.756 0 0 1-4.75 4.75 4.756 4.756 0 0 1-4.75-4.75 4.756 4.756 0 0 1 5.894-4.61Zm-.831-6.14H15v-1h-4.188v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBicycleMedium;
