import type { SVGProps } from "react";
const SvgGearChangingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.25C10.406 4.25 4.25 10.406 4.25 18S10.406 31.75 18 31.75 31.75 25.594 31.75 18 25.594 4.25 18 4.25ZM3.25 18C3.25 9.853 9.853 3.25 18 3.25S32.75 9.853 32.75 18 26.147 32.75 18 32.75 3.25 26.147 3.25 18Zm7.5-6v13.5h1v-6.25h6.5v6.25h1v-6.25h7.5V12h-1v6.25h-6.5V12h-1v6.25h-6.5V12h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGearChangingMedium;
