import type { SVGProps } from "react";
const SvgIc25 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-25_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-25_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.722 9.542v2.056h-8.33c0-.627.1-1.226.3-1.795.206-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.94-.744 1.162-1.076.222-.332.333-.717.333-1.155 0-.506-.15-.915-.451-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.697.918.786.664 1.18 1.558 1.18 2.681 0 .981-.315 1.825-.942 2.531a4.65 4.65 0 0 1-.578.562c-.179.137-.65.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.353 6.353 0 0 0-.87.926h5.426Zm8.44-9.508V6.29h-4.66l-.498 2.602c.56-.59 1.274-.886 2.144-.886.854 0 1.598.23 2.23.688.94.691 1.408 1.74 1.408 3.149 0 1.381-.5 2.475-1.502 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.208-.328-2.951-.982-.744-.659-1.137-1.547-1.179-2.665h2.27c.037.406.17.751.396 1.036.353.448.844.672 1.471.672.607 0 1.087-.218 1.44-.656.343-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.358-.401-.836-.602-1.431-.602-.728 0-1.295.327-1.701.981l-2.065-.031 1.1-6.447h6.352Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-25_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc25;
