import type { SVGProps } from "react";
const SvgCircleCrossSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10 .707-3.646 3.646-.708-.707L11.293 12 7.646 8.353l.708-.707L12 11.293l3.646-3.647.708.707L12.707 12l3.647 3.646-.708.707L12 12.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleCrossSmall;
