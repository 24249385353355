import type { SVGProps } from "react";
const SvgBusStopSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.999 2h15v3h-.998v17.001h-1V5H9v17.001H8V5H6.999V2Zm1 1v1h13V3h-13ZM2 7h5v6H5v9H4v-9H2V7Zm1 1v4h3V8H3Zm12.5 0a.5.5 0 1 0-.002.998A.5.5 0 0 0 15.5 8Zm-1.5.5a1.5 1.5 0 1 1 2.998-.002A1.5 1.5 0 0 1 14 8.5Zm-.5 2.5H13v6h1v5h3v-5h1v-6h-4.5Zm1 5H14v-4h3v4h-1v5h-1v-5h-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBusStopSmall;
