import type { SVGProps } from "react";
const SvgDatabaseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 6c0-.1.07-.299.45-.564.365-.256.926-.506 1.668-.725C7.594 4.276 9.668 4 12 4s4.407.276 5.882.711c.741.219 1.303.47 1.668.725.38.265.45.463.45.564 0 .1-.07.299-.45.564-.365.256-.927.506-1.668.725C16.406 7.724 14.332 8 12 8c-2.331 0-4.406-.276-5.882-.711-.742-.219-1.303-.47-1.668-.725C4.07 6.3 4 6.101 4 6ZM3 6c0-.59.4-1.05.876-1.383.49-.343 1.166-.631 1.959-.865C7.426 3.282 9.6 3 12 3s4.573.283 6.165.752c.793.234 1.47.522 1.959.865C20.6 4.95 21 5.41 21 6v12c0 .657-.424 1.154-.951 1.51-.532.357-1.258.635-2.079.85-1.649.431-3.82.641-5.97.641s-4.321-.21-5.97-.641c-.821-.215-1.547-.493-2.079-.85C3.424 19.153 3 18.656 3 18V6Zm1 9.556V18c0 .176.107.409.51.68.397.267 1 .51 1.773.712 1.538.403 3.617.61 5.717.61 2.1 0 4.179-.207 5.717-.61.773-.202 1.376-.445 1.774-.713.402-.27.509-.503.509-.679v-2.444c-.527.344-1.233.613-2.03.822-1.648.433-3.82.644-5.97.644s-4.322-.211-5.97-.644c-.797-.21-1.503-.478-2.03-.822Zm16-1.545c0 .179-.108.412-.51.684-.398.268-1 .512-1.773.715-1.538.404-3.617.612-5.717.612-2.1 0-4.178-.208-5.717-.612-.773-.203-1.375-.447-1.773-.715-.402-.272-.51-.505-.51-.684v-2.465c.527.343 1.233.612 2.03.82 1.648.434 3.82.645 5.97.645s4.322-.211 5.97-.644c.797-.21 1.503-.478 2.03-.821v2.465Zm0-6.545V10c0 .179-.108.413-.51.684-.398.269-1 .513-1.773.716-1.538.404-3.617.611-5.717.611-2.1 0-4.178-.207-5.717-.611-.773-.203-1.375-.447-1.773-.716C4.108 10.413 4 10.18 4 10V7.466c.477.307 1.107.567 1.835.782C7.426 8.718 9.6 9 12 9s4.573-.283 6.165-.752c.728-.215 1.358-.475 1.835-.782Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDatabaseSmall;
