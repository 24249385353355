import type { SVGProps } from "react";
const SvgFourSquaresSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.5 5H4v6h6V5H4.5Zm.5 5V6h4v4H5Zm8.5-5H13v6h6V5h-5.5Zm.5 5V6h4v4h-4ZM4 14h6v6H4v-6Zm1 1v4h4v-4H5Zm8.5-1H13v6h6v-6h-5.5Zm.5 5v-4h4v4h-4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFourSquaresSmall;
