import type { SVGProps } from "react";
const SvgArrowLongRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m29.59 18.384-4.714-4.712-.707.707 3.86 3.858H6v1h22.03l-3.861 3.87.708.707 4.713-4.724.353-.353-.354-.353Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongRightMedium;
