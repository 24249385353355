import type { SVGProps } from "react";
const SvgQuestionMarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.515 6.857c-.411.634-.638 1.529-.681 2.686h1.604c.131-2.04 1.026-3.059 2.686-3.059.802 0 1.408.258 1.819.774.342.422.513.945.513 1.566 0 .336-.06.666-.178.989-.142.38-.444.805-.904 1.278-.715.733-1.177 1.234-1.385 1.501a3.022 3.022 0 0 0-.481.905c-.155.46-.239 1.166-.251 2.117h1.585c0-.535.031-.971.094-1.306.055-.336.154-.617.293-.844.14-.226.387-.511.742-.853.801-.784 1.308-1.321 1.52-1.614.473-.64.709-1.405.709-2.294 0-1.075-.348-1.943-1.045-2.602-.746-.715-1.75-1.072-3.012-1.072-.784 0-1.49.158-2.117.475a3.818 3.818 0 0 0-1.511 1.353Zm4.514 12.123h-2.015v-1.939h2.015v1.939Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQuestionMarkSmall;
