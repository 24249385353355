import type { SVGProps } from "react";
const SvgRouteCircleStartMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.25 10.25a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-9.5 8.5c0-5.245 4.254-9.5 9.5-9.5 5.08 0 9.227 3.987 9.487 9H31.5v1h-7.763a9.5 9.5 0 0 1-9.487 9 9.5 9.5 0 0 1-9.5-9.5Zm9.5-3.999a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-5 4a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRouteCircleStartMedium;
