import type { SVGProps } from "react";
const SvgTargetLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M38 4.5V3.46l-.812.65-5 4-.188.15v6.044l-1.644 1.77A16.436 16.436 0 0 0 19.5 12C10.388 12 3 19.388 3 28.5 3 37.613 10.388 45 19.5 45 28.613 45 36 37.613 36 28.5a16.45 16.45 0 0 0-4.911-11.745L32.718 15h5.989l.147-.146 5-5L44.707 9H38V4.5ZM33 14V8.74l4-3.2V10h5.293l-4 4H33ZM4 28.5C4 19.94 10.94 13 19.5 13a15.44 15.44 0 0 1 10.175 3.807l-1.361 1.467A13.447 13.447 0 0 0 19.5 15C12.044 15 6 21.044 6 28.5S12.044 42 19.5 42 33 35.956 33 28.5c0-3.728-1.51-7.103-3.954-9.546l1.362-1.466A15.452 15.452 0 0 1 35 28.5C35 37.06 28.06 44 19.5 44 10.94 44 4 37.06 4 28.5ZM19.5 16c3.106 0 5.947 1.132 8.133 3.007l-2.726 2.935a8.5 8.5 0 1 0 .733.68l2.725-2.934A12.46 12.46 0 0 1 32 28.5C32 35.404 26.404 41 19.5 41S7 35.404 7 28.5 12.596 16 19.5 16Zm0 5a7.47 7.47 0 0 1 4.726 1.676l-2.735 2.945a3.5 3.5 0 1 0 .733.68l2.734-2.944A7.5 7.5 0 1 1 19.5 21Zm0 5c.476 0 .922.133 1.3.364l-1.666 1.796.732.68 1.667-1.795A2.5 2.5 0 1 1 19.5 26Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTargetLarge;
