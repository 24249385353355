import type { SVGProps } from "react";
const SvgExitSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.5 4H3v16h13v-3h-1v2H4V5h11v2h1V4H3.5Zm14.212 5.005 3.142 3.141.353.354-.353.353-3.142 3.15-.707-.707L19.294 13H7v-1h12.293l-2.288-2.287.707-.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgExitSmall;
