import type { SVGProps } from "react";
const SvgAvatarPoliceSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.5 3.98.137.04 7 2 .502.143-.165.495-1 3-.114.342h-1.37c-1.244 1.514-3 2.5-4.99 2.5-1.99 0-3.747-.986-4.991-2.5h-1.37l-.114-.342-1-3-.165-.495.502-.144 7-2 .138-.039ZM5.86 9h11.28l.72-2.162L11.5 5.02 5.14 6.838 5.86 9Zm2.016 1c1.01.944 2.265 1.5 3.624 1.5s2.614-.556 3.624-1.5H7.876ZM10.5 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6.942 8.7c-.125.608-.289 1.207-.52 1.57l-.002.002C14.966 20.296 12.728 21 11.49 21c-1.236 0-3.474-.703-5.429-3.729-.232-.361-.396-.96-.522-1.568a25.412 25.412 0 0 1-.318-2.053 50.477 50.477 0 0 1-.235-2.562l-.003-.042v-.015L5.48 11l.5-.031v.015l.003.04a31.492 31.492 0 0 0 .053.72c.039.474.097 1.115.177 1.789.08.675.182 1.375.304 1.969.128.62.265 1.043.384 1.229C8.693 19.503 10.63 20 11.49 20c.86 0 2.798-.497 4.59-3.27.119-.188.255-.614.383-1.232.122-.595.224-1.295.304-1.97a49.432 49.432 0 0 0 .23-2.505l.003-.04.001-.01v-.003l.5.03.498.03v.015l-.003.043-.011.16c-.01.138-.024.335-.044.576-.04.48-.099 1.133-.181 1.823a25.443 25.443 0 0 1-.318 2.053ZM9.5 14.2a.2.2 0 1 0 0-.4.2.2 0 0 0 0 .4Zm-.8-.2a.8.8 0 1 1 1.6 0 .8.8 0 0 1-1.6 0Zm4.8.2a.2.2 0 1 0 0-.4.2.2 0 0 0 0 .4Zm-.8-.2a.8.8 0 1 1 1.6 0 .8.8 0 0 1-1.6 0Zm-1.2 3c.976 0 1.774-.346 2.185-.789l.733.68C13.773 17.588 12.68 18 11.5 18c-1.112 0-2.14-.366-2.795-.984l.686-.727c.43.406 1.194.71 2.109.71Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAvatarPoliceSmall;
