import type { SVGProps } from "react";
const SvgContextMenuMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.25 7.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm0 10.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0ZM18 30.75a2.249 2.249 0 1 0 0-4.5 2.25 2.25 0 1 0 0 4.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M18 10.25a2.75 2.75 0 0 0 2.75-2.75h-1A1.75 1.75 0 0 1 18 9.25v1ZM15.25 7.5A2.75 2.75 0 0 0 18 10.25v-1a1.75 1.75 0 0 1-1.75-1.75h-1ZM18 4.75c-1.52 0-2.75 1.23-2.75 2.75h1c0-.967.783-1.75 1.75-1.75v-1Zm2.75 2.75c0-1.52-1.23-2.75-2.75-2.75v1c.967 0 1.75.783 1.75 1.75h1ZM18 20.75A2.75 2.75 0 0 0 20.75 18h-1A1.75 1.75 0 0 1 18 19.75v1ZM15.25 18A2.75 2.75 0 0 0 18 20.75v-1A1.75 1.75 0 0 1 16.25 18h-1ZM18 15.25c-1.52 0-2.75 1.23-2.75 2.75h1c0-.967.783-1.75 1.75-1.75v-1ZM20.75 18c0-1.52-1.23-2.75-2.75-2.75v1c.967 0 1.75.783 1.75 1.75h1Zm-1 10.5A1.75 1.75 0 0 1 18 30.25v1c1.52 0 2.75-1.23 2.75-2.75h-1ZM18 26.75c.967 0 1.75.784 1.75 1.75h1A2.75 2.75 0 0 0 18 25.75v1Zm-1.75 1.75c0-.966.783-1.75 1.75-1.75v-1a2.75 2.75 0 0 0-2.75 2.75h1ZM18 30.25a1.75 1.75 0 0 1-1.75-1.75h-1c0 1.52 1.23 2.75 2.75 2.75v-1Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgContextMenuMedium;
