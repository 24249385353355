import type { SVGProps } from "react";
const SvgWalkSlowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 5.5c0-.257.243-.5.5-.5s.5.243.5.5-.243.5-.5.5a.527.527 0 0 1-.5-.5Zm.5-1.5c-.81 0-1.5.69-1.5 1.5S12.69 7 13.5 7 15 6.31 15 5.5 14.31 4 13.5 4Zm-1.207 4h2.517l.138.276.98 1.96L17.4 12.2l-.8.6-1.5-2-.028-.036-.02-.04-.053-.107v2.853l.943 1.798.057.109V20h-1v-4.377l-.802-1.53H13v2.443l-.005.035-.5 3.5-.99-.142.495-3.464V9.708l-1 1V13h-1v-2.707l.147-.147 2-2L12.293 8ZM13 13.093V9h1v4.093h-1Zm-2.928.255L9.324 15.2l-1.388-.566-.464-.19-.188.466-1.124 2.782-.187.462.462.188 1.806.738a.5.5 0 1 0 .085.035l.425.173.188-.466 2.06-5.1-.927-.374Zm-2.049 2.401.926.378-.75 1.856-.925-.378.75-1.856ZM6.24 18.31a.5.5 0 1 0 0 1.001.5.5 0 0 0 0-1.001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkSlowSmall;
