import type { SVGProps } from "react";
const SvgArrowsUpDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17 16.033 19.988 13l.713.703-3.843 3.896-.355.361-.356-.36-3.853-3.894.71-.703L16 16.03V5h1v11.033ZM7.856 5.398l-.356-.36-.356.36-3.843 3.897.712.702L7 6.97V18h1V6.966l2.997 3.03.71-.704-3.851-3.894Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsUpDownSmall;
