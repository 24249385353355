import type { SVGProps } from "react";
const SvgArrowsLongUpDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m25.11 29.582 4.711-4.713-.707-.707-3.858 3.86V5.991h-1v22.031l-3.87-3.861-.707.708 4.724 4.713.353.353.354-.354ZM11.61 6.403l4.711 4.713-.707.707-3.858-3.859v22.029h-1V7.962l-3.87 3.862-.707-.708 4.724-4.713.353-.353.354.353Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLongUpDownMedium;
