import type { SVGProps } from "react";
const SvgTrainTracksHorizontalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 10.5v3.25h3.5V10.5h1v3.25h3.5V10.5h1v3.25h3.5V10.5h1v3.25h3.5V10.5h1v3.25h3.5V10.5h1v3.25h1.75v1h-1.75v8h1.75v1h-1.75V27h-1v-3.25h-3.5V27h-1v-3.25h-3.5V27h-1v-3.25h-3.5V27h-1v-3.25h-3.5V27h-1v-3.25h-3.5V27h-1v-3.25H4.5v-1h1.75v-8H4.5v-1h1.75V10.5h1Zm21.5 12.25v-8h-3.5v8h3.5Zm-8-8h3.5v8h-3.5v-8Zm-1 8v-8h-3.5v8h3.5Zm-8-8h3.5v8h-3.5v-8Zm-1 8v-8h-3.5v8h3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainTracksHorizontalMedium;
