import type { SVGProps } from "react";
const SvgUndergroundVehiculeProfileMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.015 4.765h20.628a2 2 0 0 1 1.9 1.42l4.08 13.5.004.01c.09.321.136.653.138.986v7.069a2 2 0 0 1-2 2H3.015v-1h24.75a1 1 0 0 0 1-1v-7.063a2.768 2.768 0 0 0-.1-.718L24.587 6.475a1 1 0 0 0-.949-.71H3.015v-1ZM33 32.75H3.03v-1H33v1Zm-17.25-25h-.5v11.5h10.928l-.182-.637-3-10.5-.104-.363H15.75Zm.5 10.5v-9.5h5.888l2.714 9.5H16.25Zm-13-10.5h10v19h-10v-19Zm5.5 18h3.5v-17h-3.5v17Zm-4.5-17v17h3.5v-17h-3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUndergroundVehiculeProfileMedium;
