import type { SVGProps } from "react";
const SvgTramProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.957 5.75H33v-1H3v1h9.543l5 5H3v1h19.222a2.5 2.5 0 0 1 2.196 1.244l4.032 7.493v.001a2.5 2.5 0 0 1 .3 1.25v6.012a1 1 0 0 1-1 1H3v1h1.786a3.5 3.5 0 0 0 .99 1.975l.025.025H3v1h30v-1H19.7l.025-.025a3.5 3.5 0 0 0 .99-1.975h7.035a2 2 0 0 0 2-2v-5.994a3.5 3.5 0 0 0-.42-1.743l-4.035-7.5-.003-.006-.004-.007a3.5 3.5 0 0 0-3.076-1.75h-3.255l-5-5Zm.843 26h-4.1l.025-.025a3.5 3.5 0 0 0 .99-1.975h2.07a3.5 3.5 0 0 0 .99 1.975l.025.025Zm0-2a2.5 2.5 0 0 0 4.9 0h-4.9Zm-9 0h4.9a2.5 2.5 0 0 1-4.9 0Zm.45-15H3v-1h4.25v8.5H3v-1h3.25v-6.5Zm3.5-1h-.5v8.5h8.5v-8.5h-8Zm.5 7.5v-6.5h6.5v6.5h-6.5Zm9.5-7.5h2.309l.138.276 4.5 9 .053.106v2.118h-7v-11.5Zm1 1v9.5h5v-.882l-4.309-8.618h-.691Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTramProfileMedium;
