import type { SVGProps } from "react";
const SvgArrowsLeftRightDownUpMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.605 3.773-.356-.36-.356.36-5.763 5.845.712.702 4.908-4.977v11.409L5.331 16.75l4.398-4.397-.707-.708-5.25 5.25-.354.354.354.354 5.25 5.25.707-.708-4.396-4.395H16.75v11.403l-4.92-4.972-.711.703 5.78 5.84.355.36.356-.36 5.763-5.845-.712-.702-4.91 4.98V17.752l11.415.001-4.395 4.394.708.708 5.25-5.25.353-.354-.353-.354-5.25-5.25-.707.708 4.398 4.398H17.75V5.341l4.923 4.976.711-.704-5.78-5.84Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLeftRightDownUpMedium;
