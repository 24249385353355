import type { SVGProps } from "react";
const SvgSaPr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.401 7.478h-1l.9-4.272h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.581 2.547-3.121 2.547ZM1 15h2.8l1.121-5.315h1.62c3.121 0 5.901-1.665 5.901-5.054C12.442 1.14 8.902 1 6.321 1h-2.36L1 15Zm16.661-8.023h-1.02l.78-3.771h1.16c1.14 0 2.401 0 2.401 1.465 0 1.845-1.82 2.306-3.32 2.306ZM18.341 1h-3.3l-2.92 14h2.8l1.22-5.816h1.06c1.29 0 1.428.75 1.636 1.886.037.202.076.417.124.641l.68 3.289h2.94l-1.339-5.636c-.18-.763-.66-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C23.902.999 20.99 1 18.432 1h-.09Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaPr;
