import type { SVGProps } from "react";
const SvgArrowStartRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.604 12.396 5.985 5.988.352.353-.352.353-5.985 6.012-.708-.705 5.136-5.16H10.5v-1h14.528l-5.132-5.134.708-.707ZM6 13.5V24h1V13.5H6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowStartRightMedium;
