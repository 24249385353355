import type { SVGProps } from "react";
const SvgSpeechBubbleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.5 6H4v12h3v2.957l.854-.853L9.957 18H20V6H4.5ZM5 17V7h14v10H9.543l-.147.146L8 18.543V17H5Zm2-7.021h10.021v-1H7v1Zm10 2.084H6.979v-1H17v1Zm-10.021 2h7.979v-1H6.979v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSpeechBubbleSmall;
