import type { SVGProps } from "react";
const SvgGeneralDisplayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 7.75V6h-1v1.75h-3v22h29.5v-22h-2.998V6h-1v1.75H7.248ZM25.75 15v-3.202a2.476 2.476 0 0 0-2 2.452 2.48 2.48 0 0 0 2.5 2.5c1.398 0 2.5-1.102 2.5-2.5a2.475 2.475 0 0 0-2-2.452V15h-1Zm-3-.75a3.48 3.48 0 0 1 3.5-3.5c1.95 0 3.5 1.55 3.5 3.5s-1.55 3.5-3.5 3.5a3.48 3.48 0 0 1-3.5-3.5Zm-18.5 14.5v-20h27.5v20H4.25Zm16.75-17H6v-1h15v1Zm-15 3h15v-1H6v1Zm15 3H6v-1h15v1ZM6 20.752h15v-1H6v1Zm15 3H6v-1h15v1Zm-15 3h15v-1H6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGeneralDisplayMedium;
