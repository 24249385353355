import type { SVGProps } from "react";
const SvgArrowCircleSwitzerlandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 18C3.25 9.854 9.854 3.25 18 3.25S32.75 9.854 32.75 18c0 6.02-3.615 11.445-8.805 13.75H28.5v1h-5.75V27h1v3.74c4.713-2.196 8-7.193 8-12.74 0-7.594-6.156-13.75-13.75-13.75S4.25 10.406 4.25 18 10.406 31.75 18 31.75v1C9.854 32.75 3.25 26.146 3.25 18Zm16.442-7.134-.387-.323-.32.39-.935 1.143-3.522.907-.59-.302-.268-.137-.247.173-2.505 1.76-.82.575.953.31.377.121-2.432 2.55-.084.09-.034.117-1.108 3.838-.239.826.836-.201 2.196-.528.066-.016.059-.033.657-.362 2.031 1.84.166.15.223-.024 3.24-.345.178-.019.126-.128 1.464-1.498 1.86 2.431.486.637.358-.717 1.435-2.882 3.372.833.704.174-.088-.72-.167-1.37 1.005.025.539.013-.028-.539-.162-3.173-.012-.24-.194-.14-1.378-.995-.456-.329-.274.49-.255.457-.929-.265.503-1.416.165-.466-.464-.17-3.733-1.371-1.367-1.14Zm-7.2 3.912-.18-.059 1.44-1.01.49.251.169.087.183-.048 3.864-.996.158-.04.104-.127.72-.879 1.045.873.067.056.081.03 3.35 1.23-.514 1.451-.178.502.511.146 1.823.52.38.11.193-.346.175-.313.728.526.122 2.396-1.044-.025-.579-.014.07.574.15 1.224-3.057-.755-.389-.096-.178.358-1.257 2.523-1.723-2.253-.35-.459-.404.413-1.742 1.782-2.838.302-2.13-1.93-.265-.239-.313.173-.91.502-1.295.311.836-2.894 2.895-3.037.549-.575-.756-.245Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleSwitzerlandMedium;
