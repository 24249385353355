import type { SVGProps } from "react";
const SvgBellDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m33.293 33 5 5H34.68l-23.062-5h21.675Zm-21.68 0h.005l-4.613-1c1.306-.002 2.542-.771 3.473-2.126C11.408 28.52 12 26.622 12 24.5v-8a9.78 9.78 0 0 1 .84-3.953L2.646 2.354l.708-.708 43 43-.707.708L39.293 39l-4.613-1-23.067-5Zm0 0H7c-.124 0-.37.113-.614.601-.23.46-.386 1.13-.386 1.899 0 .77.156 1.44.386 1.899.244.488.49.601.614.601h27.68l4.613 1H28.978a5.503 5.503 0 0 1-5.478 5 5.503 5.503 0 0 1-5.477-5H7c-.706 0-1.21-.557-1.508-1.154C5.179 37.22 5 36.391 5 35.5c0-.89.179-1.72.492-2.346C5.79 32.557 6.294 32 7 32l4.613 1ZM13 16.5c0-1.113.215-2.19.605-3.188l1.77 1.77C15.113 16.15 15 17.335 15 18.63V23h1v-4.37c0-.991.069-1.892.218-2.705L32.293 32H9.838a6.72 6.72 0 0 0 1.464-1.56C12.362 28.9 13 26.799 13 24.5v-8ZM23.5 43a4.503 4.503 0 0 1-4.473-4h8.945a4.503 4.503 0 0 1-4.472 4Zm-6.25-29.924c.924-1.552 2.365-2.59 4.371-3.09l-.242-.971c-2.234.558-3.913 1.742-4.989 3.55l.86.51ZM15 10.923C16.902 8.555 19.997 7 23.5 7 29.35 7 34 11.3 34 16.5v8c0 2.298.639 4.4 1.698 5.94A6.72 6.72 0 0 0 37.162 32H37v1h3c.124 0 .37.113.614.601.23.46.386 1.13.386 1.899 0 .575-.084 1.097-.231 1.513l.943.334c.192-.544.288-1.182.288-1.847 0-.89-.178-1.72-.491-2.346C41.21 32.557 40.706 32 40 32c-1.308 0-2.546-.77-3.478-2.126C35.592 28.52 35 26.622 35 24.5v-8c0-4.712-3.38-8.657-8-10.004V5.5C27 3.564 25.436 2 23.5 2A3.496 3.496 0 0 0 20 5.5v.996c-2.337.681-4.355 2.028-5.78 3.8l.78.627ZM26 5.5v.75A12.58 12.58 0 0 0 23.5 6c-.858 0-1.694.086-2.5.25V5.5C21 4.116 22.116 3 23.5 3S26 4.116 26 5.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBellDisabledLarge;
