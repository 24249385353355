import type { SVGProps } from "react";
const SvgCloudStrongRainMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m25.92 5.926.524-1.038-1.114.333c-.881.264-1.686.68-2.39 1.458-.611.675-1.123 1.599-1.56 2.874a4.88 4.88 0 0 0-.172-.067c-1.997-.724-4.471-.138-5.8 2.23-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.585-1.758 1.167-.504.705-.753 1.624-.713 2.53.04.91.371 1.84 1.073 2.548.708.713 1.75 1.155 3.124 1.155h10.5c1.412 0 2.493-.439 3.236-1.142a3.735 3.735 0 0 0 1.15-2.54 3.912 3.912 0 0 0-.023-.666c.998.068 2.22-.13 3.381-.595 1.583-.632 3.152-1.795 3.962-3.602l.52-1.16-1.17.495c-2.932 1.239-5.312.404-6.725-1.18-1.438-1.614-1.886-4.006-.912-5.934Zm.663 10.939c.83.134 2.058.006 3.29-.486 1.035-.414 2.022-1.064 2.742-1.97-2.744.683-5.06-.236-6.53-1.884-1.414-1.585-2.037-3.846-1.488-5.919-.33.19-.633.43-.916.744-.514.568-.986 1.403-1.406 2.68.61.415 1.13.975 1.489 1.66.481.92.662 2.04.416 3.294a3.014 3.014 0 0 1 1.906 1.068c.205.245.37.52.497.813Zm-10.525-4.213c1.01-2.29 3.124-2.837 4.81-2.225.848.307 1.58.908 2.01 1.728.426.814.572 1.878.193 3.153l-.199.67.698-.028c.78-.031 1.36.275 1.747.74.396.478.605 1.143.571 1.83a2.735 2.735 0 0 1-.84 1.862c-.529.5-1.35.868-2.548.868H12c-1.15 0-1.922-.364-2.415-.86-.498-.502-.753-1.183-.783-1.887-.031-.706.167-1.4.527-1.904.355-.496.856-.799 1.471-.799h.564l-.067-.56c-.296-2.472 2.359-3.736 3.987-2.403l.508.417.266-.602Zm-5.255 14.124 1.5-3 .894.447-1.5 3-.894-.447Zm10.5-3-1.5 3 .894.447 1.5-3-.894-.447Zm-9 6 3-6 .894.447-3 6-.894-.447Zm6.075-6-3 6 .894.447 3-6-.894-.447Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudStrongRainMoonMedium;
