import type { SVGProps } from "react";
const SvgIr90Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-90-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M38.737 12.897h-2.176a3.4 3.4 0 0 0 1.17 2.318c.676.575 1.531.862 2.567.862 1.555 0 2.717-.741 3.484-2.223.59-1.139.883-2.515.883-4.13 0-1.745-.333-3.1-1-4.065a3.952 3.952 0 0 0-1.404-1.257 3.828 3.828 0 0 0-1.821-.443 3.925 3.925 0 0 0-2.743 1.044c-.889.807-1.333 1.861-1.333 3.164-.006.62.12 1.235.367 1.803a3.51 3.51 0 0 0 1.02 1.353c.629.496 1.409.76 2.208.743.47.015.936-.093 1.352-.312a3.5 3.5 0 0 0 1.084-1.056 10.312 10.312 0 0 1-.229 1.51c-.163.697-.395 1.21-.697 1.54-.302.329-.733.51-1.179.494-.857 0-1.374-.449-1.553-1.345Zm.402-6.376a1.58 1.58 0 0 1 1.254-.609 1.599 1.599 0 0 1 1.332.657c.328.445.497.989.48 1.542a2.42 2.42 0 0 1-.464 1.487 1.648 1.648 0 0 1-2.744-.15c-.25-.409-.38-.88-.37-1.36a2.369 2.369 0 0 1 .512-1.567Zm12.724-1.756a3.586 3.586 0 0 0-2.318-.807 3.705 3.705 0 0 0-3.28 1.883c-.62 1.055-.93 2.452-.93 4.192 0 2.447.594 4.177 1.782 5.19a3.782 3.782 0 0 0 4.285.347 3.796 3.796 0 0 0 1.36-1.44c.588-1.07.883-2.436.883-4.097a10.873 10.873 0 0 0-.34-2.784c-.299-1.118-.78-1.946-1.442-2.484Zm-3.95 2.46c.274-.875.789-1.313 1.546-1.313.777 0 1.308.438 1.592 1.313.22.68.331 1.611.331 2.792 0 1.387-.155 2.418-.465 3.093-.31.675-.783 1.013-1.42 1.013-.766 0-1.292-.435-1.576-1.305a8.75 8.75 0 0 1-.33-2.666c-.001-1.255.107-2.23.322-2.927Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr90Negative;
