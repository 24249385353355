import type { SVGProps } from "react";
const SvgIdCardEmployeeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 5.75a1.75 1.75 0 0 0-1.75 1.75v3.25h3.5V7.5A1.75 1.75 0 0 0 18 5.75ZM15.25 7.5c0-1.52 1.23-2.75 2.75-2.75s2.75 1.23 2.75 2.75v1.75h8.5c1.932 0 3.5 1.568 3.5 3.5v13.5c0 1.932-1.568 3.5-3.5 3.5H6.75a3.501 3.501 0 0 1-3.5-3.5v-13.5c0-1.932 1.568-3.5 3.5-3.5h8.5V7.5Zm5.5 4.25v-1.5h8.5a2.5 2.5 0 0 1 2.5 2.5v13.5a2.5 2.5 0 0 1-2.5 2.5H19.165c-.308-2.907-1.673-5.412-3.64-6.659a4.9 4.9 0 0 0 .725-2.591c0-2.238-1.47-4.25-3.5-4.25-2.03 0-3.5 2.012-3.5 4.25 0 .95.265 1.86.725 2.591-1.96 1.242-3.321 3.732-3.637 6.625A2.502 2.502 0 0 1 4.25 26.25v-13.5a2.5 2.5 0 0 1 2.5-2.5h8.5v1.5h5.5Zm-13.41 17h10.82c-.313-2.7-1.602-4.873-3.273-5.877-.579.544-1.312.877-2.137.877s-1.558-.332-2.137-.878c-1.671 1.005-2.96 3.178-3.272 5.878Zm12.16-9.5h6v-1h-6v1Zm9 3h-9v-1h9v1ZM10.25 19.5c0-1.905 1.218-3.25 2.5-3.25s2.5 1.345 2.5 3.25-1.218 3.25-2.5 3.25-2.5-1.345-2.5-3.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIdCardEmployeeMedium;
