import type { SVGProps } from "react";
const SvgContactLineArrowTrainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#contact-line-arrow-train-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M3 4.25h30v-1H3v1Zm16.777 5.084-4.5-3-.554.832 3.876 2.584-3.75 2.5H13.5v1H21c2.46 0 4.57 1.432 5.585 3.507h-6.828v6.986h9.4l.883 2.436.01.026.012.025c.12.24.188.5.188.77a1.75 1.75 0 0 1-1.75 1.75H3v1h1.785a3.49 3.49 0 0 0 1.016 2H3v1h30v-1h-8.8a3.49 3.49 0 0 0 1.015-2H28.5A2.75 2.75 0 0 0 31.25 27c0-.434-.108-.834-.279-1.187l-3.146-8.674c-.981-2.834-3.648-4.889-6.825-4.889h-4.349l3.126-2.084.624-.416-.624-.416Zm.966 8.409h6.238l1.818 5.014h-8.056v-5.014ZM10.7 31.75h8.6a3.49 3.49 0 0 1-1.015-2h-6.57a3.49 3.49 0 0 1-1.015 2Zm-4.9-2a2.5 2.5 0 0 0 4.9 0H5.8Zm15.95 2a2.5 2.5 0 0 1-2.45-2h4.9a2.5 2.5 0 0 1-2.45 2Zm-13-23.793 2.896 2.897.708-.708-3.75-3.75-.354-.353-.354.353-3.75 3.75.708.708L7.75 7.957V16.5h1V7.957Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="contact-line-arrow-train-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgContactLineArrowTrainMedium;
