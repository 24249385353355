import type { SVGProps } from "react";
const SvgCarPowerPlugLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.5 7a4.5 4.5 0 0 0-4.5 4.5V18h19.038l.002-.023a4 4 0 0 1 8 0l.002.023H39v-6.5A4.5 4.5 0 0 0 34.5 7h-20Zm21.54 11a3 3 0 1 0-6 0h6ZM9 11.5V13h-.75A1.25 1.25 0 0 0 7 14.25v1.5A1.25 1.25 0 0 0 8.25 17H9v1.43c0 .03.002.059.007.087A2.502 2.502 0 0 0 8.03 20.5v10a1.5 1.5 0 0 0 1.5 1.5H10v1.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V32h17v1.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V32h.5a1.5 1.5 0 0 0 1.5-1.5v-10a2.5 2.5 0 0 0-1.004-2.003.556.556 0 0 0 .004-.067V17h.75A1.25 1.25 0 0 0 42 15.75v-1.5A1.25 1.25 0 0 0 40.75 13H40v-1.5A5.5 5.5 0 0 0 34.5 6h-20A5.5 5.5 0 0 0 9 11.5ZM8.25 14H9v2h-.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25Zm1.211 5.447A1.5 1.5 0 0 1 10.504 19H38.5a1.5 1.5 0 0 1 1.5 1.5v10a.5.5 0 0 1-.5.5H9.53a.5.5 0 0 1-.5-.5v-10a1.5 1.5 0 0 1 .431-1.053ZM40.75 16H40v-2h.75a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25ZM34 33.5V32h4v1.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5ZM15 32h-4v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V32Zm-3-11a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Zm-2 1a2 2 0 0 1 2-2h5a2 2 0 1 1 0 4h-5a2 2 0 0 1-2-2Zm21 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Zm1-2a2 2 0 1 0 0 4h5a2 2 0 1 0 0-4h-5Zm-13 6h11v4H19v-4Zm10 1h-9v2h9v-2Zm-18 2h3v-1h-3v1Zm28-2h-5v-1h5v1Zm-29 0h5v-1h-5v1Zm28 2h-3v-1h3v1Zm-10.5 6h-3.503a4.02 4.02 0 0 0-3.453 2H18v2h-4v1h4v2h2.536A3.995 3.995 0 0 0 24 44h4v-2h3v-1h-3v-3h3v-1h-3v-2h-.5ZM19 38h1.131a4.01 4.01 0 0 0-.131.997V40a4 4 0 0 0 .127 1H19v-3Zm2.884-1.115A3.02 3.02 0 0 1 24.002 36H27v7h-3a3 3 0 0 1-3-3v-.998a3.02 3.02 0 0 1 .884-2.117Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarPowerPlugLarge;
