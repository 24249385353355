import type { SVGProps } from "react";
const SvgIr27Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-27-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M44.665 13.743V15.8h-8.301c-.003-.612.1-1.22.303-1.796a4.375 4.375 0 0 1 .856-1.47 7.87 7.87 0 0 1 .997-.926c.381-.301 1.14-.852 2.274-1.654a4.43 4.43 0 0 0 1.16-1.075c.223-.343.338-.746.33-1.155.02-.453-.14-.896-.445-1.23a1.538 1.538 0 0 0-1.17-.471c-1.188 0-1.8.857-1.837 2.57h-2.216v-.252c0-1.403.392-2.49 1.175-3.26.762-.748 1.747-1.122 2.956-1.122a4.008 4.008 0 0 1 2.688.917c.783.665 1.175 1.558 1.175 2.682a3.69 3.69 0 0 1-.938 2.53c-.175.204-.367.39-.576.558-.178.14-.649.476-1.41 1.009-.726.506-1.245.894-1.558 1.163a6.219 6.219 0 0 0-.87.925h5.407Zm8.695-9.508v1.962c-.062.069-.249.282-.56.64a11.58 11.58 0 0 0-1.284 1.872 12.88 12.88 0 0 0-.993 2.163 18.184 18.184 0 0 0-.907 4.928h-2.38c.032-.98.184-1.952.452-2.895a18.94 18.94 0 0 1 1.187-3.18c.72-1.498 1.45-2.613 2.191-3.346h-5.439V4.235h7.734ZM9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr27Negative;
