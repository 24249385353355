import type { SVGProps } from "react";
const SvgTicketMachineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 3.25h-.5v23.5h1.5v6h14.5v-6h1.5V3.25h-17Zm14.5 23.5h-12.5v5h12.5v-5Zm1-1h.5V4.25h-15.5v21.5h15ZM24 7.25H12v-1h12v1Zm-1.25 3.25V15h1v-4.5h-1Zm-10.5.25h8.5v7h-8.5v-7Zm1 1v5h6.5v-5h-6.5Zm-.5 8h-.5v2.5h8.5v-2.5h-8Zm.5 1.5v-.5h6.5v.5h-6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketMachineMedium;
