import type { SVGProps } from "react";
const SvgChevronUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12 7.293.354.353 8 8-.707.708L12 8.707l-7.646 7.647-.708-.707 8-8L12 7.292Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronUpSmall;
