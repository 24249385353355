import type { SVGProps } from "react";
const SvgMetadataSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 3H2v18h20V3H2.5ZM3 20V4h18v16H3ZM5.646 8.646l2-2 .708.708L6.707 9l1.647 1.646-.708.708-2-2L5.293 9l.353-.354Zm10.708 4 2 2 .353.354-.353.354-2 2-.708-.708L17.293 15l-1.647-1.646.708-.708ZM18 8h-8V7h8v1ZM6 16v1h8v-1H6Zm9-5h-5v-1h5v1Zm1 0h2v-1h-2v1Zm-8 3H6v-1h2v1Zm1 0h5v-1H9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMetadataSmall;
