import type { SVGProps } from "react";
const SvgTagMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.25 9.25h-.501v.501l.01 6.45v.207l.146.146 14.241 14.248.354.354.353-.354 6.45-6.448.354-.354-.354-.354-14.268-14.25-.146-.146H8.25Zm.508 6.743L8.75 10.25h5.725L28.242 24 22.5 29.742 8.758 15.993Zm2.992-2.494a.25.25 0 1 1 .5.001.25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.501 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTagMedium;
