import type { SVGProps } from "react";
const SvgGiftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.002 2.851a2.165 2.165 0 0 0-.797-.656 2.219 2.219 0 0 0-1.783-.034 2.26 2.26 0 0 0-1.264 1.246c-.239.603-.198 1.316.205 2.037l.016.028.019.025L9.508 7H3v4h1v11h16V11h1V7h-6.509l1.111-1.503.017-.022.014-.024c.412-.712.457-1.42.218-2.023-.234-.588-.718-1.02-1.266-1.243a2.247 2.247 0 0 0-1.786.021 2.14 2.14 0 0 0-.797.645ZM11.5 4.07c-.161-.554-.446-.839-.725-.971a1.22 1.22 0 0 0-.98-.01 1.26 1.26 0 0 0-.707.687c-.114.288-.125.677.134 1.155L10.752 7h.745l.003-2.93Zm1 0L12.497 7h.75l1.534-2.073c.262-.466.253-.846.14-1.13a1.272 1.272 0 0 0-.712-.685 1.243 1.243 0 0 0-.989.001c-.277.13-.561.408-.72.955ZM15 11v10h4V11h-4Zm-1 10h-4V11h4v10Zm1-11V8h5v2h-5Zm-1 0V8h-4v2h4Zm-5 0V8H4v2h5Zm0 11V11H5v10h4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGiftSmall;
