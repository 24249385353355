import type { SVGProps } from "react";
const SvgBrowserLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 6H2v36h44V6H2.5ZM3 41V17h42v24H3Zm42-25H3V7h42v9ZM6 11.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM7.5 9a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm5.5 2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM14.5 9a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm7 1a1.5 1.5 0 0 0 0 3h19a1.5 1.5 0 0 0 0-3h-19Zm0 4a2.5 2.5 0 0 1 0-5h19a2.5 2.5 0 0 1 0 5h-19Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBrowserLarge;
