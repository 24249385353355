import type { SVGProps } from "react";
const SvgCoinDollarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10.435 4.084c.548-.003.996-.175 1.346-.512.349-.338.523-.77.523-1.3 0-.542-.15-.97-.453-1.284-.302-.314-.773-.53-1.416-.649v3.745Zm-.778-8.466c-.458.013-.817.11-1.076.29-.463.32-.695.782-.695 1.388 0 .472.145.845.433 1.118.288.274.733.463 1.338.566V7.617Zm-3.151 6.2h1.203c0 1.437.65 2.191 1.948 2.266V12.17c-.722-.122-1.331-.341-1.827-.658-.755-.476-1.133-1.182-1.133-2.116 0-.798.268-1.448.803-1.951.537-.503 1.255-.776 2.157-.819V5.36h.778v1.266c.897.072 1.577.337 2.039.793.5.492.746 1.138.736 1.94h-1.19c-.074-1.117-.603-1.697-1.585-1.734v3.548c.596.15 1.007.266 1.236.346.229.08.443.184.64.311.789.51 1.183 1.287 1.183 2.33 0 1.075-.44 1.896-1.317 2.457-.42.27-1.001.425-1.742.468v1.558h-.778v-1.558c-1.062-.067-1.851-.383-2.365-.95-.53-.585-.791-1.357-.786-2.316ZM8.8 4.67a8.012 8.012 0 0 0-4.499 5.197l.964.267A7.012 7.012 0 0 1 9.2 5.587l-.4-.917Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCoinDollarSmall;
