import type { SVGProps } from "react";
const SvgHomePowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.354 3.646 9 3.292l-.354.354-7 7 .708.707.646-.646V19h4.99v-4h2v4H15v-3h1c.422 0 .64.104.76.224.123.123.23.35.24.782v1.022c0 .248 0 .704.162 1.103.088.22.233.446.47.615.24.17.533.254.868.254.333 0 .626-.08.866-.248.239-.166.385-.39.475-.61.163-.403.16-.865.16-1.123L20 18v-3.05c1.14-.231 2-1.24 2-2.45V11h-1V9h-1v2h-1V9h-1v2h-1v1.5c0 1.21.86 2.219 2 2.45v3.053c.002.29-.004.563-.086.763a.351.351 0 0 1-.12.166c-.041.03-.125.069-.294.069-.165 0-.247-.04-.288-.07a.371.371 0 0 1-.123-.172c-.082-.205-.088-.48-.089-.759v-1.01c-.013-.573-.158-1.095-.53-1.47-.374-.377-.894-.52-1.47-.52h-1v-4.292l.646.646.707-.707-7-7ZM14 9.706l-5-5-5 5V18h2.99v-4h4v4H14V9.707Zm4 2.794V12h3v.5a1.5 1.5 0 0 1-3 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHomePowerPlugSmall;
