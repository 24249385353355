import { gql } from "graphql-request";

import { CurrentUserGroupsFragment } from "./graphql";

export const currentUserGroupsFragment = gql`
  fragment currentUserGroups on User {
    groups {
      name
    }
  }
`;

export function isEditor(currentUser?: CurrentUserGroupsFragment) {
  return currentUser?.groups.some((g) => g.name === "Editor") === true;
}

export function isAdmin(currentUser?: CurrentUserGroupsFragment) {
  return currentUser?.groups.some((g) => g.name === "Administrator") === true;
}
