import type { SVGProps } from "react";
const SvgSaRp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.707 3.5c.684 0 1.239-.56 1.239-1.25S12.39 1 11.707 1s-1.239.56-1.239 1.25.555 1.25 1.24 1.25Zm3.627 7.5.862 2.38a.94.94 0 0 0 .872.62c.645 0 1.1-.65.872-1.26l-1.288-3.58a.984.984 0 0 0-.931-.66h-2.527V5.75c0-.83-.664-1.5-1.487-1.5-.822 0-1.486.67-1.486 1.5V9.5c0 .83.664 1.5 1.486 1.5h3.627Zm-.743.68.614 1.7v.01c-.862.98-2.1 1.61-3.498 1.61C9.111 15 7 12.87 7 10.25c0-1.7.892-3.18 2.23-4.02V8.2a3.241 3.241 0 0 0-.744 2.05c0 1.79 1.447 3.25 3.221 3.25 1.269 0 2.359-.75 2.884-1.82ZM3.305 14.35a24.21 24.21 0 0 1-.87-1.415c-.232-.411-.528-1.025-.89-1.841C1.181 10.277 1 9.264 1 8.054c0-1.122.199-2.158.591-3.1.392-.943.963-2.002 1.713-3.175h1.272a32.879 32.879 0 0 0-.944 1.76 25.08 25.08 0 0 0-.781 1.84c-.3.78-.453 1.679-.453 2.695 0 1.038.166 1.981.5 2.82.331.842.59 1.45.77 1.825.181.374.484.919.908 1.632H3.305v-.002Zm20.062-9.524c-.422-.956-.98-1.972-1.668-3.048v.004H20.43c.413.702.71 1.243.9 1.623.184.38.388.841.605 1.38.216.537.38 1.08.49 1.623a8.25 8.25 0 0 1 .164 1.632c0 1.016-.156 1.929-.463 2.738-.309.811-.553 1.397-.735 1.76-.181.361-.503.969-.96 1.814h1.268c.459-.713.787-1.246.98-1.597.193-.352.463-.934.808-1.752.344-.816.518-1.799.518-2.948 0-1.197-.214-2.274-.638-3.23Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRp;
