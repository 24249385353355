import type { SVGProps } from "react";
const SvgMinimizeScreenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 9V4h1v6H4V9h5Zm-5 6h5v5h1v-6H4v1Zm10.5-1H14v6h1v-5h5v-1h-5.5ZM15 4v5h5v1h-6V4h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMinimizeScreenSmall;
