import type { SVGProps } from "react";
const SvgFilterSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.413 5h17.174l-.707.825-5.88 6.86V20h-4v-7.315l-5.88-6.86L3.413 5Zm2.174 1 5.293 6.174.12.14V19h2v-6.685l.12-.14L18.413 6H5.587Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFilterSmall;
