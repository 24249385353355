import type { SVGProps } from "react";
const SvgFreightWagonContainerWindSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 19.5v.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-.5m-1-1h-2m0-1.5v3m9.5-4.5h-6.5v-9H21m0 13h-7.5v-2H21m-8-9H9m-1 0H6m-1 3H3m4 3H5m7-3H6m5 3H8"
      stroke="currentColor"
    />
  </svg>
);
export default SvgFreightWagonContainerWindSmall;
