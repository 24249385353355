import type { SVGProps } from "react";
const SvgCombinedMobilitySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 2h4.434l-.518.777-1.846 2.769.822 1.81-.91.413L13.178 6H8.325l-.257.578A4.001 4.001 0 0 1 10 10c0 2.207-1.794 4-4 4s-4-1.793-4-4a4.004 4.004 0 0 1 5.155-3.83l.388-.874L7.675 5h5.557l1.334-2H12V2ZM5.543 9.796l1.202-2.703A3.004 3.004 0 0 0 3 10c0 1.655 1.346 3 3 3s3-1.345 3-3a3 3 0 0 0-1.342-2.5l-1.201 2.703-.914-.407Zm-1.897 9.85 15-15 .708.707-15 15-.708-.707ZM17 9h1c.921 0 1.7.214 2.243.757.543.543.757 1.322.757 2.242v1.002h1v1h-1V22h-3.999v-2H11v2H7v-3h13v-4h-8v-1h2.05a2.502 2.502 0 0 1 4.9 0H20v-2c0-.785-.183-1.256-.464-1.536-.28-.281-.751-.464-1.536-.464h-1V9Zm1.001 11H20v1h-1.999v-1ZM8 21v-1h2v1H8Zm8.5-8c.653 0 1.209.418 1.415 1h-2.83a1.502 1.502 0 0 1 1.415-1Zm.5 4a1 1 0 1 1 2.002.001A1 1 0 0 1 17 17Zm-7-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM6.208 4h2.793V3H6.209v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCombinedMobilitySmall;
