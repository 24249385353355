import type { SVGProps } from "react";
const SvgMagnifyingGlassPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.25 15.75c0-5.522 4.476-10 10-10s10 4.478 10 10c0 5.524-4.476 10-10 10s-10-4.476-10-10Zm10-11c-6.076 0-11 4.926-11 11 0 6.076 4.924 11 11 11 2.913 0 5.56-1.131 7.528-2.979l9.395 8.107.654-.757-9.35-8.068a10.959 10.959 0 0 0 2.773-7.303c0-6.074-4.924-11-11-11ZM7.5 16.25h6.25v6.25h1v-6.25H21v-1h-6.25V9h-1v6.25H7.5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassPlusMedium;
