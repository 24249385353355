import type { SVGProps } from "react";
const SvgSaYb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.14 6.641h-1.1l.74-3.44h.98c1.06 0 2.342.06 2.342 1.4 0 1.641-1.541 2.04-2.961 2.04Zm-1.42 6.122h-.96l.84-4.041h.9c1.5 0 2.86.22 2.86 1.681 0 2.16-1.92 2.36-3.64 2.36ZM19.68 1h-4.14l-2.921 13.963H16.6c2.08 0 6.56-.32 6.56-4.321 0-1.5-.68-2.7-2.24-2.94v-.04c1.62-.26 2.98-1.621 2.98-3.321C23.9 1.8 21.86 1 19.68 1ZM1 1l3.28 8.182-1.22 5.781h2.801l1.22-5.78L13.843 1h-3.261L6.041 6.862 3.961 1H1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaYb;
