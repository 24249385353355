import type { SVGProps } from "react";
const SvgCloudParticlesMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#cloud-particles-medium_svg__a)">
      <path
        d="M9 23.25h1.5m-3-3H9m3 1.5h1.5m-3-3H12m3 4.5h1.5m0-3H18m-4.5-1.5H15m-3-3h1.5m4.5 0h1.5m1.5 3h1.5m0-4.5H24m1.5 7.5H27m-6 1.5h1.5M8.994 16.62c-4.817 0-5.774 9.63 1.8 9.63h15.752c7.473 0 7.2-9.63 1.575-9.406 2.475-8.287-8.55-12.095-11.926-4.48-2.972-2.423-7.723-.097-7.2 4.255Z"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="cloud-particles-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCloudParticlesMedium;
