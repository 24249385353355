import type { SVGProps } from "react";
const SvgRailwayShipLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m28.473 8.162-11 32-.945-.325 11-32 .945.325Zm-.172 4.903A46.768 46.768 0 0 1 44 26.663v1.508l-5.395-1.16-.512-.11-.086.517L37.41 31h-7.08a4.481 4.481 0 0 0-2.83-1 4.482 4.482 0 0 0-2.828 1H22v1h1.759A4.48 4.48 0 0 0 23 34.5c0 .526.09 1.032.256 1.5H20v1h3.76a4.496 4.496 0 0 0 3.742 2c1.56 0 2.934-.794 3.741-2h11.325l-.683-.82L40.067 34H42.5c1.622 0 2.536-.548 3.025-1.2a2.426 2.426 0 0 0 .466-1.16l.008-.093v-.046l-.5-.001h.5v-2.9l-.394-.085-.605-.13v-2.049l-.096-.132a47.768 47.768 0 0 0-16.133-14.023l-.47.883ZM38.766 34H36.91l.097-.582.236-1.418h-6c.479.715.758 1.575.758 2.5 0 .526-.09 1.031-.256 1.5h8.687l-1.666-2ZM45 29.409v2.1l-.001.006-.002.018a1.428 1.428 0 0 1-.272.667c-.261.348-.847.8-2.225.8h-4.41l.817-4.901L45 29.409ZM28.738 15.06l-.524-.285-.188.566-2 6-.22.658H37.7l-.845-.852a34.822 34.822 0 0 0-8.116-6.088ZM27.194 21l1.59-4.77A33.83 33.83 0 0 1 35.269 21h-8.075Zm.307 10a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM14.5 35c.74 0 1.415.035 2.039.096l-.098.996A19.684 19.684 0 0 0 14.506 36c-2.442.06-4.861.494-7.17 1.29l-.326-.946A24.82 24.82 0 0 1 14.488 35h.012Zm4.64-18a2.5 2.5 0 0 0-2.453 2.01l-.199 1a2.499 2.499 0 0 0 2.45 2.99H21v-1h-2.061a1.498 1.498 0 0 1-1.47-1.794l.199-1A1.5 1.5 0 0 1 19.14 18H23v-1h-3.86Zm-6.64-6H12v7h2.39l-.8 4H1.768l.267.682 4.5 11.5.18.46.46-.174C11.086 33.001 13.25 33 17.5 33v-1h-.012c-4.101 0-6.4 0-10.2 1.362L3.232 23H14.41l.08-.402 1-5 .12-.598H13v-5h12v-1H12.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRailwayShipLarge;
