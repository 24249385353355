import type { SVGProps } from "react";
const SvgSmartphoneDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m35 35.707 9.647 9.647.707-.707-42-42-.708.707L13 13.707V41.5c0 2.029 1.498 3.5 3.5 3.5h15c2.002 0 3.5-1.471 3.5-3.5v-5.793Zm-1-1-20-20V38h20v-3.293ZM16.5 5C15.045 5 14 6.029 14 7.5V11h-1V7.5C13 5.472 14.498 4 16.5 4h15C33.502 4 35 5.472 35 7.5V32h-1V7.5C34 6.029 32.955 5 31.5 5h-15ZM14 41.5V39h20v2.5c0 1.471-1.045 2.5-2.5 2.5h-15c-1.455 0-2.5-1.029-2.5-2.5Zm8 .5h4v-1h-4v1Zm0-34h6V7h-6v1Zm-1 0h-1V7h1v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneDisabledLarge;
