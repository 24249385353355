import type { SVGProps } from "react";
const SvgSmartphoneLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.643 5C15.196 5 14 6.202 14 7.682V38h20V7.682C34 6.202 32.804 5 31.357 5H16.643ZM34 39H14v2.319c0 1.479 1.196 2.68 2.643 2.68h14.714C32.804 44 34 42.798 34 41.32V39ZM13 7.682C13 5.662 14.632 4 16.643 4h14.714C33.368 4 35 5.66 35 7.682v33.636C35 43.34 33.368 45 31.357 45H16.643C14.633 45 13 43.34 13 41.32V7.681ZM26 42h-4v-1h4v1Zm2-34h-6V7h6v1Zm-8 0h1V7h-1v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneLarge;
