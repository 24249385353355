import type { SVGProps } from "react";
const SvgWifiDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.123 5.83 3.146 3.854l.707-.708 15 15-.707.708-4.459-4.46A3.898 3.898 0 0 0 12.002 14c-1.14 0-2.164.482-2.894 1.252l-.725-.688a4.971 4.971 0 0 1 3.92-1.554l-1.829-1.83a6.99 6.99 0 0 0-3.539 2.009L6.21 12.5a7.99 7.99 0 0 1 3.447-2.137L8.1 8.808a9.998 9.998 0 0 0-3.345 2.313l-.725-.689c.94-.99 2.065-1.797 3.313-2.381L5.86 6.566a13.028 13.028 0 0 0-3.276 2.49l-.726-.688A13.981 13.981 0 0 1 5.123 5.83ZM12 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM7.172 4.877A13.884 13.884 0 0 1 12 4c3.997 0 7.593 1.684 10.142 4.367l-.724.69C19.048 6.561 15.709 5 12 5c-1.576 0-3.08.297-4.482.815l-.346-.938ZM12 7c-.813 0-1.602.093-2.36.259l.214.977a9.956 9.956 0 0 1 9.39 2.885l.726-.689A10.956 10.956 0 0 0 12 7Zm.496 4.03a6.971 6.971 0 0 1 4.576 2.154l.724-.69a7.971 7.971 0 0 0-5.23-2.461l-.07.997Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWifiDisabledSmall;
