import type { SVGProps } from "react";
const SvgUserHatMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18 4.723.158.053 6.75 2.25 1.426.475-1.426.473-2.658.883v2.989l-.05.045c.508.903.8 1.973.8 3.108 0 1.484-.506 2.88-1.353 3.924 4.079 1.928 6.604 6.658 6.604 11.826v.5h-.5l-19.5-.007h-.5v-.5c0-5.62 2.65-10.02 6.597-11.812a6.141 6.141 0 0 1-1.227-2.68H8.525l.413-.743 1.812-3.262V7.861L9.667 7.5l1.425-.475 6.75-2.25.158-.053Zm-2.898 14.971c-3.585 1.447-6.188 5.316-6.344 10.548l18.484.007c-.161-4.82-2.644-9-6.346-10.567-.81.667-1.808 1.067-2.909 1.067-1.094 0-2.082-.395-2.885-1.055Zm-.518-7.162c1.904 1.322 4.554 1.6 6.828.003.372.72.589 1.565.589 2.464 0 2.665-1.855 4.75-4.014 4.75-2.154 0-3.986-2.081-3.986-4.75 0-.9.213-1.747.583-2.468Zm-.834-.688.045.042a6.343 6.343 0 0 0-.777 2.64l-1.268-2.28V8.192l2 .664v2.988Zm1-2.654v2.21c1.72 1.461 4.352 1.81 6.5-.002V9.19l-3.092 1.027-.158.052-.157-.052L14.75 9.19ZM12.834 7.5 18 9.215l5.167-1.717L18 5.777l-5.166 1.722Zm-1.584 5.905 1.025 1.845h-2.05l1.025-1.845Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserHatMedium;
