import type { SVGProps } from "react";
const SvgRoofBedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18.554 3.393-.224-.112-.224.112-15 7.5.448.894L18.33 4.4l14.776 7.388.448-.894-15-7.5ZM3.58 32.34v-16.5h1v9.25h9.5v-6h15.5a3.5 3.5 0 0 1 3.5 3.5v9.75h-1v-6.25H4.58v6.25h-1Zm28.5-7.25h-17v-5h14.5a2.5 2.5 0 0 1 2.5 2.5v2.5Zm-24.5-4.75a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-2.75a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRoofBedMedium;
