import type { SVGProps } from "react";
const SvgLowVisionMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m23.348 24.415 4.963 4.964.708-.708-22.5-22.5-.708.708 4.424 4.423a25.563 25.563 0 0 0-5.227 5.433l-.2.282.192.287c.576.857 2.201 2.958 4.498 4.85 2.29 1.888 5.31 3.621 8.667 3.621h.015a11.84 11.84 0 0 0 5.168-1.36Zm-.743-.743L10.948 12.015a24.564 24.564 0 0 0-2.449 2.138l10.545 10.559a10.841 10.841 0 0 0 3.56-1.04Zm-4.44-15.397c3.356 0 6.377 1.733 8.667 3.62 2.296 1.893 3.921 3.995 4.498 4.85l.193.289-.201.282a26.21 26.21 0 0 1-4.343 4.725l-.648-.762a25.213 25.213 0 0 0 3.97-4.26c-.666-.926-2.126-2.72-4.105-4.352-2.213-1.824-5.01-3.39-8.026-3.392a10 10 0 0 0-3.51.714l-.371-.928a11 11 0 0 1 3.866-.786h.01ZM6.028 17.03a24.547 24.547 0 0 1 1.777-2.157l9.875 9.889c-2.833-.156-5.451-1.652-7.546-3.38-1.98-1.63-3.44-3.426-4.106-4.352Zm13.665-5.56a5.69 5.69 0 0 0-2.948.002l.26.966a4.69 4.69 0 0 1 5.747 5.747l.966.26a5.691 5.691 0 0 0-4.025-6.975Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLowVisionMedium;
