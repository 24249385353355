import type { SVGProps } from "react";
const SvgMicrophoneDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.25 8.25c0-2.199 1.801-4 4-4s4 1.801 4 4V18h1V8.25c0-2.751-2.249-5-5-5s-5 2.249-5 5V9h1v-.75Zm10.883 16.59 7.014 7.014.707-.708-27-27-.708.708 8.104 8.103v7.293c0 2.751 2.249 5 5 5a5.014 5.014 0 0 0 4.49-2.804l1.667 1.668c-1.16 1.95-3.442 3.136-6.157 3.136-3.938 0-7-2.451-7-6.25h-1c0 4.312 3.377 7.034 7.5 7.238v3.512H13.5v1H21v-1h-3.25v-3.512c2.697-.134 5.06-1.35 6.383-3.398ZM13.25 20.25v-6.293l7.731 7.731a4.017 4.017 0 0 1-3.73 2.562c-2.2 0-4-1.801-4-4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMicrophoneDisabledMedium;
