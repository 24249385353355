import type { SVGProps } from "react";
const SvgShoppingBagFastMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m22.406 9.317-.303 1.722 4.924.868.304-1.723a2.5 2.5 0 1 0-4.925-.867Zm5.606 2.764.304-1.723a3.5 3.5 0 1 0-6.895-1.215l-.303 1.723-3.939-.695-.45-.079-.119.44-4.342 15.99-.144.527.54.096L30.39 30.27l.54.095.045-.546 1.388-16.51.038-.456-.45-.079-3.939-.694Zm-1.159.811-.564 3.2.984.175.565-3.201 3.49.615-1.303 15.51-16.649-2.936 4.08-15.02 3.488.615-.564 3.202.985.174.564-3.202 4.924.868ZM3 15.25h9v1H3v-1Zm3 3h6v1H6v-1Zm4.5 3h-3v1h3v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagFastMedium;
