import type { SVGProps } from "react";
const SvgIr62 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-62_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-62_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.38-5.245h-2.2c-.205-.891-.711-1.337-1.518-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.266 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.628.728.941 1.64.941 2.737 0 1.303-.421 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.76-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.942 1.29.39.495.641 1.12.752 1.874Zm-3.916 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.775.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Zm13.131 3.971v2.056h-8.33c0-.627.1-1.226.301-1.795.206-.57.493-1.06.862-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.554-.385.942-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.45-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57H45.61V8.34c0-1.402.393-2.489 1.178-3.259.765-.749 1.754-1.123 2.967-1.123 1.075 0 1.975.306 2.697.918.786.664 1.179 1.558 1.179 2.681 0 .981-.314 1.825-.942 2.531a4.65 4.65 0 0 1-.577.562c-.18.137-.651.472-1.416 1.005-.728.506-1.25.893-1.566 1.162a6.348 6.348 0 0 0-.87.926h5.426Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-62_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr62;
