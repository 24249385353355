import type { SVGProps } from "react";
const SvgFaceThinkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 4c-4.414 0-8 3.586-8 8a7.992 7.992 0 0 0 4.912 7.389l-.384.922A8.992 8.992 0 0 1 3 12c0-4.966 4.034-9 9-9s9 4.034 9 9c0 .35-.021.687-.052 1.008l-.996-.096A9.11 9.11 0 0 0 20 12c0-4.414-3.586-8-8-8Zm8.532 11.692a.65.65 0 0 0-.835-.303l-6 2.57-.213.092-.207-.104-3.53-1.76a.559.559 0 0 0-.502 0 .564.564 0 0 0-.12.923c.557.474 1.204 1.099 1.744 1.823.454.609.85 1.309 1.041 2.067h3.59c.824 0 1.5-.676 1.5-1.5v-1.31l.276-.138 2.97-1.49a.654.654 0 0 0 .286-.871Zm-1.229-1.222a1.65 1.65 0 0 1 2.124.776l.002.003a1.654 1.654 0 0 1-.735 2.208L18 18.808v.692c0 1.376-1.124 2.5-2.5 2.5h-4.434l-.061-.43c-.099-.696-.453-1.39-.937-2.038-.481-.645-1.07-1.216-1.592-1.661l-.002-.002c-.846-.727-.66-2.077.32-2.575l.002-.001c.441-.22.957-.22 1.397 0l3.323 1.656 5.787-2.479ZM9 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-6.902 5.49 10-2-.196-.98-10 2 .196.98Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceThinkingSmall;
