import type { SVGProps } from "react";
const SvgTwoSpeechBubblesMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.25 7.75h-.5v14.5h4.5v4.082l.854-.853 3.228-3.229h.418v4.5h8.918l3.228 3.229.854.853V26.75h4.5v-14.5h-7.5v-4.5H5.25Zm18.5 5.5v3.5h4.75v1h-4.75v2H27v1h-3.25v1.5h-9v3.5h8.332l.147.146 2.521 2.522V25.75h4.5v-12.5h-6.5Zm-18 8V8.75h17v12.5h-9.832l-.147.146-2.521 2.522V21.25h-4.5Zm3.25-8h10.5v-1H9v1Zm9 3H9v-1h9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTwoSpeechBubblesMedium;
