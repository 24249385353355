import type { SVGProps } from "react";
const SvgSaDs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.323 3.133c1.019 0 2.08.291 2.9.698l.66-2.23c-1.14-.446-2.4-.601-3.621-.601-2.681 0-5.201 1.24-5.201 4.092 0 1.978 1.433 2.755 2.727 3.456 1.064.577 2.035 1.103 2.035 2.205 0 1.454-1.321 2.114-2.662 2.114-1.2 0-2.34-.445-3.42-.93l-.72 2.307c1.26.446 2.64.756 3.98.756 3.06 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.775-1.009-.577-1.896-1.085-1.896-2.023 0-1.163 1.34-1.55 2.34-1.55ZM5.58 12.634h-1.3l2-9.268h.96c2.521 0 4.222 1.144 4.222 3.258 0 3.723-1.841 6.01-5.882 6.01Zm2.561-11.4h-4.2L1 14.766h4.701c7.042 0 8.683-4.615 8.683-7.756 0-4.692-3.322-5.778-6.242-5.778Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaDs;
