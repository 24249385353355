import type { SVGProps } from "react";
const SvgSaCi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.36 4.342A8.01 8.01 0 0 1 12.02 2h.02l.02.002a9.69 9.69 0 0 1 6.934 3.774L19 3.009l1 .002-.01 4.49-.001.499H15V7h3.63A8.691 8.691 0 0 0 12 3a7.01 7.01 0 0 0-6.99 7v.052a2.5 2.5 0 1 1-1-.004V10a8.01 8.01 0 0 1 2.35-5.658ZM3 12.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm15 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaCi;
