import type { SVGProps } from "react";
const SvgSpeakerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.75 9.1V8.033l-.82.682-7.84 6.527H6.25v5.498h5.84l7.84 6.541.82.684V9.1Zm-8.159 7.026 7.159-5.96V25.83l-7.158-5.973-.14-.116H7.25v-3.498h5.202l.14-.116Zm15.08 10.3-3.398-2.101-.526.85 3.399 2.102.526-.85Zm-3.924-15.601 3.399-2.102.526.85-3.4 2.102-.525-.85Zm5.538 2.353-5.395 1.338.24.97 5.396-1.338-.241-.97Zm-.001 9.646-5.394-1.34.24-.97 5.395 1.34-.241.97ZM30 17.5h-5.99v1H30v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSpeakerMedium;
