import type { SVGProps } from "react";
const SvgCloudCo2Medium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#cloud-co2-medium_svg__a)">
      <path
        d="M8.994 16.62c-4.817 0-5.774 9.63 1.8 9.63h15.752c7.473 0 7.2-9.63 1.575-9.406 2.475-8.287-8.55-12.095-11.926-4.48-2.972-2.423-7.723-.097-7.2 4.255Z"
        stroke="currentColor"
      />
      <path
        d="M14.275 21.406h1.468v.189c-.003.653-.09 1.148-.26 1.485-.175.343-.411.59-.707.743-.302.158-.706.237-1.213.237-.633 0-1.115-.148-1.446-.444a1.855 1.855 0 0 1-.567-.977c-.1-.406-.15-1.053-.15-1.94 0-.733.033-1.287.1-1.662.065-.375.184-.681.354-.918.363-.504.955-.756 1.775-.756.712 0 1.235.174 1.569.523.196.202.328.428.392.675.068.248.1.636.1 1.166h-1.46v-.14c0-.35-.026-.61-.08-.783-.078-.27-.255-.404-.53-.404-.335 0-.531.208-.59.624-.053.354-.079.88-.079 1.578 0 .793.025 1.38.075 1.757.061.449.26.673.598.673.252 0 .423-.114.514-.343.09-.229.136-.656.136-1.283Zm4.285-4.048c.96 0 1.6.323 1.916.967a2.9 2.9 0 0 1 .26.904c.05.346.074.847.074 1.5 0 .99-.085 1.721-.255 2.193-.276.762-.942 1.142-2 1.142-1.049 0-1.714-.39-1.995-1.173-.167-.463-.25-1.208-.25-2.237 0-.62.027-1.11.083-1.469a2.85 2.85 0 0 1 .29-.921c.32-.604.945-.906 1.877-.906Zm-.005 1.046c-.29 0-.479.148-.567.444-.082.273-.123.878-.123 1.815 0 .861.034 1.458.101 1.789.08.392.277.588.594.588.28 0 .467-.143.558-.433.09-.287.136-.883.136-1.786 0-1.005-.041-1.656-.123-1.955-.085-.308-.277-.462-.576-.462Zm2.931 1.13c0-.662.1-1.143.3-1.442.321-.486.897-.73 1.726-.73.662 0 1.162.153 1.499.458.322.296.483.763.483 1.402 0 .474-.095.88-.285 1.217-.132.234-.39.535-.774.9-.436.417-.72.703-.851.858-.13.155-.24.34-.331.553h2.268v1.17h-4.065a3.484 3.484 0 0 1 .33-1.556c.131-.279.269-.503.412-.673.144-.17.54-.584 1.191-1.243.252-.255.418-.462.497-.621.079-.16.119-.367.119-.619 0-.292-.043-.506-.128-.641s-.22-.202-.404-.202c-.22 0-.37.083-.451.249-.081.165-.12.471-.12.92h-1.416Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="cloud-co2-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCloudCo2Medium;
