import type { SVGProps } from "react";
const SvgUserHelmetSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3C9.65 3 7.75 4.913 7.75 7.267V8h-.766v1H8.5c0 .93.284 1.807.77 2.503C6.473 12.902 5 16.367 5 19.5v.5h14v-.5c0-3.15-1.475-6.606-4.27-7.997.486-.696.77-1.574.77-2.503h1.484V8h-.734v-.733C16.25 4.913 14.35 3 12 3Zm3.25 5v-.733c0-.794-.282-1.521-.75-2.087V7h-1V4.368A3.222 3.222 0 0 0 12 4c-.54 0-1.051.133-1.5.368V7h-1V5.18a3.264 3.264 0 0 0-.75 2.087V8h6.5ZM12 12c1.311 0 2.5-1.268 2.5-3h-5c0 1.732 1.189 3 2.5 3Zm2.016.274A3.178 3.178 0 0 1 12 13a3.18 3.18 0 0 1-2.016-.726C7.619 13.29 6.166 16.136 6.014 19h11.973c-.152-2.88-1.608-5.717-3.971-6.726Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserHelmetSmall;
