import type { SVGProps } from "react";
const SvgFullscreenLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 2H2v17h5V7h12V2H2.5ZM3 18V3h15v3H6v12H3ZM29.5 2H29v5h12v12h5V2H29.5Zm.5 4V3h15v15h-3V6H30ZM2 29h5v12h12v5H2V29Zm1 1v15h15v-3H6V30H3Zm38.5-1H41v12H29v5h17V29h-4.5Zm.5 12.5V30h3v15H30v-3h12v-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFullscreenLarge;
