import type { SVGProps } from "react";
const SvgFigmaMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.75 3.25a5 5 0 0 0-2.064 9.555 5.024 5.024 0 0 0-1.375.98 4.949 4.949 0 0 0-1.443 3.517 4.95 4.95 0 0 0 1.483 3.502 5.04 5.04 0 0 0 1.429.985 5.368 5.368 0 0 0-2.62 2.78 5.375 5.375 0 1 0 10.34 2.056V20.25a5 5 0 1 0 6.517-7.322A5.002 5.002 0 0 0 23.25 3.25h-10.5Zm6.75 10a5.03 5.03 0 0 0-1 1v-1h1Zm-1-1h4.75a4 4 0 0 0 0-8H18.5v8Zm-1-8h-4.75a4 4 0 0 0 0 8h4.75v-8Zm5 9a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-5 .05v7.95h-4.608a4.053 4.053 0 0 1-2.84-1.16 3.95 3.95 0 0 1-1.184-2.793 3.949 3.949 0 0 1 1.152-2.807 4.051 4.051 0 0 1 2.824-1.19H17.5Zm0 8.95h-4.375a4.375 4.375 0 1 0 4.375 4.375V22.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFigmaMedium;
