import type { SVGProps } from "react";
const SvgMicroscooterProfilePowerPlugSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.5 2H16v1h1v7.035A3.508 3.508 0 0 0 14.035 13H7.731A2 2 0 0 0 4 14c0 1.103.897 2 2 2s2-.897 2-2h7v-.5c0-1.378 1.122-2.5 2.5-2.5h.5V2h-.5ZM5 14c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1Zm9 2.999v1l1.999-.004.002 1-2.001.004v1h2v1h-2v1h-1.5a2.502 2.502 0 0 1-2.45-2H7v-1h3.05a2.5 2.5 0 0 1 2.447-1.998l1.002-.002H14ZM13 18h-.502a1.499 1.499 0 1 0 .002 3h.5v-3Zm5-5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Zm-2 1c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMicroscooterProfilePowerPlugSmall;
