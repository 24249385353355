import type { SVGProps } from "react";
const SvgMergeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#merge-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M12.746 13.01c3.87 2.12 9.311 3.61 18.289 3.732l-4.974-4.974.707-.707 5.835 5.835.354.354-.354.354-5.835 5.835-.707-.708 4.974-4.973c-8.978.123-14.419 1.612-18.289 3.731-3.283 1.798-5.456 4.052-7.74 6.421-.54.56-1.085 1.126-1.652 1.694l-.708-.707c.544-.544 1.073-1.094 1.603-1.644 2.292-2.38 4.588-4.763 8.017-6.64 2.804-1.536 6.352-2.73 11.272-3.363-4.92-.632-8.468-1.827-11.272-3.362C8.837 12.01 6.541 9.626 4.25 7.247c-.53-.55-1.06-1.1-1.603-1.643l.708-.708c.567.568 1.113 1.134 1.652 1.694 2.284 2.369 4.457 4.623 7.74 6.42Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="merge-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMergeMedium;
