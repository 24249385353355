import type { SVGProps } from "react";
const SvgDeskSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.5 10H3v11h1v-8h16v8h1V10H3.5ZM20 12v-1H4v1h16Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDeskSmall;
