import type { SVGProps } from "react";
const SvgSaBs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.302 3.133c1.02 0 2.081.291 2.9.698l.66-2.23c-1.139-.446-2.4-.601-3.62-.601-2.68 0-5.2 1.24-5.2 4.092 0 1.978 1.432 2.755 2.725 3.456 1.065.577 2.035 1.103 2.035 2.205 0 1.454-1.32 2.114-2.66 2.114-1.2 0-2.34-.445-3.422-.93L13 14.244c1.26.446 2.64.756 3.981.756 3.061 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.775-1.009-.577-1.897-1.085-1.897-2.023 0-1.163 1.341-1.55 2.341-1.55ZM6.522 6.701H5.421l.74-3.335h.981c1.06 0 2.34.058 2.34 1.357 0 1.59-1.54 1.978-2.96 1.978Zm-1.421 5.933h-.96l.84-3.916h.9c1.5 0 2.861.212 2.861 1.629 0 2.093-1.921 2.287-3.641 2.287ZM8.062 1.233H3.921L1 14.767h3.981c2.081 0 6.562-.31 6.562-4.188 0-1.455-.681-2.618-2.241-2.85v-.04c1.62-.251 2.981-1.57 2.981-3.217 0-2.463-2.041-3.239-4.221-3.239Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaBs;
