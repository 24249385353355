import type { SVGProps } from "react";
const SvgBluetoothDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.835 2.629 11 1.878V9h1V4.123l3.728 3.355-2.04 1.632.624.78 2.5-2 .46-.367-.438-.394-5-4.5ZM3.646 5.354l6.865 6.864-4.345 3.91.669.743L11 13.123v8.99l.832-.74 4.148-3.686 2.666 2.667.708-.707-15-15-.708.707Zm11.625 11.625L12 13.707v6.18l3.271-2.909Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothDisabledSmall;
