import type { SVGProps } from "react";
const SvgClipboardTickMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.75 3.25h8.5v1.5h9v28H4.75v-28h9v-1.5Zm1 3v-2h6.5v2h-6.5Zm7.5.5v-1h8v26H5.75v-26h8v1.5h8.5v-.5Zm-6.851 19.427 10.5-13.875-.798-.604-10.187 13.463-5.599-4.55-.63.777 6 4.875.401.326.313-.412Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardTickMedium;
