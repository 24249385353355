import type { SVGProps } from "react";
const SvgCircleInformationSmallMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 18c0-6.006 4.744-10.75 10.75-10.75 6.004 0 10.75 4.745 10.75 10.75 0 6.006-4.746 10.75-10.75 10.75-6.006 0-10.75-4.744-10.75-10.75ZM18 6.25C11.442 6.25 6.25 11.442 6.25 18S11.442 29.75 18 29.75c6.557 0 11.75-5.192 11.75-11.75S24.557 6.25 18 6.25Zm1.22 7.25v-3h-1v3h1Zm0 9.235h3.25v1h-7.5v-1h3.25v-6.5H15v-1h4.22v7.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleInformationSmallMedium;
