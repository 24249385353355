import type { SVGProps } from "react";
const SvgPercentLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.5 15a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM16 17.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM28.5 28a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM25 30.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm-9.646 2.853 18-18-.707-.707-18 18 .707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPercentLarge;
