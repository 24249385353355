import type { SVGProps } from "react";
const SvgSignParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.75 5.25a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10.5a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V5.25Zm1-2a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2h4V33h1V17.75h4a2 2 0 0 0 2-2V5.25c0-1.106-.894-2-2-2h-9Zm2.5 3H18c.817 0 1.527.207 2.031.706.505.5.719 1.211.719 2.044 0 .828-.208 1.539-.71 2.04-.502.501-1.214.708-2.039.71H16.25V15h-1V6.25Zm1 4.5H18c.671-.001 1.084-.17 1.333-.418.249-.248.417-.66.417-1.332s-.172-1.085-.423-1.334c-.252-.25-.667-.416-1.327-.416h-1.75v3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSignParkingMedium;
