import type { SVGProps } from "react";
const SvgArrowCircleLightningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 18C3.25 9.854 9.854 3.25 18 3.25S32.75 9.854 32.75 18 26.146 32.75 18 32.75c-6.02 0-11.445-3.615-13.75-8.805V28.5h-1v-5.75H9v1H5.26c2.196 4.713 7.193 8 12.74 8 7.594 0 13.75-6.156 13.75-13.75S25.594 4.25 18 4.25 4.25 10.406 4.25 18h-1Zm11-10.25h-.377l-.104.363-3 10.5-.182.637h6.053l-1.38 6.902-.433 2.162 1.323-1.764 9-12 .6-.8h-6.191l2.638-5.276.362-.724H14.25Zm-2.337 10.5 2.714-9.5h6.314l-2.638 5.276-.362.724h5.809l-7.077 9.436 1.067-5.338.12-.598h-5.947Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleLightningMedium;
