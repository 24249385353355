import type { SVGProps } from "react";
const SvgIc9Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-9-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M38.704 12.897h-2.176a3.4 3.4 0 0 0 1.17 2.318c.676.574 1.531.862 2.567.862 1.555 0 2.717-.74 3.484-2.223.589-1.139.883-2.515.883-4.129 0-1.745-.334-3.1-1.001-4.066a3.957 3.957 0 0 0-1.403-1.258 3.828 3.828 0 0 0-1.822-.443 3.926 3.926 0 0 0-2.743 1.045c-.888.807-1.332 1.861-1.332 3.164-.006.62.119 1.235.367 1.803a3.41 3.41 0 0 0 3.228 2.096 2.72 2.72 0 0 0 1.352-.312 3.499 3.499 0 0 0 1.084-1.056 10.29 10.29 0 0 1-.229 1.51c-.163.697-.396 1.21-.698 1.54-.302.329-.732.51-1.178.494-.857 0-1.375-.448-1.553-1.345Zm.402-6.376a1.58 1.58 0 0 1 1.253-.609 1.598 1.598 0 0 1 1.333.657c.328.445.497.989.48 1.542a2.42 2.42 0 0 1-.465 1.487 1.648 1.648 0 0 1-2.743-.15c-.25-.408-.38-.88-.37-1.36a2.368 2.368 0 0 1 .512-1.567Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIc9Negative;
