import type { SVGProps } from "react";
const SvgCloudLightningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.043 16.063a2.857 2.857 0 0 0-1.863-1.08c.246-1.253.065-2.373-.417-3.293a4.664 4.664 0 0 0-2.555-2.204c-1.997-.725-4.471-.138-5.8 2.23-2.191-1.226-5.127.331-5.134 3.133-.744.144-1.342.586-1.758 1.168-.504.704-.753 1.623-.714 2.53.04.909.372 1.84 1.073 2.547.708.713 1.75 1.156 3.125 1.156v-1c-1.15 0-1.922-.364-2.415-.86-.498-.503-.753-1.184-.784-1.887-.03-.707.167-1.4.528-1.905.355-.495.855-.798 1.471-.798h.563l-.066-.56c-.296-2.473 2.36-3.737 3.986-2.404l.51.418.265-.602c1.01-2.29 3.123-2.838 4.81-2.226.848.308 1.58.908 2.01 1.728.426.815.572 1.879.193 3.153l-.2.67.7-.028c.777-.03 1.328.273 1.684.73.367.47.549 1.133.494 1.825a2.948 2.948 0 0 1-.864 1.882c-.516.503-1.292.864-2.385.864v1c1.329 0 2.363-.446 3.083-1.148a3.949 3.949 0 0 0 1.163-2.52c.07-.9-.159-1.822-.703-2.519ZM17.25 19.75h-.207l-.147.146-4.5 4.5-.853.854H15.5l-1.892 2.523-2.453 3.27 3.17-2.583 4.994-4.073 1.089-.887h-3.451l2.896-2.896.854-.854H17.25Zm-.35 5.3.412-.55h.287l-.737.601.038-.051Zm-2.943-.8h.836l.603-.604 2.897-2.896h-.836l-3.5 3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudLightningMedium;
