import type { SVGProps } from "react";
const SvgSaRz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.23 2.46V15H9.31V2.46c0-.81.65-1.46 1.46-1.46.81 0 1.46.66 1.46 1.46Zm-5.26-.93c.66 0 1.27.23 1.76.6v2.24H1.28L5.39 2c.45-.3 1-.47 1.58-.47Zm13.2 3.42a14.468 14.468 0 0 1-7.35 3.3v-3.3h7.35Zm.1-.58h-7.44V2.13c.49-.38 1.1-.6 1.76-.6.58 0 1.12.17 1.58.47l4.1 2.37Zm-18.89.58h7.35v3.3c-2.78-.39-5.31-1.57-7.35-3.3Zm21.34 8.52V15l-.01.01H19.8v-1.54c0-.81.65-1.46 1.46-1.46.81 0 1.46.66 1.46 1.46ZM19.22 15v-3.29c0-.8-.65-1.46-1.46-1.46-.81 0-1.46.65-1.46 1.46V15h2.92Zm-3.49 0v-4.46c0-.8-.65-1.46-1.46-1.46-.81 0-1.46.65-1.46 1.46V15h2.92Zm-7-.26V15h-2.9v-1.29a1.093 1.093 0 0 1 1.45-1.03 2.19 2.19 0 0 1 1.45 2.06Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRz;
