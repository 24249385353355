import type { SVGProps } from "react";
const SvgOfficeChairSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.5 4H8v9h3v1H7v3h4v3H8v1h8v-1h-3v-3h4v-3h-4v-1h3V4H8.5Zm4.5 8h2V5H9v7h4Zm-2 4h5v-1H8v1h3Zm7-5v3h-1v-3h1ZM7 14v-3H6v3h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgOfficeChairSmall;
