import type { SVGProps } from "react";
const SvgCalculatorMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.75 5.25a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v25.5a1 1 0 0 1-1 1h-18a1 1 0 0 1-1-1V5.25Zm1-2a2 2 0 0 0-2 2v25.5a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V5.25a2 2 0 0 0-2-2h-18Zm1.5 3h-.5v5.5h16v-5.5h-15.5Zm.5 4.5v-3.5h14v3.5h-14Zm1 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-11 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-5 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7-6a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0v-6Zm1-2a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0v-6a2 2 0 0 0-2-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCalculatorMedium;
