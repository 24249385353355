import type { SVGProps } from "react";
const SvgMagnifyingGlassMinusLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 17.5C3 9.492 9.492 3 17.5 3S32 9.492 32 17.5 25.508 32 17.5 32 3 25.508 3 17.5ZM17.5 2C8.94 2 2 8.94 2 17.5 2 26.06 8.94 33 17.5 33c4.1 0 7.829-1.592 10.6-4.192l3.869 3.868-.707.707-.353.353.353.354 11.324 11.324a2 2 0 1 0 2.828-2.828L34.09 31.262l-.353-.354-.354.354-.707.707-3.868-3.868A15.446 15.446 0 0 0 33 17.5C33 8.94 26.06 2 17.5 2Zm0 3C10.596 5 5 10.596 5 17.5S10.596 30 17.5 30 30 24.404 30 17.5 24.404 5 17.5 5ZM4 17.5C4 10.044 10.044 4 17.5 4S31 10.044 31 17.5 24.956 31 17.5 31 4 24.956 4 17.5Zm28.323 16.236 10.97 10.97a1 1 0 1 0 1.414-1.413l-10.97-10.97-1.414 1.413ZM26 18H9v-1h17v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassMinusLarge;
