import type { SVGProps } from "react";
const SvgCarProfilePowerPlugLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.052 7a1.5 1.5 0 0 0-1.236.65L3.451 14H16V7H9.052ZM17 14h17.905l-9.933-6.91a.5.5 0 0 0-.286-.09H17v7Zm19.456 1H3v1h.5A1.5 1.5 0 0 1 5 17.5v1A1.5 1.5 0 0 1 3.5 20H3v5h3.027a4.5 4.5 0 0 1 8.945 0h15.056a4.5 4.5 0 0 1 8.945 0H45v-6h-1.513l-.013-.001c-.79-.041-1.2-.736-1.436-1.31l-.002-.004-.753-1.881-4.827-.805ZM45 17.694V18h-1.484c-.07-.006-.143-.037-.234-.135-.105-.113-.212-.294-.319-.553l-.526-1.315 1.31.219A1.5 1.5 0 0 1 45 17.695ZM3 19v-2h.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3Zm-.5 7h3.528a4.5 4.5 0 0 0 8.945 0h15.054a4.5 4.5 0 0 0 8.945 0H46v-8.306a2.5 2.5 0 0 0-2.089-2.466l-7.218-1.203-11.15-7.756A1.5 1.5 0 0 0 24.686 6H9.052a2.5 2.5 0 0 0-2.06 1.084L2.264 13.96a1.5 1.5 0 0 0-.264.85V26h.5Zm8-4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm0 2a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3.002ZM8 25.5a2.5 2.5 0 1 1 5.002.001A2.5 2.5 0 0 1 8 25.499Zm23 0a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm3.5-1.5a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3.002ZM32 25.5a2.5 2.5 0 1 1 5.002.001A2.5 2.5 0 0 1 32 25.5ZM19 17h3v-1h-3v1Zm-3 4.999.006-6 1 .001L17 22l-1-.001Zm5 14c0-1.772 1.494-3 3-3h3v7h-3c-1.734 0-3-1.416-3-3v-1Zm3-4c-1.367 0-2.737.758-3.465 2H18v2h-4v1h4v2h2.538A3.96 3.96 0 0 0 24 41h4v-2h3v-1h-3v-3h3v-1h-3v-2h-4Zm-5 3h1.124c-.08.315-.124.65-.124 1v1c0 .342.044.677.128 1H19v-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarProfilePowerPlugLarge;
