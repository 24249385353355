import type { SVGProps } from "react";
const SvgPrinterSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 3H6v4H2v10h4v5h12v-5h4V7h-4V3H6.5ZM17 7V4H7v3h10Zm4 9h-3v-3H6v3H3V8h18v8ZM7 21v-7h10v7H7Zm9-5H8v-1h8v1Zm-8 2h8v-1H8v1Zm0 2h8v-1H8v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPrinterSmall;
