import type { SVGProps } from "react";
const SvgRouteCircleEndMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.75 10.25a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-17.25 9h7.763c.26 5.015 4.41 9 9.487 9a9.5 9.5 0 0 0 9.5-9.5c0-5.245-4.254-9.5-9.5-9.5-5.078 0-9.227 3.987-9.487 9H4.5v1ZM21.75 24a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRouteCircleEndMedium;
