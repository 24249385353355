import type { SVGProps } from "react";
const SvgHandClockMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#hand-clock-medium_svg__a)">
      <path
        d="M13.5 24.75A9.75 9.75 0 0 1 3.75 15c0-5.385 4.366-9.75 9.75-9.75 1.623 0 3.154.397 4.5 1.098M12.75 9v6.75H18m2.248 15H30.75s1.496-3 1.496-6l.004-12.005a1.495 1.495 0 0 0-2.99-.003l-.01 6.758V8.25a1.5 1.5 0 1 0-3 0V19.5 6.74a1.49 1.49 0 0 0-2.982-.001l-.018 12.76.015-11.245a1.508 1.508 0 1 0-3.015-.002l-.002 14.422-3.355-3.392a1.486 1.486 0 0 0-2.338 1.8l5.694 9.668Z"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="hand-clock-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandClockMedium;
