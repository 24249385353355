import type { SVGProps } from "react";
const SvgBicycleTandemSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.5 6H16v1h1.566l-1.334 2H14V7h-3v1h2v1H9V7H6v1h2v1.382l-.463.925A4.004 4.004 0 0 0 2 14c0 2.206 1.794 4 4 4s4-1.794 4-4h4c0 2.206 1.794 4 4 4s4-1.794 4-4a4.004 4.004 0 0 0-4.663-3.945l-.26-.52 1.839-2.758.518-.777H18.5Zm-5.809 4-1.5 3H9.874a4.01 4.01 0 0 0-1.468-2.194L8.809 10h3.882ZM18 17c-1.654 0-3-1.346-3-3h4.309l-.362-.724-1.135-2.27A3.004 3.004 0 0 1 21 14c0 1.654-1.346 3-3 3Zm-.309-4-.878-1.755A3.017 3.017 0 0 0 15.171 13h2.52Zm-3.564 0a4.016 4.016 0 0 1 2.239-2.65L16.19 10h-2.382l-1.5 3h1.818Zm-7.038-1.796A3.004 3.004 0 0 0 3 14c0 1.654 1.346 3 3 3s3-1.346 3-3H5.691l.362-.724 1.036-2.072ZM8.829 13h-1.52l.64-1.28c.395.34.702.78.88 1.28Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBicycleTandemSmall;
