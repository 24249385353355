import type { SVGProps } from "react";
const SvgMagnifyingGlassPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 10.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM9.5 3a7.5 7.5 0 1 0 5.022 13.07l6.151 5.308.654-.757-6.107-5.27A7.5 7.5 0 0 0 9.5 3ZM5 11h4v4h1v-4h4v-1h-4V6H9v4H5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassPlusSmall;
