import type { SVGProps } from "react";
const SvgTrafficJamSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.04 2c-.494 0-.973.251-1.248.675L2.256 4.97A1.49 1.49 0 0 0 2 5.8V9h2.085a1.5 1.5 0 0 0 2.83 0H8V8H6.915a1.5 1.5 0 0 0-2.83 0H3V6h11.5c.274 0 .5.226.5.5V7h1v-.5c0-.826-.674-1.5-1.5-1.5h-.793l-2.563-2.564A1.49 1.49 0 0 0 10.08 2H5.04Zm7.253 3-1.857-1.856A.49.49 0 0 0 10.08 3H9v2h3.293ZM8 3H5.04a.495.495 0 0 0-.41.22l-.004.008L3.438 5H8V3ZM5 8.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm4.782.175A1.495 1.495 0 0 1 11.03 8h5.05c.398 0 .776.15 1.064.436L19.706 11h.793c.826 0 1.5.674 1.5 1.5V15h-2.085a1.5 1.5 0 0 1-2.83 0H13v-1h4.085a1.5 1.5 0 0 1 2.83 0H21v-1.5c0-.274-.226-.5-.5-.5H8.99v1h-1v-1.2c0-.288.089-.586.256-.83l1.536-2.295Zm6.654.469L18.293 11H15V9h1.08a.49.49 0 0 1 .356.144ZM11.03 9H14v2H9.43l1.186-1.772.005-.008A.495.495 0 0 1 11.03 9Zm7.47 5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM5.04 14c-.494 0-.973.251-1.248.675L2.256 16.97A1.491 1.491 0 0 0 2 17.8V21h2.085a1.5 1.5 0 0 0 2.83 0h4.17a1.5 1.5 0 0 0 2.83 0H16v-2.5c0-.826-.674-1.5-1.5-1.5h-.793l-2.563-2.564A1.49 1.49 0 0 0 10.08 14H5.04Zm7.253 3-1.857-1.856A.49.49 0 0 0 10.08 15H9v2h3.293ZM8 15H5.04a.495.495 0 0 0-.41.22l-.004.008L3.438 17H8v-2Zm-5 3v2h1.085a1.5 1.5 0 0 1 2.83 0h4.17a1.5 1.5 0 0 1 2.83 0H15v-1.5c0-.274-.226-.5-.5-.5H3Zm2 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm7 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrafficJamSmall;
