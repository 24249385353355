import type { SVGProps } from "react";
const SvgFingerprintMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.642 4.402a23.088 23.088 0 0 1 2.607-.153c4.36 0 8.432 1.2 11.93 3.273l.51-.86a24.323 24.323 0 0 0-12.44-3.413c-.923 0-1.829.058-2.72.16l.113.993Zm-5.443 3.42a21.418 21.418 0 0 1 8.05-1.573c6.312 0 11.971 2.738 15.902 7.067l-.74.672C25.66 9.857 20.265 7.249 14.25 7.249c-2.715 0-5.302.54-7.675 1.5L6.2 7.822Zm8.05 2.428c-3.306 0-6.388.934-9.028 2.53l-.518-.856A18.389 18.389 0 0 1 14.25 9.25c6.866 0 12.842 3.748 16.034 9.297l-.867.498c-3.021-5.253-8.675-8.795-15.167-8.795ZM5.273 20.574a11.47 11.47 0 0 1 8.978-4.324c6.322 0 11.448 5.103 11.495 11.414l1-.008c-.05-6.86-5.623-12.406-12.495-12.406-3.952 0-7.47 1.84-9.759 4.7l.781.624ZM4.181 15.98a15.429 15.429 0 0 1 10.069-3.73c7.052 0 12.992 4.713 14.871 11.156l-.96.28C26.403 17.657 20.844 13.25 14.25 13.25a14.428 14.428 0 0 0-9.418 3.489l-.65-.76Zm4.578 11.692a5.495 5.495 0 0 1 10.991.079v4.044h1V27.75a6.495 6.495 0 0 0-12.991-.094l1 .015ZM5.6 23.835c1.492-3.29 4.798-5.585 8.65-5.585a9.5 9.5 0 0 1 9.5 9.5v2.645h-1V27.75a8.5 8.5 0 0 0-8.5-8.5 8.495 8.495 0 0 0-7.739 4.998l-.91-.413Zm8.65.415a3.5 3.5 0 0 0-3.5 3.5v2.645h1V27.75a2.5 2.5 0 1 1 5 0v4.5h1v-4.5a3.5 3.5 0 0 0-3.5-3.5Zm-.5 7.25V27h1v4.5h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFingerprintMedium;
