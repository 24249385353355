import type { SVGProps } from "react";
const SvgPaperAeroplaneLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m45.25 5.53-.264 1.087-8 33-.128.529-.516-.172-10.793-3.598-3.614 6.37-.463.816-.419-.839-4.904-9.808-12.796-3.937-1.145-.353 1.056-.566 41-22 .986-.529ZM17.115 32.611 21 40.381V34.32l.114-.138L39.41 11.908 17.115 32.611ZM22 40.605l2.582-4.55L22 35.192v5.412Zm14.142-1.752-13.794-4.598L43.5 8.505l-7.357 30.348Zm5.17-30.075-24.94 23.159-11.58-3.563 36.52-19.596Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPaperAeroplaneLarge;
