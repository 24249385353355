import type { SVGProps } from "react";
const SvgSaTt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.261 1H1.479L1 3.206h3.94L2.4 15h2.92L7.861 3.206h3.94L12.261 1Zm-.142 2.206L12.6 1h10.782l-.46 2.206h-3.94L16.441 15h-2.92l2.538-11.794h-3.94Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaTt;
