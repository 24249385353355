import type { SVGProps } from "react";
const SvgWheelchairMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.75 8.75a.25.25 0 1 0 0 .501.25.25 0 0 0 0-.5ZM14.5 9a1.25 1.25 0 1 1 2.5.001 1.25 1.25 0 0 1-2.5 0Zm.75 11.25V12h1v7.75h7.5v8.75h-1v-7.75h-7.5v-.5Zm-2.422-4.297c-2.111 1.075-3.57 3.272-3.57 5.797 0 3.573 2.927 6.5 6.5 6.5 2.591 0 4.84-1.539 5.882-3.742l-.904-.428c-.884 1.87-2.79 3.17-4.978 3.17-3.021 0-5.5-2.48-5.5-5.5 0-2.131 1.232-3.993 3.024-4.907l-.454-.89Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairMedium;
