import type { SVGProps } from "react";
const SvgMoneyExchangeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.673 5C13.757 4.984 5 13.493 5 24H4C4 12.909 13.24 3.98 24.68 4h.014A22.21 22.21 0 0 1 43 14.572V8h1v9h-9v-1h7.674A21.21 21.21 0 0 0 24.673 5ZM43 23.994A19.25 19.25 0 0 1 23.409 43H23.4c-3.76 0-7.555-1.495-10.83-3.849C9.53 36.97 6.966 34.067 5.317 31H13v-1H4v9h1v-6.541c1.718 2.838 4.157 5.472 6.986 7.505 3.398 2.44 7.39 4.035 11.41 4.036A20.248 20.248 0 0 0 44 24.006l-1-.012ZM28.19 21.84H17.44a10 10 0 0 1 .83-3.11 8.82 8.82 0 0 1 1.77-2.53 7.251 7.251 0 0 1 5.29-2.07 7.821 7.821 0 0 1 3.22.65A6.35 6.35 0 0 1 31 16.61l.64-1.11a8.362 8.362 0 0 0-6.19-2.45A8.491 8.491 0 0 0 17.92 17a11.29 11.29 0 0 0-1.66 4.71h-1.57l-.52.94h2v1.8h-1.52l-.52 1h2.17v.09a10.08 10.08 0 0 0 3 6.12 8.61 8.61 0 0 0 6.06 2.22 9.239 9.239 0 0 0 3.16-.54 7.42 7.42 0 0 0 2.55-1.53v-1.63a6.73 6.73 0 0 1-2.49 2 7.45 7.45 0 0 1-3.18.69 7.74 7.74 0 0 1-3.77-.93 7.49 7.49 0 0 1-3-2.94 9.558 9.558 0 0 1-1.15-3.39h8.63l.51-1h-9.25v-1.83h10.3l.52-.94Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M17.44 21.84h10.75l-.52.94h-10.3v1.83h9.25l-.51 1h-8.63A9.558 9.558 0 0 0 18.63 29a7.49 7.49 0 0 0 3 2.94 7.74 7.74 0 0 0 3.77.93 7.45 7.45 0 0 0 3.18-.69 6.73 6.73 0 0 0 2.49-2v1.63a7.422 7.422 0 0 1-2.55 1.53 9.241 9.241 0 0 1-3.16.54 8.61 8.61 0 0 1-6.06-2.22 10.08 10.08 0 0 1-3-6.12v-.09h-2.17l.52-1h1.52v-1.8h-2l.52-.94h1.57A11.29 11.29 0 0 1 17.92 17a8.49 8.49 0 0 1 7.53-3.95 8.359 8.359 0 0 1 6.19 2.45L31 16.61a6.35 6.35 0 0 0-2.45-1.83 7.82 7.82 0 0 0-3.22-.65 7.25 7.25 0 0 0-5.29 2.07 8.818 8.818 0 0 0-1.77 2.53 9.999 9.999 0 0 0-.83 3.11Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMoneyExchangeLarge;
