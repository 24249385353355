import type { SVGProps } from "react";
const SvgEv65Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-65-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.104 2.896h-2.206c-.206-.894-.714-1.341-1.524-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.47.72-.81 1.111-1.016.397-.206.873-.31 1.428-.31 1.09 0 1.965.387 2.627 1.16.63.73.944 1.644.944 2.745 0 1.306-.423 2.356-1.27 3.15-.761.714-1.676 1.07-2.745 1.07-1.37 0-2.436-.515-3.198-1.546-.761-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.222-4.65.788-1.084 1.864-1.626 3.23-1.626 1.285 0 2.268.431 2.95 1.293.392.498.644 1.124.755 1.88Zm-3.928 2.658c-.555 0-.997.233-1.325.698-.296.413-.444.905-.444 1.476 0 .566.15 1.06.452 1.484.328.47.777.706 1.349.706.555 0 1.002-.225 1.34-.674.318-.424.477-.923.477-1.5 0-.566-.143-1.05-.429-1.452-.349-.492-.822-.738-1.42-.738ZM41.835 4.17v2.063h-4.673l-.5 2.61c.56-.592 1.278-.888 2.15-.888.857 0 1.603.23 2.238.69.941.693 1.412 1.745 1.412 3.158 0 1.386-.502 2.483-1.507 3.293-.783.634-1.72.952-2.81.952-1.226 0-2.213-.328-2.959-.984-.746-.661-1.14-1.553-1.182-2.674h2.277c.037.407.17.754.397 1.04.354.45.846.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.299.328-1.706.984l-2.071-.031 1.103-6.467h6.371Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv65Negative;
