import type { SVGProps } from "react";
const SvgRe62 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="re-62_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#re-62_svg__a)" fill="#EB0000">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.979 1.979 0 0 1-.197-.625c-.037-.253-.088-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.709-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.171-.949-.514-1.202-.343-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm9.183 2.887h-2.198c-.206-.891-.712-1.337-1.519-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.265 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.086 0 1.96.385 2.618 1.155.628.728.942 1.64.942 2.737 0 1.303-.422 2.35-1.266 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.759-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.942 1.29.39.495.641 1.12.752 1.874Zm-3.915 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.776.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Zm13.131 3.971v2.056h-8.33c0-.627.101-1.226.301-1.795.206-.57.493-1.06.863-1.471.284-.317.617-.625.996-.926.385-.3 1.147-.851 2.286-1.653.554-.385.942-.744 1.163-1.076.222-.332.332-.717.332-1.155 0-.506-.15-.915-.45-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57H34.97V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.697.918.786.664 1.179 1.558 1.179 2.681 0 .981-.314 1.825-.941 2.531-.206.232-.399.42-.578.562-.18.137-.651.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.348 6.348 0 0 0-.87.926h5.426Z" />
    </g>
    <defs>
      <clipPath id="re-62_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe62;
