import type { SVGProps } from "react";
const SvgTwoSpeechBubblesRainbowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 5H2v10h4v3.065l.874-.983L8.724 15H9v3h6.043l2.103 2.104.854.853V18h3V8h-5V5H2.5ZM16 11h3v1h-3v1h2v1h-2v1h-6v2h5.457l.147.146L17 18.543V17h3V9h-4v2ZM3 14V6h12v8H8.275l-.149.168L7 15.435V14H3Zm3-1a3 3 0 0 1 6 0h-1a2 2 0 0 0-4 0H6Zm3-5c-2.766 0-5 2.234-5 5h1c0-2.214 1.786-4 4-4s4 1.786 4 4h1c0-2.766-2.234-5-5-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTwoSpeechBubblesRainbowSmall;
