import type { SVGProps } from "react";
const SvgWalkLargeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.249c-.924 0-1.75.826-1.75 1.75s.826 1.75 1.75 1.75 1.75-.826 1.75-1.75-.826-1.75-1.75-1.75Zm-2.75 1.75c0-1.476 1.274-2.75 2.75-2.75s2.75 1.274 2.75 2.75-1.274 2.75-2.75 2.75-2.75-1.274-2.75-2.75Zm0 6.901-2 3V21h-1v-5.401l.084-.126 3-4.5.148-.223h5.036l.148.223 2.898 4.347 5.073 1.45-.274.96-5.25-1.5-.177-.05-.102-.153L20.75 12.9v7.198l2.916 4.374.084.126v8.401h-1V24.9l-2.768-4.151H16.25v5.66l-.093.13-3.75 5.25-.814-.58 3.657-5.12V12.9Zm1-1.151v8h3.5v-8h-3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkLargeMedium;
