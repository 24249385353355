import type { SVGProps } from "react";
const SvgHandBriefcaseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.75a2.75 2.75 0 0 0-2.75 2.75v.25H10.5a2.75 2.75 0 0 0-2.75 2.75v9h1v-4.75h8v1.35c0 .912.738 1.65 1.65 1.65h3.7a1.65 1.65 0 0 0 1.65-1.65v-1.35h8v7.75A1.75 1.75 0 0 1 30 24.25h-1.496v1H30a2.75 2.75 0 0 0 2.75-2.75v-12A2.75 2.75 0 0 0 30 7.75h-4.75V7.5a2.75 2.75 0 0 0-2.75-2.75H18Zm6.25 3V7.5a1.75 1.75 0 0 0-1.75-1.75H18a1.75 1.75 0 0 0-1.75 1.75v.25h8Zm-13.75 1a1.75 1.75 0 0 0-1.75 1.75v3.25h8V12.4c0-.912.738-1.65 1.65-1.65h3.7c.912 0 1.65.738 1.65 1.65v1.35h8V10.5A1.75 1.75 0 0 0 30 8.75H10.5Zm7.9 3a.65.65 0 0 0-.65.65v1.35h5V12.4a.65.65 0 0 0-.65-.65h-3.7Zm4.35 4.35v-1.35h-5v1.35c0 .36.29.65.65.65h3.7a.65.65 0 0 0 .65-.65Zm-10.493 8.11-5.769-4.03a2 2 0 0 0-2.5 3.122l8.882 7.823.141.125h5.896l1.366 1.355.146.145h13.083l-.908-.862-7.99-7.595h-.001c-1.759-1.681-4.04-2.962-6.422-3.043H15c-.886 0-1.587.337-2.061.871-.466.525-.689 1.21-.689 1.88 0 .069.002.139.007.208Zm5.898-1.96c2.052.072 4.109 1.19 5.757 2.766h.001l7.085 6.734H20.831l-1.366-1.355-.146-.145H13.39l-8.747-7.705-.009-.008-.009-.007a1 1 0 0 1 1.252-1.56l.013.01.014.01 7.103 4.962c.473.491 1.15.798 1.994.798H18v-1h-3c-.614 0-1.038-.225-1.314-.535A1.836 1.836 0 0 1 13.25 24c0-.456.153-.895.436-1.215.276-.31.7-.535 1.314-.535h3.155Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandBriefcaseMedium;
