import type { SVGProps } from "react";
const SvgBookMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 8.25a2.5 2.5 0 0 1 2.5-2.5h19v18.502h-19a3.49 3.49 0 0 0-2.5 1.05V8.25Zm-1 20.002V8.25c0-1.932 1.568-3.5 3.5-3.5h20v20.502h-20a2.501 2.501 0 0 0 0 5l19.5-.002h.5v1h-.5l-19.5.002a3.501 3.501 0 0 1-3.465-3H6.25Zm3.5-1h-.5v1h20.5v-1h-20Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBookMedium;
