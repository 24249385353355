import type { SVGProps } from "react";
const SvgEv42 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-42_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.405 6.99v1.96h-1.46v2.65h-2.261v-2.65h-4.999v-2.174l5.118-6.776h2.142v6.99h1.46Zm-3.674.024V6.868L26.9 11.184h3.143Zm12.373 2.523v2.063H34.06c0-.63.1-1.23.301-1.801a4.438 4.438 0 0 1 .865-1.476 8.123 8.123 0 0 1 1-.928c.386-.302 1.15-.854 2.293-1.659.555-.386.944-.745 1.166-1.079a2.04 2.04 0 0 0 .333-1.158c0-.508-.15-.918-.452-1.23-.296-.317-.688-.476-1.174-.476-1.196 0-1.812.86-1.849 2.579h-2.23v-.254c0-1.407.395-2.497 1.183-3.27.767-.75 1.759-1.126 2.975-1.126 1.08 0 1.981.307 2.706.92.788.667 1.182 1.564 1.182 2.69 0 .984-.315 1.83-.944 2.54-.206.232-.4.42-.58.563-.18.137-.653.473-1.42 1.007-.73.508-1.253.897-1.57 1.167a6.36 6.36 0 0 0-.873.928h5.443Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv42;
