import type { SVGProps } from "react";
const SvgBookSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 5.5A1.5 1.5 0 0 1 6.5 4H19v12.001H6.5c-.563 0-1.082.186-1.5.5V5.5ZM4 19.001V5.5A2.5 2.5 0 0 1 6.5 3H20v14.001H6.5a1.5 1.5 0 0 0 0 3l13-.001h.5v1h-.5l-13 .001a2.501 2.501 0 0 1-2.45-2H4Zm2.5-1H6v1h14v-1H6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBookSmall;
