import type { SVGProps } from "react";
const SvgFaceSadSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 12c0-4.47 3.53-8 8-8 4.47 0 8 3.53 8 8 0 4.47-3.53 8-8 8-4.47 0-8-3.53-8-8Zm8-9c-5.022 0-9 3.977-9 9 0 5.022 3.978 9 9 9s9-3.978 9-9c0-5.023-3.978-9-9-9Zm0 9c-2.28 0-4.175 1.358-5.012 3.302l.918.396C8.593 14.104 10.137 13 12 13c1.863 0 3.407 1.104 4.094 2.698l.918-.396C16.175 13.358 14.28 12 12 12ZM8 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm7-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceSadSmall;
