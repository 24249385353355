import type { SVGProps } from "react";
const SvgIncreaseSizeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25.5 5.749h4.043l-5.897 5.896.708.707 5.896-5.896v4.043h1v-5.75H25.5v1ZM3 14.75h.52v-1H3v1Zm1.563 0h1.041v-1H4.562v1Zm2.083 0h1.042v-1H6.646v1Zm2.083 0h1.042v-1H8.729v1Zm2.084 0h1.041v-1h-1.041v1Zm2.083 0h1.041v-1h-1.041v1Zm2.083 0h1.042v-1h-1.042v1Zm2.084 0h1.041v-1h-1.041v1Zm2.083 0h1.041v-1h-1.041v1Zm2.083 0h.021v.021h1v-1.02h-1.02v1Zm.021 1.063v1.042h1v-1.042h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.084v1.041h1V19.98h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.084v1.041h1V26.23h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.084V33h1v-.52h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIncreaseSizeMedium;
