import type { SVGProps } from "react";
const SvgCalendarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 5v1.01h8V5h1v1.01h3v12.001l-.501-.001-15-.03L4 17.979V6.01h3V5h1Zm8 2.01V8h1v-.99h2v2.01H5V7.01h2V8h1v-.99h8ZM5 16.981V10.02h14v6.989l-14-.028ZM13 12h4.98v-1H13v1Zm-2 2H6v-1h5v1Zm-5 2h5v-1H6v1Zm12-2h-5v-1h5v1Zm-5 2h5v-1h-5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCalendarSmall;
