import type { SVGProps } from "react";
const SvgFaceGrinningLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 23.5C7 13.835 14.835 6 24.5 6S42 13.835 42 23.5 34.165 41 24.5 41 7 33.165 7 23.5ZM24.5 5C14.283 5 6 13.283 6 23.5S14.283 42 24.5 42 43 33.717 43 23.5 34.717 5 24.5 5Zm-6 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.278 1.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Zm11.445-.831a1.5 1.5 0 1 1 1.667 2.495 1.5 1.5 0 0 1-1.667-2.495ZM30.5 18a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-15.95 7h-.553l.055.55a10.5 10.5 0 0 0 20.895 0l.055-.55H14.55Zm3.577 6.546A9.5 9.5 0 0 1 15.119 26H33.88a9.5 9.5 0 0 1-15.754 5.546Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceGrinningLarge;
