import type { SVGProps } from "react";
const SvgDocumentStandardSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 4H6v16h12V9.293l-.146-.147-5-5L12.707 4H6.5ZM7 19V5h5v5h5v9H7Zm9.293-10L13 5.707V9h3.293Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentStandardSmall;
