import type { SVGProps } from "react";
const SvgSaSm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={33}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.302 3.133c1.019 0 2.08.291 2.9.698l.66-2.23C10.722 1.155 9.462 1 8.241 1 5.56 1 3.04 2.24 3.04 5.092c0 1.978 1.433 2.755 2.726 3.456 1.065.577 2.036 1.103 2.036 2.206 0 1.454-1.321 2.114-2.662 2.114-1.2 0-2.34-.447-3.42-.932L1 14.244c1.26.446 2.64.756 3.98.756 3.061 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.776-1.009-.577-1.896-1.084-1.896-2.022 0-1.163 1.34-1.551 2.341-1.551Zm11.82-1.9H15.5l-2.96 13.535h2.66l2.56-11.209h.04l1.122 11.209h2.68l6.32-11.209h.04l-2.64 11.209h2.96l2.982-13.535h-4.66l-5.502 9.754h-.04l-.94-9.754Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSm;
