import type { SVGProps } from "react";
const SvgHighlighterMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m26.301 6.094.302.302 4.5 4.5.302.302-.25.346-12 16.5-.345.473-.414-.413-.566-.567-.28.048-1.269.217c-.866.147-1.856.312-2.49.41a204.144 204.144 0 0 0-1.205 1.821l-.119.181-.032.049-.008.013-.002.003v.001l-.419-.274.418.274-.337.514-.435-.434-.463-.464-1.91 1.273-.127.084H4.31l.52-.782 2.774-4.16-.492-.492-.437-.437.518-.336.272.42-.272-.42h.001l.004-.003.014-.01.055-.035.199-.129.644-.416c.403-.26.86-.553 1.18-.755.09-.574.252-1.543.399-2.41l.222-1.311.052-.306-.567-.567-.413-.414.473-.345 16.5-12 .345-.25Zm-15.47 14.443-.156.917c-.169 1-.356 2.12-.43 2.62l-.035.229-.197.122a118.859 118.859 0 0 0-1.757 1.124l3.67 3.67.22-.335c.31-.47.684-1.037.94-1.414l.123-.183.218-.032c.59-.087 1.71-.273 2.687-.439l.85-.145-6.134-6.134Zm-2.5 6.5-2.14 3.21h2.656l1.616-1.077-2.133-2.133Zm2.186-8.227 8.173 8.173L30.094 11.3l-3.896-3.895-15.68 11.404Zm6.336 2.544 9-9-.707-.708-9 9 .707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHighlighterMedium;
