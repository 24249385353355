import type { SVGProps } from "react";
const SvgEv93Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-93-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.145 8.688h2.19c.18.9.701 1.349 1.563 1.349.487 0 .881-.164 1.183-.492.306-.333.542-.849.706-1.547.105-.444.182-.95.23-1.516-.355.498-.72.852-1.095 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.031-1.357 4.457 4.457 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.768-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.296 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.586-.865-.678-.577-1.069-1.351-1.175-2.325Zm3.856-7.006c-.497 0-.917.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.815.817 1.428.817.556 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.611 1.611 0 0 0-1.34-.66Zm4.994 1.992c.026-.953.261-1.751.706-2.397.72-1.037 1.8-1.555 3.245-1.555 1.11 0 2.007.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.146 1.084-.437 1.507-.285.418-.674.7-1.166.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.27 2.816-.782.677-1.771 1.016-2.967 1.016-1.296 0-2.325-.373-3.086-1.119-.757-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.169 1.266.507 1.658.339.386.818.579 1.436.579.62 0 1.096-.183 1.429-.548a1.79 1.79 0 0 0 .46-1.23c0-.57-.196-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.137.01.27.016.396.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.436-1.047-.291-.27-.675-.405-1.151-.405-.545 0-.965.17-1.262.508-.296.338-.454.833-.476 1.484h-2.197Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv93Negative;
