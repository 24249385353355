import type { SVGProps } from "react";
const SvgBatteryLevelPercentageSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 21.5H3.5v-18h2v-1h4v1h2V11M5 19.5h5m-5-2h5m-5-2h5m-5-2h5m9-1.438-7 9.876m1-9.438a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      stroke="currentColor"
    />
  </svg>
);
export default SvgBatteryLevelPercentageSmall;
