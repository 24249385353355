import type { SVGProps } from "react";
const SvgIr26 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-26_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M44.665 13.743V15.8h-8.301a5.32 5.32 0 0 1 .303-1.796 4.375 4.375 0 0 1 .856-1.47 7.87 7.87 0 0 1 .997-.926c.381-.301 1.14-.852 2.274-1.654a4.43 4.43 0 0 0 1.16-1.075c.223-.343.338-.746.33-1.155.02-.453-.14-.896-.445-1.23a1.538 1.538 0 0 0-1.17-.471c-1.188 0-1.8.857-1.838 2.57h-2.215v-.252c0-1.403.392-2.49 1.175-3.26.762-.748 1.747-1.122 2.956-1.122a4.008 4.008 0 0 1 2.688.917c.783.665 1.175 1.558 1.175 2.682a3.69 3.69 0 0 1-.938 2.53c-.175.204-.367.39-.576.558-.178.14-.649.476-1.41 1.009-.726.506-1.245.894-1.558 1.163a6.22 6.22 0 0 0-.87.925h5.407Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M51.256 7.123h2.191a3.89 3.89 0 0 0-.749-1.875c-.678-.86-1.655-1.29-2.932-1.29a3.751 3.751 0 0 0-3.209 1.622c-.81 1.118-1.214 2.663-1.214 4.635 0 1.846.378 3.285 1.135 4.316.757 1.03 1.816 1.546 3.177 1.546a3.84 3.84 0 0 0 2.728-1.068c.841-.791 1.261-1.838 1.261-3.14a4.05 4.05 0 0 0-.938-2.737 3.26 3.26 0 0 0-2.61-1.155 3.009 3.009 0 0 0-1.414.308c-.44.25-.82.596-1.108 1.013.05-.657.169-1.306.355-1.938.357-1.05.962-1.574 1.813-1.574.804 0 1.309.445 1.514 1.337Zm-3.028 3.346a1.53 1.53 0 0 1 1.317-.697 1.64 1.64 0 0 1 1.411.736c.291.425.44.932.426 1.448a2.428 2.428 0 0 1-.473 1.495 1.586 1.586 0 0 1-1.332.672 1.546 1.546 0 0 1-1.34-.704 2.488 2.488 0 0 1-.45-1.48 2.46 2.46 0 0 1 .441-1.47Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr26;
