import type { SVGProps } from "react";
const SvgSaRs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={13}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.707 3.5c.684 0 1.239-.56 1.239-1.25S6.39 1 5.707 1s-1.239.56-1.239 1.25.555 1.25 1.24 1.25ZM9.334 11l.862 2.38a.94.94 0 0 0 .872.62c.645 0 1.1-.65.873-1.26l-1.289-3.58a.984.984 0 0 0-.931-.66H7.194V5.75c0-.83-.664-1.5-1.487-1.5-.822 0-1.486.67-1.486 1.5V9.5c0 .83.664 1.5 1.486 1.5h3.627Zm-.743.68.614 1.7v.01c-.862.98-2.1 1.61-3.498 1.61C3.111 15 1 12.87 1 10.25c0-1.7.892-3.18 2.23-4.02V8.2a3.241 3.241 0 0 0-.744 2.05c0 1.79 1.447 3.25 3.221 3.25 1.269 0 2.359-.75 2.884-1.82Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRs;
