import type { SVGProps } from "react";
const SvgPieMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.75 10.764h-4.5v5.72a2.617 2.617 0 0 0 1.5 2.52V29.72h.5l19.5.028.5.001V19.204a2.764 2.764 0 0 0 1.455-2.733v-5.707H25.25V6h-1v4.764h-3.5V6h-1v4.764h-3.5V6h-1v4.764h-3.5V6h-1v4.764Zm16.5 8.747a2.77 2.77 0 0 1-3.045-3.039v-.342a1.765 1.765 0 0 0-1.952-1.523h-.001c-1 .114-1.659.898-1.55 1.834a2.765 2.765 0 1 1-5.497.035c-.021-.939-.795-1.718-1.702-1.711h-.02l-.02-.002a1.69 1.69 0 0 0-1.803 1.76v.03l-.002.029c-.102 1.364-1.256 2.705-2.735 2.668a2.893 2.893 0 0 1-.173-.002v3.487h18.5v-3.224Zm0 4.224H8.75v2h18.5v-2Zm0 3H8.75v1.987l18.5.027v-2.014Zm-18.558-7.49.033-.498-.032.498h-.001Zm-1.442-7.48v4.751l-.001.016a1.616 1.616 0 0 0 1.508 1.716h.002c.044.003.095.003.154.002h.027c.831.026 1.635-.774 1.719-1.717a2.69 2.69 0 0 1 2.571-2.771h.004c.088-.003.184-.004.282.002 1.493 0 2.68 1.271 2.689 2.732v.03l-.004.031a1.766 1.766 0 0 0 3.507.405v-.002a1.658 1.658 0 0 0 0-.4v-.003c-.18-1.547.944-2.773 2.43-2.944a2.764 2.764 0 0 1 3.063 2.428l.004.03v.457l-.004.028a1.765 1.765 0 1 0 3.507.405v-.002a1.658 1.658 0 0 0 0-.4l-.003-.03v-4.766H7.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPieMedium;
