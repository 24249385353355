import type { SVGProps } from "react";
const SvgStreetLocationPinMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.267 3.19h-.034a3.605 3.605 0 0 0-3.483 3.705 3.5 3.5 0 0 0 .49 1.745l2.595 4.365.431.725.43-.726 2.58-4.365.001-.003a3.5 3.5 0 0 0 .473-1.743 3.606 3.606 0 0 0-3.483-3.703Zm-1.813 1.82a2.605 2.605 0 0 1 1.796-.82 2.605 2.605 0 0 1 2.5 2.68v.014a2.5 2.5 0 0 1-.337 1.248l-2.15 3.637L15.1 8.13a2.5 2.5 0 0 1-.35-1.25v-.01a2.605 2.605 0 0 1 .704-1.86ZM3 15.25h10.37l-.131.606-3.75 17.25-.978-.212L12.13 16.25H3v-1Zm18.75 0h-.62l.131.606 3.75 17.25.978-.212L22.37 16.25h9.13v-1h-9.75Zm-4 4.25v-3h-1v3h1Zm0 6v-3h-1v3h1Zm0 3v3h-1v-3h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStreetLocationPinMedium;
