import type { SVGProps } from "react";
const SvgCloudLightningMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.953 2.988-.525 1.038c-.614 1.217-.335 2.739.584 3.77.894 1.002 2.404 1.543 4.293.744l1.172-.495-.52 1.16c-.563 1.255-1.65 2.056-2.732 2.488-.74.296-1.522.437-2.191.415a2.748 2.748 0 0 1-.828 2.082c-.52.501-1.263.81-2.206.81v-1c.714 0 1.199-.229 1.512-.53.318-.307.49-.721.52-1.142.029-.425-.09-.824-.311-1.102-.212-.265-.535-.445-1-.427l-.7.028.2-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073c-1.048-.38-2.361-.045-2.996 1.395l-.266.602-.509-.417c-.966-.792-2.566-.048-2.386 1.454l.066.559H7.2c-.348 0-.634.168-.845.463-.217.303-.34.727-.32 1.165.018.434.175.847.473 1.148.293.294.763.524 1.492.524v1c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.168-.764-1.808-.028-.638.147-1.288.507-1.791a2.066 2.066 0 0 1 1.144-.815c.092-1.85 2.007-2.895 3.523-2.176.935-1.523 2.59-1.909 3.944-1.438.284-.782.617-1.37 1.016-1.81.495-.548 1.06-.838 1.67-1.02l1.114-.334Zm1.9 7.776c-.778.312-1.541.393-2.054.322a2.336 2.336 0 0 0-.297-.484 2.099 2.099 0 0 0-1.19-.746c.125-.805-.004-1.532-.322-2.14a3.205 3.205 0 0 0-.942-1.093c.265-.771.556-1.272.863-1.611a2.44 2.44 0 0 1 .238-.23c-.205 1.318.235 2.69 1.117 3.68.929 1.041 2.343 1.658 4.011 1.407-.41.391-.905.688-1.423.896ZM11.5 13h-.207l-.147.146-3 3-.853.854H10l-1.06 1.415-2.453 3.271 3.169-2.584 3.33-2.715 1.088-.887h-2.367l1.646-1.646.854-.854H11.5Zm-1.793 3h.086l.353-.354L11.793 14h-.086l-2 2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudLightningMoonSmall;
