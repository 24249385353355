import type { SVGProps } from "react";
const SvgUserGroupMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 10.249c.728 0 1.384.285 1.925.773l.031.029c.063.057.123.117.182.18.675.721 1.111 1.787 1.111 3.021 0 .076-.004.15-.012.227l-.012.122-.009.079-.009.086v.002c-.208 2.056-1.57 3.484-3.207 3.484-1.61 0-2.952-1.38-3.195-3.379l-.012-.107-.015-.145c-.016-.139-.029-.249-.029-.37 0-1.34.517-2.483 1.295-3.2l.05-.047c.538-.477 1.186-.755 1.906-.755Zm-2.822.25c-.89.923-1.429 2.261-1.429 3.753 0 .123.01.246.021.358-.51.408-1.11.642-1.77.642-1.775 0-3.25-1.682-3.25-4 0-2.32 1.476-4.003 3.25-4.003 1.574 0 2.894 1.322 3.178 3.25Zm-.442 6.974a5.356 5.356 0 0 1-.793-1.767 3.736 3.736 0 0 1-1.944.546 3.836 3.836 0 0 1-2.606-1.016c-3.137 1.93-4.925 5.01-5.126 9.01h5.458c.774-2.864 2.478-5.172 5.01-6.773Zm-5.24 7.773a14.843 14.843 0 0 0-.245 2.497l-.009.508h17.546l-.039-.536a18.377 18.377 0 0 0-.336-2.465h6.375l-.039-.535c-.308-4.336-1.906-7.915-5.493-10.21.631-.886.994-2.032.994-3.254 0-2.683-1.75-5.002-4.25-5.002-2.028 0-3.555 1.541-4.056 3.546a3.734 3.734 0 0 0-3.888 0C15.556 7.79 14.03 6.249 12 6.249c-2.497 0-4.25 2.319-4.25 5.003 0 1.208.356 2.342.975 3.223-3.489 2.21-5.4 5.763-5.474 10.262l-.008.509h6.255ZM24 16.252a3.833 3.833 0 0 0 2.58-.991c3.206 1.99 4.74 5.078 5.127 8.99h-5.54c-.783-2.786-2.337-5.1-4.922-6.751.37-.522.646-1.134.81-1.795A3.734 3.734 0 0 0 24 16.25Zm-1.77-1.643c.51.408 1.11.643 1.77.643 1.776 0 3.25-1.682 3.25-4 0-2.32-1.475-4.003-3.25-4.003-1.573 0-2.893 1.321-3.178 3.25l.044.046c.865.921 1.384 2.24 1.384 3.707 0 .105-.007.211-.016.311l-.004.046ZM18 19.252a3.81 3.81 0 0 1-2.597-1.017c-3.143 1.93-4.936 5.013-5.136 9.016h15.44c-.387-3.915-1.923-7.003-5.136-8.993a3.805 3.805 0 0 1-2.57.994Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserGroupMedium;
