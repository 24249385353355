import type { SVGProps } from "react";
const SvgSaGn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 1H1v14h14V1Zm-1.273 12.727H2.273v-.008l1.231-.316L7.66 9.255l.335-.81.335.81 4.156 4.148 1.234.317-.335-1.234-4.14-4.149-.81-.333.81-.335 4.14-4.147.334-1.25h.008v11.455ZM13.71 2.273l-1.224.332L8.33 6.753l-.335.81-.335-.81-4.156-4.148-1.224-.332h11.43ZM2.273 2.28v11.43l.332-1.224 4.138-4.149.81-.334-.809-.334-4.14-4.147-.331-1.242Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaGn;
