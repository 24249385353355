import type { SVGProps } from "react";
const SvgSeatWindowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 5a1 1 0 1 0-.002 1.999A1 1 0 0 0 6 5ZM4 6a2 2 0 1 1 3.998-.001A2 2 0 0 1 4 6Zm2.85 12L2.97 7.33l-.94.34 4 11 .12.33H14v-1H6.85ZM5.708 9h-.71l.24.668 2.5 7 .118.332h6.546l2.444 4.77.89-.456-2.583-5.042-.14-.272H8.56l-2.143-6h.944l1.379 3.685.121.325h4.117v-1H9.556l-1.38-3.685L8.055 9H5.708Zm5.29-5h10v7h-10V4Zm1 5.707V10h3.295l-.147-.146L13.5 8.207l-1.502 1.5Zm0-1.413 1.149-1.148.353-.353.354.353L15.5 8.793l2.646-2.647.354-.353.354.353 1.144 1.145V5h-8v3.294Zm8 .411L18.5 7.207l-2.646 2.647-.147.146h4.291V8.705Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSeatWindowSmall;
