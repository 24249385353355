import type { SVGProps } from "react";
const SvgTabletSmartphoneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.25 3.25a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2H16.5v-1H5.25a1 1 0 0 1-1-1v-24a1 1 0 0 1 1-1h16.5a1 1 0 0 1 1 1V6h1v-.75a2 2 0 0 0-2-2H5.25Zm5.25 2.5h6v-1h-6v1Zm9.75 3a1 1 0 0 0-1 1v21a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1v-21a1 1 0 0 0-1-1h-10.5Zm-2 1a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v21a2 2 0 0 1-2 2h-10.5a2 2 0 0 1-2-2v-21Zm10.25.5h-6v-1h6v1Zm-12 18h-6v-1h6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTabletSmartphoneMedium;
