import type { SVGProps } from "react";
const SvgUploadSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 5.991h15v3.01h-1V6.99H6v2H5v-3ZM12.5 8l.355.357 3.908 3.936-.71.705L13 9.923v7.079h-1V9.924l-3.049 3.074-.71-.704 3.904-3.936L12.5 8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUploadSmall;
