import type { SVGProps } from "react";
const SvgWavesLadderSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13 5a2 2 0 1 1 4 0h-1a1 1 0 1 0-2 0v2h4V5a2 2 0 1 1 4 0h-1a1 1 0 1 0-2 0v9.957c.368-.1.613-.377.947-.777l.025-.03C20.377 13.663 20.93 13 22 13v1c-.577 0-.858.31-1.285.82l-.025.03c-.406.487-.959 1.15-2.028 1.15-1.07 0-1.623-.663-2.028-1.15l-.025-.03c-.427-.51-.708-.82-1.285-.82-.576 0-.856.309-1.283.82l-.024.03c-.405.486-.957 1.15-2.026 1.15-1.068 0-1.62-.664-2.025-1.15l-.025-.03c-.426-.511-.706-.82-1.282-.82-.575 0-.855.309-1.281.82l-.025.03c-.405.486-.957 1.15-2.025 1.15s-1.62-.664-2.024-1.15l-.025-.03C2.854 14.308 2.574 14 2 14v-1c1.067 0 1.62.664 2.023 1.15l.025.03c.426.512.706.82 1.28.82.575 0 .855-.309 1.281-.82l.025-.03C7.04 13.664 7.591 13 8.66 13s1.62.664 2.025 1.15l.025.03c.426.511.707.82 1.282.82.445 0 .713-.184 1.009-.504V5Zm5 9.808V11h-4v2.438c.332-.25.755-.438 1.324-.438 1.07 0 1.622.663 2.028 1.15l.025.03c.227.272.412.486.623.628ZM18 10V8h-4v2h4ZM2 18c.574 0 .854.309 1.28.82l.024.03c.405.487.956 1.15 2.024 1.15s1.62-.663 2.025-1.15l.025-.03c.426-.511.706-.82 1.281-.82.576 0 .856.309 1.282.82l.025.03c.405.487.957 1.15 2.025 1.15 1.069 0 1.62-.663 2.026-1.15l.024-.03c.427-.51.707-.82 1.283-.82.577 0 .858.31 1.285.82l.025.03c.405.487.958 1.15 2.028 1.15s1.622-.663 2.028-1.15l.025-.03c.427-.51.708-.82 1.285-.82v-1c-1.07 0-1.622.664-2.028 1.15l-.025.03c-.427.511-.708.82-1.285.82-.578 0-.859-.309-1.285-.82l-.025-.03c-.406-.486-.959-1.15-2.028-1.15s-1.621.664-2.026 1.15l-.025.03c-.426.512-.706.82-1.282.82-.575 0-.856-.308-1.282-.82l-.025-.03C10.28 17.665 9.727 17 8.66 17s-1.62.664-2.025 1.15l-.025.03c-.426.512-.706.82-1.281.82-.574 0-.854-.308-1.28-.82l-.025-.03C3.62 17.665 3.067 17 2 17v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWavesLadderSmall;
