import type { SVGProps } from "react";
const SvgShirtShoeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m23.493 3.292.251.167 9 6 .367.245-.197.395-3 6-.206.411-.427-.17-3.064-1.226v1.511h-1v-2.988l.686.274 3.322 1.33 2.597-5.194-8.388-5.593-.223.108c-.307.145-.742.338-1.258.531-1.022.384-2.4.782-3.736.782-1.335 0-2.713-.398-3.736-.782a16.59 16.59 0 0 1-1.48-.639l-8.39 5.593 2.597 5.193 3.323-1.329.65-.26.035.7.727 14.524h1.774v1H10.99l-.024-.475-.715-14.3-3.1 1.24-.427.17-.205-.411-3-6-.198-.395.368-.245 9-6 .25-.167.266.144.005.003.021.011.086.045c.076.038.189.095.332.163.288.136.697.317 1.182.5.979.366 2.226.717 3.385.717 1.16 0 2.407-.351 3.385-.718a15.575 15.575 0 0 0 1.6-.707l.02-.01.005-.003.266-.145Zm-7.164 15.083h7.529l.094.38v.001l.002.007.007.027.03.114a23.659 23.659 0 0 0 .663 1.994c.491 1.28 1.25 2.951 2.328 4.53.84 1.229 1.849 1.835 2.859 2.441.232.14.464.279.694.426.604.386 1.193.83 1.64 1.476.452.653.732 1.474.791 2.578l.028.526h-6.561c-.463 0-1.166.001-2.018-.526-.851-.526-1.805-1.546-2.885-3.48l-.002-.004c-.61-1.118-1.338-1.877-2.061-2.394v6.404h-3.138l-.096-.376.484-.124-.485.124v-.002l-.001-.004-.004-.017-.015-.062a24.238 24.238 0 0 1-.23-1.11 33.03 33.03 0 0 1-.412-3.105c-.219-2.564-.221-6 .663-9.448l.096-.376Zm.786 13.5a25.78 25.78 0 0 1-.15-.75 32.027 32.027 0 0 1-.398-3.011 32.29 32.29 0 0 1-.119-2.74l.1.008a5.814 5.814 0 0 1 1.919.51v5.983h-1.352Zm-.65-7.501.17.012c.421.036 1.005.13 1.663.36 1.323.465 2.928 1.479 4.107 3.638 1.038 1.86 1.89 2.714 2.536 3.114.61.378 1.077.377 1.499.377h5.477c-.097-.67-.3-1.154-.565-1.536-.333-.482-.79-.84-1.356-1.202a24.54 24.54 0 0 0-.618-.377c-1.022-.61-2.247-1.342-3.222-2.769-1.135-1.663-1.927-3.41-2.435-4.735a24.712 24.712 0 0 1-.635-1.88h-5.977c-.406 1.709-.591 3.413-.644 4.998Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShirtShoeMedium;
