import type { SVGProps } from "react";
const SvgMoneyExchangeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.493 4.25C10.589 4.237 4.264 10.399 4.25 18.003l-1-.002c.016-8.183 6.816-14.765 15.244-14.75 5.514.01 10.562 2.807 13.256 7.404V6h1v7.25H25.5v-1h5.96a6.429 6.429 0 0 0-.055-.119c-2.333-4.847-7.351-7.87-12.912-7.88ZM31.75 17.999c-.03 7.617-6.306 13.781-14.186 13.752-5.5-.02-10.627-3.183-12.97-7.99l-.005-.01H10.5v-1H3.25V30h1v-4.771c2.71 4.575 7.842 7.501 13.31 7.522 8.414.03 15.157-6.561 15.19-14.748l-1-.005Zm-18.041.579v.07h-1.097l-.544 1.253h1.753c.588 3.766 2.403 5.649 5.445 5.649 1.292 0 2.44-.25 3.444-.747.07-.035.258-.14.566-.314v-2.011c-1.095.592-1.866.969-2.318 1.132-.45.16-.966.242-1.548.242-.703 0-1.31-.162-1.815-.485-.486-.31-.903-.817-1.25-1.525a7.47 7.47 0 0 1-.616-1.941h4.799l.562-1.253h-5.505v-.004c-.006-.193-.01-.337-.01-.43 0-.188.007-.469.02-.84h6.05l.564-1.252h-6.44a8.04 8.04 0 0 1 .319-1.252c.601-1.826 1.684-2.738 3.25-2.738.854 0 1.69.233 2.511.698.338.195.812.495 1.427.898l.727-1.636c-1.386-1.071-2.932-1.607-4.635-1.607-2.412 0-4.074 1.242-4.983 3.728a10.76 10.76 0 0 0-.502 1.91h-1.27l-.545 1.252h1.672l-.01.072c-.015.376-.021.615-.021.717v.414Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMoneyExchangeMedium;
