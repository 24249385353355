import type { SVGProps } from "react";
const SvgTrashSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.773 7.009A4.473 4.473 0 0 1 12.5 5c1.564 0 2.925.806 3.728 2.009H20v1h-1V20H6V8.009H5v-1h3.773Zm1.288 0h4.878a3.45 3.45 0 0 0-4.878 0ZM7 8.009V19h11V8.009H7ZM9 10.5v6h1v-6H9Zm3 6v-6h1v6h-1Zm3-6v6h1v-6h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrashSmall;
