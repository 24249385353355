import type { SVGProps } from "react";
const SvgCloudRainSnowSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.25 3v3h1V3h-1Zm-.486 8.691a4.6 4.6 0 0 0-1.796-1.852c.708-.678 1.68-1.089 2.782-1.089 2.26 0 4 1.74 4 4 0 1.709-.994 3.114-2.446 3.703a3.137 3.137 0 0 0-.26-.39 2.862 2.862 0 0 0-1.864-1.079c.246-1.253.065-2.374-.416-3.293Zm2.986 6.892a3.966 3.966 0 0 0-.087-1.197c1.836-.736 3.087-2.51 3.087-4.636 0-2.813-2.188-5-5-5-1.51 0-2.85.632-3.76 1.663-1.956-.606-4.301.021-5.582 2.303-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.713 2.53.04.91.371 1.84 1.073 2.548.708.713 1.75 1.155 3.124 1.155h10.5c1.331 0 2.367-.445 3.088-1.147a3.94 3.94 0 0 0 1.162-2.52Zm-5.883-8.156c-1.685-.612-3.799-.065-4.81 2.225l-.265.602-.508-.417c-1.628-1.333-4.283-.069-3.987 2.404l.067.56H10.8c-.615 0-1.116.302-1.47.798-.361.505-.559 1.198-.528 1.905.03.703.285 1.384.784 1.886.492.497 1.264.86 2.414.86h10.5c1.096 0 1.874-.36 2.39-.863a2.942 2.942 0 0 0 .863-1.882c.055-.692-.128-1.355-.496-1.825-.357-.457-.91-.761-1.687-.73l-.698.028.2-.67c.378-1.274.232-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728Zm-1.308-2.17-2.162-2.08.693-.72 2.162 2.079-.693.72Zm9.777 9.892 2.121 2.121.707-.707-2.12-2.12-.708.706ZM31.5 12.25h3v1h-3v-1Zm-.21-6.77L29.169 7.6l.707.708 2.121-2.121-.707-.708ZM7.803 26.775l1.5-3 .894.448-1.5 3-.894-.448Zm7.5-3-1.5 3 .894.448 1.5-3-.894-.448Zm-6 6 3-6 .894.448-3 6-.894-.448ZM19.25 25.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2 4.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSnowSunMedium;
