import type { SVGProps } from "react";
const SvgSaHn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={31}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.722 1H3.921L1 15h2.801l1.28-6.137h5.641L9.442 15h2.8l2.921-14h-2.801l-1.179 5.656H5.541L6.722 1ZM21.86 1h-3.54l-3 14H18l2.22-10.73h.04L23.18 15h3.602l3.02-14h-2.64l-2.28 10.631h-.04L21.86 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaHn;
