import type { SVGProps } from "react";
const SvgCloudSnowMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.953 2.988-.525 1.038c-.614 1.217-.335 2.739.584 3.77.894 1.002 2.404 1.543 4.293.744l1.172-.495-.52 1.16c-.563 1.255-1.65 2.056-2.732 2.488-.719.287-1.478.429-2.134.416a2.657 2.657 0 0 1-.819 2.084C16.74 14.696 15.977 15 15 15H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.028-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.815c.092-1.851 2.006-2.896 3.522-2.177.936-1.522 2.59-1.908 3.945-1.437.284-.783.617-1.37 1.016-1.812.495-.547 1.06-.837 1.67-1.02l1.114-.333Zm1.9 7.776c-.76.304-1.505.389-2.017.327a2.394 2.394 0 0 0-.317-.496 2.17 2.17 0 0 0-1.206-.74c.124-.804-.005-1.53-.323-2.138a3.204 3.204 0 0 0-.943-1.094c.266-.771.557-1.272.864-1.611a2.44 2.44 0 0 1 .238-.23c-.205 1.318.235 2.69 1.117 3.68.929 1.041 2.343 1.658 4.011 1.407-.41.391-.905.688-1.423.896Zm-5.998-3.656c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.166.018.434.175.846.473 1.147.293.295.763.524 1.492.524h7c.762 0 1.267-.233 1.584-.533.321-.304.487-.713.508-1.129.021-.42-.107-.822-.342-1.104-.226-.272-.563-.453-1.03-.434l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073ZM15.5 15.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowMoonSmall;
