import type { SVGProps } from "react";
const SvgLaptopSmartphoneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.25 6.25h20.5V16.5h-1V7.25h-18.5v12.5h10.738v1H9.917l-4.667 3.5H21v1H4.25v2H21v1H3.25V24.5l.2-.15L9.25 20V6.25Zm15.5 13a1 1 0 0 0-1 1v10.5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-10.5a1 1 0 0 0-1-1h-6Zm-2 1a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v10.5c0 1.106-.894 2-2 2h-6c-1.106 0-2-.894-2-2v-10.5Zm2.75.5H30v-1h-4.5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLaptopSmartphoneMedium;
