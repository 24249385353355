import type { SVGProps } from "react";
const SvgMuseumMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#museum-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="m18.1 3.26-.1-.02-.1.02L3.65 6.155l-.4.081v4.014h3v15.5h-1.5v3h-1.5v4h29.5v-4h-1.5v-3h-1.5v-15.5h3V6.236l-.4-.081L18.1 3.26Zm10.65 6.99v15.5h-3.5v-15.5h3.5Zm-4.5 0v15.5h-3.5v-15.5h3.5Zm-4.5 0v15.5h-3.5v-15.5h3.5Zm-8 15.5h3.5v-15.5h-3.5v15.5Zm-1-15.5h-3.5v15.5h3.5v-15.5Zm-6.5-1V7.054L18 4.26l13.75 2.794V9.25H4.25Zm1.5 20v-2.5h24.5v3h1.5v2H4.25v-2h1.5v-.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="museum-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMuseumMedium;
