import type { SVGProps } from "react";
const SvgTrainTracksSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 3v2H4v1h2v3H4v1h2v3H4v1h2v3H4v1h2v2h1v-2h10v2h1v-2h2v-1h-2v-3h2v-1h-2v-3h2V9h-2V6h2V5h-2V3h-1v2H7V3H6Zm11 14v-3H7v3h10Zm0-4H7v-3h10v3Zm0-4V6H7v3h10Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainTracksSmall;
