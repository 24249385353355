import type { SVGProps } from "react";
const SvgCutleryMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.75 4.5v6.75c0 .58.17 1.439.694 2.167.474.658 1.219 1.182 2.306 1.306V31.5h1V14.723c1.087-.124 1.832-.648 2.306-1.306a3.851 3.851 0 0 0 .694-2.167V4.5h-1v6.75c0 .42-.13 1.061-.506 1.583-.357.496-.957.917-1.994.917s-1.637-.42-1.994-.917a2.852 2.852 0 0 1-.506-1.583V4.5h-1Zm3 0V12h1V4.5h-1Zm8 .25h-.5V31.5h1V19.25h3V9c0-1.502-.178-2.608-.772-3.32-.621-.745-1.576-.93-2.728-.93Zm2.5 13.5h-2V5.766c.772.052 1.195.237 1.46.554.343.413.54 1.182.54 2.68v9.25Zm6.295-11.405c-.481.722-.795 1.747-.795 2.905 0 1.158.314 2.183.795 2.905.483.725 1.096 1.095 1.705 1.095.61 0 1.222-.37 1.705-1.095.481-.722.795-1.747.795-2.905 0-1.158-.314-2.183-.795-2.905-.483-.725-1.096-1.095-1.705-1.095-.61 0-1.222.37-1.705 1.095Zm-.832-.554c.602-.904 1.49-1.541 2.537-1.541 1.048 0 1.935.637 2.537 1.54.605.908.963 2.132.963 3.46 0 1.328-.358 2.552-.963 3.46-.504.755-1.207 1.325-2.037 1.49v16.8h-1V14.7c-.83-.165-1.533-.735-2.037-1.49-.605-.908-.963-2.132-.963-3.46 0-1.328.358-2.552.963-3.46Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCutleryMedium;
