import type { SVGProps } from "react";
const SvgTrainSkiLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 9H9v1h2.191l1.5 3H9.735c-1.492 0-2.7.382-3.532 1.225-.83.841-1.203 2.058-1.203 3.55V33h3.25l-.833 2H6.5v1H7l-.833 2H5v1h17v-1h-1.167L20 36h.5v-1h-.917l-.833-2H22V17.775c0-1.492-.372-2.709-1.202-3.55-.832-.843-2.041-1.225-3.533-1.225h-2.956l1.5-3H18V9Zm-3.31 1h-2.38l1.19 2.382 1.19-2.381Zm3.81 25-.833-2H9.333L8.5 35h10ZM6 17.775c0-1.338.334-2.259.915-2.847.58-.588 1.489-.928 2.82-.928h7.53c1.332 0 2.241.34 2.821.928.581.588.915 1.509.915 2.847V24H6v-6.225ZM6 32h15v-7H6v7Zm4-15v-1h7v1h-7Zm.5 11a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM7.25 38l.833-2h10.834l.833 2H7.25ZM16 28.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM26 12.5a2 2 0 0 0-2 2V39h4V14.5a2 2 0 0 0-2-2Zm-1 2a1 1 0 1 1 2 0V38h-2V14.5Zm5 0a2 2 0 1 1 4 0V39h-4V14.5Zm2-1a1 1 0 0 0-1 1V38h2V14.5a1 1 0 0 0-1-1Zm6.414 23.625-.962-.17L39.5 26.047l2.048 10.908-.962.17.174.985.973-.172.185.987.983-.184-.183-.977.996-.175-.174-.985-1.007.178-2.524-13.444 1.145-6.097.966.182.563-3-.966-.181.184-.983-.982-.184-1.419 7.554-1.419-7.554-.983.184.185.983-.965.18.563 3 .965-.18 1.145 6.096-2.524 13.444-1.007-.178-.174.985.996.175-.183.977.983.184.185-.987.973.172.174-.985Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainSkiLarge;
