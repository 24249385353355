import type { SVGProps } from "react";
const SvgIr56 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-56_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.554L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.02 4.216 5.935h4.598l-3.124-3.434h1.92Zm18.12-8.13v2.059h-4.65L38.955 8.9c.557-.591 1.27-.887 2.139-.887.852 0 1.594.23 2.225.689.937.692 1.405 1.742 1.405 3.152 0 1.383-.5 2.479-1.5 3.286-.778.634-1.71.95-2.793.95-1.221 0-2.202-.328-2.944-.985s-1.134-1.546-1.176-2.665h2.265c.037.407.168.752.395 1.037.352.45.841.673 1.467.673.605 0 1.084-.219 1.437-.657.342-.428.513-.947.513-1.56 0-.665-.179-1.197-.537-1.596-.358-.398-.834-.597-1.428-.597-.726 0-1.292.327-1.697.982l-2.06-.032 1.097-6.454H44.1Zm5.461 5.544c-.552 0-.992.231-1.318.696-.295.412-.442.903-.442 1.473 0 .565.15 1.059.45 1.48.326.47.774.705 1.342.705.552 0 .997-.224 1.334-.673.315-.422.473-.92.473-1.496 0-.565-.142-1.048-.426-1.45-.347-.49-.818-.736-1.413-.736Zm3.907-2.654h-2.194c-.205-.892-.71-1.338-1.516-1.338-.852 0-1.457.525-1.815 1.576-.147.427-.265 1.074-.355 1.94.347-.47.717-.808 1.11-1.014.391-.206.863-.309 1.416-.309 1.083 0 1.954.386 2.612 1.156.626.73.94 1.643.94 2.74 0 1.305-.422 2.353-1.263 3.144-.758.713-1.669 1.07-2.731 1.07-1.363 0-2.423-.516-3.181-1.549-.758-1.032-1.136-2.472-1.136-4.32 0-1.974.405-3.52 1.215-4.64.784-1.082 1.855-1.624 3.212-1.624 1.279 0 2.258.43 2.936 1.291.39.496.64 1.122.75 1.877Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIr56;
