import type { SVGProps } from "react";
const SvgPictureMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 3.25h-.5v29.5h29.5V3.25h-29Zm.5 22.5V4.25h27.5v21.5H30.3l-5.859-10.985-.334-.626-.477.525-7.17 7.887-5.63-4.927-.47-.411-.299.548-4.358 7.989H4.25Zm0 1v5h27.5v-5H4.25Zm6.25-18a1.749 1.749 0 1 0 0 3.5 1.749 1.749 0 1 0 0-3.5ZM7.75 10.5c0-1.52 1.23-2.75 2.75-2.75s2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75-2.75-1.23-2.75-2.75Zm21.417 15.25-5.274-9.889-7.023 7.725-.33.363-.37-.323-5.53-4.839-3.798 6.963h22.325Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPictureMedium;
