import type { SVGProps } from "react";
const SvgEv80Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-80-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm1.962 5.371c-.54-.185-.955-.489-1.245-.912-.286-.423-.429-.936-.429-1.54 0-1.004.413-1.806 1.238-2.403.73-.53 1.597-.794 2.602-.794 1.143 0 2.077.315 2.801.944.688.593 1.032 1.325 1.032 2.198 0 .609-.146 1.13-.437 1.563-.29.434-.703.749-1.237.944.418.122.759.278 1.023.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.452 2.119-1.357 2.817-.761.587-1.74.88-2.936.88-1.296 0-2.327-.322-3.094-.967-.799-.672-1.198-1.553-1.198-2.642 0-1.021.354-1.812 1.063-2.373.323-.248.68-.423 1.071-.524Zm2.135-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.317.286.74.428 1.27.428.528 0 .946-.142 1.253-.428.307-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.735-.405-1.285-.405Zm-.016 4.721c-.572 0-1.04.178-1.405.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.85.532 1.436.532.667 0 1.167-.196 1.5-.587.323-.376.484-.81.484-1.301 0-.561-.185-1.021-.555-1.381-.355-.35-.844-.524-1.468-.524Zm8.897-6.522c.883 0 1.66.27 2.332.81.667.539 1.15 1.37 1.452 2.49.228.858.342 1.789.342 2.794 0 1.666-.297 3.036-.889 4.11-.35.624-.807 1.105-1.373 1.444a3.567 3.567 0 0 1-1.88.508c-.947 0-1.756-.286-2.428-.857-1.196-1.016-1.793-2.75-1.793-5.205 0-1.746.312-3.148.936-4.205a3.782 3.782 0 0 1 1.388-1.397 3.8 3.8 0 0 1 1.913-.492Zm-.088 1.96c-.761 0-1.28.439-1.555 1.317-.217.698-.325 1.677-.325 2.936 0 1.11.111 2.002.333 2.674.286.873.815 1.309 1.587 1.309.64 0 1.116-.338 1.428-1.016.312-.677.468-1.71.468-3.102 0-1.185-.11-2.119-.333-2.8-.285-.879-.82-1.318-1.603-1.318Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv80Negative;
