import type { SVGProps } from "react";
const SvgExclamationPointSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.905 9.592V5.436h-1.791v4.156l.446 6.031h.88l.465-6.031ZM12.998 19h-1.995v-1.911h1.995V19Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgExclamationPointSmall;
