import type { SVGProps } from "react";
const SvgIc74Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-74-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-74-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.437.034v1.962c-.063.068-.25.282-.562.64-.432.496-.862 1.12-1.29 1.875a12.805 12.805 0 0 0-.996 2.16c-.5 1.44-.804 3.082-.91 4.928H38.24c.015-.87.166-1.836.45-2.896.29-1.06.689-2.12 1.195-3.18.722-1.497 1.455-2.612 2.199-3.345h-5.458V4.234h7.76Zm9.405 6.969v1.954h-1.456v2.642H50.08v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.663.024V6.922l-2.974 4.303h2.974Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-74-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc74Negative;
