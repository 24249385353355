import type { SVGProps } from "react";
const SvgAerosolCanMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.756 3.25a3.5 3.5 0 0 0-3.5 3.458l-5.598 1.05-.408.077v2.328l.405.078 5.886 1.133a3.454 3.454 0 0 0-.285 1.376 3.5 3.5 0 0 0 6.969.469 3.5 3.5 0 0 0 0-6.938 3.502 3.502 0 0 0-3.469-3.031Zm-2.5 3.5a2.5 2.5 0 0 1 5 0v.5h.5a2.5 2.5 0 0 1 0 5h-.5v.5a2.5 2.5 0 0 1-5 0c0-.538.174-1.034.467-1.445l.451-.634-.764-.147-6.16-1.187v-.672l5.634-1.058.456-.085-.051-.461a5.83 5.83 0 0 0-.019-.149c-.008-.065-.014-.106-.014-.162ZM9.25 6.25h4v4h-1.5v1.998h.624a3.874 3.874 0 0 1 3.875 3.875l.004 16.128v.5h-10v-.5l-.007-16.128a3.874 3.874 0 0 1 3.875-3.875h.629V10.25h-1.5v-4Zm1 1v2h2v-2h-2Zm-3.004 8.873a2.874 2.874 0 0 1 2.875-2.875h2.253a2.874 2.874 0 0 1 2.875 2.875v.627H7.246v-.627Zm0 1.627.005 11.002h8.001L15.25 17.75H7.246Zm.007 14-.001-1.998h8l.001 1.999h-8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAerosolCanMedium;
