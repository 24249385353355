import type { SVGProps } from "react";
const SvgSaZm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={33}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.803 1H4.221l-.48 2.206h6.101L1.44 12.794 1 15h9.802l.46-2.206H4.901l8.422-9.588.48-2.206Zm6.78 0H15.96L13 15h2.66l2.56-11.594h.04L19.382 15h2.68l6.321-11.594h.04L25.783 15h2.96l2.981-14h-4.66l-5.502 10.09h-.04L20.582 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaZm;
