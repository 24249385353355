import type { SVGProps } from "react";
const SvgTickSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m19.353 7.363-9 8.99-.353.354-.354-.354-4-4 .708-.707L10 15.293l8.647-8.637.706.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTickSmall;
