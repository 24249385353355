import type { SVGProps } from "react";
const SvgLocationPinASmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 4.002a6 6 0 0 0-6 6v.02c.041 1.039.35 1.913.836 2.722l.007.01 4.75 7.5.422.666.422-.666 4.751-7.5.007-.009.005-.01c.473-.829.79-1.72.8-2.728v-.005a6 6 0 0 0-6-6Zm-4.31 8.223c-.409-.684-.655-1.392-.69-2.233A5 5 0 0 1 17 10c-.008.787-.253 1.508-.663 2.23l-4.322 6.822-4.324-6.827ZM11.984 6.9l1.193 3.217h-2.391L11.983 6.9Zm3.268 6.105-2.728-7.007h-1.036l-2.74 7.007h.974l.797-2.143h2.93l.778 2.143h1.025Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinASmall;
