import type { SVGProps } from "react";
const SvgBrochureMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m3.25 4.556.658.22L17.25 9.223l13.342-4.447.658-.22V28.11l-.342.114-13.5 4.5-.158.053-.158-.053-13.5-4.5-.342-.114V4.556Zm1 1.388V27.39l12.5 4.166V10.11L4.25 5.944Zm13.5 4.166v21.446l12.5-4.166V5.944l-12.5 4.166Zm-2.908 3.864-9-3 .316-.949 9 3-.316.95Zm4.816 0 9-3-.316-.949-9 3 .316.95Zm0 4.5 9-3-.316-.949-9 3 .316.95Zm9 1.5-9 3-.316-.95 9-3 .316.95Zm-9 7.5 9-3-.316-.95-9 3 .316.95Zm-13.816-12 9 3 .316-.948-9-3-.316.948Zm9 7.5-9-3 .316-.948 9 3-.316.948Zm-9 1.5 9 3 .316-.95-9-3-.316.95Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBrochureMedium;
