import type { SVGProps } from "react";
const SvgShareSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 3a2.5 2.5 0 0 0-2.377 3.277l-5.87 3.44a2.5 2.5 0 1 0 .095 3.466l5.798 3.474a2.5 2.5 0 1 0 .52-.855L8.86 12.325a2.497 2.497 0 0 0-.048-1.776l5.805-3.403A2.5 2.5 0 1 0 16.5 3Zm0 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5 11.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShareSmall;
