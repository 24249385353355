import type { SVGProps } from "react";
const SvgStationLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.315 10.536c12.174-4.837 24.306-4.923 36.374.001l.311.127V42H31v-6H16v6H5V10.66l.315-.124ZM6 11.34V41h9v-6h17v6h9V11.336C29.387 6.683 17.731 6.76 6 11.341Zm17 2.675a7.454 7.454 0 0 0-2.81.752l.993 1.719-.866.5-.992-1.718a7.541 7.541 0 0 0-2.056 2.056l1.718.992-.5.866-1.719-.993a7.456 7.456 0 0 0-.752 2.81H18v1h-1.984a7.455 7.455 0 0 0 .752 2.81l1.72-.993.5.866-1.72.992a7.543 7.543 0 0 0 2.057 2.056l.992-1.718.866.5-.992 1.72a7.458 7.458 0 0 0 2.809.75V27h1v1.983a7.458 7.458 0 0 0 2.81-.75l-.993-1.72.866-.5.992 1.718a7.541 7.541 0 0 0 2.056-2.056l-1.718-.992.5-.866 1.72.992a7.455 7.455 0 0 0 .75-2.81H29v-1h1.984a7.456 7.456 0 0 0-.752-2.809l-1.72.993-.5-.866 1.72-.992a7.541 7.541 0 0 0-2.057-2.056l-.992 1.718-.866-.5.993-1.72a7.454 7.454 0 0 0-2.81-.75V16h-1v-1.984ZM15 21.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Zm8.854.353 3.524-3.523-.707-.707-3.247 3.246-2.133-1.297-.52.854 2.47 1.501.335.204.278-.278Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStationLarge;
