import type { SVGProps } from "react";
const SvgCarProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.077 7.17A.502.502 0 0 1 6.454 7l-.377.17Zm0 0L3.602 10H9V7H6.454M10 7v3h6.793L13.94 7.147A.5.5 0 0 0 13.584 7H10Zm8 4H3v4h1.05a2.502 2.502 0 0 1 4.9 0h5.1a2.502 2.502 0 0 1 4.9 0H21l-.002-3.498c0-.215-.082-.316-.17-.38a.694.694 0 0 0-.344-.12L18 10.998Zm.95 5a2.502 2.502 0 0 1-4.9 0h-5.1a2.502 2.502 0 0 1-4.9 0H2v-5.124a1.5 1.5 0 0 1 .372-.989l2.953-3.375A1.502 1.502 0 0 1 6.454 6h7.131a1.5 1.5 0 0 1 1.062.44l3.56 3.56 2.29.002h.019c.243.01.595.089.9.312.33.24.582.632.582 1.187L22 15.5v.5h-3.05ZM6.5 14a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3.002Zm10 0a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileSmall;
