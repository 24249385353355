import type { SVGProps } from "react";
const SvgArchiveBoxMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m21.75 3.4.39.488L24.39 6.7h6.86v18.667l-.1.133-4.5 6-.15.2H4.75V13.034l.1-.134 4.5-6 .15-.2h6.31l1.05-1.312.39-.488.39.488 1.26 1.574 2.46-3.074.39-.488Zm5.793 7.243L25.19 7.7h4.56l-2.207 2.943Zm-.62.826L21.75 5l-2.21 2.761 3.95 4.938H26l.924-1.231ZM25.75 13.7h-20v17h20v-17Zm1 16 3.5-4.666V8.7l-3.5 4.667v16.334Zm-4.54-17-4.96-6.2-4.96 6.2h9.92Zm-11.2 0 4-5H10l-3.75 5h4.76Zm.99 5.5h7.5v-1H12v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArchiveBoxMedium;
