import type { SVGProps } from "react";
const SvgParliamentSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 2H11v3.035A3.5 3.5 0 0 0 8 8.5v2.862l-1.686.674-.314.126V15H3v6H2v1h19v-1h-1v-6h-3v-2.838l-.314-.126L15 11.36V8.5a3.5 3.5 0 0 0-3-3.465V4h2V2h-2.5ZM16 15v-1H7v1h9ZM6 16H4v5h2v-5Zm1 0h1v5H7v-5Zm2 0v5h1l-.001-2.5a1.5 1.5 0 0 1 3 0V21H14v-5H9Zm6 0h1v5h-1v-5Zm2 0h2v5h-2v-5Zm-5 5h-1l-.001-2.5a.5.5 0 0 1 1 0V21Zm4-8.161V13H7v-.161l4.5-1.8 4.5 1.8Zm-2-1.878V9H9v1.962l2.314-.926.186-.075.186.075L14 10.96ZM11.5 6a2.5 2.5 0 0 1 2.45 2h-4.9a2.5 2.5 0 0 1 2.45-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgParliamentSmall;
