import type { SVGProps } from "react";
const SvgIr67Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-67-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.554L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.02 4.216 5.935h4.598l-3.124-3.434h1.92Zm14.684-2.593c-.553 0-.994.232-1.32.696-.296.41-.444.901-.444 1.47 0 .565.15 1.058.452 1.48.326.47.775.704 1.344.704.554 0 1-.224 1.337-.672.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.426-1.448-.349-.49-.82-.735-1.417-.735Zm3.916-2.65h-2.2c-.205-.891-.71-1.337-1.518-1.337-.854 0-1.46.525-1.819 1.574-.148.427-.266 1.073-.356 1.938.348-.47.719-.807 1.111-1.013.393-.205.866-.308 1.42-.308 1.086 0 1.96.385 2.618 1.155.628.728.942 1.64.942 2.737 0 1.302-.422 2.35-1.266 3.14-.76.712-1.672 1.068-2.737 1.068-1.365 0-2.428-.516-3.188-1.547-.759-1.03-1.139-2.469-1.139-4.314 0-1.973.406-3.518 1.218-4.636.786-1.08 1.86-1.621 3.22-1.621 1.281 0 2.262.43 2.942 1.289.39.496.641 1.12.752 1.875Zm8.923-2.888v1.962c-.064.069-.251.282-.562.641-.433.496-.862 1.119-1.29 1.87a12.865 12.865 0 0 0-.996 2.164c-.501 1.44-.804 3.082-.91 4.928h-2.388c.015-.87.167-1.835.454-2.895.288-1.06.685-2.12 1.19-3.18.723-1.498 1.456-2.613 2.2-3.346h-5.458V4.235h7.76Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIr67Negative;
