import type { SVGProps } from "react";
const SvgTicketDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.646 21.354 19.292 20H2V5h2.292L2.646 3.354l.707-.707 18 18-.707.707ZM16.792 17.5l1.5 1.5H17v-.5h-1v.5H3V6h2.292l11 11H16v.5h.792ZM21.5 5H9v1h12v3.034a3.463 3.463 0 0 0-3 3.466 3.462 3.462 0 0 0 3 3.466V18h1v-3h-.5a2.467 2.467 0 0 1-2.5-2.5c0-1.406 1.093-2.5 2.5-2.5h.5V5h-.5ZM16 6.6v-.583h1V6.6h-1Zm0 2.327V7.763h1v1.164h-1Zm0 2.327v-1.163h1v1.163h-1ZM16 13v-.582h1V13h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketDisabledSmall;
