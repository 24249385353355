import type { SVGProps } from "react";
const SvgSaWf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.035 7.45a3.167 3.167 0 0 0-3.668-2.573 3.168 3.168 0 0 0-2.506 2.29H1v1.666h4.862a3.166 3.166 0 0 0 6.173-1.384ZM8.642 6.438a1.585 1.585 0 0 1 .55 3.123 1.585 1.585 0 0 1-.55-3.123Zm9.119.678H12.5v1.666h5.26v1.41L20 7.95l-2.24-2.243v1.41Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaWf;
