import type { SVGProps } from "react";
const SvgTicketMachineTicketMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.75 3.25h17.5v23.5h-1.5v6H6.25v-6h-1.5V3.25Zm2.5 23.5h12.5v5H7.25v-5Zm14-1H5.75V4.25h15.5v21.5ZM7.5 7.25h12v-1h-12v1ZM18.25 15v-4.5h1V15h-1Zm-10-4.25h-.5v7h8.5v-7h-8Zm.5 6v-5h6.5v5h-6.5Zm15.5-3h8.5v13h-8.5v-13Zm7.5 1h-6.5v11h6.5v-11Zm-4.75 9h3v-1h-3v1Zm3-3h-3v-1h3v1Zm-21.75-1h-.5v2.5h8.5v-2.5h-8Zm.5 1.5v-.5h6.5v.5h-6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketMachineTicketMedium;
