import type { SVGProps } from "react";
const SvgOnboardingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 1.999H6v20h13V2H6.5Zm.5 15V3h11v14H7Zm0 1v3h11v-3H7Zm7 2h-3v-1h3v1Zm-5.854-9.852 2-2 .708.707L9.207 10.5l1.647 1.647-.708.707-2-2-.353-.354.353-.353Zm8.708 0-2-2-.708.706 1.647 1.647-1.647 1.646.708.707 2-2 .353-.353-.353-.354Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgOnboardingSmall;
