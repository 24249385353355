import type { SVGProps } from "react";
const SvgNarcoticSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 4.998 20.001 2h-1v2.998H18V7h-1v11h2v3h-2v1h5v-1h-2v-3h2V7h-1V4.998h-1Zm-9.323-1.256a2.533 2.533 0 1 1 3.583 3.582l-3.113 3.113-3.583-3.582 3.113-3.113Zm-3.82 3.82-3.114 3.113a2.533 2.533 0 0 0 2.141 4.3l.11-.02a2.534 2.534 0 0 0 1.332-.697l.49-.49.54-.54.107-.107 1.977-1.977-3.583-3.582Zm8.11.469-4.994 4.994A4.5 4.5 0 1 1 5 17.5c0-.533.098-1.04.27-1.51a3.533 3.533 0 0 1-2.234-6.022L9.97 3.035a3.533 3.533 0 1 1 4.997 4.996Zm-6.013 6.013-.432.433-.488.488a3.512 3.512 0 0 1-1.626.926 3.45 3.45 0 0 0-.37 1.111h6.927a3.501 3.501 0 0 0-4.011-2.958Zm4.01 3.958H6.036a3.501 3.501 0 0 0 6.928 0ZM20 5.999V7h-1V5.998h1ZM18 16v1h3V8h-3v1h2v1h-2v1h2v1h-2v1h2v1h-2v1h2v1h-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgNarcoticSmall;
