import type { SVGProps } from "react";
const SvgTranslateMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 4.75h-.5v14.5h4.5v4.188l.854-.853 3.646-3.647V29.75h11.856l3.29 3.29.854.854V29.75h4.5v-14.5h-9V4.75h-20Zm19 10.5v-9.5H4.25v12.5h4.5v2.774l3.5-3.5V15.25h10.5Zm-9.5 1h18.5v12.5h-4.5v2.73l-2.583-2.584-.146-.146H13.25v-12.5ZM6 9.25h15v1H6v-1Zm12 3H6v1h12v-1Zm-3 7.5h15v1H15v-1Zm15 3H15v1h15v-1Zm-26.75 6.5V22.5h1v6.25h3.982l-1.798-1.798.707-.707 2.652 2.652.354.354-.354.353-2.652 2.65-.707-.706 1.798-1.798H3.25v-.5Zm24.517-21.5h4.983v5.75h-1V8.75h-3.982l1.797 1.798-.707.707-2.652-2.652-.353-.354.353-.353 2.652-2.65.707.706-1.798 1.798Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTranslateMedium;
