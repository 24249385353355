import type { SVGProps } from "react";
const SvgIc5Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-5-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M44.057 4.235v2.057h-4.644l-.496 2.603a2.807 2.807 0 0 1 2.136-.886 3.678 3.678 0 0 1 2.223.688c.936.69 1.403 1.74 1.403 3.148 0 1.382-.499 2.476-1.497 3.283a4.283 4.283 0 0 1-2.791.949c-1.22 0-2.2-.328-2.94-.985-.742-.656-1.133-1.544-1.175-2.662h2.262c.026.377.163.738.395 1.036a1.917 1.917 0 0 0 2.9.016 2.41 2.41 0 0 0 .513-1.558 2.3 2.3 0 0 0-.536-1.594 1.824 1.824 0 0 0-1.427-.597 1.896 1.896 0 0 0-1.695.98l-2.058-.031 1.096-6.447h6.33ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIc5Negative;
