import type { SVGProps } from "react";
const SvgHierarchySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 4h7v6h-3v2h5v3h3v6h-7v-6h3v-2H8v2h3v6H4v-6h3v-3h5v-2H9V4Zm1 1v4h5V5h-5ZM5 16v4h5v-4H5Zm10 0v4h5v-4h-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHierarchySmall;
