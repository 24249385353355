import type { SVGProps } from "react";
const SvgArrowUpMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.604 7.896-.354-.353-.354.353-6 6 .708.707 5.146-5.146V27h1V9.457l5.146 5.146.708-.707-6-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowUpMedium;
