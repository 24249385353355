import type { SVGProps } from "react";
const SvgTrainProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m15 4.649.277.185 4.5 3 .624.416-.624.416-3.125 2.083H21c3.172 0 5.835 2.047 6.82 4.875l.001.003.004.012 3.146 8.673c.17.353.279.754.279 1.187a2.75 2.75 0 0 1-2.75 2.75h-3.285a3.491 3.491 0 0 1-1.016 2H33v1H3v-1h2.801a3.492 3.492 0 0 1-1.016-2H3v-1h25.5a1.75 1.75 0 0 0 1.75-1.75c0-.27-.069-.529-.188-.77l-.013-.025-.009-.026-.881-2.429H19.75v-6.998h6.832c-1.015-2.073-3.125-3.502-5.582-3.502H3v-1h10.348l-3.125-2.083-.624-.416.624-.416 4.5-3L15 4.649ZM26.983 16.25H20.75v4.998h8.046l-1.813-4.998Zm-8.698 11.998c.112.779.48 1.475 1.016 2H10.7a3.491 3.491 0 0 0 1.015-2h6.571Zm-12.485 0h4.9a2.502 2.502 0 0 1-2.45 2 2.502 2.502 0 0 1-2.45-2Zm13.5 0c.232 1.14 1.241 2 2.45 2s2.218-.86 2.45-2h-4.9ZM15 10.65l3.599-2.4L15 5.852 11.401 8.25 15 10.649Zm-4.25 4.6h7v7h-7v-7Zm1 1v5h5v-5h-5Zm-8.75 0h4.75v5H3v1h5.75v-7H3v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainProfileMedium;
