import type { SVGProps } from "react";
const SvgWheelchairPartiallyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.1 18c0-5.062 1.537-8.763 3.545-11.543l.81.585C5.564 9.662 4.1 13.161 4.1 18c0 4.832 1.46 8.187 3.363 10.967l-.825.565C4.64 26.615 3.1 23.067 3.1 18Zm6.153 3.746c0-2.523 1.46-4.722 3.57-5.796l.454.891c-1.792.912-3.024 2.775-3.024 4.905 0 3.024 2.48 5.5 5.5 5.5 2.188 0 4.094-1.298 4.978-3.168l.904.427c-1.042 2.204-3.29 3.741-5.882 3.741-3.573 0-6.5-2.924-6.5-6.5ZM32.601 18c0-5.068-1.54-8.615-3.539-11.533l-.825.565C30.142 9.814 31.6 13.167 31.6 18c0 4.838-1.463 8.337-3.357 10.957l.81.585c2.01-2.78 3.547-6.481 3.547-11.543ZM15.25 20.25V12h1v7.75h7.5v8.75h-1v-7.75h-7.5v-.5ZM15.5 9a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairPartiallyMedium;
