import type { SVGProps } from "react";
const SvgCloudRainSnowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.18 14.985a2.86 2.86 0 0 1 1.864 1.079c.546.697.777 1.618.706 2.52a3.942 3.942 0 0 1-1.162 2.52c-.72.701-1.757 1.147-3.088 1.147H12c-1.375 0-2.417-.443-3.125-1.156-.701-.707-1.033-1.638-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.581 1.014-1.023 1.758-1.167.007-2.802 2.942-4.359 5.134-3.133 1.329-2.368 3.803-2.955 5.8-2.23 1.064.386 2 1.145 2.555 2.204.482.92.663 2.04.417 3.294Zm-3.313-4.558c-1.686-.612-3.8-.064-4.81 2.226l-.265.601-.509-.416c-1.627-1.334-4.282-.07-3.986 2.403l.066.56H10.8c-.616 0-1.116.303-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.887.493.496 1.265.86 2.415.86h10.5c1.096 0 1.874-.361 2.39-.864.524-.51.81-1.193.863-1.881.054-.693-.128-1.356-.496-1.826-.357-.457-.91-.76-1.687-.73l-.698.028.199-.67c.379-1.274.233-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728ZM25.5 25.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm3 5.5a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM9.303 23.777l-1.5 3 .894.447 1.5-3-.894-.447Zm4.5 3 1.5-3 .894.447-1.5 3-.894-.447Zm-1.5-3-3 6 .894.447 3-6-.894-.447Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSnowMedium;
