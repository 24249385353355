import type { SVGProps } from "react";
const SvgIc21Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-21-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M44.632 13.743V15.8h-8.301c-.003-.612.1-1.22.303-1.796a4.374 4.374 0 0 1 .856-1.471 7.94 7.94 0 0 1 .997-.925c.38-.301 1.14-.852 2.274-1.654.448-.286.84-.65 1.16-1.076.223-.342.339-.745.33-1.154.02-.453-.14-.896-.445-1.23a1.538 1.538 0 0 0-1.17-.471c-1.188 0-1.8.857-1.838 2.57h-2.215v-.252c0-1.403.392-2.49 1.175-3.26.762-.748 1.747-1.123 2.956-1.123a4.008 4.008 0 0 1 2.688.918c.783.664 1.175 1.558 1.175 2.682a3.691 3.691 0 0 1-.938 2.53c-.175.204-.367.39-.576.558-.178.14-.649.476-1.41 1.009-.726.506-1.245.894-1.558 1.163a6.22 6.22 0 0 0-.87.925h5.407Zm6.607-9.508V15.8h-2.302V8.23h-2.712V6.553a5.798 5.798 0 0 0 1.375-.174c.353-.1.684-.266.974-.49.426-.328.712-.879.86-1.654h1.805ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIc21Negative;
