import type { SVGProps } from "react";
const SvgSaSn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.307 14.267c-.35-.529-.64-1-.87-1.407-.232-.411-.528-1.02-.894-1.833C1.183 10.215 1 9.207 1 8.004c0-1.167.196-2.207.59-3.123.393-.913.967-1.954 1.717-3.12h1.274a33.18 33.18 0 0 0-.947 1.75 25.026 25.026 0 0 0-.78 1.831c-.303.776-.455 1.669-.455 2.68 0 1.035.164 1.97.499 2.805.333.836.59 1.442.771 1.814.183.373.486.913.91 1.624H3.307v.002Zm6.782-4.478L8.18 7.64l-.855-1.028a3.595 3.595 0 0 1-.692-2.163c0-1.094.403-2.126 1.21-3.1l.199-.215L8.14 1l4.84 5.462-.005-2.067 3.742-3.393.887.953L14.28 4.97l.002 1.59 4.101-3.758.915.979-4.127 3.753 1.616.104L20.1 4.647l.908.937-3.725 3.393-2.2-.143 4.145 4.674L17.52 15l-4.284-4.835-.093-.343-.106.325L7.724 15l-1.527-1.652 3.892-3.56ZM8.214 3.474a1.954 1.954 0 0 0-.128.425 2.487 2.487 0 0 0-.035.406c0 .664.246 1.278.741 1.844l.51.576 1.834 2.095 1.086-.972-3.856-4.38-.134-.355-.018.361ZM23.04 1.76c.69 1.07 1.248 2.081 1.673 3.032.423.95.636 2.02.636 3.212 0 .59-.06 1.173-.18 1.75a8.23 8.23 0 0 1-.52 1.632 15.05 15.05 0 0 1-.627 1.291c-.193.35-.522.878-.982 1.59h-1.27a27.9 27.9 0 0 0 .945-1.741 27.65 27.65 0 0 0 .772-1.823c.309-.787.465-1.694.465-2.716 0-1.023-.16-1.947-.482-2.77-.321-.824-.581-1.432-.78-1.823-.201-.39-.507-.936-.92-1.634h1.273Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSn;
