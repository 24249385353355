import type { SVGProps } from "react";
const SvgTicketStarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 4h20v5h-.5c-1.424 0-2.5 1.076-2.5 2.5s1.076 2.5 2.5 2.5h.5v5H2V4Zm1 1v13h13v-.465h1V18h4v-3.034c-1.727-.232-3-1.66-3-3.466 0-1.806 1.273-3.234 3-3.467V5h-4v.464h-1V5H3Zm13 2.32v-.928h1v.928h-1Zm0 1.857V8.25h1v.928h-1Zm0 1.857v-.928h1v.929h-1Zm0 1.858v-.929h1v.929h-1Zm0 1.857v-.929h1v.929h-1Zm0 1.857v-.929h1v.929h-1Zm-6.53-9.78L9 5.548l-.47 1.28L7.55 9.5H3.6l1.083.887 1.923 1.573-1.074 2.864-.544 1.452 1.29-.86L9 13.6l2.723 1.815 1.268.845-.521-1.432-1.075-2.957 1.91-1.477 1.16-.895H10.45l-.98-2.672Zm-1.1 3.345L9 8.452l.63 1.72.12.328h1.786l-1.04.804-.29.223.125.344.68 1.867-1.733-1.154L9 12.399l-.277.185-1.71 1.14.655-1.749.126-.335-.277-.227L6.4 10.5h1.85l.12-.328Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketStarSmall;
