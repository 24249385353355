import type { SVGProps } from "react";
const SvgSwisspassSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 8.5A1.5 1.5 0 0 1 5.5 7h13A1.5 1.5 0 0 1 20 8.5v7a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 15.5v-7ZM5.5 6A2.5 2.5 0 0 0 3 8.5v7A2.5 2.5 0 0 0 5.5 18h13a2.5 2.5 0 0 0 2.5-2.5v-7A2.5 2.5 0 0 0 18.5 6h-13ZM17 9v1h1v1h-1v1h-1v-1h-1v-1h1V9h1Zm-8.5 3a1.5 1.5 0 1 1 .001-3 1.5 1.5 0 0 1-.001 3Zm1.741.294a2.5 2.5 0 1 0-3.481 0c-.491.272-.89.623-1.185 1.065C5.177 13.958 5 14.68 5 15.5v.5h7v-.5c0-.821-.177-1.543-.575-2.142a3.306 3.306 0 0 0-1.184-1.064ZM8.5 13h-.903c-.559.233-.94.54-1.19.914-.193.29-.323.647-.378 1.086h4.942c-.055-.44-.185-.797-.379-1.088-.248-.375-.63-.682-1.188-.912H8.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSwisspassSmall;
