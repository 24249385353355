import type { SVGProps } from "react";
const SvgChevronSmallRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m10.678 7.74 3.949 3.904.36.356-.36.356-3.95 3.904-.703-.712L13.564 12l-3.59-3.548.704-.712Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallRightSmall;
