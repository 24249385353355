import type { SVGProps } from "react";
const SvgRobotSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 0 0-.5 2.915V6H6v2H4v5h2v3h13v-3h2V8h-2V6h-6V4.915A1.5 1.5 0 0 0 12.5 2ZM19 9v3h1V9h-1ZM6 9v3H5V9h1Zm1-2v8h11V7H7ZM3 19.175C3 17.945 4.05 17 5.281 17H19.72c1.23 0 2.281.945 2.281 2.175V22h-1v-2.825c0-.62-.544-1.175-1.281-1.175H5.28C4.544 18 4 18.555 4 19.175V22H3v-2.825Zm6.065-6.928C9.675 13.322 11 14 12.5 14c1.501 0 2.824-.678 3.435-1.753l-.87-.494C14.672 12.447 13.73 13 12.5 13c-1.229 0-2.171-.553-2.565-1.247l-.87.494ZM10.5 10.2a.2.2 0 1 0 0-.4.2.2 0 0 0 0 .4Zm-.8-.2a.8.8 0 1 1 1.6 0 .8.8 0 0 1-1.6 0Zm5 0a.2.2 0 1 1-.4 0 .2.2 0 0 1 .4 0Zm-.2-.8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRobotSmall;
