import type { SVGProps } from "react";
const SvgTrainStationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.25 4.75h9v1h-1.98l-2.102 3.209-.19.291h4.486c1.02 0 1.87.216 2.46.78.595.57.825 1.397.825 2.387V26.75H19.25v2h3.502v-4.5H26.5V20h-.75v-4.75h4V20H29v4.25h2.5v1h-7.748v4.5H4.5v-1h1.75v-2h-1.5V12.417c0-.99.23-1.818.825-2.387.59-.564 1.44-.78 2.461-.78h4.486l-.19-.291L10.23 5.75H8.25v-1Zm4.5 3.022L11.425 5.75h2.65L12.75 7.772Zm-6.484 2.981c-.317.303-.517.81-.517 1.664v5.833h14v-5.833c0-.854-.2-1.361-.516-1.664-.322-.308-.864-.503-1.77-.503H8.037c-.905 0-1.448.195-1.77.503ZM19.75 19.25h-14v6.5h14v-6.5Zm-1.499 9.5v-2h-11v2h11Zm9.497-15.5a.251.251 0 0 0-.25.25.25.25 0 1 0 .25-.25Zm-1.25.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM15 12.25h-4.5v1H15v-1Zm1.25 10.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-7.5 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM27 19h-.25v-2.75h2V19H28v4.5h-.5V19H27Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainStationMedium;
