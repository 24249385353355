import type { SVGProps } from "react";
const SvgHammerWrenchSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.494 4.03c-.307.062-.663.23-1.043.61L4.623 7.467l.353.353.572.572-.767.256-1.354.451 1.684 1.685.452-1.354.255-.768.572.572.354.354 2.121-2.121-.707-.707-.353-.354.353-.354 1.718-1.717a2.469 2.469 0 0 0-.506-.239 1.695 1.695 0 0 0-.876-.067Zm2.14.256.353-.354.353.354-.353.353-1.768 1.768.707.707.354.354-.354.353-.354.354 2.566 2.566 2.203-2.204c-.605-1.47-.269-3.32.85-4.385 1.18-1.122 3.179-1.494 4.71-.67l.59.319-.474.475-2.36 2.36.707.707 2.36-2.36.475-.475.319.592c.824 1.53.452 3.53-.67 4.71-1.065 1.118-2.915 1.454-4.385.849l-2.204 2.203 5.03 5.03.354.354-.353.353-1.414 1.415-.354.353-.353-.354-5.03-5.03-5.025 5.025-.354.353-.353-.353-1.415-1.415-.353-.353.353-.354 5.025-5.024-2.566-2.566-.353.354-.354.353-.354-.353-.135-.135-.45 1.353-.257.768-.572-.572-2.828-2.829-.572-.572.767-.255 1.354-.452-.135-.135-.354-.353.354-.354 3.182-3.182c.504-.504 1.032-.778 1.554-.882a2.693 2.693 0 0 1 1.388.098 3.572 3.572 0 0 1 1.268.753l.022.02.007.007.002.002v.001h.001l-.353.355Zm.09 7.869.707-.707-2.566-2.566-.707.707 2.566 2.566Zm2.121 2.121 4.677 4.677.707-.707-4.677-4.677-.707.707Zm3.035-9.4c.725-.69 1.877-.996 2.884-.762l-2.168 2.168-.353.354.353.354 1.415 1.414.353.353.354-.353 2.168-2.168c.234 1.007-.073 2.158-.763 2.884-.822.864-2.39 1.11-3.528.496l-.328-.176-.263.263-9.244 9.244-.707-.707 9.244-9.244.263-.263-.176-.328c-.613-1.137-.368-2.706.496-3.528Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHammerWrenchSmall;
