import type { SVGProps } from "react";
const SvgCircleTriangleSquareMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 4.25a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Zm-6.5 5.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm16.5-2h13v13h-13v-13Zm1 1v11h11v-11h-11Zm-6.071 11.993-.429-.715-.429.715-6.75 11.25-.454.757h15.266l-.454-.757-6.75-11.25Zm-.429 1.229 5.867 9.778H8.383l5.867-9.778Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleTriangleSquareMedium;
