import type { SVGProps } from "react";
const SvgLocomotiveProfileMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.702 2.416.41-1.056-1.057.41a4.001 4.001 0 1 0 5.176 5.175l.41-1.056-1.057.41a3 3 0 0 1-3.882-3.882ZM15.5 5.5a3 3 0 0 1 1.007-2.243 4 4 0 0 0 4.235 4.235A3 3 0 0 1 15.5 5.5ZM5.777 4.084 5.5 3.9l-.277.185-3 2-.624.416.624.416L3.849 8H2v1h3.349l.151.101.151-.1h2.687c1.474 0 2.754.803 3.444 2H8v5h5.66l.548 1.509.01.026.012.025c.07.14.108.288.108.44a1 1 0 0 1-1 1H2v1h4.388c.075.369.231.708.45 1H2v1h6.788a2.74 2.74 0 0 0 .1 0H22v-1H10.838c.218-.292.375-.631.45-1h2.05a2 2 0 0 0 2-2 1.98 1.98 0 0 0-.199-.857l-2.094-5.773c-.676-1.954-2.515-3.37-4.707-3.37H7.15l1.626-1.085L9.4 6.5l-.624-.416-3-2ZM8.8 21h.077a1.502 1.502 0 0 0 1.377-1h-2.83c.202.571.74.984 1.376 1Zm4.499-6-1.088-3H9v3h4.298ZM5.5 7.9 3.401 6.5 5.5 5.101l2.099 1.4L5.5 7.898ZM6 12H2v-1h5v5H2v-1h4v-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveProfileMoonSmall;
