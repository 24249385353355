import type { SVGProps } from "react";
const SvgSunshadeSunSandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M27.75 5.75a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-5 4a5 5 0 1 1 10 0 5 5 0 0 1-10 0ZM8.002 12.024c4.543-2.623 10.353-1.067 12.977 3.478l.25.432-.433.25-7.387 4.265 5.65 11.301H33v1H3v-1h14.941l-5.4-10.8-7.335 4.235-.433.25-.25-.434a9.5 9.5 0 0 1 3.479-12.976m.5.865a8.495 8.495 0 0 0-2.1 1.707 7.512 7.512 0 0 0-.087 1.764c.107 1.784.742 3.887 1.862 5.954L5.152 24.06a8.507 8.507 0 0 1 1.25-9.464m2.642 7.218 6.916-3.993c-1.166-1.893-2.568-3.37-3.91-4.256-1.47-.97-2.723-1.151-3.547-.676m8.323 4.432c-1.23-2.004-2.733-3.605-4.225-4.59a7.504 7.504 0 0 0-1.572-.806 8.506 8.506 0 0 1 8.821 3.65l-3.024 1.746Zm-7.782 4.493c-1.057-1.956-1.634-3.909-1.73-5.514-.106-1.758.364-2.935 1.188-3.41l-.25-.433.25.432"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunshadeSunSandMedium;
