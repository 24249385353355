import type { SVGProps } from "react";
const SvgCigaretteDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17 4.469c0-1.378 1.122-2.5 2.5-2.5S22 3.09 22 4.469c0 .753-.35 1.468-.94 1.94l-.284.228.128.34c.063.166.096.343.096.523 0 .826-.674 1.5-1.5 1.5a1.503 1.503 0 0 1-1.404-2.024l.128-.34-.283-.226A2.497 2.497 0 0 1 17 4.469Zm2.5-3.5c-1.93 0-3.5 1.57-3.5 3.5a3.49 3.49 0 0 0 1.059 2.494A2.503 2.503 0 0 0 19.5 10a2.503 2.503 0 0 0 2.441-3.037A3.49 3.49 0 0 0 23 4.47c0-1.93-1.57-3.5-3.5-3.5Zm2.646 21.885L14.293 15H2v-4h8.293L1.146 1.854l.708-.708 21 21-.708.708ZM11.293 12l2 2H10v-2h1.293ZM8 12h1v2H8v-2Zm-1 2v-2H3v2h4Zm11.75-2H14v-1h8.25v4H17v-1h1.75v-2Zm1 0v2h1.5v-2h-1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCigaretteDisabledSmall;
