import type { SVGProps } from "react";
const SvgSaRt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.541 6.977h-1.02l.78-3.771h1.16c1.14 0 2.401 0 2.401 1.465 0 1.845-1.82 2.306-3.321 2.306ZM7.221 1H3.92L1 15h2.8l1.221-5.816h1.06c1.29 0 1.428.75 1.635 1.886.037.202.077.417.125.641L8.521 15h2.941l-1.34-5.636c-.18-.763-.661-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C12.782.999 9.87 1 7.312 1H7.22Zm18.161 0H14.6l-.479 2.206h3.94L15.521 15h2.92l2.541-11.794h3.94L25.382 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRt;
