import type { SVGProps } from "react";
const SvgCarSignParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 4h5v6H5v11H4V10H2V4Zm1 1v4h3V5H3Zm8 4a2 2 0 0 0-2 2v2h5.05a2.502 2.502 0 0 1 4.9 0H20v-2a2 2 0 0 0-2-2h-7Zm6.915 4a1.502 1.502 0 0 0-2.83 0h2.83ZM9 18v-4h11v4H9Zm-1-7a3 3 0 0 1 3-3h7a3 3 0 0 1 3 3v1h1v1h-1v8h-3.999v-2H12v2H8v-8H7v-1h1v-1Zm10.001 8v1H20v-1h-1.999ZM9 19h2v1H9v-1Zm1-3a1 1 0 1 1 2.002.001A1 1 0 0 1 10 16Zm8-1a1 1 0 1 0 .002 2A1 1 0 0 0 18 15Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarSignParkingSmall;
