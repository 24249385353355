import type { SVGProps } from "react";
const SvgReduceSizeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.25 3.521v-.52h-1v.52h1Zm0 2.083V4.563h-1v1.041h1Zm0 2.084V6.646h-1v1.042h1Zm0 2.083V8.73h-1v1.042h1Zm0 2.083v-1.041h-1v1.041h1Zm0 2.084v-1.042h-1v1.042h1Zm0 2.083V14.98h-1v1.042h1Zm0 2.084v-1.042h-1v1.042h1Zm0 2.083v-1.042h-1v1.042h1Zm0 1.562v-.52h-1v.02h-.02v1h1.02v-.5ZM3 22.25h.52v-1H3v1Zm1.563 0h1.041v-1H4.562v1Zm2.083 0h1.042v-1H6.646v1Zm2.083 0h1.042v-1H8.729v1Zm2.084 0h1.041v-1h-1.041v1Zm2.083 0h1.041v-1h-1.041v1Zm2.083 0h1.042v-1h-1.042v1Zm2.084 0h1.041v-1h-1.041v1Zm2.083 0h1.041v-1h-1.041v1Zm5.104 2H30v1h-4.043l5.897 5.896-.708.707-5.896-5.896V30h-1v-5.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgReduceSizeMedium;
