import type { SVGProps } from "react";
const SvgHourglassSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.484 3h-.5v.5c0 3.043 2.064 5.632 4.218 6.932.466.286.814.87.814 1.568 0 .697-.348 1.281-.814 1.569-2.155 1.3-4.218 3.888-4.218 6.93v.5h14.032v-.5c0-3.042-2.064-5.63-4.219-6.93-.465-.288-.813-.871-.813-1.57 0-.697.348-1.28.813-1.567 2.155-1.3 4.22-3.89 4.22-6.932V3H5.483ZM9.72 9.577C7.896 8.477 6.216 6.387 6.006 4h11.988c-.21 2.388-1.89 4.476-3.715 5.577l-.003.002c-.782.48-1.292 1.398-1.292 2.42 0 1.024.51 1.942 1.291 2.423l.004.002c1.825 1.1 3.505 3.188 3.715 5.576H6.006c.21-2.388 1.89-4.476 3.714-5.576l.004-.002c.781-.481 1.292-1.399 1.292-2.422 0-1.024-.51-1.942-1.292-2.421l-.004-.002ZM9.464 7H8.192l.931.866c.35.326.727.615 1.112.85h.002l.008.005c.542.334.986.925 1.298 1.616L12 11.345l.455-1.008c.312-.69.753-1.279 1.29-1.609a7.1 7.1 0 0 0 1.132-.861L15.81 7H9.464Zm2.535 2.098A4.217 4.217 0 0 0 10.965 8h2.069a4.18 4.18 0 0 0-1.035 1.098Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHourglassSmall;
