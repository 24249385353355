import type { SVGProps } from "react";
const SvgCigaretteLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M37.5 2a2.5 2.5 0 0 0-2.496 2.348A3.484 3.484 0 0 0 33 7.5c0 1.405.83 2.6 2.016 3.156-.009.11-.015.227-.015.345 0 1.52.543 2.904 1.43 3.832A3.453 3.453 0 0 0 36 16.5a3.5 3.5 0 1 0 7 0 3.46 3.46 0 0 0-.907-2.332C42.663 13.3 43 12.195 43 11a5.97 5.97 0 0 0-.28-1.8A3.485 3.485 0 0 0 44 6.5a3.5 3.5 0 0 0-4.415-3.378A2.496 2.496 0 0 0 37.5 1.999ZM36 4.498a1.5 1.5 0 0 1 2.884-.575l.193.462.461-.193A2.5 2.5 0 0 1 43 6.5c0 .878-.455 1.648-1.145 2.096l-.341.22.146.379c.211.549.34 1.156.34 1.805 0 1.162-.377 2.19-.956 2.912l-.303.38.37.314A2.48 2.48 0 0 1 42 16.5a2.5 2.5 0 1 1-5 0c0-.542.178-1.042.477-1.456l.27-.374-.351-.3C36.576 13.674 36 12.444 36 11c0-.19.022-.376.047-.596l.002-.014.047-.403-.385-.129a2.49 2.49 0 0 1-1.712-2.359 2.49 2.49 0 0 1 1.683-2.35l.369-.128-.035-.388a1.75 1.75 0 0 0-.008-.065l-.003-.024-.003-.02L36 4.5ZM4 22h38.5a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5H4v-6Zm1 1v4h8v-4H5Zm11 4h-2v-4h2v4Zm1 0h21v-4H17v4Zm25.5 0H39v-4h3.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCigaretteLarge;
