import type { SVGProps } from "react";
const SvgTicketRvSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 2h14v20H5V2Zm1 1v18h12V3H6Zm10 13H8v-1h8v1Zm-8 2h8v-1H8v1Zm4.035-6.304c-.022-.024-.098-.144-.145-.661a12.244 12.244 0 0 0-.092-.83 1.026 1.026 0 0 0-.158-.36.894.894 0 0 0-.27-.267c.137-.086.256-.2.349-.332.16-.24.241-.523.233-.81a1.49 1.49 0 0 0-.164-.7 1.313 1.313 0 0 0-.478-.505 1.928 1.928 0 0 0-1.007-.229H8v5.012h.923V9.97h1.09c.233-.022.466.02.676.12.166.109.267.403.29.853.018.378.034.593.05.692a.755.755 0 0 0 .116.313l.045.065h1.122l-.277-.318ZM10.75 9.045a.779.779 0 0 1-.278.093 3.48 3.48 0 0 1-.527.03H8.923V7.844h1.19a2 2 0 0 1 .488.045.59.59 0 0 1 .252.132.614.614 0 0 1 .173.469.582.582 0 0 1-.276.555Zm3.527 1.71 1.258-3.753h.983l-1.793 5.012h-.92l-.037-.1-1.753-4.912h1.01l1.252 3.754Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketRvSmall;
