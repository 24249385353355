import type { SVGProps } from "react";
const SvgSmartphoneDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m25.25 25.957 5.896 5.897.708-.707-27-27-.708.707 6.604 6.603V29.25a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-3.293Zm-1-1v4.293a1 1 0 0 1-1 1h-10.5a1 1 0 0 1-1-1V12.457l12.5 12.5ZM12.75 6.25h10.5a2 2 0 0 1 2 2V21h-1V8.25a1 1 0 0 0-1-1h-10.5c-.277 0-.558.192-.786.53l-.828-.56c.31-.46.855-.97 1.614-.97ZM15 8.75h6v-1h-6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneDisabledMedium;
