import type { SVGProps } from "react";
const SvgBoatProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.75 7.25h1.75v-1H3v1h1.75v3.5H3v1h16.75v2H3v1h1.75v3.5H3v1h26.677l-5.97 7.111-.93.314a3.26 3.26 0 0 1-2.055 0l-2.13-.705a4.265 4.265 0 0 0-2.684 0l-2.13.705a3.264 3.264 0 0 1-2.056 0l-2.13-.705a4.265 4.265 0 0 0-2.684 0l-.013.005-.013.005-3.315 1.305.366.93 3.302-1.3a3.265 3.265 0 0 1 2.043.005l2.13.705a4.265 4.265 0 0 0 2.684 0l2.13-.705a3.264 3.264 0 0 1 2.056 0l2.13.705a4.265 4.265 0 0 0 2.684 0l.003-.001 1.065-.36.133-.045.09-.108 6.75-8.04.69-.821H17.75v-3.5h3v-4h-9v-3.5Zm5 7.5h-5v3.5h5v-3.5Zm-6 0v3.5h-5v-3.5h5Zm-5-4v-3.5h5v3.5h-5Zm1.485 19.166-3.302 1.3-.366-.931 3.315-1.305.013-.005.013-.005a4.265 4.265 0 0 1 2.684 0l2.13.705a3.264 3.264 0 0 0 2.056 0l2.13-.705a4.265 4.265 0 0 1 2.684 0l2.13.705a3.264 3.264 0 0 0 2.056 0l2.13-.705a4.265 4.265 0 0 1 2.684 0l.013.005.013.005 3.315 1.305-.366.93-3.302-1.3a3.265 3.265 0 0 0-2.043.005l-2.13.705a4.265 4.265 0 0 1-2.684 0l-2.13-.705a3.265 3.265 0 0 0-2.056 0l-2.13.705a4.265 4.265 0 0 1-2.684 0l-2.13-.705a3.265 3.265 0 0 0-2.043-.005Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBoatProfileMedium;
