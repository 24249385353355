import type { SVGProps } from "react";
const SvgFormSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 3c-.857 0-1.39.302-1.693.695-.08.104-.14.209-.183.305H8v1H4v17h16V5h-4V4h-2.124a1.528 1.528 0 0 0-.183-.305C13.39 3.302 12.857 3 12 3Zm4 3v1H8V6H5v15h14V6h-3Zm-5.02-1 .02-.469V4.53a.526.526 0 0 1 .1-.224c.082-.107.299-.305.9-.305s.818.198.9.305a.526.526 0 0 1 .1.224v.002l.02.469H15v1H9V5h1.98ZM7 9v1h10V9H7Zm10 3v1H7v-1h10ZM7 15v1h10v-1H7Zm5 4H7v-1h5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFormSmall;
