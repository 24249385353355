import type { SVGProps } from "react";
const SvgMilkBrickDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.25 3.25h-.5v3.348l-1.716 2.575.832.554 1.384-2.076 2.5 3.75V13.5h1v-1.75h6.5V21h1v-9.901l-.084-.126-2.916-4.374V3.25h-8Zm3.268 7.5h6.298l-2.334-3.5h-6.298l2.334 3.5Zm3.732-4.5h-6.5v-2h6.5v2Zm-3.5 12.207 6.5 6.5v6.793h-6.5V18.457Zm-1-1V31.75h-5V12.457l5 5Zm-5.5 15.293h14v-6.793l5.896 5.897.708-.708-27-27-.708.708 6.604 6.603V32.75h.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMilkBrickDisabledMedium;
