import type { SVGProps } from "react";
const SvgCloudLightningMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m26.444 4.888-.525 1.038c-.974 1.928-.526 4.32.912 5.934 1.413 1.584 3.793 2.419 6.725 1.18l1.17-.495-.52 1.16c-.81 1.807-2.38 2.97-3.962 3.602-1.193.477-2.449.673-3.462.588.024.226.027.455.012.683a3.873 3.873 0 0 1-1.159 2.526c-.728.702-1.778 1.146-3.135 1.146v-1c1.128 0 1.92-.363 2.44-.866a2.873 2.873 0 0 0 .856-1.875c.048-.69-.143-1.354-.52-1.827-.366-.46-.928-.764-1.706-.733l-.698.028.199-.67c.379-1.274.233-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728c-1.686-.612-3.8-.065-4.81 2.225l-.265.602-.509-.417c-1.626-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.887.493.496 1.265.86 2.415.86v1c-1.375 0-2.417-.443-3.125-1.156-.701-.707-1.033-1.638-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.581 1.014-1.023 1.758-1.167.007-2.802 2.943-4.36 5.134-3.134 1.329-2.367 3.803-2.954 5.8-2.23l.173.067c.436-1.274.948-2.198 1.559-2.873.704-.779 1.509-1.194 2.39-1.458l1.114-.333Zm3.43 11.49c-1.263.505-2.521.627-3.35.477a3.278 3.278 0 0 0-.466-.796 2.91 2.91 0 0 0-1.878-1.076c.246-1.253.065-2.374-.417-3.293a4.571 4.571 0 0 0-1.488-1.66c.42-1.277.893-2.112 1.406-2.68.283-.313.586-.553.916-.744-.548 2.073.075 4.334 1.488 5.919 1.47 1.648 3.787 2.567 6.53 1.883-.72.907-1.707 1.557-2.742 1.97ZM17.25 19.75h-.207l-.147.146-4.5 4.5-.853.854H15.5l-1.892 2.522-2.453 3.272 3.17-2.584 4.994-4.073 1.089-.887h-3.451l2.896-2.897.854-.853H17.25Zm-.35 5.3.412-.55h.287l-.737.601.038-.051Zm-2.943-.8h.836l.603-.604 2.897-2.896h-.836l-3.5 3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudLightningMoonMedium;
