import type { SVGProps } from "react";
const SvgDocumentZipMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 6.25h-.5v23.5h17.5V14.043l-.146-.146-7.5-7.5-.147-.147H9.75Zm.5 22.5V7.25h8v7.5h7.5v14h-15.5Zm14.793-15L19.25 7.959v5.793h5.793ZM12.25 7.989V7.5h1v.488h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.462v-.487h1V27h-1Zm1.5-18v.488h1V9h-1Zm0 1.463v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.487h1v-.487h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentZipMedium;
