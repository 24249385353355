import type { SVGProps } from "react";
const SvgIr57 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-57_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-57_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18-8.132V6.29h-4.659l-.498 2.602c.559-.59 1.273-.886 2.143-.886.855 0 1.598.23 2.231.688.939.691 1.408 1.74 1.408 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.18-2.665h2.271c.037.406.169.751.395 1.036.354.448.844.672 1.472.672.606 0 1.086-.218 1.44-.656.342-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.359-.401-.836-.602-1.432-.602-.728 0-1.295.327-1.7.981l-2.065-.031 1.1-6.447h6.351Zm9.31 0v1.962c-.063.068-.25.282-.561.64-.433.496-.863 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-57_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr57;
