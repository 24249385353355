import type { SVGProps } from "react";
const SvgContactSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.5 4H5v4H4v1h1v3H4v1h1v3H4v1h1v3h14V4H5.5ZM6 17v2h12V5H6v3h1v1H6v3h1v1H6v3h1v1H6Zm6-9c-.449 0-1 .511-1 1.5 0 .467.136.858.332 1.121.193.26.43.38.668.38.452 0 1-.505 1-1.49 0-.468-.136-.863-.333-1.128C12.473 8.121 12.237 8 12 8Zm-2 1.5c0-1.25.747-2.5 2-2.5.615 0 1.128.325 1.47.788.341.46.53 1.07.53 1.722 0 .378-.068.754-.197 1.095C15.146 11.53 16 13.411 16 15.5v.5H8v-.5c0-2.088.854-3.97 2.197-4.894A3.113 3.113 0 0 1 10 9.5Zm.732 1.953c-.89.635-1.592 1.928-1.713 3.547h5.963c-.121-1.616-.82-2.908-1.709-3.544A1.747 1.747 0 0 1 12 12c-.503 0-.938-.215-1.268-.547Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgContactSmall;
