import type { SVGProps } from "react";
const SvgTrashLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19 5.47c0-.204.203-.46.57-.46h7.86c.367 0 .57.256.57.46v1.604a136.4 136.4 0 0 0-9 0V5.47Zm10 0v1.64c3.4.138 6.795.402 10.178.793l1.38.16.442.052V12h-2.03l-2.041 32.531-.03.469H10.1l-.029-.469L8.031 12H6V8.115l.442-.052 1.38-.16c3.384-.39 6.78-.655 10.178-.792V5.47c0-.856.756-1.46 1.57-1.46h7.86c.814 0 1.57.604 1.57 1.46ZM9.032 12h28.936l-.126 2H9.158l-.126-2Zm.189 3 1.63 26H36.15l1.63-26H9.221Zm1.693 27h25.172l-.126 2H11.04l-.126-2ZM7.938 8.897 7 9.005V11h33V9.005l-.938-.108a135.497 135.497 0 0 0-31.124 0Zm25.563 8.08-1 22 .998.046 1-22-.998-.046Zm-20.002 0 1 22-.998.046-1-22 .998-.046Zm15.001 0-1 22 1 .046 1-22-1-.046Zm-10 0 1 22-1 .046-1-22 1-.046ZM23 17v22h1V17h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrashLarge;
