import type { SVGProps } from "react";
const SvgChevronSmallEndRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.264 17.645-5.923-5.856-.703.71L16.2 18l-5.565 5.5.703.712 5.925-5.856.36-.356-.36-.355ZM24 13.5v9h-1v-9z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallEndRightMedium;
