import type { SVGProps } from "react";
const SvgCopyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#copy-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M14.227 6.19h-.5v4.56H7.75v19h14.5v-4.56h5.977v-19h-14Zm8.023 18h4.977v-17h-12.5v3.56h7.523v13.44Zm-13.5 4.56v-17h12.5v17H8.75Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="copy-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCopyMedium;
