import type { SVGProps } from "react";
const SvgSaFa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.817 1c1.749 0 3.163 1.441 3.163 3.222H20a3.224 3.224 0 0 1-2.25 3.082c.03.27.05.55.05.831 0 3.793-3.272 6.865-7.3 6.865-4.028 0-7.3-3.072-7.3-6.865 0-.28.01-.56.05-.83A3.211 3.211 0 0 1 1 4.221C1 2.441 2.415 1 4.163 1c1.032 0 1.946.5 2.525 1.28 1.1-.63 2.407-1 3.802-1s2.692.36 3.802 1A3.135 3.135 0 0 1 16.817 1ZM8.516 6.004c0-.55-.443-1.001-.983-1.001s-.982.45-.982 1c0 .551.442 1.001.982 1.001s.983-.45.983-1ZM6.55 10.507c0 1.661 1.758 3.002 3.93 3.002 2.17 0 3.929-1.341 3.929-3.002 0-1.661-1.758-3.002-3.93-3.002-2.17 0-3.93 1.34-3.93 3.002Zm6.385-4.503c0 .55.443 1 .983 1s.982-.45.982-1-.442-1.001-.982-1.001-.983.45-.983 1Zm-.982 4.002c0 .83-.66 1.502-1.474 1.502s-1.473-.672-1.473-1.502c0-.829.66-1.5 1.473-1.5.814 0 1.474.671 1.474 1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaFa;
