import type { SVGProps } from "react";
const SvgSpannerLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M37.198 7.541A6.6 6.6 0 0 0 34.497 7 6.5 6.5 0 0 0 28 13.494a6.34 6.34 0 0 0 .412 2.39L15.007 29.453a6.5 6.5 0 0 0-8.338 8.813l.313.654.5-.525L10.714 35H13v2.287L9.653 40.51l-.52.5.651.311a6.5 6.5 0 0 0 8.812-8.34L32.11 19.467a6.402 6.402 0 0 0 2.482.493h.003a6.5 6.5 0 0 0 5.861-9.165l-.277-.616-.508.445L35.812 14H34v-1.812l3.376-3.859.452-.516-.63-.272ZM34.507 8a5.6 5.6 0 0 1 1.635.222l-3.018 3.449-.124.141V15h3.188l.141-.124 3.458-3.025a5.5 5.5 0 0 1-5.2 7.109 5.4 5.4 0 0 1-2.37-.54l-.32-.155-.25.252-14 14-.25.25.152.319a5.5 5.5 0 0 1-6.644 7.607l2.942-2.833.153-.147V34h-3.714l-.148.155-2.842 2.985a5.5 5.5 0 0 1 7.609-6.639l.32.153.25-.253 13.88-14.05.24-.242-.138-.312A5.34 5.34 0 0 1 29 13.511V13.5A5.5 5.5 0 0 1 34.5 8h.007ZM21.024 27.684l5.66-5.66-.708-.708-5.66 5.66.707.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSpannerLarge;
