import type { SVGProps } from "react";
const SvgArrowCircleTwoUsersMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 18C3.25 9.854 9.854 3.25 18 3.25S32.75 9.854 32.75 18 26.146 32.75 18 32.75c-6.02 0-11.445-3.615-13.75-8.805V28.5h-1v-5.75H9v1H5.26c2.196 4.713 7.193 8 12.74 8 7.594 0 13.75-6.156 13.75-13.75S25.594 4.25 18 4.25 4.25 10.406 4.25 18h-1Zm17-10.25c-1.648 0-2.931 1.338-3.35 3.02-1.848.216-3.15 2.123-3.15 4.23 0 1.192.417 2.32 1.114 3.115-2.462 1.298-4.114 4.506-4.114 8.135v.5h13v-.5c0-.864-.094-1.704-.27-2.5h3.27v-.5c0-3.628-1.652-6.837-4.114-8.135.697-.795 1.114-1.923 1.114-3.115 0-2.237-1.468-4.25-3.5-4.25Zm2.96 15h2.529c-.152-3.368-1.83-6.048-3.946-6.928a3.008 3.008 0 0 1-1.194.408 4.521 4.521 0 0 1-.963 1.885c1.624.856 2.896 2.544 3.573 4.635Zm-4.417-3.928a2.993 2.993 0 0 1-3.086 0c-2.116.88-3.794 3.56-3.946 6.928H22.74c-.152-3.368-1.83-6.048-3.946-6.928ZM20.75 15c0-1.983-1.154-3.79-2.83-4.175.378-1.27 1.33-2.075 2.33-2.075 1.282 0 2.5 1.345 2.5 3.25 0 1.656-.92 2.89-2.003 3.183.002-.06.003-.122.003-.183Zm-3.5-3.25c-1.282 0-2.5 1.345-2.5 3.25s1.218 3.25 2.5 3.25 2.5-1.345 2.5-3.25-1.218-3.25-2.5-3.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTwoUsersMedium;
