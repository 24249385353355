import type { SVGProps } from "react";
const SvgSaMe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={33}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.582 1H3.961L1 15h2.66L6.221 3.406h.04L7.382 15h2.68l6.322-11.594h.039L13.783 15h2.96l2.981-14h-4.66L9.562 11.09h-.04L8.582 1Zm22.46 0H22.82l-2.94 14h8.322l.48-2.206H23.16l.82-3.931h4.902l.44-2.207H24.44l.721-3.45h5.461L31.042 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaMe;
