import type { SVGProps } from "react";
const SvgUserTieSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 6c-1.311 0-2.5 1.268-2.5 3s1.189 3 2.5 3 2.5-1.268 2.5-3-1.189-3-2.5-3ZM8.5 9c0-2.134 1.497-4 3.5-4s3.5 1.866 3.5 4c0 .93-.284 1.807-.77 2.503C17.525 12.894 19 16.35 19 19.5v.5H5v-.5c0-3.133 1.473-6.598 4.27-7.997A4.387 4.387 0 0 1 8.5 9Zm3.685 3.995a3.238 3.238 0 0 1-.371 0l.186.223.185-.223Zm-2.253-.698C7.598 13.332 6.165 16.157 6.013 19h5.28l-.647-.646-.2-.2.069-.275.938-3.754-1.52-1.828ZM12.707 19h5.28c-.151-2.86-1.588-5.677-3.922-6.704l-1.518 1.829.938 3.754.069.275-.2.2-.647.646Zm-.26-1.153L12 16.062l-.446 1.785.446.446.446-.446Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserTieSmall;
