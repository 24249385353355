import type { SVGProps } from "react";
const SvgArrowTurnRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.5 14c-2.884 0-4.728-.86-5.862-2.17C6.492 10.506 6 8.643 6 6.5H5c0 2.276.52 4.412 1.882 5.984C8.255 14.07 10.41 15 13.5 15h3.777l-2.282 2.287.708.707 3.142-3.15.352-.353-.353-.353-3.142-3.141-.707.707L17.292 14z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowTurnRightSmall;
