import type { SVGProps } from "react";
const SvgListPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 8.75h22.5v-1H6v1Zm0 6h22.5v-1H6v1Zm12 12H6v-1h12v1Zm-12-6h12v-1H6v1ZM24.5 24H21v-1h3.5v-3.5h1V23h3v1h-3v3h-1v-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgListPlusMedium;
