import type { SVGProps } from "react";
const SvgClapperboardMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.297 4.25A1.047 1.047 0 0 0 4.25 5.297V9.25h7.441l2.5-5H5.297Zm15.394 5h-7.882l2.5-5h7.882l-2.5 5Zm3.618-5-2.5 5h9.941V5.297a1.047 1.047 0 0 0-1.047-1.047H24.31Zm-20.46-.4c.384-.384.905-.6 1.448-.6h25.406c.543 0 1.064.216 1.448.6l-.354.353.354-.353c.383.383.599.904.599 1.447v4.953H3.25V5.297c0-.543.216-1.064.6-1.447Zm.401 27.94V12.04h-1v20.75h29.5V12.04h-1v19.75H4.25Zm10.24-15.729-.74-.403v10.684l.74-.403 8.25-4.5.804-.439-.805-.439-8.25-4.5ZM21.455 21l-6.706 3.658v-7.316L21.456 21Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClapperboardMedium;
