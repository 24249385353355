import type { SVGProps } from "react";
const SvgSaAt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={29}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1 15 9.463 1h3.101l2.539 14h-2.8l-.48-3.21H5.882L4.102 15H1Zm9.563-11.513h-.08L7.122 9.584h4.32l-.88-6.097ZM27.744 1H16.962l-.48 2.206h3.941l-2.54 11.793h2.92l2.54-11.793h3.941L27.744 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaAt;
