import type { SVGProps } from "react";
const SvgBalloonsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#balloons-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M16.25 13.5c0-2.592.809-4.92 2.093-6.59 1.284-1.669 3.023-2.66 4.907-2.66s3.623.991 4.907 2.66c1.284 1.67 2.093 3.998 2.093 6.59 0 2.592-.809 4.92-2.093 6.59-1.284 1.669-3.023 2.66-4.907 2.66s-3.623-.991-4.907-2.66c-1.284-1.67-2.093-3.998-2.093-6.59Zm7-10.25c-2.258 0-4.27 1.191-5.7 3.05-.307.4-.588.83-.84 1.289a6.785 6.785 0 0 0-3.953-1.339h-.007c-2.257 0-4.268 1.191-5.699 3.05-1.43 1.86-2.301 4.407-2.301 7.2s.87 5.34 2.301 7.2c1.324 1.72 3.146 2.869 5.199 3.03V33h1v-6.294a7.85 7.85 0 0 0 6.064-4.276c1.019.737 2.184 1.202 3.436 1.3V33h1v-9.27c2.054-.161 3.876-1.31 5.2-3.03 1.43-1.86 2.3-4.407 2.3-7.2s-.87-5.34-2.3-7.2c-1.43-1.859-3.442-3.05-5.7-3.05Zm-4.73 18.523a6.85 6.85 0 0 1-5.785 3.977c-1.877-.005-3.61-.996-4.891-2.66-1.285-1.67-2.094-3.998-2.094-6.59 0-2.592.81-4.92 2.094-6.59 1.283-1.668 3.021-2.659 4.902-2.66a5.785 5.785 0 0 1 3.514 1.254c-.645 1.482-1.01 3.188-1.01 4.996 0 2.793.87 5.34 2.3 7.2.3.388.624.747.97 1.073Zm-.144-9.849c.419-2.73 1.919-5.03 4-5.568l.25.968c-1.52.393-2.87 2.203-3.262 4.752l-.988-.152Zm-6.497-2.54c-2.083.522-3.584 2.809-4.003 5.54l.988.152c.391-2.549 1.74-4.342 3.257-4.72l-.242-.971Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="balloons-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBalloonsMedium;
