import type { SVGProps } from "react";
const SvgChartPieSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 12.5a7.5 7.5 0 0 1 7-7.483v7.772l.25.144 6.481 3.742A7.5 7.5 0 0 1 5 12.5Zm15 0a7.469 7.469 0 0 1-.768 3.31L13.5 12.5l5.732-3.31A7.47 7.47 0 0 1 20 12.5Zm-1.269-4.175A7.495 7.495 0 0 0 13 5.016v6.618l5.731-3.31Zm-6.674-4.313a8.5 8.5 0 1 1 .886 16.977 8.5 8.5 0 0 1-.886-16.977Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartPieSmall;
