import type { SVGProps } from "react";
const SvgArrowsLongRightLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m29.59 10.883-4.714-4.711-.707.707 3.86 3.858H6v1h22.03l-3.861 3.87.708.707 4.713-4.724.353-.353-.354-.354Zm-23.18 13.5 4.714-4.711.707.707-3.86 3.858H30v1H7.97l3.861 3.87-.708.707L6.41 25.09l-.353-.353.354-.353Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLongRightLeftMedium;
