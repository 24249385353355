import type { SVGProps } from "react";
const SvgRampUserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 7.5c0-.257.243-.5.5-.5s.5.243.5.5-.243.5-.5.5a.527.527 0 0 1-.5-.5ZM5.5 6C4.69 6 4 6.69 4 7.5S4.69 9 5.5 9 7 8.31 7 7.5 6.31 6 5.5 6Zm-1.207 4h2.516l.138.276.98 1.96L9.4 14.2l-.8.6-1.5-2-.027-.036-.02-.04L7 12.618v2.764l.947 1.894.053.106v2.813l13.35-4.172.65-.203V22H5.5l-.15-.977L7 20.507v-2.889L6.191 16H5v6H4V11.707l-1 1V15H2v-2.707l.146-.147 2-2L4.293 10ZM5 15v-4h1v4H5Zm16 6H8.776L21 17.18V21Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRampUserSmall;
