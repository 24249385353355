import type { SVGProps } from "react";
const SvgRailwaySwitchLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M30.413 8.282 11 36.655V40h-1v-4H8v-1h2v-5H8v-1h2v-5H8v-1h2v-5H8v-1h2v-5H8v-1h2V8h1v26.884L14.341 30H13v-1h2.026l3.42-5H13v-1h6.131L20 21.73V18h-7v-1h7v-5h-7v-1h7V8h1v3h2v1h-2v5h2.236l3.421-5H25v-1h2.341l2.246-3.282.826.564ZM22.553 18 21 20.268V18h1.552ZM20 36v4h1v-3.345l.448-.655H25v-1h-2.868l3.421-5H29v-1h-2.763l3.422-5H33v-1h-2.657l3.42-5H37v-1h-2.552l3.421-5H41v-1h-2.447l1.86-2.718-.826-.564L37.342 11H30v1h6.657l-3.42 5H26v1h6.552l-3.421 5H22v1h6.447l-3.421 5H18v1h6.341l-3.42 5H14v1h6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRailwaySwitchLarge;
