import Header, { HeaderMenuItemProps } from "./Header";
import { UserSmall } from "./icons";

function getInitials(displayName: string) {
  const names = displayName.split(" ").map((n) => n[0]);
  return names.join("");
}

export declare interface UsermenuProps {
  children: React.ReactElement<HeaderMenuItemProps>[];
  displayName?: string;
  loginText?: string;
}

function Usermenu({
  children,
  displayName,
  loginText = "Login",
  ...props
}: UsermenuProps) {
  if (!displayName) {
    return (
      <button className="flex h-12 items-center space-x-1 text-granite hover:text-red125">
        <UserSmall /> <span>{loginText}</span>
      </button>
    );
  }

  return (
    <Header.Menu
      button={
        <div className="flex space-x-2">
          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-cloud font-bold text-[0.625rem] text-metal">
            {getInitials(displayName)}
          </div>
          <div>{displayName}</div>
        </div>
      }
      {...props}
    >
      {children}
    </Header.Menu>
  );
}

Usermenu.Item = Header.MenuItem;

export default Usermenu;
