import type { SVGProps } from "react";
const SvgShareMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.25 8.25a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 0 0-3.26 4.776l-9.153 5.366a3.5 3.5 0 1 0 .133 4.561l9.059 5.427a3.5 3.5 0 1 0 .52-.855l-9.067-5.43c.173-.414.268-.868.268-1.345 0-.548-.126-1.067-.35-1.529l9.085-5.325A3.5 3.5 0 1 0 24.75 4.75Zm0 19a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-17.5-6.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShareMedium;
