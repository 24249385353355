import type { SVGProps } from "react";
const SvgAirplaneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.433 2h3.435l.11.35 2.39 7.65H20c.618 0 1.135.211 1.493.6.352.383.507.888.507 1.4 0 .512-.155 1.017-.507 1.4-.358.389-.875.6-1.493.6h-5.632l-2.39 7.649-.11.35H8.434l.07-.561.93-7.436H6.309l-.862 1.723-.138.277H2.242l.298-.697L3.956 12 2.54 8.696 2.242 8h3.067l.138.276L6.31 10h3.125l-.931-7.438L8.433 2Zm1.132 1 .931 7.437.07.563H5.691l-.138-.277L4.69 9h-.933l1.202 2.803.084.196-.084.197-1.202 2.806h.933l.862-1.724L5.69 13h4.875l-.07.563L9.566 21h1.566l2.39-7.65.11-.35H20c.382 0 .616-.124.757-.278.148-.16.243-.406.243-.723 0-.316-.095-.56-.243-.721-.142-.154-.375-.279-.757-.279h-6.368l-.11-.35-2.39-7.65H9.565Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAirplaneSmall;
