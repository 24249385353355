import type { SVGProps } from "react";
const SvgTunnelFreightWagonShiftedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 11.586C3 6.687 6.804 3 11.5 3S20 6.687 20 11.586V22h1V11.586C21 6.132 16.745 2 11.5 2S2 6.132 2 11.586V22h1V11.586ZM15.191 21H9.809l.5-1h4.382l.5 1Zm1.118 0H18v1H7v-1h1.691l.5-1H7v-2H5V8h11v9h2v3h-2.191l.5 1ZM8 18h9v1H8v-1ZM6 9v8h2V9H6Zm3 0h3v8H9V9Zm4 0h2v8h-2V9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTunnelFreightWagonShiftedSmall;
