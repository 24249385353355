import type { SVGProps } from "react";
const SvgFaceGrinningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 12c0-4.47 3.53-8 8-8 4.47 0 8 3.53 8 8 0 4.47-3.53 8-8 8-4.47 0-8-3.53-8-8Zm8-9c-5.022 0-9 3.977-9 9 0 5.022 3.978 9 9 9s9-3.978 9-9c0-5.023-3.978-9-9-9ZM9 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-7 3h-.5v.5c0 3.068 2.432 5.5 5.5 5.5s5.5-2.432 5.5-5.5V12H7Zm5 5c-2.347 0-4.23-1.726-4.474-4h8.948c-.243 2.274-2.127 4-4.474 4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceGrinningSmall;
