import type { SVGProps } from "react";
const SvgFormMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.75c-1.221 0-1.942.427-2.341.945-.15.194-.245.39-.304.555H12.25v1.5h-6v25h23.5v-25h-6v-1.5h-3.105a2.041 2.041 0 0 0-.304-.555c-.4-.518-1.12-.945-2.341-.945Zm5.75 4v1.5h-11.5v-1.5h-5v23h21.5v-23h-5Zm-7.498-1.992a.424.424 0 0 0-.003.02l-.02.472H13.25v2h9.5v-2h-2.98l-.02-.473a.816.816 0 0 0-.026-.125 1.037 1.037 0 0 0-.175-.347c-.179-.232-.583-.555-1.549-.555s-1.37.323-1.55.555a1.037 1.037 0 0 0-.198.453ZM10.5 13.75v1h15v-1h-15Zm15 4.5v1h-15v-1h15Zm-15 4.5v1h15v-1h-15Zm7.5 5.5h-7.5v-1H18v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFormMedium;
