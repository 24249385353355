import type { SVGProps } from "react";
const SvgTickMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m28.853 10.868-13.5 13.485-.353.353-.354-.353-6-6 .708-.707L15 23.293 28.147 10.16l.706.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTickMedium;
