import type { SVGProps } from "react";
const SvgFlameWarningLightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m15.503 2.193.873.974a292.838 292.838 0 0 0 5.717 6.174l.99 1.047c1.86 1.973 3.438 3.687 4.976 5.636l-.785.62c-1.51-1.912-3.061-3.6-4.919-5.57l-.986-1.044c-1.42-1.5-3.017-3.188-4.887-5.251-.08 2.333-.405 4.435-1.174 6.603-.905 2.551-2.414 5.165-4.818 8.341C8.842 21.901 6 26.469 6 31.575c0 5.293 3.335 9.595 7.534 12.135a13.55 13.55 0 0 1-.756-1.007c-.85-1.255-1.493-2.71-1.493-4.232 0-2.074.585-3.658 1.825-5.4.584-.819 1.15-1.508 1.666-2.137l.2-.245c.573-.7 1.073-1.33 1.492-2.024.823-1.36 1.36-3.01 1.36-5.915v-1.367l.883 1.045c2.753 3.262 4.507 5.424 6.094 7.612l-.81.587c-1.398-1.928-2.932-3.841-5.206-6.553-.144 2.284-.674 3.8-1.465 5.109-.46.76-1 1.438-1.573 2.139l-.202.246a37.327 37.327 0 0 0-1.624 2.084c-1.139 1.599-1.64 2.984-1.64 4.819 0 1.245.53 2.504 1.321 3.671.788 1.163 1.808 2.196 2.72 2.98L16 45.5l-.187.463C10.223 43.715 5 38.49 5 31.575c0-5.44 3.006-10.227 4.693-12.456 2.368-3.128 3.814-5.65 4.672-8.071.857-2.417 1.138-4.76 1.138-7.548V2.193ZM35.5 24v-5h-1v5h1Zm-6.567-3.58 2.5 4.33-.866.5-2.5-4.33.866-.5Zm10.5 4.83 2.5-4.33-.866-.5-2.5 4.33.866.5ZM26.128 40H29v-7a6 6 0 0 1 12 0v7h2.872l.107.357 1.5 5 .193.644H24.328l.193-.644 1.5-5 .107-.356ZM40 40v-7a5 5 0 1 0-10 0v7h10Zm-13.128 1-1.2 4h18.656l-1.2-4H26.872Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFlameWarningLightLarge;
