import type { SVGProps } from "react";
const SvgCloudSnowSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.763 11.691a4.599 4.599 0 0 0-1.796-1.852c.71-.678 1.682-1.089 2.783-1.089 2.26 0 4 1.74 4 4 0 1.695-.977 3.092-2.41 3.688a3.222 3.222 0 0 0-.268-.382 2.962 2.962 0 0 0-1.892-1.072c.246-1.254.065-2.374-.417-3.293Zm3.077 6.883a3.904 3.904 0 0 0-.117-1.212c1.803-.75 3.027-2.51 3.027-4.612 0-2.813-2.187-5-5-5-1.51 0-2.85.632-3.76 1.663-1.956-.606-4.301.02-5.582 2.303-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.84 1.073 2.548.708.713 1.75 1.155 3.125 1.155h10.5c1.384 0 2.45-.44 3.186-1.144a3.802 3.802 0 0 0 1.154-2.532Zm-5.973-8.147c-1.686-.612-3.8-.065-4.81 2.225l-.265.602-.509-.417c-1.627-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.886.493.497 1.265.86 2.415.86h10.5c1.163 0 1.97-.365 2.494-.866.53-.507.807-1.185.848-1.87.04-.687-.159-1.352-.545-1.827-.377-.463-.948-.768-1.727-.737l-.698.028.199-.67c.379-1.274.233-2.339-.194-3.153a3.663 3.663 0 0 0-2.01-1.728ZM23 24.75a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm9 4a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowSunMedium;
