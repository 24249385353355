import type { SVGProps } from "react";
const SvgPieSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 7.01H4v3.975a1.911 1.911 0 0 0 1 1.787v7.208h.5l13 .02h.5v-7.1a2.008 2.008 0 0 0 .97-1.928V7.01H17V4h-1v3.01h-2V4h-1v3.01h-2V4h-1v3.01H8V4H7v3.01Zm11 6.174a2.014 2.014 0 0 1-2.03-2.211v-.209a1.01 1.01 0 0 0-1.116-.86c-.586.067-.948.515-.888 1.037a2.01 2.01 0 1 1-3.997.036c-.016-.535-.461-.971-.965-.967h-.02l-.021-.002a.96.96 0 0 0-1.024 1l.002.03-.003.03C7.866 12.027 7.066 12.993 6 13v1.99h12v-1.806Zm0 2.806H6v1h12v-1Zm0 2H6v.991L18 19v-1.01ZM5.784 12.997h-.001l.034-.5-.032.5h-.001ZM5 8.01v3.006l-.001.016a.91.91 0 0 0 .85.967h.002c.022.001.05.002.087 0h.027c.445.015.92-.427.974-.982a1.96 1.96 0 0 1 1.874-2.009h.004c.06-.002.128-.003.199.002 1.09.003 1.947.928 1.954 1.987v.03l-.004.03a1.01 1.01 0 0 0 2.007.232v-.003a.94.94 0 0 0 0-.226v-.003c-.131-1.133.695-2.024 1.766-2.147m0 0a2.01 2.01 0 0 1 2.227 1.765l.004.029v.325l-.003.028a1.01 1.01 0 1 0 2.007.232v-.003a.94.94 0 0 0 0-.226l-.004-.03V8.01H5"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPieSmall;
