import type { SVGProps } from "react";
const SvgTicketFvSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 2h14v20H5V2Zm1 1v18h12V3H6Zm10 13H8v-1h8v1Zm-8 2h8v-1H8v1Zm0-5.988h.923V9.833h2.179V9.03h-2.18V7.842h2.476V7H8v5.012Zm5.768-1.258L15.025 7h.983l-1.793 5.012h-.92l-.036-.1L11.505 7h1.01l1.253 3.754Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketFvSmall;
