import type { SVGProps } from "react";
const SvgIr48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-48_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M9.251 4.2h4.07L8.286 15.8H4.2L9.251 4.2Zm16.818 8.166a.93.93 0 0 0 .849-.553L30.22 4.2H16.038l-5.035 11.6h4.052l3.812-8.69h6.014l-1.205 2.775-5.244-.019 4.231 5.934h4.613l-3.134-3.434h1.927Zm18.86-1.163v1.954h-1.455v2.642h-2.255v-2.642h-4.825V10.99l4.944-6.755h2.136v6.968h1.455Zm-3.662.024V6.924l-2.974 4.303h2.974Zm6.249-1.637c-.538-.185-.95-.488-1.238-.91-.288-.422-.431-.934-.431-1.535 0-1.001.41-1.8 1.233-2.396.728-.528 1.593-.791 2.595-.791 1.139 0 2.07.313 2.792.941.686.59 1.028 1.32 1.028 2.191 0 .606-.145 1.126-.435 1.558-.29.433-.701.746-1.233.942.416.12.756.276 1.02.466.733.533 1.1 1.31 1.1 2.334 0 1.176-.452 2.111-1.353 2.808-.76.585-1.735.878-2.927.878-1.292 0-2.32-.322-3.085-.965-.796-.67-1.194-1.548-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.922 2.922 0 0 1 1.068-.522Zm2.111.87c-.569 0-1.035.175-1.396.526-.36.35-.541.8-.541 1.348 0 .565.183 1.023.55 1.377.366.353.842.53 1.427.53.664 0 1.163-.195 1.495-.586a1.93 1.93 0 0 0 .483-1.297c0-.559-.185-1.018-.554-1.376-.354-.348-.841-.522-1.464-.522Zm.016-4.707c-.506 0-.915.141-1.226.423-.31.283-.466.653-.466 1.112 0 .48.156.863.47 1.15.314.288.735.432 1.262.432s.944-.143 1.25-.427c.305-.285.458-.673.458-1.163 0-.48-.154-.854-.462-1.123-.309-.27-.737-.404-1.286-.404Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr48;
