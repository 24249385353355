import type { SVGProps } from "react";
const SvgArrowChangeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m4.152 11.65 5.25-5.25.353-.353.354.354 5.25 5.25-.707.707-4.397-4.397v16.793a4 4 0 1 0 8 0V11.253a5 5 0 0 1 10 0v16.794l4.396-4.396.707.707-5.25 5.25-.353.353-.354-.353-5.25-5.25.707-.707 4.397 4.397V11.253a4 4 0 0 0-8 0v13.501a5 5 0 0 1-10 0V7.961L4.86 12.358l-.707-.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowChangeMedium;
