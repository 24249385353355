import type { SVGProps } from "react";
const SvgListLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10 10H3V9h7v1Zm4 0h30V9H14v1Zm0 10h30v-1H14v1Zm30 10H14v-1h30v1ZM14 40h30v-1H14v1ZM3 20h7v-1H3v1Zm7 10H3v-1h7v1ZM3 40h7v-1H3v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgListLarge;
