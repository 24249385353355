import type { SVGProps } from "react";
const SvgSplitMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#split-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M32.25 4.75H24v1h7.056c-.274.281-.544.561-.812.84-2.284 2.369-4.457 4.623-7.74 6.42-4.042 2.214-9.797 3.74-19.504 3.74v1c9.707 0 15.462 1.526 19.504 3.74 3.282 1.797 5.456 4.051 7.74 6.42.268.279.538.559.812.84H24v1h8.75V21h-1v7.029l-.75-.776c-2.291-2.38-4.587-4.763-8.016-6.64-2.804-1.536-6.352-2.73-11.273-3.363 4.92-.632 8.469-1.827 11.273-3.362C26.413 12.01 28.709 9.626 31 7.247c.25-.259.498-.518.749-.776V13.5h1V4.75h-.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="split-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSplitMedium;
