import type { SVGProps } from "react";
const SvgDocumentSignatureSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.1 4.099h6.565l.118.117 5 5 .117.118V19.9H6.1V4.1Zm.8.801v14.198h10.2V9.897h-5V4.901H6.9Zm6 .567 3.628 3.627H12.9V5.468ZM15 11.9H9v-.8h6v.802Zm0 2H9v-.802h6.002v.802Zm-5.457 1.815c.216-.12.505-.185.803-.04l.003.002a.63.63 0 0 1 .307.359c.044.12.057.248.061.357.008.204-.018.445-.04.649l-.004.037a6.9 6.9 0 0 0-.033.377 5.05 5.05 0 0 0 .353-.403l.08-.099c.13-.16.27-.334.4-.47.08-.084.17-.17.269-.236a.704.704 0 0 1 .416-.135h.005a.86.86 0 0 1 .408.116.724.724 0 0 1 .275.299c.105.21.106.447.098.604a4.07 4.07 0 0 1-.013.178l.089-.077.048-.043a4.29 4.29 0 0 1 .382-.315 1.5 1.5 0 0 1 .633-.273h.002c.407-.061.736.154.92.31.19.164.314.34.337.377l-.683.42a1.22 1.22 0 0 0-.175-.186c-.126-.108-.222-.137-.28-.128a.71.71 0 0 0-.292.136 3.46 3.46 0 0 0-.305.253l-.055.049a2.064 2.064 0 0 1-.446.324.744.744 0 0 1-.709-.008.548.548 0 0 1-.258-.342.982.982 0 0 1-.027-.277c.001-.078.009-.16.015-.231l.001-.011c.007-.07.012-.133.015-.194a1.035 1.035 0 0 0-.001-.143c-.025.022-.054.05-.09.086-.104.11-.217.25-.346.41-.03.036-.06.073-.09.112a4.63 4.63 0 0 1-.522.568 1.26 1.26 0 0 1-.328.214.686.686 0 0 1-.477.031.601.601 0 0 1-.366-.33 1.025 1.025 0 0 1-.083-.362 4.24 4.24 0 0 1 .035-.684v-.002l.001-.005a4.58 4.58 0 0 0 .04-.563c-.068.043-.15.122-.237.232a2.757 2.757 0 0 0-.295.469l-.016.032-.004.008L9 17l-.363-.17v-.002l.002-.004.007-.013a3.257 3.257 0 0 1 .105-.2c.07-.123.172-.289.299-.45a1.78 1.78 0 0 1 .493-.445Zm.746 2.657.12-.382-.12.382Zm1.92-1.47-.004.001a.021.021 0 0 1 .004-.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSignatureSmall;
