import type { SVGProps } from "react";
const SvgListMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.001 8.75h3v-1h-3v1Zm7.502 0h15v-1h-15v1Zm15 6h-15v-1h15v1Zm-22.502 0h3v-1h-3v1Zm22.502 12h-15v-1h15v1Zm-22.502 0h3v-1h-3v1Zm22.502-6h-15v-1h15v1Zm-22.502 0h3v-1h-3v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgListMedium;
