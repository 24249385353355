import type { SVGProps } from "react";
const SvgArrowCompassFilledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="#fff" />
    <path
      clipRule="evenodd"
      d="M11.5 19.441v-7h-7L18.94 5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCompassFilledSmall;
