import type { SVGProps } from "react";
const SvgArrowsRightLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m26.589 10.883-5.985-5.987-.708.707 5.132 5.133H7.5v1h17.532l-5.136 5.16.708.706 5.985-6.013.352-.354-.352-.352ZM9.457 24.238H27v1H9.454l5.136 5.16-.709.706-5.985-6.014-.352-.353.353-.353 5.985-5.986.707.707-5.132 5.133Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsRightLeftMedium;
