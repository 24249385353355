import type { SVGProps } from "react";
const SvgFolderInfoSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.5 6h-.309l-.138.276L14.19 8H4v10h16v-.5l-.005-11V6H15.5Zm-.553 2.724L15.81 7h3.186L19 17H5V9h9.809l.138-.276ZM11 13h1v2h-1v1h3v-1h-1v-3h-2v1Zm1-2v-1h1v1h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderInfoSmall;
