import type { SVGProps } from "react";
const SvgCloudSnowLightningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.312 9.856c.491.112.89.382 1.176.753.389.504.544 1.161.49 1.796a2.83 2.83 0 0 1-.826 1.782c-.511.5-1.237.813-2.152.813v-1c.674 0 1.143-.225 1.452-.528a1.83 1.83 0 0 0 .53-1.152c.035-.428-.074-.827-.286-1.101-.2-.26-.511-.438-.976-.42l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073c-1.048-.38-2.361-.045-2.996 1.395l-.266.602-.509-.418c-.966-.792-2.566-.047-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.463-.217.303-.34.727-.32 1.165.018.434.175.847.473 1.148.293.294.763.524 1.492.524v1c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.168-.764-1.809-.028-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.815c.092-1.85 2.007-2.895 3.523-2.176.945-1.539 2.624-1.917 3.987-1.423.745.27 1.403.803 1.794 1.55.318.607.447 1.334.322 2.139ZM11.292 13h2.915l-.853.854-1.647 1.646h2.366l-1.088.887-3.33 2.715-3.17 2.584 2.454-3.271L10 17H7.293l.853-.854 3-3 .147-.146Zm-1.5 3h-.085l2-2h.086l-1.647 1.646-.353.354Zm6.708-.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-8-2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowLightningSmall;
