import type { SVGProps } from "react";
const SvgContainerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.006 2.999 13 5.062l-.001.388.376.097a1.501 1.501 0 0 1 .856 2.31l-1.496-.798-.235-.126-.235.126-.702.374A1.498 1.498 0 0 1 11.5 7h-1c0 .32.06.627.17.909L4.876 11H3v10h19V11h-1.875l-5.007-2.67A2.5 2.5 0 0 0 14 4.71L14.006 3l-1-.002Zm.262 5.477-.768-.41-.335.18a1.492 1.492 0 0 0 1.103.23Zm-2.052.275A2.493 2.493 0 0 0 13 9.5c.516 0 .995-.156 1.393-.424L18 11H7l4.216-2.249ZM4 20v-8h2v8H4Zm3 0h2v-8H7v8Zm5 0h-2v-8h2v8Zm1 0h2v-8h-2v8Zm5 0h-2v-8h2v8Zm1 0h2v-8h-2v8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgContainerSmall;
