import type { SVGProps } from "react";
const SvgDogSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 5.5V4.293l-.854.853-3.882 3.883-6.8.971h-.005c-.025 0-.064 0-.115-.002a7.104 7.104 0 0 1-1.698-.268c-.462-.141-.881-.344-1.178-.623A1.43 1.43 0 0 1 3 8.009H2c0 .785.316 1.387.782 1.826.453.426 1.034.687 1.572.851A7.545 7.545 0 0 0 6 10.98V20h1v-9.066l6.57-.939.166-.024.118-.117L17 6.707v1l.146.147 2.647 2.646-1.293 1.293-.646-.647-.354-.353-.354.353-1 1-.146.147V20h1v-7.293l.5-.5.646.647.354.353.354-.353 2-2 .353-.354-.353-.354L18 7.293V5.5ZM10.5 14h-.007c-.795.01-1.324.533-1.656 1.128-.33.591-.525 1.345-.643 2.05-.12.715-.167 1.423-.186 1.949a17.101 17.101 0 0 0-.01.82l.002.05v.017L8.5 20l.5-.015v-.014l-.001-.042-.002-.166c0-.145.002-.352.01-.602.018-.5.064-1.162.174-1.819.11-.665.281-1.285.529-1.727.245-.438.507-.611.794-.616H14v5h1v-6h-4.5Zm6-5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDogSmall;
