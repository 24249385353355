import type { SVGProps } from "react";
const SvgUserMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14 13.5c0-2.699 1.86-4.75 4-4.75 2.14 0 4 2.051 4 4.75 0 2.698-1.86 4.75-4 4.75-2.14 0-4-2.052-4-4.75Zm4-5.75c-2.831 0-5 2.65-5 5.75 0 1.46.48 2.82 1.285 3.85-4.254 1.95-6.535 7.153-6.535 11.9v.5h20.5v-.5c0-4.773-2.284-9.961-6.535-11.9C22.519 16.32 23 14.96 23 13.5c0-3.1-2.169-5.75-5-5.75Zm2.979 10.372c-.824.705-1.848 1.128-2.979 1.128s-2.156-.423-2.98-1.129c-3.825 1.552-6.103 6.132-6.261 10.629H27.24c-.157-4.522-2.44-9.088-6.262-10.628Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserMedium;
