import type { SVGProps } from "react";
const SvgDisplayGearsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 5H2v12h9v3H7v1h10v-1h-4v-3h9V5H2.5Zm12 11h2.243c.165-.47.257-.973.257-1.5a4.482 4.482 0 0 0-1.827-3.63l-1.015 1.759c.516.458.842 1.126.842 1.871a2.49 2.49 0 0 1-.5 1.5Zm3.292 0H21V6H3v10h4.208a5.49 5.49 0 0 1-.185-1H5v-1h2.022a5.49 5.49 0 0 1 .48-1.809l-1.747-1.008.5-.866L8 11.325c.362-.514.81-.963 1.325-1.325L8.317 8.255l.866-.5L10.191 9.5A5.49 5.49 0 0 1 12 9.022V7h1v2.022a5.49 5.49 0 0 1 1.809.48l1.008-1.747.866.5L15.675 10c.514.362.963.81 1.325 1.325l1.745-1.008.5.866-1.746 1.008A5.49 5.49 0 0 1 17.978 14H20v1h-2.023a5.49 5.49 0 0 1-.185 1Zm-9.535 0H10.5a2.49 2.49 0 0 1-.45-1H8.028c.038.347.117.681.229 1Zm4.243 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0-4a2.5 2.5 0 0 0-2.45 2H8.027a4.479 4.479 0 0 1 4.473-4c.644 0 1.254.133 1.806.372l-1.014 1.756A2.497 2.497 0 0 0 12.5 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDisplayGearsSmall;
