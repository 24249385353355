import type { SVGProps } from "react";
const SvgLocationPinCameraLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 17C9 8.716 15.716 2 24 2c8.284 0 15 6.716 15 15v.005c-.022 2.482-.803 4.697-2.002 6.796l-.004.007-.004.006-12.522 20.447-.427.696-.426-.696-12.523-20.447-.002-.004C9.87 21.775 9.1 19.603 9 17.02V17Zm1-.01c.095 2.385.8 4.392 1.946 6.304l.001.002 12.094 19.747 12.092-19.745c1.138-1.993 1.846-4.037 1.867-6.3C37.999 9.267 31.731 3 24 3c-7.729 0-13.995 6.263-14 13.99Zm4-5.49V10h3v1h3.14l.886-2.658L21.14 8h5.72l.114.342L27.86 11H34v12H14V11.5Zm7 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm5.806-6h-5.612l.666-2h4.28l.666 2ZM15 12v10h18V12H15Zm9 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinCameraLarge;
