import type { SVGProps } from "react";
const SvgVegetarianMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m27.3 6.676-.067-.442-.446.016c-5.158.19-8.871 1.543-11.516 3.522-2.646 1.98-4.185 4.558-5.043 7.134-.856 2.573-1.037 5.151-.966 7.16a19.78 19.78 0 0 0 .245 2.538c.056.33.115.613.172.837.026.101.053.198.082.286a68.983 68.983 0 0 0-2.711 5.055l.9.436c.822-1.7 1.713-3.36 2.67-4.98 5.003-.231 8.598-1.565 11.16-3.503 2.613-1.977 4.11-4.551 4.94-7.123 1.564-4.847.772-9.744.602-10.795l-.022-.14ZM11.247 27.2c4.487-.306 7.672-1.555 9.93-3.263 2.416-1.827 3.81-4.212 4.592-6.632 1.372-4.255.835-8.573.612-10.036-4.763.243-8.134 1.525-10.511 3.304-2.451 1.834-3.886 4.225-4.693 6.65-.809 2.428-.984 4.88-.916 6.808a18.788 18.788 0 0 0 .243 2.469 68.992 68.992 0 0 1 12.41-14.871l.671.742a67.987 67.987 0 0 0-12.339 14.83Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgVegetarianMedium;
