import type { SVGProps } from "react";
const SvgCirclePlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.75 5.75c-6.35 0-11.5 5.149-11.5 11.5 0 6.352 5.15 11.5 11.5 11.5s11.5-5.148 11.5-11.5c0-6.351-5.15-11.5-11.5-11.5Zm-12.5 11.5c0-6.903 5.597-12.5 12.5-12.5s12.5 5.597 12.5 12.5c0 6.904-5.597 12.5-12.5 12.5s-12.5-5.596-12.5-12.5Zm5.75.5v-1h6.25V10.5h1v6.25h6.25v1h-6.25V24h-1v-6.25H12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCirclePlusMedium;
