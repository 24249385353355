import type { SVGProps } from "react";
const SvgContainerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m19.759 4.499-.009 3.094-.001.389.376.097a2.502 2.502 0 0 1 1.291 4.026l-2.43-1.296-.236-.126-.235.126-1.305.696A2.49 2.49 0 0 1 17 10.5h-1c0 .527.117 1.028.326 1.476L7.375 16.75H4.75v14.5h28v-14.5h-2.625l-7.812-4.167a3.502 3.502 0 0 0-1.561-5.353l.007-2.729-1-.002Zm.784 8.274-1.793-.956-.956.51A2.49 2.49 0 0 0 19.5 13c.372 0 .725-.081 1.043-.227Zm-3.667.043A3.492 3.492 0 0 0 19.5 14c.775 0 1.492-.252 2.071-.679L28 16.75H9.5l7.376-3.934ZM5.75 30.25v-12.5h3.5v12.5h-3.5Zm4.5 0h3.5v-12.5h-3.5v12.5Zm8 0h-3.5v-12.5h3.5v12.5Zm1 0h3.5v-12.5h-3.5v12.5Zm8 0h-3.5v-12.5h3.5v12.5Zm1 0h3.5v-12.5h-3.5v12.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgContainerMedium;
