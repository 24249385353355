import type { SVGProps } from "react";
const SvgQuestionAnswerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.25 7.75h-.5v14.5h4.5v4.082l.854-.853 3.228-3.229h.418v4.5h8.918l3.228 3.229.854.853V26.75h4.5v-14.5h-7.5v-4.5H5.25Zm18.5 5.5v3.5h4.75v1h-4.75v2H27v1h-3.25v1.5h-9v3.5h8.332l.147.146 2.521 2.522V25.75h4.5v-12.5h-6.5Zm-18 8V8.75h17v12.5h-9.832l-.147.146-2.521 2.522V21.25h-4.5Zm7.492-2.308h1.266v-1.218h-1.266v1.218Zm-1.569-7.617c-.258.4-.4.962-.427 1.688h1.006c.083-1.281.645-1.922 1.688-1.922.504 0 .885.162 1.143.486.214.267.322.594.322.986 0 .21-.037.417-.11.62-.09.238-.28.506-.57.803-.448.46-.739.776-.87.944a1.844 1.844 0 0 0-.3.568c-.098.288-.15.732-.16 1.33h.996c0-.337.02-.61.059-.821.036-.21.097-.387.184-.53.089-.142.243-.32.467-.537.504-.492.822-.83.955-1.012.296-.402.444-.884.444-1.442 0-.676-.219-1.22-.655-1.635-.47-.448-1.1-.673-1.893-.673-.492 0-.935.099-1.33.298-.395.2-.712.483-.949.85Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQuestionAnswerMedium;
