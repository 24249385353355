import type { SVGProps } from "react";
const SvgUserTieLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.567 12.836C18.648 9.97 21.151 8 24 8c2.849 0 5.352 1.97 6.433 4.836a12.16 12.16 0 0 1-3.4-1.968 1.44 1.44 0 0 0-1.618-.185 3.32 3.32 0 0 1-2.83 0 1.44 1.44 0 0 0-1.618.185 12.16 12.16 0 0 1-3.4 1.968Zm-.351 1.18A9.12 9.12 0 0 0 17 16c0 4.479 3.191 8 7 8s7-3.521 7-8c0-.687-.075-1.351-.216-1.984a13.159 13.159 0 0 1-4.392-2.38l-.006-.005-.006-.006a.44.44 0 0 0-.501-.056l-.012.007-.012.006a4.321 4.321 0 0 1-3.71 0l-.012-.006-.012-.007a.44.44 0 0 0-.5.056l-.007.006-.007.005a13.16 13.16 0 0 1-4.391 2.38ZM24 7c-4.475 0-8 4.09-8 9 0 2.454.88 4.703 2.32 6.338C12.264 25.293 8 32.794 8 41.5v.5h32v-.5c0-8.705-4.246-16.21-10.319-19.162C31.12 20.702 32 18.453 32 16c0-4.91-3.525-9-8-9ZM9.005 41c.162-8.323 4.352-15.268 9.995-17.878v4.187l.724-.362 1.793-.896 1.044 1.156A1.249 1.249 0 0 0 22 28.25v.5c0 .39.18.74.46.97l-.956 7.718-.03.243.172.172 2 2 .354.354.354-.354 2-2 .17-.17-.027-.242-.914-7.76c.256-.228.417-.56.417-.931v-.5c0-.436-.223-.82-.561-1.043l1.044-1.156 1.793.896.724.362v-4.187c5.66 2.608 9.834 9.554 9.995 17.878H9.005ZM28 23.8a7.451 7.451 0 0 1-1.966.908l1.966.983v-1.892Zm-3.636 1.19a7.196 7.196 0 0 1-.728 0l-1.19.597L23.721 27h.556l1.277-1.414-1.191-.595Zm-2.398-.282A7.451 7.451 0 0 1 20 23.8v1.892l1.966-.983ZM26 15.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3.842 6.683A3 3 0 0 1 21.6 19.8l.8-.6a2 2 0 0 0 3.2 0l.8.6a3 3 0 0 1-3.742.883ZM23.433 30l-.907 7.32L24 38.792l1.475-1.475L24.614 30h-1.18ZM23 28.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5ZM21.5 15a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserTieLarge;
