import type { SVGProps } from "react";
const SvgRocketSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12 2.233.38.441c3.789 4.408 4.679 9.036 4.617 12.557-.006.337-.02.663-.042.978a4.49 4.49 0 0 1 1.902 4.604l-.183.939-.666-.686a3.446 3.446 0 0 0-1.729-.958 14.116 14.116 0 0 1-.316.962 5.848 5.848 0 0 1-.034.088l-.01.024-.003.007v.003L15.453 21l.461.193-.28.668-.525-.498c-1.574-1.491-3.103-1.477-4.255-1.113a5.628 5.628 0 0 0-1.938 1.088l-.023.021-.004.004-.526.498-.28-.668.462-.193-.461.192-.002-.003-.003-.007-.01-.024-.034-.088a14.159 14.159 0 0 1-.316-.962 3.445 3.445 0 0 0-1.728.958l-.666.686-.183-.939c-.34-1.736.358-3.574 1.902-4.603a19.307 19.307 0 0 1-.042-.979C6.94 11.71 7.83 7.082 11.62 2.674l.38-.441Zm4.528 16.906c.506.117.98.316 1.407.587a3.483 3.483 0 0 0-1.097-2.338 19.03 19.03 0 0 1-.31 1.751Zm-9.366-1.751c.085.656.195 1.245.31 1.751-.506.117-.98.316-1.407.587a3.483 3.483 0 0 1 1.097-2.338Zm1.624 2.788.074-.05a6.625 6.625 0 0 1 1.694-.83c1.293-.408 2.977-.438 4.67.851.073-.234.158-.527.244-.872.246-.987.5-2.393.53-4.061.043-2.484-.412-5.547-2.138-8.68a3.946 3.946 0 0 1-3.72 0c-1.726 3.133-2.18 6.196-2.137 8.68.029 1.668.283 3.074.53 4.061.089.359.177.662.253.901Zm1.861-14.505a2.945 2.945 0 0 0 2.706 0A19.086 19.086 0 0 0 12 3.781c-.51.628-.958 1.26-1.353 1.89ZM12 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRocketSmall;
