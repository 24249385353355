import type { SVGProps } from "react";
const SvgMachineLearningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.25 5.23a9.6 9.6 0 0 1 3-.48 9.5 9.5 0 0 1 9.464 10.319 9.454 9.454 0 0 1 1.536 5.181v.5h-4.5v2.063a2 2 0 1 1-1 0V20.75h-3.5v8.063a2 2 0 1 1-1 0V20.75h-3.5v2.063a2 2 0 1 1-1 0V20.75h-3.5v8.063a2 2 0 1 1-1 0V20.75h-3.5v2.063a2 2 0 1 1-1 0V20.75h-4.5v-.5c0-1.912.565-3.691 1.536-5.181A9.5 9.5 0 0 1 14.25 4.75a9.6 9.6 0 0 1 3 .48Zm0 1.059a8.528 8.528 0 0 1 3.378 2.295l.744-.668a9.489 9.489 0 0 0-2.604-2.038 8.5 8.5 0 0 1 9.97 7.938 9.497 9.497 0 0 0-4.613-2.767l-.25.969a8.505 8.505 0 0 1 6.36 7.732H22.25v-.25c0-.585.204-1.012.503-1.294.302-.286.736-.456 1.247-.456v-1c-.732 0-1.423.245-1.934.73-.517.487-.816 1.185-.816 2.02v.25h-3.5v-4.285a3.501 3.501 0 0 0 3-3.465h-1a2.5 2.5 0 0 1-5 0h-1a3.501 3.501 0 0 0 3 3.465v4.285h-3.5v-.25c0-.835-.3-1.533-.816-2.02-.511-.485-1.202-.73-1.934-.73v1c.51 0 .945.17 1.248.456.298.282.502.709.502 1.294v.25H4.264a8.505 8.505 0 0 1 6.36-7.732l-.249-.969a9.497 9.497 0 0 0-4.614 2.767 8.5 8.5 0 0 1 9.97-7.938 9.489 9.489 0 0 0-2.604 2.038l.745.668a8.528 8.528 0 0 1 3.378-2.295Zm-5.5 24.461a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm14.5-7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3.5 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-12.5-7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMachineLearningMedium;
