import type { SVGProps } from "react";
const SvgQrcodeTwoTicketsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 3v3h1V3.996h1v-1H3V3Zm0 4.996h2.001v-1H3v1ZM10 5H8.999V4H10v1Zm1.001 0h1V3h-1v2ZM6 2.997h3v1H7V5H5V4h1V2.997Zm7.001 2h2v-1h1v1h1v-2h-2v1h-2v1ZM4 17H3v-2h1v2Zm-1-6.002h1V10h1.001V9H4v.998H3v1Zm1 2H3v-1.002h1V11h1.001v1H4v.997ZM5.001 14H4v-1h1.001v1ZM11 16.997h6v-6h-6v6Zm1-1h4v-4h-4v4Zm3-1h-2v-2h2v2Zm-8.001-3h2v-.999H10v-1H8.999v1h-2v1Zm7.002-1.999V10h-1v-.002h-1v-1h1V8h1v1.998Zm7 3.002h-1v.998h-2v1h2V14h1v-1Zm-1-2h1v1h-1v.998h-2v-1h2V11Zm-9.002-2V7.998h2.002v-1h-3V7H10v1H9v1h2ZM16 9h-1V7h1v1.998h1v1h-1V9Zm4.001 8h1v-2h-1v2ZM9 7.996H8V10H7V6.996h2v1Zm3 13.005h1.001v-1H12v1Zm-4.002 0h-1v-2.002h1v2.002Zm2-4.003H9V17H8v1h1v.998h2.002v-1h-1l-.001-1Zm4.003 2H12v-1h2.001v1Zm5.999 2h1v-1h-1v1Zm-12.002-6h-1v-1h1V13H10v1H7.999v.998Zm-1 2h1V16H10v-1H7.999v.998h-1v1Zm14.002-8h-1v-1h-1v-1h2v2ZM8.999 21H11v-1H8.999v1Zm11.002-2.003h-1V18h-1v1.998h1v1h-2v-2h-1v1.003h-1v1h-1v-2h1v-1l3-.003V16h1v1.998h1v1Zm-2-7.998V9l-1-.002v-1h2v2h1v1l-2 .002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeTwoTicketsSmall;
