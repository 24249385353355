import type { SVGProps } from "react";
const SvgQrcodeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10 14h4v-4h-4v4Zm-1 1h6V9H9v6Zm2-2h2v-2h-2v2ZM6.999 3.996H10v-1H6.999v1Zm0 2.004V5h-2v1l1 .003v2h1v-1h2V6h2V5h-2v.002h-1v1h-1V6Zm0 3H5V7H4V6H3v2h1v1h.999v1h2V9Zm5-3.997h1v-2h-1v.998h-1v1h1v.002Zm8.001 2h1v-1h-1v1Zm-2 5h1v-1h-1v.999h-2v1h2v-.998Zm-14.001-8 2-.008v-1h-3v2.008h1v-1Zm14.001 9v2h1v-1h1v1h1v-2h-3Zm-5-8v2h1v-1l3-.002v-1h1v-1h-1v-.998h-1v1.998h-1v-.998h-1v1h-1Zm3 2v2h1v-.001h1v-1h-1v-.998h-1Zm1 14.002h1v-2h-1v2Zm3-1.003h-1v1h1v.003h1v-2h-1v.997Zm0-14.999h1v-2h-1v-.002h-2v1h2v1.002Zm0 10h-1v2.999h-1v1h2v-1h1v-1h-1v-1.998Zm0-2.999h1v-2h-1v2Zm-3-5.003h2v-1h-2v1Zm2 1v2.002h1v-1l1-.002v-1h-2Zm-8 13.001h2v-1h-2v1Zm-1-13h1v.001h1v-2h-1v.998h-1v1Zm0 11H9v1h1v.003h1v-2h-1v.997Zm5 .003h-1v2h1v-.003h1v-1h-1v-.997Zm-7.001-4.003-2 .002v1h1v.998h2v-1h-1v-1Zm2.001 2h2v-1h-2v1Zm6-6h2v-1h-2v1Zm-13.001 7h1v-1h-1v1Zm2-5H6v-1H4.999v1Zm2-4h1v-1h-1v1Zm7.001-1h1v-1h-1v1Zm-10.001 4h1v-1h-1v1Zm-1-1h1v-2h-1v2Zm0 3h1v-2h-1v2Zm2 4.001H7v-1H4.999v1Zm0-4.001h-1v1l-1 .002v1h2v-2.002Zm2 5v.002h-1v1h1v.998h1v-1.998h1v-1h-2v.998Zm-3 0h-1v2h1v.003h2v-1h-2v-1.003Zm2-4.998h2v-1h-2v1Zm0-2h2v-1h-2v1ZM17 17.002h1v-1h-1v1Zm-3 0h1v2h2v-1h-1v-1.998h1v-2h-1v1.998l-3 .002v1h-1v2h1v-1h1v-1.002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeSmall;
