import type { SVGProps } from "react";
const SvgFolderOpenArrowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m14 6.793 2.146-2.147.707.708-3 3-.353.353-.354-.353-3-3 .707-.708L13 6.793V2h1v4.793ZM2.99 8H10v2h8v2h2.992l-.218.657-2.65 8-.113.343H3v-.5l-.01-12V8ZM17 12v-1H9V9H3.99l.007 8.613 1.88-5.28.12-.333H17ZM4.209 20h13.08l2.319-7H6.703l-2.494 7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderOpenArrowSmall;
