import type { SVGProps } from "react";
const SvgCurriculumVitaeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 3.25h23.5v29.5H6.25V3.25Zm1 1v27.5h21.5V4.25H7.25ZM27 7.75h-7.5v1H27v-1Zm0 3h-7.5v1H27v-1Zm-7.5 3H27v1h-7.5v-1Zm6 6H9v1h16.5v-1Zm-16.5 3h16.5v1H9v-1Zm3.03 4.515h-3v1h3v-1Zm3 0h3v1h-3v-1Zm9 0h-3v1h3v-1ZM12.125 9c0-1.056.697-1.75 1.375-1.75s1.375.694 1.375 1.75-.697 1.75-1.375 1.75-1.375-.694-1.375-1.75ZM13.5 6.25c-1.393 0-2.375 1.32-2.375 2.75 0 .778.29 1.523.78 2.041a6.32 6.32 0 0 0-2.654 5.485l.998-.052a5.32 5.32 0 0 1 2.569-4.836 2.11 2.11 0 0 0 1.336.01 5.35 5.35 0 0 1 2.597 4.83l.999.044a6.35 6.35 0 0 0-2.671-5.464c.499-.519.796-1.272.796-2.058 0-1.43-.982-2.75-2.375-2.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCurriculumVitaeMedium;
