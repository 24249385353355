import type { SVGProps } from "react";
const SvgSaSz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.302 3.133c1.019 0 2.08.291 2.9.698l.66-2.23C10.722 1.155 9.462 1 8.241 1 5.56 1 3.04 2.24 3.04 5.092c0 1.978 1.433 2.755 2.726 3.456 1.065.577 2.036 1.103 2.036 2.206 0 1.454-1.321 2.114-2.662 2.114-1.2 0-2.34-.447-3.42-.932L1 14.244c1.26.446 2.64.756 3.98.756 3.061 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.776-1.009-.577-1.896-1.084-1.896-2.022 0-1.163 1.34-1.551 2.341-1.551Zm16.16-1.9h-9.581l-.48 2.132h6.1l-8.401 9.27-.44 2.133h9.802l.46-2.133H15.56l8.422-9.27.48-2.132Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSz;
