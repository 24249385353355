import type { SVGProps } from "react";
const SvgEv59Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-59-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm7.723 0v2.063H28.36l-.5 2.61c.561-.592 1.278-.888 2.15-.888.858 0 1.603.23 2.238.69.942.693 1.413 1.745 1.413 3.158 0 1.386-.503 2.483-1.508 3.293-.783.634-1.72.952-2.809.952-1.227 0-2.214-.328-2.96-.984-.745-.661-1.14-1.553-1.182-2.674h2.277c.038.407.17.754.397 1.04.355.45.847.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.298.328-1.706.984l-2.07-.031 1.102-6.467h6.372Zm1.224 8.688h2.19c.18.9.7 1.349 1.563 1.349.487 0 .88-.164 1.182-.492.307-.333.542-.849.706-1.547.106-.444.183-.95.23-1.516-.354.498-.719.852-1.094 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.032-1.357 4.459 4.459 0 0 1-.364-1.809c0-1.306.447-2.364 1.34-3.174.767-.698 1.688-1.047 2.762-1.047.66 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.297 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.587-.865-.677-.577-1.068-1.351-1.174-2.325Zm3.856-7.006c-.497 0-.918.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.814.817 1.428.817.555 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.61 1.61 0 0 0-1.34-.66Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv59Negative;
