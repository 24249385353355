import type { SVGProps } from "react";
const SvgChevronSmallUpCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm.352 5.373-.356-.36-.355.36-3.904 3.949.71.703 3.55-3.59 3.548 3.591.71-.703-3.903-3.95Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallUpCircleSmall;
