import type { SVGProps } from "react";
const SvgShoppingBagCouponSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 5.5a2.5 2.5 0 1 1 5 0V7H9V5.5ZM8 8v2h1V8h5v2h1V8h2.037l.923 12H5.04l.923-12H8Zm0-1V5.5a3.5 3.5 0 1 1 7 0V7h2.963l.035.462 1 13 .042.538H3.96l.041-.538 1-13L5.037 7H8Zm3 10v-5h1v5h-1Zm.5-7c-.199 0-.395.013-.587.038l.13.991a3.538 3.538 0 0 1 .915 0l.129-.991A4.536 4.536 0 0 0 11.5 10Zm2.74.93a4.493 4.493 0 0 0-1.017-.588l-.384.923c.285.118.55.272.791.458l.61-.793Zm-4.463-.588a4.47 4.47 0 0 0-1.017.588l.61.793c.24-.186.506-.34.79-.458l-.383-.924Zm5.881 2.435a4.497 4.497 0 0 0-.588-1.017l-.793.61c.185.24.34.506.458.79l.923-.383ZM7.93 11.76a4.47 4.47 0 0 0-.588 1.017l.923.383c.118-.284.273-.55.458-.79l-.793-.61ZM16 14.5c0-.199-.013-.395-.038-.587l-.992.129a3.54 3.54 0 0 1 0 .915l.992.13c.025-.192.038-.389.038-.587Zm-8.962-.587a4.538 4.538 0 0 0 0 1.174l.992-.13a3.538 3.538 0 0 1 0-.915l-.992-.13Zm8.032 3.326c.238-.309.436-.65.588-1.017l-.923-.383c-.118.285-.273.55-.458.791l.793.61Zm-7.728-1.017c.151.366.35.708.588 1.017l.793-.609a3.495 3.495 0 0 1-.458-.79l-.923.382Zm5.88 2.436a4.5 4.5 0 0 0 1.018-.588l-.61-.793a3.49 3.49 0 0 1-.79.457l.383.924ZM8.76 18.07a4.5 4.5 0 0 0 1.017.588l.383-.924a3.49 3.49 0 0 1-.79-.457l-.61.793Zm2.153.892a4.536 4.536 0 0 0 1.174 0l-.13-.992a3.52 3.52 0 0 1-.915 0l-.13.992Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagCouponSmall;
