import type { SVGProps } from "react";
const SvgDelay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="delay_svg__color-immutable"
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#EB0000" height={16} rx={2} width={16} />
    <path
      clipRule="evenodd"
      d="M7.994 2A5.997 5.997 0 0 0 2 8c0 3.312 2.682 6 5.994 6A6.003 6.003 0 0 0 14 8c0-3.312-2.688-6-6.006-6ZM8 12.8A4.799 4.799 0 0 1 3.2 8c0-2.652 2.148-4.8 4.8-4.8 2.652 0 4.8 2.148 4.8 4.8 0 2.652-2.148 4.8-4.8 4.8Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.3 5h-.9v3.541l3.15 1.859.45-.726-2.7-1.576V5Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDelay;
