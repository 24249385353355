import type { SVGProps } from "react";
const SvgWeightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.5 8.25a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm4.949 2.5a3.5 3.5 0 1 0-4.898 0h-7.73l-.065.424-3 19.5-.089.576h26.666l-.089-.576-3-19.5-.065-.424h-7.73Zm-2.449 1H8.679l-2.846 18.5h24.334l-2.846-18.5H18Zm-.25 6.266-3.356 3.387 3.673 5.18h-2.36l-2.602-3.845-1.078 1.079v2.766h-1.87v-8.567h1.87v3.545l3.357-3.545h2.367Zm8.192 8.566v-4.617h-3.597v1.371h1.904c-.02.578-.218 1.044-.594 1.401-.377.354-.861.533-1.45.533-.817 0-1.435-.308-1.853-.92-.34-.5-.509-1.176-.509-2.028 0-.871.182-1.563.545-2.074.414-.585 1-.878 1.758-.878.5 0 .912.128 1.239.386.327.258.535.616.63 1.078h1.81a3.196 3.196 0 0 0-.909-1.933c-.706-.726-1.623-1.09-2.748-1.09-1.218 0-2.212.406-2.982 1.219-.82.86-1.23 1.963-1.23 3.31 0 1.187.315 2.186.944 2.994.757.97 1.79 1.454 3.099 1.454 1.074 0 1.927-.396 2.56-1.184l.188.978h1.195Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWeightMedium;
