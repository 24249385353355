import type { SVGProps } from "react";
const SvgDisplaySwitzerlandWarningLightSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 2h18v7h-1V3H3v11h10v1h-1v2h1v1H6v-1h4v-2H2V2Zm9.519 1.833.366.328.788.704 2.181.858.401.158-.135.408-.256.774.27.083.091-.173.252-.482.43.333.816.63.166.13.01.21.055 1.185-.909.042L16 8.046l-.22-.17-.013.027-.175.335-.362-.11-1.078-.33-.445-.137.146-.442.268-.807-1.857-.73-.076-.03-.06-.054-.483-.431-.316.414-.093.121-.148.041-2.286.632-.178.049-.162-.089-.192-.105-.565.426.507.176-.453.509-1.692 1.901-.425 1.576.482-.124.518-.307.299-.176.25.242 1.204 1.168 1.534-.175.99-1.085.388-.426.324.477 1.245 1.832-.752.512-.922-1.356-.716.786-.115.126-.17.019-1.917.218-.214.025-.154-.15-1.11-1.076-.275.162-.055.033-.063.016-1.3.335-.756.195.204-.754.656-2.434.028-.103.071-.08 1.31-1.474-.026-.01-.806-.28.682-.512 1.482-1.116.235-.176.257.14.288.158 1.96-.541.523-.683.298-.39ZM17.5 12v-2h-1v2h1Zm-3.316-1.498 1 1.75-.868.496-1-1.75.868-.496ZM13 19h-.39l-.095.379-.5 2-.155.621h10.28l-.155-.621-.5-2L21.39 19H20v-3a3 3 0 0 0-6 0v3h-1Zm6-3v3h-4v-3a2 2 0 0 1 4 0Zm-5.86 5 .25-1h7.22l.25 1h-7.72Zm6.544-8.252 1-1.75-.868-.496-1 1.75.868.496Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDisplaySwitzerlandWarningLightSmall;
