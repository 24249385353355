import type { SVGProps } from "react";
const SvgWalkSlowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.25 8.25c0-.523.477-1 1-1s1 .477 1 1-.477 1-1 1-1-.477-1-1Zm1-2c-1.076 0-2 .924-2 2s.924 2 2 2 2-.924 2-2-.924-2-2-2Zm-1.706 6h3.516l.138.276 1.48 2.96L25.9 18.45l-.8.6-2.25-3-.027-.036-.02-.04-.554-1.107v5.4l1.443 2.75.057.11V30h-1v-6.627l-1.302-2.483H19.25v3.896l-.005.035-.75 5.25-.99-.142.745-5.214V13.957l-2 2V19.5h-1v-3.957l.147-.147 3-3 .147-.146Zm.706 7.64v-6.64h2v6.64h-2Zm-3.91.226-1.216 3.01-2.314-.943-.465-.19-.187.465-1.686 4.173-.187.462.461.189 2.778 1.134.465.19.188-.466 3.09-7.65-.927-.374Zm-3.443 3.182 1.852.755-1.312 3.247-1.852-.756 1.312-3.246ZM9.36 27.463a.751.751 0 0 0-.696.468.751.751 0 0 0 1.39.562.75.75 0 0 0-.694-1.03Zm2.086 1.591a.751.751 0 0 1 .976-.414.75.75 0 1 1-.976.414Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWalkSlowMedium;
