import type { SVGProps } from "react";
const SvgStreetLocationPinSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.517 1.96h-.034A2.57 2.57 0 0 0 9 4.6a2.5 2.5 0 0 0 .35 1.245l1.73 2.91.431.726.43-.727 1.72-2.91.001-.003A2.5 2.5 0 0 0 14 4.598a2.57 2.57 0 0 0-2.483-2.638Zm-1.092 1.494A1.57 1.57 0 0 1 11.5 2.96 1.57 1.57 0 0 1 13 4.575v.014c0 .263-.07.521-.202.748l-1.29 2.182-1.298-2.184a1.5 1.5 0 0 1-.21-.75v-.01a1.57 1.57 0 0 1 .425-1.12ZM2 10h7.12l-.131.606-2.5 11.5-.978-.212L7.88 11H2v-1Zm12.5 0h-.62l.131.606 2.5 11.5.978-.212L15.12 11H21v-1h-6.5ZM12 13v-2h-1v2h1Zm0 4v-2h-1v2h1Zm0 2v2h-1v-2h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStreetLocationPinSmall;
