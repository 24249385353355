import type { SVGProps } from "react";
const SvgHandPlusCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 7.5a4.5 4.5 0 1 1 9.001 0A4.5 4.5 0 0 1 5 7.5ZM9.5 2a5.5 5.5 0 1 0 .001 11A5.5 5.5 0 0 0 9.5 2Zm.5 9V8h3V7h-3V4H9v3H6v1h3v3h1Zm-1.997 4.818-3.578-2.5a1.5 1.5 0 0 0-1.873 2.344l5.917 5.213.142.125h3.925l.862.855.146.145h8.857l-.846-.852-5.028-5.064-.008-.008c-1.188-1.144-2.75-2.045-4.4-2.076H10c-.633 0-1.145.244-1.495.635a2.05 2.05 0 0 0-.502 1.183ZM3.84 14.13l4.69 3.276c.35.37.852.594 1.47.594h2v-1h-2c-.374 0-.613-.134-.763-.301a1.033 1.033 0 0 1-.243-.695c.002-.268.094-.522.256-.702.153-.171.39-.302.75-.302h2.104c1.305.026 2.634.751 3.718 1.792L19.999 21h-6.043l-.861-.855-.147-.145h-3.96l-5.783-5.095-.009-.008-.009-.007a.5.5 0 0 1 .627-.78l.013.01.013.01Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandPlusCircleSmall;
