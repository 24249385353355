import type { SVGProps } from "react";
const SvgCloudVocMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#cloud-voc-medium_svg__a)">
      <path
        d="M8.994 16.62c-4.817 0-5.774 9.63 1.8 9.63h15.752c7.473 0 7.2-9.63 1.575-9.406 2.475-8.287-8.55-12.095-11.926-4.48-2.972-2.423-7.723-.097-7.2 4.255Z"
        stroke="currentColor"
      />
      <path
        d="M11.552 17.495h1.621l.804 4.816.792-4.816h1.582l-1.442 6.424H12.98l-1.428-6.424Zm7.348-.137c.96 0 1.599.323 1.915.967.126.255.214.555.261.904.05.346.073.847.073 1.5 0 .99-.085 1.721-.255 2.193-.275.762-.941 1.142-1.999 1.142-1.049 0-1.714-.39-1.995-1.173-.167-.463-.25-1.208-.25-2.237 0-.62.027-1.11.083-1.469a2.85 2.85 0 0 1 .29-.921c.32-.604.945-.906 1.876-.906Zm-.005 1.046c-.29 0-.479.148-.567.444-.082.273-.123.878-.123 1.815 0 .861.034 1.458.101 1.789.08.392.277.588.593.588.282 0 .468-.143.558-.433.091-.287.137-.883.137-1.786 0-1.005-.041-1.656-.123-1.955-.085-.308-.277-.462-.576-.462Zm5.788 3.002h1.467v.189c-.002.653-.089 1.148-.259 1.485-.176.343-.411.59-.707.743-.302.158-.707.237-1.213.237-.633 0-1.115-.148-1.446-.444a1.855 1.855 0 0 1-.567-.977c-.1-.406-.15-1.053-.15-1.94 0-.733.033-1.287.1-1.662.065-.375.183-.681.353-.918.364-.504.955-.756 1.776-.756.712 0 1.235.174 1.569.523.196.202.328.428.392.675.067.248.1.636.1 1.166h-1.46v-.14c0-.35-.026-.61-.078-.783-.08-.27-.257-.404-.532-.404-.334 0-.53.208-.589.624-.053.354-.08.88-.08 1.578 0 .793.026 1.38.076 1.757.061.449.26.673.597.673.252 0 .424-.114.514-.343.091-.229.137-.656.137-1.283Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="cloud-voc-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCloudVocMedium;
