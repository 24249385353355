import type { SVGProps } from "react";
const SvgQrcodeTwoTicketsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.5 4.5V9H6V5.992h1.5v-1.5h-3V4.5Zm0 7.494h3.002v-1.5H4.5v1.5ZM15 7.5h-1.502V6H15v1.5Zm1.502 0h1.5v-3h-1.5v3ZM8.998 4.496h4.5v1.5h-3v1.503h-3v-1.5H9V4.496Zm10.502 3h3v-1.5H24v1.5h1.5v-3h-3v1.498h-3v1.502ZM6 25.5H4.5v-3H6v3Zm-1.5-9.003H6V15h1.502v-1.5H6v1.497H4.5v1.5ZM6 19.496H4.5v-1.502H6V16.5h1.502V18H6v1.496ZM7.502 21H6v-1.5h1.502V21Zm8.998 4.496h9v-9h-9v9Zm1.5-1.502h6v-6h-6v6Zm4.5-1.498h-3v-3h3v3Zm-12.002-4.5h3v-1.499H15v-1.5h-1.502v1.499h-3v1.5Zm10.504-2.999V15h-1.5v-.003h-1.5v-1.5h1.5V12h1.5v2.997Zm10.5 4.503h-1.5v1.497h-3v1.5h3V21h1.5v-1.5Zm-1.5-3h1.5V18h-1.5v1.497h-3v-1.5h3V16.5Zm-13.503-3v-1.503h3.002v-1.5H15v.003h-.002V12h-1.5v1.5h3Zm7.501 0h-1.5v-3H24v2.997h1.5v1.5H24V13.5Zm6.002 12h1.5v-3h-1.5v3ZM13.498 11.994H12V15h-1.5v-4.506h3v1.5ZM18 31.502h1.502v-1.5H18v1.5Zm-6.003 0h-1.5v-3.003h1.5v3.003ZM15 25.497h-1.5v.003H12V27h1.5v1.497h3.002v-1.5H15l-.002-1.5Zm6.004 3h-3.002v-1.5h3.002v1.5Zm8.998 3h1.5v-1.5h-1.5v1.5Zm-18.003-9h-1.5v-1.5h1.5V19.5H15V21h-3.002v1.497Zm-1.5 3h1.5V24H15v-1.5h-3.002v1.497h-1.5v1.5Zm21.004-12h-1.5v-1.5h-1.5v-1.5h3v3ZM13.498 31.501H16.5v-1.5h-3.002v1.5ZM30 28.497h-1.5V27H27v2.997h1.5v1.5h-3v-3H24v1.505h-1.5v1.5H21v-3h1.5v-1.5l4.5-.005V24h1.5v2.997H30v1.5ZM27 16.5v-3l-1.5-.003v-1.5h3v3H30v1.5l-3 .003Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeTwoTicketsMedium;
