import type { SVGProps } from "react";
const SvgSaSl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.808 4.74a1.874 1.874 0 0 0 1.878-1.869C5.686 1.838 4.846 1 3.808 1A1.874 1.874 0 0 0 1.93 2.871c0 1.031.839 1.869 1.878 1.869Zm10.78 1.499-3.361 2.329-4.552-4.53-2.113 2.104 5.183 5.156.021-.016.004.004 4.656-3.226 3.577 3.558.997-.992-4.412-4.387Zm-.325 3.667 2.766 2.751-.996.992-1.931-1.922L9.405 15 1 6.638l.997-.991 7.567 7.53 4.699-3.271Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSl;
