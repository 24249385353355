import type { SVGProps } from "react";
const SvgMedicalFacilitySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m12.704 3.356-.354-.353-.353.353-8 8 .707.708.646-.647v9.293h4.99v-5h4v5h5.01v-9.293l.647.647.707-.707-8-8Zm5.646 7.061-6-6-6 6v9.293h2.99v-5h6v5h3.01v-9.293Zm-7-2.707h2v2h2v2h-2v2h-2v-2h-2v-2h2v-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMedicalFacilitySmall;
