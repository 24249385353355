import type { SVGProps } from "react";
const SvgPetrolStationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.75 6.749a2.5 2.5 0 0 1 2.5-2.5h10.5a2.5 2.5 0 0 1 2.5 2.5v25H8.75v-25Zm-1 25v-25c0-1.932 1.568-3.5 3.5-3.5h10.5c1.932 0 3.5 1.568 3.5 3.5v14.5h.25c.825 0 1.53.205 2.028.707.496.5.703 1.208.722 2.032V25.5c0 .408.007.87.152 1.23a.798.798 0 0 0 .271.37c.112.08.287.15.577.15.295 0 .472-.07.584-.148a.777.777 0 0 0 .27-.36c.143-.354.148-.814.146-1.24V12.18l-3.634-4.36.768-.64 3.75 4.5.116.139v13.7c.002.392.006 1.042-.22 1.599-.121.297-.315.59-.624.805-.31.216-.698.327-1.156.327-.46 0-.847-.114-1.157-.335a1.8 1.8 0 0 1-.619-.811c-.223-.554-.223-1.194-.224-1.57v-1.528c-.017-.683-.186-1.098-.431-1.345-.244-.246-.647-.411-1.319-.411h-.25v9.499H27v1H6v-1h1.75Zm3-24h11.5v7h-11.5v-7Zm1 1v5h9.5v-5h-9.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPetrolStationMedium;
