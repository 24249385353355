import type { SVGProps } from "react";
const SvgFolderLockSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.5 6h-.309l-.138.276L14.19 8H4v10h9v-1H5V9h9.809l.138-.276L15.81 7h3.187L19 12h1l-.005-5.5V6H15.5Zm2.5 8.5a.499.499 0 1 1 1 0V16h-1v-1.5ZM17 16v-1.5a1.5 1.5 0 1 1 3 0V16h2v6h-7v-6h2Zm2 1.969V20h-1v-2.031h1ZM16 21v-4h5v4h-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderLockSmall;
