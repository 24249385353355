import type { SVGProps } from "react";
const SvgTabletSmartphoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.5 2A1.5 1.5 0 0 0 2 3.5v16A1.5 1.5 0 0 0 3.5 21H11v-1H3.5a.5.5 0 0 1-.5-.5v-16a.5.5 0 0 1 .5-.5H7v1h4V3h3.5a.5.5 0 0 1 .5.5V4h1v-.5A1.5 1.5 0 0 0 14.5 2h-11Zm10 4a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-14a.5.5 0 0 0-.5-.5H19v1h-4V6h-1.5Zm-1.5.5A1.5 1.5 0 0 1 13.5 5h7A1.5 1.5 0 0 1 22 6.5v14a1.5 1.5 0 0 1-1.5 1.5h-7a1.5 1.5 0 0 1-1.5-1.5v-14ZM11 19H7v-1h4v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTabletSmartphoneSmall;
