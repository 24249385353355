import type { SVGProps } from "react";
const SvgBanknotesDollarLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 14h39v16h-1.99v3H41v2H2V18h1.997l-.007-1.497L3.987 16H6v-2Zm1 2h36.01v13H44V15H7v1Zm-2.003 2H41v14h1.01V17H4.993l.004 1ZM3 19v15h37V19H3Zm18.5 4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM17 26.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM6.5 20H6v.5c0 .824-.676 1.5-1.5 1.5H4v9h.5c.824 0 1.5.676 1.5 1.5v.5h31v-.5c0-.824.676-1.5 1.5-1.5h.5v-9h-.5c-.824 0-1.5-.676-1.5-1.5V20H6.5ZM5 22.95c.977-.2 1.75-.973 1.95-1.95h29.1c.2.977.973 1.75 1.95 1.95v7.1c-.977.2-1.75.973-1.95 1.95H6.95A2.512 2.512 0 0 0 5 30.05v-7.1Zm4 3.55a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm22 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM30 26.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBanknotesDollarLarge;
