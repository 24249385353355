import type { SVGProps } from "react";
const SvgIc97Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-97-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-97-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm20.59 8.696h2.183c.18.896.699 1.344 1.558 1.344.485 0 .878-.163 1.179-.49.306-.332.54-.847.704-1.543.105-.443.182-.946.23-1.51-.354.495-.718.849-1.092 1.06-.37.205-.82.308-1.353.308-.849 0-1.587-.248-2.215-.744a3.543 3.543 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.765-.696 1.682-1.044 2.753-1.044.659 0 1.268.148 1.827.443a3.96 3.96 0 0 1 1.408 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.296 2.99-.886 4.129-.77 1.482-1.936 2.222-3.497 2.222-1.039 0-1.898-.287-2.578-.862-.675-.575-1.066-1.347-1.171-2.317Zm3.844-6.985c-.495 0-.915.203-1.258.609-.342.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.338.544.812.815 1.424.815.554 0 .997-.221 1.329-.664.311-.417.467-.912.467-1.487 0-.607-.161-1.12-.483-1.543a1.606 1.606 0 0 0-1.337-.656Zm13.02-1.677v1.962a45.8 45.8 0 0 0-.561.64c-.433.496-.863 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-97-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc97Negative;
