import type { SVGProps } from "react";
const SvgThumbUpMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m14.62 16.087 6.426-7.048a1.002 1.002 0 0 1 1.645 1.052l-2.156 5.476-.27.683h5.985a1 1 0 1 1 0 2H24v1h2.25a1 1 0 1 1 0 2H24v1h2.25a1 1 0 1 1 0 2h-1.5v.001H24v1h.75a1 1 0 0 1 0 2v.5-.5h-7.5c-1.093 0-2.08-.185-2.79-.739-.683-.531-1.205-1.48-1.21-3.236V16.25h1.22l.15-.163Zm-2.37-.835v-.002h1.78l6.286-6.894.007-.009.008-.008A2.002 2.002 0 0 1 23.75 9.75c0 .241-.041.477-.125.698l-.003.006-1.888 4.796h4.516a2 2 0 0 1 1.323 3.5 1.997 1.997 0 0 1 0 3 2 2 0 0 1-1.098 3.488 2 2 0 0 1-1.725 3.012v-.5.5h-7.5c-1.17 0-2.431-.19-3.406-.949a3.604 3.604 0 0 1-.594-.585v1.547H7.749V15.252h4.501Zm0 1H8.749v11.012h3.501V16.252Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgThumbUpMedium;
