import type { SVGProps } from "react";
const SvgArrowCircleTimeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.5 13.75H7.488C9.166 9.987 13.404 7.25 18 7.25c6.249 0 10.75 4.501 10.75 10.75S24.249 28.75 18 28.75c-5.253 0-9.247-3.181-10.405-7.87l-.97.24c1.272 5.15 5.678 8.63 11.375 8.63 6.801 0 11.75-4.949 11.75-11.75S24.801 6.25 18 6.25c-4.434 0-8.596 2.353-10.75 5.82V7.516h-1v7.235h7.25v-1ZM16.75 12v7.25H24v-1h-6.25V12h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTimeMedium;
