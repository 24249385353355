import type { SVGProps } from "react";
const SvgSaSh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.303 3.133c1.019 0 2.08.291 2.9.698l.66-2.23C10.723 1.155 9.463 1 8.242 1 5.56 1 3.041 2.24 3.041 5.092c0 1.978 1.433 2.755 2.726 3.456 1.065.577 2.036 1.103 2.036 2.206 0 1.454-1.321 2.114-2.662 2.114-1.2 0-2.34-.447-3.42-.932L1 14.244c1.26.446 2.641.756 3.98.756 3.061 0 5.743-1.202 5.743-4.518 0-2.138-1.538-3.017-2.866-3.776-1.008-.577-1.896-1.084-1.896-2.022 0-1.163 1.34-1.551 2.342-1.551Zm9.959-1.9H15.46l-2.92 13.535h2.8l1.28-5.934h5.64l-1.28 5.934h2.802l2.92-13.535h-2.801L22.722 6.7h-5.64l1.18-5.468Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSh;
