import type { SVGProps } from "react";
const SvgDiamondLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 3a3 3 0 0 1-3 3v1a3 3 0 0 1 3 3h1a3 3 0 0 1 3-3V6a3 3 0 0 1-3-3h-1Zm1.884 3.605A4.02 4.02 0 0 0 11.5 8.062 4.019 4.019 0 0 0 9.938 6.5a4.02 4.02 0 0 0 1.457-1.384 3.515 3.515 0 0 0 1.489 1.489ZM16.368 10h-.266l-.149.222-7.368 11-.197.293.211.284 17.5 23.5.402.538.4-.538 17.5-23.5.211-.284-.197-.293-7.368-11-.148-.222H16.367Zm-.178 1.665L9.937 21h8.995l-2.742-9.335ZM9.996 22l15.24 20.466L19.227 22h-9.23Zm17.77 20.465L43.003 22h-9.228l-6.01 20.465ZM43.062 21l-6.253-9.334L34.07 21h8.993Zm-7.1-10h-8.559l5.83 9.293L35.964 11Zm-16.197 9.293L25.596 11h-8.56l2.73 9.293Zm6.734-8.852L20.503 21h11.994L26.5 11.44ZM32.733 22l-6.232 21.221L20.268 22h12.465ZM6 11a2 2 0 0 1-2 2v1a2 2 0 0 1 2 2h1a2 2 0 0 1 2-2v-1a2 2 0 0 1-2-2H6Zm.5 3.341a3.016 3.016 0 0 0-.841-.841 3.02 3.02 0 0 0 .841-.841c.222.333.508.62.841.841a3.02 3.02 0 0 0-.841.841Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDiamondLarge;
