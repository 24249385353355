import type { SVGProps } from "react";
const SvgQuestionAnswerLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 10h25v6h10v19h-6v4.613l-.832-.74L29.81 35H18v-6h-.81l-4.358 3.874-.832.74V29H6V10Zm25 10v-3h9v17h-6v3.387l-3.668-3.26L30.19 34H19v-5h18v-1h-6v-3h5v-1h-5v-3h6v-1h-6ZM7 11v17h6v3.387l3.668-3.26.142-.127H30V11H7Zm8.38 6.57a4.15 4.15 0 0 1 .51-2.11 2.9 2.9 0 0 1 1.17-1.08 3.631 3.631 0 0 1 1.67-.38 3.199 3.199 0 0 1 2.35.85 2.62 2.62 0 0 1 .79 1.94 2.86 2.86 0 0 1-.61 1.82c-.368.443-.77.857-1.2 1.24-.226.205-.433.43-.62.67a1.897 1.897 0 0 0-.24.61 5.772 5.772 0 0 0-.1 1.19h-.9a5.4 5.4 0 0 1 .21-1.7 2.18 2.18 0 0 1 .37-.63c.15-.18.53-.58 1.15-1.19a2.79 2.79 0 0 0 1-1.95 2 2 0 0 0-.53-1.38 2.17 2.17 0 0 0-1.65-.63 2.18 2.18 0 0 0-2 1.11 3.65 3.65 0 0 0-.37 1.62h-1Zm3.83 5.92V25h-1.14v-1.51h1.14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQuestionAnswerLarge;
