import type { SVGProps } from "react";
const SvgFaceSmilingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 12c0-4.47 3.53-8 8-8 4.47 0 8 3.53 8 8 0 4.47-3.53 8-8 8-4.47 0-8-3.53-8-8Zm8-9c-5.022 0-9 3.977-9 9 0 5.022 3.978 9 9 9s9-3.978 9-9c0-5.023-3.978-9-9-9ZM6.988 14.697C7.825 16.643 9.72 18 12 18c2.28 0 4.175-1.357 5.012-3.303l-.919-.395A4.417 4.417 0 0 1 12 17a4.42 4.42 0 0 1-4.094-2.698l-.918.396ZM8 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm7-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceSmilingSmall;
