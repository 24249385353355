import type { SVGProps } from "react";
const SvgClipboardUsersSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.5 2H9v1H2v18h20V3h-6V2H9.5ZM16 4v1H9V4H3v16h18V4h-5Zm-1 0h-5V3h5v1Zm-4 4H4V7h7v1Zm-7 3h5v-1H4v1Zm3 3H4v-1h3v1Zm4.65-2.68c0 .5.132.985.365 1.394-1.59.937-2.425 2.938-2.425 4.786v.5h8.65v-.5a6.32 6.32 0 0 0-.129-1.26H20v-.5c0-1.859-.873-3.82-2.456-4.746.248-.422.386-.923.386-1.434 0-1.31-.906-2.56-2.26-2.56-.606 0-1.177.195-1.6.598a2.13 2.13 0 0 0-.624 1.214c-1.069.247-1.796 1.331-1.796 2.508Zm5.198.435a2.05 2.05 0 0 1-.782.328 2.767 2.767 0 0 1-.266.64c.938.551 1.62 1.473 2.02 2.517h1.156c-.147-1.513-.949-2.902-2.128-3.485Zm.082-2.195c0 .69-.339 1.216-.778 1.44-.114-1.015-.765-1.907-1.689-2.165.06-.22.167-.39.297-.513.207-.197.516-.322.91-.322.646 0 1.26.63 1.26 1.56Zm-1.814 3.93a2.05 2.05 0 0 1-1.206.39 2.05 2.05 0 0 1-1.215-.397c-1.168.59-1.941 1.997-2.082 3.517h6.603c-.143-1.519-.93-2.924-2.1-3.51ZM13.91 9.76c-.624 0-1.26.62-1.26 1.56 0 .94.636 1.56 1.26 1.56s1.26-.62 1.26-1.56c0-.94-.636-1.56-1.26-1.56Z"
      fill="#010101"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardUsersSmall;
