import type { SVGProps } from "react";
const SvgDeskAdjustableMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m22.896 9.096-2.146 2.146V4.5h-1v6.743l-2.146-2.146-.708.707 3 3 .354.354.354-.354 3-3-.708-.707Zm5.208-3.9-.354-.354-.354.354-3 3 .708.707 2.146-2.147V13.5h1V6.756l2.146 2.147.708-.707-3-3ZM4.75 15.249h26.5v16.25h-1v-12.25H5.75v12.25h-1v-16.25Zm1 3h24.5v-2H5.75v2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDeskAdjustableMedium;
