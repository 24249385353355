import type { SVGProps } from "react";
const SvgFaceSadLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 23.5C7 13.835 14.835 6 24.5 6S42 13.835 42 23.5 34.165 41 24.5 41 7 33.165 7 23.5ZM24.5 5C14.283 5 6 13.283 6 23.5S14.283 42 24.5 42 43 33.717 43 23.5 34.717 5 24.5 5Zm-6 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.278 1.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Zm11.445-.831a1.5 1.5 0 1 1 1.667 2.495 1.5 1.5 0 0 1-1.667-2.495ZM30.5 18a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-6 8.998a12.09 12.09 0 0 0-9.566 4.696l.792.612a11.089 11.089 0 0 1 17.549 0l.79-.612a12.09 12.09 0 0 0-9.565-4.696Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceSadLarge;
