import type { SVGProps } from "react";
const SvgRe20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="re-20_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#re-20_svg__a)" fill="#EB0000">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.979 1.979 0 0 1-.197-.625c-.037-.253-.088-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.709-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.171-.949-.514-1.202-.343-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm9.382 9.508v2.056h-8.33c0-.627.1-1.226.3-1.795.207-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.941-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.686-.474-1.17-.474-1.193 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.765-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.313 1.825-.941 2.531a4.72 4.72 0 0 1-.577.562c-.18.137-.652.472-1.416 1.005-.728.506-1.25.893-1.567 1.162a6.348 6.348 0 0 0-.87.926h5.427Zm4.904-9.785c.88 0 1.656.269 2.325.807.665.538 1.148 1.366 1.448 2.484.227.854.34 1.782.34 2.784 0 1.661-.295 3.027-.886 4.098-.348.622-.804 1.102-1.368 1.44a3.557 3.557 0 0 1-1.875.505c-.944 0-1.75-.284-2.42-.854-1.192-1.012-1.788-2.742-1.788-5.189 0-1.74.311-3.138.933-4.192a3.771 3.771 0 0 1 1.385-1.393 3.788 3.788 0 0 1 1.906-.49Zm-.087 1.954c-.76 0-1.276.438-1.55 1.313-.217.696-.325 1.672-.325 2.927 0 1.107.111 1.996.333 2.665.284.87.812 1.306 1.581 1.306.639 0 1.113-.338 1.424-1.013.312-.675.467-1.706.467-3.093 0-1.181-.11-2.112-.332-2.792-.285-.875-.818-1.313-1.598-1.313Z" />
    </g>
    <defs>
      <clipPath id="re-20_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe20;
