import type { SVGProps } from "react";
const SvgBulbOnSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 4v1.5h1V4h-1Zm4.006.811-.702 1.287.878.48.702-1.288-.878-.479ZM12 8c-2.224 0-4 1.776-4 4 0 1.455.833 2.764 2.037 3.5h3.94C15.151 14.861 16 13.475 16 12c0-2.224-1.776-4-4-4Zm-5 4c0 1.62.81 3.06 2 3.995v2.46l.335.117 1.665.583V20h2v-.845l1.665-.583.335-.117v-2.427c1.205-.905 2-2.428 2-4.028 0-2.776-2.224-5-5-5s-5 2.224-5 5Zm3 4.5v1.245l1.665.583.335.117.335-.117L14 17.745V16.5h-4Zm7.32-8.28 1.282-.707.483.875-1.283.708-.483-.875Zm1.179 4.28 1.5.004.002-1-1.5-.004-.002 1Zm.12 3.987-1.3-.693.471-.883 1.299.693-.47.883Zm-13.32-.02 1.31-.78-.512-.86-1.31.78.511.86ZM5.5 12.5H4v-1h1.5v1Zm-.66-4.09 1.28.824.541-.84-1.28-.825-.542.841Zm3.478-1.826-.709-1.29.876-.481.71 1.289-.877.482Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBulbOnSmall;
