import type { SVGProps } from "react";
const SvgCloudMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m25.92 5.926.65-1.288-1.308.61c-.729.339-1.514.744-2.234 1.485-.634.652-1.194 1.54-1.645 2.82a4.868 4.868 0 0 0-.175-.066c-1.997-.725-4.471-.138-5.8 2.23-2.192-1.226-5.127.33-5.134 3.133-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.841 1.073 2.548.708.713 1.75 1.155 3.125 1.155h10.5c1.34 0 2.38-.444 3.104-1.146a3.918 3.918 0 0 0 1.16-2.522 3.95 3.95 0 0 0-.006-.688c1.017.09 2.283-.106 3.486-.586 1.582-.633 3.152-1.796 3.962-3.603l.52-1.16-1.17.495c-2.932 1.24-5.312.405-6.725-1.18-1.438-1.614-1.886-4.006-.912-5.934Zm.584 10.925c.83.156 2.097.036 3.369-.472 1.034-.413 2.022-1.064 2.742-1.97-2.744.684-5.06-.235-6.53-1.884-1.379-1.546-2.005-3.734-1.527-5.765a4.814 4.814 0 0 0-.813.67c-.536.551-1.047 1.347-1.47 2.602a4.57 4.57 0 0 1 1.488 1.66c.482.918.663 2.039.417 3.292a2.878 2.878 0 0 1 1.869 1.078c.189.24.34.506.455.79Zm-10.446-4.199c1.01-2.29 3.123-2.837 4.81-2.225.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.2.67.7-.028c.777-.031 1.332.274 1.692.731.371.471.557 1.134.505 1.826a2.918 2.918 0 0 1-.86 1.88c-.518.502-1.301.863-2.408.863H12c-1.15 0-1.922-.363-2.415-.86-.498-.502-.753-1.183-.784-1.886-.03-.707.167-1.4.528-1.905.355-.496.855-.799 1.471-.799h.563l-.066-.559c-.296-2.473 2.358-3.737 3.986-2.404l.51.417.265-.602Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudMoonMedium;
