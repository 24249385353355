import type { SVGProps } from "react";
const SvgFaceSadMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.75 18c0-6.844 5.407-12.25 12.25-12.25S30.25 11.156 30.25 18c0 6.843-5.407 12.25-12.25 12.25S5.75 24.843 5.75 18ZM18 4.75C10.605 4.75 4.75 10.604 4.75 18c0 7.395 5.855 13.25 13.25 13.25S31.25 25.395 31.25 18c0-7.396-5.855-13.25-13.25-13.25Zm0 13.5c-3.316 0-6.07 1.973-7.289 4.802l.918.395c1.068-2.478 3.473-4.197 6.37-4.197 2.9 0 5.303 1.719 6.371 4.197l.918-.395C24.07 20.223 21.316 18.25 18 18.25Zm-5.75-4.75a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm9-1a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceSadMedium;
