import type { SVGProps } from "react";
const SvgMusicNotesSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 1.9v13.6a3.5 3.5 0 1 1-1-2.45V6.1L9 7.916V18.5a3.5 3.5 0 1 1-1-2.45V4.083l.41-.075 11-2 .59-.107Zm-11 5 10-1.817V3.099L9 4.917v1.984ZM5.5 16a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm8.5-.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMusicNotesSmall;
