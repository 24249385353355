import type { SVGProps } from "react";
const SvgFreightWagonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.75 12.25h26.5v9.009h1.752v1H31.25v1.491h-3.026a1.25 1.25 0 1 1-2.448 0H10.224a1.25 1.25 0 1 1-2.448 0H4.75v-1.491H2.997v-1H4.75V12.25Zm1 1v9.5h6.5v-9.5h-6.5Zm7.5 0v9.5h9.5v-9.5h-9.5Zm17 9.5h-6.5v-9.5h6.5v9.5ZM9 23.754a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm17.75.25c0-.137.113-.25.25-.25s.25.113.25.25a.25.25 0 0 1-.5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonMedium;
