import type { SVGProps } from "react";
const SvgChevronSmallLeftRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.67 11.644 16.72 7.74l-.703.711L19.607 12l-3.588 3.548.703.711 3.948-3.904.36-.355-.36-.356Zm-17.3 0L7.32 7.74l.703.711L4.433 12l3.588 3.548-.703.711-3.948-3.904L3.01 12l.36-.356Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallLeftRightSmall;
