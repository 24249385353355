import type { SVGProps } from "react";
const SvgBellSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10 3h4v1.277a5.26 5.26 0 0 1 2.806 1.993C17.706 7.52 18 9.003 18 10v6c0 .337.105.853.406 1.27.282.392.757.73 1.594.73v1h-5.044a2.21 2.21 0 0 1-.895 1.423c-.565.409-1.306.577-2.061.577-.755 0-1.496-.168-2.061-.577A2.21 2.21 0 0 1 9.044 19H4v-1c.337 0 .853-.105 1.27-.406.392-.282.73-.757.73-1.594v-6c0-.997.295-2.48 1.194-3.73A5.26 5.26 0 0 1 10 4.277V3Zm1 1.064c.298-.04.612-.062.942-.064H11v.064ZM12.058 4c.33.002.644.024.942.064V4h-.942Zm-1.985 15a1.2 1.2 0 0 0 .452.613c.34.245.85.387 1.475.387.626 0 1.135-.142 1.475-.387a1.2 1.2 0 0 0 .452-.613h-3.854ZM7 10c0-.837.255-2.103 1.006-3.145C8.738 5.838 9.963 5 12 5s3.262.838 3.994 1.855C16.744 7.897 17 9.163 17 10v6c0 .497.145 1.23.594 1.855.036.049.073.098.112.145H6.304c.416-.47.696-1.125.696-2v-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBellSmall;
