import type { SVGProps } from "react";
const SvgChartColumnTrendMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M28.25 5.25a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 0 0-1.532 3.285l-5.249 7.348a1.996 1.996 0 0 0-2.332.683l-3.903-1.56a2 2 0 1 0-3.809.564L7.95 17.15a2 2 0 1 0 .625.78l4.475-3.58a1.997 1.997 0 0 0 2.812-.416l3.904 1.56a2 2 0 1 0 3.516-1.03l5.249-7.347a2 2 0 1 0 .719-3.867Zm-23.5 15.5a1 1 0 1 1 2.001.001 1 1 0 0 1-2.001-.001Zm6.5 1.006h4V31.75h3.5v-8.994h4v8.994h3.5V13.756h4V32.75H4.75v-6.994h4v5.994h3.5V19.756Zm3 11.994V20.756h-2V31.75h2Zm7.5 0v-7.994h-2v7.994h2Zm7.5-16.994V31.75h-2V14.756h2Zm-22.5 12v4.994h-2v-4.994h2Zm6.5-15.006a1 1 0 1 0 .001 2.001 1 1 0 0 0-.001-2.001Zm6.5 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnTrendMedium;
