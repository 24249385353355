import type { SVGProps } from "react";
const SvgClapperboardLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M33.012 5h8.061c.778 0 1.523.311 2.071.863.549.553.856 1.3.856 2.08V15H4V7.943c0-.78.307-1.527.856-2.08A2.918 2.918 0 0 1 6.926 5h26.087Zm.284 1h5.857l-4.444 8h-5.857l4.444-8ZM43 14h-7.147l4.444-8h.777c.51 0 .999.204 1.36.568.362.364.566.859.566 1.375V14ZM32.152 6l-4.444 8H21.85l4.445-8h5.856Zm-11.445 8 4.445-8h-5.857l-4.445 8h5.857Zm-2.556-8-4.444 8H7.85l4.444-8h5.857ZM6.706 14l4.444-8H6.926c-.51 0-.999.204-1.36.568l-.355-.352.354.352A1.952 1.952 0 0 0 5 7.943V14h1.706ZM4.5 16H4v28h40V16H4.5ZM5 43V17h38v26H5Zm13.744-20.436L18 22.147v15.706l.744-.417 12.5-7 .78-.436-.78-.436-12.5-7ZM19 30v-6.147L29.977 30 19 36.147V30Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClapperboardLarge;
