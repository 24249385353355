import type { SVGProps } from "react";
const SvgUserChangeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 4.25c-1.335 0-2.5 1.396-2.5 3.25 0 .646.144 1.253.398 1.762.458.926 1.235 1.488 2.102 1.488.868 0 1.643-.563 2.102-1.49.252-.51.398-1.117.398-1.76 0-1.854-1.165-3.25-2.5-3.25Zm2.541 6.184c-.613.786-1.494 1.316-2.541 1.316-1.042 0-1.922-.525-2.535-1.307-2.081 1.156-2.872 3.525-2.954 6.313h10.987c-.083-2.797-.884-5.166-2.957-6.322Zm-5.596-.846A5.001 5.001 0 0 1 6.25 7.5c0-2.16 1.394-4.25 3.5-4.25 2.105 0 3.5 2.09 3.5 4.25 0 .741-.157 1.453-.442 2.077 2.662 1.499 3.447 4.581 3.447 7.679v.5H3.254v-.5c0-3.083.77-6.168 3.44-7.668Zm17.944-3.03c1.405 1.877 2.2 4.479 2.16 7.649l2.85-2.81.702.712-3.759 3.706-.351.347-.352-.347-3.74-3.697.702-.712 2.947 2.912c.059-3.057-.697-5.474-1.96-7.162-1.403-1.876-3.462-2.891-5.848-2.884l-.004-1c2.696-.008 5.056 1.15 6.653 3.285Zm1.61 12.692c-1.334 0-2.5 1.396-2.5 3.25 0 .757.204 1.465.545 2.023.47.77 1.174 1.227 1.956 1.227s1.486-.457 1.957-1.227c.34-.558.543-1.266.543-2.023 0-1.854-1.165-3.25-2.5-3.25Zm2.517 6.215c-.611.768-1.485 1.285-2.516 1.285-1.03 0-1.904-.517-2.515-1.284-1.833 1.312-2.852 3.752-2.97 6.289h10.98c-.12-2.548-1.146-4.982-2.98-6.29Zm-5.557-.852a5.008 5.008 0 0 1-.46-2.113c0-2.16 1.395-4.25 3.5-4.25 2.107 0 3.5 2.09 3.5 4.25 0 .754-.162 1.48-.458 2.112 2.34 1.63 3.465 4.698 3.465 7.643v.5H19.753v-.5c0-2.93 1.116-6.007 3.456-7.642ZM9.749 19.8l.352.347 3.75 3.696-.702.712-2.955-2.913c-.06 3.061.696 5.495 1.96 7.197 1.405 1.894 3.464 2.922 5.847 2.914l.004 1c-2.699.01-5.058-1.167-6.654-3.318-1.404-1.89-2.197-4.505-2.16-7.678l-2.84 2.806-.702-.711 3.75-3.705.35-.347Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserChangeMedium;
