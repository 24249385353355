import type { SVGProps } from "react";
const SvgHandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#hand-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M17.75 19.5V6.75c0-.549.451-1 1-1 .549 0 1 .451 1 1V19.5h1V8.25c0-.549.451-1 1-1 .549 0 1 .451 1 1V19.5h1v-6.779c.016-.536.46-.971 1-.971.548 0 1 .451 1 1V24c0 2.147-.366 3.782-.725 4.872a9.866 9.866 0 0 1-.568 1.378h-9.922l-.075-.127-.584-.988c-.486-.82-1.137-1.924-1.804-3.053-1.335-2.26-2.723-4.614-2.954-5.016-.214-.373-.317-.678-.328-.921a.76.76 0 0 1 .248-.606c.39-.39 1.033-.39 1.423 0l3.435 3.435.854.853V8.25c0-.549.45-1 1-1 .548 0 1 .451 1 1V19.5h1Zm6-8.481a1.985 1.985 0 0 1 1-.269c1.1 0 2 .899 2 2V24c0 2.263-.385 4.003-.776 5.186-.195.59-.39 1.042-.54 1.349a6.701 6.701 0 0 1-.24.446l-.016.026-.005.008-.001.003-.001.001-.421-.269.42.27-.147.23H13.964l-.145-.245.43-.255-.43.254v-.001l-.004-.005-.01-.018-.043-.073-.163-.275-.584-.988-1.804-3.053a1242.496 1242.496 0 0 1-2.96-5.027c-.25-.437-.44-.905-.46-1.377a1.76 1.76 0 0 1 .54-1.356c.78-.78 2.057-.78 2.837 0l2.582 2.582V8.25c0-1.101.898-2 2-2 .369 0 .715.101 1.012.277A2.005 2.005 0 0 1 18.75 4.75c1.026 0 1.876.78 1.988 1.777a1.986 1.986 0 0 1 1.012-.277c1.101 0 2 .899 2 2v2.769Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="hand-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandMedium;
