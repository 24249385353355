import type { SVGProps } from "react";
const SvgBellLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20 5.5C20 3.564 21.564 2 23.5 2S27 3.564 27 5.5v.996c4.62 1.347 8 5.292 8 10.004v8c0 2.122.591 4.02 1.522 5.374C37.454 31.23 38.692 32 40 32c.706 0 1.21.557 1.508 1.154.313.626.492 1.455.492 2.346 0 .89-.179 1.72-.492 2.346C41.21 38.443 40.706 39 40 39H28.977a5.503 5.503 0 0 1-5.477 5 5.503 5.503 0 0 1-5.477-5H7c-.706 0-1.21-.557-1.508-1.154C5.179 37.22 5 36.391 5 35.5c0-.89.179-1.72.492-2.346C5.79 32.557 6.294 32 7 32c1.308 0 2.546-.77 3.478-2.126C11.408 28.52 12 26.622 12 24.5v-8c0-4.712 3.38-8.657 8-10.004V5.5ZM7 33c-.124 0-.37.113-.614.601-.23.46-.386 1.13-.386 1.899 0 .77.156 1.44.386 1.899.244.488.49.601.614.601h33c.124 0 .37-.113.614-.601.23-.46.386-1.13.386-1.899 0-.77-.156-1.44-.386-1.899-.244-.488-.49-.601-.614-.601H7Zm30.162-1H9.838a6.719 6.719 0 0 0 1.464-1.56C12.362 28.9 13 26.799 13 24.5v-8C13 11.3 17.65 7 23.5 7S34 11.3 34 16.5v8c0 2.298.639 4.4 1.698 5.94.42.613.914 1.143 1.464 1.56ZM26 5.5v.75A12.58 12.58 0 0 0 23.5 6c-.857 0-1.694.086-2.5.25V5.5C21 4.116 22.116 3 23.5 3S26 4.116 26 5.5ZM19.027 39a4.503 4.503 0 0 0 4.473 4 4.503 4.503 0 0 0 4.473-4h-8.945ZM16 18.627c-.015-2.478.472-4.403 1.402-5.817.923-1.404 2.314-2.351 4.21-2.825l-.243-.97c-2.104.526-3.723 1.604-4.803 3.245-1.072 1.631-1.581 3.77-1.566 6.371V23h1v-4.374Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBellLarge;
