import type { SVGProps } from "react";
const SvgSaOm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23 11V5c2.288 0 3 .207 3 1.5 0 1.695-1.402 4.5-3 4.5ZM7 7h14V6H7v1Zm0 3h14V9H7v1Zm-2 1c-1.598 0-3-2.805-3-4.5C2 5.207 2.712 5 5 5v6Zm18-7v-.846L14 1 5 3.154V4C3.218 4 1 4 1 6.5 1 8.663 2.718 12 5 12v1.923L14 15l9-1.077V12c2.282 0 4-3.337 4-5.5C27 4 24.782 4 23 4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaOm;
