import type { SVGProps } from "react";
const SvgWarningLightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.25 7.5V3h-1v4.5h1Zm-5.396 1.146-3-3-.707.707 3 3 .707-.707Zm13.5-2.293-3 3-.707-.707 3-3 .707.707ZM13.25 17.25a5.5 5.5 0 0 1 11 0v8.5h-11v-8.5Zm-1 8.5v-8.5a6.5 6.5 0 0 1 13 0v8.5h2.86l.115.342 1.5 4.5.22.659H7.556l.219-.659 1.5-4.5.114-.341h2.86Zm4-9.25V24h-1v-7.5h1ZM8.944 30.25l1.167-3.5H27.39l1.167 3.5H8.944Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWarningLightMedium;
