import type { SVGProps } from "react";
const SvgDriverlessBusProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.5 5A1.5 1.5 0 0 0 3 6.5V17h2.267a2 2 0 0 0 3.466 0h6.534a2 2 0 0 0 3.466 0H21V6.5A1.5 1.5 0 0 0 19.5 5h-15ZM19 16h1V6.5a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5V16h1a2 2 0 1 1 4 0h6a2 2 0 0 1 4 0ZM5.5 7H5v6h4V7H5.5Zm.5 5V8h2v4H6Zm1 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm10 0a1 1 0 1 0 .001 2A1 1 0 0 0 17 15Zm-7-8h4v6h-4V7Zm1 1v4h2V8h-2Zm4.5-1H15v6h4V7h-3.5Zm.5 5V8h2v4h-2ZM6 20a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1v1Zm-4-2a4 4 0 0 0 4 4v-1a3 3 0 0 1-3-3H2Zm16 1a1 1 0 0 0 1-1h1a2 2 0 0 1-2 2v-1Zm3-1a3 3 0 0 1-3 3v1a4 4 0 0 0 4-4h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDriverlessBusProfileSmall;
