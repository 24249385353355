import type { SVGProps } from "react";
const SvgDisruption = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="disruption_svg__color-immutable"
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#EB0000" height={16} rx={2} width={16} />
    <path
      clipRule="evenodd"
      d="m3.35 14.497.48-3.444 1.165 1.295 3.522-4.386L3 6.708 7.243 1.5h5.021L6.097 6.237 12.5 7.688l-6.572 5.523L7.106 14.5l-3.756-.003Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDisruption;
