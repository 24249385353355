import type { SVGProps } from "react";
const SvgLockOpenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 9a4.25 4.25 0 1 1 8.5 0v4.75h12v16H9.25v-16h4.5V9a3.25 3.25 0 1 0-6.5 0h-1ZM18.5 19.5v6h-1v-6h1Zm-8.25 9.25v-14h15.5v14h-15.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLockOpenMedium;
