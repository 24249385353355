import type { SVGProps } from "react";
const SvgEv17Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-17-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm5.914 0v11.6h-2.317V8.177h-2.73V6.495c.566-.016 1.026-.075 1.38-.175.36-.1.689-.265.985-.492.428-.328.716-.88.865-1.658h1.817Zm10.904 0v1.967c-.063.07-.251.283-.563.643-.434.497-.865 1.124-1.294 1.88a12.781 12.781 0 0 0-1 2.167c-.502 1.444-.806 3.092-.912 4.943h-2.396c.016-.873.166-1.84.452-2.904a19.09 19.09 0 0 1 1.198-3.19c.725-1.502 1.46-2.62 2.206-3.356h-5.554V4.17h7.863Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv17Negative;
