import type { SVGProps } from "react";
const SvgHandWithServiceBellMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.25 6.75a1 1 0 0 1 1-1 1 1 0 1 1-1 1Zm2.69 1.07a1.999 1.999 0 0 0-1.69-3.07 1.999 1.999 0 0 0-1.69 3.07c-2.577.215-5.015.932-6.926 2.206-2.224 1.483-3.722 3.717-3.872 6.724H3v1h28.5v-1h-1.762c-.15-3.007-1.648-5.24-3.872-6.724-1.911-1.274-4.35-1.991-6.926-2.206Zm-1.69.93c-3.052 0-5.947.698-8.061 2.108-1.973 1.316-3.28 3.255-3.425 5.892h22.972c-.146-2.637-1.452-4.576-3.425-5.892-2.114-1.41-5.009-2.108-8.061-2.108Zm-5.004 15.455L6.488 20.18a2.001 2.001 0 0 0-2.5 3.123l8.88 7.823.142.125h5.897l1.366 1.355.146.145h12.582l-.846-.852-7.542-7.596-.008-.008c-1.757-1.69-4.041-2.999-6.432-3.044H15c-.88 0-1.58.338-2.057.87a2.85 2.85 0 0 0-.697 2.085ZM5.903 20.99l7.067 4.941-8.501-4.807c.346-.43.976-.5 1.408-.154l.013.01.013.01Zm7.067 4.941-8.5-4.807c-.344.43-.276 1.06.155 1.406l.01.007.008.008 8.746 7.705h5.931l.147.145 1.365 1.355H30.6l-6.691-6.74c-1.653-1.588-3.705-2.72-5.749-2.76H15c-.607 0-1.033.225-1.312.537-.287.32-.444.762-.447 1.217-.004.455.146.893.428 1.21.275.309.703.536 1.331.536h3v1h-3c-.865 0-1.555-.314-2.03-.819Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandWithServiceBellMedium;
