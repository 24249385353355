import type { SVGProps } from "react";
const SvgBanknotesDollarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 6h17v9h-2v3H2V8h3V6Zm16 8h-1V8H6V7h15v7ZM3 9v8h16V9H3Zm8.887 4.99c0 .367-.228.579-.683.634v-1.272c.23.072.368.123.417.152a.524.524 0 0 1 .266.487Zm-1.693-2.162c0-.338.206-.526.618-.563v1.127c-.23-.056-.39-.127-.482-.21-.09-.082-.136-.2-.136-.354Zm1.01.654v-1.207c.37.047.558.268.566.66h.877c-.008-.415-.136-.738-.382-.97-.249-.232-.602-.358-1.06-.383V10h-.393v.583c-.297.02-.536.07-.715.15a1.33 1.33 0 0 0-.58.474 1.234 1.234 0 0 0-.203.697c0 .236.048.435.146.6.097.165.25.309.46.43.163.094.461.201.892.322v1.37c-.465-.05-.697-.332-.692-.845h-.919c.004.522.186.927.543 1.213.257.205.613.32 1.068.346V16h.392v-.657c.287-.014.524-.06.713-.136.188-.077.361-.198.516-.364.245-.26.367-.58.367-.962 0-.376-.119-.673-.357-.89-.192-.175-.519-.322-.983-.444a6.75 6.75 0 0 1-.256-.064Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBanknotesDollarSmall;
