import type { SVGProps } from "react";
const SvgCitySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 2.5v-.61l-.598.12-5 1L2 3.09V22h1v-4h4v4h1v-2h7v2h1v-2h5v2h1V8h-6V4H8V2.5ZM16 9v10h5V9h-5ZM7 4.5V17H3V3.91l4-.8V4.5ZM15 19H8V5h7v14ZM6 5H4v1h2V5Zm8 2h-1v2h1V7Zm4 6h-1v-2h1v2Zm0 2v2h-1v-2h1Zm2 2v-2h-1v2h1Zm0-4h-1v-2h1v2Zm-6 4v-2h-1v2h1Zm0-4h-1v-2h1v2Zm-2 2v2h-1v-2h1Zm0-4h-1v2h1v-2Zm0-2h-1V7h1v2Zm-2 8v-2H9v2h1Zm0-4H9v-2h1v2Zm0-6H9v2h1V7ZM4 8h2v1H4V8Zm0 3h2v1H4v-1Zm2 3H4v1h2v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCitySmall;
