import type { SVGProps } from "react";
const SvgSaRn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 3.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="m13.007 14.447.011.011-1.036 1.084-2.092-2A4.692 4.692 0 0 1 6.5 15c-2.62 0-4.75-2.13-4.75-4.75a4.69 4.69 0 0 1 1.302-3.249L.482 4.542 1.517 3.46l11.488 10.988.002-.001ZM4 7.907l1 .957V9.5c0 .83.67 1.5 1.5 1.5h.733l1.585 1.516A3.225 3.225 0 0 1 6.5 13.5c-1.79 0-3.25-1.46-3.25-3.25 0-.78.29-1.49.75-2.05v-.293Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M5.236 4.941 8 7.585V5.75c0-.83-.67-1.5-1.5-1.5-.532 0-.998.275-1.264.691ZM8.956 8.5l3.515 3.362-.981-2.702a.992.992 0 0 0-.94-.66H8.956Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSaRn;
