import type { SVGProps } from "react";
const SvgCalendarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.75 7.5v1.765h12.5V7.5h1v1.765h4.5v17.501l-.501-.001-22.5-.045-.499-.001V9.265h4.5V7.5h1Zm12.5 2.765v1.734h1v-1.734h3.5v3.514H7.25v-3.514h3.5v1.734h1v-1.734h12.5Zm-17 15.456V14.78h21.5v10.984l-21.5-.043ZM19.5 17.75h7.47v-1H19.5v1Zm-3 3H9v-1h7.5v1Zm-7.5 3h7.5v-1H9v1Zm18-3h-7.5v-1H27v1Zm-7.5 3H27v-1h-7.5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCalendarMedium;
