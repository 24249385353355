import type { SVGProps } from "react";
const SvgEnvelopeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.5 6H4v12h16V6H4.5ZM5 7.71v8.583l4.286-4.286L5 7.71ZM5.707 17h12.585l-4.304-4.296-1.655 1.65-.354.353-.353-.354-1.634-1.638L5.707 17ZM19 16.294V7.705l-4.304 4.293L19 16.294ZM18.29 7H5.706l6.276 6.293L18.29 7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeSmall;
