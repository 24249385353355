import type { SVGProps } from "react";
const SvgCloudFogMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.18 14.984c.792.118 1.43.514 1.88 1.075.554.695.8 1.615.738 2.52a3.865 3.865 0 0 1-1.158 2.526c-.729.703-1.78 1.146-3.14 1.146H12c-1.375 0-2.417-.443-3.125-1.156-.701-.707-1.033-1.638-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.581 1.014-1.023 1.758-1.167.007-2.802 2.942-4.359 5.134-3.133 1.329-2.368 3.803-2.955 5.8-2.23 1.064.386 2 1.145 2.555 2.204.482.92.663 2.04.417 3.293Zm-3.313-4.557c-1.686-.612-3.8-.064-4.81 2.226l-.265.601-.509-.416c-1.627-1.334-4.282-.07-3.986 2.403l.066.56H10.8c-.616 0-1.116.303-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.887.493.496 1.265.86 2.415.86h10.5c1.132 0 1.925-.364 2.446-.866a2.866 2.866 0 0 0 .855-1.875c.047-.69-.145-1.353-.523-1.826-.367-.46-.93-.765-1.708-.734l-.698.028.199-.67c.379-1.274.233-2.339-.193-3.153a3.665 3.665 0 0 0-2.01-1.728ZM26.99 25.25H7.5v-1h19.49v1ZM9 28.25h16.49v-1H9v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudFogMedium;
