import type { SVGProps } from "react";
const SvgFreightWagonWeightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm5 4a4.992 4.992 0 0 1-2 4h7.866l.11.348 7 22 .208.652H5.816l.208-.652 7-22 .11-.348H21a5 5 0 1 1 8-4Zm-15.134 5h20.268l6.682 21H7.184l6.682-21ZM6.5 36H6v2H4v-1H3v3h1v-1h2v1.99h4.034A3.496 3.496 0 0 0 13.5 44a3.496 3.496 0 0 0 3.466-3.01h14.068A3.496 3.496 0 0 0 34.5 44a3.496 3.496 0 0 0 3.466-3.01H42V39h2v1h1v-3h-1v1h-2v-2H6.5Zm7 7a2.496 2.496 0 0 1-2.452-2.01h4.904A2.496 2.496 0 0 1 13.5 43Zm18.548-2.01A2.496 2.496 0 0 0 34.5 43a2.496 2.496 0 0 0 2.452-2.01h-4.904ZM7 39.99V37h34v2.99H7ZM22.583 9.41a2 2 0 1 1 2.829-2.828 2 2 0 0 1-2.829 2.828Zm-.707-3.535a3 3 0 1 0 4.243 4.242 3 3 0 0 0-4.243-4.242ZM23.41 18.15l-4.52 4.29 4.69 6.4h-1.79l-3.89-5.43-1.77 1.68v3.75h-1.41V18.15h1.41v5.3l5.43-5.3h1.85Zm9.87 5.07v-.02l.02.02h-.02Zm0 5.63v-5.63h-4.67v1.14h3.42c-.02 1.15-.34 2.03-.95 2.63-.63.61-1.43.92-2.4.92-1.26 0-2.23-.47-2.92-1.4-.57-.77-.86-1.75-.86-2.95 0-1.3.32-2.35.94-3.14.68-.87 1.63-1.3 2.84-1.3.83 0 1.53.21 2.1.64.56.42.9.99 1 1.69h1.39c-.09-.87-.44-1.62-1.06-2.25-.83-.86-1.98-1.29-3.43-1.29-1.57 0-2.81.52-3.74 1.55-.98 1.1-1.46 2.48-1.46 4.15 0 1.43.37 2.64 1.13 3.64.93 1.23 2.26 1.85 3.97 1.85 1.44 0 2.59-.53 3.44-1.59.04.13.09.31.15.53l.21.81h.9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonWeightLarge;
