import type { SVGProps } from "react";
const SvgCloudSnowflakeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.312 9.859c.413.098.763.31 1.034.603.39.421.598.982.641 1.545.086 1.114-.468 2.412-1.812 2.852l-.311-.95c.801-.263 1.186-1.05 1.126-1.825-.03-.381-.167-.715-.377-.942-.2-.216-.488-.359-.893-.343l-.698.028.2-.67c.24-.811.144-1.476-.118-1.977a2.277 2.277 0 0 0-1.25-1.073c-1.048-.38-2.362-.045-2.997 1.395l-.266.602-.508-.418c-.967-.792-2.566-.047-2.386 1.454l.067.56h-.563c-.59 0-1.079.533-1.159 1.337-.076.769.267 1.548 1.121 1.838l-.321.947c-1.397-.474-1.905-1.769-1.795-2.884.092-.928.65-1.917 1.639-2.174.093-1.85 2.007-2.893 3.522-2.174.945-1.539 2.624-1.917 3.987-1.423.745.27 1.404.803 1.795 1.55.318.608.448 1.336.322 2.142Zm-7.64 2.52 1.62 1.62H8.015v1h2.292l-1.62 1.622.707.707L11 15.72V18h1v-2.293l1.621 1.621.707-.707L12.708 15h2.306v-1h-2.293l1.622-1.622-.707-.707L12 13.306V11h-1v2.293L9.378 11.67l-.707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowflakeSmall;
