import type { SVGProps } from "react";
const SvgTrainSignalLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19 4h-.207l-.147.146-3.5 3.5-.146.147v25.414l.146.147 3.5 3.5.147.146H22v5.994h1V37h2v5.994h1V37h3.207l.147-.146 3.5-3.5.146-.147V7.793l-.146-.147-3.5-3.5L29.207 4H19Zm-3 4.207L19.207 5h9.586L32 8.207v24.586L28.793 36h-9.586L16 32.793V8.207ZM20 6h-.207l-.147.146-2.5 2.5-.146.147v23.414l.146.147 2.5 2.5.147.146h8.414l.147-.146 2.5-2.5.146-.147V8.793l-.146-.147-2.5-2.5L28.207 6H20Zm-2 3.207L20.207 7h7.586L30 9.207v22.586L27.793 34h-7.586L18 31.793V9.207ZM24 26a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-11a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-7a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm14 8h2v-1h-2v1Zm-23.646-6.354 2 2-.708.708-2-2 .708-.708Zm24 2.708 2-2-.708-.708-2 2 .708.708ZM11 19h2v1h-2v-1Zm1.646 3.646-2 2 .708.708 2-2-.708-.708Zm24 2.708-2-2 .708-.708 2 2-.708.708ZM10 19H9v1h1v-1Zm29 1h-1v-1h1v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainSignalLarge;
