import type { SVGProps } from "react";
const SvgArmchairLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M14.5 4A4.5 4.5 0 0 0 10 8.5v7.725a5.655 5.655 0 0 0-.937-.201h-.006A5.5 5.5 0 0 0 7 26.797V33.5a3.5 3.5 0 0 0 3.5 3.5h.5v4a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-4h17v4a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-4.036a3.5 3.5 0 0 0 3-3.464v-6.702a5.5 5.5 0 0 0-2.057-10.775h-.005a5.69 5.69 0 0 0-.938.2V8.5A4.5 4.5 0 0 0 33.5 4h-19ZM37 16.635V8.5A3.5 3.5 0 0 0 33.5 5h-19A3.5 3.5 0 0 0 11 8.5v8.139a5.649 5.649 0 0 1 3 5.079V26h20v-4.282h-.01A5.65 5.65 0 0 1 37 16.635Zm-2.51 5.075H35v5.79a3.5 3.5 0 0 1-3.5 3.5h-15a3.5 3.5 0 0 1-3.5-3.5v-5.79h.5l-.5-.008a4.65 4.65 0 0 0-4.06-4.686 4.5 4.5 0 0 0-1.347 8.893l.407.077V33.5a2.5 2.5 0 0 0 2.5 2.5h27a2.5 2.5 0 0 0 2.5-2.5v-7.514l.407-.077a4.5 4.5 0 0 0-1.347-8.892 4.65 4.65 0 0 0-4.07 4.685l-.5.008ZM37 37h-3v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-4Zm-22 0h-3v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-4Zm3-28v1h-1v1h1v1h1v-1h1v-1h-1V9h-1ZM8.5 20a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6 21.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm32 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM34 27H14v.5a2.5 2.5 0 0 0 2.5 2.5h15a2.5 2.5 0 0 0 2.5-2.5V27Zm-16-8v1h-1v1h1v1h1v-1h1v-1h-1v-1h-1ZM29 9v1h-1v1h1v1h1v-1h1v-1h-1V9h-1Zm0 10v1h-1v1h1v1h1v-1h1v-1h-1v-1h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArmchairLarge;
