import type { SVGProps } from "react";
const SvgEyeDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m15.442 17.15 3.204 3.204.708-.707-15-15-.708.707L6.46 8.168c-1.695 1.32-2.902 2.88-3.37 3.544l-.198.282.194.286c.392.58 1.486 1.994 3.03 3.268C7.657 16.817 9.707 18 12 18c1.242 0 2.401-.342 3.442-.85Zm-.752-.753-.851-.851A3.818 3.818 0 0 1 12 16a4 4 0 0 1-4-4c0-.684.149-1.288.451-1.841L7.173 8.88c-1.428 1.09-2.508 2.386-3.058 3.122a16.431 16.431 0 0 0 2.638 2.774C8.216 15.983 10.043 17 12 17c.94 0 1.842-.23 2.69-.603Zm-1.602-1.601L9.2 10.908c-.133.328-.2.685-.2 1.093A3 3 0 0 0 12 15c.396 0 .757-.07 1.088-.204Zm-2.066-6.663c.3-.076.627-.132.978-.132a4 4 0 0 1 4 4c0 .35-.058.678-.133.977l-.97-.243a3.02 3.02 0 0 0 .103-.734 3 3 0 0 0-3-3c-.243 0-.485.039-.735.102l-.243-.97ZM9.364 6.504c.822-.307 1.704-.503 2.636-.503 2.294 0 4.345 1.183 5.883 2.451 1.546 1.275 2.639 2.688 3.032 3.268l.193.286-.199.282a17.797 17.797 0 0 1-2.93 3.184l-.644-.765a16.97 16.97 0 0 0 2.55-2.71 16.431 16.431 0 0 0-2.638-2.773C15.784 8.018 13.957 7 12 7c-.79 0-1.553.166-2.285.44l-.351-.937Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEyeDisabledSmall;
