import type { SVGProps } from "react";
const SvgIc11Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-11-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M42.26 4.235V15.8h-2.302V8.23h-2.712V6.553a5.795 5.795 0 0 0 1.375-.174c.353-.1.684-.266.974-.49.425-.328.712-.879.859-1.654h1.805Zm7.687 0V15.8h-2.302V8.23h-2.712V6.553a5.795 5.795 0 0 0 1.376-.174c.353-.1.683-.266.973-.49.426-.328.713-.879.86-1.654h1.805ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIc11Negative;
