import type { SVGProps } from "react";
const SvgUnicornSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m22.854 3.807-1.858 1.93-3.593 3.73.05.1c.192.386.42.815.655 1.26l.001.002c.108.204.218.411.327.62.343.652.677 1.312.889 1.848.105.265.191.527.227.757.018.114.028.245.008.376a.736.736 0 0 1-.219.435c-.065.06-.126.12-.188.18l-.004.005c-.062.06-.126.122-.19.18a2.04 2.04 0 0 1-.44.326c-.173.089-.37.14-.59.124a1.053 1.053 0 0 1-.386.487.914.914 0 0 1-.762.123c-.253-.069-.47-.234-.65-.45a9.784 9.784 0 0 0-2.832-1.882l.4-.916a10.785 10.785 0 0 1 3.147 2.097l.023.023.02.026c.049.06.087.094.114.113l.001-.004.003-.007a.349.349 0 0 0 .004-.182l-.26-1.182 1.018.653c.13.083.2.1.228.103.016.002.03.002.065-.016a1.11 1.11 0 0 0 .22-.17c.051-.048.106-.1.17-.163l.006-.006.108-.105-.002-.013a2.816 2.816 0 0 0-.169-.544c-.19-.48-.5-1.097-.844-1.752l-.318-.603c-.24-.451-.478-.9-.675-1.297a11.641 11.641 0 0 1-.367-.8 2.52 2.52 0 0 1-.189-.658 1.522 1.522 0 0 0-.751-1.121l-.41-.235.211-.423.547-1.093c-.287.115-.527.29-.652.54l-.19.378-.403-.123a6.877 6.877 0 0 0-6.526 1.399l-.008.007c-1.443 1.206-2.36 2.986-2.938 4.93-.525 1.768-.757 3.635-.858 5.248 3.373.43 6.074.876 9.357 1.651-.7-1.075-1.247-2.002-1.637-3.034C11.228 15.31 11 13.783 11 11.5h1c0 2.217.223 3.61.68 4.826.465 1.23 1.179 2.305 2.237 3.897l.714 1.075-1.252-.313c-3.975-.994-6.955-1.49-10.94-1.989l-.46-.057.022-.463c.085-1.751.315-3.899.923-5.947.607-2.042 1.603-4.029 3.252-5.41a7.877 7.877 0 0 1 7.108-1.698c.362-.442.87-.664 1.267-.777a3.898 3.898 0 0 1 .922-.144h.026V5v-.5h.81l-.362.724-.81 1.618c.023.019.045.038.066.058l4.222-1.963 2.43-1.13Zm-4.438 3.166-1.42 1.474v-.01a2.518 2.518 0 0 0-.2-.71l1.62-.754ZM14 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUnicornSmall;
