import type { SVGProps } from "react";
const SvgSaZ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={15}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.221 1h9.582l-.48 2.206-8.422 9.588h6.361L10.802 15H1l.44-2.206 8.402-9.588H3.741L4.221 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaZ;
