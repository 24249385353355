import type { SVGProps } from "react";
const SvgSaSk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={23}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.538 9.555c0 1.146-.914 2.076-2.04 2.076-1.122 0-2.035-.93-2.035-2.076S10.376 7.48 11.5 7.48c1.125 0 2.039.929 2.039 2.075Zm-2.04 3.889a3.75 3.75 0 0 1-2.737-1.177 3.893 3.893 0 0 1-1.079-2.823c.053-2.027 1.718-3.72 3.707-3.777a3.754 3.754 0 0 1 2.774 1.101 3.888 3.888 0 0 1 1.157 2.787h1.527a5.453 5.453 0 0 0-1.618-3.903 5.275 5.275 0 0 0-3.88-1.538c-2.786.078-5.118 2.449-5.196 5.285-.03 1.1.258 2.156.821 3.061C4.18 11.081 3 8.657 2.66 7.803c.658-1.141 3.402-5.249 8.838-5.249 5.435 0 8.179 4.099 8.84 5.246-.492 1.2-2.727 5.644-8.84 5.644Zm10.364-6.052C21.733 7.133 18.645 1 11.498 1 4.354 1 1.267 7.133 1.139 7.392L1 7.676l.092.299C1.116 8.044 3.365 15 11.498 15c8.138 0 10.386-6.956 10.408-7.025L22 7.676l-.139-.284Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSk;
