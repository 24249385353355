import type { SVGProps } from "react";
const SvgChevronSmallUpCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 18c0-5.937 4.813-10.75 10.75-10.75 5.938 0 10.75 4.813 10.75 10.75 0 5.938-4.812 10.75-10.75 10.75-5.937 0-10.75-4.812-10.75-10.75ZM18 6.25C11.51 6.25 6.25 11.51 6.25 18S11.51 29.75 18 29.75 29.75 24.49 29.75 18 24.49 6.25 18 6.25Zm.35 7.985-.356-.36-.355.36-5.856 5.924.711.703 5.5-5.564 5.5 5.565.712-.703-5.856-5.925Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallUpCircleMedium;
