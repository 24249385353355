import type { SVGProps } from "react";
const SvgFaceKingLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.889 2.337a2 2 0 1 1 1.97 3.47l2.716 3.584 1.978-1.728a1.491 1.491 0 0 1-.524-.87 1.5 1.5 0 1 1 2.375.904l2.042 2.042 2.236-2.982a1.503 1.503 0 0 1-.568-1.831A1.5 1.5 0 1 1 37 6.914v11.072A17.04 17.04 0 0 1 24 46h-.002a17.04 17.04 0 0 1-13-28.014V6.914a1.5 1.5 0 1 1 1.319-.157l2.236 2.982 2.042-2.042a1.498 1.498 0 0 1 .07-2.444 1.5 1.5 0 0 1 1.775 2.415l1.985 1.767 2.718-3.627a2.002 2.002 0 0 1-.255-3.47ZM11.999 8v10h24V8l-2.1 2.8-.345.461-.408-.407-2.67-2.67-2.647 2.313-.404.352-.324-.427-3.099-4.091L20.9 10.47l-.326.435-.407-.361-2.647-2.357-2.666 2.667-.408.407-.346-.461L12 8Zm-.536 11A16.04 16.04 0 0 0 24 45a16.04 16.04 0 0 0 12.537-26H11.463ZM24 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM11.222 5.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83ZM17.5 6a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm0 18a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.278 1.084a.5.5 0 1 1 .555.83.5.5 0 0 1-.555-.83Zm-1.605 9.237a10.29 10.29 0 0 0 15.766 0l-.766-.642a9.29 9.29 0 0 1-14.234 0l-.766.642Zm14.05-10.068a1.5 1.5 0 1 1 1.666 2.495 1.5 1.5 0 0 1-1.666-2.495ZM30.5 25a.5.5 0 1 0 0 .998.5.5 0 0 0 0-.998Zm6-20a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-6.278 1.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceKingLarge;
