import type { SVGProps } from "react";
const SvgSunshineLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24 4v8h1V4h-1Zm-8.354 11.354-5-5 .708-.708 5 5-.708.708Zm.354 8.15C16 18.809 19.766 15 24.468 15 29.175 15 33 18.816 33 23.507A8.47 8.47 0 0 1 24.52 32C19.813 32 16 28.195 16 23.504ZM24.468 14C19.209 14 15 18.262 15 23.504 15 28.75 19.264 33 24.52 33c5.26 0 9.48-4.25 9.48-9.493C34 18.261 29.725 14 24.468 14ZM5 24h8v-1H5v1Zm27.646-9.354 5-5 .708.708-5 5-.708-.708ZM44 23h-8v1h8v-1ZM24 43v-8h1v8h-1Zm-12.65-5.643 5-4.893-.7-.715-5 4.894.7.714Zm22.004-5.71 5 5-.708.707-5-5 .708-.708Zm-3.352-7.688c-.217 2.672-2.366 4.822-5.043 5.043l.082.996c3.164-.26 5.7-2.798 5.957-5.957l-.996-.082Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunshineLarge;
