import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaPowerPlugMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 3.272a8.75 8.75 0 0 0-8.75 8.75v.02c.06 1.51.509 2.782 1.22 3.967l.006.01 7.126 11.247.422.666.422-.666 7.125-11.247.006-.01.006-.01c.694-1.217 1.154-2.513 1.167-3.973v-.004A8.75 8.75 0 0 0 18 3.272Zm-6.675 12.216c-.636-1.058-1.022-2.164-1.075-3.476a7.75 7.75 0 0 1 15.5.008c-.012 1.238-.398 2.363-1.03 3.473l-6.696 10.57-6.7-10.574Zm1.821 7.527c-2.791.316-5.209.9-6.944 1.666-.866.382-1.587.822-2.1 1.317-.513.496-.852 1.085-.852 1.752 0 .847.544 1.567 1.315 2.144.781.584 1.88 1.09 3.195 1.507 2.636.836 6.252 1.349 10.24 1.349 3.988 0 7.604-.513 10.24-1.349 1.314-.417 2.413-.923 3.194-1.508.771-.576 1.315-1.296 1.315-2.143 0-.662-.334-1.247-.84-1.74-.506-.493-1.218-.93-2.073-1.311-1.712-.764-4.098-1.347-6.856-1.668l-.115.993c2.699.314 4.977.88 6.564 1.588.795.354 1.391.733 1.782 1.114.39.379.539.723.539 1.024 0 .385-.25.845-.915 1.343-.656.49-1.638.955-2.898 1.355-2.514.797-6.023 1.302-9.938 1.302-3.914 0-7.423-.505-9.937-1.302-1.26-.4-2.241-.864-2.898-1.355-.665-.498-.914-.958-.914-1.343 0-.304.151-.65.547-1.033.397-.383 1.003-.765 1.81-1.121 1.61-.712 3.919-1.277 6.652-1.587l-.113-.994ZM20.75 9.25v-1.5h-.5l-1.503.002v.5-.5m2.003 2.498h2.5v-1h-2.5m0 1v2h2.5v1h-2.5v1.5h-2a3.502 3.502 0 0 1-3.465-3H12v-1h3.285a3.498 3.498 0 0 1 3.461-2.998m.001 1 1.003-.002v5h-1a2.5 2.5 0 0 1-2.5-2.5 2.497 2.497 0 0 1 2.497-2.498Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaPowerPlugMedium;
