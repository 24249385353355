import type { SVGProps } from "react";
const SvgCloudSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.967 9.839a4.599 4.599 0 0 1 1.796 1.852c.482.92.663 2.04.417 3.293.79.119 1.42.516 1.857 1.082.096.123.182.254.258.39 1.457-.585 2.455-1.993 2.455-3.706 0-2.26-1.74-4-4-4-1.101 0-2.074.411-2.783 1.089Zm4.68 7.553c.087.388.113.793.08 1.194a3.98 3.98 0 0 1-1.165 2.517c-.717.7-1.745 1.147-3.062 1.147H12c-1.375 0-2.417-.442-3.125-1.155-.701-.707-1.033-1.638-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.581 1.014-1.024 1.758-1.167.007-2.802 2.942-4.36 5.134-3.134 1.28-2.282 3.626-2.91 5.582-2.303.91-1.031 2.25-1.663 3.76-1.663 2.813 0 5 2.187 5 5 0 2.132-1.259 3.91-3.103 4.642Zm-10.59-4.74c1.01-2.29 3.125-2.837 4.81-2.225.849.308 1.58.908 2.01 1.728.427.814.573 1.879.194 3.153l-.2.67.7-.028c.776-.031 1.324.273 1.675.728.364.469.541 1.131.484 1.825a2.98 2.98 0 0 1-.867 1.884c-.515.504-1.285.863-2.363.863H12c-1.15 0-1.922-.363-2.415-.86-.498-.502-.753-1.183-.784-1.886-.03-.707.167-1.4.528-1.905.355-.496.855-.799 1.471-.799h.563l-.066-.559c-.296-2.473 2.358-3.737 3.986-2.404l.51.417.265-.602Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSunMedium;
