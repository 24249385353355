import type { SVGProps } from "react";
const SvgTicketJourneyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.989 4.76a7.25 7.25 0 0 0-7.25 7.25v.02a6.55 6.55 0 0 0 .252 1.58l.96-.277a5.547 5.547 0 0 1-.212-1.332 6.25 6.25 0 0 1 12.5.007c-.01.993-.32 1.897-.83 2.796l-5.402 8.526-1.115-1.76-.845.535 1.537 2.427.423.666.422-.666 5.83-9.203.007-.01.006-.01c.572-1.005.955-2.08.967-3.293v-.005a7.25 7.25 0 0 0-7.25-7.25Zm.01 5.491a1.749 1.749 0 1 0 0 3.5 1.75 1.75 0 1 0 0-3.5Zm-2.75 1.75a2.749 2.749 0 1 1 5.5 0 2.75 2.75 0 1 1-5.5 0ZM7.498 16.5H6v1.49H4.489v-3h1.5v-.002H7.5V16.5ZM5.989 31.491h-1.5v-3H6v1.498h1.5v1.512H5.988v-.01Zm4.5-3.002v-1.5H7.5v-1.5h-.012v-1.488h1.5v2.987h7.5v-2.986h1.5v1.488h-1.499v1.5h-3v1.5h2.999v1.512h1.5v1.5H19.5v-.011H21v-3h-1.51v1.498H18v-1.5h-1.51v-1.5h3.002v-1.5h1.498v-1.5H18v-1.498h2.99v-1.5H19.5v-1.502h-1.51V18H18v-1.5h1.49v1.49h1.512v-3H19.5v-.002h-1.512v1.5h-1.5v1.502h-1.499v1.498H13.5v-1.5h-1.511V16.49h-3v1.498h-1.5v1.502h-1.5v1.5h-1.5v1.512H6V20.99h2.988v1.5h-1.5v1.5h-3v1.5h1.5v1.512h1.5v1.488h3Zm0 0H12v1.5h2.99v1.5h-3v-1.488H10.5v1.5H8.988V29.99h1.5v-1.5Zm-1.489-9h2.988V17.99H9v1.498Zm7.488 3.002h1.5V19.49h-1.5v3Zm-4.499-6h3v-1.5h-3v1.5Zm-.001 7.512H13.5V22.49h-1.512v1.512Zm3 1.486h-4.5v-4.5h4.5v4.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketJourneyMedium;
