import type { SVGProps } from "react";
const SvgBatteryLevelEmptyLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m21.995 7.528.002-.002a.008.008 0 0 1-.002.002Zm.045-.053a.27.27 0 0 0-.04.045v1.96a.27.27 0 0 0 .04.045c.065.062.187.14.378.217.38.152.94.258 1.582.258.642 0 1.201-.106 1.582-.258.191-.077.313-.155.378-.217A.27.27 0 0 0 26 9.48V7.52a.27.27 0 0 0-.04-.045 1.232 1.232 0 0 0-.378-.217C25.202 7.106 24.642 7 24 7c-.642 0-1.201.106-1.582.258a1.232 1.232 0 0 0-.378.217Zm-.045 1.997.002.002-.002-.002Zm4.01 0-.002.002.002-.002Zm0-1.944-.002-.002.002.002ZM24 6c-.738 0-1.429.12-1.953.33a2.201 2.201 0 0 0-.694.418 1.132 1.132 0 0 0-.312.48c-1.282.191-2.435.497-3.304.889-.478.215-.895.468-1.2.763-.305.295-.537.672-.537 1.12v30c0 .564.344 1.022.78 1.366.443.348 1.051.64 1.76.876 1.42.474 3.351.758 5.46.758s4.04-.285 5.46-.758c.709-.236 1.317-.528 1.76-.876.436-.344.78-.802.78-1.366V10c0-.445-.226-.83-.526-1.14-.301-.31-.715-.586-1.194-.824-.89-.442-2.076-.792-3.398-.988a1.224 1.224 0 0 0-.235-.3 2.201 2.201 0 0 0-.694-.418C25.43 6.12 24.738 6 24 6Zm3 2.079c1.133.19 2.117.495 2.835.852.419.209.726.424.921.626.198.203.244.353.244.443 0 .126-.076.326-.4.58-.316.25-.806.497-1.456.713C27.85 11.725 26.031 12 24 12s-3.85-.275-5.144-.707c-.65-.216-1.14-.463-1.457-.713-.323-.254-.399-.454-.399-.58 0-.082.04-.216.232-.401.192-.185.496-.381.916-.57.721-.326 1.71-.6 2.852-.782V9.5c0 .32.165.574.353.752.188.177.433.314.694.418.524.21 1.215.33 1.953.33s1.429-.12 1.953-.33c.261-.104.506-.241.694-.418.188-.178.353-.432.353-.752V8.079Zm2.46 4.163c.598-.2 1.124-.438 1.54-.717V40c0 .126-.076.326-.4.58-.316.25-.806.497-1.456.713C27.85 41.724 26.031 42 24 42s-3.85-.276-5.144-.707c-.65-.216-1.14-.463-1.457-.713-.323-.254-.399-.454-.399-.58V11.525c.416.279.942.518 1.54.717 1.42.473 3.351.758 5.46.758s4.04-.284 5.46-.758Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBatteryLevelEmptyLarge;
