import type { SVGProps } from "react";
const SvgIr23Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-23-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-23-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.578 1.376v2.056h-8.33c0-.627.1-1.226.3-1.795.206-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.941-.744 1.163-1.076.22-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.686-.474-1.17-.474-1.193 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.314 1.825-.941 2.531a4.65 4.65 0 0 1-.578.562c-.179.137-.65.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.348 6.348 0 0 0-.87.926h5.426Zm.933-5.846c.026-.949.261-1.745.704-2.389.717-1.033 1.796-1.55 3.235-1.55 1.108 0 2.002.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.163.847c.623.142 1.113.459 1.472.949.364.49.545 1.084.545 1.78 0 1.144-.421 2.08-1.265 2.808-.78.675-1.767 1.012-2.958 1.012-1.292 0-2.318-.371-3.078-1.115-.754-.744-1.13-1.748-1.13-3.014v-.15h2.222c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.196-1.02-.586-1.353-.321-.274-.817-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.138.01.27.016.396.016 1.329 0 1.993-.493 1.993-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.672-.403-1.147-.403-.543 0-.962.169-1.257.506-.296.338-.454.83-.475 1.48h-2.191Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-23-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr23Negative;
