import type { SVGProps } from "react";
const SvgEv85 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-85_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm1.962 5.371c-.54-.185-.954-.489-1.245-.912-.286-.423-.429-.936-.429-1.54 0-1.004.413-1.806 1.238-2.403.73-.53 1.597-.794 2.602-.794 1.143 0 2.077.315 2.801.944.688.593 1.032 1.325 1.032 2.198 0 .609-.146 1.13-.437 1.563-.29.434-.703.749-1.237.944.418.122.759.278 1.023.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.452 2.119-1.357 2.817-.761.587-1.74.88-2.935.88-1.296 0-2.328-.322-3.095-.967-.799-.672-1.198-1.553-1.198-2.642 0-1.021.354-1.812 1.063-2.373.323-.248.68-.423 1.071-.524Zm2.135-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.317.286.74.428 1.27.428.528 0 .946-.142 1.253-.428.307-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.735-.405-1.285-.405Zm-.016 4.721c-.572 0-1.04.178-1.405.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.85.532 1.436.532.667 0 1.167-.196 1.5-.587.323-.376.484-.81.484-1.301 0-.561-.185-1.021-.555-1.381-.355-.35-.844-.524-1.468-.524ZM41.836 4.17v2.063h-4.674l-.5 2.61c.56-.592 1.278-.888 2.15-.888.857 0 1.603.23 2.238.69.942.693 1.412 1.745 1.412 3.158 0 1.386-.502 2.483-1.507 3.293-.783.634-1.72.952-2.81.952-1.226 0-2.213-.328-2.959-.984-.746-.661-1.14-1.553-1.182-2.674h2.277c.037.407.17.754.397 1.04.354.45.846.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.299.328-1.706.984l-2.07-.031 1.102-6.467h6.372Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv85;
