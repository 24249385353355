import type { SVGProps } from "react";
const SvgSaXt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.442 5.814 7.421 1H4.4l3.061 6.398L1 15h3.481l4.361-5.617h.04L11.022 15h3.06l-3.24-7.322L16.623 1h-3.301l-3.84 4.814h-.04ZM16.9 3.206 17.38 1h10.782l-.46 2.206h-3.94L21.221 15h-2.92L20.84 3.206H16.9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaXt;
