import type { SVGProps } from "react";
const SvgSaHk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={31}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.722 1H3.921L1 15h2.801l1.28-6.137h5.641L9.442 15h2.8l2.921-14h-2.801l-1.179 5.656H5.541L6.722 1Zm13.379 5.496L25.642 1h3.9l-7.061 6.378 4.5 7.622h-3.34L19.76 7.88 18.24 15h-2.8l3-14h2.8l-1.179 5.496h.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaHk;
