import type { SVGProps } from "react";
const SvgCircleInformationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.25 18c0-7.682 6.07-13.75 13.75-13.75S31.75 10.318 31.75 18c0 7.68-6.07 13.75-13.75 13.75S4.25 25.68 4.25 18ZM18 3.25C9.767 3.25 3.25 9.766 3.25 18c0 8.233 6.517 14.75 14.75 14.75S32.75 26.233 32.75 18c0-8.234-6.517-14.75-14.75-14.75ZM19.22 12V9h-1v3h1Zm0 12.235h3.25v1h-7.5v-1h3.25v-8H15v-1h4.22v9Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleInformationMedium;
