import type { SVGProps } from "react";
const SvgSaHi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={23}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.722 1H3.921L1 15h2.801l1.28-6.137h5.641L9.442 15h2.8l2.921-14h-2.801l-1.179 5.656H5.541zm14.622 0h-2.92l-2.94 14h2.92z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaHi;
