import type { SVGProps } from "react";
const SvgIr58 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-58_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ir-58_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18-8.132V6.29h-4.659l-.498 2.602c.559-.59 1.273-.886 2.143-.886.855 0 1.598.23 2.231.688.939.691 1.408 1.74 1.408 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.18-2.665h2.271c.037.406.169.751.395 1.036.354.448.844.672 1.472.672.606 0 1.086-.218 1.44-.656.342-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.359-.401-.836-.602-1.432-.602-.728 0-1.295.327-1.7.981l-2.065-.031 1.1-6.447h6.351Zm3.275 5.356c-.538-.186-.952-.489-1.242-.91-.285-.422-.427-.934-.427-1.535 0-1.002.411-1.801 1.234-2.397.728-.527 1.592-.791 2.594-.791 1.14 0 2.07.314 2.793.941.685.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.733.532 1.1 1.31 1.1 2.333 0 1.176-.451 2.112-1.353 2.808-.76.586-1.735.878-2.926.878-1.292 0-2.32-.321-3.085-.965-.797-.67-1.195-1.547-1.195-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.068-.522Zm2.128-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.265.427.528 0 .944-.142 1.25-.427.306-.285.459-.672.459-1.163 0-.48-.156-.854-.467-1.123-.306-.269-.733-.403-1.281-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.359.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.369.354.846.53 1.431.53.665 0 1.163-.195 1.495-.585a1.93 1.93 0 0 0 .483-1.297c0-.56-.184-1.018-.554-1.377-.353-.348-.84-.522-1.463-.522Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ir-58_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr58;
