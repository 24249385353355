import type { SVGProps } from "react";
const SvgTreeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 2.008a5.5 5.5 0 0 0-5.464 6.116C3.74 8.573 2 10.575 2 13.008a5 5 0 0 0 5 5h4V21H2v1h20v-1H12v-2.992h6a4 4 0 0 0 4-4 3.981 3.981 0 0 0-1.347-2.986A3.5 3.5 0 0 0 17.5 6.008c-.243 0-.479.027-.704.075-.627-2.343-2.75-4.075-5.296-4.075Zm.5 15h6a3 3 0 0 0 3-3 2.991 2.991 0 0 0-1.28-2.451l-.396-.279.266-.405c.259-.394.41-.861.41-1.365a2.5 2.5 0 0 0-2.5-2.5c-.313 0-.613.064-.898.175l-.578.225-.098-.612c-.343-2.147-2.188-3.788-4.426-3.788a4.5 4.5 0 0 0-4.5 4.5c0 .32.036.63.1.934l.12.554-.565.049C4.606 9.223 3 10.922 3 13.008a4 4 0 0 0 4 4h4V13.24l-2.312-1.85.625-.78L11 11.96V9h1v5.67l2.624-3 .752.66L12 16.187v.82Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTreeSmall;
