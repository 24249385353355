import type { SVGProps } from "react";
const SvgLockOpenLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.852 3.438A6.5 6.5 0 0 0 11.305 3h-.013A6.67 6.67 0 0 0 5 9.755V11.5a1.5 1.5 0 0 0 3 0V9.663a3.62 3.62 0 0 1 3.165-3.646A3.5 3.5 0 0 1 15 9.517V18H8v27h31V18H18V9.5a6.5 6.5 0 0 0-4.148-6.062ZM17 18V9.5A5.5 5.5 0 0 0 11.342 4 5.67 5.67 0 0 0 6 9.743V11.5a.5.5 0 0 0 1 0V9.674a4.62 4.62 0 0 1 4.048-4.65l.013-.002a4.5 4.5 0 0 1 4.94 4.5V18h1Zm-8 1v25h29V19H9Zm14.265 5A3.5 3.5 0 0 1 25 30.654V37.5a1.5 1.5 0 1 1-3 0v-6.846A3.5 3.5 0 0 1 23.265 24Zm1.389 1.274a2.5 2.5 0 1 0-1.987 4.575l.333.117V37.5a.5.5 0 0 0 1 0v-7.534l.333-.117a2.5 2.5 0 0 0 .32-4.575Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLockOpenLarge;
