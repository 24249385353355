import type { SVGProps } from "react";
const SvgHandGraduationCapLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m24 6.45.207.094 16.5 7.5.293.134v7.371a2.502 2.502 0 0 1 .591 4.7l.89 3.118-.961.274-.898-3.144a2.598 2.598 0 0 1-.243 0l-.899 3.144-.961-.274.89-3.117a2.501 2.501 0 0 1 .591-4.7v-6.316l-5 1.97v5.956l-.094.131L34.5 23l.406.292-.002.002-.003.005-.01.012-.03.039a5.605 5.605 0 0 1-.542.566 9.175 9.175 0 0 1-1.836 1.274C30.778 26.107 28.07 27 24 27c-4.047 0-6.993-.893-8.939-1.797a13.516 13.516 0 0 1-2.172-1.25 8.495 8.495 0 0 1-.685-.545 2.919 2.919 0 0 1-.038-.036l-.01-.01-.004-.004h-.002v-.001L12.5 23l-.35.356-.15-.147v-6.4l-4.683-1.844-1.098-.433 1.074-.488 16.5-7.5L24 6.45ZM13 17.204v5.572c.103.087.26.212.472.364.434.312 1.1.733 2.01 1.156C17.303 25.142 20.107 26 24 26c3.919 0 6.461-.858 8.009-1.69a8.183 8.183 0 0 0 1.637-1.133c.165-.15.281-.273.354-.356v-5.224l-9.817 3.868-.183.072-.183-.072L13 17.204Zm26.22-2.737L24 20.462 8.78 14.467 24 7.549l15.22 6.918ZM40.5 22.5a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0-.001-3.002ZM8.548 27.537l8.657 5.847a2.416 2.416 0 0 1 .722-1.617c.488-.478 1.194-.768 2.073-.768h4.227c3.686.062 6.12 2.074 8.11 3.72a119.44 119.44 0 0 0 .324.268l10.053 8.123 1.1.89h-16.52l-.146-.146L25.278 42H17.41l-.142-.125L5.426 31.443a2.501 2.501 0 0 1 3.122-3.906Zm9.08 7.34-9.656-6.522-.017-.012-.016-.013a1.5 1.5 0 1 0-1.878 2.34l.009.007.008.008 11.71 10.314h7.902l.146.145L27.706 43h13.28l-8.956-7.237-.004-.003-.287-.237c-2.017-1.664-4.2-3.465-7.524-3.523H20c-.652 0-1.096.21-1.373.482a1.418 1.418 0 0 0-.424 1.018c0 .373.142.742.424 1.018.277.272.72.482 1.373.482h4v1h-4c-.88 0-1.585-.29-2.073-.768a2.404 2.404 0 0 1-.299-.355Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandGraduationCapLarge;
