import type { SVGProps } from "react";
const SvgMountainMinusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.248 6.477.438.778 11.852 21.069 1.269 2.174.439.752H3.255l.438-.752 3.805-6.52 9.315-16.721.435-.78ZM9.293 22.809l8.468-5.168.327-.199.278.263 8.678 8.224-9.792-17.405-7.959 14.285Zm19.413 6.073-10.75-10.188L8.3 24.587 4.996 30.25h24.508l-.798-1.368Zm2.8-18.629H23.91v-1h7.598v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainMinusMedium;
