import type { SVGProps } from "react";
const SvgOnboardingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 3.248h-.5V32.75h19V3.25h-.5l-18-.002Zm.5 22.502V4.248l17 .001v21.5h-17Zm0 1v5h17v-5h-17Zm10.75 3h-4.5v-1H21v1Zm-8.604-14.353 3-3 .708.707-2.647 2.646 2.647 2.647-.708.707-3-3-.353-.354.353-.353Zm12.708 0-3-3-.708.706 2.647 2.647-2.647 2.646.708.707 3-3 .353-.353-.353-.354Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgOnboardingMedium;
