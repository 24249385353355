import type { SVGProps } from "react";
const SvgCloudSnowflakeSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.968 9.839a4.6 4.6 0 0 1 1.795 1.851c.482.92.663 2.041.417 3.296a2.82 2.82 0 0 1 1.655.877c.17.183.316.386.437.602 1.47-.58 2.478-1.993 2.478-3.715 0-2.26-1.74-4-4-4-1.1 0-2.073.411-2.782 1.089Zm4.665 7.56c.05.206.082.418.099.63.122 1.587-.667 3.407-2.547 4.022l-.311-.95c1.337-.438 1.957-1.748 1.86-2.994-.047-.617-.271-1.175-.632-1.564-.35-.378-.852-.62-1.531-.594l-.699.028.2-.67c.378-1.274.232-2.338-.195-3.153a3.666 3.666 0 0 0-2.01-1.728c-1.686-.611-3.8-.064-4.81 2.226l-.266.602-.509-.418c-1.626-1.333-4.281-.068-3.985 2.404l.067.56h-.563c-1.065 0-1.86.956-1.986 2.231-.123 1.24.433 2.538 1.85 3.019l-.322.947c-1.96-.665-2.68-2.48-2.523-4.065.138-1.39.99-2.807 2.454-3.084.007-2.801 2.943-4.358 5.133-3.133 1.281-2.282 3.627-2.91 5.584-2.302.91-1.031 2.248-1.663 3.759-1.663 2.813 0 5 2.187 5 5 0 2.138-1.265 3.92-3.117 4.648Zm-13.45.992 2.86 2.859H12.02v1h4.042l-2.858 2.858.707.707 2.838-2.838V27h1v-4.043l2.859 2.859.707-.707-2.859-2.859h4.064v-1h-4.044l2.86-2.86-.707-.707-2.88 2.88V16.5h-1v4.043l-2.86-2.86-.706.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowflakeSunMedium;
