import type { SVGProps } from "react";
const SvgSaBr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.522 6.656H5.421l.74-3.45h.981c1.06 0 2.341.06 2.341 1.404 0 1.646-1.541 2.046-2.961 2.046Zm-1.421 6.138h-.96l.841-4.052h.899c1.501 0 2.861.22 2.861 1.686 0 2.165-1.921 2.366-3.641 2.366ZM8.062 1H3.921L1 15h3.982c2.08 0 6.56-.321 6.56-4.333 0-1.504-.68-2.707-2.24-2.947v-.04c1.62-.261 2.98-1.626 2.98-3.33 0-2.548-2.04-3.35-4.22-3.35Zm10.7 5.977h-1.02l.78-3.77h1.16c1.14 0 2.4 0 2.4 1.464 0 1.845-1.82 2.306-3.32 2.306ZM19.441 1H16.14l-2.92 14h2.8l1.221-5.816h1.06c1.29 0 1.428.751 1.636 1.886.037.202.076.417.124.641l.68 3.289h2.94l-1.339-5.636c-.18-.763-.66-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C25.002 1 22.09 1 19.532 1h-.09Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaBr;
