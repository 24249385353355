import type { SVGProps } from "react";
const SvgDownloadMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.75 21.721 5.002-5.041.71.704-5.857 5.903-.354.357-.355-.357-5.862-5.903.71-.704 5.006 5.041V10.498h1v11.223Zm-11.5 4.544V22.5h1v3.265h20v-3.25h1v4.25h-22v-.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDownloadMedium;
