import type { SVGProps } from "react";
const SvgIc17 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-17_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-17_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm26.341.034v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.98-.49.428-.328.716-.879.863-1.654h1.812Zm11.113 0v1.962c-.063.068-.25.282-.561.64-.433.496-.863 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-17_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc17;
