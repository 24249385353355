import type { SVGProps } from "react";
const SvgCustomerAssistanceSbbSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.501 3c-.73 0-1.5.785-1.5 2 0 1.215.77 2 1.5 2s1.5-.786 1.5-2c0-1.215-.77-2-1.5-2Zm-2.5 2c0-1.547 1.02-3 2.5-3 1.478 0 2.5 1.452 2.5 3 0 .614-.16 1.213-.447 1.712C20.052 7.81 21 10.038 21 12.499v.5h-9v-.5c0-2.461.95-4.689 2.448-5.787A3.469 3.469 0 0 1 14.001 5Zm2.5 3c.54 0 1.02-.195 1.411-.517 1.095.774 1.954 2.442 2.075 4.516h-6.973c.12-2.074.98-3.742 2.075-4.516.391.322.87.517 1.412.517ZM3.5 2H3v7h9V6.08l.823-.699.414-.35-.384-.384L12 3.793V2H3.5ZM4 8V3h7v1.207l.146.147.616.616-.586.498-.177.15V8H4Zm6-4.02H4.979v1h5.02v-1Zm-5.021 2h3.02v1H4.98v-1ZM12 20.5c0-2.462-.95-4.69-2.447-5.788.286-.5.447-1.098.447-1.712 0-1.547-1.022-3-2.5-3S5 11.453 5 13c0 .614.16 1.213.447 1.712C3.95 15.811 3 18.037 3 20.5v.5h9v-.5Zm-1.014-.5c-.12-2.075-.98-3.743-2.074-4.517A2.194 2.194 0 0 1 7.5 16a2.19 2.19 0 0 1-1.411-.517c-1.096.775-1.954 2.442-2.075 4.516h6.972ZM7.5 11c-.73 0-1.5.786-1.5 2s.77 2 1.5 2S9 14.214 9 13s-.77-2-1.5-2ZM18 9.687l-1-.937h.75L19 10l-1.25 1.25H17l1-.938h-1.187v.938h-.625v-.938H15l1 .938h-.75L14 10l1.25-1.25H16l-1 .937h1.188V8.75h.625v.937H18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCustomerAssistanceSbbSmall;
