import type { SVGProps } from "react";
const SvgMountainRockTransmitterSignalMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#mountain-rock-transmitter-signal-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="m3.25 4.183.82.683 4.5 3.75.136.113.034.173 1.45 7.246 6.513 2.895.214.095.064.225 1.312 4.591 8.43-5.62.554.832-8.519 5.68 6.364 7.07.75.834H3.25V4.183Zm1 2.135V31.75h19.377l-5.999-6.665-.077-.086-.032-.111-1.436-5.026-6.536-2.905-.237-.105-.05-.254-1.466-7.327L4.25 6.318Zm17.862 9.027a6.752 6.752 0 0 1 9.777 0l.722-.69a7.752 7.752 0 0 0-11.223 0l.724.69Zm1.502 1.588c.835-1.017 2.03-1.683 3.386-1.683 1.356 0 2.551.666 3.386 1.683l-.772.634c-.676-.822-1.603-1.317-2.614-1.317s-1.938.495-2.614 1.317l-.772-.634Zm-7.435-8.94-2.25-3.75-.858.514 2.25 3.75.858-.514Zm-6-3.75 2.7 4.5-.858.514-2.7-4.5.858-.514Zm4.37 10.074c-.56-.159-.862-.435-1.01-.709a1.19 1.19 0 0 1-.08-.918c.188-.623.793-1.07 1.569-.822 1.352.515 1.657 1.403 1.538 1.902-.06.254-.234.476-.538.602-.31.128-.794.166-1.46-.05l-.01-.003-.009-.002Zm.8-3.395c-1.405-.462-2.525.413-2.847 1.478a2.19 2.19 0 0 0 .158 1.686c.296.545.837.97 1.609 1.19.823.265 1.557.26 2.14.02.594-.244.993-.72 1.13-1.294.276-1.16-.52-2.451-2.169-3.073l-.01-.004-.01-.003Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="mountain-rock-transmitter-signal-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMountainRockTransmitterSignalMedium;
