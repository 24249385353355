import type { SVGProps } from "react";
const SvgSaTx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.262 1H1.48L1 3.206h3.94L2.4 15h2.921l2.54-11.794h3.941L12.262 1Zm5.74 4.814L15.983 1H12.96l3.06 6.398L9.56 15h3.48l4.362-5.615h.04L19.582 15h3.06l-3.24-7.32L25.182 1h-3.3l-3.84 4.814h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaTx;
