import type { SVGProps } from "react";
const SvgFilterMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.663 7.75h24.674l-.707.825-8.88 10.36V29.75h-5.5V18.934L6.37 8.575l-.707-.824Zm2.174 1 8.293 9.674.12.14V28.75h3.5V18.565l.12-.14 8.293-9.675H7.837Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFilterMedium;
