import type { SVGProps } from "react";
const SvgTicketIpvSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.5 2H5v20h15V2H5.5ZM6 21V3h13v18H6Zm2-5h9v-1H8v1Zm9 2H8v-1h9v1Zm-8.88-5.988V7.015H7v4.997h1.12Zm.985-4.997h2.07c.349 0 .623.02.823.063.2.042.38.117.542.224.446.291.67.731.67 1.32 0 .521-.185.931-.554 1.23a1.473 1.473 0 0 1-.537.275c-.2.056-.449.083-.744.083h-1.15v1.802h-1.12V7.015Zm1.12.923v1.415h1c.297 0 .51-.052.642-.154.161-.128.242-.316.242-.564 0-.25-.094-.438-.281-.56-.136-.092-.357-.137-.663-.137h-.94Zm6.07 4.074L18 7.015h-1.162l-1.172 3.742-1.154-3.742H13.34l1.68 4.997h1.275Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketIpvSmall;
