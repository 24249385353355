import type { SVGProps } from "react";
const SvgLotusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.342 2.635 12 2.315l-.342.32C9.603 4.562 8.54 6.426 8.138 8.161c-.403 1.736-.132 3.295.4 4.591.529 1.291 1.319 2.325 1.969 3.032a11.16 11.16 0 0 0 1.09 1.036l.073.057.02.016.007.005.002.001.3-.399-.3.4.3.225.3-.225-.3-.4.301.4h.001l.002-.002.006-.005.02-.016.073-.057a11.158 11.158 0 0 0 1.09-1.036c.651-.707 1.441-1.741 1.97-3.032.532-1.296.803-2.855.4-4.591-.402-1.735-1.465-3.6-3.52-5.526Zm.415 12.472c-.298.324-.565.574-.757.743a10.15 10.15 0 0 1-.757-.743c-.6-.652-1.31-1.587-1.78-2.734-.469-1.142-.698-2.488-.351-3.987.327-1.409 1.174-2.993 2.888-4.692 1.714 1.699 2.561 3.283 2.888 4.692.347 1.499.118 2.845-.35 3.987-.471 1.147-1.181 2.082-1.781 2.734ZM7.177 6.74a8.948 8.948 0 0 0-3.507-.603l-.391.012-.082.383a8.96 8.96 0 0 0 6.805 10.603l.219-.976a7.96 7.96 0 0 1-6.119-9.025c.93.018 1.847.204 2.715.539l.36-.933Zm14.167 5.797c.6.33 1.158.726 1.668 1.181l.293.262-.185.346a8.973 8.973 0 0 1-9.19 4.662l.141-.99a7.973 7.973 0 0 0 7.97-3.793 8.11 8.11 0 0 0-1.18-.792l.483-.876ZM.988 13.718c.51-.455 1.07-.85 1.667-1.18l.484.875a8.3 8.3 0 0 0-1.18.792A7.975 7.975 0 0 0 9.93 18l.142.99A8.975 8.975 0 0 1 .88 14.327l-.185-.347.293-.261Zm18.91-6.584a7.983 7.983 0 0 0-2.715.539l-.36-.933a8.948 8.948 0 0 1 3.507-.603l.392.012.081.383a8.96 8.96 0 0 1-6.805 10.603l-.218-.976a7.96 7.96 0 0 0 6.118-9.025Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLotusSmall;
