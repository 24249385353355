import type { SVGProps } from "react";
const SvgLaptopLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.44 12a1.5 1.5 0 0 0-1.5 1.5L7.999 34H9V13h30v21h1V13.5a1.5 1.5 0 0 0-1.5-1.5H9.44ZM38 34V14H10v20h28ZM6.94 13.501 6.999 34H4v1.5A2.5 2.5 0 0 0 6.5 38h35.01a2.5 2.5 0 0 0 2.5-2.5V34H41V13.5a2.5 2.5 0 0 0-2.5-2.5H9.44a2.5 2.5 0 0 0-2.5 2.5v.001ZM5 35v.5A1.5 1.5 0 0 0 6.5 37h35.01a1.5 1.5 0 0 0 1.5-1.5V35H5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLaptopLarge;
