import type { SVGProps } from "react";
const SvgTrainProfileSignalLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20 4.899.277.185 7.5 5 .624.416-.624.416L21.651 15h7.565c4.844 0 8.925 3.028 10.43 7.23l.001.004 4.936 13.153c.256.509.417 1.083.417 1.704C45 39.266 43.18 41 40.973 41h-4.995a5.496 5.496 0 0 1-2.315 4H46v1H2v-1h5.337a5.496 5.496 0 0 1-2.315-4H2v-1h38.973C42.661 40 44 38.68 44 37.09c0-.451-.119-.88-.32-1.274l-.014-.025-.01-.027-1.415-3.771H28.007v-9.986h10.477C36.97 18.492 33.414 16 29.216 16h-9.065l-.151.1-.151-.1H2v-1h16.349l-6.126-4.084-.624-.416.624-.416 7.5-5L20 4.899Zm18.864 18.094h-9.871v8.014H41.87l-3.007-8.014ZM13.401 10.5 20 14.899l6.599-4.4L20 6.102 13.401 10.5ZM30.5 45a4.5 4.5 0 0 0 4.472-4h-8.945a4.5 4.5 0 0 0 4.473 4Zm-3.163 0a5.496 5.496 0 0 1-2.315-4h-9.044a5.496 5.496 0 0 1-2.315 4h13.674ZM10.5 45a4.5 4.5 0 0 0 4.473-4H6.027a4.5 4.5 0 0 0 4.473 4ZM8.36 23.847c-3.147 3.258-3.147 8.547 0 11.806l-.72.694c-3.52-3.645-3.52-9.549 0-13.195l.72.695Zm2.75 8.556c-1.48-1.533-1.48-4.024 0-5.556l-.72-.695c-1.853 1.92-1.853 5.025 0 6.945l.72-.694Zm8.25-9.25c3.52 3.645 3.52 9.549 0 13.194l-.72-.694c3.147-3.258 3.147-8.548 0-11.806l.72-.695Zm-2.75 9.944c1.853-1.92 1.853-5.025 0-6.945l-.72.695c1.48 1.532 1.48 4.023 0 5.556l.72.694Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTrainProfileSignalLarge;
