import type { SVGProps } from "react";
const SvgArrowLongDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.612 29.59 4.711-4.714-.707-.707-3.858 3.86V6h-1v22.03l-3.87-3.861-.707.708 4.724 4.713.354.353.353-.354Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongDownMedium;
