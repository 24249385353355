import type { SVGProps } from "react";
const SvgIc63Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ic-63-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ic-63-negative_svg__a)">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        fill="#EB0000"
        fillRule="evenodd"
      />
      <path
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.524 2.921h-2.2c-.205-.891-.711-1.337-1.518-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.266 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.628.728.941 1.64.941 2.737 0 1.303-.421 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.76-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.942 1.29.39.495.641 1.12.752 1.874Zm-3.916 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.775.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Zm5.047-1.875c.026-.949.261-1.745.704-2.389.717-1.033 1.795-1.55 3.235-1.55 1.108 0 2.002.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.163.847c.623.142 1.113.459 1.472.949.363.49.545 1.084.545 1.78 0 1.144-.421 2.08-1.265 2.808-.78.675-1.767 1.012-2.958 1.012-1.292 0-2.318-.371-3.078-1.115-.754-.744-1.13-1.748-1.13-3.014v-.15h2.222c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.196-1.02-.586-1.353-.321-.274-.817-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.138.01.27.016.396.016 1.329 0 1.993-.493 1.993-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.672-.403-1.147-.403-.543 0-.962.169-1.257.506-.296.338-.454.83-.475 1.48h-2.191Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ic-63-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc63Negative;
