import type { SVGProps } from "react";
const SvgDisplaySwitzerlandWarningLightLarge = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 2H2v28h18v6H10v1h17v-1h-3v-6h3v-1H3V3h38v16h1V2H2.5ZM21 36h2v-6h-2v6Zm3.035-31.164.365.323 2.079 1.843 5.617 2.19.403.157-.137.411-.867 2.596 1.911.58.494-.94.251-.477.429.329 2.056 1.577.168.128.01.211.14 2.964-.909.043-.13-2.753-1.46-1.12-.418.794-.175.333-.36-.11-2.72-.824-.447-.136.148-.443.879-2.63-5.29-2.062-.075-.03-.061-.053-1.775-1.573-1.254 1.625-.093.12-.147.041-5.765 1.58-.177.047-.16-.087-.877-.477-2.815 2.1.877.302.648.224-.458.51-4.375 4.874-1.421 5.226 2.459-.628 1.391-.816.298-.174.248.239 3.273 3.147 4.453-.503 2.672-2.903.387-.42.323.471 3.14 4.58-.75.515-2.817-4.11-2.4 2.608-.115.125-.17.02-4.834.545-.212.024-.154-.148-3.179-3.056-1.15.673-.055.033-.062.016-3.277.837-.757.193.205-.753 1.654-6.084.028-.104.072-.08 3.99-4.444-1.043-.36-.813-.28.69-.514 3.738-2.788.233-.174.256.139.972.528 5.442-1.49 1.458-1.89.297-.387ZM35.5 24v-5h-1v5h1Zm-6.567-3.58 2.5 4.33-.866.5-2.5-4.33.866-.5Zm10.5 4.83 2.5-4.33-.866-.5-2.5 4.33.866.5ZM26.128 40H29v-7a6 6 0 0 1 12 0v7h2.872l.107.356 1.5 5 .193.644H24.328l.193-.644 1.5-5 .107-.356ZM40 40v-7a5 5 0 0 0-10 0v7h10Zm-13.128 1-1.2 4h18.656l-1.2-4H26.872Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDisplaySwitzerlandWarningLightLarge;
