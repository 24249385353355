import type { SVGProps } from "react";
const SvgIr70Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-70-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M44.381 4.235v1.962c-.063.069-.25.282-.56.64-.488.58-.919 1.207-1.284 1.872a12.88 12.88 0 0 0-.994 2.163 18.185 18.185 0 0 0-.906 4.928h-2.381c.032-.98.185-1.952.453-2.895a18.827 18.827 0 0 1 1.187-3.18c.72-1.498 1.45-2.613 2.191-3.346h-5.44V4.235h7.734Z"
      fill="#fff"
    />
    <path
      clipRule="evenodd"
      d="M51.863 4.765a3.587 3.587 0 0 0-2.318-.806 3.706 3.706 0 0 0-3.28 1.882c-.62 1.055-.93 2.452-.93 4.193 0 2.446.594 4.176 1.782 5.189a3.782 3.782 0 0 0 4.285.348 3.796 3.796 0 0 0 1.36-1.44c.588-1.07.882-2.436.882-4.097a10.865 10.865 0 0 0-.339-2.785c-.3-1.118-.78-1.946-1.442-2.484Zm-3.95 2.46c.274-.875.789-1.313 1.545-1.313.778 0 1.309.438 1.593 1.313.22.68.33 1.611.33 2.793 0 1.387-.154 2.418-.464 3.093-.31.675-.783 1.012-1.42 1.012-.767 0-1.292-.435-1.576-1.305a8.747 8.747 0 0 1-.331-2.666c0-1.255.108-2.23.323-2.927Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr70Negative;
