import type { SVGProps } from "react";
const SvgHeartLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.17 24.77 24 43 5.84 24.77c-.74-.78-1.37-1.66-1.89-2.62-.93-1.69-1.45-3.64-1.45-5.72C2.5 9.8 7.82 4.5 14.45 4.5c3.95 0 7.38 2.08 9.55 5 2.17-2.92 5.62-5 9.56-5a11.9 11.9 0 0 1 11.95 11.93c0 2.07-.53 4.02-1.45 5.72-.52.96-1.14 1.84-1.89 2.62Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgHeartLarge;
