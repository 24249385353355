import type { SVGProps } from "react";
const SvgLocationPinBMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 6.262a8.75 8.75 0 0 0-8.75 8.75v.02c.06 1.51.509 2.78 1.22 3.965l.007.01 7.125 11.249.422.667.422-.667 7.125-11.249.007-.01.005-.01c.694-1.216 1.154-2.512 1.167-3.97v-.005A8.75 8.75 0 0 0 18 6.262Zm-6.675 12.215c-.635-1.058-1.022-2.163-1.075-3.475a7.75 7.75 0 0 1 15.5.008c-.011 1.237-.398 2.362-1.03 3.472l-6.696 10.572-6.7-10.577Zm4.923 1.328v-3.7h2.29c.855 0 1.416.058 1.683.173.686.287 1.03.857 1.03 1.709 0 .757-.256 1.28-.764 1.566-.298.168-.879.252-1.741.252h-2.498Zm0-4.82v-3.268h2.374c.67 0 1.118.045 1.346.136.273.11.485.294.634.554.152.258.229.57.229.933 0 .364-.089.682-.263.955-.174.273-.41.458-.702.554-.294.091-.748.136-1.366.136h-2.252Zm2.556-4.474h-3.805v10.501h4.394c.712 0 1.286-.127 1.721-.38.927-.548 1.392-1.445 1.392-2.695 0-.952-.333-1.678-.996-2.176-.195-.149-.47-.276-.822-.381a2.207 2.207 0 0 0 1.026-.876c.248-.398.372-.852.372-1.365 0-.483-.114-.93-.34-1.34a2.342 2.342 0 0 0-.923-.937c-.422-.234-1.096-.351-2.019-.351Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinBMedium;
