import type { SVGProps } from "react";
const SvgPaperClipSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 4h6v9.985l-.18.15-1.505 1.25-.321.266-.32-.267-1.495-1.25-.179-.15V7h1v6.516l.996.833L13 13.515V5H9v11.501l3 2.272 3-2.272V4h1v12.999l-.198.15-3.5 2.65-.302.228-.302-.228-3.5-2.65-.198-.15V4Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPaperClipSmall;
