import type { SVGProps } from "react";
const SvgComputerChipSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 3v3.5m3-3.5v3.5m3-3.5v3.5m-6 12V22m3-3.5V22m3-3.5V22M3 15.5h3.5m-3.5-3h3.5M3 9.5h3.5m12 6H22m-3.5-3H22m-3.5-3H22m-3.5 9h-12v-12h12v12Zm-3-9h-6v6h6v-6Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgComputerChipSmall;
