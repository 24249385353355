import type { SVGProps } from "react";
const SvgWaitingRoomMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.25 11.25c0-3.072 2.43-5.5 5.5-5.5 3.072 0 5.5 2.428 5.5 5.5 0 3.071-2.428 5.5-5.5 5.5-3.07 0-5.5-2.429-5.5-5.5Zm5.5-6.5a6.458 6.458 0 0 0-6.5 6.5c0 3.624 2.877 6.5 6.5 6.5 3.624 0 6.5-2.876 6.5-6.5 0-3.625-2.876-6.5-6.5-6.5Zm.391 7.56 2.852-3.6-.784-.621-2.522 3.184-2.453-2.196-.667.745 2.849 2.55.396.354.33-.416ZM18.25 22.765h13v8.485h-13v-8.485Zm1 1v6.485h11v-6.485h-11Zm3.252-1.5h4.5v-1h-4.5v1ZM8.248 8.75a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-1 4h-.5v8.5h1.5v9h4v-9h1.5v-8.5h-6.5Zm.5 7.5v-6.5h5v6.5h-1.5v9h-2v-9h-1.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWaitingRoomMedium;
