import type { SVGProps } from "react";
const SvgCircleSignalNoiseRatioMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#circle-signal-noise-ratio-medium_svg__a)">
      <path
        d="M14.096 16.088h-1.869c-.037-.439-.2-.774-.488-1.005-.288-.231-.694-.347-1.214-.347-.463 0-.823.09-1.08.273a.885.885 0 0 0-.383.765c0 .296.123.509.369.64.237.13.839.316 1.807.553 1.02.254 1.727.51 2.121.769.726.475 1.087 1.137 1.087 1.985 0 .93-.364 1.665-1.094 2.2-.643.472-1.513.708-2.612.708-1.221 0-2.17-.278-2.846-.836-.677-.557-1.027-1.35-1.052-2.38h1.956c.028.5.157.872.387 1.113.36.386.903.578 1.628.578.493 0 .882-.096 1.17-.288a1.04 1.04 0 0 0 .484-.904c0-.381-.227-.66-.682-.837-.25-.098-.84-.276-1.77-.534a10.41 10.41 0 0 1-1.365-.455 2.924 2.924 0 0 1-.787-.48c-.46-.41-.688-.975-.688-1.696 0-.865.357-1.55 1.075-2.054.61-.43 1.403-.645 2.379-.645.857 0 1.575.17 2.152.51.832.488 1.27 1.277 1.315 2.367Zm5.864-2.662-4.162 11.299h-1.634l4.18-11.3h1.616Zm8.615 0v8.987h-1.973l-3.764-6.123.025 6.123h-1.886v-8.987h1.998l3.736 6.11-.023-6.11h1.887Z"
        fill="currentColor"
      />
      <path
        d="M18 32.25c7.87 0 14.25-6.38 14.25-14.25S25.87 3.75 18 3.75 3.75 10.13 3.75 18 10.13 32.25 18 32.25Z"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="circle-signal-noise-ratio-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircleSignalNoiseRatioMedium;
