import type { SVGProps } from "react";
const SvgAerosolCanSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.504 2a2.5 2.5 0 0 0-2.495 2.333l-3.601.676-.408.076v1.828l.405.078 3.729.718a2.5 2.5 0 1 0 4.828 1.25 2.5 2.5 0 0 0 0-4.917A2.502 2.502 0 0 0 16.504 2Zm-1.5 2.5a1.5 1.5 0 0 1 3 0V5h.5a1.5 1.5 0 0 1 0 3h-.5v.5a1.5 1.5 0 0 1-3 0c0-.322.104-.62.28-.867L15.736 7l-.764-.147L11 6.087v-.172l3.62-.68.456-.085-.051-.461-.014-.114a.462.462 0 0 1-.007-.075ZM6 4h3v3H8v.998h.25a2.75 2.75 0 0 1 2.75 2.75l.002 10.752v.5h-7v-.5l-.005-10.75A2.75 2.75 0 0 1 6.747 8H7V7H6V4Zm1 1v1h1V5H7Zm-2.003 5.748c0-.966.783-1.75 1.75-1.75H8.25c.967 0 1.75.784 1.75 1.75V11H4.997v-.252Zm0 1.252.004 7.001h5L10 12H4.998Zm.005 9v-.999h5v1h-5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAerosolCanSmall;
