import type { SVGProps } from "react";
const SvgTramSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.5 4H10v1H8a3 3 0 0 0-3 3v11h2.066l-1.482 2.222.832.555L8.268 19h7.464l1.852 2.777.832-.555L16.934 19H19V8a3 3 0 0 0-3-3h-2V4h-3.5ZM14 7h-4v1h4V7Zm4 6V8a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v5h12ZM6 14h12v4H6v-4Zm8 2a1 1 0 1 1 2.002.001A1 1 0 0 1 14 16Zm-5-1a1 1 0 1 0 .002 2A1 1 0 0 0 9 15Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTramSmall;
