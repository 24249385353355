import type { SVGProps } from "react";
const SvgGlobeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.75 18c0-5.972 4.189-10.966 9.788-12.205l.04-.063.064.04A12.548 12.548 0 0 1 17.25 5.5c.894 0 1.766.094 2.607.272l.065-.04.04.063c5.6 1.239 9.788 6.233 9.788 12.205s-4.189 10.966-9.788 12.205l-.04.063-.064-.04c-.841.178-1.714.272-2.608.272a12.55 12.55 0 0 1-2.608-.272l-.064.04-.04-.063C8.938 28.966 4.75 23.972 4.75 18Zm23.534 3.25c.303-1.03.466-2.122.466-3.25 0-1.129-.163-2.22-.466-3.25h-5.462A29.38 29.38 0 0 1 23 18a29.42 29.42 0 0 1-.178 3.25h5.462Zm-.345 1h-5.247c-.377 2.547-1.087 4.85-2.044 6.74a11.532 11.532 0 0 0 7.291-6.74Zm-6.123-1c.12-1.046.184-2.134.184-3.25 0-1.118-.064-2.205-.184-3.25h-9.131A28.397 28.397 0 0 0 12.5 18c0 1.117.064 2.204.184 3.25h9.132Zm-8.997 1h8.862c-.425 2.763-1.254 5.193-2.347 7.062a11.572 11.572 0 0 1-4.168 0c-1.093-1.868-1.921-4.299-2.347-7.062Zm-1.14-1A29.409 29.409 0 0 1 11.5 18c0-1.112.061-2.2.178-3.25H6.216A11.502 11.502 0 0 0 5.75 18c0 1.128.163 2.22.466 3.25h5.462Zm-5.118 1h5.247c.377 2.547 1.087 4.85 2.044 6.74a11.532 11.532 0 0 1-7.291-6.74ZM20.648 7.01a11.532 11.532 0 0 1 7.29 6.74h-5.246c-.377-2.547-1.086-4.85-2.044-6.74Zm-1.313-.321c1.092 1.866 1.92 4.297 2.346 7.06H12.82c.426-2.763 1.254-5.193 2.347-7.06a11.566 11.566 0 0 1 4.169 0Zm-5.483.321c-.957 1.89-1.667 4.193-2.044 6.74H6.56a11.532 11.532 0 0 1 7.291-6.74Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGlobeMedium;
