import type { SVGProps } from "react";
const SvgArrowCompassSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.1 3.84-.715 1.389-7.44 14.441L11 21.503v-8.562H2.438l1.833-.945 14.44-7.44 1.39-.716Zm-13.537 8.1H12v5.438L17.78 6.16 6.562 11.941Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCompassSmall;
