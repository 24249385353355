import type { SVGProps } from "react";
const SvgSaBk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={29}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.522 6.656H5.421l.74-3.45h.981c1.06 0 2.341.06 2.341 1.404 0 1.646-1.541 2.046-2.961 2.046Zm-1.421 6.138h-.96l.841-4.052h.899c1.501 0 2.861.22 2.861 1.686 0 2.165-1.921 2.366-3.641 2.366ZM8.062 1H3.921L1 15h3.982c2.08 0 6.56-.321 6.56-4.333 0-1.504-.68-2.707-2.24-2.947v-.04c1.62-.261 2.98-1.626 2.98-3.33 0-2.548-2.04-3.35-4.22-3.35Zm9.979 5.496L19.22 1h-2.8l-3 14h2.8l1.522-7.12 3.88 7.12h3.34l-4.5-7.622L27.524 1h-3.902L18.08 6.496h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaBk;
