import type { SVGProps } from "react";
const SvgLaptopMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 6.25h23.5v13.849l2.916 4.374.084.126v3.651H3.25v-3.651l.084-.126 2.916-4.374V6.25Zm1 1v12.5h21.5V7.25H7.25Zm-2.566 17 2.334-3.5h21.964l2.334 3.5H4.684Zm-.434 1v2h27.5v-2H4.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLaptopMedium;
