import type { SVGProps } from "react";
const SvgArrowCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 12c0-4.073 2.927-7 7-7 2.906 0 5.585 1.68 6.744 4H15v1h5V5h-1v2.504C17.466 5.394 14.812 4 12 4c-4.625 0-8 3.375-8 8 0 4.626 3.375 8 8 8 3.871 0 6.883-2.367 7.75-5.882l-.971-.239C18.027 16.93 15.429 19 12 19c-4.073 0-7-2.925-7-7Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleSmall;
