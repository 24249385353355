import type { SVGProps } from "react";
const SvgDiamondMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.482 3.25h17.036l.148.223 6 9 .198.296-.216.284-14.25 18.75-.398.523-.398-.524-14.25-18.75-.216-.283.198-.296 6-9 .148-.223Zm-4.798 9 4.89-7.335 2.181 7.335h-7.07ZM16.74 29.015 4.758 13.25h7.295l4.687 15.765ZM31.242 13.25 19.26 29.015l4.687-15.765h7.295Zm-4.817-8.335 4.89 7.335h-7.07l2.18-7.335Zm-7.527-.665h6.682l-2.167 7.287-4.515-7.287Zm-1.796 0-4.515 7.289L10.42 4.25h6.682Zm-3.78 8L18 4.7l4.678 7.55h-9.355ZM18 29.745l4.904-16.495h-9.808L18 29.745Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDiamondMedium;
