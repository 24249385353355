import type { SVGProps } from "react";
const SvgPercentSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.998 8.997a1 1 0 1 0-.002 1.999 1 1 0 0 0 .002-1.999Zm-2 1a2 2 0 1 1 3.998-.001 2 2 0 0 1-3.998.001ZM14 15a1 1 0 1 0 .001 2A1 1 0 0 0 14 15Zm-2 1a2 2 0 1 1 4.001 0A2 2 0 0 1 12 16Zm-4.146 1.853 9-9-.707-.707-9 9 .707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPercentSmall;
