import type { SVGProps } from "react";
const SvgCoinDollarLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.5 6C13.283 6 5 14.283 5 24.5S13.283 43 23.5 43 42 34.717 42 24.5 33.717 6 23.5 6ZM4 24.5C4 13.73 12.73 5 23.5 5S43 13.73 43 24.5 34.27 44 23.5 44 4 35.27 4 24.5Zm12.25 3.56h2.355c0 2.81 1.271 4.288 3.812 4.435v-7.663c-1.413-.24-2.606-.67-3.577-1.289-1.477-.933-2.217-2.315-2.217-4.144 0-1.562.526-2.833 1.573-3.818 1.05-.984 2.457-1.518 4.221-1.602V11.5h1.524v2.48c1.756.139 3.087.657 3.991 1.552.98.961 1.46 2.227 1.441 3.798h-2.328c-.147-2.189-1.181-3.322-3.104-3.395v6.943c1.166.296 1.97.522 2.42.679a6 6 0 0 1 1.254.61c1.542.997 2.313 2.517 2.313 4.559 0 2.106-.858 3.71-2.577 4.81-.822.527-1.96.83-3.41.915V37.5h-1.524v-3.049c-2.078-.13-3.623-.75-4.628-1.858-1.036-1.145-1.548-2.655-1.539-4.533Zm6.167-12.138c-.897.026-1.598.216-2.107.567-.906.627-1.359 1.534-1.359 2.717 0 .923.283 1.653.847 2.189.564.537 1.436.906 2.619 1.108v-6.581Zm1.524 16.573c1.072-.007 1.95-.344 2.634-1.003.684-.662 1.024-1.509 1.024-2.545 0-1.06-.294-1.9-.887-2.513-.59-.616-1.514-1.038-2.771-1.27v7.331ZM9.677 20.15c1.411-4.49 4.987-8.047 9.474-9.473l-.302-.954c-4.795 1.525-8.616 5.322-10.126 10.127l.954.3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCoinDollarLarge;
