import type { SVGProps } from "react";
const SvgThumbDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.749 7.75h5.501v1.547a3.65 3.65 0 0 1 .594-.585c.975-.759 2.237-.95 3.406-.95h7.5a2 2 0 0 1 1.725 3.013 1.999 1.999 0 0 1 1.098 3.488 1.997 1.997 0 0 1 0 3 2 2 0 0 1-1.323 3.5h-4.516l1.888 4.795.003.007a2 2 0 0 1-3.294 2.107l-.008-.008-.007-.008-6.287-6.893H12.25v-.001H7.749V7.75Zm4.501 4.986v7.026H8.749V8.75h3.501v3.986Zm1 7.027h1.22l.15.163 6.426 7.047a1 1 0 0 0 1.644-1.053l-2.155-5.474-.27-.683h5.985a1 1 0 1 0 0-2H24v-1h2.25a1 1 0 1 0 0-2H24v-1h2.25a1 1 0 1 0 0-2H24v-1h.75a1 1 0 0 0 0-2h-7.5c-1.093 0-2.08.184-2.79.738-.683.531-1.205 1.48-1.21 3.236v7.026Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgThumbDownMedium;
