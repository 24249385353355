import type { SVGProps } from "react";
const SvgShipSteeringWheelSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 4.016V2h1v2.016a7.468 7.468 0 0 1 4.438 1.84l1.426-1.427.707.707-1.426 1.426A7.47 7.47 0 0 1 18.984 11H21v1h-2.016a7.468 7.468 0 0 1-1.839 4.438l1.426 1.426-.707.707-1.426-1.426A7.468 7.468 0 0 1 12 18.984V21h-1v-2.016a7.47 7.47 0 0 1-4.438-1.839l-1.426 1.426-.707-.707 1.426-1.426A7.469 7.469 0 0 1 4.016 12H2v-1h2.016a7.469 7.469 0 0 1 1.839-4.438L4.429 5.136l.707-.707 1.426 1.426A7.469 7.469 0 0 1 11 4.016Zm.5.984a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM6 11.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm1.027-.5H9.05a2.48 2.48 0 0 1 .364-.878L7.984 8.69A4.479 4.479 0 0 0 7.027 11Zm1.664-3.016 1.43 1.43c.263-.173.56-.3.879-.364V7.027a4.479 4.479 0 0 0-2.309.957ZM12 7.027V9.05c.32.065.616.19.879.365l1.43-1.431A4.478 4.478 0 0 0 12 7.027Zm3.016 1.664-1.43 1.43c.173.263.3.56.364.879h2.023a4.479 4.479 0 0 0-.957-2.309ZM15.973 12H13.95a2.48 2.48 0 0 1-.364.878l1.43 1.43c.518-.646.86-1.44.957-2.308Zm-1.664 3.016-1.43-1.43c-.263.173-.56.3-.879.364v2.023a4.48 4.48 0 0 0 2.31-.957ZM11 15.973V13.95a2.486 2.486 0 0 1-.879-.364l-1.43 1.43a4.48 4.48 0 0 0 2.309.957Zm-3.016-1.664 1.43-1.43c-.173-.263-.3-.56-.364-.879H7.027c.096.868.439 1.662.957 2.309ZM11.5 10a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 11.5 10Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShipSteeringWheelSmall;
