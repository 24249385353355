import type { SVGProps } from "react";
const SvgFolderPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23.25 9.25h-.309l-.138.276-1.362 2.724H6.25v14.5H22.5v-1H7.25v-12.5h14.809l.138-.276 1.362-2.724h5.184l.007 9.25h1l-.008-9.75v-.5H23.25ZM28.75 30v-3.25H25.5v-1h3.25V22.5h1v3.25H33v1h-3.25V30h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderPlusMedium;
