import type { SVGProps } from "react";
const SvgWeightWheelMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#weight-wheel-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M16.25 6a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm3.871 1.75a2.75 2.75 0 1 0-4.243 0H9.36l-.095.379-3 12-.155.621h23.78l-.155-.621-3-12-.095-.379h-6.519ZM18 8.75h-7.86l-2.75 11h21.22l-2.75-11H18Zm3.273 1.75c.818 0 1.5.3 2.045.9.41.45.682 1.05.682 1.65h-1.364c-.136-.45-.272-.75-.409-.9-.273-.15-.545-.3-.954-.3-.546 0-.955.3-1.364.75-.273.3-.409.9-.409 1.65 0 .75.136 1.35.41 1.65.272.45.817.75 1.363.75.409 0 .818-.15 1.09-.45.273-.3.41-.75.41-1.2h-1.5v-1.2H24v3.9h-.954l-.137-.75C22.364 17.55 21.82 18 21 18c-.954 0-1.773-.45-2.318-1.2-.41-.75-.682-1.5-.682-2.55 0-1.2.273-2.1.955-2.7.545-.75 1.363-1.05 2.318-1.05Zm-7.878 0v3.125l2.652-3.125h1.813l-2.65 2.969L18 18h-1.814l-1.953-3.281-.838.937V18H12v-7.5h1.395ZM25.76 22.408c-.673 3.59-3.888 6.342-7.759 6.342-3.88 0-7.095-2.742-7.758-6.34l-.984.18c.752 4.079 4.384 7.16 8.742 7.16 4.35 0 7.98-3.094 8.741-7.158l-.982-.184Zm-18.513.025c.706 5.25 5.25 9.317 10.754 9.317 5.51 0 10.053-4.062 10.754-9.316l.992.132C28.978 28.318 24.01 32.75 18 32.75c-6.005 0-10.972-4.439-11.746-10.183l.992-.134ZM18 22.75c-.381 0-.813-.24-1.131-.588l-.738.676c.43.469 1.109.912 1.869.912.786 0 1.43-.378 1.886-.933l-.772-.634c-.301.366-.678.567-1.114.567Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="weight-wheel-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWeightWheelMedium;
