import type { SVGProps } from "react";
const SvgSaRq = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={29}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.1 9.531c0 1.764.92 3.336 2.94 3.336 3.202 0 4.982-3.722 4.982-6.419 0-1.803-.96-3.316-2.98-3.316-3.26 0-4.942 3.666-4.942 6.4Zm8.596 3.096a8.876 8.876 0 0 0 2.247-5.888c0-3.607-2.04-5.739-5.822-5.739-2.28 0-4.22.835-5.74 2.521a8.86 8.86 0 0 0-2.202 5.855c0 3.22 2.061 5.624 5.502 5.624 1.553 0 3.009-.308 4.24-.973l.632.739h3.37l-2.227-2.139ZM5.521 7.011h1.02c1.501 0 3.321-.446 3.321-2.23 0-1.416-1.261-1.416-2.401-1.416h-1.16l-.78 3.646ZM3.92 1.233h3.391c2.56 0 5.471 0 5.471 3.2 0 2.326-1.8 3.567-4.08 3.606v.039c.759.039 1.24.503 1.42 1.24l1.34 5.449H8.521l-.68-3.18a19.254 19.254 0 0 1-.125-.62C7.51 9.87 7.371 9.145 6.081 9.145h-1.06L3.8 14.767H1L3.92 1.233Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaRq;
