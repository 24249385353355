import type { SVGProps } from "react";
const SvgMountainRockTransmitterSignalSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m2 2.433.82.683 3 2.5.136.113.034.173.95 4.746 4.263 1.895.214.095.064.225.812 2.841 5.43-3.62.554.832-5.519 3.68 4.114 4.57.75.834H2V2.433Zm1 2.135V21h12.377l-3.749-4.165-.077-.086-.032-.111-.936-3.276-4.286-1.905-.237-.105-.05-.254-.966-4.827L3 4.568Zm11.862 5.777a4.335 4.335 0 0 1 6.277 0l.722-.69a5.334 5.334 0 0 0-7.723 0l.724.69Zm.752.838C16.197 10.473 17.039 10 18 10c.961 0 1.803.473 2.386 1.183l-.772.635C19.19 11.3 18.617 11 18 11c-.617 0-1.19.301-1.614.818l-.772-.635Zm-4.685-5.94-1.5-2.5-.858.514 1.5 2.5.858-.514Zm-4-2.5 1.8 3-.858.514-1.8-3 .858-.514Zm2.816 6.641c-.338-.095-.499-.255-.573-.391a.627.627 0 0 1-.04-.485c.103-.34.42-.568.832-.438.85.325.97.849.918 1.071a.407.407 0 0 1-.26.286c-.161.067-.441.098-.86-.037l-.008-.003-.01-.003Zm.54-2.261c-1.042-.342-1.873.312-2.11 1.096-.12.394-.101.847.119 1.251.222.409.621.717 1.17.874.575.184 1.105.187 1.54.008.443-.183.747-.542.85-.979.211-.882-.402-1.81-1.548-2.243l-.01-.004-.011-.003Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainRockTransmitterSignalSmall;
