import type { SVGProps } from "react";
const SvgShoppingCartLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 12h4v20h22v-1H14v-3h21.876l.104-.361 3.48-12 .186-.64H14V11H9v1Zm5 4v11h21.124l3.19-11H14Zm4.5 19a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3ZM16 36.5a2.5 2.5 0 1 1 5.002.001A2.5 2.5 0 0 1 16 36.5Zm12 0a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 28 36.5Zm1.5-2.5a2.5 2.5 0 1 0 .002 5 2.5 2.5 0 0 0-.002-5ZM33 20H19v-1h14v1Zm-14 4h12v-1H19v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShoppingCartLarge;
