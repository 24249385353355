import type { SVGProps } from "react";
const SvgDoorbellHandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 3.25h-.5v13h9.3l.992.99a2.003 2.003 0 0 0-.895.518l-.001.001a2.005 2.005 0 0 0-.155 2.656c-.332.087-.646.26-.906.52l-.001.001a2.005 2.005 0 0 0-.148 2.665 2.002 2.002 0 0 0-.912 3.354l.001.001.002.002.006.005.024.024.11.11.546.543 3.025 3.022.01.01a7.673 7.673 0 0 0 4.043 2.004 7.873 7.873 0 0 0 1.442.11 6.02 6.02 0 0 0 .541-.035l.034-.004.01-.002h.005l-.073-.495.074.494.177-.026.12-.134 6.75-7.5.178-.197-.064-.258-2.096-8.38h7.361V3.25h-29Zm21.368 12h6.632v-11H4.25v11h7.3l-2.006-2.006A3.5 3.5 0 1 1 13.25 9.75c0 .455-.094.881-.25 1.27l4.338 4.23h3.972a2.002 2.002 0 0 1 1.938-1.5c.555 0 1.005.21 1.342.577.237.257.405.578.528.923Zm-3.871 1h-2.884l2.885 2.813-.001-2.813Zm-9.05-6.009c.035-.16.053-.323.053-.491a2.5 2.5 0 1 0-3.34 2.355 2.002 2.002 0 0 1 3.287-1.864Zm2.821-1.491h13.5v-1h-13.5v1Zm0 3h13.5v-1h-13.5v1ZM26.45 24.613l-6.443 7.16a6.88 6.88 0 0 1-1.55-.082 6.674 6.674 0 0 1-3.517-1.74 3205.514 3205.514 0 0 0-3.568-3.564l-.11-.11-.025-.024-.006-.006h-.001a1.002 1.002 0 0 1 1.417-1.417h.001l.53.53h.001l.707-.707-1.594-1.593a1.005 1.005 0 0 1 0-1.417c.369-.369.953-.39 1.346-.066l1.665 1.66.706-.709-1.595-1.59.003-.002a1.998 1.998 0 0 0-.102-.095l-.961-.958a1.005 1.005 0 0 1 0-1.417c.374-.374.969-.39 1.362-.052l2.712 2.711.707-.707-2.656-2.66a2.17 2.17 0 0 0-.078-.073l-5.23-5.227a1.002 1.002 0 0 1 1.417-1.418l.005.005 9.807 9.563.85.828V20.25l-.004-4.496c0-.556.449-1.003 1.002-1.003.274 0 .458.093.606.254.164.178.304.464.41.872l.001.002 2.185 8.735Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDoorbellHandMedium;
