import type { SVGProps } from "react";
const SvgHalfFareCardSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 7.5A2.5 2.5 0 0 1 4.5 5h15A2.5 2.5 0 0 1 22 7.5v10a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 17.5v-10ZM4.5 6A1.5 1.5 0 0 0 3 7.5v10A1.5 1.5 0 0 0 4.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 19.5 6h-15ZM8 12V7h-.9c-.073.334-.215.573-.427.715a1.573 1.573 0 0 1-.485.212 3.235 3.235 0 0 1-.688.074v.726h1.354V12H8Zm6.194 1.388C13.87 13.13 13.466 13 12.98 13c-.546 0-.992.158-1.335.476-.354.323-.53.782-.53 1.375v.106h1c.018-.724.293-1.085.83-1.085.219 0 .393.066.527.198a.697.697 0 0 1 .203.52.81.81 0 0 1-.15.487c-.1.141-.274.291-.524.455-.511.338-.854.571-1.025.698a3.414 3.414 0 0 0-.452.39 1.836 1.836 0 0 0-.385.621c-.093.24-.138.493-.138.759h3.75v-.869h-2.444a2.79 2.79 0 0 1 .393-.391c.143-.113.377-.277.705-.491.344-.224.556-.367.636-.426.082-.058.168-.136.26-.235.284-.299.424-.655.424-1.067 0-.476-.178-.853-.53-1.133Zm-7.82 3.944 8-9-.748-.664-8 9 .748.664Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHalfFareCardSmall;
