import type { SVGProps } from "react";
const SvgCarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.464 7.464C6.184 7.744 6 8.215 6 9v2h6.05a2.501 2.501 0 0 1 4.9 0H18V9c0-.785-.183-1.256-.464-1.536C17.256 7.184 16.785 7 16 7H8c-.785 0-1.256.183-1.536.464ZM15.914 11a1.5 1.5 0 0 0-2.829 0h2.83ZM18 16v-4H6v4h12Zm1 .5V11h1v-1h-1V9c0-.921-.214-1.7-.757-2.243C17.7 6.214 16.921 6 16 6H8c-.921 0-1.7.214-2.243.757C5.214 7.3 5 8.079 5 9v1H4v1h1v8h4v-2h6.001v2H19v-2.5ZM6 18v-1h2v1H6Zm10.001 0v-1H18v1h-1.999ZM7 14a1 1 0 1 1 2.002 0A1 1 0 0 1 7 14Zm8 0a1 1 0 1 1 2.002.001A1 1 0 0 1 15 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCarSmall;
