import type { SVGProps } from "react";
const SvgHouseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.354 5.646 18 5.292l-.354.354-12 12 .708.707 1.396-1.396V29.75h6.985v-7.5h6.5v7.5h7.015V16.957l1.396 1.396.708-.707-12-12Zm8.896 10.31L18 6.707l-9.25 9.25V28.75h4.985v-7.5h8.5v7.5h5.015V15.957Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHouseMedium;
