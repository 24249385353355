import type { SVGProps } from "react";
const SvgSunshineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.012 7.003V3.988h1v3.015h-1Zm-5.973-.334 2.277 2.277.707-.707-2.277-2.277-.707.707ZM12.5 9c-1.927 0-3.472 1.564-3.472 3.499a3.495 3.495 0 0 0 3.495 3.495A3.48 3.48 0 0 0 16 12.5c0-1.93-1.57-3.5-3.5-3.5Zm-4.472 3.499C8.028 10.02 10.012 8 12.5 8c2.482 0 4.5 2.017 4.5 4.5a4.48 4.48 0 0 1-4.477 4.494 4.495 4.495 0 0 1-4.495-4.495ZM7.003 13H4v-1h3.003v1Zm11.303-6.948L16.042 8.29l.703.711 2.264-2.238-.703-.71ZM17.994 12H21v1h-3.006v-1Zm1.03 6.239-2.267-2.257-.706.708 2.267 2.257.706-.708ZM12 20.996v-3.003h1v3.003h-1Zm-3.696-4.948-2.262 2.26.707.708 2.262-2.261-.707-.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunshineSmall;
