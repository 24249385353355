import type { SVGProps } from "react";
const SvgHostelMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 3.25h23.5v3H33v1h-1.75v23H33v1H3v-1h1.75v-23H3v-1h3.25v-3Zm0 4h-.5v23h8v-4.5h8.501l-.001.5-.008 4h8.008v-23h-24Zm14.992 23H14.75v-3.5h6.499l-.007 3.5Zm7.508-26v2H7.25v-2h21.5Zm-9 9v3.25h1V9h-1v3.25h-3.5V9h-1v7.5h1v-3.25h3.5Zm-10 7.151L10.5 18l.75 2.401h2.25L11.7 21.6l.75 2.4-1.95-1.349L8.55 24l.75-2.25-1.8-1.35h2.25ZM18 18l-.75 2.401H15l1.8 1.349-.75 2.25L18 22.65 19.95 24l-.75-2.4 1.8-1.35h-2.25L18 18Zm6.75 2.401L25.5 18l.75 2.25h2.25l-1.8 1.35.75 2.4-1.95-1.349L23.55 24l.75-2.25-1.8-1.349h2.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHostelMedium;
