import type { SVGProps } from "react";
const SvgScooterProfilePowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.858 9.014c.92.073 1.765.422 2.447.966l-.624.782A3.5 3.5 0 0 0 15 13.499v.5H8.95a2.502 2.502 0 0 1-4.9 0H2.014L2 13.514c-.055-1.922.788-3.526 1.813-4.648a7.088 7.088 0 0 1 1.62-1.328c.53-.31 1.077-.516 1.544-.537h3.372l-.411.741-.001.002-.007.012-.027.053a8.863 8.863 0 0 0-.43 1.03A8.602 8.602 0 0 0 9.018 11h2.739l4.635-5.915L15.648 3H14V2h2.352l.119.332 2.387 6.682ZM16.79 6.2l-4.396 5.61-.15.191H8v-.5c0-1.17.266-2.227.527-2.986.066-.19.131-.362.193-.514H7.013c-.24.014-.62.135-1.075.401a6.09 6.09 0 0 0-1.387 1.14c-.805.88-1.451 2.067-1.542 3.458h11.019a4.502 4.502 0 0 1 3.781-3.946L16.79 6.199ZM5.085 14a1.502 1.502 0 0 0 2.83 0h-2.83ZM18.5 12a1.5 1.5 0 1 0 .001 3.002A1.5 1.5 0 0 0 18.5 12ZM16 13.5a2.5 2.5 0 1 1 5.002.002A2.5 2.5 0 0 1 16 13.5ZM14 18v-1h-.5l-1.002.002A2.5 2.5 0 0 0 10.05 19H7v1h3.05a2.502 2.502 0 0 0 2.45 2H14v-1h2v-1h-2v-1l2.001-.004-.002-1L14 18Zm-1.502 0H13v3h-.5a1.5 1.5 0 0 1-.002-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgScooterProfilePowerPlugSmall;
