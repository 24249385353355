import type { SVGProps } from "react";
const SvgSaVt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.8 1H1l1.84 14h3.341l7.802-14h-3.041L5.141 11.892h-.04L3.8 1Zm21.342 0H14.36l-.48 2.206h3.941L15.281 15h2.92l2.54-11.794h3.941L25.142 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaVt;
