import type { SVGProps } from "react";
const SvgTwoFingerTapLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.334 5.012A8.93 8.93 0 0 1 26.001 3h.008a9.34 9.34 0 0 1 9.16 9.504 9.7 9.7 0 0 1-.948 4.212l-.902-.432a8.7 8.7 0 0 0 .85-3.782v-.011A8.34 8.34 0 0 0 25.996 4a7.93 7.93 0 0 0-5.142 1.881l-.127.108-.167.01a8 8 0 0 0-7.53 7.98l6.377-7.827-6.376 7.828v.5h-1v-.5a9 9 0 0 1 8.304-8.968Zm3.834 3.18a5.5 5.5 0 0 1 6.822 5.31 5.35 5.35 0 0 1-.914 3.018l.829.56a6.35 6.35 0 0 0 1.085-3.582A6.5 6.5 0 0 0 20.762 9.07a6.5 6.5 0 0 0-5.715 5.344l.986.172a5.5 5.5 0 0 1 5.005-4.537l.202-.016.134-.151a5.5 5.5 0 0 1 2.794-1.69Zm.271 3.747A1.5 1.5 0 0 1 27 13v13h1v-2.5a1.5 1.5 0 1 1 3 0V26h1v-.51a1.5 1.5 0 0 1 3 .01v7c0 2.877-.49 5.075-.975 6.545a13.335 13.335 0 0 1-.662 1.655c-.059.12-.11.22-.153.3H19.784l-7.353-12.334c-.295-.5-.446-.93-.46-1.29-.013-.335.09-.64.383-.932l.001-.002a1.498 1.498 0 0 1 2.13 0l.006.006.005.005 4.66 4.41.844.799V15.5a1.5 1.5 0 1 1 3 0V26h1V13c0-.398.158-.78.44-1.06Zm-5.768 29.148-7.1-11.91-.002-.003c-.335-.57-.574-1.165-.597-1.758-.025-.616.186-1.191.674-1.679a2.499 2.499 0 0 1 3.543-.005L19 29.338V15.5a2.5 2.5 0 0 1 4-2V13a2.5 2.5 0 0 1 5 0v8.5a2.5 2.5 0 0 1 4 2 2.5 2.5 0 0 1 4 2v7c0 2.993-.51 5.295-1.025 6.858-.244.74-.49 1.313-.681 1.715A1.1 1.1 0 0 1 35 42.1v2.8a1.1 1.1 0 0 1-1.1 1.1H19.1a1.1 1.1 0 0 1-1.1-1.1v-2.8a1.1 1.1 0 0 1 .671-1.013ZM33.774 42H19.1a.1.1 0 0 0-.1.1v2.8a.1.1 0 0 0 .1.1h14.8a.1.1 0 0 0 .1-.1v-2.8a.1.1 0 0 0-.1-.1h-.126Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTwoFingerTapLarge;
