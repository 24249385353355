import type { SVGProps } from "react";
const SvgTorchSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.796 3.097 11 2.512v5.294l-2.342-.78L8 6.806V10.5a3.5 3.5 0 0 0 3.5 3.5c1.964 0 3.325-1.596 3.496-3.439a2.575 2.575 0 0 0 .025-.2c.013-.134.028-.325.033-.56a8.628 8.628 0 0 0-.17-1.877c-.304-1.5-1.107-3.372-3.088-4.827Zm2.212 7.316-.001.005-.003.018-.002.02C13.872 11.906 12.844 13 11.5 13A2.5 2.5 0 0 1 9 10.5V8.194l2.342.78.658.22V4.578a6.78 6.78 0 0 1 1.903 3.545c.134.66.16 1.242.15 1.657a6.023 6.023 0 0 1-.041.604l-.004.028ZM9.5 15h-.61l.12.598 1 5 .08.402h2.82l.08-.402 1-5 .12-.598H9.5Zm1.41 5-.8-4h2.78l-.8 4h-1.18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTorchSmall;
