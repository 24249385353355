import type { SVGProps } from "react";
const SvgLargeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.5 6C14.836 6 7 13.836 7 23.5S14.836 41 24.5 41a17.52 17.52 0 0 0 7.282-1.585l.415.91A18.52 18.52 0 0 1 24.5 42C14.284 42 6 33.716 6 23.5S14.284 5 24.5 5 43 13.284 43 23.5c0 .655-.03 1.305-.103 1.946l-.994-.112c.068-.599.097-1.21.097-1.834C42 13.836 34.164 6 24.5 6ZM17 18.5c0-.826.674-1.5 1.5-1.5s1.5.674 1.5 1.5-.674 1.5-1.5 1.5-1.5-.674-1.5-1.5Zm1.5-.5c-.274 0-.5.226-.5.5s.226.5.5.5.5-.226.5-.5-.226-.5-.5-.5Zm10.5.5c0-.826.674-1.5 1.5-1.5s1.5.674 1.5 1.5-.674 1.5-1.5 1.5-1.5-.674-1.5-1.5Zm1.5-.5c-.274 0-.5.226-.5.5s.226.5.5.5.5-.226.5-.5-.226-.5-.5-.5Zm7 4.5.412-.283-.412-.6-.412.6.412.283Zm0 0 .412-.283.001.002.004.004.01.017.044.064a40.358 40.358 0 0 1 .735 1.147c.474.766 1.106 1.84 1.738 3.068C41.697 28.949 43 32.079 43 34.64c0 2.98-2.478 5.36-5.5 5.36-3.023 0-5.5-2.39-5.5-5.36 0-2.556 1.304-5.686 2.555-8.118a46.048 46.048 0 0 1 2.474-4.217l.043-.065.011-.016.003-.005.001-.001.413.282Zm-.356 1.477c.133-.214.253-.404.356-.566a44.85 44.85 0 0 1 2.055 3.566C40.805 29.4 42 32.34 42 34.64c0 2.4-2.002 4.36-4.5 4.36-2.497 0-4.5-1.97-4.5-4.36 0-2.294 1.196-5.234 2.445-7.66a45.018 45.018 0 0 1 1.7-3.003ZM24.5 27c-3.929 0-7.385 1.825-9.568 4.698l.796.605C17.725 29.675 20.89 28 24.5 28c2.319 0 4.442.69 6.2 1.875l.56-.83A12.025 12.025 0 0 0 24.5 27Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLargeLarge;
