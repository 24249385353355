import type { SVGProps } from "react";
const SvgArrowChangeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m2.65 7.65 3.5-3.5.353-.354.354.353 3.5 3.5-.707.707L7.003 5.71v10.793a2.5 2.5 0 1 0 5 0V7.502a3.5 3.5 0 0 1 7 0v10.793l2.646-2.646.707.707-3.5 3.5-.353.354-.354-.354-3.5-3.5.708-.707 2.646 2.647V7.502a2.5 2.5 0 0 0-5 0v9a3.5 3.5 0 0 1-7 0V5.71L3.357 8.356 2.65 7.65Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowChangeSmall;
