import type { SVGProps } from "react";
const SvgArrowsRightLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.843 7.137-3.99-3.99-.707.706 3.137 3.138H5v1h11.287l-3.141 3.156.708.705 3.99-4.009.352-.353-.353-.353ZM6.707 15.992H18v1H6.703l3.142 3.156-.71.705-3.99-4.009-.351-.353.353-.353 3.99-3.99.707.706-3.137 3.138Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsRightLeftSmall;
