import type { SVGProps } from "react";
const SvgWheelMicrophoneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#wheel-microphone-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M18 4.25C10.406 4.25 4.25 10.406 4.25 18S10.406 31.75 18 31.75c1.414 0 2.702-.185 3.978-.578l.294.956c-1.377.424-2.765.622-4.272.622-8.146 0-14.75-6.604-14.75-14.75S9.854 3.25 18 3.25c5.142 0 9.776 2.598 12.417 6.6l-.834.55C27.126 6.678 22.805 4.25 18 4.25Zm9 9A1.75 1.75 0 0 0 25.25 15v9a1.75 1.75 0 1 0 3.5 0v-9A1.75 1.75 0 0 0 27 13.25ZM24.25 15a2.75 2.75 0 1 1 5.5 0v9a2.75 2.75 0 1 1-5.5 0v-9ZM18 16.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 18a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM26.5 29.733v2.017H24v1h6v-1h-2.5v-2.017c2.862-.191 5.25-2.028 5.25-4.983h-1c0 2.398-2.03 4-4.75 4s-4.75-1.602-4.75-4h-1c0 2.955 2.388 4.792 5.25 4.983ZM7.25 18c0-5.937 4.813-10.75 10.75-10.75a10.67 10.67 0 0 1 7.74 3.296l.72-.692A11.671 11.671 0 0 0 18 6.25C11.51 6.25 6.25 11.51 6.25 18S11.51 29.75 18 29.75c1.005 0 1.919-.163 2.822-.39l-.244-.97c-.858.216-1.684.36-2.578.36-5.937 0-10.75-4.813-10.75-10.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="wheel-microphone-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWheelMicrophoneMedium;
