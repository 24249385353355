import type { SVGProps } from "react";
const SvgDocumentVideoSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6 4h6.707l.147.147 5 5 .146.146V20H6V4Zm1 1v14h10v-9h-5V5H7Zm6 .707L16.293 9H13V5.707Zm-3.235 5.37L9 10.597v6.804l.765-.478 4-2.5.678-.424-.678-.424-4-2.5ZM12.557 14 10 15.598v-3.196L12.557 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentVideoSmall;
