import type { SVGProps } from "react";
const SvgPlayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m10.75 6.63.752.438 9 5.25 9 5.25.74.432-.74.432-9 5.25-9 5.25-.752.438V6.63Zm1 1.74v19.26l8.248-4.812L28.258 18l-8.26-4.818L11.75 8.37Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPlayMedium;
