import type { SVGProps } from "react";
const SvgIdCardEmployeeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 4a1 1 0 0 0-1 1v2h2V5a1 1 0 0 0-1-1Zm-2 1a2 2 0 1 1 4 0v1h5.5A2.5 2.5 0 0 1 22 8.5v9a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 17.5v-9A2.5 2.5 0 0 1 4.5 6H10V5Zm4 2.5V7h5.5A1.5 1.5 0 0 1 21 8.5v9a1.5 1.5 0 0 1-1.5 1.5h-6.575c-.231-1.846-1.094-3.457-2.351-4.325A3.474 3.474 0 0 0 11 13c0-1.547-1.022-3-2.5-3S6 11.453 6 13c0 .599.153 1.184.426 1.675-1.244.859-2.101 2.444-2.344 4.266A1.502 1.502 0 0 1 3 17.5v-9A1.5 1.5 0 0 1 4.5 7H10v1h4v-.5ZM5.083 19h6.834c-.232-1.618-1.002-2.904-1.974-3.543A2.197 2.197 0 0 1 8.5 16a2.197 2.197 0 0 1-1.443-.543c-.972.639-1.742 1.925-1.974 3.543ZM13 13h4v-1h-4v1Zm6 2h-6v-1h6v1ZM7 13c0-1.215.77-2 1.5-2s1.5.785 1.5 2c0 1.215-.77 2-1.5 2S7 14.215 7 13Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIdCardEmployeeSmall;
