import type { SVGProps } from "react";
const SvgWifiMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.966 12.724C6.746 8.746 12.075 6.25 18 6.25c5.923 0 11.253 2.496 15.032 6.474l-.725.689C28.707 9.623 23.635 7.25 18 7.25c-5.637 0-10.709 2.373-14.31 6.163l-.724-.69ZM18 25.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 27a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM18 10.75c-4.64 0-8.814 1.954-11.773 5.07l.725.689C9.732 13.582 13.648 11.75 18 11.75s8.268 1.832 11.048 4.759l.725-.69C26.813 12.705 22.639 10.75 18 10.75Zm-8.506 8.173a11.705 11.705 0 0 1 8.514-3.668c3.352 0 6.367 1.41 8.506 3.658l-.725.69a10.703 10.703 0 0 0-7.781-3.348c-3.068 0-5.83 1.292-7.788 3.356l-.726-.688ZM18 19.75c-2.072 0-3.928.88-5.246 2.267l.725.69A6.215 6.215 0 0 1 18 20.75c1.781 0 3.382.755 4.522 1.956l.725-.688A7.215 7.215 0 0 0 18 19.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWifiMedium;
