import type { SVGProps } from "react";
const SvgDocumentPdfMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.263 6.25h9.694l.146.147 7.5 7.5.147.146v15.708H9.25v-.5l.013-22.5v-.5Zm1 1-.013 21.5h15.5v-14h-7.5v-7.5h-7.987Zm8.987.708 5.793 5.793H19.25V7.958Zm-6.273 16.649v-1.274h.823c.27 0 .464.04.582.123.164.111.246.28.246.504 0 .224-.07.395-.213.509-.114.091-.301.138-.562.138h-.876Zm.834-2.105h-1.815V27h.98v-1.62h1.009c.26 0 .477-.025.652-.076.177-.05.335-.132.471-.248.324-.267.486-.636.486-1.107 0-.528-.196-.924-.586-1.188a1.413 1.413 0 0 0-.476-.2 3.63 3.63 0 0 0-.721-.058Zm3.303.831v2.837h.756c.233 0 .417-.024.555-.07a.859.859 0 0 0 .359-.236c.238-.257.357-.635.357-1.134 0-.46-.11-.806-.33-1.043-.22-.235-.546-.354-.978-.354h-.719Zm-.98-.83h1.817c.338 0 .627.04.869.116.241.078.453.201.637.37.45.41.674.979.674 1.706 0 .773-.212 1.367-.636 1.788-.186.186-.396.32-.63.398-.234.08-.533.118-.893.118h-1.839v-4.497Zm7.84.83v-.83h-3.166V27h.981v-1.851h1.902v-.773H21.79v-1.042h2.184Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPdfMedium;
