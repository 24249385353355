import type { SVGProps } from "react";
const SvgSaFs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={23}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.881 3.133c1.02 0 2.08.291 2.9.698l.66-2.23c-1.14-.446-2.4-.601-3.62-.601-2.68 0-5.202 1.24-5.202 4.093 0 1.977 1.433 2.754 2.727 3.455 1.064.577 2.035 1.103 2.035 2.206 0 1.454-1.32 2.114-2.66 2.114-1.2 0-2.34-.447-3.42-.932l-.722 2.308c1.261.446 2.641.756 3.981.756 3.061 0 5.741-1.202 5.741-4.518 0-2.138-1.536-3.017-2.864-3.775-1.008-.577-1.896-1.085-1.896-2.023 0-1.163 1.34-1.551 2.34-1.551Zm-6.239-1.9H3.94L1 14.768h2.8l1.281-5.933h4.68l.441-2.133H5.541l.74-3.336h4.881l.48-2.133Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaFs;
