import type { SVGProps } from "react";
const SvgStationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M21.689 4.837C15.593 2.35 9.46 2.394 3.315 4.835L3 4.961V21h6v-2.992h7V21h6V4.964l-.311-.127ZM4 20V5.642c5.702-2.187 11.359-2.222 17-.004V20h-4v-2.99H8V20H4Zm8.5-13A3.471 3.471 0 0 0 9 10.5c0 1.956 1.544 3.5 3.5 3.5s3.5-1.544 3.5-3.5S14.456 7 12.5 7ZM8 10.5C8 7.992 9.992 6 12.5 6S17 7.992 17 10.5 15.008 15 12.5 15A4.471 4.471 0 0 1 8 10.5Zm6.792-1.59-1.9 2.4-.33.417-.395-.354-1.9-1.7.667-.746 1.504 1.346 1.57-1.983.784.62Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgStationSmall;
