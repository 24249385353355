import type { SVGProps } from "react";
const SvgRecordSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRecordSmall;
