import type { SVGProps } from "react";
const SvgBusSurroundingAreaSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.464 4.464C6.184 4.744 6 5.215 6 6v4h12V6c0-.785-.183-1.256-.464-1.536C17.256 4.184 16.785 4 16 4H8c-.785 0-1.256.183-1.536.464ZM6 16v-5h12v5H6ZM5.757 3.757C6.3 3.214 7.079 3 8 3h8c.921 0 1.7.214 2.243.757C18.786 4.3 19 5.079 19 6v2h1v2h-1v9h-3.999v-2H9v2H5v-9H4V8h1V6c0-.921.214-1.7.757-2.243ZM16 17h2v1h-1.999v-1ZM6 18v-1h2v1H6ZM16 5H8v1h8V5ZM3.778 16.439c-.503.249-.934.535-1.246.864-.314.33-.532.734-.532 1.196 0 .642.412 1.165.944 1.563.541.405 1.294.75 2.18 1.03C6.9 21.657 9.328 22 12 22c2.671 0 5.1-.343 6.877-.906.886-.281 1.638-.626 2.18-1.03.531-.399.943-.922.943-1.564 0-.462-.218-.865-.532-1.196-.312-.329-.743-.615-1.247-.863l-.442.897c.443.218.762.442.963.654.2.21.258.38.258.508 0 .18-.117.444-.543.763-.417.311-1.052.614-1.882.878-1.656.524-3.977.86-6.575.86-2.597 0-4.919-.335-6.574-.86-.831-.264-1.466-.567-1.883-.878-.426-.32-.543-.582-.543-.763 0-.128.058-.297.258-.508.201-.212.52-.436.964-.655l-.444-.897ZM8 12.999a1 1 0 1 0 .002 2.002A1 1 0 0 0 8 13Zm7 1a1 1 0 1 1 2.002.002A1 1 0 0 1 15 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBusSurroundingAreaSmall;
