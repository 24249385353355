import type { SVGProps } from "react";
const SvgFaceGrinningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.75 18c0-6.844 5.407-12.25 12.25-12.25S30.25 11.156 30.25 18c0 6.843-5.407 12.25-12.25 12.25S5.75 24.843 5.75 18ZM18 4.75C10.605 4.75 4.75 10.604 4.75 18c0 7.395 5.855 13.25 13.25 13.25S31.25 25.395 31.25 18c0-7.396-5.855-13.25-13.25-13.25Zm-4.5 7.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm8 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-12 5H10v.5c0 4.465 3.536 8 8 8s8-3.535 8-8v-.5H10.5Zm7.5 7.5c-3.744 0-6.733-2.828-6.983-6.5h13.966c-.25 3.672-3.24 6.5-6.983 6.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFaceGrinningMedium;
