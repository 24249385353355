import type { SVGProps } from "react";
const SvgMagnifyingGlassLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 17.5C3 9.492 9.492 3 17.5 3S32 9.492 32 17.5 25.508 32 17.5 32 3 25.508 3 17.5ZM17.5 2C8.94 2 2 8.94 2 17.5 2 26.06 8.94 33 17.5 33c4.1 0 7.829-1.592 10.601-4.192l3.872 3.872-.706.706-.354.354.354.353 11.32 11.32.006.007.006.006a2 2 0 0 0 2.827-2.827l-.366.341.354-.354-11.32-11.32-.354-.353-.353.353-.707.707-3.872-3.872A15.446 15.446 0 0 0 33 17.5C33 8.94 26.06 2 17.5 2ZM5 17.5C5 10.596 10.596 5 17.5 5S30 10.596 30 17.5 24.404 30 17.5 30 5 24.404 5 17.5ZM17.5 4C10.044 4 4 10.044 4 17.5S10.044 31 17.5 31 31 24.956 31 17.5 24.956 4 17.5 4Zm27.2 39.287-10.96-10.96-1.413 1.413 10.96 10.96a1 1 0 0 0 1.413-1.413Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassLarge;
