import type { SVGProps } from "react";
const SvgFreightWagonGlobeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.022 9c.065-.715.266-1.39.578-2h1.68a11.067 11.067 0 0 0-.27 2H6.023Zm1.989 1H6.022c.065.715.266 1.39.578 2h1.68a11.066 11.066 0 0 1-.27-2Zm1.297 2a9.99 9.99 0 0 1-.296-2H11v2H9.308ZM12 12v-2h1.988c-.033.7-.136 1.37-.296 2H12Zm-2.374 1H11v1.978a5.525 5.525 0 0 1-.301-.036A7.676 7.676 0 0 1 9.626 13ZM12 13v1.978a5.89 5.89 0 0 0 .301-.036A7.678 7.678 0 0 0 13.374 13H12Zm0-6v2h1.988a9.988 9.988 0 0 0-.296-2H12Zm-1 2V7H9.308a9.99 9.99 0 0 0-.296 2H11Zm2.374-3H12V4.022c.101.01.202.021.302.036.427.554.792 1.21 1.072 1.942ZM11 6V4.022c-.101.01-.202.021-.302.036A7.675 7.675 0 0 0 9.626 6H11Zm-2.437 7H7.257a5.518 5.518 0 0 0 1.968 1.509A9.355 9.355 0 0 1 8.563 13Zm.662-8.509A5.519 5.519 0 0 0 7.257 6h1.306c.181-.535.404-1.04.662-1.509ZM14.989 9a11.074 11.074 0 0 0-.268-2h1.68c.31.61.512 1.285.577 2h-1.989Zm0 1c-.03.692-.122 1.362-.269 2h1.68c.312-.61.513-1.285.578-2h-1.989Zm-.552 3a9.35 9.35 0 0 1-.662 1.509A5.517 5.517 0 0 0 15.743 13h-1.306Zm-.662-8.509c.258.468.48.974.662 1.509h1.306a5.518 5.518 0 0 0-1.968-1.509ZM11.5 3a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM8 20H6a1 1 0 1 0 2 0Zm-3 0H4v-1H3v1H2v-3h1v1h1v-1h16v1h1v-1h1v3h-1v-1h-1v1h-1a2 2 0 1 1-4 0H9a2 2 0 1 1-4 0Zm12 1a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1ZM5 18v1h14v-1H5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonGlobeSmall;
