import type { SVGProps } from "react";
const SvgLinkExternalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 5h2.293l-3.647 3.646.708.707L19 5.706V8h1V4h-4v1ZM4.5 7H4v13h13v-9h-1v8H5V8h8V7H4.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLinkExternalSmall;
