import type { SVGProps } from "react";
const SvgTicketsClassSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.5 3H10v2H4v16h10v-2h6V3h-9.5Zm3 2H11V4h8v14h-5v-4h4v-1h-4v-1h4v-1h-4V5h-.5ZM13 18.5V20H5V6h8v12.5Zm4.975-13.569v4.07h-.812V6.335h-.959v-.59c.2-.005.36-.025.487-.06a1.03 1.03 0 0 0 .344-.173c.15-.116.25-.31.302-.582h.638Zm-5.985 5.345V11H9.059c0-.221.036-.432.108-.632.07-.2.172-.373.302-.518.1-.111.217-.22.352-.325.134-.106.402-.3.802-.582.195-.136.332-.262.409-.379a.707.707 0 0 0 .118-.406.604.604 0 0 0-.158-.433.544.544 0 0 0-.413-.165c-.42 0-.635.301-.65.904h-.78v-.089c0-.494.138-.876.414-1.146.269-.264.617-.396 1.044-.396.379 0 .695.108.949.323.276.234.414.548.414.944 0 .344-.11.641-.331.89-.072.082-.14.147-.203.196-.063.049-.23.168-.498.355a7.922 7.922 0 0 0-.55.409c-.11.095-.212.203-.307.326h1.909ZM12 14H6v-1h6v1Zm-6 2h6v-1H6v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketsClassSmall;
