import type { SVGProps } from "react";
const SvgSeatWindowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 7.25a1.749 1.749 0 1 0 0 3.5 1.75 1.75 0 1 0 0-3.5ZM6.25 9a2.749 2.749 0 1 1 5.5 0 2.75 2.75 0 1 1-5.5 0Zm3.85 18.25L4.22 11.08l-.94.34 6 16.5.12.33H21v-1H10.1Zm-1.538-13.5h-.71l.24.668 3.75 10.5.118.332h9.796l3.735 7.291.89-.456-3.874-7.563-.14-.272h-9.703l-3.393-9.5h1.944l2.129 5.69.121.325h6.003v-1h-5.31l-2.128-5.69-.121-.325H8.562Zm8.185-7.5h14.5v10h-14.5v-10Zm1 8.207v.793h12.5v-2.296l-2.497-2.497-4.146 4.147-.354.353-.354-.353-2.646-2.647-2.503 2.5Zm0-1.413 2.15-2.148.353-.353.354.353 2.646 2.647 4.146-4.147.354-.353.354.353 2.143 2.144V7.25h-12.5v5.794Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSeatWindowMedium;
