import type { SVGProps } from "react";
const SvgFireplaceSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 2h20v2.207l-.146.147-.854.853V22H3V5.207l-.854-.853L2 4.207V2Zm1 1v.793l.854.853.146.147V21h2V9h11.998l.002.498L18.044 21H20V4.793l.147-.147.853-.853V3H3Zm14.044 18-.042-11H7v11h1.807a13.503 13.503 0 0 1-.556-2.967c-.128-1.926.24-4.338 2.525-5.48l.702-.351.022.784.004.15c.02.756.045 1.717.163 2.59a6.5 6.5 0 0 0 .268 1.228c.121.35.257.557.381.659l.002.001c.007.009.042.049.219-.048.197-.109.434-.327.677-.61a8.32 8.32 0 0 0 .607-.814 9.866 9.866 0 0 0 .23-.366l.012-.02.003-.005.542-.941.363 1.023L14.5 16l.471-.167v.001l.002.003.003.01.013.037.043.133a13.18 13.18 0 0 1 .478 2.05c.09.61.138 1.293.067 1.942a4.134 4.134 0 0 1-.224.991h1.691Zm-2.798 0h-4.39c-.056-.17-.127-.4-.201-.676a12.505 12.505 0 0 1-.406-2.357c-.103-1.545.154-3.1 1.277-4.06.023.612.062 1.303.15 1.954.068.498.166.993.314 1.421.146.419.359.832.693 1.105.454.371.969.256 1.335.055.357-.196.688-.524.955-.835.123-.143.239-.29.345-.432.074.307.148.662.203 1.038.082.563.12 1.153.062 1.688-.048.441-.158.813-.337 1.1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFireplaceSmall;
