import type { SVGProps } from "react";
const SvgEyeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 9.25c-3.354 0-6.334 1.69-8.605 3.559a26.25 26.25 0 0 0-4.554 4.904l-.198.282.193.285c.578.856 2.203 2.957 4.499 4.85 2.289 1.887 5.309 3.62 8.665 3.62 3.354 0 6.334-1.69 8.605-3.558a26.25 26.25 0 0 0 4.554-4.904l.198-.282-.193-.286c-.578-.855-2.203-2.956-4.499-4.85-2.289-1.887-5.308-3.62-8.665-3.62ZM9.971 22.358a24.744 24.744 0 0 1-4.108-4.354c.723-.987 2.191-2.798 4.167-4.423 2.191-1.803 4.95-3.33 7.97-3.33 3.019 0 5.816 1.566 8.03 3.391a24.739 24.739 0 0 1 4.107 4.355c-.723.986-2.191 2.797-4.167 4.423-2.191 1.802-4.95 3.33-7.97 3.33-3.018 0-5.816-1.567-8.029-3.392Zm8.03-10.108a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5ZM13.25 18a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEyeMedium;
