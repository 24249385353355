import type { SVGProps } from "react";
const SvgHandPlusCircleLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 15.5C11 9.702 15.702 5 21.5 5S32 9.702 32 15.5C32 21.3 27.298 26 21.5 26S11 21.3 11 15.5ZM21.5 4C15.15 4 10 9.15 10 15.5 10 21.852 15.15 27 21.5 27S33 21.852 33 15.5C33 9.15 27.85 4 21.5 4Zm.5 19v-7h7v-1h-7V8h-1v7h-7v1h7v7h1ZM6.312 31.11c-1.04-.832-1.003-1.908-.53-2.57.241-.337.594-.566.992-.623.39-.056.88.046 1.413.473 1.268 1.015 3.701 2.703 5.801 4.127a360.756 360.756 0 0 0 3.587 2.402l.233.153.017.011c.042.05.087.099.134.145.48.48 1.174.772 2.04.772h4v-1h-4c-.633 0-1.063-.208-1.333-.479a1.44 1.44 0 0 1-.416-1.021c0-.376.14-.746.416-1.022.27-.27.7-.478 1.334-.478h3.5c1.369 0 2.312.122 3.208.413.897.291 1.774.76 3.006 1.498L41.074 43H27.707l-1.854-1.854-.146-.146H17.69l-.25-.222-1.054-.934c-.883-.781-2.087-1.844-3.369-2.97-2.559-2.247-5.444-4.756-6.705-5.765Zm8.238.58c1.034.702 1.993 1.343 2.7 1.813V33.5c0-.624.234-1.254.709-1.729.48-.48 1.174-.771 2.04-.771h3.5c1.432 0 2.489.128 3.518.462 1.02.331 1.987.857 3.24 1.61l.029.016.026.021 12.5 10 1.113.89H29.047L27.912 44H27.5v-.5.5h-.207l-.147-.147L25.293 42H17.31l-.142-.126.332-.374-.332.374-.002-.002-.005-.004-.02-.017-.075-.068-.29-.257a1254.545 1254.545 0 0 0-4.419-3.9c-2.566-2.253-5.43-4.744-6.67-5.736-1.46-1.168-1.496-2.842-.719-3.93a2.484 2.484 0 0 1 1.664-1.033c.703-.1 1.464.11 2.18.683 1.232.985 3.63 2.65 5.738 4.08Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandPlusCircleLarge;
