import type { SVGProps } from "react";
const SvgSaUk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.228 7.526 5.648 1h-2.8l-1.6 7.473c-.82 3.925.3 6.527 4.86 6.527 3.881 0 5.561-2.15 6.362-5.68L14.331 1h-2.76l-1.522 6.98-.015.068c-.496 2.251-1.053 4.783-3.906 4.783-3.1 0-2.36-3.373-1.9-5.305Zm14.8-1.124L20.207 1h-2.8l-3 13.763h2.8l1.52-7 3.88 7h3.341l-4.5-7.493L28.51 1h-3.902l-5.54 5.402h-.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaUk;
