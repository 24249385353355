import type { SVGProps } from "react";
const SvgDoubleChevronSmallLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.323 7.74-3.95 3.904-.36.356.36.355 3.948 3.904.704-.71L8.436 12l3.59-3.548-.703-.712Zm4 0-3.95 3.904-.359.356.36.355 3.948 3.904.703-.71L12.437 12l3.59-3.548-.703-.712Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDoubleChevronSmallLeftSmall;
