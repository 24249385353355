import type { SVGProps } from "react";
const SvgReroute = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="reroute_svg__color-immutable"
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#EB0000" height={16} rx={2} width={16} />
    <path
      clipRule="evenodd"
      d="M8.68 10.655a2.729 2.729 0 0 1-.68.095 2.69 2.69 0 0 1-.791-.13A2.744 2.744 0 0 1 5.249 8c0-.236.04-.461.096-.68l1.864 1.864 1.47 1.47.03-.008v.038l-.03-.03Zm2.39.269-1.06-1.06-1.301-1.301-1.5-1.5L6.137 5.99l-1.06-1.06-2.411-2.41-1.06 1.06 2.58 2.58A4.198 4.198 0 0 0 3.75 8a4.252 4.252 0 0 0 3.459 4.17V16h1.5v-3.821c.4-.068.777-.193 1.13-.365l3.08 3.08 1.06-1.06-2.91-2.91Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="m8.68 10.655.029.029v-.038l-.03.009ZM8 5.25c.247 0 .481.043.709.104A2.746 2.746 0 0 1 10.75 8c0 .152-.019.3-.044.445l1.202 1.202A4.17 4.17 0 0 0 12.25 8c0-2.101-1.535-3.838-3.541-4.178V0h-1.5v3.83a4.134 4.134 0 0 0-.849.268l1.197 1.198c.144-.028.291-.046.443-.046Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgReroute;
