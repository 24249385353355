import type { SVGProps } from "react";
const SvgBikeProfileSignParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.75 5.25a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10.5a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V5.25Zm1-2c-1.106 0-2 .894-2 2v10.5a2 2 0 0 0 2 2h4V33h1V17.75h4a2 2 0 0 0 2-2V5.25c0-1.106-.894-2-2-2h-9Zm3 3h-.5V15h1v-3.25h1.751c.825-.002 1.537-.209 2.039-.71.502-.501.71-1.212.71-2.04 0-.833-.214-1.544-.719-2.044-.504-.5-1.214-.706-2.03-.706h-2.25Zm2.25 4.5h-1.75v-3.5H27c.66 0 1.075.166 1.327.416.251.249.423.662.423 1.334s-.168 1.085-.417 1.332c-.249.249-.662.417-1.333.418Zm-16.5 3h6.184l-.518.777-2.845 4.268 1.264 2.782A5.756 5.756 0 0 1 22.25 27a5.755 5.755 0 0 1-5.75 5.75A5.755 5.755 0 0 1 10.75 27a5.753 5.753 0 0 1 2.926-5.007l-1.247-2.742H4.85l-.929 2.552c1.96.914 3.33 2.89 3.33 5.198a5.76 5.76 0 0 1-4.117 5.513l-.285-.958A4.76 4.76 0 0 0 6.25 27c0-2.045-1.307-3.784-3.133-4.45l-.47-.172.171-.469 1.212-3.33.12-.329h8.333l2.333-3.5H10.5v-1Zm3.591 9.157a4.753 4.753 0 0 0-2.34 4.092c0 2.62 2.13 4.75 4.75 4.75 2.619 0 4.75-2.13 4.75-4.75A4.756 4.756 0 0 0 15 22.492l1.955 4.3-.91.414-1.955-4.299Zm-8.09-6.657H3v-1h3v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBikeProfileSignParkingMedium;
