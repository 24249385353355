import type { SVGProps } from "react";
const SvgBuildingTreeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M33 6.25H9v1h1.75V15h1V7.25h18.5v24.5h-18.5V27h-1v4.75h-2v-6.651a3.502 3.502 0 0 0 4.535-3.598 3.5 3.5 0 0 0-2.326-3.043c.025-.15.039-.3.04-.452V18a2.75 2.75 0 1 0-5.5 0v.006c.003.152.016.303.042.452a3.497 3.497 0 0 0-2.107 4.545 3.5 3.5 0 0 0 4.316 2.096v6.651H3v1h28.25V7.25H33v-1Zm-17.25 3h-.5v5.5h11.5v-5.5h-11Zm.5 4.5v-3.5h9.5v3.5h-9.5Zm-1 3h11.5v5.5h-11.5v-5.5Zm1 1v3.5h9.5v-3.5h-9.5Zm-.5 6.5h-.5v5.5h11.5v-5.5h-11Zm10 4.5h-9.5v-3.5h9.5v3.5ZM7.012 16.763a1.75 1.75 0 0 1 2.869 1.874l-.205.555.581.11a2.5 2.5 0 1 1-1.75 4.6l-.257-.155-.258.154a2.5 2.5 0 1 1-1.75-4.6l.582-.11-.205-.554a1.75 1.75 0 0 1 .393-1.874Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBuildingTreeMedium;
