import type { SVGProps } from "react";
const SvgSn13Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="sn-13-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.792.5h55.416c.71 0 1.292.58 1.292 1.3v16.4c0 .72-.58 1.3-1.292 1.3H1.792C1.082 19.5.5 18.92.5 18.2V1.8c0-.72.581-1.3 1.292-1.3Z"
      stroke="#FFDE15"
    />
    <path
      d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm7.404 0v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.981-.49.427-.328.715-.879.862-1.654h1.812Zm3.314 3.662c.026-.949.261-1.745.704-2.389.717-1.033 1.796-1.55 3.235-1.55 1.108 0 2.002.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.145 1.082-.436 1.503a2.1 2.1 0 0 1-1.162.847c.622.142 1.112.459 1.47.949.365.49.547 1.084.547 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.766 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.754-.744-1.132-1.748-1.132-3.014v-.15h2.223c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.195-1.02-.585-1.353-.322-.274-.818-.411-1.488-.411-.19 0-.377.01-.561.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.673-.403-1.147-.403-.544 0-.963.169-1.258.506-.295.338-.454.83-.475 1.48h-2.19Z"
      fill="#FFDE15"
    />
  </svg>
);
export default SvgSn13Negative;
