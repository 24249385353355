import type { SVGProps } from "react";
const SvgChairliftProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.198 2.98 8.1 5.8v.866A1.5 1.5 0 0 1 6.9 8.16h-.002L3.5 8.84a.5.5 0 0 0-.4.5v9.15a.5.5 0 0 0 .5.5h.5v-5h3v3h2v1h-3v-3h-1v4h3.783l.146.243 1.5 2.5-.858.514-1.354-2.257H3.6a1.5 1.5 0 0 1-1.5-1.5V9.355A1.5 1.5 0 0 1 3.3 7.86h.002L6.7 7.18a.5.5 0 0 0 .4-.5V6l-4.902.98L2.002 6l20-4 .196.98ZM5.1 11.49a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChairliftProfileSmall;
