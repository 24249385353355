import type { SVGProps } from "react";
const SvgClipboardPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.5 2H9v1H3v19h18V3h-6V2H9.5Zm.5 1.5V3h4v1h-4v-.5ZM9 4H4v17h16V4h-5v1H9V4Zm2 12v-1h3v-3h1v3h3v1h-3v3h-1v-3h-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardPlusSmall;
