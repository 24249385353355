import type { SVGProps } from "react";
const SvgIr47 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ir-47_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M9.251 4.2h4.07L8.286 15.8H4.2L9.251 4.2Zm16.818 8.166a.93.93 0 0 0 .849-.553L30.22 4.2H16.038l-5.035 11.6h4.052l3.812-8.69h6.014l-1.205 2.775-5.244-.019 4.231 5.934h4.613l-3.134-3.434h1.927Zm18.86-1.163v1.954h-1.455v2.642h-2.255v-2.642h-4.825V10.99l4.944-6.755h2.136v6.968h1.455Zm-3.662.024V6.924l-2.974 4.303h2.974ZM53.55 4.235v1.961c-.063.069-.25.282-.56.641-.433.496-.863 1.12-1.29 1.87-.427.752-.76 1.473-.997 2.164-.5 1.44-.804 3.082-.91 4.928h-2.388c.016-.87.167-1.835.455-2.895.287-1.06.684-2.12 1.19-3.18.723-1.497 1.456-2.613 2.2-3.346h-5.46V4.235h7.76Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIr47;
