import type { SVGProps } from "react";
const SvgSmartphoneShakingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.5 6.917c-2.935-.772-6.082-1.167-9.25-1.167-3.189 0-6.31.387-9.252 1.165l2.76 1.656-.515.857-3.75-2.25-.429-.257.257-.429 2.25-3.75.858.515-1.602 2.67c3.005-.787 6.184-1.177 9.423-1.177 3.22 0 6.425.398 9.424 1.179l-1.603-2.672.858-.515 2.25 3.75.257.43-.429.256-3.75 2.25-.514-.857L26.5 6.917Zm-15.75 5.83a2 2 0 0 1 2-2l9 .001a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V12.747Zm2-1a1 1 0 0 0-1 1v18.001a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-18a1 1 0 0 0-1-1l-9-.001Zm6.75.501v1H15v-1h4.5Zm-10.672-.553-4.29 1.572.172.47-.172-.47a2 2 0 0 0-1.195 2.564l.001.003 5.187 13.965.937-.348-5.185-13.963a1 1 0 0 1 .598-1.281l4.29-1.573-.343-.939Zm21.041 1.572-4.289-1.572-.344.94 4.29 1.571h.001a1 1 0 0 1 .598 1.281v.002L24.939 29.45l.938.348 5.186-13.965.002-.003a2 2 0 0 0-1.195-2.564l-.172.47.171-.47Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneShakingMedium;
