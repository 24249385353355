import type { SVGProps } from "react";
const SvgBarrierConstructionLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.5 4A1.5 1.5 0 0 0 11 5.5V9h3V5.5A1.5 1.5 0 0 0 12.5 4ZM10 5.5V9H4v9h6v8.002H4v9h6V43h5v-7.998h18V43h5v-7.998h6v-9h-6V18h6V9h-5.998V5.5a2.5 2.5 0 1 0-5 0V9H15V5.5a2.5 2.5 0 1 0-5 0ZM37 18h-3v8.002h3V18Zm-4 0v8.002H15V18h18Zm4.002-9V5.5a1.5 1.5 0 1 0-3 0V9h3ZM11 18v8.002h3V18h-3Zm-6-1v-7h9.793l-7 7H5Zm13.793-7h-2.586l-7 7h2.586l7-7Zm1.414 0h13.586l-7 7H13.207l7-7Zm17.586 0h-2.586l-7 7h2.586l7-7Zm1.414 0H43v7H32.207l7-7ZM37 35.002h-3V42h3v-6.998ZM11 42v-6.998h3V42h-3Zm.291-14.998L5 33.291v-6.29h6.291Zm-5.585 7 7-7h2.585l-7 7H5.706Zm4 0h8.585l7-7h-8.585l-7 7Zm19.585-7h-2.585l-7 7h2.585l7-7Zm1.415 0h9.585l-7 7h-9.585l7-7Zm12.294 0h-1.294l-7 7h2.585l5.71-5.71v-1.29Zm0 2.705-4.294 4.294H43v-4.294Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBarrierConstructionLarge;
