import type { SVGProps } from "react";
const SvgCloudLittleSnowSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 4V2h1v2h-1Zm-1.076 2.536c.437.292.81.69 1.066 1.18.318.609.447 1.336.323 2.14a2.066 2.066 0 0 1 1.29.901C18.438 10.362 19 9.52 19 8.5 19 7.085 17.915 6 16.5 6c-.609 0-1.153.2-1.576.536Zm3.042 5.155c.041.234.052.474.034.71a2.794 2.794 0 0 1-.825 1.787C16.66 14.69 15.927 15 15 15H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.027-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.815c.092-1.851 2.007-2.896 3.523-2.177.887-1.443 2.42-1.865 3.732-1.504A3.484 3.484 0 0 1 16.5 5C18.467 5 20 6.533 20 8.5c0 1.436-.818 2.646-2.034 3.19Zm-7.108-3.189c.635-1.44 1.949-1.774 2.997-1.394.53.192.984.566 1.25 1.073.262.5.357 1.165.116 1.977l-.2.67.7-.028c.464-.019.781.16.986.422.216.276.33.675.296 1.102-.033.423-.209.84-.525 1.148-.31.302-.786.528-1.478.528H8c-.73 0-1.199-.23-1.491-.524-.299-.3-.456-.713-.475-1.147-.019-.438.105-.863.321-1.166.21-.294.497-.463.845-.463h.564l-.067-.559c-.18-1.502 1.42-2.246 2.387-1.454l.508.417.266-.602Zm.625-4.264 1.44 1.386.694-.72-1.441-1.386-.693.72Zm9.37 9.393-1.414-1.414.708-.707 1.414 1.414-.707.707ZM23 8h-2v1h2V8Zm-3.672-3.05 1.414-1.415.707.707-1.414 1.414-.707-.707ZM10 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-1 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudLittleSnowSunSmall;
