import type { SVGProps } from "react";
const SvgDocumentPptMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.263 6.25h9.694l.146.147 7.5 7.5.147.146v15.708H9.25v-.5l.013-22.5v-.5Zm1 1-.013 21.5h15.5v-14h-7.5v-7.5h-7.987Zm8.987.708 5.793 5.793H19.25V7.958Zm-6.248 16.65v-1.273h.841c.275 0 .473.04.594.123.167.11.25.279.25.504 0 .223-.071.393-.217.507-.117.091-.309.14-.573.14h-.895Zm.85-2.104H12V27h1.002v-1.622h1.03c.263 0 .485-.024.665-.075.18-.05.34-.132.481-.247.33-.269.497-.638.497-1.107 0-.53-.201-.926-.6-1.188a1.427 1.427 0 0 0-.486-.203 3.91 3.91 0 0 0-.737-.055Zm3.374.83v1.274h.895c.264 0 .456-.048.573-.14.146-.113.218-.283.218-.506 0-.225-.084-.395-.25-.504-.122-.083-.32-.123-.595-.123h-.841Zm-1.002-.83h1.852c.312 0 .557.02.737.055.178.04.34.107.486.203.399.262.6.658.6 1.188 0 .47-.167.838-.497 1.107a1.313 1.313 0 0 1-.481.247 2.48 2.48 0 0 1-.665.075h-1.03v1.622h-1.002v-4.497Zm7.776.831v-.83h-3.785v.83h1.388v3.666h1.002v-3.666H24Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPptMedium;
