import type { SVGProps } from "react";
const SvgLinkExternalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24 7.249h4.043l-5.897 5.896.708.707 5.896-5.896v4.043h1v-5.75H24v1ZM6.75 10.75h-.5v19h19V16.5h-1v12.25h-17v-17H19.5v-1H6.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLinkExternalMedium;
