import type { SVGProps } from "react";
const SvgCircleQuestionMarkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 5.75A12.214 12.214 0 0 0 5.75 18c0 6.792 5.457 12.25 12.25 12.25S30.25 24.792 30.25 18c0-6.793-5.457-12.25-12.25-12.25ZM4.75 18c0-7.345 5.904-13.25 13.25-13.25S31.25 10.655 31.25 18c0 7.344-5.904 13.25-13.25 13.25A13.215 13.215 0 0 1 4.75 18Zm11.936 8.986h2.595v-2.498h-2.595v2.498ZM13.467 11.37c-.53.818-.822 1.971-.878 3.459h2.067c.167-2.627 1.32-3.939 3.46-3.939 1.032 0 1.811.333 2.341.997.441.545.66 1.217.66 2.018 0 .432-.076.857-.228 1.274-.183.489-.572 1.036-1.165 1.645-.92.945-1.515 1.59-1.784 1.933a3.892 3.892 0 0 0-.618 1.166c-.2.593-.307 1.502-.324 2.727h2.041c0-.69.041-1.25.12-1.681.073-.434.199-.796.379-1.088.181-.293.497-.659.955-1.1 1.034-1.009 1.686-1.7 1.958-2.077.608-.825.913-1.81.913-2.955 0-1.386-.45-2.502-1.346-3.351-.961-.921-2.254-1.381-3.878-1.381-1.01 0-1.919.204-2.728.612a4.923 4.923 0 0 0-1.945 1.741Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCircleQuestionMarkMedium;
