import type { SVGProps } from "react";
const SvgMountainSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 6a4 4 0 0 0-.814 7.918l-3.62 6.334-.428.748h19.715l-.417-.744-7-12.5-.407-.728-.45.702-2.508 3.918-.293-.342C10.92 10.899 11 10.46 11 10a4 4 0 0 0-4-4Zm2.977 4.371a3 3 0 1 0-3.26 2.616l1.849-3.235.352-.616.462.539.597.696ZM5.727 20l4.781-7.472-1.426-1.664L3.862 20h1.864Zm14.42 0H6.914l4.05-6.329.682.682.255.255.323-.161 1.677-.839.745.745.289.289.34-.225 1.274-.841L20.147 20ZM13.971 8.972l-2.455 3.837.583.582 1.677-.838.323-.162.255.255.71.712.995-.657-2.088-3.729Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMountainSunSmall;
