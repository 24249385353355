import type { SVGProps } from "react";
const SvgCloudIceSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.491 10.608a2.052 2.052 0 0 0-1.178-.752c.125-.804-.005-1.532-.323-2.14a3.276 3.276 0 0 0-1.794-1.548c-1.363-.495-3.042-.116-3.987 1.422-1.516-.719-3.431.326-3.523 2.176-.481.129-.87.432-1.144.815-.36.504-.534 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.92 0 1.65-.313 2.162-.813a2.814 2.814 0 0 0 .825-1.784c.052-.636-.105-1.293-.496-1.796Zm-6.633-2.106c.635-1.44 1.949-1.774 2.997-1.394.53.192.984.566 1.25 1.073.262.5.357 1.165.116 1.977l-.2.67.7-.028c.464-.019.778.16.98.42.213.276.325.675.29 1.102a1.815 1.815 0 0 1-.528 1.15c-.31.303-.781.528-1.463.528H8c-.73 0-1.199-.23-1.491-.524-.299-.3-.456-.713-.475-1.148-.019-.437.105-.862.321-1.165.21-.294.497-.463.845-.463h.564l-.067-.56c-.18-1.5 1.42-2.245 2.387-1.453l.508.417.266-.602ZM7 17H6v-1h1v1Zm1 0H7v1H6v1h1v-1h1v1h1v-1H8v-1Zm0 0v-1h1v1H8Zm2 4h1v1h-1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v1h-1v-1h-1v1Zm5-4h-1v-1h1v1Zm1 0h-1v1h-1v1h1v-1h1v1h1v-1h-1v-1Zm0 0v-1h1v1h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudIceSmall;
