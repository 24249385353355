import type { SVGProps } from "react";
const SvgGlobeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 12a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Zm8.5-7.5c-.438 0-.867.038-1.284.11C9.544 5.78 9.026 7.287 8.742 9h5.517c-.284-1.713-.802-3.22-1.475-4.39A7.548 7.548 0 0 0 11.5 4.5Zm-2.589.459C8.37 6.127 7.963 7.5 7.73 8.999H4.624a7.525 7.525 0 0 1 4.287-4.04Zm-1.31 5.04H4.27A7.506 7.506 0 0 0 4 12c0 .693.094 1.363.27 2H7.6a19.832 19.832 0 0 1 0-4Zm.128 5H4.624a7.525 7.525 0 0 0 4.287 4.042C8.37 17.873 7.963 16.5 7.73 15Zm1.012 0h5.518c-.284 1.713-.802 3.22-1.475 4.391a7.549 7.549 0 0 1-2.568 0C9.543 18.218 9.025 16.712 8.74 15Zm5.654-1h-5.79a18.799 18.799 0 0 1 0-4h5.79a18.816 18.816 0 0 1 0 4Zm.876 1c-.234 1.5-.64 2.874-1.182 4.042A7.525 7.525 0 0 0 18.376 15H15.27Zm3.46-1H15.4a19.86 19.86 0 0 0 0-4h3.33A7.51 7.51 0 0 1 19 12c0 .693-.094 1.363-.27 2Zm-4.642-9.04a7.525 7.525 0 0 1 4.287 4.04H15.27c-.234-1.499-.64-2.872-1.182-4.04Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgGlobeSmall;
