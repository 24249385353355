import type { SVGProps } from "react";
const SvgLocomotiveMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 5.748h15v-1H3v1ZM3 7.75h7.25V26.5h3.5V7.75h3.498c2.502-.024 4.372.387 5.839 1.22 1.471.835 2.483 2.066 3.319 3.584l.005.01.005.01 5.345 10.78.009.018.007.019c.6 1.51.85 2.783.727 3.849-.126 1.09-.637 1.928-1.472 2.538-.815.594-1.915.957-3.21 1.175-1.301.22-2.842.3-4.572.299l-9-.002h-.5V27.5h-3.5v3.75H3v-1h6.25V8.75H3v-1Zm13.75 6h6.059l.138.276 4.5 9 .362.724H16.75v-10Zm1 1v8h8.441l-4-8H17.75Zm-.495-6c2.394-.023 4.074.371 5.338 1.09 1.256.713 2.153 1.775 2.932 3.187l5.33 10.752c.565 1.427.75 2.517.655 3.346-.093.808-.454 1.396-1.068 1.845-.635.463-1.56.79-2.787.997-1.22.206-2.698.285-4.405.285l-8.5-.002V8.75h2.505Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveMedium;
