import type { SVGProps } from "react";
const SvgWheelchairReservationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 3.25h-.5v29.5h29.5V3.25h-29Zm.5 28.5V4.25h27.5v27.5H4.25Zm8.578-15.798c-2.111 1.076-3.57 3.273-3.57 5.798 0 3.573 2.927 6.5 6.5 6.5 2.591 0 4.84-1.539 5.882-3.742l-.904-.428c-.885 1.87-2.791 3.17-4.978 3.17-3.021 0-5.5-2.48-5.5-5.5 0-2.131 1.232-3.993 3.024-4.907l-.454-.89Zm2.422 4.298V12h1v7.75h7.5v8.75h-1v-7.75h-7.5v-.5ZM15.5 9a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.501 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairReservationMedium;
