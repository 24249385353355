import type { SVGProps } from "react";
const SvgDocumentPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.5 4H6v16h7v-1H7V5h5v4.996h5V15h1V9.293l-.146-.147-5-5L12.707 4H6.5Zm9.79 4.996L13 5.706v3.29h3.29ZM9 13v-1h6v1H9Zm-.002 2H15v-1H8.998v1ZM9 17v-1h6v1H9Zm8 0v2h-2.001v1H17v2h1v-2h1.999v-1H18v-2h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPlusSmall;
