import type { SVGProps } from "react";
const SvgSwitzerlandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m19.937 8.236.4.353 2.027 1.789 5.492 2.132.445.173-.152.452-.83 2.476 1.776.535.462-.876.276-.523.47.36 2.014 1.538.185.142.01.232.238 4.903.026.538-.538-.014-1.74-.044.283 2.433.084.726-.707-.185-5.095-1.33-2.184 4.631-.361.766-.496-.686-2.872-3.972-2.314 2.505-.126.136-.185.021-4.736.531-.232.026-.17-.162-3.083-2.95-1.092.635-.06.035-.068.017-3.21.816-.832.212.226-.828 1.62-5.931.031-.115.08-.088 3.848-4.267-.94-.322-.896-.309.761-.566 3.66-2.72.256-.19.28.152.934.505 5.292-1.442 1.417-1.829.326-.42Zm.138 1.455-1.193 1.54-.103.132-.161.044-5.646 1.539-.193.052-.176-.095-.83-.449-2.643 1.964.757.26.716.246-.507.562-4.275 4.74-1.362 4.989 2.31-.588 1.357-.79.326-.189.272.26 3.186 3.05 4.318-.484 2.602-2.817.415-.448.358.495 2.733 3.78 2-4.24.177-.376.401.105 4.79 1.25-.265-2.278-.066-.572.575.015 1.777.045-.2-4.134-1.36-1.039-.378.717-.192.365-.395-.12-2.664-.803-.493-.15.164-.488.843-2.511-5.131-1.992-.083-.032-.067-.06-1.694-1.495Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSwitzerlandMedium;
