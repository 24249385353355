import type { SVGProps } from "react";
const SvgWagonCouplingDraggingEquipmentLarge = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M29 8H2V7h28v19h8v-5h1v12h-1v-6h-4v7.521l5.327 4.6-.654.757-5.5-4.75-.173-.15V27h-3v8h-9v2a7.998 7.998 0 0 1-4.124 7h5.936l7.859-6.876.329-.288.33.288L39.187 44H46v1H2v-1h7.124A7.998 7.998 0 0 1 5 37v-2H2v-1h27V8Zm2 30.164L37.67 44H24.33L31 38.164ZM13 44a7 7 0 0 0 7-7v-2H6v2a7 7 0 0 0 7 7Zm29-23h1v5h3v1h-3v6h-1V21Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgWagonCouplingDraggingEquipmentLarge;
