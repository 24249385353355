import type { SVGProps } from "react";
const SvgArrowsUpDownPressureSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.997 6.992 12.5 9.5m0 0L15 7m-2.5 2.5V3M15 18l-2.5-2.5m0 0-2.503 2.508M12.5 15.5V22m7-8.5h-14v-2h14v2Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgArrowsUpDownPressureSmall;
