import type { SVGProps } from "react";
const SvgLotusLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m24.348 9.64-.354-.341-.348.347c-3.681 3.682-5.55 7.265-6.252 10.586-.703 3.32-.233 6.339.7 8.878.93 2.535 2.323 4.599 3.478 6.025.579.714 1.1 1.272 1.479 1.652a15.468 15.468 0 0 0 .575.55l.034.03.01.008.002.002.001.001L24 37l-.326.379.32.276.326-.27L24 37l.32.384.002-.001.002-.003.01-.008.035-.03.13-.113c.11-.1.269-.246.465-.436.39-.38.93-.937 1.529-1.652 1.195-1.426 2.636-3.49 3.6-6.026.964-2.54 1.451-5.562.724-8.886-.728-3.324-2.662-6.908-6.47-10.588Zm-.081 26.436a16.62 16.62 0 0 1-.261.248 20.916 20.916 0 0 1-1.656-1.819c-1.11-1.37-2.436-3.338-3.318-5.74-.88-2.398-1.317-5.224-.66-8.326.635-2.998 2.298-6.292 5.634-9.732 3.456 3.444 5.177 6.739 5.834 9.736.678 3.098.228 5.92-.683 8.317-.911 2.4-2.282 4.368-3.43 5.74a21.244 21.244 0 0 1-1.46 1.576ZM7.4 14.772a17.57 17.57 0 0 1 9.283 2.312l-.497.868a16.57 16.57 0 0 0-8.358-2.188C6.2 24.428 11.727 32.869 20.375 34.806l-.218.976c-9.313-2.086-15.212-11.279-13.23-20.614l.082-.383.391-.013Zm23.916 2.312a17.57 17.57 0 0 1 9.282-2.312l.392.013.082.383c1.981 9.335-3.917 18.528-13.23 20.614l-.219-.976c8.65-1.937 14.174-10.378 12.548-19.042a16.57 16.57 0 0 0-8.358 2.188l-.497-.868Zm-24.555 8.43a17.705 17.705 0 0 0-3.244 2.295l-.294.262.185.346a17.447 17.447 0 0 0 17.871 9.064l-.142-.99a16.447 16.447 0 0 1-16.656-8.196 16.718 16.718 0 0 1 2.763-1.906l-.483-.876Zm34.477 0a17.668 17.668 0 0 1 3.244 2.296l.293.261-.185.347a17.446 17.446 0 0 1-17.87 9.063l.142-.99a16.447 16.447 0 0 0 16.655-8.196 16.677 16.677 0 0 0-2.761-1.906l.482-.876Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLotusLarge;
