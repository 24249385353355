import type { SVGProps } from "react";
const SvgThumbUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m9.87 10.837 4.28-4.695a.501.501 0 0 1 .821.526l-1.436 3.65-.27.682H17.5a.5.5 0 1 1 0 1H16v1h1.5a.5.5 0 1 1 0 1H16v1h1.5a.5.5 0 1 1 0 1H16v1h.5a.5.5 0 0 1 0 1v.5-.5h-5c-.716 0-1.329-.122-1.758-.457-.403-.313-.739-.89-.742-2.026V11h.72l.15-.163ZM8 10.001h1.28l4.14-4.543.008-.008.008-.008a1.502 1.502 0 0 1 2.47 1.583l-.003.005L14.734 10H17.5a1.5 1.5 0 0 1 1.118 2.5c.238.266.382.616.382 1s-.144.735-.382 1a1.5 1.5 0 0 1-.723 2.448A1.5 1.5 0 0 1 16.5 19v-.5.5h-5c-.792 0-1.68-.128-2.373-.668A2.635 2.635 0 0 1 9 18.226v.784H4.999v-9.008H8Zm0 1H5.999v7.008H8v-7.008Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgThumbUpSmall;
