import type { SVGProps } from "react";
const SvgSaFl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.954 6.135 1.405-1.407c.335-.335 1.1-.653 1.574-.653h2.828v1.41L20 3.243 17.76 1v1.41h-2.827c-.918 0-2.1.49-2.75 1.141l-1.405 1.407c.479.294.882.698 1.176 1.177Zm5.807 5.79h-2.828c-.474 0-1.239-.318-1.574-.653l-1.405-1.407a3.583 3.583 0 0 1-1.176 1.177l1.405 1.407c.65.65 1.832 1.14 2.75 1.14h2.828V15L20 12.757l-2.24-2.242v1.41Zm-5.726-4.476a3.167 3.167 0 0 0-3.668-2.572 3.168 3.168 0 0 0-2.506 2.29H1v1.665h4.862a3.166 3.166 0 0 0 6.173-1.383ZM9.192 9.561a1.585 1.585 0 0 1-.55-3.123 1.585 1.585 0 0 1 .55 3.123Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaFl;
