import type { SVGProps } from "react";
const SvgLocomotiveHighSpeedLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.429 9.974c.105 0 .232.063.368.202l-.373-1.202h-4c-.474.001-.846.298-1.05.494a2.848 2.848 0 0 0-.357.425l-.007.01-.002.003h-.001v.001l.419.273.419.273.01-.015.045-.06c.042-.054.1-.123.17-.19.161-.157.289-.213.356-.214h.882l4.34 2.17-1.476 1.847H6v1h6.999c2.033 0 4.035.498 5.832 1.45l.27.143-.029.04c-1.465 2.072-.88 5.16 1.511 6.243l.051.023 4.439 1.947a13.786 13.786 0 0 0 5.539 1.162h6.255l3.173 1.681A3.688 3.688 0 0 1 42 30.94v.027H12.979v1h28.89a4.049 4.049 0 0 1-3.915 3.024H7v1h3.027c.03.281.087.555.167.818H3.998v1h6.639c.27.451.617.851 1.023 1.182H2v1h12.209a4.533 4.533 0 0 0 .582 0H25.21a4.533 4.533 0 0 0 .582 0H46v-1H28.341c.406-.33.753-.73 1.023-1.182h8.354l-.25-.673-.423-1.145h.909A5.049 5.049 0 0 0 43 30.939c0-1.736-.959-3.33-2.492-4.142L19.3 15.557a13.455 13.455 0 0 0-6.3-1.566H8.452l1.364-1.704.386-.484-.553-.276-3.105-1.553h.885Zm28.55 26.017h-6.004c-.03.281-.087.555-.167.818h6.474l-.303-.818Zm-10.735 3h.514a3.501 3.501 0 0 0 3.21-3h-6.933a3.501 3.501 0 0 0 3.21 3Zm-4.05-2.182a4.482 4.482 0 0 1-.166-.818h-2.054c-.03.281-.087.555-.167.818h2.388Zm.444 1h-3.275c-.27.451-.617.851-1.023 1.182h5.321a4.522 4.522 0 0 1-1.023-1.182Zm-3.672-1.818h-6.932a3.501 3.501 0 0 0 3.21 3h.513a3.501 3.501 0 0 0 3.209-3Zm2.026-18.935-.103.146c-1.155 1.632-.634 3.965 1.107 4.754l.04.018 4.438 1.947c1.62.71 3.37 1.078 5.138 1.078h4.368l-14.988-7.943ZM2 34.99v1h4v-1H2Zm0-20h3v-1H2v1Zm4 15.976v1H4v-1h2Zm6 0H8v1h4v-1ZM3 19.991h10.317a3 3 0 0 1 0 6H5v-1h2V20.99H3v-1Zm8.62 5H8V20.99h5.317a2 2 0 1 1 0 4H11.62Zm-9.62 1h2v-1H2v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveHighSpeedLarge;
