import type { SVGProps } from "react";
const SvgTreeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.25 3.262a8 8 0 0 0-8 8c0 .384.029.76.082 1.128-3.442.56-6.082 3.515-6.082 7.122a7.25 7.25 0 0 0 7.25 7.25h6.25v4.988H3v1h30v-1H17.75v-4.988H27a5.75 5.75 0 0 0 5.75-5.75c0-1.78-.815-3.365-2.082-4.417.37-.696.582-1.49.582-2.333a5 5 0 0 0-5-5 4.85 4.85 0 0 0-1.24.163c-.83-3.527-3.975-6.163-7.76-6.163Zm.5 22.5H27a4.75 4.75 0 0 0 4.75-4.75c0-1.605-.802-3.02-2.026-3.881l-.396-.279.266-.405a3.96 3.96 0 0 0 .656-2.185 4 4 0 0 0-4-4c-.504 0-.986.103-1.439.28l-.577.224-.098-.612c-.534-3.338-3.404-5.892-6.886-5.892a7 7 0 0 0-7 7c0 .498.055.981.156 1.454l.12.553-.565.05c-3.2.278-5.711 2.93-5.711 6.193a6.25 6.25 0 0 0 6.25 6.25h6.25V19.74l-3.562-2.85.624-.78 2.938 2.35V13.5h1v9.17l4.374-5 .752.66-5.126 5.858v1.574Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTreeMedium;
