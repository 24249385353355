import type { SVGProps } from "react";
const SvgChartColumnTrendLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M38 7.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 38 7.5ZM39.5 5a2.5 2.5 0 0 0-1.83 4.203l-7.153 10.013a2.498 2.498 0 0 0-3.08.871l-5.486-2.095a2.5 2.5 0 1 0-4.677.647L11.1 23.58a2.5 2.5 0 1 0 .625.78l6.175-4.939a2.498 2.498 0 0 0 3.662-.506l5.486 2.095a2.5 2.5 0 1 0 4.282-1.211l7.153-10.014A2.5 2.5 0 1 0 39.5 5ZM8 25.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 8 25.5Zm9 1.5h5v16h5V31h5v12h5V19h5v25H7v-9h5v8h5V27Zm14 16V32h-3v11h3Zm-13 0h3V28h-3v15Zm-7-7v7H8v-7h3Zm8.5-20a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 19.5 16Zm8.5 5.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 28 21.5ZM41 43h-3V20h3v23Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnTrendLarge;
