import type { SVGProps } from "react";
const SvgLucerneChapelBridgeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m21.5 5.667.4.533 9.006 12-.624.747-.276-.138v2.811l-.053.106L29 23.619v4.154l11.968-.772.064.998L10.3 29.982l-2.382 3.963 33.054-1.944.058.998L8.5 34.913v2.056L40.97 35l.06.998-3.028.184V39h-1v-2.757l-13.001.788V40h-1v-2.908L10 37.88V41H9v-3.06l-.97.06-.53.031v-3.06L7.03 35l-.947.056.488-.812 3.005-5 .135-.225.262-.017 4.032-.26v-5.123l-.952-1.893L13 21.62v-2.81l-.276.137-.624-.747 9-12 .4-.533Zm-6.495 23.01 1.001-.065V23.64l-1 .25v4.786Zm2.001-.13L21 28.29V23.5h-3.994v5.047ZM22 28.225l4-.258V23.5h-4v4.725Zm5-.323 1-.064V23.89l-1-.25v4.262Zm.178-5.248 1.055.264.609-1.21-1.069-.532-.595 1.478Zm-.383-1.727-.633 1.573H22v-1.97l4.795.398Zm1.211-.753 1 .498V18.31l-1-.5v2.365Zm-1-2.865v2.632L22 19.525v-2.973l4.365.436.641.321ZM21 22.5v-1.97l-4.79.397.634 1.573H21Zm0-2.974v-2.973l-4.359.435-.641.321v2.632l5-.415Zm-7-1.217 1-.5v2.365l-1 .498V18.31Zm.164 3.399 1.069-.532.595 1.478-1.055.264-.609-1.21ZM21.5 7.333l-7.33 9.773 2.106-1.053.083-.041.091-.01 5-.5.025-.002h.056l.025.003 5 .5.091.009.083.04 2.104 1.053L21.5 7.333Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLucerneChapelBridgeLarge;
