import type { SVGProps } from "react";
const SvgCloudStrongRainSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 2v2h1V2h-1Zm-.01 5.717a3.215 3.215 0 0 0-1.066-1.18A2.51 2.51 0 0 1 16.5 6C17.915 6 19 7.085 19 8.5c0 1.02-.562 1.862-1.397 2.257a2.066 2.066 0 0 0-1.29-.9c.124-.805-.005-1.532-.323-2.14ZM18 12.402a2.83 2.83 0 0 0-.034-.711C19.182 11.146 20 9.936 20 8.5 20 6.533 18.467 5 16.5 5c-1.016 0-1.924.41-2.56 1.086-1.311-.361-2.844.06-3.731 1.504-1.516-.719-3.431.326-3.523 2.177-.481.128-.87.43-1.144.814-.36.504-.534 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.927 0 1.66-.312 2.175-.813A2.794 2.794 0 0 0 18 12.402Zm-7.142-3.9c.635-1.44 1.948-1.774 2.997-1.394.53.192.984.566 1.25 1.073.261.5.357 1.165.116 1.977l-.2.67.7-.028c.464-.019.78.16.986.422.216.276.33.675.296 1.102-.033.423-.209.84-.525 1.148-.31.302-.786.528-1.478.528H8c-.73 0-1.199-.23-1.491-.524-.299-.3-.456-.713-.475-1.147-.019-.438.104-.863.321-1.166.21-.294.497-.463.845-.463h.564l-.067-.559c-.18-1.502 1.419-2.246 2.386-1.454l.51.417.265-.602Zm-3.805 9.274 1-2 .894.448-1 2-.894-.448Zm6 0 1-2 .894.448-1 2-.894-.448Zm-3-2-2 4 .894.448 2-4-.894-.448Zm.05 4 2-4 .894.448-2 4-.894-.448Zm2.82-14.152-1.44-1.386.693-.72 1.44 1.386-.692.72Zm6.516 6.593 1.414 1.414.707-.707-1.413-1.414-.708.707ZM21 8h2v1h-2V8Zm-.258-4.465L19.328 4.95l.707.707 1.414-1.414-.707-.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudStrongRainSunSmall;
