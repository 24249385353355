import type { SVGProps } from "react";
const SvgBikeProfilePowerPlugMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.75 4.75H19.5v1h4.316l-2.334 3.5H13.15l-.12.33-1.042 2.865A5.756 5.756 0 0 0 4.75 18a5.756 5.756 0 0 0 5.75 5.75A5.756 5.756 0 0 0 16.25 18a5.757 5.757 0 0 0-3.323-5.212l.923-2.538h7.578l1.247 2.743A5.753 5.753 0 0 0 19.751 18a5.755 5.755 0 0 0 5.75 5.75 5.756 5.756 0 0 0 5.75-5.75 5.756 5.756 0 0 0-7.666-5.422L22.32 9.796l2.846-4.269.518-.777h-.934ZM20.752 18c0-1.74.94-3.265 2.34-4.092l1.954 4.299.91-.414-1.955-4.3A4.756 4.756 0 0 1 30.251 18a4.756 4.756 0 0 1-4.75 4.75 4.755 4.755 0 0 1-4.75-4.75Zm-9.107-4.61-1.615 4.44.94.34 1.614-4.438A4.756 4.756 0 0 1 15.25 18a4.756 4.756 0 0 1-4.75 4.75A4.756 4.756 0 0 1 5.75 18a4.756 4.756 0 0 1 5.895-4.61Zm-.832-6.14H15v-1h-4.188v1Zm9.937 19.998V25.75h-.5l-1.503.002a3.499 3.499 0 0 0-3.462 2.998H10.5v1h4.786a3.502 3.502 0 0 0 3.464 3h2v-1.5H24v-1h-3.25v-2l3.251-.007-.002-1-3.249.006Zm-2.003-.497 1.003-.001v5h-1a2.498 2.498 0 1 1-.003-4.998Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBikeProfilePowerPlugMedium;
