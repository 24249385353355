import type { SVGProps } from "react";
const SvgHandUserMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 4.25c-2.14 0-4 2.052-4 4.75s1.86 4.75 4 4.75c2.14 0 4-2.052 4-4.75s-1.86-4.75-4-4.75ZM13 9c0-3.1 2.17-5.75 5-5.75 2.831 0 5 2.65 5 5.75 0 1.458-.48 2.816-1.282 3.846C25.944 14.71 28.25 19.195 28.25 24h-1c0-4.645-2.282-8.805-6.27-10.378-.824.705-1.85 1.128-2.98 1.128-1.131 0-2.157-.423-2.98-1.13-2.681 1.066-4.582 3.291-5.548 6.04l-.944-.332c1.003-2.853 2.961-5.243 5.754-6.482C13.48 11.816 13 10.458 13 9ZM6.488 20.18l5.759 4.022a2.85 2.85 0 0 1 .697-2.082c.475-.532 1.175-.87 2.056-.87h3.355v.006c2.324.107 4.537 1.39 6.248 3.037l.002.001 7.99 7.594.907.862H20.419l-.146-.145-1.366-1.355h-5.896l-.141-.125-8.881-7.823a2 2 0 0 1 2.499-3.123Zm11.671 2.07H15c-.607 0-1.032.225-1.312.537-.287.32-.443.762-.447 1.217-.003.455.146.893.429 1.21.274.309.702.536 1.33.536h3v1h-3c-.872 0-1.565-.319-2.04-.83l-7.057-4.93-.014-.01-.013-.01a1 1 0 0 0-1.252 1.56l.01.007.008.008 8.747 7.705h5.93l.146.145 1.366 1.355h10.167l-7.085-6.733-.002-.002c-1.652-1.59-3.707-2.724-5.752-2.765Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandUserMedium;
