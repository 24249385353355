import type { SVGProps } from "react";
const SvgDownloadLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M23 37.756V14h1v23.756l10.639-11.102.722.692-11.5 12-.361.377-.361-.377-11.5-12 .722-.692L23 37.756ZM3 45v-7H2v8h43v-8h-1v7H3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDownloadLarge;
