import type { SVGProps } from "react";
const SvgSaEp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.32 7.478h-1l.9-4.272h.88c1.2 0 2.342.3 2.342 1.725 0 1.745-1.581 2.547-3.121 2.547ZM11.92 15h2.8l1.12-5.315h1.62c3.122 0 5.902-1.666 5.902-5.054C23.362 1.14 19.82 1 17.24 1h-2.36l-2.96 14Zm.242-14H3.94L1 15h8.322l.479-2.206H4.28l.82-3.931h4.902l.44-2.207H5.56l.721-3.45h5.461L12.162 1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaEp;
