import type { SVGProps } from "react";
const SvgHandClockSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 16.5a6.5 6.5 0 1 1 0-13c1.082 0 2.103.264 3 .732M8.5 6v4.5H12m1.5 10h7s.998-2 .998-4l.002-8.003a.996.996 0 0 0-1.993-.002L19.5 13V5.5a1 1 0 0 0-2 0V13 4.494a.994.994 0 0 0-1.988-.002L15.5 13l.01-7.497A1.005 1.005 0 1 0 13.5 5.5v9.615l-2.238-2.261a.99.99 0 0 0-1.559 1.2L13.5 20.5Z"
      stroke="currentColor"
    />
  </svg>
);
export default SvgHandClockSmall;
