import type { SVGProps } from "react";
const SvgHomePowerPlugMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m13.854 5.646-.354-.354-.354.354-10.5 10.5.708.707 1.396-1.397V28.25h6.985v-6h3.5v6h7.015v-4.5H24c.672 0 1.075.166 1.319.411.245.247.415.662.431 1.345v1.529c0 .376.001 1.016.224 1.57.12.297.312.592.62.81.309.221.696.335 1.156.335.458 0 .845-.11 1.156-.327a1.77 1.77 0 0 0 .624-.804c.226-.557.222-1.207.22-1.599v-4.805a3.502 3.502 0 0 0 3-3.465v-2h-1.5V13.5h-1v3.25h-2V13.5h-1v3.25h-1.5v2a3.502 3.502 0 0 0 3 3.465v4.788c.002.426-.003.886-.147 1.24a.774.774 0 0 1-.268.36c-.113.078-.29.147-.585.147-.29 0-.465-.07-.577-.15a.803.803 0 0 1-.271-.37c-.145-.36-.151-.821-.152-1.23v-1.511c-.019-.825-.226-1.533-.722-2.033-.498-.502-1.203-.706-2.028-.706h-1.75v-7.293l1.396 1.397.707-.708-10.5-10.5Zm7.396 8.81-7.75-7.75-7.75 7.75V27.25h4.985v-6h5.5v6h5.015V14.456Zm5.5 4.293v-1h5v1a2.501 2.501 0 0 1-5 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHomePowerPlugMedium;
