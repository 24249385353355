import type { SVGProps } from "react";
const SvgSaPh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18.042 1h-2.8L12.32 15h2.801l1.28-6.137h5.642L20.763 15h2.8l2.92-14h-2.8l-1.18 5.656h-5.642L18.042 1ZM6.401 7.479h-1l.9-4.273h.881c1.2 0 2.34.301 2.34 1.725 0 1.746-1.58 2.548-3.121 2.548ZM1 15h2.801l1.12-5.315h1.621c3.12 0 5.901-1.665 5.901-5.055C12.443 1.14 8.902 1 6.321 1h-2.36L1 15Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaPh;
