import type { SVGProps } from "react";
const SvgLaptopSmartphoneLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.938 10.502v.001L7.998 31H9V10h30v7h-1v-6H10v20h19v1H5v.508C5 33.331 5.668 34 6.493 34H29v1H6.493A2.493 2.493 0 0 1 4 32.508V31h2.999l-.061-20.495v-.002A2.503 2.503 0 0 1 9.441 8h29.055A2.503 2.503 0 0 1 41 10.503V17h-1v-6.497C40 9.673 39.328 9 38.496 9H9.441c-.83 0-1.503.673-1.503 1.502ZM32.357 19c-.732 0-1.357.622-1.357 1.409V35h12V20.409c0-.787-.625-1.409-1.357-1.409h-9.286ZM43 36H31v1.591c0 .786.625 1.409 1.357 1.409h9.286c.732 0 1.357-.623 1.357-1.409V36ZM30 20.409C30 19.096 31.047 18 32.357 18h9.286C42.953 18 44 19.096 44 20.409v17.182C44 38.903 42.953 40 41.643 40h-9.286C31.047 40 30 38.903 30 37.591V20.409ZM38 38h-2v-1h2v1Zm1-17h-4v-1h4v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLaptopSmartphoneLarge;
