import type { SVGProps } from "react";
const SvgUserHeadsetMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25.06 12.25h.44c.369 0 .702.12.938.38.23.25.312.572.312.87v3c0 .352-.116.678-.364.916a1.26 1.26 0 0 1-.886.334h-3.101c-.2.426-.445.82-.73 1.175 4.578 2.39 6.58 7.31 6.58 11.825v.5H7.75v-.5c0-5.108 2.467-9.7 6.58-11.828a5.993 5.993 0 0 1-1.079-2.114v.942H10.5c-.33 0-.638-.15-.857-.359a1.24 1.24 0 0 1-.393-.891v-2.991c0-.361.166-.674.386-.89.217-.213.526-.369.864-.369h.308c1.916-8.08 12.7-7.812 14.253 0Zm-1.022 0H22.75v.941c-.644-2.26-2.468-3.941-4.75-3.941s-4.107 1.68-4.75 3.941v-.941h-1.41c1.807-6.773 10.731-6.453 12.198 0Zm-6.038-2c-2.166 0-4 2.038-4 4.75s1.834 4.75 4 4.75c1.334 0 2.542-.773 3.273-2H18v-1h3.726A5.6 5.6 0 0 0 22 15c0-2.712-1.834-4.75-4-4.75Zm0 10.5a4.55 4.55 0 0 0 2.934-1.076c4.182 2.035 6.161 6.376 6.307 10.576H8.758c.158-4.711 2.552-8.78 6.306-10.578A4.55 4.55 0 0 0 18 20.75Zm-7.75-7.241a.26.26 0 0 1 .086-.176.26.26 0 0 1 .164-.083h1.75v3.5H10.5a.27.27 0 0 1-.165-.082.242.242 0 0 1-.085-.168v-2.991Zm13.5 3.241h1.75c.119 0 .172-.035.194-.055.017-.017.056-.065.056-.195v-3c0-.125-.034-.179-.05-.197-.01-.01-.051-.053-.2-.053h-1.75v3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserHeadsetMedium;
