import type { SVGProps } from "react";
const SvgLockClosedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M18 5.75A3.25 3.25 0 0 0 14.75 9v4.75h6.5V9A3.25 3.25 0 0 0 18 5.75Zm4.25 8V9a4.25 4.25 0 0 0-8.5 0v4.75h-4.5v16h17.5v-16h-4.5ZM18.5 19.5v6h-1v-6h1Zm-8.25 9.25v-14h15.5v14h-15.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLockClosedMedium;
