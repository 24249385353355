import type { SVGProps } from "react";
const SvgAppIconMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.25 9.54a5.289 5.289 0 0 1 5.289-5.29h16.923a5.289 5.289 0 0 1 5.288 5.29v16.92a5.29 5.29 0 0 1-5.288 5.29H9.538a5.29 5.29 0 0 1-5.288-5.29V9.54Zm5.289-6.29A6.289 6.289 0 0 0 3.25 9.54v16.92a6.29 6.29 0 0 0 6.289 6.29h16.923a6.29 6.29 0 0 0 6.288-6.29V9.54a6.289 6.289 0 0 0-6.288-6.29H9.538Zm13.885 13.727L19.896 13.5h2.622L27 18l-4.482 4.5h-2.622l3.528-3.453h-4.386V22.5h-2.074v-3.453H12.6l3.53 3.453h-2.623L9 18l4.506-4.5h2.623l-3.528 3.477h4.363V13.5h2.074v3.477h4.386Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAppIconMedium;
