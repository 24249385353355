import type { SVGProps } from "react";
const SvgHandPlusCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.25 11.25a7 7 0 1 1 14.002.001A7 7 0 0 1 7.25 11.25Zm7-8a8 8 0 1 0 .002 16.001A8 8 0 0 0 14.25 3.25Zm.5 13.25v-4.75h4.75v-1h-4.75V6h-1v4.75H9v1h4.75v4.75h1Zm-2.504 7.7-5.758-4.02a2 2 0 0 0-2.499 3.123l8.88 7.823.141.125h5.898l1.365 1.355.147.145H33l-.846-.852-7.542-7.596-.008-.008c-1.756-1.69-4.041-2.999-6.431-3.044H15c-.881 0-1.582.337-2.058.87-.468.522-.697 1.206-.702 1.876 0 .068.001.137.005.205Zm-6.343-3.21 7.056 4.929c.475.512 1.169.83 2.042.83h3v-1h-3c-.63 0-1.057-.226-1.332-.535-.282-.317-.432-.755-.428-1.21.004-.456.16-.897.447-1.218.28-.312.705-.536 1.313-.536h3.158c2.044.04 4.097 1.172 5.749 2.76l6.691 6.74h-9.768l-1.365-1.355-.146-.145h-5.932l-8.746-7.705-.009-.008-.008-.007a1 1 0 0 1 1.253-1.56l.012.01.013.01Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandPlusCircleMedium;
