import type { SVGProps } from "react";
const SvgHandshakeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.74 11.561 4 11.158v15.651l.276.138 3.997 1.999 14.474 7.486c.32.206.834.413 1.409.393.62-.023 1.27-.312 1.76-1.048.365-.547.468-1.081.407-1.559l.813.44a2.36 2.36 0 0 0 3.19-.951 2.36 2.36 0 0 0 .264-1.41l1.147.62a2.359 2.359 0 0 0 3.19-.952c.238-.44.317-.92.261-1.378a2.36 2.36 0 0 0 3.335-1.756l4.22-2.343.257-.143V11.5l-.742.413-5.214 2.895-7.79-2.433-.003-.001c-1.911-.617-3.718.004-5.458 1.22-2.558-2.113-5.661-1.937-6.93-1.575l-.016.005-.015.005-6.79 2.425L4.74 11.56Zm18.229 2.654c-2.185-1.676-4.78-1.53-5.817-1.239l-6.984 2.495-.21.075-.197-.107L5 12.842v13.349l3.724 1.862.006.003 14.5 7.5.024.013.023.015c.184.122.507.253.842.241.304-.01.655-.138.965-.602.31-.465.292-.838.185-1.122a1.516 1.516 0 0 0-.546-.685l.03-.045-3.115-1.683.476-.88 5.498 2.97a1.36 1.36 0 0 0 1.834-.547 1.375 1.375 0 0 0-.545-1.847l-4.477-2.42.475-.88 4.478 2.42 2.836 1.533c.654.353 1.48.107 1.834-.547.344-.636.106-1.438-.534-1.804l-6.182-3.34.475-.88 6.153 3.324.039.021 1.557.841c.654.354 1.48.108 1.834-.546a1.36 1.36 0 0 0-.546-1.835l.366-.938.109.059c.552.298.937.788 1.117 1.344L42 25.756V14.062l1-2.561-1 2.56V13.2l-4.658 2.587-.187.103-.205-.063-8-2.5h-.005c-1.734-.562-3.46.132-5.393 1.703-1.58 1.283-3.2 3.072-4.97 5.024-.393.435-.795.878-1.205 1.326-.768.923-.64 1.854-.153 2.436.5.596 1.436.896 2.453.387h.002l6.862-3.394.232-.114.227.122 10.209 5.515-.367.94-10.09-5.452-6.628 3.277-.001.001c-1.424.712-2.863.317-3.665-.64-.813-.969-.916-2.445.159-3.728l.007-.01.008-.008c.4-.436.796-.873 1.187-1.305 1.772-1.956 3.458-3.817 5.102-5.153l.048-.039Zm14.24 12.117L42 14.063l-4.79 12.27h-.001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandshakeLarge;
