import type { SVGProps } from "react";
const SvgSaIi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.861 1h-2.92L1 15h2.921l2.94-14Zm5.56 0h-2.92L6.56 15h2.921l2.94-14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaIi;
