import type { SVGProps } from "react";
const SvgUserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.5 9c0-1.732 1.189-3 2.5-3s2.5 1.268 2.5 3-1.189 3-2.5 3-2.5-1.268-2.5-3ZM12 5C9.997 5 8.5 6.866 8.5 9c0 .929.284 1.807.77 2.503C6.473 12.902 5 16.367 5 19.5v.5h14v-.5c0-3.15-1.475-6.606-4.27-7.997.486-.696.77-1.574.77-2.503 0-2.134-1.497-4-3.5-4Zm2.016 7.274A3.178 3.178 0 0 1 12 13a3.18 3.18 0 0 1-2.016-.726C7.619 13.29 6.166 16.136 6.014 19h11.973c-.152-2.88-1.608-5.717-3.971-6.726Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgUserSmall;
