import type { SVGProps } from "react";
const SvgGlobeArrowsLeftRightUpDownMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#globe-arrows-left-right-up-down-medium_svg__a)">
      <path
        d="M23.25 18.75a6 6 0 0 1-6 6m6-6a6 6 0 0 0-6-6m6 6h-12m6 6a6 6 0 0 1-6-6m6 6c1.326 0 2.4-2.686 2.4-6m-2.4 6c-1.326 0-2.4-2.686-2.4-6s1.074-6 2.4-6m-6 6a6 6 0 0 1 6-6m0 0c1.326 0 2.4 2.686 2.4 6m0 0h-6.9M13.5 6l3.75 3.75L21 6m9 9-3.75 3.75L30 22.5m-25.5 0 3.75-3.75L4.5 15M21 31.5l-3.75-3.75-3.75 3.75"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="globe-arrows-left-right-up-down-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGlobeArrowsLeftRightUpDownMedium;
