import type { SVGProps } from "react";
const SvgHeartFilledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M30.299 11.664A7.466 7.466 0 0 0 23.25 6.75c-2.478 0-4.636 1.196-5.995 3.027C15.89 7.946 13.727 6.75 11.25 6.75a7.475 7.475 0 0 0-7.509 7.496c0 1.305.329 2.53.908 3.595.328.605.72 1.157 1.188 1.644L17.25 30.9l11.4-11.4c.27-.327.909-1.152 1.119-1.522a7.506 7.506 0 0 0 .982-3.732c0-.91-.159-1.778-.451-2.582Z"
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
    />
  </svg>
);
export default SvgHeartFilledMedium;
