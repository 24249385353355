import type { SVGProps } from "react";
const SvgBulbOffSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 12c0-2.224 1.776-4 4-4s4 1.776 4 4c0 1.475-.85 2.861-2.023 3.5h-3.94C8.833 14.764 8 13.455 8 12Zm1 3.995C7.81 15.061 7 13.62 7 12c0-2.776 2.224-5 5-5s5 2.224 5 5c0 1.6-.795 3.123-2 4.028v2.427l-.335.117-1.665.583V20h-2v-.845l-1.665-.583L9 18.455v-2.46Zm1 1.75V16.5h4v1.245l-1.665.583-.335.117-.335-.117L10 17.745Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBulbOffSmall;
