import type { SVGProps } from "react";
const SvgFreightWagonWeightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm2.732 1a2 2 0 1 0-3.465 0H6.09l-.08.402-2 10-.12.598h16.22l-.12-.598-2-10L17.91 5h-4.178ZM12 6H6.91l-1.8 9h13.78l-1.8-9H12ZM3 20v-1h1v1h1c0 1.106.894 2 2 2 1.106 0 2-.894 2-2h6c0 1.106.894 2 2 2 1.106 0 2-.894 2-2h1v-1h1v1h1v-3h-1v1h-1v-1H4v1H3v-1H2v3h1Zm3 0c0 .554.446 1 1 1s1-.446 1-1H6Zm11 1c-.554 0-1-.446-1-1h2c0 .554-.446 1-1 1ZM5 18v1h14v-1H5Zm6.8-9.89-.01.01h-1.3l-1.87 1.97V8.12H7.58v4.77h1.04v-1.54l.6-.6 1.45 2.14h1.31L9.93 10l1.86-1.88h.02l-.01-.01Zm4.57 4.77v-2.57h-2.01v.76h1.06c-.01.32-.12.58-.33.78-.21.2-.48.3-.81.3-.45 0-.8-.17-1.03-.51-.18-.28-.28-.64-.28-1.13 0-.49.1-.87.3-1.16.23-.33.56-.49.98-.49.28 0 .51.08.69.22.18.14.3.34.35.6h1.01c-.05-.42-.22-.78-.51-1.08-.39-.41-.9-.61-1.53-.61-.68 0-1.23.23-1.66.68-.46.47-.69 1.09-.69 1.84 0 .66.18 1.22.53 1.67.43.54 1 .81 1.73.81.6 0 1.08-.22 1.43-.66l.1.55h.67Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonWeightSmall;
