import type { SVGProps } from "react";
const SvgCloudRainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M24.18 14.985a2.87 2.87 0 0 1 1.867 1.078c.547.696.78 1.617.711 2.52a3.928 3.928 0 0 1-1.161 2.52c-.723.703-1.761 1.148-3.097 1.148H12c-1.375 0-2.417-.443-3.125-1.156-.701-.707-1.033-1.638-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.581 1.014-1.023 1.758-1.167.007-2.802 2.942-4.359 5.134-3.133 1.329-2.368 3.803-2.955 5.8-2.23 1.064.386 2 1.145 2.555 2.204.482.92.663 2.04.417 3.294Zm-3.313-4.558c-1.686-.612-3.8-.064-4.81 2.226l-.265.601-.509-.416c-1.627-1.334-4.282-.07-3.986 2.403l.066.56H10.8c-.616 0-1.116.303-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.887.493.496 1.265.86 2.415.86h10.5c1.102 0 1.882-.361 2.4-.864a2.929 2.929 0 0 0 .861-1.88c.053-.693-.131-1.356-.5-1.827-.36-.456-.913-.76-1.69-.73l-.7.028.2-.67c.379-1.274.233-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728Zm-10.064 16.35 1.5-3 .894.447-1.5 3-.894-.447Zm10.5-3-1.5 3 .894.447 1.5-3-.894-.447Zm-9 6 3-6 .894.447-3 6-.894-.447Zm6.075-6-3 6 .894.447 3-6-.894-.447Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainMedium;
