import type { SVGProps } from "react";
const SvgBulbOffMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.75 18A6.222 6.222 0 0 1 18 11.75 6.222 6.222 0 0 1 24.25 18c0 2.325-1.349 4.51-3.22 5.5h-6.045c-1.903-1.137-3.235-3.196-3.235-5.5Zm2 5.87c-1.784-1.356-3-3.483-3-5.87A7.222 7.222 0 0 1 18 10.75 7.222 7.222 0 0 1 25.25 18c0 2.367-1.2 4.616-3 5.915v3.59l-.335.117-2.665.933v1.195h-2.5v-1.195l-2.665-.933-.335-.117V23.87Zm1 2.925V24.5h6.5v2.295l-2.665.933-.335.117v.905h-.5v-.905l-.335-.117-2.665-.933Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBulbOffMedium;
