import type { SVGProps } from "react";
const SvgRewindMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M29.75 7.25v21.5l-.8-.6-8.2-6.15v6.728l-.793-.573-13.5-9.75L5.896 18l.561-.405 13.5-9.75.793-.573V14l8.2-6.15.8-.6ZM7.604 18l12.146 8.772V20l.8.6 8.2 6.15V9.25l-8.2 6.15-.8.6V9.228L7.604 18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRewindMedium;
