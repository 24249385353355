import type { SVGProps } from "react";
const SvgAirplaneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.932 3.249h4.686l.11.35 3.64 11.65H30c.868 0 1.572.296 2.055.82.477.519.695 1.211.695 1.93 0 .72-.218 1.412-.695 1.93-.483.525-1.187.82-2.055.82h-8.632l-3.64 11.65-.11.35h-4.684l.07-.562 1.43-11.435H9.309l-1.362 2.724-.138.276H3.742l.298-.697L6.206 18 4.04 12.946l-.298-.697h4.067l.138.276L9.31 15.25h5.125L13.002 3.811l-.07-.562Zm1.133 1 1.431 11.438.07.562H8.691l-.138-.276-1.362-2.724H5.258l1.952 4.553.084.197-.084.197-1.952 4.556h1.933l1.362-2.724.138-.276h6.875l-.07.562-1.43 11.435h2.816l3.64-11.65.11-.35H30c.632 0 1.053-.207 1.32-.497.273-.296.43-.729.43-1.253 0-.523-.157-.956-.43-1.252-.267-.29-.688-.498-1.32-.498h-9.368l-.11-.35-3.64-11.65h-2.817Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAirplaneMedium;
