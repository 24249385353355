import type { SVGProps } from "react";
const SvgRjxNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="rjx-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.093 15.802h-2.39L6.133 4.2h5.152c.886 0 1.576.091 2.07.273.493.182.891.516 1.194 1.001.304.486.456 1.074.456 1.765 0 .987-.296 1.8-.887 2.442-.59.64-1.485 1.038-2.683 1.19.306.275.594.636.863 1.085.533.907 1.126 2.19 1.78 3.846h-2.564c-.205-.654-.609-1.675-1.21-3.063-.328-.749-.676-1.253-1.045-1.511-.227-.153-.623-.23-1.187-.23h-.974l-1.005 4.804Zm1.37-6.545h1.266c1.282 0 2.133-.076 2.552-.23a2.03 2.03 0 0 0 .985-.72 1.72 1.72 0 0 0 .356-1.028c0-.423-.171-.74-.514-.95-.211-.127-.668-.19-1.37-.19H8.112l-.649 3.118ZM22.375 4.2h2.342l-1.464 7.028c-.39 1.862-.902 3.123-1.535 3.783-.633.66-1.607.989-2.92.989-1.114 0-1.947-.256-2.501-.768-.554-.511-.831-1.208-.831-2.09 0-.184.013-.39.04-.616l2.207-.238a4.432 4.432 0 0 0-.047.578c0 .37.105.652.316.847.212.195.536.293.974.293.612 0 1.042-.18 1.29-.539.184-.274.409-1.047.673-2.318L22.376 4.2Zm4.538 11.602h-3.087l5.12-5.872-2.88-5.73h2.493l1.132 2.176c.037.08.295.615.775 1.607.032.053.066.124.103.214a81.605 81.605 0 0 1 1.623-1.931L34.02 4.2h3l-5.311 6.11 2.857 5.492h-2.691l-.87-1.749c-.454-.923-.755-1.585-.903-1.986-.206.31-.736.955-1.59 1.93l-1.6 1.805Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRjxNegative;
