import type { SVGProps } from "react";
const SvgSwisspassTemporarySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.207 7.028A1.55 1.55 0 0 1 5.5 7h.464V6H5.5c-.166 0-.33.016-.487.047l.194.981ZM6.893 7h.928V6h-.928v1ZM8.75 7h.929V6H8.75v1Zm1.857 0h.929V6h-.929v1Zm1.857 0h.929V6h-.929v1Zm1.857 0h.929V6h-.929v1Zm1.858 0h.928V6h-.928v1Zm1.857 0h.464c.1 0 .199.01.293.028l.194-.98A2.506 2.506 0 0 0 18.5 6h-.464v1Zm-13.783.667c.11-.164.25-.305.414-.415l-.556-.83a2.513 2.513 0 0 0-.69.688l.832.557Zm15.08-.415c.164.11.305.251.415.415l.83-.557a2.513 2.513 0 0 0-.689-.689l-.556.831ZM4 8.5c0-.101.01-.199.029-.293l-.981-.195A2.508 2.508 0 0 0 3 8.5v.437h1V8.5Zm15.971-.293c.02.094.029.192.029.293v.437h1V8.5c0-.167-.016-.33-.048-.488l-.98.195ZM4 10.687v-.875H3v.875h1Zm16-.875v.875h1v-.875h-1ZM4 12.437v-.875H3v.875h1Zm16-.875v.875h1v-.875h-1ZM4 14.187v-.875H3v.875h1Zm16-.875v.875h1v-.875h-1ZM4 15.5v-.438H3v.438c0 .166.016.33.048.487l.98-.194A1.506 1.506 0 0 1 4 15.5Zm16-.438v.438c0 .1-.01.198-.029.293l.981.194c.032-.158.048-.32.048-.487v-.438h-1ZM4.667 16.747a1.512 1.512 0 0 1-.414-.414l-.831.556c.182.272.416.507.689.69l.556-.832Zm15.08-.414a1.51 1.51 0 0 1-.414.414l.556.831c.273-.182.507-.417.69-.689l-.832-.556ZM5.5 17c-.1 0-.199-.01-.293-.029l-.194.981c.158.031.32.048.487.048h.464v-1H5.5Zm13.293-.029A1.507 1.507 0 0 1 18.5 17h-.464v1h.464c.166 0 .33-.017.487-.048l-.194-.98ZM7.821 17h-.928v1h.928v-1Zm1.858 0H8.75v1h.929v-1Zm1.857 0h-.929v1h.929v-1Zm1.857 0h-.929v1h.929v-1Zm1.857 0h-.929v1h.929v-1Zm1.857 0h-.928v1h.928v-1ZM17 10V9h-1v1h-1v1h1v1h1v-1h1v-1h-1Zm-7 1H6v-1h4v1Zm-4 2h6v-1H6v1Zm5 2H6v-1h5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSwisspassTemporarySmall;
