import type { SVGProps } from "react";
const SvgHourglassMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.226 4.75h-.5v.5c0 4.452 3.026 8.265 6.207 10.184.778.478 1.341 1.437 1.341 2.566 0 1.128-.563 2.087-1.341 2.567-3.181 1.918-6.207 5.73-6.207 10.183v.5h20.548v-.5c0-4.453-3.027-8.265-6.208-10.183-.778-.48-1.34-1.439-1.34-2.567 0-1.13.562-2.088 1.34-2.566 3.18-1.919 6.208-5.732 6.208-10.184v-.5H8.226Zm19.034 1H8.74c.216 3.796 2.859 7.109 5.711 8.829l.004.002c1.093.671 1.82 1.965 1.82 3.419s-.727 2.747-1.82 3.42l-.004.002c-2.853 1.72-5.495 5.031-5.71 8.828H27.26c-.216-3.796-2.86-7.108-5.713-8.828l-.003-.003c-1.094-.672-1.818-1.966-1.818-3.42 0-1.453.724-2.747 1.818-3.418l.003-.002c2.853-1.72 5.497-5.033 5.713-8.83Zm-13.064 5h-1.272l.931.866a10.22 10.22 0 0 0 1.632 1.246l.003.002.008.004c.87.536 1.563 1.471 2.045 2.535l.455 1.007.456-1.007c.48-1.062 1.168-1.994 2.032-2.524a10.399 10.399 0 0 0 1.658-1.262l.934-.867h-8.882Zm1.819 1.262a8.045 8.045 0 0 1-.405-.262h4.778c-.14.097-.28.189-.422.274l-.002.002c-.816.501-1.468 1.255-1.966 2.09-.499-.836-1.152-1.59-1.968-2.094l-.006-.004-.01-.006Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHourglassMedium;
