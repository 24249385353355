import type { SVGProps } from "react";
const SvgGithubSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 19.5c.296.001.589-.053.862-.16.274-.108.523-.266.732-.465.209-.2.375-.436.487-.696.113-.26.17-.54.169-.822v-.714c-.001-.282.056-.56.169-.821a2.13 2.13 0 0 1 .487-.697c.21-.2.458-.357.732-.464a2.34 2.34 0 0 1 .862-.16H10l4-.001h1.5a2.35 2.35 0 0 1 .863.16c.273.108.522.266.73.465.21.2.376.436.488.697.113.26.17.54.169.82v.715c-.001.282.056.561.169.822.112.26.278.497.487.696A2.322 2.322 0 0 0 20 19.5m-12 1c.296.001.839-.05 1.113-.15.273-.1.522-.247.73-.433.21-.186.376-.407.488-.65.113-.244.17-.504.169-.767v-4m5.5 6c-.296.001-.839-.05-1.113-.15a2.278 2.278 0 0 1-.73-.433 1.994 1.994 0 0 1-.488-.65 1.8 1.8 0 0 1-.169-.767v-4m-3.75 0a4.5 4.5 0 0 1-4.5-4.5v-.75a4.622 4.622 0 0 1 .797-2.56 4.875 4.875 0 0 1 .328-4.19 4.875 4.875 0 0 1 4.106 2.25h3.038a4.876 4.876 0 0 1 4.106-2.25 4.874 4.874 0 0 1 .328 4.19c.513.756.79 1.647.797 2.56V10a4.5 4.5 0 0 1-4.5 4.5"
      stroke="currentColor"
    />
  </svg>
);
export default SvgGithubSmall;
