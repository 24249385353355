import type { SVGProps } from "react";
const SvgArmchairProfileUserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm4.566 6.748-2-3.5.868-.496L10.29 10H15v1H9.71l-.144-.252Zm-6.342-.906c-.22-.659-.175-1.153-.027-1.494.148-.34.419-.575.747-.688.653-.225 1.588.022 2.108 1.064L6.5 8.5l-.446.224v.001l.002.004.009.018.033.066.127.254.454.907 1.375 2.75.138.276H15.5c.347 0 .84.11 1.166.383a.963.963 0 0 1 .323.508c.057.219.062.527-.065.956l-.741 2.146L15.143 20H6.85a995.948 995.948 0 0 1-1.379-3.795c-.813-2.25-1.748-4.87-2.246-6.363Zm2.806 10.83.47-.172-.47.172.12.328h9.706l.117-.337.91-2.637 3.786 3.349.662-.75-4.094-3.622.636-1.84.003-.01.003-.01c.17-.565.191-1.067.078-1.503a1.962 1.962 0 0 0-.648-1.023C16.743 12.141 15.987 12 15.5 12H8.809L7.572 9.526l-.453-.906-.127-.254-.034-.067-.008-.017-.002-.004v-.001h-.001c-.729-1.459-2.169-1.962-3.329-1.562-.577.199-1.072.622-1.338 1.234s-.285 1.368-.004 2.21c.502 1.506 1.442 4.137 2.254 6.386a1022.973 1022.973 0 0 0 1.38 3.799l.09.243.023.063.006.017.001.004v.001Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArmchairProfileUserSmall;
