import type { SVGProps } from "react";
const SvgTicketHeartMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.25 6.25h29.5v7h-.5c-2.274 0-4 1.726-4 4 0 2.273 1.726 4 4 4h.5v7H3.25v-22Zm1 1v20h27.5v-5.023c-2.578-.24-4.5-2.32-4.5-4.977 0-2.658 1.922-4.738 4.5-4.977V7.25H4.25Zm3.464 7.635c0-2.256 1.677-4.134 3.847-4.134 1.056 0 1.992.455 2.675 1.173.682-.718 1.618-1.173 2.674-1.173 1.692 0 3.089 1.15 3.615 2.723.148.442.227.917.227 1.411 0 .74-.179 1.438-.493 2.042l-.002.003a8.645 8.645 0 0 1-.542.805l-.015.02-.016.017-5.08 5.529-.369.4-.368-.4-5.088-5.537-.007-.008a4.211 4.211 0 0 1-.6-.903 4.403 4.403 0 0 1-.458-1.968Zm3.847-3.134c-1.542 0-2.847 1.352-2.847 3.134 0 .555.129 1.073.352 1.521.129.257.279.487.453.686l4.716 5.132 4.696-5.11c.126-.17.37-.517.441-.651.24-.461.38-1 .38-1.578 0-.387-.062-.755-.175-1.094-.408-1.217-1.459-2.04-2.667-2.04-.92 0-1.736.48-2.26 1.248l-.413.605-.413-.604c-.526-.769-1.344-1.25-2.263-1.25ZM24.25 7.5v.487h1V7.5h-1Zm0 1.462v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95V27h1v-.488h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketHeartMedium;
