import type { SVGProps } from "react";
const SvgSignExclamationPointSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.5 1.877.447.9 8.7 17.5.36.723H1.993l.359-.723 8.7-17.5.448-.9ZM3.607 20h15.786L11.5 4.123 3.607 20ZM11 18v-2h1v2h-1Zm1-4V8h-1v6h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSignExclamationPointSmall;
