import type { SVGProps } from "react";
const SvgShuttleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.019 5.019c-.486.486-.769 1.27-.769 2.481v6.25h21.5V7.5c0-1.211-.282-1.995-.769-2.481-.486-.486-1.27-.769-2.481-.769h-15c-1.211 0-1.995.283-2.481.769ZM7.25 21.25v-6.5h21.5v6.5H7.25Zm.062-16.938C8.06 3.563 9.152 3.25 10.5 3.25h15c1.348 0 2.44.313 3.188 1.062.749.749 1.062 1.84 1.062 3.188v3.25h1.5v2.5h-1.5v12h-5.498v-3H11.75v3h-5.5v-12h-1.5v-2.5h1.5V7.5c0-1.348.313-2.44 1.062-3.188ZM29.75 12.25h.5v-.5h-.5v.5Zm-4.498 10h3.498v2h-3.498v-2Zm-18.002 0h3.5v2h-3.5v-2Zm-1-10.5v.5h-.5v-.5h.5Zm19.25-5.5h-15v1h15v-1Zm-15 10.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM10.25 18a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm14 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM8.353 30.25l1.963-1.92-.7-.715-2.84 2.778-.367.359.368.357 2.844 2.763.696-.717-1.961-1.906H15v-1H8.353Zm19.296.986-1.964 1.92.7.715 2.84-2.778.367-.359-.368-.357-2.845-2.763-.697.717 1.962 1.905H21v1h6.649Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShuttleMedium;
