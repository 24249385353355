import type { SVGProps } from "react";
const SvgLocationPinBSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 4.008a6 6 0 0 0-6 6v.02c.041 1.039.35 1.913.837 2.723l.007.01 4.75 7.499.422.666.422-.666 4.75-7.5.007-.01.005-.01c.473-.827.791-1.72.8-2.728v-.004a6 6 0 0 0-6-6Zm-4.308 8.223c-.41-.684-.657-1.392-.692-2.233a5 5 0 0 1 10 .008c-.007.787-.253 1.508-.663 2.23l-4.321 6.822-4.324-6.827Zm3.14.972v-2.466h1.527c.57 0 .944.038 1.122.115.457.191.686.571.686 1.14 0 .504-.17.852-.509 1.043-.199.112-.586.168-1.161.168h-1.665Zm0-3.213V7.811h1.583c.446 0 .745.03.897.091a.837.837 0 0 1 .423.37c.101.171.152.38.152.621 0 .243-.059.455-.175.637a.863.863 0 0 1-.468.37c-.196.06-.499.09-.911.09h-1.501Zm1.704-2.983H9.999v7.001h2.93c.474 0 .857-.085 1.147-.254.618-.365.928-.963.928-1.796 0-.635-.222-1.119-.664-1.45a1.72 1.72 0 0 0-.548-.255c.29-.124.518-.319.684-.584.165-.265.248-.568.248-.91 0-.322-.076-.62-.227-.893a1.562 1.562 0 0 0-.615-.625c-.281-.156-.731-.234-1.346-.234Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinBSmall;
