import type { SVGProps } from "react";
const SvgCigaretteDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M25.25 6.703c0-2.205 1.795-4 4-4s4 1.795 4 4c0 1.208-.562 2.35-1.505 3.107l-.283.227.128.34c.105.277.16.572.16.873 0 1.378-1.122 2.5-2.5 2.5a2.504 2.504 0 0 1-2.5-2.5c0-.3.055-.595.16-.874l.128-.34-.283-.226a3.995 3.995 0 0 1-1.505-3.107Zm4-5c-2.758 0-5 2.243-5 5 0 1.402.601 2.725 1.615 3.66a3.504 3.504 0 0 0 3.385 4.387 3.504 3.504 0 0 0 3.385-4.387 4.988 4.988 0 0 0 1.615-3.66c0-2.757-2.242-5-5-5Zm4.146 32.4L21.543 22.25H3.25v-5.5h12.793L1.896 2.604l.708-.708 31.5 31.5-.708.708ZM17.043 17.75l3.5 3.5H14.75v-3.5h2.293Zm-5.293 0h2v3.5h-2v-3.5Zm-1 3.5v-3.5h-6.5v3.5h6.5Zm17.625-3.5H21v-1h12.125v5.5H25.5v-1h2.875v-3.5Zm1 0v3.5h2.75v-3.5h-2.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCigaretteDisabledMedium;
