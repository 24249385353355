import type { SVGProps } from "react";
const SvgLocationPinMapSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m13.446 4.003.292.157 5 2.7.262.142V9h-1V7.598l-4-2.16v7.772l.674.758-.748.664-.484-.545-4.63 3.704-.298.238-.309-.225-5-3.65-.205-.15v-9.98l.792.57 4.692 3.379 4.704-3.763.258-.207ZM13 13.16V5.64l-4 3.2v7.52l4-3.2ZM4 5.976l4 2.88v7.56l-4-2.92v-7.52Zm11.004 7.213C15.004 11.463 16.312 10 18 10c1.69 0 2.997 1.462 2.997 3.188v.004c-.005.54-.165 1.014-.392 1.444l-.005.01-.006.01-2.155 3.665-.43.733-.432-.733-2.157-3.665-.006-.01a3.11 3.11 0 0 1-.41-1.441v-.017Zm1-.01c.014.366.113.674.281.976l1.723 2.928 1.718-2.923c.171-.326.268-.638.271-.974-.001-1.243-.929-2.185-1.997-2.185-1.065 0-1.991.939-1.996 2.179ZM18 12c-.55 0-1 .451-1 1 0 .556.462 1 1.005 1A1 1 0 0 0 18 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinMapSmall;
