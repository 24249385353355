import type { SVGProps } from "react";
const SvgFreightWagonContainerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.5 6H5v10h14V6H5.5Zm.5 5V7h12v8H6v-4Zm3 9h6a2 2 0 1 0 4 0h1v-1h1v1h1v-3h-1v1h-1v-1H4v1H3v-1H2v3h1v-1h1v1h1a2 2 0 1 0 4 0Zm-1 0H6a1 1 0 1 0 2 0Zm9 1a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1ZM5 18v1h14v-1H5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonContainerSmall;
