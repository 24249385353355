import type { SVGProps } from "react";
const SvgDoorArrowRightLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 12 5.5 9.5m0 0L8 7M5.5 9.5h10m-5 1.5v8.5h7v-15h-7V8M21 19.5H3m12.5-10L13 7m2.5 2.5L13 12m3 .5h-1"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgDoorArrowRightLeftSmall;
