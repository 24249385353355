import type { SVGProps } from "react";
const SvgClipboardTickSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9 2h6v1h6v19H3V3h6V2Zm1 1v1h4V3h-4ZM4 4h5v1h6V4h5v17H4V4Zm6.399 13.552 7-9.25-.798-.604-6.687 8.838-3.599-2.924-.63.776 4 3.25.401.326.313-.412Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgClipboardTickSmall;
