import type { SVGProps } from "react";
const SvgDisplayGearsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 7.75h-.5v17.5h13.5v5H10.5v1h15v-1h-6.25v-5h13.5V7.75h-29Zm14 17.5h.5v5h-.5v-5Zm3.45-1h4.09c.295-.776.46-1.617.46-2.5a6.973 6.973 0 0 0-3.07-5.806L20.915 19a3.494 3.494 0 0 1 1.335 2.75 3.49 3.49 0 0 1-1.05 2.5Zm5.15 0h5.4V8.75H4.25v15.5h6.9a7.997 7.997 0 0 1-.385-2H7.5v-1h3.265a7.993 7.993 0 0 1 .812-3.064l-2.82-1.628.5-.866 2.819 1.627a7.978 7.978 0 0 1 2.243-2.243l-1.627-2.819.866-.5 1.628 2.82a7.993 7.993 0 0 1 3.064-.812V10.5h1v3.265a7.993 7.993 0 0 1 3.064.812l1.628-2.82.866.5-1.627 2.819a7.978 7.978 0 0 1 2.243 2.243l2.819-1.627.5.866-2.82 1.628c.46.931.745 1.967.812 3.064H30v1h-3.265a7.997 7.997 0 0 1-.385 2Zm-14.14 0h4.09a3.49 3.49 0 0 1-1.015-2h-3.517a7.02 7.02 0 0 0 .443 2Zm6.54 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0-6a3.501 3.501 0 0 0-3.465 3h-3.518a6.97 6.97 0 0 1 6.983-6.5c1.1 0 2.14.25 3.063.695L20.05 18.5a3.49 3.49 0 0 0-1.3-.249Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDisplayGearsMedium;
