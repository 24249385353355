import { forwardRef, LegacyRef, MouseEventHandler } from "react";

type Variant = "alternative" | "ghost" | "primary" | "secondary";

export declare interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  href?: string;
  icon?: boolean;
  onClick?: MouseEventHandler;
  target?: string;
  title?: string;
  type?: "button" | "reset" | "submit";
  variant?: Variant;
}

const variantClassName = {
  alternative: "bg-granite hover:bg-iron text-white ",
  ghost: "border-storm text-granite hover:border-iron border ",
  primary: "bg-red hover:bg-red125 text-white ",
  secondary: "bg-silver text-iron hover:bg-platinum ",
};

function getClassName(
  variant: Variant = "primary",
  disabled: boolean,
  icon: boolean,
) {
  return `block text-center rounded-sm py-1 ${variantClassName[variant]} ${disabled ? "pointer-events-none bg-opacity-40 text-opacity-50" : ""} ${icon ? "px-1" : "px-4 min-w-[60px]"}`;
}

function Button(
  {
    children,
    disabled = false,
    href,
    icon = false,
    onClick,
    title = "",
    type = "button",
    variant,
    ...props
  }: Props,
  ref: LegacyRef<HTMLAnchorElement> | undefined,
) {
  if (href) {
    return (
      <a
        className={getClassName(variant, disabled, icon)}
        href={href}
        onClick={onClick}
        ref={ref}
        title={title}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={getClassName(variant, disabled, icon)}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}

export default forwardRef(Button);
