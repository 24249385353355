import type { SVGProps } from "react";
const SvgCalendarOneDaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 6.01V5H7v1.01H4v11.969l.499.001 15 .03.501.001V6.01h-3V5h-1v1.01H8ZM16 8v-.99H8V8H7v-.99H5v2.01h14V7.01h-2V8h-1ZM5 16.98v-6.96h14v6.989l-14-.028Zm8.175-.98v-5h-.783c-.064.334-.188.572-.373.713a1.254 1.254 0 0 1-.422.213 2.551 2.551 0 0 1-.597.075v.725h1.176V16h.999Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCalendarOneDaySmall;
