import type { SVGProps } from "react";
const SvgFlashlightOnSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m9.416 4.723-2-3-.832.555 2 3 .832-.555Zm7-2.445-2 3-.832-.555 2-3 .832.555ZM11 2v3h1V2h-1ZM7.5 6H7v3.096l.036.09.967 2.418V22H15V11.597l.964-2.411.036-.09V6H7.5Zm1.503 6H14v9H9.003v-9Zm5.159-1 .6-1.5H8.239l.6 1.5h5.323ZM8 8.5h7V7H8v1.5Zm2 4.5h3v7h-3v-7Zm1 3v3h1v-3h-1Zm0-1h1v-1h-1v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFlashlightOnSmall;
