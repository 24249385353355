import type { SVGProps } from "react";
const SvgCashRegisterSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 2h10.309l-.362.724L10 6.618v1.395A9.5 9.5 0 0 1 18.987 17H22v5H2V2Zm1 5h6v2h.5a8.5 8.5 0 0 1 8.485 8H16v1h5v3H3v-3h6v-1H3V7Zm6.191-1H3V3h7.691l-1.5 3ZM12.5 17a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-.647-.854a1.5 1.5 0 1 1-.707.707L8.88 14.586a2.5 2.5 0 1 1 .707-.707l2.267 2.267ZM6 12.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 6 12.5Zm13.538 1.879-.342-.94.94-.342.342.94-.94.342Zm-1.195-2.562.5.866.866-.5-.5-.866-.866.5Zm-.477-.683-.643-.766.765-.643.643.766-.765.643Zm-2-1.999.767.643.643-.766-.767-.643-.643.766Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCashRegisterSmall;
