import type { SVGProps } from "react";
const SvgSaVr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.42 1H1v5.01h1.1V4.04h.98c.35 0 .6.06.74.2.151.13.231.38.261.73.03.4.05.65.07.76.01.11.04.2.08.27h1.14l-.05-.06c-.07-.09-.12-.3-.15-.65l-.01-.095c-.044-.425-.072-.694-.1-.815-.03-.13-.08-.26-.16-.38a.848.848 0 0 0-.52-.37c.26-.09.45-.24.59-.46.14-.21.21-.47.21-.76 0-.25-.05-.49-.17-.69-.12-.2-.28-.37-.49-.49-.25-.15-.62-.23-1.1-.23ZM2.09 3.23V1.86h1.09c.32 0 .55.05.701.16.15.11.22.27.22.52s-.09.43-.26.55c-.14.09-.38.14-.71.14H2.09ZM23.558 5c.67-.31 1.41-.5 2.2-.5h-.01A5.257 5.257 0 0 1 31 9.75c0 2.9-2.36 5.25-5.252 5.25a5.257 5.257 0 0 1-5.252-5.25c0-1.56.71-2.96 1.801-3.92l-.32-.49-3.591 5.16h-2.971c-.36 2.54-2.531 4.5-5.172 4.5a5.257 5.257 0 0 1-5.252-5.25c0-2.9 2.361-5.25 5.252-5.25 1 0 1.92.29 2.721.78l1.16-1.4-.71-1.38h-1.41V1h4.001v1.5h-.9l.39.75h5.762V1h3.751v1.5h-2.25v1.28l.8 1.22Zm-8.133 4h1.34l-1.89-3.67-.75.91c.69.75 1.15 1.7 1.3 2.76Zm-1.49 0c-.12-.6-.4-1.13-.771-1.59L11.854 9h2.08Zm-7.433.75c0 2.07 1.68 3.75 3.751 3.75h.01c1.81 0 3.321-1.29 3.671-3H8.663l3.34-4.05a3.7 3.7 0 0 0-1.75-.45 3.752 3.752 0 0 0-3.751 3.75Zm9.773-5 1.83 3.55 2.471-3.55h-4.3Zm5.732 5c0 2.07 1.68 3.75 3.751 3.75h.01c2.07 0 3.752-1.68 3.752-3.75 0-2.07-1.681-3.75-3.752-3.75-.48 0-.94.1-1.37.27l2 3.07-1.26.82-2.01-3.07a3.725 3.725 0 0 0-1.121 2.66Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaVr;
