import type { SVGProps } from "react";
const SvgBulbOnMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.5 6v2.25h1V6h-1Zm5.979 1.337-1.053 1.93.878.48 1.053-1.931-.878-.48ZM18 11.75A6.222 6.222 0 0 0 11.75 18c0 2.304 1.332 4.363 3.235 5.5h6.044c1.872-.99 3.221-3.175 3.221-5.5A6.222 6.222 0 0 0 18 11.75ZM10.75 18c0 2.387 1.216 4.514 3 5.87v3.635l.335.117 2.665.933v1.195h2.5v-1.195l2.665-.933.335-.117v-3.59c1.8-1.299 3-3.548 3-5.915A7.222 7.222 0 0 0 18 10.75 7.222 7.222 0 0 0 10.75 18Zm4 6.5v2.295l2.665.933.335.117v.905h.5v-.905l.335-.117 2.665-.933V24.5h-6.5ZM26.1 12.55l1.924-1.062.483.876-1.924 1.062-.483-.876Zm1.649 5.95 2.25.006.002-1-2.25-.006-.002 1Zm.296 6.01-1.948-1.04.47-.882 1.949 1.04-.47.881ZM7.82 24.484l1.965-1.17-.512-.859-1.965 1.17.512.86Zm.43-5.985H6v-1h2.25v1Zm-.856-6.095 1.922 1.236.54-.84-1.92-1.237-.542.841Zm5.302-2.65-1.063-1.933.876-.482 1.064 1.933-.877.482Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBulbOnMedium;
