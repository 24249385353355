import type { SVGProps } from "react";
const SvgChevronSmallDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.639 21.801-5.856-5.925.711-.703 5.5 5.565 5.5-5.562.712.703-5.856 5.922-.356.36-.355-.36Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallDownMedium;
