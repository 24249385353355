import type { SVGProps } from "react";
const SvgSunMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m9.47 6.57.586-1.612-1.36 1.045C6.855 7.416 6.028 9.21 5.98 10.994c-.047 1.77.672 3.49 1.85 4.78 1.178 1.293 2.837 2.18 4.694 2.249 1.867.07 3.873-.688 5.731-2.572l1.48-1.502-1.997.677c-1.036.352-2.028.428-2.947.291A4.21 4.21 0 0 0 16 12c0-2.176-1.822-4-4-4a4.016 4.016 0 0 0-2.76 1.11c-.124-.874-.057-1.746.23-2.54Zm.056 3.729a7.317 7.317 0 0 0 1.483 2.516 6.623 6.623 0 0 0 2.591 1.794c.835-.545 1.4-1.562 1.4-2.609 0-1.624-1.376-3-3-3a3.044 3.044 0 0 0-2.474 1.299Zm.74 3.185c1.412 1.57 3.458 2.625 5.807 2.486-1.21.794-2.41 1.095-3.51 1.054-1.556-.059-2.972-.803-3.994-1.924-1.022-1.121-1.628-2.596-1.588-4.079.029-1.07.393-2.165 1.21-3.159-.129 1.99.684 4.076 2.075 5.622Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunMoonSmall;
