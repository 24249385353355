import type { SVGProps } from "react";
const SvgNewspaperSbbSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.5 4H3v13.5C3 18.876 4.124 20 5.5 20H8v-1H5.5c-.824 0-1.5-.676-1.5-1.5V5h14v10h1V4H3.5ZM5 7h12V6H5v1Zm.5 1H5v4h5V8H5.5Zm.5 3V9h3v2H6Zm5 0h6v-1h-6v1Zm6-2h-6V8h6v1Zm0 5h-6v-1h6v1ZM5 14h5v-1H5v1Zm5 2H5v-1h5v1Zm-5 2h4v-1H5v1Zm12.2-2 2.4 2.25h-2.85V16h-1.5v2.25H12.4L14.8 16H13l-3 3 3 3h1.8l-2.4-2.25h2.85V22h1.5v-2.25h2.85L17.2 22H19l3-3-3-3h-1.8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgNewspaperSbbSmall;
