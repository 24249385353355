import type { SVGProps } from "react";
const SvgEyeDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m23.213 25.42 4.933 4.934.707-.707-22.5-22.5-.707.707 4.423 4.422c-2.634 1.994-4.514 4.421-5.228 5.436l-.199.282.193.286c.579.855 2.204 2.956 4.5 4.85 2.288 1.887 5.308 3.62 8.665 3.62 1.88 0 3.636-.536 5.213-1.33Zm-.748-.747-1.664-1.664a5.467 5.467 0 0 1-2.801.74 5.749 5.749 0 0 1-5.75-5.748c0-1.053.243-1.97.739-2.805l-2.206-2.206c-2.354 1.756-4.106 3.903-4.92 5.014a24.784 24.784 0 0 0 4.107 4.354c2.214 1.825 5.011 3.392 8.03 3.392 1.574 0 3.075-.419 4.465-1.077Zm-2.401-2.401-6.34-6.34c-.317.614-.474 1.286-.474 2.07A4.749 4.749 0 0 0 18 22.75a4.49 4.49 0 0 0 2.064-.479Zm-3.47-9.83c.438-.11.906-.191 1.406-.191a5.75 5.75 0 0 1 5.75 5.75c0 .499-.083.968-.192 1.405l-.97-.242c.097-.391.162-.774.162-1.163a4.75 4.75 0 0 0-4.75-4.75c-.392 0-.775.063-1.163.16l-.243-.97Zm-2.46-2.451c1.21-.454 2.504-.74 3.866-.74 3.357 0 6.377 1.733 8.666 3.62 2.295 1.893 3.92 3.994 4.498 4.85l.194.285-.199.282a26.46 26.46 0 0 1-4.35 4.729l-.646-.765a25.651 25.651 0 0 0 3.974-4.255 24.748 24.748 0 0 0-4.108-4.354C23.816 11.818 21.02 10.25 18 10.25c-1.22 0-2.395.256-3.516.676l-.35-.936Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEyeDisabledMedium;
