import type { SVGProps } from "react";
const SvgDeskAdjustableSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m18.854 3.346-.354-.353-.354.353-2 2 .708.707L18 4.907V9h1V4.907l1.146 1.146.708-.707-2-2Zm-7 2.6L13 7.093V3h1v4.093l1.146-1.147.708.707-2 2-.354.354-.354-.354-2-2 .708-.707ZM3 10h18v11h-1v-8H4v8H3V10Zm1 2h16v-1H4v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDeskAdjustableSmall;
