import type { SVGProps } from "react";
const SvgSaAbteilkinderwagen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.21 1c1.44.83 2.47 2.29 2.75 4H9.9l2.31-4ZM1.53 4h3.5v1h-3.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5Zm13.5 2c-.17 1.61-1.01 2.82-2 4h-8c-.99-1.18-1.82-2.39-2-4h12Zm-9 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm0-3c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Zm4 1c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2Zm1 0c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaAbteilkinderwagen;
