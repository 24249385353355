import type { SVGProps } from "react";
const SvgNextSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.772 5.08 4 4.58v14.84l.772-.502 5-3.25 5-3.25.646-.419-.645-.42-5-3.25-5-3.25ZM5 12V6.421L9.228 9.17 13.582 12l-4.354 2.83L5 17.58V12Zm11.501-7h-.5v14h4V5h-3.5Zm2.5 13h-2V6h2v12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgNextSmall;
