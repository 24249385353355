import type { SVGProps } from "react";
const SvgArchiveBoxSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m14.5 2 .39.488L16.34 4.3H21v12.667l-.1.133-3 4-.15.2H3V8.634l.1-.134 3-4 .15-.2h4.21l.65-.812L11.5 3l.39.488.71.887 1.509-1.887.39-.488Zm3.858 4.823L17.14 5.3h2.36l-1.142 1.523Zm-.62.826-3.239-4.048-1.26 1.574L15.74 8.3h1.51l.488-.65ZM17 9.3H4v11h13v-11Zm1 10 2-2.666V6.3l-2 2.667v10.334Zm-3.54-11L11.5 4.6 8.54 8.3h5.92Zm-7.2 0 2.4-3H6.75l-2.25 3h2.76Zm.74 4h5v-1H8v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArchiveBoxSmall;
