import type { SVGProps } from "react";
const SvgPulseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 20.25h6L13.5 9 18 27l4.5-12 1.5 5.25h6"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgPulseMedium;
