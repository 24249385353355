import type { SVGProps } from "react";
const SvgSaYt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={27}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m1 1 3.28 8.203L3.06 15h2.801l1.22-5.796L13.843 1h-3.261L6.041 6.877 3.961 1H1Zm12.88 2.206.479-2.205h10.783l-.46 2.205H20.74L18.2 15h-2.92l2.54-11.794h-3.94Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaYt;
