import type { SVGProps } from "react";
const SvgFreightWagonSwitzerlandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m20.115 6.366-.388-.323-.32.39-1.255 1.533-4.71 1.215-.824-.422-.268-.137-.247.173-3.275 2.3-.82.576.953.309.725.235-3.348 3.512-.085.089-.034.117-1.45 5.02-.238.825.836-.2 2.872-.691.065-.016.06-.033.955-.527 2.738 2.48.166.15.222-.024 4.237-.45.179-.02.125-.128 2.039-2.085 2.54 3.32.486.637.358-.718 1.932-3.879 4.528 1.12.704.174-.087-.72-.24-1.968 1.492.035.539.014-.028-.539-.211-4.15-.013-.239-.194-.14-1.802-1.302-.456-.328-.273.49-.393.704-1.49-.426.712-2.006.165-.466-.464-.17-4.907-1.802-1.808-1.509Zm-1.293 2.36 1.04-1.269 1.487 1.241.066.056.082.03 4.524 1.66-.725 2.042-.178.502.512.146 2.383.68.38.11.194-.346.312-.56 1.152.833.172 3.372-1.531-.037-.579-.014.07.574.222 1.823-4.213-1.042-.39-.096-.178.359-1.753 3.52-2.403-3.143-.35-.458-.404.413-2.317 2.369-3.835.408-2.837-2.569-.264-.24-.313.173-1.209.667-1.97.474 1.177-4.076 3.812-3.998.549-.575-.756-.245-.529-.172 2.21-1.552.723.371.17.087.183-.048 5.052-1.302.159-.041.103-.127ZM4.25 28.25V30h-1v-4.5h1v1.75h2v-1.5h23.5v1.5h2V25.5h1V30h-1v-1.75h-2v1.5h-1.5V30a2.75 2.75 0 0 1-5.5 0v-.25h-9.5V30a2.75 2.75 0 0 1-5.5 0v-.25h-1.5v-1.5h-2Zm4.5 1.5h3.5V30a1.75 1.75 0 1 1-3.5 0v-.25Zm18.5 0h-3.5V30a1.75 1.75 0 1 0 3.5 0v-.25Zm-20-2v-1h21.5v2H7.25v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonSwitzerlandMedium;
