import type { SVGProps } from "react";
const SvgButtonPowerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.5 7.5V15h1V7.5h-1Zm4.722 4.005a7.752 7.752 0 0 1 3.528 6.494c0 4.274-3.478 7.75-7.75 7.75s-7.75-3.476-7.75-7.75c0-2.71 1.403-5.094 3.524-6.481l-.548-.837c-2.388 1.562-3.976 4.254-3.976 7.318 0 4.826 3.926 8.75 8.75 8.75s8.75-3.924 8.75-8.75a8.752 8.752 0 0 0-3.981-7.332l-.547.838Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgButtonPowerMedium;
