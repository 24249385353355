import type { SVGProps } from "react";
const SvgEv70Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-70-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.016 0v1.968c-.063.068-.25.283-.563.642-.434.498-.865 1.124-1.293 1.88a12.784 12.784 0 0 0-1 2.167c-.503 1.444-.807 3.092-.913 4.943h-2.396c.016-.873.167-1.84.452-2.904a19.1 19.1 0 0 1 1.198-3.19c.725-1.502 1.46-2.62 2.206-3.356h-5.554V4.17h7.863Zm4.962-.278c.883 0 1.66.27 2.332.81.667.539 1.15 1.37 1.452 2.49.228.858.342 1.789.342 2.794 0 1.666-.297 3.036-.889 4.11-.35.624-.807 1.105-1.373 1.444a3.567 3.567 0 0 1-1.88.508c-.947 0-1.756-.286-2.428-.857-1.196-1.016-1.793-2.75-1.793-5.205 0-1.746.312-3.148.936-4.205a3.782 3.782 0 0 1 1.388-1.397 3.8 3.8 0 0 1 1.913-.492Zm-.088 1.96c-.761 0-1.28.439-1.555 1.317-.217.698-.325 1.677-.325 2.936 0 1.11.111 2.002.333 2.674.286.873.815 1.309 1.587 1.309.64 0 1.116-.338 1.428-1.016.312-.677.468-1.71.468-3.102 0-1.185-.11-2.119-.333-2.8-.285-.879-.82-1.318-1.603-1.318Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv70Negative;
