import type { SVGProps } from "react";
const SvgAvatarPoliceMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.25 6.23.137.04 10.5 3 .502.143-.165.495-1.5 4.5-.114.342h-1.993c-1.832 2.276-4.43 3.75-7.367 3.75-2.938 0-5.536-1.474-7.368-3.75H7.89l-.115-.341-1.5-4.5-.165-.496.502-.143 10.5-3 .138-.04Zm-6.042 8.52c1.62 1.712 3.73 2.75 6.042 2.75s4.422-1.038 6.041-2.75H11.208ZM7.39 10.088l1.22 3.662h17.28l1.22-3.662-9.86-2.818-9.86 2.818Zm9.86.162a.25.25 0 1 0 .001.501.25.25 0 0 0 0-.5ZM16 10.5a1.25 1.25 0 1 1 2.501 0 1.25 1.25 0 0 1-2.5 0Zm9.92 13c-.19.915-.428 1.77-.747 2.27l-.002.003c-2.89 4.475-6.173 5.478-7.935 5.478-1.76 0-5.042-1.003-7.933-5.478v-.001c-.32-.498-.56-1.352-.748-2.267-.194-.94-.351-2.03-.473-3.059a75.475 75.475 0 0 1-.353-3.83l-.004-.062v-.017l-.001-.004v-.001l.499-.03.499-.03v.004l.001.015.004.061a48.573 48.573 0 0 0 .08 1.085 74.5 74.5 0 0 0 .268 2.691c.12 1.016.273 2.074.459 2.975.191.926.403 1.606.61 1.927 2.727 4.224 5.709 5.021 7.092 5.021 1.385 0 4.367-.797 7.095-5.02.207-.325.418-1.008.61-1.933.185-.902.338-1.96.459-2.975a74.192 74.192 0 0 0 .347-3.771l.004-.06.001-.016v-.005l.5.03.498.03v.023l-.005.063a54.934 54.934 0 0 1-.081 1.1 75.555 75.555 0 0 1-.271 2.725 37.916 37.916 0 0 1-.473 3.058Zm-11.67-2.45a.05.05 0 0 0 .05-.05.05.05 0 0 0-.05-.05.05.05 0 0 0-.05.05c0 .028.022.05.05.05ZM13.3 21a.95.95 0 1 1 1.9 0 .95.95 0 0 1-1.9 0Zm6.95.05a.05.05 0 0 0 .05-.05.05.05 0 0 0-.05-.05.05.05 0 0 0-.05.05c0 .028.022.05.05.05ZM19.3 21a.95.95 0 1 1 1.9 0 .95.95 0 0 1-1.9 0Zm-2.05 4.75c1.516 0 2.787-.535 3.462-1.262l.733.68c-.91.98-2.476 1.582-4.194 1.582-1.618 0-3.095-.533-4.021-1.406l.686-.728c.702.663 1.914 1.134 3.335 1.134Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgAvatarPoliceMedium;
