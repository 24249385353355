import type { SVGProps } from "react";
const SvgTicketHeartSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 4h20v5h-.5c-1.424 0-2.5 1.076-2.5 2.5s1.076 2.5 2.5 2.5h.5v5H2V4Zm1 1v13h13v-.465h1V18h4v-3.034c-1.727-.232-3-1.66-3-3.466 0-1.806 1.273-3.234 3-3.467V5h-4v.464h-1V5H3Zm1.976 4.923C4.976 8.341 6.156 7 7.707 7a2.6 2.6 0 0 1 1.784.715A2.595 2.595 0 0 1 11.273 7c1.209 0 2.198.822 2.568 1.93l-1.028.115C12.522 8.407 11.931 8 11.273 8c-.551 0-1.046.288-1.369.76l-.412.605-.414-.604c-.323-.473-.82-.76-1.37-.76-.893 0-1.677.759-1.73 1.81a2.102 2.102 0 0 0 .491 1.472L9.49 14.57l3.002-3.267c.085-.115.235-.33.276-.405A2.11 2.11 0 0 0 13 9.923a2.056 2.056 0 0 0-.188-.878l1.028-.115c.104.311.16.646.16.993 0 .521-.125 1.012-.348 1.439l-.001.002c-.084.16-.298.456-.376.56l-.014.019-.017.018-3.387 3.686-.368.4-.368-.4-3.392-3.691-.007-.008a3.103 3.103 0 0 1-.747-2.024Zm7.837-.878-6.834.766 6.834-.766ZM16 6.393v.928h1v-.928h-1Zm0 1.857v.928h1V8.25h-1Zm0 1.857v.929h1v-.93h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTicketHeartSmall;
