import type { SVGProps } from "react";
const SvgLinkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m29.135 8.013-1.101-1.057a4.96 4.96 0 0 0-7.024.003l.344.345-.344-.345-.002.002-.005.005-.02.02-.073.073-.276.277-.936.936-2.4 2.4.708.707 2.399-2.4.936-.936.276-.277.074-.074.02-.02.004-.004.001-.001a3.96 3.96 0 0 1 5.614 0l.008.006 1.1 1.057a3.967 3.967 0 0 1 .005 5.616v.001l-6.373 6.4a4.011 4.011 0 0 1-5.656 0l-1.06-1.06-.707.707 1.06 1.06a5.011 5.011 0 0 0 7.07 0h.001l6.372-6.4a4.968 4.968 0 0 0-.008-7.034l-.007-.007Zm-9.94 6.98a5.016 5.016 0 0 0-7.072 0L5.76 21.359a5.013 5.013 0 0 0 0 7.072l1.06 1.06c1.946 1.944 5.126 1.945 7.07 0l3.72-3.72-.706-.707-3.72 3.72a4.014 4.014 0 0 1-5.656 0l-1.06-1.06a4.013 4.013 0 0 1 0-5.658l6.362-6.364a4.016 4.016 0 0 1 5.658 0l1.06 1.062.708-.707-1.061-1.062Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLinkMedium;
