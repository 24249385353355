import type { SVGProps } from "react";
const SvgMinimizeScreenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#minimize-screen-medium_svg__a)">
      <path
        clipRule="evenodd"
        d="M13.75 13.75V6h1v8.75H6v-1h7.75ZM6 22.25h7.75V30h1v-8.75H6v1Zm15.75-1h-.5V30h1v-7.75H30v-1h-8.25ZM22.25 6v7.75H30v1h-8.75V6h1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="minimize-screen-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMinimizeScreenMedium;
