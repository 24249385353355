import type { SVGProps } from "react";
const SvgArrowsCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.265 18c.011-5.936 4.833-10.74 10.77-10.73 2.058.005 4.3.838 6.267 2.172 1.715 1.165 3.183 2.687 4.11 4.308H22.5v1h7.234l.001-.499.015-6.735-1-.002-.01 4.882c-.999-1.44-2.356-2.749-3.877-3.781-2.081-1.413-4.516-2.34-6.826-2.344-6.49-.013-11.76 5.238-11.772 11.728l1 .002Zm.229 4.263 6.007-.014-.002-1-6.735.016h-.498l-.001.498-.015 6.735 1 .003.01-4.562C9.451 27.432 13.7 29.75 18 29.75c6.49 0 11.75-5.26 11.75-11.75h-1c0 5.937-4.813 10.75-10.75 10.75-4.45 0-8.802-2.694-10.506-6.487Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsCircleMedium;
