import type { SVGProps } from "react";
const SvgLaptopSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 4h16v9.349l1.916 2.874.084.126V19H2v-2.651l.084-.126L4 13.349V4Zm1 1v8h14V5H5ZM3.434 16l1.334-2h14.464l1.334 2H3.434ZM3 17v1h18v-1H3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLaptopSmall;
