import type { SVGProps } from "react";
const SvgSaVs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M20.07 2.98c1.02 0 2.08.3 2.9.72l.66-2.3c-1.14-.46-2.4-.62-3.62-.62-2.68 0-5.2 1.28-5.2 4.22 0 2.04 1.432 2.841 2.725 3.565 1.065.595 2.035 1.137 2.035 2.275 0 1.5-1.32 2.18-2.66 2.18-1.2 0-2.34-.46-3.42-.96l-.72 2.38c1.26.46 2.64.78 3.98.78 3.06 0 5.74-1.24 5.74-4.66 0-2.205-1.537-3.111-2.864-3.894-1.009-.595-1.896-1.119-1.896-2.086 0-1.2 1.32-1.6 2.34-1.6ZM4.17 1.02h-2.8l1.84 13.96h3.34l7.8-13.96h-3.04l-5.8 10.86h-.04L4.17 1.02Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaVs;
