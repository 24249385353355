import type { SVGProps } from "react";
const SvgCheckpointsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.244 8.25a4.006 4.006 0 1 1 8.012 0 4.006 4.006 0 0 1-8.012 0ZM8.25 3.256a4.994 4.994 0 1 0 4.97 5.488h16.03a2.506 2.506 0 0 1 2.506 2.506v3a2.506 2.506 0 0 1-2.506 2.506h-7.03a4.995 4.995 0 0 0-9.94 0H6.75a3.494 3.494 0 0 0-3.494 3.494v4.5a3.494 3.494 0 0 0 3.494 3.494h16.03a4.995 4.995 0 1 0 0-.988H6.75a2.506 2.506 0 0 1-2.506-2.506v-4.5a2.506 2.506 0 0 1 2.506-2.506h5.53a4.995 4.995 0 0 0 9.94 0h7.03a3.494 3.494 0 0 0 3.494-3.494v-3a3.494 3.494 0 0 0-3.494-3.494H13.22a4.995 4.995 0 0 0-4.97-4.5Zm2.6 3.843-.7-.698L7.5 9.05 6.35 7.9l-.7.698 1.5 1.5.35.35.35-.35 3-3Zm16.9 16.645a4.006 4.006 0 1 0 0 8.012 4.006 4.006 0 0 0 0-8.012Zm2.6 2.855-.7-.698L27 28.55l-1.15-1.15-.7.698 1.5 1.5.35.35.35-.35 3-3Zm-13.1-13.355a4.006 4.006 0 1 0 0 8.011 4.006 4.006 0 0 0 0-8.011Zm2.6 2.855-.7-.7-2.65 2.651-1.15-1.15-.7.699 1.5 1.5.35.35.35-.35 3-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCheckpointsMedium;
