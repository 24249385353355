import type { SVGProps } from "react";
const SvgDocumentXlsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.01 4h6.697l.146.147 5 5 .147.146V20H6v-.5l.009-15V4Zm.999 1L7 19h10v-9h-5V5H7.01ZM13 5.708 16.293 9H13V5.708ZM9.81 16.433l.963-1.43h-.726l-.602.962-.595-.962h-.767l.976 1.43L8 18h.749l.668-1.042.655 1.042h.788l-1.05-1.567Zm6.194-.542h-.624a.457.457 0 0 0-.163-.336c-.096-.078-.232-.115-.405-.115a.618.618 0 0 0-.36.09.296.296 0 0 0-.128.256c0 .1.041.17.123.212.079.045.281.106.603.186.341.084.576.17.708.256a.75.75 0 0 1 .362.662c0 .31-.121.555-.365.734-.214.16-.505.237-.871.237-.407 0-.724-.094-.949-.28-.226-.185-.343-.45-.351-.793h.651c.01.166.053.29.13.371.12.13.301.192.543.192a.687.687 0 0 0 .39-.096.346.346 0 0 0 .162-.3c0-.128-.076-.22-.227-.28a7.943 7.943 0 0 0-.591-.178 3.662 3.662 0 0 1-.455-.152 1.016 1.016 0 0 1-.263-.16c-.153-.137-.23-.326-.23-.567 0-.287.12-.515.36-.683.203-.145.468-.217.793-.217.286 0 .525.057.718.17.277.164.423.426.439.791Zm-2.72 2.11v-.554h-1.468v-2.444h-.654V18h2.121Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentXlsSmall;
