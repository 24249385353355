import type { SVGProps } from "react";
const SvgArrowsLeftRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m3.957 10 2.647-2.647-.708-.707-3.5 3.5-.353.354.353.353 3.5 3.5.708-.707L3.958 11l11.04.001v-1H3.957Zm16.085 3-2.646-2.646.708-.707 3.5 3.5.353.353-.353.354-3.5 3.5-.707-.707L20.043 14 9 13.999v-1h11.042Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLeftRightSmall;
