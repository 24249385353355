import type { SVGProps } from "react";
const SvgQuestionMarkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12.773 10.286c-.617.95-.957 2.293-1.022 4.029h2.406c.197-3.06 1.539-4.589 4.029-4.589 1.203 0 2.112.387 2.729 1.161.512.633.769 1.418.769 2.349 0 .504-.09.999-.267 1.483-.213.57-.666 1.208-1.356 1.918-1.073 1.099-1.765 1.85-2.078 2.251a4.533 4.533 0 0 0-.721 1.357c-.233.69-.358 1.75-.377 3.176h2.378c0-.802.046-1.456.141-1.959.082-.504.231-.925.44-1.266.21-.339.58-.767 1.112-1.28 1.202-1.175 1.963-1.981 2.28-2.42.71-.96 1.064-2.108 1.064-3.441 0-1.613-.522-2.915-1.567-3.904-1.12-1.072-2.625-1.608-4.518-1.608-1.177 0-2.236.237-3.176.713a5.727 5.727 0 0 0-2.266 2.03Zm6.77 18.184h-3.022v-2.909h3.022v2.909Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgQuestionMarkMedium;
