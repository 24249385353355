import type { SVGProps } from "react";
const SvgEv73 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ev-73_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#F27E00"
      fillRule="evenodd"
    />
    <path
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.016 0v1.968c-.063.068-.25.283-.563.642-.434.498-.865 1.124-1.293 1.88a12.785 12.785 0 0 0-1 2.167c-.503 1.444-.807 3.092-.913 4.943h-2.396c.016-.873.167-1.84.453-2.904a19.03 19.03 0 0 1 1.198-3.19c.724-1.502 1.46-2.62 2.205-3.356h-5.554V4.17h7.863Zm.979 3.674c.026-.953.261-1.751.706-2.397.72-1.037 1.8-1.555 3.245-1.555 1.11 0 2.007.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.146 1.084-.437 1.507-.285.418-.674.7-1.166.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.27 2.816-.782.677-1.771 1.016-2.967 1.016-1.296 0-2.325-.373-3.086-1.119-.757-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.169 1.266.507 1.658.339.386.818.579 1.437.579.618 0 1.095-.183 1.428-.548.307-.344.46-.754.46-1.23 0-.57-.196-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.137.01.27.016.396.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.436-1.047-.291-.27-.675-.405-1.15-.405-.546 0-.966.17-1.262.508-.297.338-.455.833-.477 1.484h-2.197Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEv73;
