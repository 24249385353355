import type { SVGProps } from "react";
const SvgSn9 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="sn-9_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#sn-9_svg__a)">
      <path
        d="M1.792 0h55.416C58.197 0 59 .806 59 1.8v16.4c0 .994-.801 1.8-1.792 1.8H1.792A1.797 1.797 0 0 1 0 18.2V1.8C0 .806.803 0 1.792 0Z"
        fill="currentColor"
      />
      <path
        d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm1.653 8.662h2.183c.18.896.7 1.344 1.559 1.344.485 0 .878-.163 1.178-.49.306-.332.54-.847.704-1.543a10.3 10.3 0 0 0 .23-1.51c-.354.495-.718.849-1.092 1.06-.37.205-.82.308-1.353.308-.849 0-1.587-.248-2.214-.744a3.542 3.542 0 0 1-1.029-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.765-.696 1.682-1.044 2.753-1.044.66 0 1.268.148 1.827.443a3.961 3.961 0 0 1 1.408 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.296 2.99-.886 4.129-.77 1.482-1.935 2.222-3.496 2.222-1.04 0-1.899-.287-2.58-.862-.674-.575-1.064-1.347-1.17-2.317Zm3.845-6.985c-.496 0-.915.203-1.258.609-.343.411-.514.933-.514 1.566 0 .512.123.965.371 1.36.338.544.813.815 1.424.815a1.58 1.58 0 0 0 1.33-.664c.31-.417.466-.912.466-1.487 0-.607-.161-1.12-.483-1.543a1.606 1.606 0 0 0-1.337-.656Z"
        fill="#FFDE15"
      />
    </g>
    <defs>
      <clipPath id="sn-9_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSn9;
