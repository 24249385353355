import type { SVGProps } from "react";
const SvgFreightWagonContainerWindMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#freight-wagon-container-wind-medium_svg__a)">
      <path
        d="M26.25 29.25V30A2.247 2.247 0 0 1 24 32.25 2.247 2.247 0 0 1 21.75 30v-.75m-1.5-1.5h-3m0-2.25V30m14.25-6.75h-9.75V9.75h9.75m0 19.5H20.25v-3H31.5m-12-13.5h-6m-1.5 0H9m-1.5 4.5h-3m6 4.5h-3m10.5-4.5H9m7.5 4.5H12"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="freight-wagon-container-wind-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFreightWagonContainerWindMedium;
