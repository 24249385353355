import type { SVGProps } from "react";
const SvgMagnifyingGlassPlusLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.5 3C9.492 3 3 9.492 3 17.5S9.492 32 17.5 32 32 25.508 32 17.5 25.508 3 17.5 3ZM2 17.5C2 8.94 8.94 2 17.5 2 26.06 2 33 8.94 33 17.5c0 4.1-1.592 7.829-4.192 10.6l3.868 3.869.707-.707.353-.354.354.354 11.324 11.324a2 2 0 1 1-2.828 2.828L31.262 34.09l-.354-.354.354-.353.707-.707-3.868-3.868A15.446 15.446 0 0 1 17.5 33C8.94 33 2 26.06 2 17.5Zm3 0C5 10.596 10.596 5 17.5 5S30 10.596 30 17.5 24.404 30 17.5 30 5 24.404 5 17.5ZM17.5 4C10.044 4 4 10.044 4 17.5S10.044 31 17.5 31 31 24.956 31 17.5 24.956 4 17.5 4Zm25.793 40.707-10.97-10.97 1.413-1.414 10.97 10.97a.999.999 0 1 1-1.413 1.414ZM18 18h8v-1h-8V9h-1v8H9v1h8v8h1v-8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassPlusLarge;
