import type { SVGProps } from "react";
const SvgCertificateRibbonTickSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7 8a5 5 0 1 1 10 0A5 5 0 0 1 7 8Zm5-6a6 6 0 0 0-3.81 10.636l-4.114 6.583-.737 1.179 1.32-.44 2.588-.863.806 1.613.447.894.447-.894 3.049-6.098 3.057 6.114.447.894.447-.894.806-1.612 2.589.863 1.319.44-.737-1.18-4.121-6.594A6 6 0 0 0 12 2Zm-.791 11.948a5.96 5.96 0 0 1-2.195-.743L5.66 18.57l1.68-.56.412-.138.194.388.553 1.106 2.709-5.418Zm3.77-.738a5.96 5.96 0 0 1-2.195.74l2.716 5.432.553-1.105.194-.388.411.137 1.681.56-3.36-5.376Zm.375-6.856-4 4-.354.353-.354-.353-2-2 .708-.708L11 9.293l3.646-3.647.707.708Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCertificateRibbonTickSmall;
