import type { SVGProps } from "react";
const SvgHandSbbLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26.44 13.41 20.95 8h4.08L32 15l-6.97 7h-4.08l5.49-5.37h-6.83V22h-3.22v-5.37H9.6L15.09 22h-4.08L4 15l7.01-7h4.08L9.6 13.41h6.79V8h3.22v5.41h6.83Zm-9.732 18.978L8.04 26.532a2.5 2.5 0 0 0-3.115 3.908l12.25 10.44.14.12h7.978l1.854 1.854.146.146H43.98l-1.177-.898-10.652-8.124c-.11-.09-.22-.18-.331-.273-1.983-1.638-4.411-3.645-8.092-3.705H19.5c-.878 0-1.584.29-2.071.768a2.416 2.416 0 0 0-.721 1.62ZM7.47 27.354l9.665 6.53c.085.123.183.24.294.348.488.478 1.193.768 2.071.768h4v-1h-4c-.651 0-1.094-.21-1.371-.482a1.42 1.42 0 0 1-.424-1.018c0-.373.143-.742.424-1.018.277-.272.72-.482 1.371-.482h4.216c3.319.055 5.496 1.851 7.504 3.507l.003.002c.1.083.2.166.3.247l.007.006.007.006L41.02 42H27.707l-1.853-1.853-.147-.147h-8.023L5.574 29.68a1.5 1.5 0 0 1 1.876-2.34l.01.007.01.007Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgHandSbbLarge;
