import type { SVGProps } from "react";
const SvgEiffelTowerLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M22.013 3H26v.516l.002.069.007.25a406.41 406.41 0 0 0 .11 3.638c.06 1.747.137 3.581.213 4.527H28v4h-1.13c.457 3.107 1.08 7.001 1.835 11h2.73l-.518.777-1.616 2.424v.012l.041.152c.864 2.98 2.733 6.686 4.405 9.67a106.307 106.307 0 0 0 3.049 5.093l.048.074.012.019.003.004v.002l-.418.273.419-.273.504.773h-8.382l-.04-.457a4.99 4.99 0 0 0-9.943 0L18.96 46h-8.413l.543-.785.411.285-.411-.285.004-.006.013-.019.053-.078.204-.302a86.212 86.212 0 0 0 3.076-4.972c1.726-3.02 3.57-6.719 4.228-9.677l-1.584-2.384-.515-.777h2.728a281.116 281.116 0 0 0 1.835-11h-1.13v-4h1.667c.077-.952.153-2.802.214-4.557a462.556 462.556 0 0 0 .11-3.629l.006-.242L22 3.51v-.021l.5.012-.5-.013.013-.487ZM19.49 31c-.418 1.581-1.111 3.307-1.91 5h2.559l1.666-5H19.49Zm-2.395 6a66.972 66.972 0 0 1-1.788 3.334A87.153 87.153 0 0 1 12.438 45h5.62a5.99 5.99 0 0 1 11.823 0h5.648a107.39 107.39 0 0 1-2.655-4.476A84.26 84.26 0 0 1 31.002 37H17.097Zm13.418-1c-.813-1.702-1.54-3.43-2.026-5h-2.294l1.666 5h2.654ZM25.119 7.507c.058 1.666.132 3.474.21 4.493H22.67c.08-1.025.153-2.849.211-4.523A468.34 468.34 0 0 0 22.987 4h2.026c.018.706.056 2.069.106 3.507ZM22.142 16h3.716a283.454 283.454 0 0 0 1.828 11h-7.372a283.629 283.629 0 0 0 1.828-11Zm.718 15-1.666 5h5.612l-1.666-5h-2.28Zm5.376-1 .064-.094v-.007l.084-.126L29.566 28H18.432l1.183 1.78.15.22h8.47ZM21 13v2h6v-2h-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEiffelTowerLarge;
