import type { SVGProps } from "react";
const SvgBellDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.5 3H10v1.277c-.709.213-1.31.54-1.813.943l.626.78c.73-.584 1.752-1 3.187-1 2.037 0 3.262.838 3.994 1.855C16.744 7.897 17 9.163 17 10v5h1v-5c0-.997-.295-2.48-1.194-3.73A5.26 5.26 0 0 0 14 4.277V3h-3.5ZM13 4.064V4h-.942c.33.002.644.024.942.064ZM11.942 4H11v.064c.298-.04.612-.062.942-.064ZM7.135 8.843A5.46 5.46 0 0 0 7 10v6c0 .875-.28 1.53-.696 2h9.989L7.135 8.843Zm-.814-.815A6.6 6.6 0 0 0 6 10v6c0 .837-.338 1.312-.73 1.594-.417.3-.933.406-1.27.406v1h5.044a2.21 2.21 0 0 0 .895 1.423c.565.409 1.306.577 2.061.577.756 0 1.496-.168 2.061-.577A2.21 2.21 0 0 0 14.956 19h2.337l1.354 1.354.707-.707-15-15-.708.707 2.675 2.674ZM13.917 19h.01a1.2 1.2 0 0 1-.452.613c-.34.245-.85.387-1.475.387-.625 0-1.135-.142-1.475-.387a1.2 1.2 0 0 1-.452-.613h3.844Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBellDisabledSmall;
