import type { SVGProps } from "react";
const SvgBulbOnPercentageMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#bulb-on-percentage-medium_svg__a)">
      <path
        d="M11.852 24c-2.1-1.2-3.6-3.45-3.6-6 0-3.75 3-6.75 6.75-6.75a6.718 6.718 0 0 1 6.576 5.212m-.658-8.886-1.053 1.93m5.4 2.42-1.924 1.062M4.565 24.056l1.965-1.17M3.002 18h2.25m-.585-6.015 1.921 1.236m2.485-5.64 1.063 1.933M15 6v2.25m16.5 9.89L21 29.954M18.75 24v3.15l-3 1.05v1.05h-1.5V28.2l-3-1.05V24h7.5Zm4.5-5.203a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm6 7.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="bulb-on-percentage-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBulbOnPercentageMedium;
