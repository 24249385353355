import type { SVGProps } from "react";
const SvgCloudSunshineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16 2v2h1V2h-1Zm-.01 5.717a3.215 3.215 0 0 0-1.066-1.18A2.51 2.51 0 0 1 16.5 6C17.915 6 19 7.085 19 8.5c0 1.02-.562 1.862-1.397 2.257a2.065 2.065 0 0 0-1.29-.9c.124-.805-.005-1.532-.323-2.14ZM18 12.402a2.83 2.83 0 0 0-.034-.711C19.182 11.146 20 9.936 20 8.5 20 6.533 18.467 5 16.5 5c-1.016 0-1.923.41-2.56 1.086-1.311-.361-2.844.06-3.732 1.504-1.516-.719-3.43.326-3.522 2.177-.481.128-.87.43-1.144.814-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.927 0 1.66-.312 2.175-.813A2.794 2.794 0 0 0 18 12.402Zm-4.145-5.294c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.166.018.434.175.846.473 1.147.293.295.763.524 1.492.524h7c.691 0 1.167-.226 1.477-.528.317-.308.493-.725.526-1.148.033-.427-.08-.826-.296-1.101-.205-.262-.522-.442-.987-.423l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073Zm-.931-1.484-1.441-1.386.693-.72 1.44 1.386-.692.72Zm6.515 6.593 1.414 1.414.707-.707-1.413-1.414-.708.707ZM21 8h2v1h-2V8Zm-.258-4.465L19.328 4.95l.707.707 1.414-1.414-.707-.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudSunshineSmall;
