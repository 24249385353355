import type { SVGProps } from "react";
const SvgBulbOffLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.49 18.49A8.5 8.5 0 0 1 32 24.5a8.6 8.6 0 0 1-4.427 7.5H25v-3.76l2.312-1.85-.624-.78-2.5 2-.188.15V32h-1v-4.24l-.188-.15-2.5-2-.624.78L22 28.24V32h-2.568A8.84 8.84 0 0 1 15 24.496a8.5 8.5 0 0 1 2.49-6.006ZM18 32.258a9.84 9.84 0 0 1-4-7.75V24.5a9.5 9.5 0 1 1 19 0v.001a9.6 9.6 0 0 1-4 7.777v6.777l-.335.117L25 40.455V42h-3v-1.545l-3.665-1.283-.335-.117v-6.797ZM19 34v-1h9v1h-2v1h2v1h-2v1h2v1.345l-3.665 1.283-.335.117V41h-1v-1.255l-.335-.117L19 38.345V37h2v-1h-2v-1h2v-1h-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBulbOffLarge;
