import type { SVGProps } from "react";
const SvgTimetableSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 4h7V3H2v1Zm9 11.5c0-2.48 2.02-4.5 4.5-4.5s4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5-4.5-2.02-4.5-4.5Zm4.5-5.5a5.506 5.506 0 0 0-5.5 5.5c0 3.032 2.467 5.5 5.5 5.5s5.5-2.468 5.5-5.5c0-3.033-2.467-5.5-5.5-5.5ZM15 4h-4V3h4v1Zm-4 3h4V6h-4v1Zm11 0h-5V6h5v1Zm-5-3h5V3h-5v1ZM9 7H2V6h7v1Zm-7 3h7V9H2v1Zm5 3H2v-1h5v1Zm-5 3h5v-1H2v1Zm5 3H2v-1h5v1Zm8-7v4h4v-1h-3v-3h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgTimetableSmall;
