import type { SVGProps } from "react";
const SvgCloudStrongRainMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m17.428 4.026.525-1.038-1.114.333c-.61.183-1.175.473-1.67 1.02-.4.442-.732 1.03-1.016 1.812-1.355-.471-3.009-.085-3.944 1.437-1.516-.719-3.431.326-3.523 2.177-.481.128-.87.43-1.144.814-.36.504-.534 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.977 0 1.741-.305 2.272-.808a2.657 2.657 0 0 0 .819-2.084c.656.013 1.415-.129 2.134-.416 1.082-.432 2.168-1.233 2.731-2.488l.52-1.16-1.17.495c-1.89.799-3.4.258-4.294-.744-.92-1.031-1.199-2.553-.584-3.77Zm.408 7.065c.513.062 1.258-.023 2.018-.326a4.454 4.454 0 0 0 1.423-.896c-1.668.25-3.082-.366-4.012-1.408-.881-.989-1.32-2.361-1.116-3.68a2.433 2.433 0 0 0-.238.23c-.307.34-.598.84-.864 1.612.384.282.71.65.943 1.094.318.607.447 1.334.323 2.139a2.17 2.17 0 0 1 1.206.739c.126.152.232.319.317.496Zm-6.978-2.589c.635-1.44 1.948-1.774 2.997-1.394.53.192.984.566 1.25 1.073.261.5.357 1.165.116 1.977l-.2.67.7-.028c.466-.019.803.162 1.029.434.235.282.363.684.342 1.104-.02.416-.187.825-.508 1.129-.317.3-.822.533-1.584.533H8c-.73 0-1.199-.23-1.491-.524-.299-.3-.456-.713-.475-1.147-.019-.438.104-.863.321-1.166.21-.294.497-.463.845-.463h.564l-.067-.559c-.18-1.502 1.419-2.246 2.386-1.454l.51.417.265-.602Zm-3.805 9.274 1-2 .894.448-1 2-.894-.448Zm7-2-1 2 .894.448 1-2-.894-.448Zm-6 4 2-4 .894.448-2 4-.894-.448Zm4.05-4-2 4 .894.448 2-4-.894-.448Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCloudStrongRainMoonSmall;
