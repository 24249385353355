import type { SVGProps } from "react";
const SvgEntranceMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.25 6.25h-.5v23.5h19V25.5h-1v3.25h-17V7.25h17v3.25h1V6.25H5.25Zm5.66 12.147 4.712-4.724.708.706-3.86 3.871h19.045v1H12.472l3.858 3.858-.707.707-4.712-4.712-.353-.353.353-.353Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEntranceMedium;
