import type { SVGProps } from "react";
const SvgEscalatorSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.998 5.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 2.999 0 1.5 1.5 0 0 1-3 0Zm6.743.526A2.96 2.96 0 0 1 15.5 5.5h3c1.655 0 3 1.346 3 3s-1.345 3-3 3h-1.64l-6.1 6.973A2.959 2.959 0 0 1 8.5 19.5h-4c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3h1.998v-5h3v2.303l3.743-4.277Zm-4.743 5.42V9.5h-1v3.59l1-1.143ZM15.5 6.5a1.96 1.96 0 0 0-1.501.68l-.004.003-6.254 7.146-.15.171H4.5c-1.102 0-2 .897-2 2 0 1.102.898 2 2 2h4c.582 0 1.126-.241 1.501-.68l.004-.003 6.253-7.146.15-.171H18.5c1.103 0 2-.898 2-2 0-1.102-.897-2-2-2h-3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEscalatorSmall;
