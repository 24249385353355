import type { SVGProps } from "react";
const SvgDocumentPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.75 6.25h-.5v23.5H19.5v-1h-9.25V7.25h8v7.494h7.5V22.5h1v-8.458l-.146-.146-7.5-7.5-.147-.146H9.75Zm15.288 7.494L19.25 7.957v5.787h5.788ZM22.5 19.25h-9v-1h9v1Zm-9.004 3H22.5v-1h-9.003v1Zm9.004 3h-9v-1h9v1Zm3.25.25v3.25h-3.252v1h3.252V33h1v-3.25h3.248v-1H26.75V25.5h-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPlusMedium;
