import type { SVGProps } from "react";
const SvgDragSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4 7h16v1H4V7Zm0 8h16v1H4v-1Zm16-4H4v1h16v-1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgDragSmall;
