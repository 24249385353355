import type { SVGProps } from "react";
const SvgArrowLongUpMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m16.893 6.41-4.711 4.714.707.707 3.858-3.86V30h1V7.97l3.87 3.861.706-.708L17.6 6.41l-.354-.353-.353.354Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongUpMedium;
