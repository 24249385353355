import type { SVGProps } from "react";
const SvgBicycleLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M26 12h8l-.6.8-4.318 5.758 1.924 4.022A8 8 0 0 1 42 30c0 4.42-3.58 8-8 8a8 8 0 0 1-3.897-14.988L28.184 19h-9.343l-1.465 3.745A8.001 8.001 0 0 1 22 30a8 8 0 0 1-8 8 8 8 0 1 1 2.445-15.62l1.59-4.062.124-.318H28.25L32 13h-6v-1Zm7.55 18.216-3.013-6.3A7 7 0 0 0 34 37c3.867 0 7-3.133 7-7a7 7 0 0 0-9.563-6.516l3.014 6.3-.902.432Zm-17.47-6.902-2.546 6.504.932.364 2.545-6.503a7 7 0 1 1-.932-.365ZM15 15h5v-1h-5v1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgBicycleLarge;
