import type { SVGProps } from "react";
const SvgRecordMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.75 18c0-5.661 4.589-10.25 10.25-10.25S28.25 12.339 28.25 18 23.661 28.25 18 28.25 7.75 23.661 7.75 18ZM18 8.75A9.25 9.25 0 0 0 8.75 18 9.25 9.25 0 0 0 18 27.25 9.25 9.25 0 0 0 27.25 18 9.25 9.25 0 0 0 18 8.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRecordMedium;
