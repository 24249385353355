import type { SVGProps } from "react";
const SvgShoppingBagSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M12 2a3 3 0 0 0-3 3v1.957H5.034l-.033.464-1 14-.038.535h16.074l-.038-.535-1-14-.034-.464h-3.967L15 5a3 3 0 0 0-3-3Zm1.997 5.957-.004 3.042 1 .002.004-3.044h3.037l.929 13H5.037l.928-13H9V11h1V7.957h3.997Zm0-1L14 5a2 2 0 1 0-4 0v1.957h3.998Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagSmall;
