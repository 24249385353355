import type { SVGProps } from "react";
const SvgRe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="re_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#re_svg__a)">
      <path
        d="M.5 2A1.5 1.5 0 0 1 2 .5h55A1.5 1.5 0 0 1 58.5 2v16a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 18V2Z"
        stroke="#EB0000"
      />
      <path
        d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.979 1.979 0 0 1-.197-.625c-.037-.253-.088-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.709-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.171-.949-.514-1.202-.343-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Z"
        fill="#EB0000"
      />
    </g>
    <defs>
      <clipPath id="re_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe;
