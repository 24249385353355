import type { SVGProps } from "react";
const SvgLocationPinCameraMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.75 13.51C7.75 7.843 12.339 3.25 18 3.25s10.25 4.594 10.25 10.26v.004c-.015 1.706-.552 3.224-1.367 4.653l-.005.008-.005.008-8.42 13.58-.425.686-.425-.685-8.42-13.58-.005-.008C8.346 16.788 7.82 15.3 7.75 13.53v-.02Zm1-.01c.063 1.571.527 2.894 1.284 4.16l7.994 12.891 7.99-12.887c.754-1.324 1.218-2.671 1.232-4.157C27.249 8.394 23.107 4.25 18 4.25c-5.105 0-9.245 4.14-9.25 9.25Zm9.25.367a.202.202 0 1 0 0 .405.202.202 0 0 0 0-.405Zm-1.202.203a1.202 1.202 0 1 1 2.404 0 1.202 1.202 0 0 1-2.404 0ZM16.5 9.25h-.309l-.138.276-.612 1.224H12.25v7h11.5v-7h-3.191l-.612-1.224-.138-.276H16.5Zm-.303 2.224.612-1.224h2.382l.612 1.224.138.276h2.809v5h-9.5v-5h2.809l.138-.276Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinCameraMedium;
