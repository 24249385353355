import type { SVGProps } from "react";
const SvgSn6Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="sn-6-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#sn-6-negative_svg__a)">
      <path
        d="M1.792.5h55.416c.71 0 1.292.58 1.292 1.3v16.4c0 .72-.58 1.3-1.292 1.3H1.792C1.082 19.5.5 18.92.5 18.2V1.8c0-.72.581-1.3 1.292-1.3Z"
        stroke="#FFDE15"
      />
      <path
        d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429ZM34.1 7.121h-2.199c-.206-.891-.712-1.337-1.519-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.265 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.087 0 1.96.385 2.618 1.155.628.728.942 1.64.942 2.737 0 1.303-.422 2.35-1.266 3.14-.76.712-1.671 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.759-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.943 1.29.39.495.64 1.12.751 1.874Zm-3.916 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.776.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.475-.92.475-1.495 0-.564-.143-1.047-.428-1.447-.348-.49-.82-.736-1.416-.736Z"
        fill="#FFDE15"
      />
    </g>
    <defs>
      <clipPath id="sn-6-negative_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSn6Negative;
