import type { SVGProps } from "react";
const SvgTrainTracksPenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#train-tracks-pen-medium_svg__a)">
      <path
        d="M6.75 33V3m10.5 15V3M3 11.25h18M3 6.75h18m-18 9h18m-18 4.5h12m-12 4.5h9m-9 4.5h6m2.25-.435v3.435h3.435l11.25-11.252-3.435-3.433-11.25 11.25Zm13.815-13.816 3.435 3.435 3.435-3.448-3.435-3.421-3.435 3.434Z"
        stroke="currentColor"
      />
    </g>
    <defs>
      <clipPath id="train-tracks-pen-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrainTracksPenMedium;
