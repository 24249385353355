import type { SVGProps } from "react";
const SvgSunMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m13.97 9.77.586-1.612-1.36 1.046c-2.702 2.074-3.904 4.697-3.974 7.294-.07 2.583.981 5.101 2.709 6.995 1.728 1.896 4.156 3.19 6.866 3.292 2.72.102 5.663-1 8.41-3.784l1.48-1.502-1.998.678c-1.785.605-3.486.679-5.037.358 1.287-1.1 2.097-2.795 2.097-4.535 0-3.126-2.622-5.75-5.75-5.75-1.727 0-3.26.78-4.304 1.943-.296-1.511-.228-3.04.275-4.422Zm.043 5.64a11.22 11.22 0 0 0 2.315 3.98c1.115 1.24 2.51 2.232 4.102 2.8 1.384-.846 2.32-2.493 2.32-4.19 0-2.574-2.175-4.75-4.75-4.75-1.678 0-3.146.895-3.987 2.16Zm1.572 4.65c2.258 2.51 5.612 4.118 9.44 3.548-2.113 1.632-4.245 2.25-6.19 2.178-2.41-.09-4.594-1.243-6.166-2.967s-2.509-3.997-2.447-6.294c.05-1.877.768-3.797 2.398-5.477-.474 3.124.734 6.532 2.965 9.012Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSunMoonMedium;
