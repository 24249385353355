import type { SVGProps } from "react";
const SvgFolderOpenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 7.75h10.015v3h12v3h4.392l-.218.658-3.976 12-.114.342H6.265v-.5l-.015-18v-.5Zm21.015 6v-2h-12v-3H7.25l.012 14.614 3.307-9.281.118-.332h16.578Zm-19.791 12h20.153l3.645-11h-19.88l-3.918 11Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFolderOpenMedium;
