import type { SVGProps } from "react";
const SvgPlatformChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="platform-change_svg__color-immutable"
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#EB0000" height={16} rx={2} width={16} />
    <path
      clipRule="evenodd"
      d="m11.288 5.278-.542.893 1.281.778 1.942-3.197L10.77 1.8l-.78 1.28 1.152.704c-.963.085-1.902.384-2.695 1.307.32.36.616.79.885 1.311.532-.81 1.16-1.058 1.956-1.123ZM4 9.75H2v1.5h2c.96 0 1.932-.084 2.798-.66a8.972 8.972 0 0 1-.632-1.41c-.586.5-1.283.57-2.166.57Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="m12.027 8.929-1.28.778.541.894c-1.129-.092-1.925-.525-2.577-2.462-1.094-3.24-2.927-3.51-4.71-3.51H2v1.5h2c1.523 0 2.505.168 3.288 2.49.917 2.72 2.358 3.345 3.854 3.476L9.99 12.8l.781 1.279 3.2-1.953-1.943-3.197Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgPlatformChange;
