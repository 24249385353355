import type { SVGProps } from "react";
const SvgParliamentMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.25 3.25h-.5v4.525a5 5 0 0 0-4.5 4.975v4.462l-2.686 1.074-.314.126v4.338h-4.5v9H3v1h28.5v-1h-1.75v-9h-4.5v-4.338l-.314-.126-2.686-1.075V12.75a5 5 0 0 0-4.5-4.975V5.75h3v-2.5h-3.5Zm7 19.5v-2h-14v2h14Zm-15 1h-3.5v8h3.5v-8Zm1 0h2v8h-2v-8Zm3 0v8h2l-.002-4a2 2 0 0 1 4 0l.002 4h2v-8h-8Zm9 0h2v8h-2v-8Zm3 0h3.5v8h-3.5v-8Zm-5.5-19h-2v-.5h2v.5Zm-1.5 27h-2l-.002-4a1 1 0 0 1 2 0l.002 4Zm6-12.661v.661h-14v-.661l7-2.8 7 2.8Zm-3-2.277V13.25h-8v3.562l3.814-1.526.186-.075.186.075 3.814 1.525Zm-4-8.062a4 4 0 0 1 3.97 3.5h-7.94a4 4 0 0 1 3.97-3.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgParliamentMedium;
