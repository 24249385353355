import type { SVGProps } from "react";
const SvgRailwayShipMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.25 3.25h-.5v4h3v3.5H3.682l.373.728 3.322 6.49-1.417.486a3.154 3.154 0 0 1-2.048 0l-.324.946c.875.3 1.821.3 2.696 0l2.128-.73a3.154 3.154 0 0 1 2.048 0l2.128.73.324-.946-2.128-.73a4.153 4.153 0 0 0-2.447-.076l-3.02-5.898H21v-1h-1.75v-3.5H24v-1H11.75v-2H25.5v-1H11.25Zm7 7.5h-3.5v-3.5h3.5v3.5ZM5.646 29.646l22.5-22.5.707.708-22.5 22.5-.707-.708ZM24.25 18.25h4.371a5.612 5.612 0 0 0-3.033-3.069l.385-.923a6.614 6.614 0 0 1 3.707 3.97l2.817 7.882c.155.326.253.695.253 1.095 0 1.4-1.12 2.545-2.516 2.545h-3.52a3.492 3.492 0 0 1-1.015 2H33v1H15v-1h5.801a3.493 3.493 0 0 1-1.015-2H15v-1h15.234c.83 0 1.516-.684 1.516-1.545 0-.24-.06-.469-.164-.682l-.012-.025-.01-.026-.436-1.222H24.25v-7Zm1.45 11.5a2.502 2.502 0 0 1-2.45 2 2.502 2.502 0 0 1-2.45-2h4.9Zm5.07-5.5-1.787-5H25.25v5h5.52ZM22.25 18v7.25H15v-1h6.25V18h1Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgRailwayShipMedium;
