import type { SVGProps } from "react";
const SvgFastForwardSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m4 4.522.793.573 4.5 3.25.707.51V4.522l.793.573 4.5 3.25 4.5 3.25.561.405-.561.405-4.5 3.25-4.5 3.25-.793.573v-4.334l-.707.511-4.5 3.25-.793.573V4.522Zm1 1.956v11.044l3.707-2.677 1.5-1.084.793-.573v4.334l3.707-2.677L18.646 12l-3.939-2.845L11 6.478v4.333l-.793-.573-1.5-1.083L5 6.478Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFastForwardSmall;
