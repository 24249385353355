import type { SVGProps } from "react";
const SvgFreightWagonContainerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.25 9.25h-.5v14.5h20.5V9.25h-20Zm.5 7.25v-6.25h18.5v12.5H8.75V16.5Zm15 13.5v-.25h3.5V30a1.75 1.75 0 1 1-3.5 0Zm-1 0v-.25h-9.5V30a2.75 2.75 0 1 1-5.5 0v-.25h-1.5v-1.5h-2V30h-1v-4.5h1v1.75h2v-1.5h23.5v1.5h2V25.5h1V30h-1v-1.75h-2v1.5h-1.5V30a2.75 2.75 0 1 1-5.5 0Zm-14-.25V30a1.75 1.75 0 1 0 3.5 0v-.25h-3.5Zm-1.5-1h21.5v-2H7.25v2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonContainerMedium;
