import type { SVGProps } from "react";
const SvgSaSp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.563 2.959c1.055 0 2.154.302 3.003.724l.684-2.31C11.07.91 9.764.75 8.5.75c-2.777 0-5.387 1.285-5.387 4.238 0 2.049 1.484 2.853 2.824 3.58 1.103.597 2.108 1.142 2.108 2.285 0 1.506-1.368 2.188-2.757 2.188-1.243 0-2.424-.463-3.542-.965L1 14.466c1.305.463 2.734.784 4.122.784 3.17 0 5.947-1.245 5.947-4.68 0-2.214-1.592-3.124-2.967-3.91-1.045-.598-1.964-1.123-1.964-2.094 0-1.205 1.388-1.607 2.425-1.607Zm9.721 4.52h-1l.9-4.273h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.58 2.547-3.12 2.547Zm-5.4 7.52h2.8l1.12-5.314h1.62c3.121 0 5.901-1.666 5.901-5.054 0-3.49-3.54-3.631-6.12-3.631h-2.36l-2.962 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgSaSp;
