import type { SVGProps } from "react";
const SvgCableCarProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.241 7.976 9.14 6.25l-2.838 5.24H3.62a1.5 1.5 0 0 0-1.5 1.5v8a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5h-2.681l-3.11-6.663 7.412-1.852-.242-.97-20 5 .242.97Zm11.6-2.9-3.386.846-3.016 5.568h9.396L13.84 5.076ZM3.621 12.49a.5.5 0 0 0-.501.5v8a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-17Zm.499 1h4v5h-4v-5Zm1 1v3h2v-3h-2Zm5.5-1h-.5v5h4v-5h-3.5Zm.5 4v-3h2v3h-2Zm5-4h4v5h-4v-5Zm1 1v3h2v-3h-2Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgCableCarProfileSmall;
