import type { SVGProps } from "react";
const SvgSn8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="sn-8_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#sn-8_svg__a)">
      <path
        d="M1.792 0h55.416C58.197 0 59 .806 59 1.8v16.4c0 .994-.801 1.8-1.792 1.8H1.792A1.797 1.797 0 0 1 0 18.2V1.8C0 .806.803 0 1.792 0Z"
        fill="currentColor"
      />
      <path
        d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm3.465 5.356c-.538-.186-.952-.489-1.242-.91-.285-.422-.428-.934-.428-1.535 0-1.002.412-1.801 1.235-2.397.727-.527 1.592-.791 2.594-.791 1.14 0 2.07.314 2.792.941.686.591 1.029 1.321 1.029 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.702.746-1.234.941.416.121.756.277 1.02.467.733.532 1.1 1.31 1.1 2.333 0 1.176-.451 2.112-1.353 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.924 2.924 0 0 1 1.068-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.31.28-.466.648-.466 1.107 0 .48.155.865.466 1.155.317.285.739.427 1.266.427s.944-.142 1.25-.427c.306-.285.459-.672.459-1.163 0-.48-.156-.854-.467-1.123-.306-.269-.733-.403-1.282-.403Zm-.015 4.706c-.57 0-1.037.177-1.4.53-.36.348-.538.796-.538 1.345 0 .564.181 1.023.545 1.376.37.354.847.53 1.432.53.665 0 1.163-.195 1.495-.585a1.93 1.93 0 0 0 .483-1.297c0-.56-.185-1.018-.554-1.377-.353-.348-.841-.522-1.463-.522Z"
        fill="#FFDE15"
      />
    </g>
    <defs>
      <clipPath id="sn-8_svg__a">
        <path d="M0 0h59v20H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSn8;
