import type { SVGProps } from "react";
const SvgEnvelopeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.75 9.25h-.5v17.5h23.5V9.25h-23Zm.5 1.71v14.082l7.032-7.032-7.032-7.05Zm.707 14.79h20.085l-7.06-7.047-2.659 2.651-.354.353-.353-.354-2.628-2.635-7.031 7.032Zm20.793-.706V10.955l-7.06 7.041 7.06 7.048Zm-.71-14.794H7.956L17.97 20.293 28.04 10.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeMedium;
