import type { SVGProps } from "react";
const SvgArrowCircleSwitzerlandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 3.823-2.15 7.292-5.319 9H19v1h-4v-4h1v2.227c2.956-1.49 5-4.687 5-8.227a9 9 0 1 0-9 9v1C6.477 22 2 17.523 2 12Zm11.235-4.884-.387-.323-.32.39-.59.72-2.233.575-.337-.172-.268-.137-.247.173-1.67 1.173-.82.575.952.309-1.438 1.508-.084.089-.034.118-.74 2.558-.238.826.836-.2 1.464-.353.066-.016.059-.032.334-.184 1.266 1.146.166.15.222-.024 2.16-.23.179-.018.126-.129.84-.86 1.123 1.467.488.637.357-.717.897-1.802 2.118.524.704.174-.087-.72-.088-.722.477.011.539.013-.028-.538-.108-2.115-.012-.24-.194-.14-.919-.664-.455-.329-.274.49-.106.19-.322-.092.276-.777.165-.466-.464-.17-2.462-.904-.889-.742Zm-4.1 2.823-.558-.182.604-.424.238.122.168.087.184-.048 2.576-.664.158-.04.104-.127.373-.456.568.474.067.056.081.03 2.079.763-.288.812-.178.502.512.146 1.214.347.38.109.194-.346.025-.044.269.194.068 1.338-.517-.013-.578-.014.07.574.07.577-1.803-.446-.389-.096-.178.359-.718 1.442-.987-1.29-.35-.458-.404.413-1.12 1.144-1.758.188-1.365-1.236-.264-.24-.313.172-.587.325-.563.135.466-1.615 1.902-1.995.549-.575Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleSwitzerlandSmall;
