import type { SVGProps } from "react";
const SvgShuttleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.464 3.464C5.184 3.744 5 4.215 5 5v4h14V5c0-.785-.183-1.256-.464-1.536C18.256 3.184 17.785 3 17 3H7c-.785 0-1.256.183-1.536.464ZM5 14v-4h14v4H5ZM4.757 2.757C5.3 2.214 6.079 2 7 2h10c.921 0 1.7.214 2.243.757C19.786 3.3 20 4.079 20 5v2h1v2h-1v8h-3.999v-2H8v2H4V9H3V7h1V5c0-.921.214-1.7.757-2.243ZM17 15h2v1h-1.999v-1ZM5 16v-1h2v1H5ZM17 4H7v1h10V4ZM7 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm9 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5.977 20l1.017-.994-.7-.715L4.4 20.143l-.366.359.368.357 1.896 1.842.696-.717-1.013-.985H10v-1H5.977Zm12.047.99-1.017.995.7.715 1.893-1.852.367-.359-.368-.357-1.897-1.842-.697.717 1.013.984H14v1h4.024Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgShuttleSmall;
