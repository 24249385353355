import type { SVGProps } from "react";
const SvgChevronLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M16.354 4.353 8.707 12l7.647 7.646-.707.707-8-8L7.293 12l.354-.354 8-8 .707.707Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgChevronLeftSmall;
