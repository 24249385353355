import type { SVGProps } from "react";
const SvgOgvNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ogv-negative_svg__color-immutable"
    fill="none"
    height={20}
    width={59}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      fill="#EB0000"
      fillRule="evenodd"
    />
    <path
      d="M4.116 11.252c0-.686.103-1.408.308-2.168.27-1.017.678-1.9 1.226-2.65a5.956 5.956 0 0 1 2.09-1.771c.838-.438 1.793-.657 2.863-.657 1.435 0 2.592.446 3.473 1.337.886.891 1.329 2.072 1.329 3.544a7.82 7.82 0 0 1-.863 3.551c-.574 1.144-1.355 2.025-2.34 2.642-.987.617-2.102.926-3.347.926-1.081 0-1.988-.245-2.721-.736-.733-.49-1.252-1.097-1.558-1.82a5.632 5.632 0 0 1-.46-2.198Zm2.357-.048c0 .796.243 1.464.728 2.002.485.537 1.123.806 1.914.806.643 0 1.26-.21 1.851-.633.596-.427 1.086-1.07 1.471-1.93.39-.864.586-1.706.586-2.523 0-.912-.245-1.627-.736-2.144C11.797 6.26 11.172 6 10.412 6c-1.165 0-2.114.544-2.847 1.63a6.29 6.29 0 0 0-1.092 3.575Zm15.469-1.598h5.189l-1.044 4.976c-.612.395-1.363.73-2.255 1.004a9.125 9.125 0 0 1-2.713.412c-1.434 0-2.534-.324-3.298-.973-1.044-.886-1.567-2.17-1.567-3.852 0-1.129.225-2.21.673-3.244.538-1.244 1.302-2.21 2.294-2.895.991-.685 2.157-1.028 3.496-1.028 1.34 0 2.413.311 3.22.933.812.623 1.35 1.53 1.613 2.721l-2.223.254c-.195-.654-.511-1.147-.949-1.48-.432-.332-.978-.498-1.637-.498-.77 0-1.477.2-2.12.601-.643.401-1.147 1.02-1.51 1.86-.365.838-.547 1.752-.547 2.744 0 .976.222 1.698.665 2.167.443.464 1.091.696 1.946.696.511 0 1.036-.07 1.574-.213a7.269 7.269 0 0 0 1.392-.522l.356-1.725h-2.959l.404-1.938ZM33.408 15.8h-2.547l-2.08-11.596h2.38l1.472 8.772 4.801-8.772H39.8L33.408 15.8Z"
      fill="#fff"
    />
  </svg>
);
export default SvgOgvNegative;
