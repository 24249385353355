import type { SVGProps } from "react";
const SvgArrowsUpDownPressureMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={36}
    width={36}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arrows-up-down-pressure-medium_svg__a)">
      <path
        d="m14.996 10.488 3.754 3.761m0 0 3.75-3.75m-3.75 3.75V4.5M22.5 27l-3.75-3.75m0 0-3.754 3.762m3.754-3.762V33m10.5-12.75h-21v-3h21v3Z"
        stroke="currentColor"
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="arrows-up-down-pressure-medium_svg__a">
        <path d="M0 0h36v36H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowsUpDownPressureMedium;
