import type { SVGProps } from "react";
const SvgArrowsLeftRightDownUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11.855 2.398-.356-.36-.356.36-3.841 3.897.712.702L11 3.97V11H3.957l2.647-2.647-.707-.708-3.5 3.5-.354.354.354.354 3.5 3.5.707-.708L3.958 12H11v7.03l-2.995-3.027-.71.703 3.852 3.894.356.36.355-.36 3.842-3.898-.712-.701L12 19.032v-7.031h7.041l-2.645 2.645.708.708 3.5-3.5.353-.354-.353-.354-3.5-3.5-.707.708L19.043 11H12V3.967l2.997 3.029.711-.704-3.853-3.894Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLeftRightDownUpSmall;
